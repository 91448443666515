import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function ProjectReports({project,type}) {
  console.log(type)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [projectReport, setProjectReport] = useState(null);
  const { state } = useLocation();
 // const { project } = state;
  const projectName = project ? project.projectName.toUpperCase() : '';
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchProjectReport() {
      setLoading(true);
      const token = sessionStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/computed-reports/generate-projects-report?projectIds=${project._id}`, {
          headers: {
            'x-token': token
          }
        });
        if (!response.ok) {
          const data = response.json();
          console.log(data);
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const cleanedData = removeDummyEntries(data); // Clean the data
        setProjectReport(cleanedData);
        console.log('Fetched project report:', cleanedData);
        setError(false); // Reset error state on successful fetch
      } catch (error) {
        setError(true);
        console.error('Error fetching project report:', error);
        // Optionally handle error with a redirect:
        // clearStorageAndRedirectToHome();
      } finally {
        setLoading(false);
      }
    }

    async function fetchProjectReportProduction() {
      setLoading(true);
      const token = sessionStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/computed-reports-production/generate-projects-report?projectIds=${project._id}`, {
          headers: {
            'x-token': token
          }
        });
        if (!response.ok) {
          const data = response.json();
          console.log(data);
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const cleanedData = removeDummyEntries(data); // Clean the data
        setProjectReport(cleanedData);
        console.log('Fetched project report:', cleanedData);
        setError(false); // Reset error state on successful fetch
      } catch (error) {
        setError(true);
        console.error('Error fetching project report:', error);
        // Optionally handle error with a redirect:
        // clearStorageAndRedirectToHome();
      } finally {
        setLoading(false);
      }
    }
  
    if (project && type === 'quotation') {
      fetchProjectReport();
    }else if (project && type === 'production') {
      fetchProjectReportProduction();
    }
    else {
      setProjectReport({}); // Initialize projectReport as an empty object
    }
  }, [project]);

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    navigate('/measurit');
  }

  const removeDummyEntries = (data) => {
    const cleanedData = {
      project: {},
      doors: {},
      windows: {},
      tiles: {}
    };

    for (let key in data) {
      const value = data[key];

      if (key === 'Doors' || key === 'Windows' || key === 'Tiles') {
        cleanedData[key.toLowerCase()] = value;
      } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        const cleanedChildData = removeDummyEntries(value);
        if (Object.keys(cleanedChildData).length > 0) {
          cleanedData.project[key] = cleanedChildData;
        }
      } else if (key !== 'Dummy') {
        cleanedData.project[key] = value;
      }
    }

    return cleanedData;
  };

  const sumAreas = (data) => {
    let total = 0;
    for (let key in data) {
      const value = data[key];
      if (Array.isArray(value)) {
        total += parseFloat(value[0]) || 0;
      } else if (typeof value === 'object' && value !== null) {
        total += sumAreas(value);
      }
    }
    return total;
  };

  const sumCounts = (data) => {
    let total = 0;
    for (let key in data) {
      const value = data[key];
      if (Array.isArray(value) && value.length > 1) {
        total += parseFloat(value[1]) || 0;
      } else if (typeof value === 'object' && value !== null) {
        total += sumCounts(value);
      }
    }
    return total;
  };

  const filterData = (data) => {
    const cleanedData = {};
    for (let key in data) {
      if (key !== 'Total Doors Area' && key !== 'Dummy' && key !== 'Total Windows Area') {
        cleanedData[key] = data[key];
      }
    }
    return cleanedData;
  };

  const renderTableRows = (data, parentKey = '', includeCount = false) => {
    return Object.keys(data).map((key) => {
      if (key === 'Total Projects') return null; // Skip "Total Projects" key
      const value = data[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        const totalArea = sumAreas(value).toFixed(2);
        const totalCount = includeCount ? sumCounts(value) : null;
        return (
          <React.Fragment key={`${parentKey}-${key}`}>
            <tr>
              <td className="px-4 py-2 border border-gray-400"><strong>{key}</strong></td>
              <td className="px-4 py-2 border border-gray-400"><strong>{totalArea}</strong></td>
              {includeCount && <td className="px-4 py-2 border border-gray-400"><strong>{totalCount}</strong></td>}
            </tr>
            {renderTableRows(value, `${parentKey}-${key}`, includeCount)}
          </React.Fragment>
        );
      } else if (Array.isArray(value)) {
        return (
          <tr key={`${parentKey}-${key}`}>
            <td className="px-4 py-2 border border-gray-400">{key}</td>
            <td className="px-4 py-2 border border-gray-400">{value[0]}</td>
            {includeCount && <td className="px-4 py-2 border border-gray-400">{value[1]}</td>}
          </tr>
        );
      } else {
        return (
          <tr key={`${parentKey}-${key}`}>
            <td className="px-4 py-2 border border-gray-400">{key}</td>
            <td className="px-4 py-2 border border-gray-400" colSpan={includeCount ? 2 : 1}>{value}</td>
          </tr>
        );
      }
    });
  };

  const renderProjectTable = () => {
    if (!projectReport || !projectReport.project || Object.keys(projectReport.project).length === 0) {
      return null; // If no data, return null to hide the table
    }
  
    return (
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="px-4 py-2 border border-gray-400">Project Details</th>
            <th className="px-4 py-2 border border-gray-400">Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(projectReport.project).map(([key, value]) => (
            <tr key={key}>
              <td className="px-4 py-2 border border-gray-400">{key}</td>
              <td className="px-4 py-2 border border-gray-400">
                {typeof value === 'object' ? JSON.stringify(value) : value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  

  const renderDoorsTable = () => {
    if (!projectReport || Object.keys(projectReport.doors).length === 0) {
      return null; // If no data, return null to hide the table
    }
    const filteredDoors = filterData(projectReport.doors);

    return (
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="px-4 py-2 border border-gray-400">Doors</th>
            <th className="px-4 py-2 border border-gray-400">Area(sq.ft)</th>
            <th className="px-4 py-2 border border-gray-400">Nos</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(filteredDoors, 'doors', true)}
        </tbody>
      </table>
    );
  };

  const renderWindowsTable = () => {
    if (!projectReport || !projectReport.windows || Object.keys(projectReport.windows).length === 0) {
      return null; // If no data, return null to hide the table
    }
    const filteredWindows = filterData(projectReport.windows);
  
    return (
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="px-4 py-2 border border-gray-400">Windows</th>
            <th className="px-4 py-2 border border-gray-400">Area(sq.ft)</th>
            <th className="px-4 py-2 border border-gray-400">Nos</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(filteredWindows, 'windows', true)}
        </tbody>
      </table>
    );
  };

  const renderTilesTable = () => {
    if (!projectReport || Object.keys(projectReport.tiles).length === 0) {
      return null; // If no data, return null to hide the table
    }
    const filterTilesData = (data) => {
      const cleanedData = {};
      for (let key in data) {
        if (key !== 'Total Tiles Area' && key !== 'Dummy') {
          cleanedData[key] = data[key];
        }
      }
      return cleanedData;
    };

    const filteredTiles = filterTilesData(projectReport.tiles);

    return (
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="px-4 py-2 border border-gray-400">Tiles</th>
            <th className="px-4 py-2 border border-gray-400">Area(sq.ft)</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(filteredTiles, 'tiles')}
        </tbody>
      </table>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-full text-center">Error occurred while fetching report.</div>
      </div>
    );
  }

  return (
    <div className="p-10 overflow-x-scroll no-scrollbar">
      <div className="px-4 py-2 border border-gray-400 mb-10 flex justify-center items-center">
        {projectName}
      </div>
      <div className="flex justify-center">
        {renderProjectTable()}
      </div>
      <div className="flex justify-center mt-10">
        {renderDoorsTable()}
      </div>
      <div className="flex justify-center mt-10">
        {renderWindowsTable()}
      </div>
      <div className="flex justify-center mt-10">
        {renderTilesTable()}
      </div>
    </div>
  );
}

export default ProjectReports;
