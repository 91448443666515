import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown, faChevronUp, faSortUp, faSortDown, faX, faPaperclip, faPlus, faPen, 
  faMobileAndroidAlt, faSave, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Css/Tooltip.css';
import { Loader, Spinner } from '../Loader/Loader';

const OrganizationDetails = () => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const location = useLocation();
  const { organizationData } = location.state;
  console.log(organizationData);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [showAddress, setShowAddress] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showOtherDetails, setShowOtherDetails] = useState(false);
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  console.log(backendApiUrl);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [organizationStatus, setOrganizationStatus] = useState('Deactivate');
  const RETRY_LIMIT = 3;
  const [retryCount, setRetryCount] = useState(0);
  const [isAddressEditing, setIsAddressEditing] = useState(false);
  const [address1, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [pincode, setPincode] = useState();
  const [isGSTEditing, setIsGSTEditing] = useState(false);
const [GSTValue, setGSTValue] = useState();
const [isPanEditing, setIsPanEditing] = useState(false);
const [panValue, setPanValue] = useState();
const [mappedOrganizations, setMappedOrganizations] = useState([]);


useEffect(() => {
  console.log('organizationData in useEffect:', organizationData);
  if (organizationData) {
    fetchOrganizations(organizationData);
  }
}, [organizationData]);

const fetchOrganizations = async (Organization, attempt = 0) => {
  console.log('Fetching organizations...', Organization);
  try {
    const token = sessionStorage.getItem('token');
    console.log('Token:', token);
    const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}organization/get-all-organizations`;
    console.log('Fetch URL:', url);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
    });

    console.log('Response status:', response.status);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Fetched data:', data);

    if (data.status === 200 || data.status === 201 || data.status === 204) {
      //const organizationsData = data.data;
      setOrganizations(data.data);  
      console.log('Set organizations:', data.data);

      const mappedData = data.data.map((organization) => ({
        _id: organization._id,
        organization_name: organization.organization_name,
        company_name: organization.company_name,
      }));
  
      setMappedOrganizations(mappedData);
  

      const FindOrganization = data.data.find((p) => p._id === Organization._id);
      
        handleOrganizationClick(FindOrganization);
      
      setIsLoading(false);
    } else {
      throw new Error(data.message || 'Unknown error occurred');
    }
   } catch (error) {
      console.error('Failed to fetch:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchOrganizations(Organization, attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage(error.message || 'Failed to fetch organizations. Please try again.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchOrganizations(Organization, 0));
      }
    }
  };
  
  const fetchOrganizationsByOrganizationId = async (organizationId, attempt = 1) => {
    console.log(organizationId);
    try {
      const token = sessionStorage.getItem('token');
      console.log(token);
      const getOrganizations = `${backendApiUrl}organization/get-organizations-by-Id?organizationId=${organizationId}`;
      console.log(getOrganizations);
      const response = await fetch(getOrganizations, {
    
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-token': token
        },
      });
      const data = await response.json();
      console.log(data);
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setOrganizations(data.data)
         
      } else if (data.status === 404) {
        setOrganizations([]);
        setPopupMessage(data.message);
        setPopupType('404');
        setShowPopup(true);
      } else if (data.status === 500) {
        setPopupMessage(data.message);
        setPopupType('500');
        setRetryFunction(() => fetchOrganizationsByOrganizationId);
        setShowPopup(true);
      } else {
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchOrganizationsByOrganizationId(organizationId, attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchOrganizationsByOrganizationId(organizationId, attempt));
      }
    }
  };
  
  useEffect(() => {
    if (organizationId) {
      fetchOrganizationsByOrganizationId(organizationId);
    }
  }, [organizationId]);
  console.log(organizationId);
  
  

  const handleOrganizationClick = (organization) => {
    setOrganizationId(organization._id);
    if (organizations && organizations._id === organization._id) {
      setSelectedOrganization(organizations);
    }
  };
   
  const handleToggleAddress = () => {
    setShowAddress(!showAddress);
    setShowOtherDetails(false);
  };

  const handleEditAddress = () => {
    setIsAddressEditing(true);
  };

  const handleCancelClick = () => {
    setIsAddressEditing(false);
    setAddress(selectedOrganization?.address || '');
    setCity(selectedOrganization?.city || '');
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  
  

  const handleToggleOtherDetails = () => {
    setShowOtherDetails(!showOtherDetails);
    setShowAddress(false);
  };

  const handleOrganizationInputfieldChange = (e) => {
    const { id, value } = e.target;
    setSelectedOrganization((prevSelectedOrganization) => ({
       ...prevSelectedOrganization,
       [id]: value
    }));
  };

  const handleUpdateOrganization = async (attempt = 1) => {
    const token = sessionStorage.getItem('token');
    const url = `${backendApiUrl}organization_name/update-organization_name`;

    const errorMessages = [];
    if (!selectedOrganization.name) {
      errorMessages.push('Name is required.');
    }
    if (!selectedOrganization.mobile_number) {
      errorMessages.push('Mobile number is required.');
    } else if (selectedOrganization.mobile_number.length !== 10) {
      errorMessages.push('Mobile number must be exactly 10 digits.');
    }
    if (selectedOrganization.email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(selectedOrganization.email)) {
        errorMessages.push('Invalid email address.');
      }
    }

    if (errorMessages.length > 0) {
      setErrorMessages(errorMessages);
      setIsSaving(false);
      return;
    }

    setIsSaving(true);
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify(selectedOrganization)
      });
      const responseData = await response.json();
      if (responseData.status === 200 || responseData.status === 201 || responseData.status === 204) {
        handleSetUpdatingOrganizationsData(selectedOrganization);
        setIsLoading(false);
        setIsSaving(false);
        setShowPopup(true);
        setPopupType('200or201');
        setPopupMessage(responseData.message);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      } else if (responseData.status === 500) {
        setIsLoading(false);
        setIsSaving(false);
        setShowPopup(true);
        setPopupType('500');
        setRetryFunction(() => handleUpdateOrganization);
        setPopupMessage(responseData.message);
      } else {
        setIsSaving(false);
        setIsLoading(false);
        setPopupMessage(responseData.message);
        setPopupType(`${responseData.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => handleUpdateOrganization(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleUpdateOrganization(attempt));
      }
    } finally {
      setErrorMessages([]);
    }
  };

  const handleSetUpdatingOrganizationsData = async (selectedOrganization) => {
    const FindingOrganizationIndex = organizations.findIndex(p => p._id === selectedOrganization._id);
    if (FindingOrganizationIndex !== -1) {
      const updatedOrganizations = organizations.map((organization, index) =>
        index === FindingOrganizationIndex
          ? { ...organization, ...selectedOrganization }
          : organization
      );
      setOrganizations(updatedOrganizations);
    }
  };

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...organizations].sort((a, b) => {
      if (a[key] < b[key]) {
       return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setOrganizations(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  

  const handleCreateOrganization = () => {
    navigate('/bb/app/purchases/neworganization');
  };

  const HandleClose = () => {
    navigate('/bb/app/purchases/organizationslist');
  };

  const handleCloseError = () => {
    setErrorMessages([]);
  };

  const handleEdit = () => {
    navigate('/bb/app/settings/EditOrganization', { state: { OrganizationData: selectedOrganization } });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  const Retry = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      if (retryFunction) {
        await retryFunction();
      }
      setIsLoading(false);
    }, 5000);
    setShowPopup(false);
  };

  const BackToLoginPage = () => {
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
    navigate('/bb');
  };

  return (
    <div className="bg-white flex flex-row w-full justify-center overflow-y-hidden">
      {/* List of Organizations Div start */}
      <div className="flex flex-col w-[32%]">
        <div className="p-4 border-b flex flex-row justify-between">
          <div>
            <h5 className="text-md font-semibold cursor-pointer">
              All Organizations
              <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer" />
            </h5>
          </div>
          <div>
            <button
              className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md"
              data-tooltip="Create Organization"
              title=""
              style={{ '--tooltip-bg': 'black' }}
              onClick={handleCreateOrganization}
            >
              <FontAwesomeIcon icon={faPlus} className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className=" flex justify-center  overflow-y-hidden  flex-col ">
  <div className='flex  flex-col h-[80vh]  overflow-y-auto '>
    {mappedOrganizations.map((organization) => (
      <div key={organization._id} onClick={() => handleOrganizationClick(organization)} className={`border-b text-sm flex items-center px-4 py-2 ${organizationId === organization._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}>
        <div className='flex flex-row'>
          <div>
            <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
          </div>
          <div className='flex flex-col'>
            <p className='text-md font-semibold'>{organization.organization_name}</p>
            <p className="text-gray-600 text-sm">{organization.company_name}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

</div>


      {/* List of Organizations Div end */}
  
      <div className="border-l w-full flex flex-col">
        {selectedOrganization ? (
          <>
            <div className="border-b h-24">
              <div className='p-4 flex justify-between items-center'>
                <span className="text-md font-semibold">{selectedOrganization.organization_name}</span>
                <div className="flex items-center px-2">
                <button className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md mr-2" onClick={handleEdit}>
                    Edit
                  </button>    
                    
                  
                  {/* <button className="bg-gray-100 px-1 py-1 shadow-sm text-sm border rounded-md mr-2">
                    <FontAwesomeIcon icon={faPaperclip} className="text-md text-black mx-2 cursor-pointer" />
                  </button> */}
                  <button className="text-white px-1 py-1 rounded-md"
                    title="" data-tooltip="Close" style={{ '--tooltip-bg': 'black' }}>  
                    <FontAwesomeIcon icon={faX} onClick={HandleClose} className="text-md text-[#f7525a] mx-2 cursor-pointer" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex h-5/6 w-full overflow-y-auto flex-row">
              <div className="bg-[#fbfbfb] w-[32%] border-r p-4">
                <h2 className="text-sm pb-2 border-b">
                  {selectedOrganization.company_name}
                </h2>
                <div className='pt-4 pl-4 pr-4 flex flex-row'>
                  <div className='flex-shrink-0'>
                    <img className="rounded-md w-10 h-10 cursor-pointer" src={'/ProfileImageSvg.jpg'} alt="Brick Bucket Logo" />
                  </div>
                  <div className='text-sm ml-3 flex flex-col'>
                    <div className='font-semibold mb-0.5 break-words word-break'>{selectedOrganization.name}</div>
                    <div className='break-words word-break'>{selectedOrganization.email}</div>
                    <div className='break-words word-break'>
                      <FontAwesomeIcon icon={faMobileAndroidAlt} className='mr-1 text-xs'/>
                      {selectedOrganization.mobile_number}
                    </div>
                    <div className='text-xs flex flex-row'>
                      <button onClick={handleEdit} className='text-[#408dfb] hover:text-blue-600'>Edit</button>
                      <p className='text-gray-300 ml-2 mr-2'>|</p>
                      <button
                        // onClick={selectedOrganization.is_active ? handleDeactivateClick : handleActivateClick}
                        className='text-[#408dfb] hover:text-blue-600'
                      >
                        {/* {selectedOrganization.is_active ? 'Deactivate' : 'Activate'} */}
                      </button>
                    </div>
                  </div>
                </div>
  
                <div onClick={handleToggleAddress} className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
                  <span className='text-sm cursor-pointer' onClick={handleToggleAddress}>ADDRESS</span>
                  <button className='text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleAddress}>
                    {showAddress ? <FontAwesomeIcon icon={faChevronUp} className='text-xs text-blue-800'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs text-blue-800'/>}
                  </button>
                </div>
  
                <div className={`${showAddress ? '' : 'hidden'}`}>
                  <div className='text-sm pt-2 pl-4 pr-4'>
                    <div className='flex justify-between'>
                      <h1> Address</h1>
                      {/* {!isAddressEditing &&
                        <FontAwesomeIcon icon={faPen} className='text-xs' onClick={handleEditAddress}/>
                      } */}
                    </div>
  
                    <div className='flex flex-col'>
                      <div className='flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>
                        <div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex justify-end'>
                          {isAddressEditing ? (
                            <div className='flex flex-col space-y-2'>
                              <input
                                type="text"
                                value={address1}
                                onChange={(e) => {setAddress(e.target.value); selectedOrganization.address1 = e.target.value}}
                                className="border border-gray-300 w-30 rounded px-2 py-1"
                              />
                              <input
                                type="text"
                                value={city}
                                onChange={(e) => {setCity(e.target.value); selectedOrganization.city = e.target.value}}
                                className="border border-gray-300 w-30 rounded px-2 py-1"
                              />
                                <input
                                type="text"
                                value={state}
                                onChange={(e) => {setState(e.target.value); selectedOrganization.state = e.target.value}}
                                className="border border-gray-300 w-30 rounded px-2 py-1"
                              />
                                <input
                                type="text"
                                value={pincode}
                                onChange={(e) => {setPincode(e.target.value); selectedOrganization.pincode = e.target.value}}
                                className="border border-gray-300 w-30 rounded px-2 py-1"
                              />
                            
                            
                            </div>
                          ) : (
                            <>
                              <div className='flex flex-col space-y-2'>
                                <p>{selectedOrganization.address1}</p>
                                <p>{selectedOrganization.city}</p>
                                <p>{selectedOrganization.state}</p>
                                <p>{selectedOrganization.pincode}</p>


                              </div>
                            </>
                          )}
                          <div className='flex items-center'>
                            {isSaving && (
                              <Spinner/>
                            )}
                            {isAddressEditing && (
                              <>
                                <FontAwesomeIcon
                                  icon={faSave}
                                  className='mr-2 text-xs ml-2 cursor-pointer'
                                  onClick={handleUpdateOrganization}
                                />
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className='mr-2 text-xs ml-2 cursor-pointer'
                                  onClick={handleCancelClick}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div onClick={handleToggleOtherDetails} className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
                  <span className='text-sm cursor-pointer' onClick={handleToggleOtherDetails}>TAX DETAILS</span>
                  <button className='text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleOtherDetails}>
                    {showOtherDetails ? <FontAwesomeIcon icon={faChevronUp} className='text-xs text-blue-800'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs text-blue-800'/>}
                  </button>
                </div>
  
                <div className={`${showOtherDetails ? '' : 'hidden'} mt-2 mb-2 flex flex-col`}>
                  <div className='flex flex-row pb-2'>
                    <div className='text-[#212529] text-xs pt-2 pl-2 pr-2 w-32'>
                      <h1>GST</h1>
                    </div>
                    <div className='flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>
                      <div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex justify-end'>
                        {isGSTEditing ? (
                          <input
                            type="text"
                            value={GSTValue}
                            onChange={(e) => {setGSTValue(e.target.value); selectedOrganization.gst = e.target.value}}
                            className="border border-gray-300 w-24 rounded px-2 py-1"
                          />
                        ) : (
                          <p>{selectedOrganization.gst}</p>
                        )}
                      </div>
                      <div className='flex items-center'>
                        {isSaving && <Spinner />}
                        {isGSTEditing ? (
                          <>
                            <FontAwesomeIcon
                              icon={faSave}
                              className='mr-2 text-xs ml-2 cursor-pointer'
                              onClick={handleUpdateOrganization}
                            />
                            <FontAwesomeIcon
                              icon={faTimes}
                              className='mr-2 text-xs ml-2 cursor-pointer'
                              onClick={handleCancelClick}
                            />
                          </>
                        ) : (
                          <FontAwesomeIcon
                            icon={faPen}
                            className='mr-2 text-xs ml-2 cursor-pointer'
                            onClick={() => setIsGSTEditing(true)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row pb-2'>
                    <div className='text-[#212529] text-xs pt-2 pl-2 pr-2 w-32'>
                      <h1>PAN</h1>
                    </div>
                    <div className='flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>
                      <div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex justify-end'>
                        {isPanEditing ? (
                          <input
                            type="text"
                            value={panValue}
                            onChange={(e) => {setPanValue(e.target.value); selectedOrganization.pan = e.target.value}}
                            className="border border-gray-300 w-24 rounded px-2 py-1"
                          />
                        ) : (
                          <p>{selectedOrganization.pan}</p>
                        )}
                      </div>
                      <div className='flex items-center'>
                        {isSaving && <Spinner />}
                        {isPanEditing ? (
                          <>
                            <FontAwesomeIcon
                              icon={faSave}
                              className='mr-2 text-xs ml-2 cursor-pointer'
                              onClick={handleUpdateOrganization}
                            />
                            <FontAwesomeIcon
                              icon={faTimes}
                              className='mr-2 text-xs ml-2 cursor-pointer'
                              onClick={handleCancelClick}
                            />
                          </>
                        ) : (
                          <FontAwesomeIcon
                            icon={faPen}
                            className='mr-2 text-xs ml-2 cursor-pointer'
                            onClick={() => setIsPanEditing(true)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="w-[68%] pl-2">
                <div className="w-full">
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                      <div className="overflow-x-auto w-full container mx-auto px-4">
                        {selectedOrganization.name} 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-500">Select an organization to view details</p>
          </div>
        )}
      </div>
    </div>
  );
}
  
export default OrganizationDetails;