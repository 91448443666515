import React, { useRef ,useState, useEffect, useCallback  } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle, faPaperclip,faTrash, faX, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages'
import TableSearchDropDown from '../Components/TableSearchDropDownForSalesOrder';
import TableSearchForAreaName from '../Components/TabelSerchForAreaName'
import  '../Css/Tooltip.css';
import {useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
    setFocusedOptionIndex(null);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };

  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };


  useOutsideClick(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);


  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option,index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EditDeliveryChallans = () => {
  const navigate = useNavigate ();
  const location= useLocation();
  const { QuoteID } = location.state || {};
  const [Quotation , setQuotation] = useState();
  const [salesOrders, setSalesOrders] = useState([]);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);
  const [allSalesOrders, setAllSalesOrders] = useState([]);

 
  const [selectedProject, setSelectedProject] = useState(null);
const [selectedProjectId, setSelectedProjectId] = useState('');
const dropdownRef = useRef(null); // Define the dropdownRef variable
  const [quoteTypeOptions, setQuoteTypeOptions] = useState([
    { label: "General", value: "General" },
    { label: "Group", value: "Group" },
  ]);
  const [selectedQuoteType, setSelectedQuoteType] = useState(); // Initial value
 // console.log(selectedQuoteType.value)
  const [TaxOptions] = useState([
    {label: "Tax Exclusive", value: "Tax Exclusive"},
    {label: "Tax Inclusive", value: "Tax Inclusive"},
  ]);
  const [selectedTax, setSelectedTax] = useState();
  const [priceLists , setPriceLists] = useState([ ]);
  //console.log(priceLists);
  const[selectedPricList, setselectedPricList] = useState('');
  const [UomOptions , setUomOptions] = useState([]);
  const [AreaMeasurementsData, setAreaMeasurementsData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
 console.log(selectedCustomer);
  const [quotationNumber, setQuotationNumber] = useState('');
  const [salesorderDate, setsalesorderDate] = useState(new Date());
  const [showDesignSelectPopup, setshowDesignSelectPopup] = useState(false);
  const [showDoorsAndWindowsBreakUpPopup, setShowDoorsAndWindowsBreakUpPopup] = useState(false);
  const [selectedWires, setSelectedWires] = useState({});
  const [showWireSelectPopup, setshowWireSelectPopup] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [Summary , setSummary] =  useState({});
  const [selectedProductCategory, setSelectedProductCategory] = useState();
  console.log(selectedProductCategory)
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const FetchProjectsUrl = process.env.REACT_APP_FORE_APILINK; //measurit
  const Taxes = [{name:'GST' , value: 'GST'},{name:'IGST' , value: 'IGST'}]
  const [SelectedItemRow,setSelectedItemRow] = useState()
const [SelectedItemRowIndex ,setSelectedItemRowIndex] = useState()
const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES; 
const [searchTerm, setSearchTerm] = useState('');
const [DefaultProductRatesOfPriceList,setDefaultProductRatesOfPriceList]= useState()
const [allRowsSelectedPriceList, setAllRowsSelectedPriceList] = useState([]);
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowPricelists, setRowPricelists] = useState({});
  const [rows, setRows] = useState([{ item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',},  quantity: '', UOM: '', price: '', tax: '', amount: '', designs:[]}]);
  const [products, setProducts] = useState([]); // assume products is set to one of the arrays you provided
  const [amounts, setAmounts] = useState([]);
  const [items, setItems] = useState([{ tilesArea: '', doorsArea: '', windowsArea: '' }]);
  const [tilesOptions, setTilesOptions] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  //console.log(discountPercentage);
  const [adjustment, setAdjustment] = useState('');
  const[ attributes, setAttributes]= useState([])
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const [customersLoaded, setCustomersLoaded] = useState(false);
const [productCategoriesLoaded, setProductCategoriesLoaded] = useState(false);
const [QuotationItems ,setQuotationItems] = useState();
const  [SubTotal,setSubTotal]= useState(0)

const [total, setTotal] = useState(0);
const [amendedDiscount, setAmendedDiscount] = useState();
const [amendedPrice, setAmendedPrice] = useState(0);
const [breakUp,setBreakUp] = useState();
console.log(breakUp)
const [selectedApplicationAreas, setSelectedApplicationAreas] = useState({});

    useEffect(() => {
      fetchAllApprovedCustomers();
      fetchProductCategories();
      handleGetAllUoms();
      fetchSalesOrder();
    }, [fetchUrl]);

    useEffect(() => {
        filterSalesOrders();
      }, [selectedCustomer, selectedProductCategory, allSalesOrders]);
    
    

    // useEffect(() => {
    //   if (customersLoaded && productCategoriesLoaded) {
    //     fetchQuotationById(customers);
    //   }
    // }, [customersLoaded, productCategoriesLoaded]);
      
    const fetchAllApprovedCustomers = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + `customer/get-all-approved-customers?organizationId=${sessionStorage.getItem('organizationId')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        if (output) {
          // Format data for dropdown
          const formattedCustomers = output.map(customer => ({
            id: customer._id,  // Use customer ID as value
            label: customer.customer_name, // Use customer name as label
            value: customer.customer_name
          }));
    
          setCustomers(formattedCustomers); // Update state with formatted data
         // console.log(formattedCustomers);
         setCustomersLoaded(true);
        } else {
          handleFetchError(response, attempt, fetchAllApprovedCustomers);
        }
      } catch (error) {
        handleErrorWithRetry(error, attempt, fetchAllApprovedCustomers);
      }
    };

    const fetchProductCategories = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
          setProductCategories(output); // Update productCategories state
          setProductCategoriesLoaded(true);
        }  else {
          handleFetchError(response, attempt, fetchProductCategories);
        }
      } catch (error) {
        handleErrorWithRetry(error, attempt, fetchProductCategories);
      }
    };

  
    const fetchSalesOrder = async (attempt = 0) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/get-all-salesorders?organizationId=${sessionStorage.getItem('organizationId')}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
      
          const data = await response.json();
      
          if (data.status === 200 || data.status === 201 || data.status === 204) {
            const salesOrderOptions = data.data.map(order => ({
                id: order._id,
                value: order.salesorder_number,
                label: order.salesorder_number,
                customer_id: order.customer_id._id,
                product_category_id: order.product_category_id._id
              }));
              setAllSalesOrders(salesOrderOptions); 
            setSalesOrders(salesOrderOptions);
            console.log(salesOrderOptions);
            console.log(data.data);
            setIsLoading(false);
          } else if (data.status === 500) {
            setIsLoading(false);
            setRetryFunction(() => fetchSalesOrder);
            setPopupMessage(data.message);
            setPopupType(`${data.status}`);
            setShowPopup(true);
          } else {
            setIsLoading(false);
            setPopupMessage(data.message);
            setPopupType(`${data.status}`);
            setShowPopup(true);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
      
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1);
            setTimeout(() => fetchSalesOrder(attempt + 1), 1000);
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => fetchSalesOrder(attempt));
          }
        }
      };

      const filterSalesOrders = () => {
        console.log("Filtering sales orders");
        console.log("Selected Customer:", selectedCustomer);
        console.log("Selected Product Category:", selectedProductCategory);
        console.log("All Sales Orders:", allSalesOrders);
      
        let filteredOrders = allSalesOrders;
      
        if (selectedCustomer) {
          filteredOrders = filteredOrders.filter(order => {
            console.log("Comparing:", order.customer_id, selectedCustomer.id);
            return order.customer_id === selectedCustomer.id;
          });
          console.log("Filtered by customer:", filteredOrders);
        }
      
        if (selectedProductCategory) {
          filteredOrders = filteredOrders.filter(order => {
            console.log("Comparing:", order.product_category_id, selectedProductCategory.value);
            return order.product_category_id === selectedProductCategory.value;
          });
          console.log("Filtered by product category:", filteredOrders);
        }
      
        setSalesOrders(filteredOrders);
        console.log("Final filtered orders:", filteredOrders);
      };
      
      
      const fetchSalesOrderById = async (salesOrderId) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}customer/salesorder/get-salesorder?salesorder_id=${salesOrderId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
      
          const data = await response.json();
      
          if (data.status === 200 || data.status === 201 || data.status === 204) {
            const salesOrderDetails = data.data;
            console.log("Fetched Sales Order Details:", salesOrderDetails);
            return salesOrderDetails;
          } else {
            console.error("Failed to fetch sales order details:", data.message);
            setPopupMessage(data.message);
            setPopupType(`${data.status}`);
            setShowPopup(true);
          }
        } catch (error) {
          console.error("Error fetching sales order details:", error);
          setPopupMessage("Error fetching sales order details. Please try again.");
          setPopupType("error");
          setShowPopup(true);
        }
      };
     
      const populateItemTableWithSalesOrderData = (salesOrderDetails) => {
        console.log("Sales Order Details:", salesOrderDetails);
        if (salesOrderDetails && salesOrderDetails.items && salesOrderDetails.items.length > 0) {
          console.log("Items:", salesOrderDetails.items);
          const newRows = salesOrderDetails.items.map(item => ({
            item_name: item.item_name,
            item_id: item.item_id,
            quantity: item.quantity,
            UOM: item.UOM,
            price: item.selling_price,
            tax: item.tax_rate,
            total: item.sales_total,
            total_tax_amount: item?.total_tax_amount || 0,
            application_area: Array.isArray(item.application_areas) ? (
              item.application_areas.map(area => ({
                area_name: area.area_name,
                area: area.area,
                count: area.count,
              }))
            ) : [],
          }));
          console.log("New Rows:", newRows);
          setRows(newRows);
        } else {
          console.log("No items found in sales order details.");
          setRows([]); // or display a message to the user
        }
      };
    
    const fetchAllprojectsbyCustomer = async (customerid, ProjectId , attempt = 1) => {
      //console.log('Fetching projects for customer_id:', id);
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${FetchProjectsUrl}/projects/get-projects-by-customer?customer_id=${customerid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        //console.log(output);
        if (output) {
           const formattedProjects = output.map(project => ({
            id: project?._id,  // Use customer ID as value
            label: project?. projectName, // Use customer name as label
            value: project?.projectName
          }));
    
          setProjects(formattedProjects);
          //console.log(formattedProjects)
          const selectedProject = formattedProjects.find(project => project.id === ProjectId);
          //console.log(selectedProject);
          setSelectedProject(selectedProject);
          setSelectedProjectId(selectedProject?.id);
        } /* else {
          if (response.status === 500) {
            console.log('retry entered');
            setRetryFunction(() => () => fetchAllprojectsbyCustomer(customerid));
          }
          //console.error(data.message);
        } */
      } catch (error) {
        console.error('Failed to fetch projects:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1);
          setTimeout(() => fetchAllprojectsbyCustomer(customerid, attempt + 1), 1000);
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAllprojectsbyCustomer(customerid, attempt));
        }
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (selectedProject) {
        fetchProjectSummarybyProjectId(selectedProject.id);
        
        // Check if the selectedProductCategory is "windows" or "doors"   
        if (selectedProductCategory.label === 'Windows' || selectedProductCategory.label === 'Doors') {
          fetchAdditionalDataByProjectId(selectedProject.id);
        }
      }
    }, [selectedProject, selectedProductCategory]); // Dependencies for the useEffect
    
    const fetchAdditionalDataByProjectId = async (projectid) => {
      // console.log(projectid)
       try {
         const token = sessionStorage.getItem('token');
         const response = await fetch(`${FetchProjectsUrl}/project-reports-production/get-project-measurements-by-project-and-product-category?project_id=${projectid}&product_category_id=${selectedProductCategory.value}`, {
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
             'x-token': token
           }
         });
         
         const data = await response.json();
         console.log(data)
         if (response.ok) {
            // console.log(data?.data?.projectMeasurements);
           setAreaMeasurementsData(data?.data?.projectMeasurements);
           console.log(data?.data?.projectMeasurements)
           setSummary(data.data);
   
         } else {
           console.error('Failed to fetch projects:', response.status, response.statusText);
         }
       } catch (error) {
         console.error('Failed to fetch projects:', error);
       } 
     };

    const fetchProjectSummarybyProjectId = async (projectid) => {
     // console.log(projectid)
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${FetchProjectsUrl}/project-reports/get-project-measurements-by-project-and-product-category?project_id=${projectid}&product_category_id=${selectedProductCategory.value}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        
        const data = await response.json();
        console.log(data)
        if (response.ok) {
           // console.log(data?.data?.projectMeasurements);
          setAreaMeasurementsData(data?.data?.projectMeasurements);
          setSummary(data.data);
  
        } else {
          console.error('Failed to fetch projects:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      } 
    };
 
   // Centralized error handling
const handleFetchError = (response, attempt, retryFunction) => {
  if (response.status === 500) {
    setRetryFunction(() => retryFunction); 
  }
};
    
    const handleErrorWithRetry = (error, attempt, retryFunction, ...args) => {
      console.error('Failed to fetch:', error);
    
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => retryFunction(...args, attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => retryFunction(...args, attempt));
      }
    };

    const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
        //console.log(output)
         setAttributes(output)
        } else {
          setAttributes([])
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
        }
      }
      }

    const fetchProductsbyProductCategoryID = async (product_category, attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product/get-products-by-product-category?product_category_id=${product_category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
         // console.log(output)
          const productsWithItemName = output.map((product) => {
            // Match the item_id with the rows and get the corresponding item_name
            const matchedRow = rows.find(row => row.item_id === product.item_id);
            const itemName = matchedRow ? product.item_name : ''; // Map the item_name

            return {
                ...product,
                itemName, // Add itemName directly to the product object
            };
        });
        //console.log(productsWithItemName)
        setProducts(output);
         // setProducts(output); // Update productCategories state
        } else {
          setProducts([])
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchProductsbyProductCategoryID(attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchProductsbyProductCategoryID(attempt)); // Set retry function
        }
      }/* finally {
        setIsLoading(false);
      } */
    };

    const handleGetAllUoms = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        });
    
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        if (output) {
          // Log the data to ensure it's fetched correctly
          //console.log('Fetched uoms:',output);
    
          const uomData = output.map((item) => ({
            id: item._id,
            value: item.UOM,
            label: item.UOM,
          }));
    
      setUomOptions(uomData)
        } else {
          console.error('Error:', data.message);
          return ([])
        
         
        }
      }catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleGetAllUoms(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleGetAllUoms(attempt)); // Set retry function
        }
      }finally {
        setIsLoading(false);
      }
    };

    const fetchAllPriceLists = async (ProductCategory,attempt = 1) => {
     
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(fetchUrl + `pricelist/get-pricelists-with-productrates-by-product-category?product_category_id=${ProductCategory.value}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
         
          const output = await handleGetResponse(response , data)
          if (output) {
            //console.log(output);
            setPriceLists(output); // Update productCategories state
          } else {
            console.error(data.message);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchAllPriceLists(ProductCategory, attempt + 1), 1000); // Retry after 1 second
          } else {
           
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchAllPriceLists(ProductCategory, attempt)); // Set retry function
          }
        }/* finally {
          setIsLoading(false);
        } */
      
      
    };

    useEffect(() => {
      const fetchQuotationData = async () => {
        try {
          const items = Quotation.items;

          const initialRows = items.map((item, index) => {  // Added index here
            const selectedOption = { label: item.tax_type, value: item.tax_type };
            
            handleTaxChange(
              index,  // Pass the index to the handleTaxChange function
              selectedOption,
              item.tax_type ,
              item?.selling_price,
              item?.quantity
            ); 
            const product = products.find(p => p.item_id === item.item_id) || {};
            const selectedUom = UomOptions.find(p => p?.id === product?.UOM_id) || {};
            // Common fields for both general and group quotations
            const commonFields = {
              item_name: product.item_name || '',
              item_id: product.item_id || '',
              measured_area: item.measured_area || '',
              quantity: item.quantity || '',
              UOM: selectedUom.value || '',
              UOM_id: selectedUom.id || '',
              price: item.selling_price || '',
              cost_price: item.cost_price || '',
              tax: item.tax_rate || '',
              tax_type: item?.tax_type || '',
              total: item.total || '',
              price_list_id: item?.price_list_id || '',
              total_tax_amount: item?.total_tax_amount || 0,
              application_area: {
                area_name: item.area_types?.area_name || '',
                area: item.area_types?.area || '',
                count: item.area_types?.count || '',
              },
              sub_items: product?.sub_items || [],
              selectedDesigns: item?.required_order_breakup || []
            };
            
            return commonFields;
          });
    
          setRows(initialRows); // Set rows state with fetched data
          const TaxOption = { label: Quotation?.tax_prefrence || '', value: Quotation?.tax_prefrence || '' };
          setSelectedTax(TaxOption);
          setTotal(Quotation?.total || 0)
          if (Quotation?.default_price_list_id) {
            const priceList = priceLists.find(p => p?.priceList?._id === Quotation.default_price_list_id)?.priceList;
            if (priceList) {
              const option = { label: priceList.price_list_name, value: Quotation.default_price_list_id };
              setselectedPricList(option);
    
              const allPriceLists = items.map(item => {
                const priceListData = priceLists.find(p => p?.priceList?._id === item.price_list_id)?.priceList;
                if (priceListData) {
                  return { label: priceListData.price_list_name, value: priceListData._id };
                }
                return null; // Handle case where no matching priceList is found
              }).filter(eachList => eachList !== null); // Filter out any null values
    
              setAllRowsSelectedPriceList(allPriceLists);
            }
          }
    
        } catch (error) {
          console.error('Error fetching quotation data:', error);
        }
      };
    
      fetchQuotationData();
    }, [products, UomOptions]);

    useEffect(() => {
      console.log("Updated Rows:", rows);
    }, [rows]);
    
    
    const handleSelectWire = (color, quantity) => {
      setSelectedWires((prevSelectedWires) => ({
        ...prevSelectedWires,
        [color]: quantity,
      }));
    };
    
  const handleDateChange = (date) => {
    setsalesorderDate(date);
  };

  const handleQuotationNumberChange = (e) => {
    setQuotationNumber(e.target.value);
  };

  const handleSalesOrderSelect = async (selectedOption) => {
    setSelectedSalesOrder(selectedOption);
    if (selectedOption) {
      try {
        const salesOrderDetails = await fetchSalesOrderById(selectedOption.id);
        console.log("Fetched Sales Order Details:", salesOrderDetails);
        if (salesOrderDetails) {
          populateItemTableWithSalesOrderData(salesOrderDetails);
        }
      } catch (error) {
        console.error("Error fetching sales order details:", error);
      }
    }
  };
  
  const handlePriceListSelect = (option) => {
    ///console.log(option)
    setselectedPricList(option);
    setRowPricelists(option)
    const RequiredpriceList= priceLists.find(p=>p?.priceList._id===option?.value)
    setDefaultProductRatesOfPriceList(RequiredpriceList?.productRates )
    if (!selectedPricList) {
      const id = option?.value;
      const RequiredpriceList = priceLists.find(p => p.priceList._id === id);
      const productRates = RequiredpriceList?.productRates;
      
      const updatedRows = [...rows]; // Create a shallow copy of rows array
    
      // Loop through each row and update the price if a matching product rate is found
      rows.forEach((row, index) => {
        const requireproductRate = productRates?.find(p => p.price_list_item_id === row?.item_id);
        
        if (requireproductRate) {
          const custom_price = parseFloat(requireproductRate?.pricing?.custom_rate) || 0;
          const discount = parseFloat(requireproductRate?.pricing?.discount) || 0;
          const discountPrice = (discount / 100) * custom_price;
          const finalPrice = custom_price - discountPrice;
          
          updatedRows[index].price = finalPrice;
          updatedRows[index].price_list_id = id;
        }
      });
    
      // Set the updated rows
      setRows(updatedRows);
      
      // Set the allRowsSelectedPriceList with the option for all rows
      const TotalLengthOfItemsInrows = rows.length;
      const allRowsSelectedPriceList = new Array(TotalLengthOfItemsInrows).fill(option);
    
      setAllRowsSelectedPriceList(allRowsSelectedPriceList);
    }
    
  }

  const handleTaxSelect = (option) => {
    setSelectedTax(option);
  };

  const handleQuoteTypeChange = (newQuoteType) => {
    //console.log(newQuoteType)
    setSelectedQuoteType(newQuoteType);
    const newRow = { item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',}, measured_area: '', quantity: '', UOM: '', price: '', tax: '', total: '', designs: [] };
    setRows([ newRow]);
  };
  
  const handleInputChange = (value, index, key, selling_price) => {
    // console.log(selling_price)
     const updatedData = [...rows];
     
     // Update the specific key in the row data
     updatedData[index][key] = value;
   
     // If the key is 'quantity', calculate the total and update the 'amount' in the same row
     if (key === 'quantity') {
       const total = parseFloat(value) * parseFloat(selling_price);
       console.log(total)
     /*   updatedData[index].amount = isNaN(total) ? "" : total; // Handle potential NaN values
   
       // Update the amounts array if needed (optional)
       const newAmounts = [...amounts];
       newAmounts[index] = updatedData[index].amount;
       setAmounts(newAmounts); */
       const totaltaxOfproduct = calculateTaxAmount(updatedData[index]?.tax || 0 , selling_price, value);
       updatedData[index].total_tax_amount = totaltaxOfproduct;
       updatedData[index].total = total;
     }
   
     // Update the rows state
     setRows(updatedData);
   };
  

  const handleClosePopup = () => {
    setshowDesignSelectPopup(false);
    setshowWireSelectPopup(false);
  }

  const handleSelectDoorsAndWindowsBreakUpPopup = (index,) => {
    setShowDoorsAndWindowsBreakUpPopup(true);

      fetchBreakUpOfDoorsOrWindows()
console.log(rows);
  };

  const handleCloseDoorsAndWindowsBreakUpPopup = () => {
    setShowDoorsAndWindowsBreakUpPopup(false);
  }


  const fetchBreakUpOfDoorsOrWindows = async () => {
     try {
       const token = sessionStorage.getItem('token');
       const response = await fetch(`${FetchProjectsUrl}/project-reports-production/get-computed-measurements-of-project-as-array?projectId=${selectedProjectId}&productCategoryId=${selectedProductCategory.value}`, {
     
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
           'x-token': token
         }
       });
       console.log(response)
       const data = await response.json();
       
       if (response.ok) {
          console.log(data);
         setBreakUp(data);
 
       } else {
         console.error('Failed to fetch projects:', response.status, response.statusText);
       }
     } catch (error) {
       console.error('Failed to fetch projects:', error);
     } 
   };


  const handleSelectDesign = (design) => {
    const updatedRows = [...rows];
    const selectedDesigns = updatedRows[SelectedItemRowIndex].selectedDesigns || [];
  
    const designIndex = selectedDesigns.findIndex((d) => d.sub_item_id === design.sub_item_id);
  
    if (designIndex !== -1) {
      // Design is already in the array, remove it
      selectedDesigns.splice(designIndex, 1);
    } else {
      // Design is not in the array, add it
      selectedDesigns.push({ ...design, quantity: 1 }); // Default quantity set to 1 when adding
    }
  
    updatedRows[SelectedItemRowIndex].selectedDesigns = [...selectedDesigns];
    setRows(updatedRows); // Trigger a state update
  };
  
  
  const handleQuantityChangeForDesign = (subItemId, index, quantity) => {
    const updatedRows = [...rows];
    const selectedDesigns = updatedRows[SelectedItemRowIndex].selectedDesigns || [];
  
    updatedRows[SelectedItemRowIndex].selectedDesigns = selectedDesigns.map((item) =>
      item.sub_item_id === subItemId ? { ...item, sub_item_quantity: quantity } : item
    );
  
    setRows(updatedRows); // Trigger a state update
  };


  const handleCustomerSelect = (option) => {
    setSelectedProject(null); // Reset the selected project
    setSelectedProjectId(null); // Reset the selected project ID
    setSelectedProductCategory(null); // Reset the selected product category
    setProductCategories([]); 
    fetchProductCategories();
    setSelectedCustomer(option); // Set the selected option object
  };
  
 

const handleRowPriceListSelect = (index, option, item) => {
    const id = option?.value;
    const RequiredpriceList = priceLists.find(p => p.priceList._id === id);
    const productRates = RequiredpriceList?.productRates;
    const requireproductRate = productRates?.find(p => p.price_list_item_id === item?.item_id);
  
    const custom_price = parseFloat(requireproductRate?.pricing?.custom_rate) || 0;
    const discount = parseFloat(requireproductRate?.pricing?.discount) || 0;
    const discountPrice = (discount / 100) * custom_price;
    const finalPrice = custom_price - discountPrice;
  
    const updatedRows = [...rows]; // Use spread operator for correct shallow copy of rows array
  
    if (requireproductRate) {
      updatedRows[index].price = finalPrice;
      updatedRows[index].price_list_id = id;
      updatedRows[index].total= updatedRows[index].quantity * finalPrice
      setRows(updatedRows);
    }else{
      const itemData = products.find(p=>p.item_id===item?.item_id)
      updatedRows[index].price = itemData?.selling_price;
      updatedRows[index].price_list_id = id;
      setRows(updatedRows);
    }
  
    setAllRowsSelectedPriceList((prev) => {
      const newState = [...prev];
      newState[index] = option;
      return newState;
    });
  };
  
  const handleProductCategorySelect = (selectedOption) => {
    
    //console.log(selectedOption)
    fetchAllPriceLists(selectedOption);
    setSelectedProductCategory(selectedOption);
    setSelectedProject(null); // Reset the selected project
    setSelectedProjectId(null); // Reset the selected project ID
    fetchProductsbyProductCategoryID(selectedOption.value);
    fetchAttributesByproductCategory(selectedOption.value)
    fetchAllprojectsbyCustomer(selectedCustomer?.id);
    const newRow = { item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',}, measured_area: '', quantity: '', UOM: '', unit_price: '', tax: '', total: '', designs: [] };
    setRows([newRow]);
    const index = 0
    const option = {label: '' , value: ''}
    handleItemNameChange(index ,option)
  };
  
  const handleProjectSelect = (selectedOption) => {
    setSelectedProject(selectedOption);
    setSelectedProjectId(selectedOption.id);
  };


  const addRow = () => {
    const newRow = { item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',}, measured_area: '', quantity: '', UOM: '', price: '', tax: '', total: '', sub_items: [] };
    setRows([...rows, newRow]);
    if(selectedPricList){
      setAllRowsSelectedPriceList([...allRowsSelectedPriceList, selectedPricList])
    }
  };

  const deleteRow = (index) => {
    const updatedData = [...rows];
    updatedData.splice(index, 1);
    setRows(updatedData);
    if(selectedPricList){
     const updatedRowPriceLists = [...allRowsSelectedPriceList]
     updatedRowPriceLists.splice(index, 1);
     setAllRowsSelectedPriceList(updatedRowPriceLists)
    }
  };
  
 /*  const handleUomChange = (index, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    const uomid= selectedOption?selectedOption.id:null
      const updatedData = [...rows];
      updatedData[index].UOM = value;
      updatedData[index].UOM_id = uomid;
      //setData(updatedData);
      setRows(updatedData)
  
  }; */

 

  const handleItemNameChange = (index, selectedOption) => {
    console.log("selected item:", selectedOption);
    const updatedRows = [...rows];
    const selectedItem = products.find(product => product.item_id === selectedOption.value) || null;
    const rowPriceListItem = allRowsSelectedPriceList[index];
  
    console.log(allRowsSelectedPriceList);
  
    if (selectedItem) {
      updatedRows[index].item_id = selectedItem?.item_id;
      updatedRows[index].item_name = selectedItem?.item_name;
      updatedRows[index].item_image = selectedItem?.item_image;
      updatedRows[index].cost_price = selectedItem?.cost_price;
      updatedRows[index].selectedDesigns = [];
  
      if (rowPriceListItem) {
        const id = rowPriceListItem?.value;
        const RequiredpriceList = priceLists.find(p => p.priceList._id === id);
        const productRates = RequiredpriceList?.productRates;
        const requireproductRate = productRates.find(p => p.price_list_item_id === selectedItem?.item_id);
        const custome_price = parseFloat(requireproductRate?.pricing?.custom_rate);
        const discount = parseFloat(requireproductRate?.pricing?.discount) || 0;
        const discounPrice = (discount / 100) * custome_price;
        const finalPrice = custome_price - discounPrice;
  
        if (requireproductRate) {
          updatedRows[index].price = finalPrice;
          updatedRows[index].price_list_id = id;
        } else {
          updatedRows[index].price = selectedItem.selling_price;
        }
      } else if (selectedItem.selling_price) {
        console.log('selling price');
        updatedRows[index].price = selectedItem.selling_price;
      } else {
        console.log('');
      }
  
      const product = products.find(p => p.item_id === selectedItem.item_id);
      const selectedUom = UomOptions.find(p => p?.id === product?.UOM_id);
      updatedRows[index].UOM = selectedUom?.value;
      updatedRows[index].UOM_id = selectedUom?.id;
  
      // Apply conversion factor logic
      if (selectedQuoteType.value === 'Group') {
        const product_category = productCategories.find(p => p._id === selectedProductCategory.value);
        const category_mapping_id = parseFloat(product_category?.category_mapping_id);
  
        if (category_mapping_id === 3 && 
          (updatedRows[index].UOM === 'No' || updatedRows[index].UOM === 'Nos')) {
        // Use count as quantity for Windows with UOM as No or Nos
        handleInputChange(updatedRows[index].application_area.count, index, 'quantity', product?.selling_price);
        console.log(updatedRows[index].quantity);
          }

        if (category_mapping_id === 1) {
          const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
  
          let conversionFactor = 0;
          if (selectedItem.attribute_value_ids) {
            for (const id of selectedItem.attribute_value_ids) {
              const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
              if (attributevalue) {
                conversionFactor = parseFloat(attributevalue.attribute_value);
                break;
              }
            }
  
            if (conversionFactor > 0) {
              const currentArea = parseFloat(updatedRows[index].application_area.area) || 0;
              const quantity = Math.ceil(currentArea / conversionFactor);
              updatedRows[index].quantity = quantity;
            }
          }
        }
      }
  
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index].sub_items = selectedItem.sub_items;
        const updatedSubItems = selectedItem.sub_items;
/*         setSubItems({
          subItems: updatedSubItems,
          UOM: selectedUom?.value,
          UOM_id: selectedItem.UOM_id
        }); */
        return updatedRows;
      });
  
      setRows(updatedRows);
      calculateTotal(updatedRows);
    }
  };

 
  const handleAreaChange = (index, selectedOptionOrInputValue) => {
    const updatedData = [...rows];
    const oldAreaName = updatedData[index].application_area.area_name;

    if (typeof selectedOptionOrInputValue === 'string') {
      // This handles the case when only the input value changes
      updatedData[index].application_area.area_name = selectedOptionOrInputValue;
    } else {
      // This handles the case when an option is selected
      const value = selectedOptionOrInputValue ? selectedOptionOrInputValue.area : '';
      const areaName = selectedOptionOrInputValue ? selectedOptionOrInputValue.areaName : '';
      const count = selectedOptionOrInputValue ? selectedOptionOrInputValue.count : '';
  
      updatedData[index].application_area.area_name = areaName;
      updatedData[index].application_area.area = value;
      updatedData[index].application_area.count = count;
    }
  
    setRows(updatedData);
  
    setSelectedApplicationAreas(prevSelected => {
      const newSelected = {...prevSelected};
      if (oldAreaName) delete newSelected[oldAreaName];
      if (selectedOptionOrInputValue && typeof selectedOptionOrInputValue !== 'string') {
        const newAreaName = selectedOptionOrInputValue.areaName;
        newSelected[newAreaName] = true;
  
        const category_mapping_id = parseFloat(productCategories.find(p => p._id === selectedProductCategory.value)?.category_mapping_id);
  
        if (category_mapping_id === 3) { // Windows
          if (newAreaName === 'Bedroom Windows Area') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Bedroom') && key !== 'Bedroom Windows Area') delete newSelected[key];
            });
          } else if (newAreaName === 'Ventilators Area') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Bathroom') && key !== 'Ventilators Area') delete newSelected[key];
            });
          }
        } else if (category_mapping_id === 2) { // Doors
          if (newAreaName === 'Bedroom Doors Area') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Bedroom') && key !== 'Bedroom Doors Area') delete newSelected[key];
            });
          } else if (newAreaName === 'Bathroom Doors Area') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Bathroom') && key !== 'Bathroom Doors Area') delete newSelected[key];
            });
          }
        } else if (category_mapping_id === 1) { // Tiles
          if (newAreaName === 'Balcony Flooring') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Balcony') && key !== 'Balcony Flooring') delete newSelected[key];
            });
          } else if (newAreaName === 'Total Bathroom Walls') {
            Object.keys(newSelected).forEach(key => {
              if (key.includes('Bathroom') && key.includes('Wall') && key !== 'Total Bathroom Walls') delete newSelected[key];
            });
          } else if (newAreaName === 'Total Bathroom Flooring') {
            Object.keys(newSelected).forEach(key => {
              if (key.includes('Bathroom') && key.includes('Flooring') && key !== 'Total Bathroom Flooring') delete newSelected[key];
            });
          }
        }
      }
      return newSelected;
    });

    const product = products.find(p => p.item_id === updatedData[index].item_id);
  
    if (selectedQuoteType.value === 'Group') {
      const product_category = productCategories.find(p => p._id === selectedProductCategory.value);
      const category_mapping_id = parseFloat(product_category?.category_mapping_id);
  
      if (category_mapping_id === 3 && 
        (updatedData[index].UOM === 'No' || updatedData[index].UOM === 'Nos')) {
      // Use count as quantity for Windows with UOM as No or Nos
      handleInputChange(updatedData[index].application_area.count, index, 'quantity', product?.selling_price);
      console.log(updatedData[index].quantity);
        }
      else if  (category_mapping_id === 1) {
        const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
  
        let conversionFactor = 0;
        if (product && product.attribute_value_ids) {
          for (const id of product.attribute_value_ids) {
            const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
            if (attributevalue) {
              conversionFactor = parseFloat(attributevalue.attribute_value);
              break;
            }
          }
  
          if (conversionFactor > 0) {
            const quantity = Math.ceil((parseFloat(updatedData[index].application_area.area) || 0) / conversionFactor);
            handleInputChange(quantity, index, 'quantity', product?.selling_price);
          } else {
            handleInputChange(updatedData[index].application_area.area, index, 'quantity', product?.selling_price);
          }
        } else {
          handleInputChange(updatedData[index].application_area.area, index, 'quantity', product?.selling_price);
        }
      } else {
        handleInputChange(updatedData[index].application_area.area, index, 'quantity', product?.selling_price);
      }
    }
  };
 
  const handleAreaValueChange = (value, index, key, item_id) => {
    const updatedRows = [...rows];
    const [parentKey, childKey] = key.split('.');
    updatedRows[index][parentKey][childKey] = value;
    setRows(updatedRows);
    const product_category = productCategories.find(p => p._id === selectedProductCategory.value);
    const category_mapping_id = parseFloat(product_category?.category_mapping_id);

    const product = products.find(p => p.item_id === item_id);
    if (category_mapping_id === 1) {
      const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
     // console.log(attributeValues)
      let conversionFactor = 0;
      if (product && product.attribute_value_ids) {
        for (const id of product.attribute_value_ids) {
          const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
          if (attributevalue) {
            conversionFactor = parseFloat(attributevalue.attribute_value);
            break; // Stop iterating once we find a match
          }
        }

        if (conversionFactor > 0) {
          const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
          //console.log(quantity)
          handleInputChange(quantity, index, 'quantity', product?.selling_price);
        } else {
          handleInputChange(value, index, 'quantity', product?.selling_price);
        }
      } else {
        handleInputChange(value, index, 'quantity', product?.selling_price);
      }
    } else {
      handleInputChange(value, index, 'quantity', product?.selling_price);
    }
  }
  
  const handleAmountInputChange = (e, index, fieldName) => {
    const { value } = e.target;
    
    // Assuming you have a state like `updatedRows` that holds your table data
    const updatedRows = [...rows]; 
  
    updatedRows[index] = {
      ...updatedRows[index],
      [fieldName]: value
    };
  
    // Set the updated rows back to state
    setRows(updatedRows);
    recalculateAmounts();
  };

  const recalculateAmounts = () => {
    const updatedAmounts = rows.map(row => {
      const sellingPrice = products.find(p => p.item_id === row.item_id)?.selling_price || 0;
      return row.quantity * sellingPrice;
    });
  
    setAmounts(updatedAmounts);
  };

  
useEffect(() => {
  if (Quotation && Quotation.discount !== undefined) {
    setDiscountPercentage(Quotation.discount);
  } else {
    setDiscountPercentage(0);
  }

  if (Quotation && Quotation.adjustment !== undefined) {
    setAdjustment(typeof Quotation.adjustment === 'string'
      ? parseFloat(Quotation.adjustment)
      : Quotation.adjustment);
      setAmendedPrice(typeof Quotation.adjustment === 'string'
        ? parseFloat(Quotation.adjustment)
        : Quotation.adjustment);
  } else {
    setAdjustment(0); // Default to 0 if adjustment is not present
  }

  recalculateAmounts();
}, [rows, products]); // Dependencies: rows or products change
  

  

useEffect(()=>{
  calculateSubtotal()
  calculateTax()
  
   },[rows])
  
   const calculateSubtotal = () => {
    let subtotal = 0;
  
    rows.forEach(row => {
      const quantity = parseFloat(row?.quantity) || 0;
      const price = parseFloat(row?.price) || 0;
      const rowTotal = quantity * price;
      subtotal += rowTotal;
    });
   setSubTotal(subtotal)
    return subtotal;
  };
  
const calculateTotal = () => {
  const subtotal = calculateSubtotal() || 0; // Get the subtotal from the existing function
  const discount = (amendedDiscount / 100) * subtotal || 0; // Calculate the discount based on the amendedDiscount percentage
  const tax_amount = calculateTax() || 0; // Calculate the tax amount using the existing function
 //console.log(tax_amount)
  // Parse amendedPrice to ensure it's a valid number
  const parsedAmendedPrice = parseFloat(amendedPrice) || 0;

  // Adjust the subtotal based on amended price
  const adjustedSubtotal = subtotal - discount + parsedAmendedPrice;

  let total = adjustedSubtotal; // Start with the adjusted subtotal

  if (selectedTax) {
    if (selectedTax.value === 'Tax Exclusive') {
      total += tax_amount; // Add tax if it's exclusive
    }
    // For 'Tax Inclusive', no need to adjust since total is already calculated
  } else {
    // If selectedTax is not defined, assume tax is exclusive
    total += tax_amount;
  }

  return total;
};

useEffect(() => {
  const calculatedTotal = calculateTotal();
  setTotal(calculatedTotal);
}, [rows, amendedDiscount, amendedPrice, selectedTax]);

const calculateTax = () => {
  let TotalTax = 0;
//console.log(rows);
  rows.forEach((p) => {
    const price = parseFloat(p.price) || 0;  // Handle missing or invalid price
    const taxRate = parseFloat(p.tax) || 0;  // Handle missing or invalid tax rate
    const quantity = parseFloat(p.quantity) || 0;  // Handle missing or invalid quantity

    const tax = (taxRate / 100) * price;
    const SubTotalTax = tax * quantity;

    TotalTax += SubTotalTax;
  });
//console.log(TotalTax)
  return TotalTax;
};

const handleTaxChange = (index, selectedOption, value, price, quantity) => {
  if (selectedOption) {
      // Check if all parameters are correct
      //console.log("Parameters:", { index, selectedOption, value, price, quantity });

      // Calculate tax amount
      const totaltaxOfproduct = calculateTaxAmount(value, price, quantity);

      // Log the calculated tax amount to verify it's correct
      //console.log("Calculated Tax Amount:", totaltaxOfproduct);

      // Create a deep copy of rows with the updated tax and total_tax_amount fields
      const updatedRows = rows.map((row, idx) =>
          idx === index
              ? {
                  ...row,
                  tax: value,
                  tax_type: selectedOption?.label,
                  total_tax_amount: totaltaxOfproduct
              }
              : row
      );

      // Log the updated rows to see if the new value is being set correctly
     // console.log("Updated Rows:", updatedRows);

      // Set the updated rows to state
      setRows(updatedRows);

      // Check if the state is being updated correctly
      //console.log("State after update:", updatedRows);
  }
};


  const calculateTaxAmount = (taxRate, price, quantity) => {
    const tax = parseFloat(taxRate) || 0;
    const sellingPricePerQuantity = parseFloat(price) || 0;
    const totalQuantity = parseFloat(quantity) || 0; // Ensure quantity is a number
    const taxAmount = parseFloat(((tax / 100) * sellingPricePerQuantity) * totalQuantity) || 0;
   // console.log(taxAmount)
    return taxAmount;
  };

  const calculateTotalTaxAmount = () => {
   // console.log(rows)
    let totalTaxAmount = 0;
  
    if (Array.isArray(rows)) {
      rows.forEach((row) => {
       // console.log(row)
        totalTaxAmount += row?.total_tax_amount || 0;
      });
    }
   
    return totalTaxAmount.toFixed(2); // Update the total tax amount state
    
  };
  
  

  const calculateEachProductTotal = (discount, sellingPrice, quantity) => {
    console.log(discount, sellingPrice, quantity);
    // Ensure sellingPrice and quantity are numbers
    const price = parseFloat(sellingPrice) || 0;
    const qty = parseFloat(quantity) || 0;
    
    // Calculate the initial total without discount
    let total = price * qty;
  
    // If the discount is provided and is a valid number, apply it
    if (discount && !isNaN(discount) && parseFloat(discount) > 0) {
      total = total * (1 - parseFloat(discount) / 100);
    }
    console.log(total);
    return total;
  };


const handleTheItemsData = () => {
  const data = rows.map((p) => {
    console.log(p)
    const itemData = {
      item_name: p?.item_name,
      item_id: p?.item_id,
      quantity: p?.quantity || 0,
      count:selectedProductCategory?.label === 'Doors' ? p?.application_area?.count || 0 :selectedProductCategory?.label === 'Windows' ? p?.application_area?.count || 0 : 0,
      cost_price:  p?.cost_price||0,
      selling_price: p?.price || 0,
      discount: parseFloat(p?.discount) || 0,
      tax_rate: parseFloat(p?.tax) || 0,
      tax_type: p?.tax_type,
      total_tax_amount:p?.total_tax_amount || 0,
      tax_amount: (parseFloat(p?.price) * parseFloat(p?.tax / 100)) * parseFloat(p?.quantity) || 0,
      sales_total: calculateEachProductTotal(p?.discount, p?.price, p?.quantity) || 0,
      quantity_breakup: p?.quantity_breakup || {},
      UOM: p?.UOM || '',
      UOM_id: p?.UOM_id,
      selected_designs: p?.selected_designs || [],
    };   
    if (p.price_list_id) {
      itemData.price_list_id = p.price_list_id;
    }
    if (p.sub_items && 
      p.sub_items.length > 0) {
    
      const subItems = p.selectedDesigns
        .map((item) => {
         
              return {
                sub_item_name: item.sub_item_name,
                sub_item_id: item?.sub_item_id,
                sub_item_quantity: item?.sub_item_quantity,
              };
            
         
        })
    
      itemData.required_order_breakup = subItems;
    }
    

    if (selectedQuoteType?.value === 'Group') {
      itemData.application_areas = p?.application_area ;
      //itemData.selected_designs = [...p?.selected_designs];
    }

    return itemData;
  });

  return data;
};



const handleSaveSalesOrderData = async (attempt = 1) => {
  setIsSaving(true);

  try {
    const deliveryChallanData = {
      customer_id: selectedCustomer?.id,
      product_category_id: selectedProductCategory?.value,
      sales_order_id: selectedSalesOrder?.id,
      subtotal: await calculateSubtotal(),
      tax_amount: await calculateTax(),
      total: await calculateTotal(),
      tax_preference: selectedTax?.value,
      items: await handleTheItemsData()
    };

    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}customer/delivery-challan/create-delivery-challan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify(deliveryChallanData)
    });

    const data = await response.json();
    const output = await handleSaveResponse(response, data);

    if (output) {
      setTimeout(() => {
        setShowPopup(false);
        navigate('/bb/app/deliverychallans/deliverychallanslist');
      }, 2000);
    } else {
      throw new Error(data.message || 'Failed to create delivery challan');
    }
  } catch (error) {
    console.error('Failed to create delivery challan:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => handleSaveSalesOrderData(attempt + 1), 1000);
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleSaveSalesOrderData(attempt));
    }
  } finally {
    setIsSaving(false);
  }
};

  const formatNumber = (value) => {
    // Parse the input as a number
    const parsedValue = parseFloat(value);
  
    // Check if the parsed value is a finite number
    if (Number.isFinite(parsedValue)) {
      // Round to two decimal places
      const roundedValue = (Math.round(parsedValue * 100) / 100);
      return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
    }
  
    // Return '0.00' if the value is not a valid number
    return '0.00';
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleStayHere = () => {
    setShowCancelPopup(false);
  }

  const handleCancel = () => {
    setShowCancelPopup(true);
  }

  const handleLeave = () => {
    navigate(-1);
  }

  if (isLoading) {
    return <Loader />;
  }

  const handleExportAsExcel = () => {
    if (Array.isArray(breakUp) && breakUp.length > 0) {
      // Prepare data for the Excel sheet
      const exportData = breakUp.map(row => {
 // Split the UIC by '-' and get the third part for area name matching
 const uicParts = row[0].split('-');
 const uicAreaNamePart = uicParts.length > 2 ? uicParts[2] : '';
console.log(uicAreaNamePart)
 // Find a row in `rows` where the processed area_name matches the third part of UIC
 const matchedRow = rows.find(rowItem => {
   // Extract and process the first word of area_name
/*       const areaNameFirstWord = rowItem.application_area.area_name.split(' ')[0];
   const isLastCharNumber = !isNaN(areaNameFirstWord.slice(-1));
   const processedAreaName = isLastCharNumber
     ? areaNameFirstWord.substring(0, 3) + areaNameFirstWord.slice(-1)  // Use the first 3 characters + last number
     : areaNameFirstWord.substring(0, 3); // Otherwise, use first 3 characters
     console.log(processedAreaName)
   return processedAreaName === uicAreaNamePart; */
// Extract the first word from area_name
const areaNameFirstWord = rowItem.application_area.area_name.split(' ')[0];

// Check if the first word ends with a number
const lastChar = areaNameFirstWord.slice(-1);
const isLastCharNumber = !isNaN(lastChar);

// Extract the first three letters of the first word for comparison
const areaNameFirstThreeLetters = areaNameFirstWord.substring(0, 3);

// Determine the processed area name based on the presence of a number
let processedAreaName;
if (isLastCharNumber) {
// If the first word ends with a number, use the first three characters plus the number
processedAreaName = areaNameFirstThreeLetters + lastChar;
} else {
// If there's no number, use only the first three characters
processedAreaName = areaNameFirstThreeLetters;
}

// Compare processed area name with uicAreaNamePart
const uicAreaNamePartFirstThree = uicAreaNamePart.substring(0, 3);
let isMatch;

// Matching logic
if (isLastCharNumber) {
// Exact match if areaNameFirstWord ends with a number
isMatch = uicAreaNamePart === processedAreaName;
} else {
// Match first three characters if areaNameFirstWord does not end with a number
isMatch = uicAreaNamePartFirstThree === processedAreaName;
}

// Log the results for debugging
console.log("Processed Area Name:", processedAreaName); // Expected: "Bed1", "Bat2", etc.
console.log("UIC Area Name Part:", uicAreaNamePart);    // Part of input for matching
console.log("Is Match:", isMatch);                      // Matching result

// Return whether there's a match
return isMatch;


 });

  
        // Create a row including matchedRow data if available
        return [
          row[0], // UIC
          row[1], // Height
          row[2], // Width
          row[3], // Area
          matchedRow ? matchedRow.item_name : 'N/A', // Item Group (Item Name)
        ];
      });
  
      // Create a new workbook and worksheet
      const ws = XLSX.utils.aoa_to_sheet([
        ["UIC", "Height", "Width", "Area", "Item Code"], // Header row
        ...exportData // Data rows
      ]);
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "BreakUp");
  
      // Generate Excel file
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(data);
  
      // Create a link element and trigger a download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'break_up_data.xlsx';
      a.click();
  
      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    } else {
      alert('No data available to export');
    }
  };
  



/*   const updateBreakUp = () => {
    console.log(breakUp)
    // Check if setBreakUp is an array
    if (!Array.isArray(breakUp)) {
        console.error("setBreakUp is not an array:", breakUp);
        return;
    }

    const updatedBreakUp = breakUp.map((breakUp) => {
        const [id, height, width, area, frameType] = breakUp;

        // Extract room code dynamically
        const roomCode = id.split("-")[2]; // "Liv", "Kit", etc.

        // Find corresponding room name
        const roomName = Object.keys(setAreaMeasurementsData).find(room => room.includes(roomCode));
        
        // Find corresponding item in setRows using the room name
        const correspondingItem = setRows.find((row) => row.item_name === roomName);

        if (correspondingItem) {
            const { selling_price, tax, amount } = correspondingItem;

            // Use the item data to update the breakUp
            return [
                id,
                height,
                width,
                area, // Update this if needed based on your logic
                frameType,
                selling_price,
                tax,
                amount
            ];
        }

        return breakUp; // Return the original if no match found
    });

    console.log(updatedBreakUp);
    // In React, you would call setBreakUp(updatedBreakUp) here
}; */

return (
  <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
    <div className="flex flex-row w-full">
      <div className="w-full flex-1">
        <div className="flex flex-col w-full h-full justify-center items-center">
          <div className="w-full h-full flex flex-col justify-between">
            <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-4 items-center">
                <h1 className="text-lg font-semibold">Edit Delivery Challan</h1>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
              <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="customername" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Customer Name<span className="text-red-500">*</span>
                      </label>
                      <div className='w-1/3'>
                        <CustomDropdown
                          options={customers}
                          selected={selectedCustomer}
                          onSelect={handleCustomerSelect}
                          placeholder='Select Customer'
                          showAddNew={false}
                          showSearch={true}
                        />
                      </div>
                    </div>
                   
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Item Category<span className="text-red-500">*</span>
                      </label>           
                      <div className='w-1/3'>
                        <CustomDropdown
                          options={productCategories.map(category => ({
                            value: category._id,
                            label: category.product_category_name
                          }))}
                          selected={selectedProductCategory}
                          onSelect={handleProductCategorySelect}
                          placeholder="Select Item Category"
                          showAddNew={false}
                          showSearch={true}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Sales Order Number<span className="text-red-500">*</span>
                      </label>
                      <div className='w-1/3'>
                        <CustomDropdown
                          options={salesOrders}
                          selected={selectedSalesOrder}
                          onSelect={handleSalesOrderSelect}
                          placeholder='Select Sales Order'
                          showAddNew={false}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="w-full container mx-auto px-4 mb-2 mt-4">
  <div className='border border-gray-300 rounded-md bg-[#fafafc]'>
  <h2 className="text-lg font-semibold py-1 px-2">Item Table</h2>
  </div>
  <table className="w-full">
  <thead>
    <tr className="font-semibold">
      <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/3">Item Details</th>
      <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/3">Quantity</th>
      <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/3">UOM</th>
    </tr>
  </thead>
  <tbody>
    {rows.map((item, index) => (
      <tr key={index}>
        <td className="border-r border-b px-2 py-2">
          <TableSearchDropDown
            options={products.map(product => ({
              value: product.item_id,
              label: product.item_name,
            }))}
            value={item.item_name}
            onChange={(selectedOption) => handleItemNameChange(index, selectedOption)}
          />
        </td>
        <td className="border-r border-b px-2 py-2">
          <input
            type="text"
            value={item.quantity}
            onChange={(e) => handleInputChange(e.target.value, index, 'quantity')}
            className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
          />
        </td>
        <td className="border-r border-b px-2 py-2">
          <input
            type="text"
            value={item.UOM}
            readOnly
            className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
          />
        </td>
      </tr>
    ))}
  </tbody>
</table>


</div>
              </div>
              </div>
              <div className="mt-auto w-full">
              <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                <button 
                  className="px-2 py-1 bg-[#f7525a] text-white border border-[#DDDDDD] rounded-md"
                  onClick={handleSaveSalesOrderData}
                >
                  Save as Draft {isSaving && <Spinner />}
                </button>
                <button 
                  onClick={handleCancel}  
                  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
                    isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
                  }`}
                  disabled={isSaving}
                >
                  Cancel
                </button>
              </div>
            </div>
              </div>
              {/* <div className='pt-2 px-6 py-6 flex flex-row justify-between w-full'>
              <div class="flex flex-col">

                <div><button  onClick={addRow} className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'><FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1"  />
                Add New Row</button></div> 
</div>     
 </div> */}
            
           
          </div>
          </div>

{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}
    {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
{/* popup ends */}
</div>
  );
};

export default EditDeliveryChallans;
