import React from 'react';
import { useState, useRef } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
          callback();
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => {
          document.removeEventListener('click', handleClick);
      };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect,showAddNew }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null);
  
    const filteredOptions = options.filter(option => {
      if (!option || !option.label) return false;
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  
    const handleOptionClick = (value, label, id) => {
      onSelect(value, label, id);
      setIsOpen(false);
      setFocusedOptionIndex(null);
    };
  
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'Enter':
          e.preventDefault();
          if (focusedOptionIndex !== null) {
            const selectedOption = filteredOptions[focusedOptionIndex];
            handleOptionClick(selectedOption.value, selectedOption.label, selectedOption.id);
          }
          break;
        default:
          break;
      }
    };
  
    const scrollToOption = (index) => {
      const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
      if (optionElements && optionElements[index]) {
        optionElements[index].scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    };
  
    useOutsideClick(dropdownRef, () => setIsOpen(false));
    useEffect(() => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isOpen]);
  
    return (
      <div className="relative w-1/3" ref={dropdownRef}>
        <div
          className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
            {selected || 'Select '}
          </span>
          <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
        </div>
        {isOpen && (
          <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex p-1 flex-col">
            <input
              ref={searchInputRef}
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
            <div className="overflow-y-auto scrollbar-visible flex-grow">
              {filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option.value, option.label, option.id)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))}
            </div>
                    {showAddNew && (
                      <div
                          className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
                          onClick={() => handleOptionClick('add-new', 'Add New')}
                      >
                          {options.find(option => option.value === 'add-new')?.icon && (
                              <FontAwesomeIcon
                                  icon={options.find(option => option.value === 'add-new').icon}
                                  className="mr-2"
                              />
                          )}
                          {options.find(option => option.value === 'add-new')?.label}
                      </div>
                  )}
              </div>
          )}
      </div>
  );
};

const states = [
  { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
  { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
  { value: 'Assam', label: 'Assam' },
  { value: 'Bihar', label: 'Bihar' },
  { value: 'Chhattisgarh', label: 'Chhattisgarh' },
  { value: 'Goa', label: 'Goa' },
  { value: 'Gujarat', label: 'Gujarat' },
  { value: 'Haryana', label: 'Haryana' },
  { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
  { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
  { value: 'Jharkhand', label: 'Jharkhand' },
  { value: 'Karnataka', label: 'Karnataka' },
  { value: 'Kerala', label: 'Kerala' },
  { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
  { value: 'Maharashtra', label: 'Maharashtra' },
  { value: 'Manipur', label: 'Manipur' },
  { value: 'Meghalaya', label: 'Meghalaya' },
  { value: 'Mizoram', label: 'Mizoram' },
  { value: 'Nagaland', label: 'Nagaland' },
  { value: 'Odisha', label: 'Odisha' },
  { value: 'Punjab', label: 'Punjab' },
  { value: 'Rajasthan', label: 'Rajasthan' },
  { value: 'Sikkim', label: 'Sikkim' },
  { value: 'Tamil Nadu', label: 'Tamil Nadu' },
  { value: 'Telangana', label: 'Telangana' },
  { value: 'Tripura', label: 'Tripura' },
  { value: 'Uttarakhand', label: 'Uttarakhand' },
  { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  { value: 'West Bengal', label: 'West Bengal' },
]; 


function Address({ address, city, state, pincode, onChange, updateAddressState }) {
  const [statesOptions] = useState(states);

  return (
    <div className="flex flex-col justify-start items-start w-full flex-grow ">
    <div className="w-full flex flex-col mb-4 mt-6 ">
      {/* Address Label and Textarea */}
      <div className="w-full flex flex-row items-center mb-4 space-x-2">
                    <label htmlFor="address" className="block text-sm font-regular text-gray-700 w-1/6">Address</label>
                    <textarea
                      id="address"
                      name="address"
                      value={address}
                      onChange={onChange}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          document.getElementById('city').focus();
                        }
                      }}
                      className="mt-1 w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                  </div>
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label htmlFor="city" className="block text-sm font-regular text-gray-700 w-1/6">City</label>
        <input
          type="text"
          id="city"
          name='city'
          value={city}
          onChange={onChange}
          className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        />
      </div>
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label htmlFor="state" className="block text-sm font-regular text-gray-700 w-1/6">State</label>
        <CustomDropdown
        options={statesOptions}
        selected={state}
        onSelect={updateAddressState} 
      />
      </div>
        <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label htmlFor="pincode" className="block text-sm font-regular text-gray-700 w-1/6">Pincode</label>
        <input
          type="text"
          id="pincode"
          name='pincode'
          value={pincode}
          onChange={onChange}
          className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        />
      </div>
    </div>
    </div>
  );
}

export default Address;
