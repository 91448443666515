import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
//import logoImage from "./images/BB.png";
import { useNavigate, useLocation } from 'react-router-dom';

function OTPpage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [otp, setOtp] = useState(['', '', '', '', '']);
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(60); // Timer starts at 45 seconds
  const inputRefs = useRef([]);
  const verifyOTP = process.env.REACT_APP_FORE_BRICKBUCKET + 'customer/verify-otp-and-login';
  const ConsumerLogin = process.env.REACT_APP_FORE_BRICKBUCKET + 'customer/customer-login';

  const { mobileNumber } = location.state;
  useEffect(() => {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      setUserData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    // Countdown timer effect
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clean up interval on component unmount or when timer reaches 0
    return () => clearInterval(interval);
  }, []); // <-- Dependencies array is empty to run effect only once on mount

  useEffect(() => {
    // Reset timer when it reaches 0
    if (timer === 0) {
      setTimer(60); // Reset timer to 60 seconds
    }
  }, [timer]); // <-- Run effect whenever `timer` changes

  
  const handleChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== '' && index < 4) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleOTP = async () => {
    if (mobileNumber.length === 10) {
        try {
            const res = await fetch(ConsumerLogin, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile_number: mobileNumber }),
            });
            console.log(mobileNumber);
            if (res.ok) {
                const responseData = await res.json();
                console.log(responseData);
                setMessage(responseData.message)
                // Save the response data in local storage
/*                     localStorage.setItem('userData', JSON.stringify(responseData));
                sessionStorage.setItem('userData',JSON.stringify(responseData));
                sessionStorage.setItem('token',responseData.token);
                sessionStorage.setItem('appId',responseData.appId); */
                navigate('/consumer/otp', { state: { mobileNumber: mobileNumber } });
             
            } else {
                if (!res.ok) {
                // Handle error response here if needed
                console.error('Login failed');
                console.log(res)
                setMessage("Try with valid number")
                }
                // Or set an error state
                // setError('Login failed');
            }
        } catch (error) {
            // Handle any network errors or exceptions here
            console.error('Error:', error);
            // For example, set an error state
            // setError('Network error occurred');
            clearStorageAndRedirectToHome()
        }
    }
  };

  function clearStorageAndRedirectToHome() {
    navigate("/bb/user/ErrorPage");
  }
  
  const handleVerifyOtp = async () => {
    const otpString = otp.join(''); // Convert OTP array to a string
    console.log(`Sending OTP: ${otpString} for mobile number: ${mobileNumber}`); // Log the OTP and mobile number for debugging

    try {
      const response = await fetch(verifyOTP, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mobile_number: mobileNumber,
          otp: otpString,
        }),
      });

      const data = await response.json();
      console.log(data);
      if (response.ok) {
        localStorage.setItem('userData', JSON.stringify(data));
        sessionStorage.setItem('userData', JSON.stringify(data));
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('organizationId', data.organizationId);
        sessionStorage.setItem('roleId', data.roleId);

        if (data.customer_status === 1) {
          navigate("/consumer/UserDetails");
        } else if (data.customer_status === 2) {
          navigate("/consumer/NotApproved");
        } else if (data.customer_status === 3) {
          navigate("/consumer/addproject");
        } else {
          console.error('Unexpected customer_status:', data.customer_status);
        }
        setMessage(data.message);
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      setMessage('Internal server error.');
    }
  };

  return (
    <div className="w-full flex justify-center items-center h-screen font-roboto border border-gray-300">
      <div className="flex w-full justify-center items-center">
        <div className="flex flex-col space-y-4 justify-center w-full items-center">
          <div className='flex flex-row justify-center items-center'>
            <img src={'/BB.png'} alt="Logo" className='h-20 w-20 sm:h-14 sm:w-14' />
            <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
          </div>
          <div className="flex-col w-full sm:w-6/7 md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4">
            <p className="text-2xl pt-4">Enter Verification Code</p>
            <div className="p-6 relative">
              <div className="flex justify-center items-center space-x-2">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="w-12 h-12 text-center border border-gray-300 rounded-md"
                  />
                ))}
              </div>
              {timer > 0 ? (
                <p className="text-center mt-4 text-[#EB001B]">You can resend OTP in {timer} seconds</p>
              ) : (
                <button className='text-center mt-4 text-[#EB001B]' onClick={handleOTP}>Resend OTP</button>
              )}
              <div className="flex justify-center items-center mt-6">
                <button
                  className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1 mb-4"
                  onClick={handleVerifyOtp}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTPpage;
