import React, { useEffect, useState, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {  faChevronDown, faPaperclip, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import {Loader} from '../Loader/Loader';
import { FaRegTrashAlt } from 'react-icons/fa';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { useLocation, useNavigate } from 'react-router-dom';
import { validate } from '../CommenResponseAndErrorHandlers/commenUiErrorHandlers';
//import  '../Css/Tooltip.css';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};


const CustomDropdown = ({ options, selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const handleOptionClick = (value) => {
    onSelect(value);
    setIsOpen(false);
  };


  return (
    <div className="relative w-1/6" ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Salu'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {isOpen && (
      <div className="absolute mt-1 w-full h-40 bg-white border border-gray-300 rounded-md shadow-lg z-10 flex flex-col">
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {options.map((option) => (
            option.value !== 'add-new' && (
              <div className='p-1'>

              <div

                key={option.value}

                className="py-2 pl-1 cursor-pointer text-sm hover:bg-blue-500 hover:rounded-md  hover:text-white"

                onClick={() => handleOptionClick(option.value)}

              >

                {option.icon && <FontAwesomeIcon icon={option.icon} className="" />}

                {option.label}

              </div>

              </div>

            )

          ))}

        </div>

      </div>

    )}

  </div>

  

  );

};

const ViewProductGroup = () => {
    const navigate= useNavigate();
    const [productCategories, setProductCategories] = useState([
      ]);
      const [productGroups, setProductGroups] = useState([
      ]);
      const [productAttributes, setProductAttributes] = useState([
      ]);
      
      const [productSubcategories, setProductSubcategories] = useState([
      ]);
   const location= useLocation();
   const {ItemGroupData} = location.state || {}
  const [selectedProductCategory, setSelectedProductcategory] = useState(null);
  const [selectedProductGroup, setSelectedProductGroup] = useState(null);
  const [productGroupid , setproductGroupid] = useState(null);
  const [showProjectDetailsPopup, setShowProjectDetailsPopup] = useState(false);
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const url = process.env.REACT_APP_FORE_BRICKBUCKET
  const [isLoading, setIsLoading] = useState(true);
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [vendor , setvendor] = useState()
  const [productCategory , setProductCategory] = useState()
  const [attributes , setAttributes] = useState([])
  const [showAssignAttributesPopup, sethowAssignAttributesPopup] = useState(false)
  const [attributeLoading , setAttributesloading]= useState(true)
  const [attributeIds, setAttributeIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [ProductGroupLoader,setProductGroupLoader]= useState(true);
  const [errors, setErrors] = useState({});
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [retryFunction, setRetryFunction] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [assignType, setAssignType] = useState('');
  const [AddOnsProductGroups,setAddOnsProductGroups] = useState([]);
  //const [addOnGroupIds , setAddOnGroupIds] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);

/*   useEffect(() => {
    const fetchProductCategories = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(url + "product-category/get-all-productCategories", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                }
            });
            const data = await response.json();
            if (response.ok) {
                setProductCategories(data); // Update customers state
                console.log(data)
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }
    fetchProductCategories();
}, []); */

/* useEffect(() => {
    const fetchItemGroups = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "product-groups/get-all-productGroups", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                }
            });
            const data = await response.json();
            if (response.ok) {
                setProductGroups(data); // Update ItemGroups state
                console.log(data)
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error(error);
        }finally {
          setIsLoading(false);
        }
    }
    fetchItemGroups();
}, []); */

useEffect(() => {
  fetchRoleId();
  fetchItemGroups(ItemGroupData);
}, [ItemGroupData]);

const fetchRoleId = () => {
  const roleId = JSON.parse(sessionStorage.getItem('roleId'));
  setRoleId(roleId);
};

const fetchItemGroups = async (selecteditemGroup,attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `product-groups/get-all-productGroups?organizationId=${sessionStorage.getItem('organizationId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      setProductGroups(data.data);
      //console.log(data.data);
      setSelectedProductGroup(selecteditemGroup)
      handleProductGroupClick(selecteditemGroup)
    } else if (data.status === 400) {
      setShowPopup(true);
      setPopupType('400');
      setPopupMessage('Bad Request!');
    } else if (data.status === 401) {
      setShowPopup(true);
      setPopupType('401');
      setPopupMessage('Unauthorized!');
    } else if (data.status === 403) {
      setShowPopup(true);
      setPopupType('403');
      setPopupMessage('Forbidden!');
    } else if (data.status === 404) {
      setShowPopup(true);
      setPopupType('404');
      setPopupMessage('Not Found!');
    } else if (data.status === 500) {
      setShowPopup(true);
      setPopupType('500');
      setPopupMessage('Internal Server Error!');
    } else {
      setShowPopup(true);
      setPopupType('unknown');
      setPopupMessage('Unknown error!');
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => fetchItemGroups(selecteditemGroup,attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchItemGroups(selecteditemGroup,attempt)); // Set retry function
    }
  } finally {
    setIsLoading(false);
  }
};



  const handleProductGroupClick = (productGroup) => {
    setImageSrc(imageUrl + productGroup?.product_group_image);
    setproductGroupid(productGroup?._id);

    setSelectedProductGroup(productGroup);
    getProductGroupDetails(productGroup?._id)
    setProductGroupLoader(true);
  };

  const getProductGroupDetails = async (id,attempt = 1) => {
    try {
       const token = sessionStorage.getItem('token')
      const apiEndpoint = backendApiUrl + `product-groups/get-productGroup?id=${id}`; // Replace with your actual API endpoint
  
      // Make a GET request to the API
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
           'x-token' : token
        },
      });

      const data = await response.json();
    const output =  await getResponseHandling(response , data)
    if(output){
    setAttributes(output.attributeData);
   
    setvendor(output.vendor)
    setProductCategory(output.productCategory)
    }else{
      setAttributes([])
      setvendor()
      setProductCategory()
    }
    }  catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => getProductGroupDetails(id,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => getProductGroupDetails(id,attempt)); // Set retry function
      }
    }finally{
      setProductGroupLoader(false)
    }
  };

  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){
  
        await retryFunction ();
      }
  
      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };

  const getResponseHandling = async (response, data)=>{
    if (response.ok) {
      if (response.status === 200 || response.status === 201) {
        //setPopupType('200or201');
          return (data.data)
      } else if (response.status === 204) {
       // setPopupType('204');
        return (data.data)
      } 
    } else {
      if (response.status === 400) {
        setPopupType('400');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 401) {
        setPopupType('401');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 403) {
       
        setPopupType('403');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 500) {
       
        setPopupType('500');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else {
        setErrorMessages([...errorMessages, data.message]);
      }
    }
  }
  const handleClose = () => {
    navigate(-1);
  }
   const handleCloseAttributePopup = ()=>{
    sethowAssignAttributesPopup(false)
    setSelectedAddOns([])
   }

  
  
  const fetchItemCategoryAttributes = async (category,attempt = 1) => {
    setErrors({})
    const data = attributes.map((attribute, index) => {
      return {
        attribute_name_id: attribute.attribute_id,
        attribute_value_id: attribute.attribute_value_id,
      };
    });
    setAttributeIds(data)
    const id = category._id
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `product-attributes/get-all-attributes-by-productCategory?product_category_id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      console.log(data)
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setProductAttributes(data.data);
      } else if (data.status === 400) {
        setShowPopup(true);
        setPopupType('400');
        setPopupMessage('Bad Request!');
      } else if (data.status === 401) {
        setShowPopup(true);
        setPopupType('401');
        setPopupMessage('Unauthorized!');
      } else if (data.status === 403) {
        setShowPopup(true);
        setPopupType('403');
        setPopupMessage('Forbidden!');
      } else if (data.status === 404) {
        setShowPopup(true);
        setPopupType('404');
        setPopupMessage('Not Found!');
      } else if (data.status === 500) {
        setShowPopup(true);
        setPopupType('500');
        setPopupMessage('Internal Server Error!');
      } else {
        setShowPopup(true);
        setPopupType('unknown');
        setPopupMessage('Unknown error!');
      }
    }  catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchItemCategoryAttributes(category,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchItemCategoryAttributes(category,attempt)); // Set retry function
      }
    }finally {
      setAttributesloading(false);
    }
  };

  const handleRadioChange = (attribute_name_id, attributeValueId) => {
    const findIndexOfAttributeId = attributeIds.findIndex(
      (p) => p.attribute_name_id === attribute_name_id
    );
  
    if (findIndexOfAttributeId === -1) {
      // If attribute doesn't exist, add it to the array
      setAttributeIds([
        ...attributeIds,
        { attribute_name_id: attribute_name_id, attribute_value_id: attributeValueId },
      ]);
    } else {
      // If attribute exists, check if the same value is selected twice
      if (attributeIds[findIndexOfAttributeId].attribute_value_id === attributeValueId) {
        // Remove the attribute if the same value is selected again
        const updatedAttributeIds = attributeIds.filter(
          (_, index) => index !== findIndexOfAttributeId
        );
        setAttributeIds(updatedAttributeIds);
      } else {
        // Update the attribute's value
        const updatedAttributeIds = [...attributeIds];
        updatedAttributeIds[findIndexOfAttributeId].attribute_value_id = attributeValueId;
        setAttributeIds(updatedAttributeIds);
      }
    }
  };

  const handleAddattributesToGroup = async (attempt =1)=>{
    
    const attributeValueIds = attributeIds.map(item => item.attribute_value_id);
    const isattributeValueIds= attributeValueIds.length > 0
    console.log(isattributeValueIds)
    const fields = [ 
      { name: 'assign_attribute', value: isattributeValueIds, required: true,  message: 'select attribute values ', usage: 'attribute values'},  
    ];
    const validationErrors = validate(fields);
    setErrors(validationErrors);

    // Proceed only if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
    const data = {
      product_group_id: selectedProductGroup._id,
      attribute_value_ids:attributeValueIds
    }
    console.log(attributeValueIds)
    const token = sessionStorage.getItem('token');
    const url = process.env.REACT_APP_FORE_BRICKBUCKET + "product-groups/assign-attributes-to-productGroup";
    setIsSaving(true);
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify(data)
      });
  
      const responseData = await response.json();
      if (responseData.status === 200 || responseData.status === 201) {
       
        sethowAssignAttributesPopup(false);
        setIsSaving(false);
        // Display a new popup with a 5-second timer and response message
        setShowPopup(true);
        setPopupType('200or201');
        setPopupMessage(responseData.message);
        handleProductGroupClick(selectedProductGroup)
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      } else if (responseData.status === 403) {
        console.error('Forbidden: Access denied');
        sethowAssignAttributesPopup(false);
        setIsSaving(false);
        setShowPopup(true);
        setPopupType('403');
        setPopupMessage('Your session has expired');
        // Log the user out and redirect to login page
      } else if (responseData.status === 404) {
        console.error('Not Found: The requested Item Group was not found');
        sethowAssignAttributesPopup(false);
        setIsSaving(false);
        setShowPopup(true);
        setPopupType('404');
        setPopupMessage('Not Found: The requested Item Group was not found. Please try updating a different Item Group or contact our support team.');
        // Allow the user to try updating a different vendor or contact support
      } else if (responseData.status === 500) {
        console.error('Internal Server Error: Please try again later');
        sethowAssignAttributesPopup(false);
        setIsSaving(false);
        setShowPopup(true);
        setPopupType('500');
        setPopupMessage('Internal Server Error: Please try again later. If the issue persists, contact our support team.');
        // Do not close the vendor details popup, allow the user to retry
      } else {
        console.error('Unknown error occurred');
        setShowPopup(true);
        setPopupType('400');
        setPopupMessage('Unknown error occurred');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleAddattributesToGroup(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleAddattributesToGroup(attempt)); // Set retry function
      }
    }
  }
  }
  
  const closePopup = () => {
    setShowPopup(false);
  };

  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleFileInputClick = () => {
    document.getElementById('imageInput').click();
  };
  
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file); // This should match the field name in Multer's configuration
      formData.append('_id', selectedProductGroup._id);
  
      setIsUploading(true);
  
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}product-groups/change-product-group-image`, {
          method: 'PUT',
          headers: {
            'x-token': token,
          },
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
  
        if (data.status === 200) {
  
          console.log(imageUrl + data.data.product_group_image);
          setImageSrc(imageUrl + data.data.product_group_image); // Update the image URL with the new uploaded image URL
          fetchItemGroups(data.data)
        } else {
          console.error('Failed to upload image:', data.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleNavigateEditItemGroup = () =>{
    navigate("/bb/app/items/edititemgroup", {state:{ItemGroupData:selectedProductGroup}})
    console.log(selectedProductGroup);
   }

   const handleCreateItemGroup = () => {
    navigate('/bb/app/items/newproductgroups')
  }
 
  const groupsToDisplay = roleId === 6 
  ? productGroups.filter(group => (group.product_category_id?.product_category_name.toLowerCase()) === 'tiles')
  : productGroups;
 // console.log(groupsToDisplay);


  const handleAssignTypeSelect = (type) => {
    setAssignType(type);
    if (type === 'attributes') {
      fetchItemCategoryAttributes(selectedProductGroup?.product_category_id);
    } else if (type === 'addons') {
      // Implement function to fetch add-ons
       fetchAddOnsProductGroups(selectedProductGroup?.product_category_id._id);
       fetchselecetdAddOnProductGroup(selectedProductGroup?._id);
    }
    sethowAssignAttributesPopup(true);
    //setAssignType(false); // Close the dropdown after selection

  };

  const fetchselecetdAddOnProductGroup = async (productGroupId, attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}product-groups/get-assigned-product-group-add-ons?product_group_id=${productGroupId}`, {
        method: 'GET',
        headers: {
          'x-token': token,
        },
      });
      const data = await response.json();
      const output =  await getResponseHandling(response , data)
    
        if(output){
          setSelectedAddOns(output.add_on_product_group_ids)
          }else{
            setSelectedAddOns([])
          }
    }catch (error) {
      console.error('Failed to fetch:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchselecetdAddOnProductGroup(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchselecetdAddOnProductGroup(attempt)); // Set retry function
      }
    }
  }

  const handleOpenAssign = () => {
    setAssignType(prevState => prevState ? '' : 'attributes');
  }


  const fetchAddOnsProductGroups = async (PCid , attempt = 1) => {
    //console.log('Fetching add-ons for product group:', PCid);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}product-groups/get-all-category-add-on-product-groups?product_category_id=${PCid}`, {
        method: 'GET',
        headers: {
          'x-token': token,
        },
      });

      const data = await response.json();
      const output =  await getResponseHandling(response , data)
    
        if(output){
         // console.log(output);
          setAddOnsProductGroups(output);
      
          }else{
            setAddOnsProductGroups([])
          }
          }  catch (error) {
            console.error('Failed to fetch:', error);
      
            if (attempt < RETRY_LIMIT) {
              setRetryCount(attempt + 1); // Increment retry count
              setTimeout(() => fetchAddOnsProductGroups(PCid, attempt + 1), 1000); // Retry after 1 second
            } else {
              setIsLoading(false);
              setPopupMessage('Internal Server Error. Kindly Retry.');
              setPopupType('500');
              setShowPopup(true);
              setRetryFunction(() => () => fetchAddOnsProductGroups(PCid,attempt)); // Set retry function
            }
          }finally{
            setProductGroupLoader(false)
            setIsLoading(false);
            setAttributesloading(false);
          }
  };


  const handleAddOnChange = (groupId) => {
    setSelectedAddOns(prevSelected => {
      if (prevSelected.includes(groupId)) {
        return prevSelected.filter(id => id !== groupId);
      } else {
        return [...prevSelected, groupId];
      }
    });
  };
 
  const handleAssignAddOnGroupsToMainGroups = async () => {
    const token = sessionStorage.getItem('token');
    setIsSaving(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}product-groups/assign-add-on-product-groups`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({
          product_category_id: selectedProductGroup.product_category_id._id,
          main_product_group_id: selectedProductGroup._id,
          add_on_product_group_ids: selectedAddOns
        })
      });
  
      const data = await response.json();
      
      if (data.status === 200 || data.status === 201) {
        setShowPopup(true);
        setPopupType('200or201');
        setPopupMessage('Add-ons assigned successfully');
        handleProductGroupClick(selectedProductGroup);
        handleCloseAttributePopup();
        
        setTimeout(() => {
          setShowPopup(false);
        }, 2000); // Close the popup after 3 seconds
      } else {
        setShowPopup(true);
        setPopupType('400');
        setPopupMessage(data.message || 'Failed to assign add-ons');
        
        setTimeout(() => {
          setShowPopup(false);
        }, 2000); // Close the popup after 3 seconds
      }
      
    } catch (error) {
      console.error('Error assigning add-ons:', error);
      setShowPopup(true);
      setPopupType('500');
      setPopupMessage('Internal Server Error');
    } finally {
      setIsSaving(false);
    }
  };
  

  if (isLoading) {
    return <Loader />;
  }

  return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">

      {/* List of Customers Div start */}

      <div className=" flex flex-col  w-[32%]  ">

  <div className="p-4 border-b  flex flex-row justify-between ">

    <div>

    <h5 className="text-md font-semibold cursor-pointer ">All Item Groups

     <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />

    </h5>

    </div>

    <div className=''>

    <button 

  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md" 

  title="" data-tooltip="Create Item Group" style={{ '--tooltip-bg': 'black' }}
  onClick={handleCreateItemGroup}>
  <FontAwesomeIcon icon={faPlus} className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />

</button>

</div>

  </div>

  <div className=" flex justify-center  overflow-y-hidden  flex-col ">

   <div className='flex  flex-col h-[80vh]  overflow-y-auto '>

   {groupsToDisplay.map((productGroup) => (
  <div
    key={productGroup._id}
    onClick={() => handleProductGroupClick(productGroup)}
    className={`border-b text-sm flex items-center px-4 py-2 ${selectedProductGroup?._id === productGroup._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
  >
    <div className='p-1 w-full flex flex-row justify-between'>
      <div className='w-[10%]'>
        <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
      </div>
      <div className='w-[80%] flex flex-col pr-2'>
        <div>
          <p className='text-md font-semibold'>{productGroup.product_group_name}</p>
        </div>
        {productGroup?.UOM_id && (
          <div className='flex flex-row'>
            <p className='text-xs mr-1'>Units:</p>
            <p className='text-xs'>{productGroup.UOM_id.UOM}</p>
          </div>
        )}
      </div>
      <div className='w-[10%] flex flex-row'>
      {productGroup?.selling_price && (
        <p className='text-md font-semibold'>₹{productGroup.selling_price.toLocaleString()}</p>
      )}
      </div>
    </div>
  </div>
))}



  </div>

</div>

</div>

{/* List of Customers Div end */}

<div className="border-l  w-full flex flex-col ">
    {selectedProductGroup? (
  <>
  <div className="border-b h-24 over-flow-hidden"> {/* or h-12, depending on your desired height */}

<div className='p-4 flex justify-between  items-center relative'>
  <span className="text-md font-semibold">{selectedProductGroup.product_group_name}</span>
  <div className="flex items-center px-2">
      <button  onClick={handleNavigateEditItemGroup}  className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md mr-2">
        Edit
      </button>
     {/*  <button className="bg-gray-100 px-1 py-1 shadow-sm text-sm border rounded-md mr-2">
        <FontAwesomeIcon icon={faPaperclip} className="text-md text-black mx-2 cursor-pointer " />
      </button>
      <button className="bg-[#f7525a] text-sm text-white px-2 py-1 shadow-sm border rounded-md mr-2">
        New Transaction
      </button> */}
     <div className="relative">
  <button 
    className="bg-gray-100 text-sm px-2 py-1 shadow-sm border rounded-md mr-2"
    onClick={handleOpenAssign}
  >
    Assign
  </button>
  {assignType && (
    <div className="absolute right-0 mt-2 w-24 bg-white rounded-md shadow-lg z-10">
      <div 
        className="py-1 px-2 hover:bg-[#f7525a] hover:text-white cursor-pointer"
        onClick={() => handleAssignTypeSelect('attributes')}
      >
        Attributes
      </div>
      <div 
        className="py-1 px-2 hover:bg-[#f7525a] hover:text-white cursor-pointer"
        onClick={() => handleAssignTypeSelect('addons')}
      >
        Add-ons
      </div>
    </div>
  )}
</div>
      <button className=" text-white px-1 py-1 rounded-md "
        title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}>   
        <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-md text-[#f7525a] mx-2 cursor-pointer " />
      </button>
    </div>
</div>

</div>
{ProductGroupLoader ? (
    <Loader />
  ) : (
<div className="h-5/6 w-full flex justify-center text-[0.89rem]">
  <div className="flex flex-row h-full w-full">
    <div className="flex flex-1  flex-col">
      <div className="flex flex-col h-[30rem] overflow-y-auto">
        <div className="flex flex-col h-60 px-2">
          <div className="flex justify-start items-start mt-2">
            <span className="text-md text-[1rem] font-semibold">Item Group Attributes</span>
          </div>
          {attributes.length > 0 ? (
            <div className="flex flex-col p-2 w-full items-start mt-2">
            <table className="table-auto w-3/4 ">
  <tbody>
    {attributes.map((attribute, index) => (
      <tr className="flex justify-between mt-2">
        <th className="text-left text-[#777] font-normal w-1/2">{attribute.attribute_name}</th>
        <td className="text-left w-1/2">{attribute.attribute_value}</td>
      </tr>
    ))}
  </tbody>
</table>
                  </div>
          ) : (
            <div className="flex justify-start px-2 py-1">
              <span className=" text-[#777] font-normal"> No attributes currently assigned to this group. Please assign attributes if needed.</span>
            </div>
          )}
        </div>

        <div className="flex flex-col h-60  mt-2 px-2">
          <div className="flex justify-start items-start">
            <span className="text-md text-[1rem] font-semibold">Item Category Details</span>
          </div>
          {productCategory ? (
          
             <div className="flex flex-col p-2 w-full items-start">
      <table className="table-auto w-3/4 ">
        <tbody>
          <tr className="flex justify-between mt-2">
            <th className="text-left text-[#777] font-normal w-1/2">Item Category Name</th>
            <td className="text-left w-1/2">{productCategory.category_name}</td>
          </tr>
          <tr className="flex justify-between mt-2">
            <th className="text-left text-[#777] font-normal w-1/2">Description
            </th>
            <td className="text-left w-1/2">{productCategory?.category_description}</td>
          </tr>
          
        </tbody>
      </table>
            </div>
          ) : (
            <div className="flex justify-center p-4">
              <span className="text-[0.8rem] text-[#777] font-normal">There are no Item Category currently assigned to this group. Please assign Item Category if needed.</span>
            </div>
          )}
        </div>
        {selectedProductGroup?.cost_price && (
        <div className="flex flex-col h-60  mt-2 px-2">
          <div className="flex justify-start items-start">
            <span className="text-md text-[1rem] font-semibold">Purchase Information</span>
          </div>
        <div className="flex flex-col p-2 w-full items-start">
      <table className="table-auto w-3/4 ">
        <tbody>
          <tr className="flex justify-between mt-2">
            <th className="text-left text-[#777] font-normal w-1/2">Cost Price</th>
            <td className="text-left w-1/2">₹{selectedProductGroup.cost_price.toLocaleString()}</td>
          </tr>
          {/* <tr className="flex justify-between mt-2">
            <th className="text-left text-[#777] font-normal w-1/2">Description
            </th>
            <td className="text-left w-1/2">{productCategory?.category_description}</td>
          </tr> */}
          
        </tbody>
      </table>
            </div>
            </div>
        )}

{selectedProductGroup?.selling_price && (
            <div className="flex flex-col h-60  mt-2 px-2">
          <div className="flex justify-start items-start">
            <span className="text-md text-[1rem] font-semibold">Sales Information</span>
          </div>
        <div className="flex flex-col p-2 w-full items-start">
      <table className="table-auto w-3/4 ">
        <tbody>
          <tr className="flex justify-between mt-2">
            <th className="text-left text-[#777] font-normal w-1/2">Selling Price</th>
            <td className="text-left w-1/2">₹{selectedProductGroup.selling_price.toLocaleString()}</td>
          </tr>
          {/* <tr className="flex justify-between mt-2">
            <th className="text-left text-[#777] font-normal w-1/2">Description
            </th>
            <td className="text-left w-1/2">{productCategory?.category_description}</td>
          </tr> */}
          
        </tbody>
      </table>
            </div>
            </div>
)}

        <div className="flex flex-col h-60 mt-2 px-2">
  <div className="flex justify-start items-start w-full mb-2">
    <span className="text-[1rem] font-semibold ">Vendor Details</span>
  </div>
  {vendor ? (
    <div className="flex flex-col p-2 w-full items-start">
      <table className="table-auto w-3/4 ">
        <tbody>
          {/* Vendor Name */}
          <tr className="flex justify-between mt-2">
            <th className="text-left text-[#777] font-normal w-1/2">Vendor Name</th>
            <td className="text-left w-1/2">{vendor?.vendor_name}</td>
          </tr>
          {/* Vendor Company */}
          <tr className="flex justify-between mt-2">
            <th className="text-left text-[#777] font-normal w-1/2">Vendor Company</th>
            <td className="text-left w-1/2">{vendor?.vendor_company}</td>
          </tr>
          {/* Vendor Address */}
          <tr className="flex justify-between mt-2">
            <th className="text-left font-normal text-[#777] w-1/2">Vendor Address</th>
            <td className="text-left w-1/2">{vendor?.vendor_address}</td>
          </tr>
          {/* Vendor Pincode */}
          <tr className="flex justify-between mt-2">
            <th className="text-left font-normal text-[#777] w-1/2">Vendor Pincode</th>
            <td className="text-left w-1/2">{vendor?.vendor_pincode}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div className="flex justify-center p-4">
      <span className="text-[0.8rem]">There are no vendors currently assigned to this group. Please assign a vendor if needed.</span>
    </div>
  )}
</div>

      </div>
    </div>

    <div className="flex w-60 h-full p-2 justify-center">
      {/* <div className="flex flex-col h-48 justify-center p-2 w-40 border">
        <div className="flex justify-center">
          <img
            src={imageUrl + selectedProductGroup.product_group_image}
            alt={selectedProductGroup.product_group_name}
            className="flex w-28 h-28 object-cover"
          />
        </div>
        <div className="mt-6 flex justify-between">
          <span className="text-[0.89rem] text-blue-500" onClick={handleChangeP}>Change Image</span>
          <FaRegTrashAlt className="text-xs text-gray-600 mx-1 cursor-pointer" />
        </div>
      </div> */}

      <div className="flex flex-col h-48 justify-center p-2 w-40 border">
    <div className="flex justify-center">
          <img
            src={imageUrl + selectedProductGroup.product_group_image}
            alt={selectedProductGroup.product_group_name}
            className="flex w-28 h-28 object-cover"
          />
        </div>
      <div className="mt-6 flex justify-between">
        <span
          className="text-[0.89rem] text-blue-500 cursor-pointer"
          onClick={handleFileInputClick}
        >
          Change Image
        </span>
        <FaRegTrashAlt className="text-xs text-gray-600 mx-1 cursor-pointer" />
      </div>
      <input
        type="file"
        id="imageInput"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageChange}
      />
    </div>
    {isUploading && <p>Uploading...</p>}
    </div>
  </div>
</div>
 )}
  </>
    ):(
 <div className="flex justify-center items-center h-full">
    <p className="text-gray-500">Select a Item Group to view details</p>

</div>
    )}



</div>

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}

{showAssignAttributesPopup && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col ">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md">{assignType === 'attributes' ? 'Attributes' : 'Add-ons'}</h2>
        <button onClick={handleCloseAttributePopup} className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      {attributeLoading ? (
        <Loader />
      ) : (
        assignType === 'attributes' ? (
          <>
            <div className="w-full flex flex-col justify-center items-center mb-4 space-y-4 px-6">
              {Object.keys(errors).length > 0 && (
                <div className="flex justify-between w-full p-2 items-center px-auto py-3 bg-[#fddcde] rounded-md">
                  <div className="px-6 flex flex-col space-y-2 justify-start items-start">
                    {errors.assign_attribute && (
                      <span className="text-sm">• {errors.assign_attribute}</span>
                    )}
                  </div>
                  <div className='px-4'>
                    <button
                      className="text-gray-700 hover:text-red-500"
                      onClick={() => setErrors({})}
                    >
                      <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                    </button>
                  </div>
                </div>
              )}
              <div className="flex justify-center flex-col items-center">
                <table>
                  <tbody>
                    {productAttributes.map((attribute, index) => (
                      <tr key={index} className='border-2 border-opacity-50'>
                        <td className='py-2 px-4 border-2 border-opacity-50'>
                          <h1 className='font-semibold'>{attribute.attribute_name}</h1>
                        </td>
                        <td>
                          <div className='flex flex-col text-sm items-left ml-10 px-4 py-2'>
                            {attribute.attribute_values.map((attributeValue, attributeValueIndex) => (
                              <div key={attributeValueIndex} className='flex flex-row items-center'>
                                <input
                                  type='radio'
                                  id={`attribute_${index}_${attributeValueIndex}`}
                                  name={`attribute_${index}`}
                                  onClick={() => handleRadioChange(attribute.attribute_name_id, attributeValue.attribute_value_id)}
                                  checked={attributeIds.some(id => id.attribute_value_id === attributeValue.attribute_value_id)}
                                  className="appearance-none w-3 h-3 border border-black rounded-full checked:bg-[#EB001B] checked:border-transparent focus:outline-none focus:ring-1 focus:ring-[#EB001B]"
                                />
                                <label htmlFor={`attribute_${index}_${attributeValueIndex}`} className='ml-5'>
                                  {attributeValue.attribute_value}
                                </label>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-auto w-full">
              <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                <button 
                  className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                  onClick={handleAddattributesToGroup}
                >
                  <span>Save</span>
                  {isSaving && (<Spinner/>)}
                </button>
                <button 
                  onClick={handleCloseAttributePopup}  
                  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
                    isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
                  }`}
                  disabled={isSaving}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col h-full">
            <div className="grid grid-cols-3 gap-3 pl-2 py-2 flex-grow overflow-y-auto">
              {AddOnsProductGroups.length > 0 ? (
                AddOnsProductGroups.map((group) => (
                  <div key={group._id} className="flex p-2 flex-row justify-center space-x-2">
                    <input
                      type="checkbox"
                      id={group._id}
                      value={group._id}
                      className="form-checkbox h-4 w-4 text-[#EB001B] focus:ring-[#EB001B]"
                      checked={selectedAddOns.includes(group._id)}
                      onChange={() => handleAddOnChange(group._id)}
                    />
                    <label htmlFor={group._id} className="text-gray-700">{group.product_group_name}</label>
                  </div>
                ))
              ) : (
                <div>No add-on product groups available.</div>
              )}
            </div>
            <div className="mt-auto w-full">
              <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                <button 
                  className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                  onClick={handleAssignAddOnGroupsToMainGroups}
                >
                  <span>Save</span>
                  {isSaving && (<Spinner/>)}
                </button>
                <button 
                  onClick={handleCloseAttributePopup}  
                  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
                    isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
                  }`}
                  disabled={isSaving}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  </div>
)}


               


    </div>

  );

};



export default ViewProductGroup;