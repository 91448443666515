import React from "react";
import LeftsideMenu from "./LeftsideMenu";
import HeaderRow from "./HeaderRow";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";



function AdminHome() {
   const Navigate=useNavigate();
  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null
   
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
       Navigate('/measurit')
      }
    }else{
      Navigate('/measurit')
    }
  }) 


  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
       Navigate('/measurit')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
       Navigate('/measurit')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

  return (
    <div className="flex h-screen bg-[#f9f4f4] font-roboto">
      
     
  {/* Left Sidebar */}
  <LeftsideMenu/>

  {/* Right Content */}
  <div className="w-full m-2 flex flex-col ml-[16.66%] overflow-y-auto">
  
    {/* Header Row with Notification Icon */}
    <HeaderRow />



        {/* admin home container */}

          <Outlet />

       

    </div>
    
    </div>
  );
}

export default AdminHome;
