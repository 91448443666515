import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader, Spinner } from '../Loader/Loader';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import Search from '../Components/Search';

const VehicleScheduleList = () => {
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [vehicleSchedule, setVehicleSchedule] = useState([/* sampleVehicleScheduleData */]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const [filteredVS, setFilteredVS] = useState([]);
const searchTypes = ['Schedule Number', 'Vehicle Number', 'Driver Name', 'Driver Number', 'Status'];

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...vehicleSchedule].sort((a, b) => {
      const aValue = key.split('.').reduce((obj, k) => obj && obj[k], a) || '';
      const bValue = key.split('.').reduce((obj, k) => obj && obj[k], b) || '';
      
      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();

      if (aString < bString) return direction === 'ascending' ? -1 : 1;
      if (aString > bString) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setVehicleSchedule(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  const handleCreate = () => {
    navigate("/bb/app/sales/newvehicleschedule");
  };

//   const handleNavigateDetails = (schedule) => {
//     navigate("/bb/app/deliverychallans/deliverychallandetails", { state: { DeliveryChallanData: schedule } });
//   };

useEffect(() => {
  handleSearch();
}, [vehicleSchedule]);

const handleSearch = (query, type) => {
  if (!query || !type) {
    setFilteredVS(vehicleSchedule);
    return;
  }

  const filtered = vehicleSchedule.filter((schedule) => {
    const lowercaseQuery = query.toLowerCase();
    switch (type) {
      case 'Schedule Number':
        return schedule.schedule_number?.toLowerCase().includes(lowercaseQuery);
      case 'Vehicle Number':
        return schedule.vehicle_number?.toLowerCase().includes(lowercaseQuery);
      case 'Driver Name':
        return schedule.driver_name?.toLowerCase().includes(lowercaseQuery);
      case 'Driver Number':
        return schedule.driver_number?.toLowerCase().includes(lowercaseQuery);
        case 'Status':
          return schedule.status?.toLowerCase().includes(lowercaseQuery);
      default:
        return false;
    }
  });

  setFilteredVS(filtered);
};

  const formatScheduleDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const handleNavigateDetails = (schedule) => {
    navigate("/bb/app/sales/vehicleschedulingdetails", { state: { vehicalScheduleData: schedule } });
  };

  useEffect(() => {
    getVehicleschedule();
  }, []);
  
  
  const getVehicleschedule = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + `customer/vehicle-schedule/get-all-vehicle-schedules?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
  
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      if (output) {
        setVehicleSchedule(output);
        console.log(output);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Failed to fetch vehicle schedules:', error);
      setPopupMessage('Network error. Please check your connection and try again.');
      setPopupType('error');
      setShowPopup(true);
    }
  };
  

  return (
    <div className="bg-white flex flex-col w-full h-full">
     <div className="w-full h-16 border-b flex items-center">
                    <div className="flex w-full justify-between px-6 items-center">
                    <div className='space-x-10 flex flex-row'>
                          <h1 className="text-lg font-bold">Schedule List</h1>
                          <Search onSearch={handleSearch} searchTypes={searchTypes} />
                        </div>
                        <button
                          onClick={handleCreate}
                          className="bg-[#F7525A] text-white px-2 py-1 rounded"
                        >
                          + New
                        </button>    
                       </div>
                    
                  </div>
      <div className="overflow-y-auto h-[80vh]">
        <table className="w-full mb-5">
          <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
            <tr className='border-b'>
            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('schedule_number')}>
                Schedule Number {getSortIcon('schedule_number') && <FontAwesomeIcon icon={getSortIcon('schedule_number')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('schedule_date')}>
                Schedule Date {getSortIcon('schedule_date') && <FontAwesomeIcon icon={getSortIcon('schedule_date')} />}
              </th>
             
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('vehicle_number')}>
                Vehicle Number {getSortIcon('vehicle_number') && <FontAwesomeIcon icon={getSortIcon('vehicle_number')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('dc_status')}>
               DC Status {getSortIcon('dc_status') && <FontAwesomeIcon icon={getSortIcon('dc_status')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('driver_name')}>
                Driver Name {getSortIcon('driver_name') && <FontAwesomeIcon icon={getSortIcon('driver_name')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('driver_number')}>
                Driver Number {getSortIcon('driver_number') && <FontAwesomeIcon icon={getSortIcon('driver_number')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('status')}>
                Status {getSortIcon('status') && <FontAwesomeIcon icon={getSortIcon('status')} />}
              </th>
              {/* <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('total_weight_in_kgs')}>
                Total Weight (Kgs) {getSortIcon('total_weight_in_kgs') && <FontAwesomeIcon icon={getSortIcon('total_weight_in_kgs')} />}
              </th> */}
            </tr>
          </thead>
          <tbody className="border-b border-t">
          {filteredVS.length > 0 ? (
    filteredVS.map((schedule) => (
              <tr key={schedule._id} className="text-sm border-b">
                <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" onClick={() => handleNavigateDetails(schedule)}>
  {schedule.schedule_number}
</td>
                <td className="py-2 text-center">{formatScheduleDate(schedule.schedule_date)}</td>
                <td className="py-2 text-center">{schedule.vehicle_number}</td>
                <td className="py-2 text-center">{schedule.DC_status}</td>
                <td className="py-2 text-center">{schedule.driver_name}</td>
                <td className="py-2 text-center">{schedule.driver_number}</td>
                <td className="py-2 text-center">{schedule.status}</td>
                {/* <td className="py-2 text-center">{schedule.total_weight_in_kgs}</td> */}
              </tr>
            ))
          ) : (
          <tr>
            <td colSpan="10" className="text-center text-[#6c7184] py-4">
              Not found
            </td>
          </tr>
)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VehicleScheduleList;
