import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faBell, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";

function HeaderRow() {
    const Navigate=useNavigate();
    const location = useLocation();
    const [userData,setUserData]=useState() 
    const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNo, setMobileNo] = useState('');  
    const [userProfileImage, setuserProfileImage] = useState( );

    const pageNames = {
      "/measurit/AdminHome": "Admin Home",
      "/measurit/CreateProject": "Create Project",
      "/measurit/CreateUser": "Create Users",
      "/measurit/Profile": "Profile",
      "/measurit/Projects": "Projects",
      "/measurit/Users": "Users",
      "/measurit/UnAssignedProjects": "UnAssigned Projects",
      "/measurit/UnAssignedUsers": "UnAssigned Users",
      "/measurit/UserDetails": "User Details",
      "/measurit/View": "Project Details",
      "/measurit/blocks": "Blocks",
      "/measurit/flats": "Flats",
      "/measurit/Measurements": "Measurements",
      "/measurit/UserHome": "User Home",
      "/measurit/UserViewProject": "User Project Details",
      "/measurit/Reports": "Reports",
      "/measurit/ProjectReports": "Project Reports",
      "/measurit/AggregatedProjectReports": "Aggregate Report",
      

      // Add mappings for the rest of your pages
    };


    
    useEffect(() => {
      const storedLoginData = JSON.parse(localStorage.getItem('LoginData')) || null;
      const userData = JSON.parse(sessionStorage.getItem('userData')) || null;
      
      if (storedLoginData && userData) {
        if (storedLoginData.token === userData.token) {
          setUserData(userData);
         // console.log(userData);
        } else {
          Navigate('/measurit');
        }
      } else {
        Navigate('/measurit');
      }
    }, []);
    
    useEffect(()=>{
      const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
      const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
      if(storedLoginData&&Userdata){
        if(storedLoginData.token===Userdata.token){
        }else{
         Navigate('/measurit')
         sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
        }
      }else{
         Navigate('/measurit')
          sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
      }
    })

    const currentPage = pageNames[location.pathname] || "Unknown Page";

     const getUserDetails = async (_id) => {
        const GetUserData = `${process.env.REACT_APP_FORE_APILINK}/user/get-user-details?userId=${_id}`;
          try {
            const token = sessionStorage.getItem('token');

            const response = await fetch(GetUserData, {
              method: 'GET',
              headers: {
                  'x-token': token
              }
          });
            const data = await response.json();
            // Update state with user details
            setName(data.user.name);
            setEmail(data.user.email);
            setMobileNo(data.user.mobileNo);
            setuserProfileImage(data.user.userProfileImage || null);
           
          } catch (error) {
            clearStorageAndRedirectToHome()
          }
        }; 
    
    function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }
      useEffect(() => {
        
        if(userData){
          getUserDetails(userData.userId);
        
        }
          
           
        
    }, [userData]); 

    const handleProfile=async()=>{
        Navigate('/measurit/Profile');
       }

    return(
<div>
    {/* Header Row Starts*/}
    <div className="lg:mb-0 p-1 md:mb-0 sm:mb-2 mb-0 rounded-md bg-white flex justify-between items-center ml-4 mr-4 relative">
            <h1 className="ml-2 text-lg font-bold">{currentPage}</h1>
            <div className="flex items-center">
            {/*   <div className="h-8 border-r border-gray-300 mr-2"></div> */}
            {/*   <FontAwesomeIcon icon={faBell} className="text-lg cursor-pointer text-black mr-2" /> */}
              {/* <div className="h-8 border-l border-gray-300 mr-4"></div> */}
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center text-white text-sm font-semibold mr-4 cursor-pointer " onClick={handleProfile}>
        {userProfileImage ? (
      <img
        src={process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL + userProfileImage}
        alt="Profile"
        className="w-8 h-8 object-cover rounded-full"
      />
    ) : (
      <FontAwesomeIcon icon={faUserCircle} />
    )}
        </div>
          {userData&&(
            <h1 className="mr-4">{name} </h1> 
          )}
               
            </div>
          </div>
          {/* Header Row Ends*/}
</div>
    );
}
export default HeaderRow;