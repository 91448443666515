import React, { useState,useEffect,useCallback } from 'react';
import CustomDropdown from '../Components/CustomDropdown';
import { faSortUp, faSortDown, faPlus, faTrash, faCaretDown, faX, faPlusCircle, faChevronDown, faFileImport, faInfoCircle, faArrowRight, faArrowLeft, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Loader ,Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500, ErrorPopup } from '../Messages/Messages';

const RETRY_LIMIT = 3; // Maximum number of retries
const BulkUploadStep2 = ({excelData,onPreviousClick,onNextClick,showOverwriteOptions,
  OverwriteItemsOptions, selectedItem, selectedFile ,handleOverwriteItemDropdownChange }) => {
    console.log(selectedFile);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [currentStep, setCurrentStep] = useState(2);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryId , setSelectedCategoryId] = useState();
  const [selectedSubCategoryId,setSelectedSubCategoryId]= useState()
  const [selectedVendorId, setSelectedVendorId] = useState()
  const [ProductSubCategories,setProductSubCategories] = useState([])
  const [vendors,setVendors]= useState([])
const [selectedVendorName , setSelectedVendorName] = useState()
const [selectedUOM , setselectedUOM] = useState()
const [selectedUomId,setselectedUomId]= useState()
console.log(selectedUomId);
const [selectedSubCategoryName,setSelectedSubCategoryName]= useState()
const [productGroups,setProductGroups] = useState()
const [selectedItemGroupName,setSelectedProductGroupName] = useState()
const [selectedItemGroupId,setSelectedProductGroupId] = useState()
const[UOMId,setUOMId] = useState()
const navigate = useNavigate();
const [errorMessages, setErrorMessages] = useState([]);
const [isSaving, setIsSaving] = useState(false);
const [showCancelPopup, setShowCancelPopup] = useState(false);
const [showPopup, setShowPopup] = useState(false);
const [popupMessage, setPopupMessage] = useState('');
const [popupType, setPopupType] = useState('');
const [retryFunction, setRetryFunction] = useState(null);
const [isLoading, setIsLoading] = useState(null);
const [retryCount, setRetryCount] = useState(0); // Track retry attempts
//const [ErrorPopup , setErrorPopup] = useState(null);
const [ErrorMSGPopup , setErrorMSGPopup] = useState(null);
const[ uomOptions ,setUomOptions]= useState([
 
 ]);
  // Initialize headerMappings with empty strings
  const [headerMappings, setHeaderMappings] = useState({
    'product_name': '',
    'SKU': '',
    'HSN_Code': '',
    'selling_price': '',
    'cost_price': '',
    'tax_preference': '',
    'specifications': '',
    'GST': '',
    'IGST': ''
  });

  const headers = Object.keys(excelData[0] || {});

  // User-friendly labels
  const labels = {
    'product_name': 'Item Name',
    'SKU': 'SKU',
    'HSN_Code': 'HSN Code',
    'selling_price': 'Selling Price',
    'cost_price': 'Cost Price',
    'tax_preference': 'Tax Preference',
    'specifications': 'Specifications',
    'GST': 'GST',
    'IGST': 'IGST'
  };

  const handleHeaderSelect = (header, key) => {
    setHeaderMappings(prevMappings => ({ ...prevMappings, [key]: header }));
  };


  const handleSubmit = async (attempt = 1) => {
    // Check if all required headers are selected
    const requiredHeaders = ['product_name']; // Add other required headers if needed
    const allHeadersSelected = requiredHeaders.every(header => headerMappings[header]);
  
    if (!allHeadersSelected) {
      setErrorMSGPopup('Mandatory columns missing.');
      setPopupType('ErrorPopup');
      setShowPopup(true);
      return;
    }
  
    const ids = {
      product_group_id: selectedItemGroupId,
      vendor_id: selectedVendorId,
      product_category_id: selectedCategoryId,
      product_sub_category_id: selectedSubCategoryId,
      UOM_id: selectedUomId
    };
  
    // Map headers and include IDs in each row
    const mappedData = excelData.map(row => {
      const newRow = {};
      // Add the ids to each row
      Object.keys(ids).forEach(key => {
        newRow[key] = ids[key];
      });
      // Map headers to data
      Object.keys(headerMappings).forEach(key => {
        newRow[key] = row[headerMappings[key]] || ''; // Default to empty string if header is not found
      });
      return newRow;
    });
  
    try {
      const token = sessionStorage.getItem('token');
      // Send the mapped data to the backend API
      const response = await fetch(fetchUrl + "product/create-products", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
        body: JSON.stringify({
          productsData: mappedData,
          ...ids , 
          overWriteWith: selectedItem 

        }),
      });
  
      const responseData = await response.json();
      console.log(responseData)
  
    if (responseData.status === 201 || responseData.status === 200) {
      setIsSaving(false);
      setPopupMessage(responseData.message);
      setPopupType('200or201');
      setShowPopup(true);
      setTimeout(() => {setShowPopup(false);}, 3000); 
      const saved = responseData.data.savedCount;
      const notSaved = responseData.data.notSavedCount;
      const updated = responseData.data.updatedCount;
      const savedProducts = responseData.data.savedProducts;
      const updatedProducts = responseData.data.updatedProducts;
      const notSavedProducts = responseData.data.notSavedProducts;
         onNextClick(saved,notSaved,updated,savedProducts,updatedProducts,notSavedProducts);
    } else if (responseData.status === 500) {
      setIsSaving(false);
      setIsLoading(false);
      setRetryFunction(() => handleSubmit);
      setPopupMessage(responseData.message);
      setPopupType('500');
      setShowPopup(true);
    } else {
      setIsLoading(false);
      setIsSaving(false);
      setPopupMessage(responseData.message);
      setPopupType(`${responseData.status}`);
      setShowPopup(true);
    } 
  } catch (error) {
    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleSubmit(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      console.error('Failed to fetch:', error);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleSubmit(attempt)); // Set retry function

    }
  }
  };
  


  const fetchProductCategories = useCallback(async (attempt = 1) => {
    console.log("1");
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();

      if (data.status === 200 || data.status === 201 || data.status === 204) {
        const categories = data.data.map(cat => ({
          product_category_id: cat._id,
          product_category_name: cat.product_category_name
        }));
        setProductCategories(categories);
        setIsLoading(false);
        setRetryFunction(null); // Clear retry function on success
      } else {
        setIsLoading(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }
  }, [fetchUrl]);

  useEffect(() => {
    fetchProductCategories();
  }, [fetchProductCategories]);


  const onProductCategorySelect = async (value,label,id) => {
    console.log(value,label,id)
    try {
      // Update selected category and related states
      setUOMId(value)
      setSelectedCategory(label);
      setSelectedCategoryId(id);
      // Fetch new product subcategories
      fetchSubCategories(id);
    
    } catch (error) {
      console.error('Error handling category change:', error);
    }
  };

   // Use this function to call handleGetProductSubCategories with the necessary id
   const fetchSubCategories = (id) => {
    setIsLoading(true); // Start loading before fetching
    handleGetProductSubCategories(id);
  };

  const handleGetProductSubCategories = useCallback(async (id, attempt = 1) => {
    console.log(id);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + `product-sub-category/get-product-subCategories-and-vendors-by-productCategory?product_category_id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();

      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // Log the data to ensure it's fetched correctly
        console.log('Fetched Product Categories:', data);
        const vendorsData = data.data.AllAssignedVendors;
        const categories = data.data.subCategories;
        setProductSubCategories(categories);
        setVendors(vendorsData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleGetProductSubCategories(id, attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        console.error('Failed to fetch:', error);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleGetProductSubCategories(id,attempt)); // Set retry function

      }
    }
  }, [fetchUrl]);


  const onProductSubCategorySelect = async (value, label,id) => {
    setSelectedSubCategoryName(label); 
    setSelectedSubCategoryId(id);
  };

  const onSelectedVendor = async (value, label, id) => {
    console.log(label, id);
  
      // Set the selected vendor's name and id
      setSelectedVendorName(label);
      setSelectedVendorId(id);

  };
  const onSelecteUom = async (value , label , id) =>{
    setselectedUOM(label)
    setselectedUomId(id)
  }
  
  useEffect(() => {
    if (selectedVendorId) {
      handleGetProductGroups();
    }
  }, [selectedVendorId]);

const onSelectedProductGroup = async(value,label,id) => {
  console.log(label,id)
  if(!UOMId){
    setUOMId(value)
  }
  setSelectedProductGroupName(label)
  setSelectedProductGroupId(id);
}

const handleGetProductGroups = async (attempt = 1)=>{
  console.log(selectedCategoryId,selectedVendorId)
  try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + `product-groups/get-productGroups-by-vendor-and-productCategory?product_category_id=${selectedCategoryId}&&vendor_id=${selectedVendorId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();


        if (data.status === 200 || data.status === 201 || data.status === 204) {
        // Log the data to ensure it's fetched correctly
        console.log('Fetched Product groups:', data);
        const productGroupsData= data.data

        setProductGroups(productGroupsData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleGetProductGroups(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        console.error('Failed to fetch:', error);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleGetProductGroups(attempt)); // Set retry function

      }
    }

}



  const handlePreviousClick = () => {
    onPreviousClick(1); // Call the callback function
};



const handleDone = () => {
  navigate('/bb/app/items/itemslist')
  };

  const handleCloseError = () => {
    setErrorMessages([]);
   // setErrorPopup(false);
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async () => {
    console.log("Retry called");
    setIsLoading(true); // Show loader
    setTimeout(async () => {
      if (retryFunction) {
        await retryFunction(); // Call retry function
      }
      setIsLoading(false); // Hide loader after retry
    }, 5000); // 5-second delay
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleCancel = () => {
    setShowCancelPopup(true);
  }
  const handleStayHere = () => {
    setShowCancelPopup(false);
  }

  const handleLeave = () => {
    navigate(-1);
  }

  const getUoms = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const getUomUrl = `${fetchUrl}UOMs/get-all-UOMs`;
  
      const response = await fetch(getUomUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });

      const data = await response.json();


      if (data.status === 200 || data.status === 201 || data.status === 204) {
      // Log the data to ensure it's fetched correctly
      console.log('Fetched Product groups:', data);
      const output= data.data
      
      const AllUoms= []
      for ( const uom of output){
        const uomData= {
          label: uom.UOM, value: uom._id, id: uom._id
        }
        AllUoms.push(uomData)
        
      }

      setUomOptions(AllUoms)
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => getUoms(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      console.error('Failed to fetch:', error);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => getUoms(attempt)); // Set retry function

    }
  }
  };

  useEffect(() => {
    getUoms();
  }, []);

  if (isLoading) {
    return <Loader />;
  }


  return (
    <div className='flex flex-col w-full justify-center items-center'>
      
      <div className="w-3/4 flex flex-col items-center justify-center mb-4 space-y-5">
      <div className="w-full flex flex-col items-center justify-center">
            {showOverwriteOptions && (
               <div className="w-2/3 flex flex-col items-start justify-center mt-4 mb-5 p-4 bg-[#FEF4EA]">
                    <label className="text-sm font-Regular text-gray-700 mb-3">
                        Choose the field based on which you want to overwrite the items details.
                    </label>
                    <CustomDropdown
                    options={OverwriteItemsOptions}
                    selected={selectedItem}
                    onSelect={handleOverwriteItemDropdownChange}
                    showAddNew={false}
    />
                </div>
            )}
            </div>
        
           <div className='text-sm flex flex-row text-'><p>Your Selected File :</p><p className='ml-1 font-semibold'>{selectedFile.name}</p></div>
        <div className="w-full flex flex-row items-center justify-center">
          <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/4">
            Item Category <span className="text-red-500">*</span>
          </label>
          <div className='w-1/3'>
          <CustomDropdown
            options={productCategories.map(category => ({
              value: category.UOM_id,
              label: category.product_category_name,
              id:category.product_category_id,
            }
          ))}
            selected={selectedCategory ? selectedCategory: ''}
            onSelect={(value, label,id) => onProductCategorySelect(value, label,id)}
            showAddNew={false}
          />
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-center">
          <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700 w-1/4">
            Item Sub-Category 
          </label>
          <div className='w-1/3'>
          <CustomDropdown
            options={(ProductSubCategories || []).map(subCategory => ({
              value: subCategory._id,
              label: subCategory.product_sub_category_name,
              id:subCategory._id
            }))}
            selected={selectedSubCategoryName ? selectedSubCategoryName : ''}
            onSelect={(value, label,id) => onProductSubCategorySelect(value, label,id)}
            showAddNew={false}
          />
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-center">
          <label htmlFor="vendor" className="block text-sm font-regular text-[#e54643] w-1/4">
            Vendor<span className="text-red-500">*</span>
          </label>
          <div className='w-1/3'>
          <CustomDropdown
            options={(vendors || []).map(vendor => ({
              value: vendor._id,
              label: vendor.vendor_name,
              id:vendor._id
            }))}
            selected={selectedVendorName ? selectedVendorName : ''}
            onSelect={(value, label,id) => onSelectedVendor(value,label,id )}
            showAddNew={false} // Adjust as needed
          />
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-center">
          <label htmlFor="ItemGroup" className="block text-sm font-regular text-gray-700 w-1/4">
            Item Group
          </label>
          <div className='w-1/3'>
          <CustomDropdown
            options={(productGroups || []).map(group => ({
              value: group.UOM_id,
              label: group.product_group_name,
              id:group._id
            }))}
            selected={selectedItemGroupName ? selectedItemGroupName : ''}
            onSelect={(value, label,id) => onSelectedProductGroup(value,label,id)}
            showAddNew={false} // Adjust as needed
          />
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-center">
          <label htmlFor="ItemGroup" className="block text-sm font-regular text-gray-700 w-1/4">
            UOM
          </label>
          <div className='w-1/3'>
          <CustomDropdown
            options={uomOptions}
            selected={selectedUOM ? selectedUOM : ''}
            onSelect={(value, label,id) => onSelecteUom(value,label,id)}
            showAddNew={false} // Adjust as needed
          />
          </div>
        </div>
      </div>
      <table className="w-1/2 mt-5 border-t border-gray-200">
        <thead className='bg-[#F9F9FB]'> 
          <tr>
            <th className="text-left text-sm font-semibold text-gray-500 p-2 border-b border-gray-200">Item Details</th>
            <th className="text-left text-sm font-semibold text-gray-500 p-2 border-b border-gray-200">Imported File Headers</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(headerMappings).map((key) => (
            <tr key={key}>
              <td className="text-left text-sm p-2">
                {labels[key]}
                {key === 'product_name' && <span className="text-red-500">*</span>}
              </td>
              <td className="text-left p-2">
                <CustomDropdown
                  options={headers.map(header => ({ value: header, label: header ,id:header}))}
                  selected={headerMappings[key] || ''}
                  onSelect={(value) => handleHeaderSelect(value, key)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='flex flex-col justify-center items-center w-full'>
                                <hr className="border-t border-gray-200 w-full mt-5" style={{ borderTopWidth: '1px' }} />
                                 <div className="w-1/2 ">
                                 <div className="flex justify-between items-center space-x-2 px-6 mt-5 mb-4">
                              {/* New content for step 2 */}
                              <div className="flex justify-center items-center space-x-2 px-6 mt-5 mb-4">
                              <button  className="px-2 py-1 bg-[#f5f5f5] border border-[#DDDDDD] text-gray-700 rounded-md"
                                onClick={() => handlePreviousClick(1)}><FontAwesomeIcon icon={faArrowLeft} className="ml-2 text-sm" /> Previous</button>

                              <button  className="px-2 py-1 bg-[#F7525A] border-[#F7525A] text-white rounded-md" onClick={handleSubmit}>Next 
                              <FontAwesomeIcon icon={faArrowRight} className="ml-2 text-sm" />
                              {isSaving && <Spinner />}
                              </button>

                              </div>
                              <div className="flex justify-center items-center space-x-2 px-6 mt-5 mb-4">
                              <button  className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md"  onClick={handleDone}>Cancel</button>
                            </div>
                              </div>
                              
                            </div>
                            
                            </div>
                         
                         
                            {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
          {popupType === 'ErrorPopup' && <ErrorPopup message={ErrorMSGPopup} closePopup={closePopup} />}
  </div>
)}
{/* {ErrorPopup && (
  <div className='fixed top-12 left-0 w-full h-screen flex items-top justify-center'>
    <div className="bg-[#FEEDEE] rounded-md shadow-md w-[300px] h-[48px] flex flex-row justify-center items-center space-x-2">
      <div className="p-1 rounded-md bg-[#ef5364]">
        <p><FontAwesomeIcon icon={faExclamationTriangle} className="text-md text-white" /></p>
      </div>
      <h2 className="text-md text-black-500">{ErrorMSGPopup}</h2>
      <FontAwesomeIcon icon={faX} className='text-[#F7525A] w-3 h-3 cursor-pointer' onClick={handleCloseError} />
    </div>
  </div>
)} */}
    </div>
  
  );
};

export default BulkUploadStep2;
