import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import '../Css/Animation.css';

const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);
};

const CustomDropdown = ({ options, selected, onSelect,showAddNew }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null);
  
    const filteredOptions = options.filter(option => {
      if (!option || !option.label) return false;
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  
    const handleOptionClick = (value, label, id) => {
      onSelect(value, label, id);
      setIsOpen(false);
      setFocusedOptionIndex(null);
    };
  
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'Enter':
          e.preventDefault();
          if (focusedOptionIndex !== null) {
            const selectedOption = filteredOptions[focusedOptionIndex];
            handleOptionClick(selectedOption.value, selectedOption.label, selectedOption.id);
          }
          break;
        default:
          break;
      }
    };
  
    const scrollToOption = (index) => {
      const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
      if (optionElements && optionElements[index]) {
        optionElements[index].scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    };
  
    useOutsideClick(dropdownRef, () => setIsOpen(false));
    useEffect(() => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isOpen]);
  

  return (
    <div className="relative " ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Select'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {isOpen && (
      <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
        <input
          type="text"
          className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus={isOpen}
        />
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option,index) => (
              <div
                key={option.value}
                data-index={index}
                className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                onClick={() => handleOptionClick(option.value, option.label,option.id)}
              >
                {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                {option.label}
              </div>
            ))
          ) : (
            <div className="p-2 text-sm text-gray-500">
              Not available
            </div>
          )}
        </div>
                    {showAddNew && (
                        <div
                            className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
                            onClick={() => handleOptionClick('add-new', 'Add New')}
                        >
                            {options.find(option => option.value === 'add-new')?.icon && (
                                <FontAwesomeIcon
                                    icon={options.find(option => option.value === 'add-new').icon}
                                    className="mr-2"
                                />
                            )}
                            {options.find(option => option.value === 'add-new')?.label}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
