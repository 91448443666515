import React, { useEffect, useRef, useState } from "react";
import { useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {   faHomeAlt, faGear, faUserCircle,  faPlus, faSearch, faUserGroup, faX, faDoorClosed, faSignOut, } from '@fortawesome/free-solid-svg-icons';
import { FaChevronDown, FaDoorClosed, FaRegBell } from 'react-icons/fa'; // Importing the necessary icons
const Navbar = () =>{
  const navigate = useNavigate();
  const [searchdropDown, setSearchDropDown] = useState(false);
  const [userdropDown ,setUserDropDown] = useState(false);
  const [Type , setType ] = useState('')
  const [userData, setUserData] = useState(null);
  const ref = useRef(null);
  const URLs = {
    'Items' : '',
    'Customers' : '',
    'Vendors' : '',
    'Quotes': '',
  }
  const List = ['Items', 'Customers', 'Vendors', 'Quotes'];

  useEffect(() => {
    // Get user data from session storage
    const storedUserData = sessionStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const handleDropDownToggle = () => {
    setSearchDropDown(!searchdropDown);
  };

  const handleClickItem= (item) => {
    setType(item)
    setSearchDropDown(false)
  }
  const handleChangeSerch = () =>{
    setSearchDropDown(false)
  }

  const handleUserClick= () => {
    setUserDropDown(true);
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchDropDown(false);
      setUserDropDown(false);
    }
  };

  const handleclose = () => {
    setUserDropDown(false);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/measurit_v3.0');
  }


return(
<div className="bg-[#21263c] text-[0.8125rem] font-normal  w-full h-full flex items-center justify-between overflow-hidden py-2">
  <div className="flex flex-row space-x-4">
<div className=' w-[12.5rem] px-3'> 
        <div className=' flex flex-row  '>
             <img className="  hidden md:hidden lg:block w-8 h-8 cursor-pointer" src={'/BB.png'} alt="Brick Bucket Logo" />
  
             </div>
    </div>
    <div className=" w-[18.75rem] flex flex-row space-x-4 items-center h-[2.125rem] bg-[#333850] border-[0.0625rem] border-[#6c7184] rounded-[0.5rem] px-2 hover:border-blue-500 focus-within:border-blue-500">
    <div className="flex text-white space-x space-x-2 text-[1rem] font-light border-r p-1  border-[#6c7184] ">
  <FontAwesomeIcon icon={faSearch} className="h-4 w-4 " />
  <FaChevronDown className="text-blue-400 text-[0.8rem] text-center" onClick={handleDropDownToggle}/>
</div>

  <input
    type="text"
    className=" bg-[#333850] text-white w-full ml-2 py-1 rounded-md focus:outline-none"
    placeholder="Search in Quotes (/)"
    onChange={handleChangeSerch} />
</div>
</div>
        <div className="flex items-center">
          <button className=" text-white px-4 py-2 rounded-md mx-2">Subscribe</button>
          <button className=" text-white px-4 py-2  mx-2">measurit</button>
          <button className="bg-[#E54643] text-white px-1 py-1 rounded-md ">   
            <FontAwesomeIcon icon={faPlus} className="text-xl text-white mx-2 " />
          </button>
          <div className="flex items-center flex-row space-x-6 mx-2">
          <FontAwesomeIcon icon={faUserGroup} className="text-white mx-2" />
          <FaRegBell className="text-white mx-2" /> 

            <FontAwesomeIcon icon={faGear} className="text-white mx-2"/>
            <FontAwesomeIcon icon={faUserCircle} className="cursor-pointer text-4xl text-white mx-2" onClick={handleUserClick}/>
            <FontAwesomeIcon icon={faHomeAlt} className="text-white mx-2"/>
          </div>
        </div>
        {searchdropDown && (
            <div ref={ref} className="absolute left-48 z-10 top-12 bg-white shadow-md border-[#6c7184] rounded-[0.5rem] mt-2 w-80">
              {List.map((item, index) => (
                <div key={index} className="px-4 py-2  text-left hover:bg-blue-500 hover:text-white hover:rounded-md cursor-pointer bg-white"
                onClick={()=>handleClickItem(item)}>
                  {item}
                </div>
              ))}
            </div>
          )}
            {userdropDown && (
            <div ref={ref} className="absolute right-0 z-10 top-12 bg-white shadow-md border-[#6c7184] rounded-[0.5rem] w-96">
              <div className="p-4 bg-[#fbfbfb]">
              <div className="flex flex-col p-2">
              <div className="flex flex-row justify-between pb-2">
              <div className="flex flex-row">
            <div> <img className="rounded-md w-8 h-8 cursor-pointer" src={'/usericon.gif'} alt="Brick Bucket Logo" /></div>
             <div className="mt-2 ml-2">
              <p>{userData.username}</p>
             </div>
</div>
<div className="">
<button title="" data-tooltip="close" style={{ '--tooltip-bg': 'black' }} onClick={handleclose}>
    <FontAwesomeIcon icon={faX} className="cursor-pointer text-md text-[#f7525a] mx-2"/>
  </button>  </div>
</div>
<div className="p-2 border-t border-b flex justify-end">
{/* <div className="text-blue-500">My Account</div> */}
  <div>
  <button onClick={handleLogout}
   className="text-[#f7525a] text-md"><FontAwesomeIcon icon={faSignOut} className="cursor-pointer text-md text-[#f7525a] mx-2"/>
    Sign Out</button></div>
    </div>
</div>
            </div>
            </div>
          )}
      </div>
)
}

export default Navbar;