import React from 'react';
import ReactDOM from 'react-dom';
import MainApp from './mainApp';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './App.css'
import { createRoot } from 'react-dom/client';

const root = document.getElementById('root');
const rootContainer = createRoot(root);
rootContainer.render(
  <React.StrictMode>
    <BrowserRouter>
      <MainApp />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
