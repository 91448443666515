import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faAdd, faCheck, faTimes, faSpinner, faClose  } from "@fortawesome/free-solid-svg-icons";
import Area from "./areas";
import { useNavigate } from "react-router-dom";
const Rooms = ({ flatId,RoomData }) => {
       const [FlatId, setFlatId] = useState();
        const [roomData,setRoomData] = useState([]);
        const [selectedRoom, setSelectedRoom] = useState(null);
        const [SelectedAreaBlocks ,setSelectedAreaBlocks] = useState(null);
        const [SelectedRoomData,setSelectedRoomData]=useState(null);    
        const [areasStatusses , setAreasStatusses] = useState (null)
          // State to hold the sorted room data
  const [sortedRoomData, setSortedRoomData] = useState([]);

    // Sort room data based on roomId when component mounts or roomData changes
    useEffect(() => {
      const sortedData = [...roomData].sort((a, b) => a.roomId - b.roomId);
      console.log('roomdata',roomData)
      console.log(sortedData);
      setSortedRoomData(sortedData);
    }, [roomData]);
        
           useEffect(() => {
           
          //const RoomsData=JSON.parse( localStorage.getItem('RoomsData'))
          setRoomData(RoomData)
          }, [RoomData]);
     
          const Navigate=useNavigate();
          useEffect(()=>{
            const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
            const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
            if(storedLoginData&&Userdata){
              if(storedLoginData.token===Userdata.token){
              //console.log('profile matched')
              }else{
                //console.log('logout')
               Navigate('/measurit')
               sessionStorage.removeItem('userRole');
                  sessionStorage.removeItem('token');
                  sessionStorage.removeItem('userData');
              }
            }else{
              Navigate('/measurit')
                sessionStorage.removeItem('userRole');
                  sessionStorage.removeItem('token');
                  sessionStorage.removeItem('userData');
            }
          })

        const handleRoomClick = async (roomData) => {
         setSelectedRoomData(roomData);
            //setFlatId(flatId)
            //console.log(roomData);
            setSelectedRoom(roomData.roomName === selectedRoom ? null : roomData.roomName);
            
            fetchAreas(roomData.roomId);
          };

          const fetchAreas = async (RoomId) => {
            const allMeasurementData=JSON.parse(localStorage.getItem('measurementData'))||[]
            const allRoomsElectricalMeasurementsfromLocalStorage= JSON.parse(localStorage.getItem('electricalsData'))||[]
            const PerticularRoomElecticalMeasurements=allRoomsElectricalMeasurementsfromLocalStorage.filter(p=>p.roomId===RoomId)||null
            const RemailningRoomElecticalMeasurements=allRoomsElectricalMeasurementsfromLocalStorage.filter(p=>p.roomId!==RoomId)||null
            const AnotherRoomMeasurements=allMeasurementData.filter(p=>p.roomId!==RoomId)||null
            
            const ParticularRoomMeasurements=allMeasurementData.filter(p=>p.roomId===RoomId)||null
            try {
              const token = sessionStorage.getItem('token');
              const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/measurements/get-all-areas?flatId=${flatId}&roomId=${RoomId}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'x-token':token
                },
              });
          
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
          
              const allareas = await response.json();
              ////console.log(allareas);
          
              const areas = allareas.areas;
              //console.log(allareas);
              //console.log(areas);
              setSelectedAreaBlocks(areas)
              //console.log(allareas.measurements);
              const Measurements = allareas.measurements;
              const ElectricalMeasurements=allareas. electricalsmeasurements /* allareas.ElectricalMeasurements|| */
              //console.log(ElectricalMeasurements);
              setFlatId(flatId);
              //console.log(ParticularRoomMeasurements);
              const objectsWithoutId = ParticularRoomMeasurements.filter(item => !('_id' in item));
              const ElectricalobjectsWithoutId = PerticularRoomElecticalMeasurements.filter(item => !('_id' in item));
              //console.log(objectsWithoutId)
              ////console.log(Measurements);
              const updatedDataAreas = [...Measurements, ...AnotherRoomMeasurements,...objectsWithoutId]|| ParticularRoomMeasurements || null;
              const updatedDataofElecricalMeasurements=[...ElectricalMeasurements,...RemailningRoomElecticalMeasurements,...ElectricalobjectsWithoutId]||PerticularRoomElecticalMeasurements||null
              const doorCompleted = updatedDataAreas.filter((p) => p.roomId === RoomId && p.areaId === 1) || null;
              const windowCompleted = updatedDataAreas.filter((p) => p.roomId === RoomId && p.areaId === 2) || null;
              const flooringCompleted = updatedDataAreas.filter((p) => p.roomId === RoomId && p.areaId === 3) || null;
              const wallsCompleted = updatedDataAreas.filter((p) => p.roomId === RoomId && p.areaId === 4) || null;
              const ceilingCompleted = updatedDataAreas.filter((p) => p.roomId === RoomId && p.areaId === 5) || null;
              const electricalsCompleted = updatedDataofElecricalMeasurements.filter((p) => p.roomId === RoomId && p.areaId === 6) || null;
              
              const Areastatuses = {
                  doorStatus: doorCompleted.length !== 0 ? "completed" : "pending",
                  windowStatus: windowCompleted.length !== 0 ? "completed" : "pending",
                  flooringStatus: flooringCompleted.length !== 0 ? "completed" : "pending",
                  wallsStatus: wallsCompleted.length !== 0 ? "completed" : "pending",
                  ceilingStatus: ceilingCompleted.length !== 0 ? "completed" : "pending",
                  electricalsStatus: electricalsCompleted.length !== 0 ? "completed" : "pending",
              }
              
              const Areas = areas.filter(p => p.display === "no");
              if (Areas) {
                  for (const eacharea of Areas) {
                      switch (eacharea.areaId) {
                          case 1:
                              Areastatuses.doorStatus = "completed";
                              break;
                          case 2:
                              Areastatuses.windowStatus = "completed";
                              break;
                          case 3:
                              Areastatuses.flooringStatus = "completed";
                              break;
                          case 4:
                              Areastatuses.wallsStatus = "completed";
                              break;
                          case 5:
                              Areastatuses.ceilingStatus = "completed";
                              break;
                          case 6:
                              Areastatuses.electricalsStatus = "completed";
                              break;
                          default:
                              break;
                      }
                  }
              }
              
              setAreasStatusses(Areastatuses);
              
              localStorage.setItem('areaStatus', JSON.stringify(Areastatuses));
              
          
              const RoomsData = JSON.parse(localStorage.getItem('RoomsData'));
              
              if (!RoomsData) {
                setRoomData(RoomsData)
                // If AreasMeasurements is null or undefined, set the default values
                localStorage.setItem('measurementData', JSON.stringify([...updatedDataAreas]));
                localStorage.setItem('electricalsData', JSON.stringify([...updatedDataofElecricalMeasurements]));
              } else {
                //const RoomAreasMeasurements=Measurements.filter(p=>p.roomId===roomId)
               // const AreasMeasurements= RoomAreasMeasurements.filter(p=>p.areaId===areaId)
                //setArea(AreasMeasurements);
                setRoomData(RoomsData)
                localStorage.setItem('measurementData', JSON.stringify([...updatedDataAreas]));
                localStorage.setItem('electricalsData', JSON.stringify([...updatedDataofElecricalMeasurements]));
              }
          
            } catch (err) {
              clearStorageAndRedirectToHome()
              //console.error('Fetch error:', err.message);
            }
          };

          function clearStorageAndRedirectToHome() {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = '/measurit';
          }
      
      return(
        <div className="ml-5 mr-5 mt-2 flex-grow">
          <div className="rounded-md shadow-sm bg-white grid grid-cols-5 gap-4">
    {sortedRoomData.map((room) => (
  <div
    key={room.roomId}
    className={`m-2 rounded-md flex items-center justify-between p-2 rounded-md shadow-sm cursor-pointer ${selectedRoom === room.roomName ? 'bg-gray-500' : 'bg-gray-300 hover:bg-gray-500'}`}
    onClick={() => handleRoomClick(room)}
  >
    <span>{room.roomName}</span>
    {room.status === 'completed' && (
      <FontAwesomeIcon icon={faCheck} className="text-green-500" />
    )}
    {room.status === 'pending' && (
      <FontAwesomeIcon icon={faTimes} className="text-red-500" />
    )}
    {room.status === 'processing' && (
      <FontAwesomeIcon icon={faSpinner} className="text-blue-500" />
    )}
  </div>
))}

  {/* Add Button */}
  <div className="m-2 hover:bg-gray-400 flex items-center justify-center bg-gray-300 p-2 rounded-md shadow-md">
    <FontAwesomeIcon icon={faAdd} className="text-lg cursor-pointer text-black" />
  </div>
</div>
{SelectedRoomData&&SelectedAreaBlocks&&areasStatusses &&FlatId?(
 
<div className="mt-4 p-4 bg-white rounded-md shadow-md relative">
 
<Area SelectedAreaBlocks={SelectedAreaBlocks}
      selectedRoom={selectedRoom}
      setRoomData={setRoomData}
      areasStatusses={areasStatusses} 
      SelectedRoomData={SelectedRoomData}
      FlatId={FlatId}
      handleRoomClick={handleRoomClick}
      RoomsData={roomData}
  />

</div>
):(
  <div></div>
)

}
        </div>
      )
    };
    export default Rooms;