import * as XLSX from 'xlsx';

const importFromExcel = (file, expectedPricingType, pricelistId) => {
  return new Promise((resolve, reject) => {
    const expectedHeaders = [
      'Price List Item ID',
      'Item Name',
      'Item Price',
      'SKU',
      'GST Included',
      'HSN Code',
      'Type',
      'Custom Rate',
      'Discount',
      'Start Quantity',
      'End Quantity'
    ];

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (!jsonData || !Array.isArray(jsonData)) {
          reject(new Error('Invalid or empty data'));
          return;
        }

        const headers = jsonData[0];
        if (!headers || !Array.isArray(headers)) {
          reject(new Error('Invalid or empty headers'));
          return;
        }

        const unexpectedHeaders = headers.filter(header => !expectedHeaders.includes(header));
        if (unexpectedHeaders.length > 0) {
          reject(new Error('Unexpected headers found: ' + unexpectedHeaders.join(', ')));
          return;
        }

        const typeIndex = headers.indexOf('Type');
        if (typeIndex === -1) {
          reject(new Error('Missing "Type" column in the sheet'));
          return;
        }

        const invalidTypeRows = jsonData.slice(1).filter(row => {
          const typeValue = row[typeIndex];
          return typeValue !== expectedPricingType;
        });

        if (invalidTypeRows.length > 0) {
          reject(new Error(`Sheet does not match the expected pricing type: "${expectedPricingType}"`));
          return;
        }

        // Accumulate pricing data
        const pricingMap = {};

        jsonData.slice(1).forEach(row => {
          const dataObject = {};
          headers.forEach((header, index) => {
            dataObject[header] = row[index];
          });

          const gstIncluded = dataObject['GST Included'];
          const gstIncludedBoolean = typeof gstIncluded === 'string' ? gstIncluded.toLowerCase() === 'true' : Boolean(gstIncluded);

          const itemId = dataObject['Price List Item ID'];
          if (!pricingMap[itemId]) {
            pricingMap[itemId] = {
              price_list_id: pricelistId,
              _id: itemId,
              product_name: dataObject['Item Name'],
              selling_price: parseFloat(dataObject['Item Price']),
              SKU: dataObject['SKU'],
              gst_included: gstIncludedBoolean,
              HSN_Code: dataObject['HSN Code'],
              unit: dataObject['Type'] === 'Unit' ? '' : undefined,
              custom_rate: dataObject['Type'] === 'Unit' ? (parseFloat(dataObject['Custom Rate']) || null) : null,
              discount: dataObject['Type'] === 'Unit' ? (parseFloat(dataObject['Discount']) || null) : null,
              volumePricing: dataObject['Type'] === 'Volume' ? [] : undefined
            };
          }

          if (dataObject['Type'] === 'Volume') {
            if (!Array.isArray(pricingMap[itemId].volumePricing)) {
              pricingMap[itemId].volumePricing = [];
            }
            pricingMap[itemId].volumePricing.push({
              start_quantity: parseFloat(dataObject['Start Quantity']) || null,
              end_quantity: parseFloat(dataObject['End Quantity']) || null,
              custom_rate: parseFloat(dataObject['Custom Rate']) || null,
              discount: parseFloat(dataObject['Discount']) || null
            });
          }
        });

        // Ensure that only the first volumePricing data is kept outside the array
        Object.values(pricingMap).forEach(item => {
          if (item.volumePricing && item.volumePricing.length > 0) {
            // Store first set of volume pricing data outside
            item.custom_rate = item?.volumePricing[0]?.custom_rate;
            item.discount = item?.volumePricing[0]?.discount;
            item.start_quantity = item?.volumePricing[0]?.start_quantity;
            item.end_quantity = item?.volumePricing[0]?.end_quantity;
            
            // Remove the first set from volumePricing array
            item?.volumePricing.shift();
          }
        });

        const dataRows = Object.values(pricingMap);
        resolve(dataRows);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export { importFromExcel };
