 
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faAdd, faCheck, faTimes, faSpinner, faClose  } from "@fortawesome/free-solid-svg-icons";

import {json, useNavigate } from "react-router-dom";
import AreasMeasurements from "./areasMeasurements";

const  Areas=({SelectedAreaBlocks,selectedRoom, setRoomData , 
  areasStatusses, SelectedRoomData, FlatId,
               handleRoomClick, RoomsData})=>{
    const Navigate= useNavigate();
    const [selectedDoor, setSelectedDoor] = useState(null);
    const [selectedWindow, setSelectedWindow] = useState(null);
    const [selectedFlooring, setSelectedFlooring] = useState(null);
    const [selectedWalls, setSelectedWalls] = useState(null);
    const [selectedCeiling, setSelectedCeiling] = useState(null);
    const [selectedElectricals, setSelectedElectricals] = useState(null);
    const [SelectedAreadata,setSelectedAreadata]=useState()
    const[specificAreaData,setspecificAreaData]=useState(false);
   const [selecetedRoomData ,setselecetedRoomData] = useState (null);
   const [roomId, setRoomId]= useState();
  const [areaId,setAreaId]=useState(null);
  const [AreasStatusses,setAreasStatusses]=useState(null);
  const [flatId ,setFlatId] =useState();
  const [alertMessage, setAlertMessage]= useState(null);
  const [FlatCompletedalertMessage,setFlatCompletedalertMessage]= useState(null)
    useEffect(() => {
      ////console.log(SelectedAreaBlocks);
        setSelectedDoor(SelectedAreaBlocks.find(area => area.areaName === 'Doors' && area.display === 'yes'));
        setSelectedWindow(SelectedAreaBlocks.find(area => area.areaName === 'Windows' && area.display === 'yes'));
        setSelectedFlooring(SelectedAreaBlocks.find(area => area.areaName === 'Flooring' && area.display === 'yes'));
        setSelectedWalls(SelectedAreaBlocks.find(area => area.areaName === 'Walls' && area.display === 'yes'));
        setSelectedCeiling(SelectedAreaBlocks.find(area => area.areaName === 'Ceiling' && area.display === 'yes'));
        setSelectedElectricals(SelectedAreaBlocks.find(area => area.areaName === 'Electricals' && area.display === 'yes'));
        setRoomId(SelectedRoomData.roomId)
        setFlatId(FlatId)
        
      }, [SelectedAreaBlocks]);

      useEffect(() => {
      const allRoomsData=JSON.parse(localStorage.getItem('RoomsData')) || []
      const particularRoomData=allRoomsData.find(p=>p.roomId===SelectedRoomData.roomId);
        setselecetedRoomData(SelectedRoomData)
        setspecificAreaData(false);
      }, [SelectedRoomData]);

      useEffect(() => {
       
        setAreasStatusses(areasStatusses)
       // //console.log(areasStatusses);
      }, [areasStatusses]);

      const handleAreaClick = (selectedArea) => {
        setAreaId(selectedArea.areaId);
        setSelectedAreadata(selectedArea);
        setspecificAreaData(true);
       // //console.log(selectedArea.areaId)
       
      };
  

     
          useEffect(()=>{
            const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
            const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
            if(storedLoginData&&Userdata){
              if(storedLoginData.token===Userdata.token){
              ////console.log('profile matched')
              }else{
                ////console.log('logout')
               Navigate('/measurit')
               sessionStorage.removeItem('userRole');
                  sessionStorage.removeItem('token');
                  sessionStorage.removeItem('userData');
              }
            }else{
              Navigate('/measurit')
                sessionStorage.removeItem('userRole');
                  sessionStorage.removeItem('token');
                  sessionStorage.removeItem('userData');
            }
          })
      
      // Function to send all room measurements to the backend
      const sendRoomMeasurementsToBackend = async () => {
        const roomData= JSON.parse(localStorage.getItem('RoomsData'))
      //const selecetedRoomDataData = roomData.find(P => P.roomId === roomId);
      const AllroomMeasurements = JSON.parse(localStorage.getItem('measurementData'));
      const allRoomsElectricalMeasurementsfromLocalStorage=JSON.parse(localStorage.getItem('electricalsData'));
      const roomElectricalMeasurements=allRoomsElectricalMeasurementsfromLocalStorage.filter(P=>P.roomId===selecetedRoomData.roomId)||[]
      const RemainingroomElectricalMeasurements=allRoomsElectricalMeasurementsfromLocalStorage.filter(P=>P.roomId!==selecetedRoomData.roomId)||[]
      ////console.log(AllroomMeasurements);
      const roomMeasurements=AllroomMeasurements.filter(P=>P.roomId===selecetedRoomData.roomId)||[]
      console.log('room Measuremets',roomMeasurements )
      const RemainingroomMeasurements=AllroomMeasurements.filter(P=>P.roomId!==selecetedRoomData.roomId)||[]
      // const AreasMeasurements=roomMeasurements.filter(p=>p.areaId===areaId);
      const noRoomData = { ...selecetedRoomData, flatId: flatId };
      ////console.log(selecetedRoomData);
      if (selecetedRoomData.noRoom === false) {
      try {
        const token = sessionStorage.getItem('token');
      const areastatuses = JSON.parse(localStorage.getItem('areaStatus'));
      
      const statusValuesToCheck = ['pending', 'processing'];
      
      const hasPendingOrProcessingStatus = Object.values(areastatuses).some(status => statusValuesToCheck.includes(status));
      
      if (hasPendingOrProcessingStatus) {
        setAlertMessage('Finish all areas before  proced.')
      //alert('Finish all areas before  proced.');
      } else {
        ////console.log(roomMeasurements);
      
        if (!roomMeasurements || roomMeasurements.length === 0) {
          console.log('No room measurements to send.');
        
        }
      const roomMeasurementsData=[...roomMeasurements, ...roomElectricalMeasurements]
       console.log('total measurements',roomMeasurementsData);
        // Make a single request to send all room measurements to the backend
        const response = await fetch(process.env.REACT_APP_FORE_APILINK+'/measurements/save-measurements', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-token':token
          },
          body: JSON.stringify({
          roomMeasurements: roomMeasurementsData,
          }),
        });
      
        if (!response.ok) {
          throw new Error(`Error sending room measurements to the backend: ${response.statusText}`);
        }
      
       // //console.log('Room measurements sent to the backend successfully.');
      
        const result = await response.json();
       console.log('Data Response:', result);
      
        if (result) {
         // const AreasMeasurements=result.roomMeasurements.filter(p=>p.areaId===areaId);
         // const RemainigAreaMeasurements=result.roomMeasurements.filter(p=>p.areaId!==areaId);
         // const Allareasmeasurements=[...AreasMeasurements,...RemainigAreaMeasurements]
         // setArea(Allareasmeasurements);
          const SelecetedRoomData = result.roomStatus[0];
          ////console.log(selecetedRoomData);
          const index = roomData.findIndex(p => p.roomId === SelecetedRoomData.roomId);
          ////console.log(index);
          const updateselecetedRoomData = [...roomData];
          updateselecetedRoomData[index].status = SelecetedRoomData.status;
          ////console.log(updateselecetedRoomData);
          setselecetedRoomData(updateselecetedRoomData[index]);
          setRoomData(updateselecetedRoomData);
          localStorage.setItem('RoomsData',JSON.stringify(updateselecetedRoomData));
          // Get the index of the currently selected room
         // //console.log(selectedRoom);
          const nextIndex = updateselecetedRoomData.findIndex(room => room.status === 'pending');
        ////console.log(nextIndex);
      
        // Check if the currentIndex is not -1 before proceeding
        if (nextIndex!==-1) {
         // const nextRoomIndex = (nextIndex) % roomData.length;
          const nextRoomData = roomData[nextIndex];
          ////console.log(nextRoomData)
          const AllRoomsAreaMeasurements = JSON.parse(localStorage.getItem('measurementData'))
          const RemailningRoomMeasurements=AllRoomsAreaMeasurements.filter(P=>P.roomId!==selecetedRoomData.roomId);
          localStorage.setItem('measurementData',JSON.stringify(RemailningRoomMeasurements));
          localStorage.setItem('electricalsData',JSON.stringify(RemainingroomElectricalMeasurements));
          //localStorage.removeItem('measurementData');
            localStorage.removeItem('areaStatus');
            setAlertMessage(`Moved to the ${nextRoomData.roomName}`)
          handleRoomClick(nextRoomData);
          
        }else{
        // //console.log('')
          localStorage.removeItem('measurementData');
          localStorage.removeItem('electricalsData');
            localStorage.removeItem('areaStatus');
            localStorage.removeItem('RoomsData');
           
            setFlatCompletedalertMessage('flat completed')
       //alert('flat completed')
       
      
        }
        }
      }
      } catch (error) {
        clearStorageAndRedirectToHome()
      ////console.error('Error sending room measurements to the backend:', error.message);
      }
      } else {
      try {
     // //console.log(noRoomData);
      const token = sessionStorage.getItem('token');
      // Make a single request to send all room measurements to the backend
      const response = await fetch(process.env.REACT_APP_FORE_APILINK+'/measurements/save-measurements', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
        body: JSON.stringify({ noRoomData }),// Wrap noRoomData in an array
      });
      
      if (!response.ok) {
        throw new Error(`Error sending room measurements to the backend: ${response.statusText}`);
      }
      ////console.log('Room measurements sent to the backend successfully.');
      
      const result = await response.json();
     // //console.log('Data:', result);
      
      if (result) {
        //const AreasMeasurements=result.roomMeasurements.filter(p=>p.areaId===areaId);
        //setArea(AreasMeasurements);
        const SelecetedRoomData = result.roomStatus[0];
       // //console.log(selecetedRoomData);
        const index = roomData.findIndex(p => p.roomId === SelecetedRoomData.roomId);
       // //console.log(index);
        const updateselecetedRoomData = [...roomData];
        updateselecetedRoomData[index].status = SelecetedRoomData.status;
        updateselecetedRoomData[index].noRoom = SelecetedRoomData.noRoom;
        ////console.log(updateselecetedRoomData);
        setselecetedRoomData(updateselecetedRoomData[index]);
        setRoomData(updateselecetedRoomData);
        localStorage.setItem('RoomsData', JSON.stringify(updateselecetedRoomData));
      
        // Get the index of the currently selected room
        //console.log(selectedRoom);
    
        const nextIndex = updateselecetedRoomData.findIndex(room => room.status === 'pending' || room.status === 'processing');
      
        //console.log(nextIndex);
      
        // Check if the currentIndex is not -1 before proceeding
        if (nextIndex!==-1) {
         // const nextRoomIndex = (nextIndex) % roomData.length;
          const nextRoomData = roomData[nextIndex];
          //console.log(nextRoomData)
          //localStorage.removeItem('measurementData');
            localStorage.removeItem('areaStatus');
            setAlertMessage(`moved to ${nextRoomData.roomName}`)
          handleRoomClick(nextRoomData);
          
        }else{
       /* localStorage.setItem('measurementData',JSON.stringify(RemainingroomMeasurements));
       localStorage.setItem('measurementData',JSON.stringify(RemainingroomElectricalMeasurements)); */
          localStorage.removeItem('measurementData');
            localStorage.removeItem('areaStatus');
            localStorage.removeItem('RoomsData');
            localStorage.removeItem('electricalsData');
            
            setFlatCompletedalertMessage('flat completed')
            //alert('flat completed')
            
        }
      }
      } catch (error) {
        clearStorageAndRedirectToHome()
      //console.error('Error sending room measurements to the backend:', error.message);
      }
      }
      };
      const handleFlatCompleted=async()=>{
        const updatedFlatStatusData={
          _id:flatId,
          flatMeasurementsStatus:'Completed'
        }
        try{
          const token = sessionStorage.getItem('token');
          //console.log(updatedFlatStatusData)
          const response = await fetch(process.env.REACT_APP_FORE_APILINK+'/flats/update-flat', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-token':token
            },
            body: JSON.stringify(updatedFlatStatusData),// Wrap noRoomData in an array
          });
          if(response.ok){
            Navigate(-1);
          }
        }catch{
          clearStorageAndRedirectToHome()
      //console.error('failed to fetch to updateFlat ')
        }
      }
   
      function clearStorageAndRedirectToHome() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/measurit';
      }
      
        const handleNoRoomData = async () => {
          const allRoomsData = JSON.parse(localStorage.getItem('RoomsData')) || [];
       const allMeasurementData =  JSON.parse(localStorage.getItem('measurementData')) || [];
       const allElectricalMeasurementData =  JSON.parse(localStorage.getItem('electricalsData'))|| [];
       const RemainingRoomsMeasurements = allMeasurementData.filter(p=>p.roomId!==roomId)|| null;
       const RemainingRoomsElectricalMeasurements = allElectricalMeasurementData.filter(p=>p.roomId!==roomId)|| null;
       localStorage.setItem('measurementData', JSON.stringify(RemainingRoomsMeasurements));
       localStorage.setItem('electricalsData', JSON.stringify(RemainingRoomsElectricalMeasurements));
       
          if (selecetedRoomData.noRoom === true) {
            const selectedRoomDataIndex = allRoomsData.findIndex(p => p.roomId === roomId);
            const updateRoomDataStatus = [...allRoomsData];
        
            updateRoomDataStatus[selectedRoomDataIndex].noRoom = false;
            updateRoomDataStatus[selectedRoomDataIndex].status = 'pending';
            setselecetedRoomData(updateRoomDataStatus[selectedRoomDataIndex])
            setRoomData(updateRoomDataStatus);
            localStorage.setItem('RoomsData', JSON.stringify(updateRoomDataStatus));
          } else {
            const selectedRoomDataIndex = allRoomsData.findIndex(p => p.roomId === selecetedRoomData.roomId);
            const updateRoomDataStatus = [...allRoomsData];
        
            updateRoomDataStatus[selectedRoomDataIndex].noRoom = true;
            setselecetedRoomData(updateRoomDataStatus[selectedRoomDataIndex])
            setRoomData(updateRoomDataStatus);
            localStorage.setItem('RoomsData', JSON.stringify(updateRoomDataStatus));
          }
        };
        
    const handleCloseAllert= async()=>{
  setFlatCompletedalertMessage(null);  
  handleFlatCompleted();
    }

    return(
<div>
{alertMessage && (
  <div className="fixed inset-0 flex z-10 items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30"></div>

    {/* Popup content */}
    <div className="w-64 bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-bold mb-2">{alertMessage}</p>
      <button
        className="items-center flex justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
        onClick={() => {
          setAlertMessage(false);
          // Additional handling on OK button click if needed
        }}
      >
        OK
      </button>
    </div>
  </div>
)}
{FlatCompletedalertMessage && (
  <div className="fixed inset-0 flex z-10 items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30"></div>

    {/* Popup content */}
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-bold mb-2">{FlatCompletedalertMessage}</p>
      <button
        className="items-center flex justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
        onClick={() => {
          handleCloseAllert()
          // Additional handling on OK button click if needed
        }}
      >
        OK
      </button>
    </div>
  </div>
)}
           <div className="rounded-md relative">

          <h2 className="flex justify-center text-lg font-semibold mb-4">{selectedRoom}</h2>
{selecetedRoomData&&(
  <div>
 <div className="flex justify-center items-center mb-4">
            <input
              type="checkbox"
              id="noRoomCheckbox"
              checked={selecetedRoomData.noRoom}
              onClick={() => handleNoRoomData()}
              className="mr-2"
            />
            <label htmlFor="noRoomCheckbox">No Room</label>
          </div>
{selecetedRoomData.noRoom===false&&(
  <div className=" grid grid-cols-6 gap-4">
            {!(selecetedRoomData.noRoom) && (
              <>
              {selectedDoor&&(
           <div
           className={`flex items-center justify-between p-2 rounded-md shadow-sm cursor-pointer ${areaId === 1 ? 'bg-gray-500' : 'bg-gray-300 hover:bg-gray-500'}`}
           onClick={(e)=>handleAreaClick(selectedDoor)}>
           <span>Doors</span>
           {AreasStatusses&&(
            <div>
           {AreasStatusses.doorStatus === "processing" &&
<FontAwesomeIcon icon={faSpinner} className="text-blue-500 mr-2" />}
{AreasStatusses.doorStatus === "pending" &&
<FontAwesomeIcon icon={faClose} className="text-red-500 mr-2" />}
{AreasStatusses.doorStatus === "completed" &&
<FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />}
             </div> 
           )}
           
          
         </div>
              )}

{selectedWindow&&(
           <div
           className={`flex items-center justify-between p-2 rounded-md shadow-sm cursor-pointer ${areaId === 2 ? 'bg-gray-500' : 'bg-gray-300 hover:bg-gray-500'}`}
           onClick={(e)=>handleAreaClick(selectedWindow)}>
           <span>Windows</span>
           {AreasStatusses&&(
            <div>
          {AreasStatusses.windowStatus === "processing" &&
<FontAwesomeIcon icon={faSpinner} className="text-blue-500 mr-2" />}
{AreasStatusses.windowStatus === "pending" &&
<FontAwesomeIcon icon={faClose} className="text-red-500 mr-2" />}
{AreasStatusses.windowStatus === "completed" &&
<FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />}
             </div> 
           )}
           
         </div>
              )}

{selectedFlooring &&(
                <div
  className={`flex items-center justify-between p-2 rounded-md shadow-sm cursor-pointer ${areaId === 3  ? 'bg-gray-500' : 'bg-gray-300 hover:bg-gray-500'}`}
  onClick={(e)=>handleAreaClick(selectedFlooring)}>
  <span>Flooring</span>
  {AreasStatusses&&(
            <div>
         {AreasStatusses.flooringStatus === "processing" &&
<FontAwesomeIcon icon={faSpinner} className="text-blue-500 mr-2" />}
{AreasStatusses.flooringStatus === "pending" &&
<FontAwesomeIcon icon={faClose} className="text-red-500 mr-2" />}
{AreasStatusses.flooringStatus === "completed" &&
<FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />}
             </div> 
           )}
</div>
)}

{selectedWalls &&(
                <div
  className={`flex items-center justify-between p-2 rounded-md shadow-sm cursor-pointer ${areaId === 4  ? 'bg-gray-500' : 'bg-gray-300 hover:bg-gray-500'}`}
  onClick={(e)=>handleAreaClick(selectedWalls)}>
  <span>Walls</span>
  {AreasStatusses&&(
            <div>
          {AreasStatusses.wallsStatus === "processing" &&
<FontAwesomeIcon icon={faSpinner} className="text-blue-500 mr-2" />}
{AreasStatusses.wallsStatus === "pending" &&
<FontAwesomeIcon icon={faClose} className="text-red-500 mr-2" />}
{AreasStatusses.wallsStatus === "completed" &&
<FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />}
             </div> 
           )}
  
</div>
)}

{selectedCeiling &&(
                <div
  className={`flex items-center justify-between p-2 rounded-md shadow-sm cursor-pointer ${areaId === 5  ? 'bg-gray-500' : 'bg-gray-300 hover:bg-gray-500'}`}
  onClick={(e)=>handleAreaClick(selectedCeiling)}>
  <span>Ceiling</span>
  {AreasStatusses&&(
            <div>
   {AreasStatusses.ceilingStatus === "processing" &&
<FontAwesomeIcon icon={faSpinner} className="text-blue-500 mr-2" />}
{AreasStatusses.ceilingStatus === "pending" &&
<FontAwesomeIcon icon={faClose} className="text-red-500 mr-2" />}
{AreasStatusses.ceilingStatus === "completed" &&
<FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />}
             </div> 
           )}
  
</div>
)}

{selectedElectricals &&(
                <div
  className={`flex items-center justify-between p-2 rounded-md shadow-sm cursor-pointer ${areaId === 6  ? 'bg-gray-500' : 'bg-gray-300 hover:bg-gray-500'}`}
  onClick={(e)=>handleAreaClick(selectedElectricals)}>
  <span>Electricals</span>
  {AreasStatusses&&(
            <div>
   {AreasStatusses.electricalsStatus === "processing" &&
<FontAwesomeIcon icon={faSpinner} className="text-blue-500 mr-2" />}
{AreasStatusses.electricalsStatus === "pending" &&
<FontAwesomeIcon icon={faClose} className="text-red-500 mr-2" />}
{AreasStatusses.electricalsStatus === "completed" &&
<FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />}
             </div> 
           )}
</div>
)}
     </>
  )}
  </div>
)}     
  </div>
)}
 </div>

{specificAreaData&&AreasStatusses&& selecetedRoomData.noRoom===false &&setRoomData&&SelectedAreaBlocks&&SelectedAreadata&&(
    <div className="bg-white rounded-md shadow-md relative mt-4 gap-4 p-4 flex justify-center items-center h-auto">
     
      <AreasMeasurements
       areasStatusses={AreasStatusses}
       setAreasStatusses={setAreasStatusses}
       handleAreaClick={handleAreaClick}
       RoomData={selecetedRoomData}
       selectedAreadata={SelectedAreadata}
       FlatId={flatId}
       setRoomData={setRoomData}
       SelectedAreaBlocks={SelectedAreaBlocks}
      />
    </div>
  )}
   {/* Areas Measurements Input fields ends */}
   <div className="mt-2 flex justify-center">
    
      <button className="bg-[#0f9d58] text-white text-md p-1 mt-2 rounded-md hover:bg-green-600 focus:outline-none focus:shadow-outline-blue active:bg-[#0f9d58]"
     onClick={sendRoomMeasurementsToBackend}>
       Proceed to the Next Room 
    </button>
    
    </div>
  </div>
    )
}
export default Areas;