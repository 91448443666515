import React from 'react';

const formatNumber = (value) => {
  // Parse the input as a number
  const parsedValue = parseFloat(value);

  // Check if the parsed value is a finite number
  if (Number.isFinite(parsedValue)) {
    // Round to two decimal places
    const roundedValue = (Math.round(parsedValue * 100) / 100);
    // Format the number with commas and two decimal places
    return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
   }

  // Return '0.00' if the value is not a valid number
  return '0.00';
};

const SalesOrderHtmlDocument = ({ salesorder }) => (
  <div className="p-8 flex flex-col"
    style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}  
  >
    <div className="flex justify-between mb-4 text-xs">
      <div className="flex-1">
        <h1 className="font-bold text-md">Brickbucket</h1>
        <p>Andhra Pradesh</p>
        <p>India</p>
        <p>GSTIN 37AAKCK7567P1ZG</p>
        <p>info@brickbucket.com</p>
        <div className="mt-10">
        <h1 className='font-semibold '>Bill To</h1>
        </div>
        <p className="font-semibold"> {salesorder?.customer_id?.company_name}</p> 
        <p className=""> {salesorder?.customer_id?.customer_name}</p>
        <p className=""> {salesorder?.customer_id?.address}</p>
          <p className=""> {salesorder?.customer_id?.city}</p>
        <p className=""> {salesorder?.customer_id?.state}</p> 
        <div className="mt-1 flex flex-row">
          <h1 className=''>GST: </h1>
          <p className=""> {salesorder?.customer_id?.gstin}</p>
        </div>
        <p className=""> {salesorder?.customer_id?.email}</p> 
        <p className=""> {salesorder?.customer_id?.mobile_number}</p> 
      </div>

      <div className="flex-1 mt-2 text-right">
        <h1 className="text-2xl font-semibold mb-0">Sales Order</h1>
        <h1 className="text-md mt-1 font-semibold mb-0">{salesorder?.salesorder_number}</h1>
        <h1 className="text-sm mt-5 font-semibold">
          {new Date(salesorder?.salesorder_date).toLocaleDateString()}
        </h1>
        <h1 className="text-md mt-1 font-semibold mb-0">{salesorder?.salesorder_status}</h1>
      </div>
    </div>

    <div className="mb-4 p-2 text-xs">
      <table className="w-full border-collapse">
        <thead className='bg-[#3C3D3A] text-white p-2 h-12 text-left'>
          <tr>
            <th className="text-center">#</th>
            <th>Items</th>
            <th>Item Category</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Tax</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {salesorder?.items?.length > 0 ? (
            salesorder.items.map((item, index) => (
              <React.Fragment key={index}>
                <tr className='border-b p-2 h-12 text-left'>
                  <td className="p-1 text-center">{index + 1}</td>
                  <td className="p-1">{item?.item_name}</td>
                  <td className="p-1">{salesorder?.product_category_id?.product_category_name}</td>
                  <td className="p-1">{item?.quantity} {item?.UOM}</td>
                  <td className="p-1">₹{item?.selling_price}</td>
                  <td className="p-1">{item?.tax_rate}%</td>
                  <td className="p-1">₹{formatNumber(item?.sales_total)}</td>
                </tr>
                {item.required_order_breakup?.map((breakup, breakupIndex) => (
                  <tr key={breakupIndex} className='border-b p-2 h-8 text-left '>
                    <td className="p-1 text-center"></td>
                    <td colSpan="2" className="p-1 pl-4">{breakup?.sub_item_name}</td>
                    <td className="p-1">{breakup?.sub_item_quantity} {item?.UOM}</td>
                    <td colSpan="3" className="p-1"></td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center p-2">No items available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>

    <div className="flex justify-end mb-4">
      <div className="text-right p-2 text-xs">
        <div className="mb-2 flex justify-between space-x-20 p-2">
          <p className="text-sm font-semibold">Sub Total:</p>
          <p className="text-sm font-semibold">₹{formatNumber(salesorder?.subtotal)}</p>
        </div>
        <div className="flex justify-between p-2">
          <p className="text-sm font-regular">Discount:</p>
          <p className="text-sm font-regular">{salesorder?.discount}%</p>
        </div>
        <div className="mb-2 flex justify-between p-2">
          <p className="text-sm font-regular">Tax Amount:</p>
          <p className="text-sm font-regular">₹{formatNumber(salesorder?.tax_amount)}</p>
        </div>
        <div className="flex justify-between bg-[#F5F4F3] p-2">
          <p className="text-sm font-bold">Total:</p>
          <p className="text-sm font-regular">₹{formatNumber(salesorder?.total)}</p>
        </div>
      </div>
    </div>

    <div className="mb-4 p-2 text-xs">
    {/*   <h3 className="font-regular">Notes</h3>
      <h3 className="font-regular mt-5">Looking forward for your business.</h3> */}

      <div className="mt-6 flex items-center">
        <h3 className="text-sm font-regular mr-4 flex-shrink-0">Authorized Signature</h3>
        <div className="flex-grow">
          <div className="border-t border-black" style={{ width: '150px', height: '1px', marginTop: '1rem' }}></div>
        </div>
      </div>
    </div>
  </div>
);

const SalesOrderPdfViewer = ({ salesorder }) => (
  <div className="h-screen">
    <SalesOrderHtmlDocument salesorder={salesorder} />
  </div>
);

export default SalesOrderPdfViewer;
