import * as XLSX from 'xlsx';

const importFromExcel = (file, pricingSchema) => {
  return new Promise((resolve, reject) => {
    const expectedHeaders = [
      'Price List Item ID',
      'Item Name',
      'Item Price',
      'SKU',
      'GST Included',
      'HSN Code',
      'Type',
      'Custom Rate',
      'Discount',
      'Start Quantity',
      'End Quantity'
    ];

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = jsonData[0];
        const extraHeaders = headers.filter(header => !expectedHeaders.includes(header));
        if (extraHeaders.length > 0) {
          reject(new Error('Unexpected headers found: ' + extraHeaders.join(', ')));
          return;
        }

        // Validate 'Type' column
        const typeIndex = headers.indexOf('Type');
        if (typeIndex === -1) {
          reject(new Error('Missing "Type" column in the sheet'));
          return;
        }

        const invalidTypeRows = jsonData.slice(1).filter(row => {
          const typeValue = row[typeIndex];
          return typeValue !== pricingSchema;
        });

        if (invalidTypeRows.length > 0) {
          reject(new Error(`Please select a sheet with "${pricingSchema}" pricing data`));
          return;
        }

        const dataRows = jsonData.slice(1).map(row => {
          const dataObject = {};
          headers.forEach((header, index) => {
            dataObject[header] = row[index];
          });

          
          if (dataObject['Type'] === 'Unit') {
          const  pricing = {
            price_list_item_id: dataObject['Price List Item ID'],
            product_name: dataObject['Item Name'],
            selling_price: parseFloat(dataObject['Item Price']),
            SKU: dataObject['SKU'],
            gst_included: dataObject['GST Included'] === 'true',
            HSN_Code: dataObject['HSN Code'],
              unit: '',
              customRate: parseFloat(dataObject['Custom Rate']) || null,
              discount: parseFloat(dataObject['Discount']) || null
            };
            return pricing
          } else if (dataObject['Type'] === 'Volume') {
           const  pricing = {
           price_list_item_id: dataObject['Price List Item ID'],
            product_name: dataObject['Item Name'],
            selling_price: parseFloat(dataObject['Item Price']),
            SKU: dataObject['SKU'],
            gst_included: dataObject['GST Included'] === 'true',
            HSN_Code: dataObject['HSN Code'],
              startQuantity: parseFloat(dataObject['Start Quantity']) || null,
              endQuantity: parseFloat(dataObject['End Quantity']) || null,
              customRate: parseFloat(dataObject['Custom Rate']) || null,
              discount: parseFloat(dataObject['Discount']) || null
            };
            return pricing
          }

         
        });

        resolve(dataRows);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export { importFromExcel };
