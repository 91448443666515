import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faExclamationTriangle ,faTrash, faX, faPlusCircle, faChevronDown, faCheckCircle, faCheck, faCog, faFileExport, faFileImport, faPlus, faClose } from '@fortawesome/free-solid-svg-icons';
import '../Css/Animation.css';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { useNavigate } from 'react-router-dom';
import TableSearchDropDown from '../Components/tableSerchDropDown';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { validate } from '../CommenResponseAndErrorHandlers/commenUiErrorHandlers';
import { exportToExcel } from '../Components/ExcelExport';
import * as XLSX from 'xlsx';
import { importFromExcel } from '../Components/ImportPriceListExcel';

//import Dropdown from './DropDown'
// Custom hook to handle outside clicks
 const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect,showAddNew }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (value, label, id) => {
    onSelect(value, label, id);
    setIsOpen(false);
    setFocusedOptionIndex(null);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!isOpen) { 
          setIsOpen(true);
        }
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (!isOpen) { 
          setIsOpen(true);
        }
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption.value, selectedOption.label, selectedOption.id);
        }
        break;
      default:
        break;
    }
  };

  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative w-1/4" ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Select'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {isOpen && (
      <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
        <input
          type="text"
          className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus={isOpen}
        />
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={option?.value}
                data-index={index}
                className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                onClick={() => handleOptionClick(option?.value, option?.label,option?.id)}
              >
                {option?.icon && <FontAwesomeIcon icon={option?.icon} className="mr-2" />}
                {option?.label}
              </div>
            ))
          ) : (
            <div className="p-2 text-sm text-gray-500">
              Not available
            </div>
          )}
        </div>
        {showAddNew && (
          <div
            className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
            onClick={() => handleOptionClick('add-new', 'Add New')}
          >
            {options.find(option => option?.value === 'add-new')?.icon && (
              <FontAwesomeIcon
                icon={options.find(option => option?.value === 'add-new').icon}
                className="mr-2"
              />
            )}
            {options.find(option => option?.value === 'add-new')?.label}
          </div>
        )}
      </div>
    )}
  </div>
  );
}; 

const NewPriceList = () => {
    const navigate = useNavigate();
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [productCategories, setProductCategories] = useState([]);
    const [selectedProductCategory, setSelectedProductCategory] = useState({ id: '', name: '' });
    //console.log(selectedProductCategory)
    const [errors, setErrors] = useState({});
    const [selectedPercentageOption, setSelectedPercentageOption] = useState({ label: 'Markup', value: 'markup' });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const percentageOptions = [
        { label: 'Markup', value: 'markup' },
        { label: 'Markdown', value: 'markdown' },
      ];
      const roundOffOptions = [
       { value: 'never-mind', label: 'Never Mind' }, // Add this option
      ];

      const CurrencyOptions = [
        { value: 'inr', label: 'INR - Indian Rupees' }
      ];
      

      const [SelectedRoundoffOption, setSelectedRoundoffOption] = useState();
      const [selectedCurrency, setSelectedCurrency] = useState('INR - Indian Rupees');
      const [isDiscountChecked, setIsDiscountChecked] = useState(false);
      const [isToggled, setIsToggled] = useState(false);
      const fileInputRef = useRef(null);
      const [selectedFileName, setSelectedFileName] = useState('');
      const [pricingSchema, setPricingSchema] = useState('Unit'); // Default value
      const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET
      const [data, setData] = useState([
        
      ]);
      const [selectedOption, setSelectedOption] = useState('individualItems');
      const [priceListName, setPricelistName ] = useState ('')
      const [typeOfPriceList, setTypeOfPriceList] = useState ('Sales') 
      const [pricelistDescription, setPricelistDescription]= useState('')
      const [currency, setcurrency]= useState('')
      const [products, setProducts]= useState([])
      const [selectedImportExcelFile, setSelectedImportExcelFile] = useState(null);
      const [importMessage, setImportMessage] = useState('');
      const [importedData, setImportedData] = useState(null);
      const [SucessImportMessage , setSucessImportMessage]= useState('')
      const [ErrorInImportingFile, setErrorInImportingFile]= useState(false)
      const { 
        showPopup, popupMessage, popupType, retryFunction,
        isSaving , setIsLoading ,setPopupMessage,setPopupType,handleGetResponse, handleSaveResponse, setShowPopup,
        setRetryFunction, handleDeleteResponse, setIsSaving  } = useCommonResponseHandler();
        const RETRY_LIMIT = 3; // Maximum number of retries
        const [retryCount, setRetryCount] = useState(0); 
        const [showCancelPopup, setShowCancelPopup] = useState(false);

      useEffect(() => {
   
        fetchProductCategories();
      }, [fetchUrl]);
    
      const fetchProductCategories = async (attempt = 1) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
          const output = await handleGetResponse(response , data)
          if (output) {
            setProductCategories(output); // Update productCategories state
          } else {
            console.error(data.message);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
          } else {
           
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
          }
        }/* finally {
          setIsLoading(false);
        } */
      };
    
      const addNewRange = (index) => {
        const newData = [...data];
        newData[index].Volumepricing.push({
          startQuantity: '',
          endQuantity: '',
          customRate: '',
          gst: 'No',
        });
        setData(newData);
      };

      const handleDeleteVolumePricing = (index, subIndex) => {
        // Create a copy of the data array
        const newData = [...data];
      
        // Check if the item at the specified index and the Volumepricing at subIndex exist
        if (newData[index] && newData[index].Volumepricing) {
          // Remove the Volumepricing entry at the specified subIndex
          newData[index].Volumepricing.splice(subIndex, 1);
      
          // Update the state with the modified data array
          setData(newData);
        }
      };
      
    
      const handleInputChange = (index, field, value, subIndex = null) => {
        const newData = [...data];
        if (subIndex !== null) {
          newData[index].Volumepricing[subIndex][field] = value;
        } else {
          newData[index][field] = value;
        }
        setData(newData);
      };

      const handleCheckboxChange = (index, field, value) => {
        const newData = [...data];
        newData[index][field] = value;
        setData(newData);
      };

      const handleToggle = () => {
        setIsToggled(!isToggled);
        setErrorInImportingFile(false)
        setSelectedImportExcelFile(null)
      };

  const handleCurrencySelect = (value, label) => {
    setSelectedCurrency(label);
    //console.log('Selected Currency:', label); // You can handle the selected currency as needed
  };

  const handleDiscountChange = () => {
    setIsDiscountChecked(!isDiscountChecked);
  };
  
    const handleOptionClick = (option) => {
      setSelectedPercentageOption(option);
      setIsDropdownOpen(false);
    };


    // Handle circle click to set the selected option
    const handleCircleClick = (option) => {
      // Log the option to ensure the correct one is clicked
      //console.log('Option clicked:', option);
      setSelectedOption(option);
    };

    const handleProductCategorySelect = (value, label) => {
        setSelectedVendor({})
        setProducts([])
        setSelectedProductCategory({ id: value, name: label }); // Store ID and label correctly
        fetchVendors(value)
      };

      const handleVendorClick = (id)=> {
      fetchProductsByVendorAndProductCategory(id)
      }

      const fetchProductsByVendorAndProductCategory = async (vendorId ,attempt = 1) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(fetchUrl + `product/get-products-by-category-and-vendor?product_category_id=${selectedProductCategory.id}&vendor_id=${vendorId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
          const output = await handleGetResponse(response , data)
          //console.log('Fetched Data:', response);
         // console.log('Fetched Data:', data);
          if (output) {
  
          setProducts(output);
          const pricelistProducts = output.map(p => ({
            product_name : p?.product_name,  selling_price: p?.selling_price , price_list_item_id : p?._id, SKU:p.SKU,
            HSN_Code:p?.HSN_Code, customRate: '', gst_included: false , discount: '' , Volumepricing: []
          }))
        // console.log(pricelistProducts)
          setData(pricelistProducts)
          } else {
            setData([])
            setProducts([]);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchProductsByVendorAndProductCategory(vendorId,attempt + 1), 1000); // Retry after 1 second
          } else {
         
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchProductsByVendorAndProductCategory(vendorId,attempt)); // Set retry function
          }
        }
      };


      const fetchVendors = async (product_category_id,attempt = 1) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(fetchUrl + `vendor/get-vendorsBy-productCategoryId?product_category_id=${product_category_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
          const output = await handleGetResponse(response , data)
           
        //console.log('Fetched Data:', data);
          if (output) {
          setVendors(output);
          } else {
            setVendors([]);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchVendors(product_category_id,attempt + 1), 1000); // Retry after 1 second
          } else {
            
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchVendors(product_category_id,attempt)); // Set retry function
          }
        }
      };
 

   
      
      const handleChange = (type) => {
        setPricingSchema(type);
        const pricelistProducts = products.map(p => ({
          product_name : p?.product_name,  selling_price: p?.selling_price , price_list_item_id : p?._id,
          customRate: '', gst_included: false , discount: '' , Volumepricing: []
        }))
        setData(pricelistProducts)
      };

      
      const closePopup = () => {
        //console.log("closePopup called");
        setShowPopup(false);
        setPopupType('');
      };
      
      const Retry = async() => {
        //console.log("Retry called");
        setPopupType('');
        setTimeout(async ()=>{
          if(retryFunction){
      
            await retryFunction ();
          }
      
       
        },1000)
        
        setShowPopup(false);
      };
      
      const BackToLoginPage = () => {
       // console.log("Back To Login Page");
        setShowPopup(false);
        localStorage.removeItem('LoginData');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('appId');
      navigate('/bb');
      };
      
     /*  const handleStayHere = () => {
        setShowCancelPopup(false);
      } */
        const handleSavePriceList = async () => {
          const priceListData = {
            price_list_name: priceListName,
            product_category_id: selectedProductCategory?.id,
            vendor_id: selectedVendor?.id,
            type: typeOfPriceList,
            isActive: false,
            used_in_quotation: false,
            pricing_type: pricingSchema,
            applied_type : selectedOption,
            description: pricelistDescription,
            organizationId: sessionStorage.getItem('organizationId'),
          };
          //console.log('Exel',data)
          const pricelistProductRatesData = await handleSetPriceListProductRatesData(data);
          //console.log(pricelistProductRatesData)
          const combinedData = { priceListData, pricelistProductRatesData };
          
          handleSubmitPricelistData(combinedData);
        };
        const handleSetPriceListProductRatesData = async (pricelistData) => {
  
          const filteredData = pricelistData.filter(p => 
            (pricingSchema === 'Unit' && (p?.customRate || p?.discount)) ||
            (pricingSchema === 'Volume' && (p?.customRate || p?.discount||p?.Volumepricing.some(vp => vp?.customRate || vp?.discount)))
          );
        
          const productRates = await Promise.all(
            filteredData.map(async (p) => ({
              selling_price: parseFloat(p.selling_price),
              price_list_item_id: p.price_list_item_id,
              gst_included: p.gst_included,
              pricing: await handleAddInPricing(p),
            }))
          );
          
          return productRates;
        };
        
        const handleAddInPricing = async (pricingData) => {
          if (pricingSchema === 'Unit') {
            const data = {};
            if (pricingData.customRate) data.custom_rate = parseFloat(pricingData.customRate);
            if (pricingData.discount) data.discount = parseFloat(pricingData.discount);
            data.unit = '';
            
            return data;
          } else if (pricingSchema === 'Volume') {
            const volumePricing = await Promise.all(
              (pricingData?.Volumepricing || []).map(async (p) => {
                const vp = {};
                if (p.startQuantity) vp.start_quantity = parseFloat(p.startQuantity);
                if (p.endQuantity) vp.end_quantity = parseFloat(p.endQuantity);
                if (p.customRate) vp.custom_rate = parseFloat(p.customRate);
                if (p.discount) vp.discount = parseFloat(p.discount);
                return vp;
              })
            );
        
            const totalVolumePricing = [
              ...volumePricing,
              {
                ...(pricingData?.startQuantity && { start_quantity: parseFloat(pricingData.startQuantity) }),
                ...(pricingData?.endQuantity && { end_quantity: parseFloat(pricingData.endQuantity) }),
                ...(pricingData?.customRate && { custom_rate: parseFloat(pricingData.customRate) }),
                ...(pricingData?.discount && { discount: parseFloat(pricingData.discount) })
              }
            ];
        
            return totalVolumePricing;
          }
        };
        
        
        const handleSubmitPricelistData = async (data, attempt = 1) => {
         // console.log(data);
          
          // Define the fields to be validated
          const fields = [
            { name: 'vendor', value: data?.priceListData?.vendor_id, required: true, message: 'Vendor is required', usage: 'vendor' },
            { name: 'product_category', value: data?.priceListData?.product_category_id, required: true, message: 'Item category is required', usage: 'product category' },
            { name: 'price_list_name', value: data?.priceListData?.price_list_name, required: true, message: 'Price List name is required', usage: 'price list' },
            { name: 'import_data', value: !ErrorInImportingFile, required: true, message: 'Error importing the file. Please check the file format and try again.', usage: 'import data' },
          ];
          
          if (isToggled) {
            const FileErrorMessage = { name: 'import_file', value: selectedImportExcelFile?.fileName, required: true, message: 'Please upload the file.', usage: 'import file' };
            fields.push(FileErrorMessage);
          }
          
        
          // Validate the fields
          const validationErrors = validate(fields);
          setErrors(validationErrors);
        
          // Proceed only if there are no validation errors
          if (Object.keys(validationErrors).length === 0) {
            setIsSaving(true);
            
            try {
              // Fetch token and URL for the request
              const token = sessionStorage.getItem('token');
              const saveProductUrl = `${fetchUrl}pricelist/save-pricelist`;
        
              // Send the POST request
              const response = await fetch(saveProductUrl, {
                method: 'POST',
                headers: {
                  'x-token': token,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
              });
        
              // Parse the response
              const responseData = await response.json();
              const output = await handleSaveResponse(response, responseData);
        
              if (output) {
                setTimeout(() => {
                  setShowPopup(false);
                  navigate('/bb/app/items/viewpricelists')
                }, 2000);
              } else {
                console.error(responseData.message || 'Unknown error');
              }
            } catch (error) {
              console.error('Failed to fetch:', error);
        
              if (attempt < RETRY_LIMIT) {
                setRetryCount(attempt + 1); // Increment retry count
                setTimeout(() => handleSubmitPricelistData(data, attempt + 1), 1000); // Retry after 1 second
              } else {
                setIsLoading(false);
                setPopupMessage('Internal Server Error. Kindly Retry.');
                setPopupType('500');
                setShowPopup(true);
                setRetryFunction(() => () => handleSubmitPricelistData(data, attempt)); // Set retry function
              }
            } finally {
              setIsSaving(false);
            }
          } else {
            // Log or display the validation errors
           // console.log(validationErrors);
          }
        };

        
        const handleExportPriceList = async () =>{
          const fields = [
            { name: 'vendor', value: selectedVendor?.id , required: true, message: 'Vendor is required', usage: 'vendor' },
            { name: 'product_category', value: selectedProductCategory?.id, required: true, message: 'Item category is required', usage: 'product category' },
          ];
          
       //console.log( pricelistProductRatesData , data)
       const validationErrors = validate(fields);
       setErrors(validationErrors);
     
       // Proceed only if there are no validation errors
       if (Object.keys(validationErrors).length === 0) {
        const pricelistProductRatesData = await handleConvertProductRatesData();
       exportToExcel(pricelistProductRatesData, pricingSchema);
       }
        }
      
        const handleConvertProductRatesData = async () => {       
          const productRates = await Promise.all(
            data.map(async (p) => ({
              product_name:p?.product_name,
              SKU: p?.SKU,
              HSN_Code:p?.HSN_Code,
              selling_price: parseFloat(p?.selling_price),
              price_list_item_id: p?.price_list_item_id,
              gst_included: p?.gst_included,
              pricing: await handleAddInPricing(p),
            }))
          );
          
          return productRates;
        };

       

        const handleFileChange = (e) => {
          const file = e.target.files[0];
          if (file) {
            setSelectedImportExcelFile({ fileName: file?.name });
            importFromExcel(file ,pricingSchema)
              .then(data => {
                //console.log(data);
                setImportedData(data);
                setData(data)
                setImportMessage('');
                setSucessImportMessage('File is imported successfully. Please save the data.')
                setErrorInImportingFile(false)
              })
              .catch(err => {setImportMessage(`Import failed: ${err.message}`); setSucessImportMessage (''); setErrorInImportingFile(true)});
          }
        };
      
        const handleImportClick = () => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        };

        const handleCancel = () => {
          setShowCancelPopup(true);
        }

        const handleStayHere = () => {
          setShowCancelPopup(false);
        }
        const handleLeave = () => {
          navigate(-1);
        }

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
  <div className="flex flex-row w-full">
    <div className="w-full flex-1">
      <div className="flex flex-col w-full h-full justify-center items-center">
        <div className="w-full h-full flex flex-col justify-between">
          <div className="w-full h-16 border-b flex items-center">
            <div className="flex w-full justify-between px-4 items-center">
              <h1 className="text-lg font-semibold">New Price List</h1>
             {/*  <button className="text-white px-1 py-1 rounded-md">
                <FontAwesomeIcon icon={faX} className="text-md text-[#F7525A] cursor-pointer" />
              </button> */}
            </div>
            </div>
            {Object.keys(errors).length > 0 && (
        <div className="flex justify-between  w-full p-2 items-center px-auto py-3 bg-[#fddcde] rounded-md">{console.log(errors)}
          <div className="px-6 flex flex-col space-y-2 justify-start items-start">
            {errors?.vendor && (
              <span className="text-sm">
                • {errors.vendor}
              </span>
            )}
            {errors?.product_category && (
              <span className="text-sm">
                • {errors.product_category}
              </span>
            )}
            {errors?.price_list_name && (
              <span className="text-sm">
                • {errors.price_list_name}
              </span>
            )}
            {errors?.import_file && (
              <span className="text-sm">
                • {errors.import_file}
              </span>
            )}
          </div>
          <div className='px-4'>
            <button
              className="text-gray-700 hover:text-red-500"
              onClick={() => setErrors({})}
            >
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )}

          <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
          <div className="w-full flex flex-col justify-start items-start px-4">
          <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-20">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                  Item Category <span className="text-red-500">*</span>
                </label>           
                  <CustomDropdown
                    options={productCategories.map(category => ({
                      value: category?._id,
                      label: category?.product_category_name
                    }))}
                    selected={selectedProductCategory ? selectedProductCategory.name : ''}
                    onSelect={(value, label) => handleProductCategorySelect(value, label)}
                    showAddNew={false}                 />
              </div>
               {/* vendor Label and Input */}
               <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Vendor<span className="text-red-500">*</span>
                      </label>
                      <CustomDropdown
              options={(vendors || []).map(vendor => ({
                value: vendor?._id,
                label: vendor?.name,
              }))}
              selected={selectedVendor ? selectedVendor.name : ''}
              onSelect={(value, label) => {setSelectedVendor({ id: value, name: label }); handleVendorClick(value)}}
              showAddNew={false} // Adjust as needed
            />
                    </div>

                     {/* Product Category Name Label and Input */}
                     <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                      <label htmlFor="name" className="block text-sm font-regular text-[#e54643] w-1/6">
                      Price List Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="name" 
                        className="w-1/4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                        value={priceListName}
                       onChange={(e)=>setPricelistName(e.target.value)} 
                      />
                    </div>

                     {/* Product Category Name Label and Input */}
                     <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
  <label htmlFor="name" className="block text-sm font-regular text-gray-700 w-1/6">
    Transaction Type
  </label> 
  <div className="flex items-center">
    <input type="radio" name="Sales" checked={typeOfPriceList === 'Sales'} value={typeOfPriceList === 'Sales'} className="mr-1" onChange={()=>setTypeOfPriceList('Sales')} />
    <span className="text-sm font-regular text-gray-700">Sales</span>
  </div>
  <div className="flex items-center">
    <input type="radio" name="Purchase" checked={ typeOfPriceList === 'Purchase'} value={typeOfPriceList === 'Purchase'} className="mr-1"  onChange={()=>setTypeOfPriceList('Purchase')} />
    <span className="text-sm font-regular text-gray-700">Purchase</span>
  </div>
</div>

<div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
      <label htmlFor="name" className="block text-sm font-regular text-gray-700 w-1/6">
        Price List Type
      </label>
      
      <div className="flex items-start bg-[#F9F9FB] p-2 rounded-md hover:border-blue-500 hover:border cursor-pointer" onClick={() => handleCircleClick('allItems')}>
        <div 
          className={`relative flex items-center justify-center w-5 h-5 border rounded-full ${selectedOption === 'allItems' ? 'bg-blue-500 border-blue-500' : 'bg-white border-gray-400'}`}
          
        >
          <FontAwesomeIcon 
            icon={faCheck} 
            className={`absolute ${selectedOption === 'allItems' ? 'text-white' : 'text-gray-400'}`} 
            style={{ fontSize: '12px' }}
          />
        </div>
        <div className="ml-2">
          <span className="text-sm font-semibold block">All Items</span>
          <span className="text-xs font-regular text-gray-700 block mt-1">Mark up or mark down the rates of all items</span>
        </div>
      </div>

      <div className="flex items-start bg-[#F9F9FB] p-2 rounded-md hover:border-blue-500 hover:border cursor-pointer" onClick={() => handleCircleClick('individualItems')}>
        <div 
          className={`relative flex items-center justify-center w-5 h-5 border rounded-full ${selectedOption === 'individualItems' ? 'bg-blue-500 border-blue-500' : 'bg-white border-gray-400'}`}
          
        >
          <FontAwesomeIcon 
            icon={faCheck} 
            className={`absolute ${selectedOption === 'individualItems' ? 'text-white' : 'text-gray-400'}`} 
            style={{ fontSize: '12px' }}
          />
        </div>
        <div className="ml-2">
          <span className="text-sm font-semibold block">Individual Items</span>
          <span className="text-xs font-regular text-gray-700 block mt-1">Customize the rate of each item</span>
        </div>
      </div>     
</div>

  {/* Description Label and Textarea */}
  <div className="w-full flex flex-row items-center mb-4 space-x-20">
                      <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
                      <textarea
                        id="Description" 
                        name="Description"
                        className="mt-1 w-1/4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                         value={pricelistDescription}
                        onChange={(e)=>setPricelistDescription(e.target.value)} 
                      />
                    </div>

                    {/* Pricing Schema */}
                  {selectedOption === 'allItems' && (
                  <div className='flex flex-col w-full justify-center items-start'>
            <div className="w-full flex flex-col items-start justify-center mb-4 mt-5 space-y-5">
                    <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                    <label htmlFor="percentage" className="block text-sm font-regular text-[#e54643] w-1/6">
                      Percentage<span className="text-red-500">*</span>
                    </label>
                    <div className="relative flex w-1/4 border border-gray-300 rounded-md overflow-hidden">
                     
                      <div className="flex items-center w-full">
                        
                        {/* Input field */}
                        <input
                          type="text"
                          id="percentage"
                          className="flex-1 outline-none p-2 text-sm text-gray-700 border-none"
                          placeholder="Enter percentage"
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        />
                      </div>
                      
                    </div>
                  
                  
                  </div>
                  
                  <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                                        <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                                          Round Off To<span className="text-red-500">*</span>
                                        </label>
                                        <CustomDropdown
                            options={roundOffOptions}
                            selected={SelectedRoundoffOption ? SelectedRoundoffOption?.label : ''}
                            onSelect={(value, label) => setSelectedRoundoffOption({ value, label })}
                            showAddNew={false} // Adjust as needed
                          />
                                      </div>
                                      </div>
                                      </div>
                  
                  )}

{selectedOption === 'individualItems' && (
                  <div className='flex flex-col w-full justify-center items-start'>
            <div className="w-full flex flex-col items-start justify-center mb-4 mt-5 space-y-5">
            <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
  <label htmlFor="name" className="block text-sm font-regular text-gray-700 w-1/6">
    Pricing Schema
  </label>
  <div className="flex items-center bg-[#F9F9FB] p-2 rounded-md hover:border-blue-500 hover:border cursor-pointer">
  <input
              type="radio"
              name="Unit"
              value="Unit"
              checked={pricingSchema === 'Unit'}
              onClick={()=>handleChange('Unit')}
              className="mr-2 cursor-pointer"
            />
    <span className="text-sm font-regular text-gray-700">Unit Pricing</span>
  </div>
  <div className="flex items-center bg-[#F9F9FB] p-2 rounded-md hover:border-blue-500 hover:border cursor-pointer">
  <input
              type="radio"
              name="Volume"
              value="Volume "
              checked={pricingSchema === 'Volume'}
              onClick={()=>handleChange('Volume')}
              className="mr-2 cursor-pointer"
            />
    <span className="text-sm font-regular text-gray-700">Volume Pricing</span>
  </div>
</div>
                  
<div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                                        <label htmlFor="productCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                                          Currency
                                        </label>
                                        <CustomDropdown
                           options={CurrencyOptions}
                           selected={selectedCurrency}
                           onSelect={handleCurrencySelect}
                           showAddNew={false} // Adjust if you need an "Add New" option
                          />
                                      </div>

                                      <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                    <label htmlFor="discountCheckbox" className="block text-sm font-regular text-gray-700 w-1/6">
                      Discount
                    </label>
                    <div className='flex items-center'>
                    <input
                      type="checkbox"
                      id="discountCheckbox"
                      checked={isDiscountChecked}
                      onChange={handleDiscountChange}
                      className="w-4 h-4"
                    />
                     <span className="text-sm text-gray-700 ml-1">
                     I want to include discount percentage for the items
                    </span>
                    </div>
                  
                   
                   
                  </div>

                  
                  

            
                                      </div>
                   
                                      <div className="flex w-full justify-between px-4 items-center border-t mt-2">
      <div>
        <div className="flex w-full justify-start items-center  mt-2">
          <h1 className="text-lg font-semibold mt-5">Customize Rates In Bulk</h1>
        </div>
        <div className='flex w-full justify-start items-start mt-2'>
          <div className="flex items-center cursor-pointer">
            <FontAwesomeIcon icon={faCog} className="text-blue-500 mr-2" />
            <h1 className="text-sm font-regular text-blue-500">Update Rates in Bulk</h1>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <span className="text-sm font-regular text-gray-700 mr-2">Import Price List for Items</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" className="sr-only peer" onChange={handleToggle} />
          <div className="w-8 h-4 bg-gray-200 rounded-full peer peer-checked:bg-blue-600 peer-focus:outline-none after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
        </label>
      </div>
    </div>
    
    {isToggled ? (
        <div className="mt-4">
          <div className="mb-4">
            <h2 className="text-md font-semibold">1.Export items as XLS file</h2>
            <p className='text-sm mt-1'>Export all your items in an XLS file, customize the rates, and import the file.</p>
            <button className="mt-2 bg-[#F9F9FB] px-2 py-1 rounded-md" onClick={()=>handleExportPriceList()}>
            <FontAwesomeIcon icon={faFileExport} className="text-blue-500 ml-2 text-sm " /><span className='text-sm'> Export Items</span></button>
          </div>
          <div className="mb-4">
            <h2 className="text-md font-semibold">2.Import items as XLS file</h2>
            <p className='text-sm mt-1'>Import the CSV or XLS file that you've exported and updated with the customized rates to update the price list.</p>
            <p className="mt-2 font-regular">Note:</p>
            <ul className="list-disc pl-5">
              <p className='text-sm mt-1'>1. Before you import, ensure that the following column names are in English as given below:</p>
              <ul className="list-disc pl-10 mt-1">
                <li className='text-sm text-gray-500'>Item Name</li>
                <li className='text-sm text-gray-500'>SKU</li>
                <li className='text-sm text-gray-500'>PriceList Rate</li>
              </ul>
              <p className='text-sm mt-1'>2. Once you import the file, the existing items and their rates in this price list will be replaced with the data in the import file.</p>
            </ul>
           <div className="mt-2">
      <input
        type="file"
        accept=".csv, .xls, .xlsx"
        className="hidden"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <button onClick={handleImportClick} className="mt-2 bg-[#F9F9FB] px-2 py-1 rounded-md">
        <FontAwesomeIcon icon={faFileImport} className="text-blue-500 ml-2 text-sm" />
        <span className='text-sm'>Import Items</span>
      </button>
      {selectedImportExcelFile && (
        <span className="ml-2 text-sm text-gray-700">{selectedImportExcelFile?.fileName}</span>
      )}
      {importMessage && <p className="ml-2 text-sm text-red-500">{importMessage}</p>}
      {SucessImportMessage && <p className="ml-2 text-sm text-blue-500">{SucessImportMessage}</p>}
      {importedData && (
        <div>
          <h3>Imported Data:</h3>
          <pre></pre>
        </div>
      )}
    </div>
          </div>
        </div>
    ) : (
      <div className='w-full'>
         {/* Table for item details */}
         <div className="mt-4 w-full overflow-x-auto">
      <table className="w-full mb-5">
        <thead className="sticky top-0 border-t text-[#6c7184] border-b">
          <tr className="border-b">
            <th className="text-xs py-3 px-2 text-start border-r cursor-pointer w-1/6">ITEM DETAILS</th>
            <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">SALES RATE</th>
            <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">GST INCLUDED</th>
            {pricingSchema === 'Volume' && (
              <>
                <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">START QUANTITY</th>
                <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">END QUANTITY</th>
              </>
            )}
            <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">CUSTOM RATE</th>
           
            {isDiscountChecked && (
              <th className="text-xs py-3 px-2 text-end cursor-pointer w-1/6">DISCOUNT</th>
            )}
          </tr>
        </thead>
        <tbody className="border-b border-t w-full">
          {data.map((row, index) => (
            <React.Fragment key={index}>
              {/* Main row */}
              <tr className='border'>
                <td className={`py-2 px-2 text-start border-r w-1/6 ${row?.Volumepricing.length ? 'border-b-0' : ''}`}>
                  <input
                    type="text"
                    value={row?.product_name}
                    className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                  />
                </td>
                <td className={`py-2 px-2 text-end border-r w-1/6 ${row?.Volumepricing.length ? 'border-b-0' : ''}`}>
                  <input
                    type="text"
                    value={row?.selling_price}
                    className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                  />
                </td>
                <td className="py-2 px-2 text-end border-r w-1/6">
                 {/*  <select
                    value={row.gst}
                    onChange={(e) => handleInputChange(index, 'gst', e.target.value)}
                    className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                  >
                    <option value="Included">Included</option>
                    <option value="Not Included">Not Included</option>
                  </select> */}

                  <div className="flex items-center justify-end">
                    <label className="inline-flex items-center mr-4">
                      <input
                        type="checkbox"
                        checked={row?.gst_included=== true}
                        onChange={() => handleCheckboxChange(index, 'gst_included', true)}
                        className="form-checkbox"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={row?.gst_included === false}
                        onChange={() => handleCheckboxChange(index, 'gst_included', false)}
                        className="form-checkbox"
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                </td>
                {pricingSchema === 'Volume' && (
                  <>
                    <td className="py-2 px-2 text-end border-l border-r w-1/6">
                      <input
                        type="text"
                        value={row?.startQuantity || ''}
                        onChange={(e) => handleInputChange(index, 'startQuantity', e.target.value)}
                        className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                      />
                    </td>
                    <td className="py-2 px-2 text-end border-r w-1/6">
                      <input
                        type="text"
                        value={row?.endQuantity || ''}
                        onChange={(e) => handleInputChange(index, 'endQuantity', e.target.value)}
                        className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                      />
                    </td>
                  </>
                )}
                <td className="py-2 px-2 text-end border-r w-1/6">
                  <input
                    type="text"
                    value={row?.customRate}
                    onChange={(e) => handleInputChange(index, 'customRate', e.target.value)}
                    className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                  />
                </td>
                
                {isDiscountChecked && (
                  <td className="py-2 px-2 text-end  w-1/6">
                    <input
                      type="text"
                      value={row?.discount}
                      onChange={(e) => handleInputChange(index, 'discount', e.target.value)}
                      className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                )}
              </tr>

              {/* Extra fields rows */}
              {pricingSchema === 'Volume' && row?.Volumepricing.map((extraField, subIndex) => (
                <tr key={subIndex} className="">
                  <td className="py-2 px-2 border-none w-1/6"></td> {/* Skip itemDetails */}
                  <td className="py-2 px-2 border-none w-1/6"></td> {/* Skip salesRate */}
                  <td className="py-2 px-2 border-none w-1/6"></td>
                  <td className="py-2 px-2 text-end border-l border-b border-r w-1/6">
                    <input
                      type="text"
                      value={extraField?.startQuantity}
                      onChange={(e) => handleInputChange(index, 'startQuantity', e.target.value, subIndex)}
                      className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                  <td className="py-2 px-2 text-end border-r border-b w-1/6">
                    <input
                      type="text"
                      value={extraField?.endQuantity}
                      onChange={(e) => handleInputChange(index, 'endQuantity', e.target.value, subIndex)}
                      className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                  <td className="py-2 px-2 text-end border-r border-b w-1/6">
                    <input
                      type="text"
                      value={extraField?.customRate}
                      onChange={(e) => handleInputChange(index, 'customRate', e.target.value, subIndex)}
                      className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                 {/*  <td className="py-2 px-2 text-end border-r border-b w-1/6">
                  <div className='flex items-end justify-end'>
                  <label className="flex items-center justify-center mr-4">
                      <input
                        type="checkbox"
                        checked={extraField.gst === 'Yes'}
                        onChange={() => handleCheckboxChange(index, 'gst', 'Yes', subIndex)}
                        className="form-checkbox"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="flex items-center justify-center">
                      <input
                        type="checkbox"
                        checked={extraField.gst === 'No'}
                        onChange={() => handleCheckboxChange(index, 'gst', 'No', subIndex)}
                        className="form-checkbox"
                      />
                      <span className="ml-2">No</span>
                    </label>
                    </div>
                  </td> */}
                  {isDiscountChecked && (
                    <td className="py-2 px-2 text-end border-b border-r w-1/6">
                      <input
                        type="text"
                        value={extraField?.discount}
                        onChange={(e) => handleInputChange(index, 'discount', e.target.value, subIndex)}
                        className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                      />
                    </td>
                  )}
                  <td className=' p-2'>
                  <FontAwesomeIcon icon={faClose} className="mr-1 text-red-600" onClick={()=>handleDeleteVolumePricing(index , subIndex)}/>
                  </td>
                </tr>
                
              ))}

              {/* Add New Range button */}
              {pricingSchema === 'Volume' && (
                <tr className="">
                  <td className="py-2 px-2 border-none w-1/6"></td> {/* Skip itemDetails */}
                  <td className="py-2 px-2 border-r w-1/6"></td> {/* Skip salesRate */}
                  <td className="py-2 px-2 border-r w-1/6"></td>
                  <td className="py-2 px-2 flex items-center justify-start w-full">
                    <button
                      onClick={() => addNewRange(index)}
                      className="text-sm text-blue-500 flex items-center">
                      <FontAwesomeIcon icon={faPlus} className="mr-1" />
                      Add New Range
                    </button>
                  </td>
                  <td className="py-2 px-2 border-none w-1/6"></td> {/* Skip endQuantity */}
                  <td className="py-2 px-2 border-none w-1/6"></td> {/* Skip customRate */}
                  <td className="py-2 px-2 border-none w-1/6"></td> {/* Skip GST */}
                  {isDiscountChecked && <td className="py-2 px-2 border-none w-1/6"></td>} {/* Skip discount */}
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>

   </div>
    )}
    


                                      </div>
                  
                  )}

                    
{/* <TableSearchDropDown
      options={DropDownUoms} // Pass your options here
      value={productsubcategory?.UOM_id} // Pass the value (ID) of the selected UOM
      onChange={(selectedOption) => HandleChangeUom(selectedOption , index)} // Handle change
      index={index} // Pass the index
      getFilteredOptions={(options, inputValue) => options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )}
      setEditUOM={(value) => setUomName(value)}
    /> */}



</div>
</div>

<div className="mt-5 w-full">
                  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
           onClick={handleSavePriceList} >
            Save {/* {isSaving && ( <Spinner/>)} */}
            </button>
          <button onClick={handleCancel} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
        </div>
</div>
</div>
</div>

{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}
</div>
</div>
  );
};

export default NewPriceList;
