import React, {useEffect, useState} from "react";
import { useLocation , useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { FaBox, FaInfoCircle, FaRuler } from "react-icons/fa";

const ViewTilesQuote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const detailedSelection = location.state?.detailedSelection || [];
  //console.log('detailedSelection', detailedSelection);
  const {categoryId} = location.state || '';
  const {customerId} = location.state || '';
  const {projectId} = location.state || '';
  //console.log(location.state);
  const [showPopup, setShowPopup] = useState(false);
  const[ attributes, setAttributes]= useState([]);
  const [UomOptions , setUomOptions] = useState([]);
  const { handleSaveResponse, setRetryFunction, setPopupMessage, handleGetResponse , setPopupType } = useCommonResponseHandler();
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [isSaving, setIsSaving] = useState(false);
 

  const groupedTiles = detailedSelection.reduce((acc, tile) => {
    if (tile && tile.area) {
      if (!acc[tile.area]) {
        acc[tile.area] = [];
      }
      acc[tile.area].push(tile);
    }
    return acc;
  }, {});

  useEffect(() => {
    const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        //console.log(data)
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
        //console.log(output)
         setAttributes(output)
        } else {
          setAttributes([])
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
        }
      }
      }
      if(categoryId){
      fetchAttributesByproductCategory(categoryId);
      }
  }, [categoryId]);


  useEffect(() => {
    FetchUoms();
}, []);

  const FetchUoms = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
  
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      if (output) {
        // Log the data to ensure it's fetched correctly
        //console.log('Fetched uoms:',output);
  
        const uomData = output.map((item) => ({
          id: item._id,
          value: item.UOM,
          label: item.UOM,
        }));
  console.log(data);
    setUomOptions(uomData)
      } else {
        console.error('Error:', data.message);
        return ([])

      }
    }catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => FetchUoms(attempt + 1), 1000); // Retry after 1 second
      } else {
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => FetchUoms(attempt)); // Set retry function
      }
    }
  };

  const findMatchingUOM = (uomId) => {
    const matchedUOM = UomOptions.find(uom => uom.id === uomId);
    return matchedUOM ? matchedUOM.value : ' '; // Default to 'Box' if no match found
  };
  

  const handleApprove = async () => {
    setIsSaving(true);
  
    try {
      // Calculate subtotal using reduce
      const subtotal = detailedSelection.reduce((total, element) => {
        const quantity = handletheQuantity(element.value, element.productGroup);
        const price = calculetBoxPrices(quantity, element.productGroup?.selling_price);
        return total + parseFloat(price); // Ensure to add float values correctly
      }, 0);
  
      // Prepare quotation items by mapping detailedSelection
      const items = await Promise.all(detailedSelection.map(async (item) => {
        const quantity = handletheQuantity(item.value, item.productGroup);
        const price = calculetBoxPrices(quantity, item.productGroup?.selling_price);
        const matchedUOM = findMatchingUOM(item.productGroup.UOM_id);

        return {
          item_id: item.productGroup._id,
          item_name: item.productGroup.product_group_name,
          quantity: quantity,
          selling_price: item.productGroup.selling_price,
          tax_rate: item.productGroup.GST,
          tax_type: 'GST',
          cost_price: item.productGroup.cost_price,
          UOM_id: item.productGroup.UOM_id,
          UOM: matchedUOM,
          total_tax_amount: calculateItemTax(item.productGroup.GST, price),
          total: price,
          area_types: {
            area_name: item.area,
            area: item.value,
          }
        };
      }));
      const subtotalAmount = calculateSalesTotalOfEachLineItem(items);
      const taxAmount = calculateSaleTaxAmount(items);
      // Prepare quotation data
      const quotationData = {
        customer_id: customerId,
        product_category_id: categoryId,
        quotation_date: new Date(),
        project_id: projectId,
        status: 'Approved',
        quotation_type: 'Group', 
        items: items, // Use the prepared items array
        subtotal: subtotalAmount, // Use correct subtotal calculation
        tax_amount: taxAmount, // Calculate tax amount
        total: parseFloat(subtotalAmount) + parseFloat(taxAmount), // Use the calculated subtotal
        tax_prefrence: 'Tax Exclusive',
        organizationId:sessionStorage.getItem('organizationId'),
      };
  
      console.log(quotationData);
  
      // Send the request to the server
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}customer/quotations/save-quotation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(quotationData),
      });

      const data = await response.json();
      const output = await handleSaveResponse(response, data);

      if (output) {
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to save quotation:', error);
      setPopupMessage('Failed to save quotation');
      setPopupType('500');
      setShowPopup(true);
    } finally {
      setIsSaving(false);
    }
  };

  const calculateSaleTaxAmount = (items) => {
    return items.reduce((totalTax, element) => {
      const taxAmount = parseFloat(element.total_tax_amount || 0); // Ensure value is a number
      return totalTax + taxAmount;
    }, 0);
  };
  
  // Corrected function to calculate the total sales of each line item
  const calculateSalesTotalOfEachLineItem = (items) => {
    return items.reduce((total, element) => {
      const salesTotal = parseFloat(element.total || 0); // Ensure value is a number
      return total + salesTotal;
    }, 0);
  };
  
  const calculateItemTax = (GST, cost) => {
    const lineItemTax = (parseFloat(GST) / 100) * parseFloat(cost);
    return lineItemTax;
  };

  const handleItems = () => {
    navigate("/consumer/app/itemsforso", { state: { detailedSelection ,    categoryId : categoryId , customerId: customerId, projectId: projectId } });
  }

   const [RequiredBoxesToArea , setRequiredBoxesToArea] = useState([])
 

 /*  useEffect(() => {
    let RequiredBoxesToArea = [];
      groupedTiles.forEach((measurement) => {
        const Boxes = handletheQuantity(measurement.area, measurement?.value ,measurement?.productGroup);
        console.log('boxes', Boxes);
  
        const measurementKey = measurement.area;
        const data = {
          [measurementKey]: Boxes
        };
        RequiredBoxesToArea.push(data);
        console.log('area', RequiredBoxesToArea);
      });
    
  
    setRequiredBoxesToArea(RequiredBoxesToArea);
    // You can now use RequiredBoxesToArea as needed, e.g., set it to state
  
  }, []); */
  

const handletheQuantity = ( value , productGroup) => {
  
    const productGroupData = productGroup;
    //console.log('productGroupData', productGroupData)
    const attributeValueIds = productGroupData?.attribute_value_ids || [];
    const sqFtPerBoxAttribute = attributes.find(p => p.attribute_name === 'Sq Ft per Box');
    
    if (sqFtPerBoxAttribute) {
      let conversionFactor = 0;

      for (const valueId of attributeValueIds) {
        const attributeValue = sqFtPerBoxAttribute.attribute_values.find(p => p.attribute_value_id === valueId);
        if (attributeValue) {
          conversionFactor = parseFloat(attributeValue.attribute_value);
          break;
        }
      }

      if (conversionFactor > 0) {
        const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
        //console.log('quantity', quantity);
        return quantity;
      }
    }
  
  return 0; // Return 0 if no valid conversion can be made
};

const calculetBoxPrices = (Boxes, price) => {
  // Convert Boxes and price to numbers and handle undefined or invalid values
  const numBoxes = parseFloat(Boxes) || 0; // Default to 0 if Boxes is not a valid number
  const numPrice = parseFloat(price) || 0; // Default to 0 if price is not a valid number

  const totalPrice = numBoxes * numPrice;
  return totalPrice.toFixed(2); // Ensure it returns a string with two decimal places
};

 
  return (
    <div className="bg-[#FAFAFA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
  <h1 className="text-2xl font-bold mb-4">Tiles Quote</h1>

  {Object.entries(groupedTiles).map(([area, tiles]) => (
    <div key={area} className="">
      {tiles.map((item, index) => (
        <div key={index} className="bg-white rounded-lg shadow-lg border border-[#BDC3C7] p-6 mt-2">
          <div className="flex flex-col items-start border-b border-[#ECF0F1] mb-4 pb-4">
            <div className="flex items-start text-xl font-semibold mb-3">
              <FaInfoCircle className="flex-shrink-0 mt-1 mr-2 text-[#3498DB]" />
              <span className="text-[#3498DB]">{item.area}</span>
            </div>
            
            <div className="text-lg text-[#2C3E50] mb-2">
              <FaRuler className="inline mr-2 text-[#E74C3C]" />
              Area: <span className="font-bold">{item.value.toFixed(2)}</span> <span className="font-semibold">sq.ft</span>
            </div>
            
            {attributes.length > 0 && (
              <div className="text-lg text-[#2C3E50]">
                <FaBox className="inline mr-2 text-[#27AE60]" />
                Quantity: <span className="font-bold">{handletheQuantity(item.value, item.productGroup)}</span> <span className="font-semibold">Box</span>
              </div>
            )}
          </div>
          
          <div className="bg-[#D6EAF8] p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-[#2C3E50]">{item.productGroup.product_group_name}</h3>
            <p className="text-lg text-[#27AE60]">
              ₹{item.productGroup.selling_price}/<span className="text-sm">Box</span>
            </p>
            {attributes.length > 0 && (
              <p className="mt-2 text-lg text-[#2C3E50]">
                Total: ₹{calculetBoxPrices(handletheQuantity(item.value, item.productGroup), item.productGroup?.selling_price)}
              </p>
            )}
          </div>
         {/*  <div className="mt-6 text-right">
        <span
        className="text-sm font-semibold text-[#EC001D] bg-red-50 px-3 py-1 rounded-full cursor-pointer hover:bg-red-100 transition duration-300"
        onClick={() => handleGetProductsDataByGroup(category)} > Select Tiles
        </span>
        </div> */}
        </div>
      ))}
    </div>
  ))}

  <div className="mt-6 flex flex-col justify-center items-center space-y-2">
    <p className="text-lg text-[#2C3E50]">Sub Total: ₹{detailedSelection.reduce((total, item) => total + (handletheQuantity(item.value, item.productGroup) * item.productGroup.selling_price), 0).toFixed(2)}</p>
    <p className="text-lg text-[#2C3E50]">Tax: ₹{(() => {
      const subtotal = detailedSelection.reduce((total, item) => total + (handletheQuantity(item.value, item.productGroup) * item.productGroup.selling_price), 0);
      const avgGST = detailedSelection.reduce((total, item) => total + item.productGroup.GST, 0) / detailedSelection.length;
      return (subtotal * avgGST / 100).toFixed(2);
    })()} (Exclusive)</p>
    <p className="text-xl font-bold text-[#2C3E50]">Total: ₹{(() => {
      const subtotal = detailedSelection.reduce((total, item) => total + (handletheQuantity(item.value, item.productGroup) * item.productGroup.selling_price), 0);
      const avgGST = detailedSelection.reduce((total, item) => total + item.productGroup.GST, 0) / detailedSelection.length;
      const tax = subtotal * avgGST / 100;
      return (subtotal + tax).toFixed(2);
    })()}</p>
  </div>

  <div className="mt-6 flex justify-center">
    <button
      className="bg-[#3498DB] text-white px-4 py-2 rounded-lg font-semibold hover:bg-[#2980B9] transition-colors duration-200"
      onClick={handleApprove}
    >
      Approve Quote
    </button>
  </div>
      {/* popup starts */}
      {showPopup && (
  <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-end z-50">
    <div className="bg-white rounded-t-lg shadow-lg p-6 w-full max-w-sm transform transition-transform duration-300 ease-out translate-y-0 animate-slide-up">
      <h2 className="text-2xl font-bold mb-4 text-[#2C3E50]">Quote Approved</h2>
      <p className="text-lg text-[#34495E] mb-2">Your quotation has been approved.</p>
      <p className="text-lg text-[#34495E] mb-6">Please select the items to generate an order.</p>
      <div className="flex justify-between">
        <button
          className="bg-[#3498DB] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#2980B9] transition-colors duration-200"
          onClick={handleItems}
        >
          Okay
        </button>
        <button
          className="bg-[#E74C3C] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#C0392B] transition-colors duration-200"
          onClick={() => setShowPopup(false)}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}


       {/* popup ends */}
    </div>
  );
};

export default ViewTilesQuote;
