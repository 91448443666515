import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
//import logoImage from "./images/BB.png";
import { useNavigate } from 'react-router-dom';

function ConsumerLogin() {
    const [mobilenumber, setMobilenumber] = useState('');
    const navigate = useNavigate();
    const [isInvalidMobile, setIsInvalidMobile] = useState(false);
    const ConsumerLogin = process.env.REACT_APP_FORE_BRICKBUCKET + 'customer/customer-login';
    const [message,setMessage] = useState();
    
    const handleOTP = async () => {
      if (mobilenumber.length === 10) {
        try {
          const res = await fetch(ConsumerLogin, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ mobile_number: mobilenumber }),
          });
          console.log(mobilenumber);
    
          if (res.ok) {
            const responseData = await res.json();
            console.log(responseData);
            setMessage(responseData.message);
    
        // Move navigation outside of all conditional blocks
        navigate('/consumer/otp', { state: { mobileNumber: mobilenumber } });
          } else {
            console.error('Login failed');
            console.log(res);
            setMessage("Try with valid number");
          }
        } catch (error) {
          console.error('Error:', error);
          clearStorageAndRedirectToHome();
        }
    

      } else {
        setIsInvalidMobile(true);
      }
    };
    
    
      function clearStorageAndRedirectToHome() {
        window.location.href = '/bb/user';
      }
    

    const handleChange = (e) => {
        const value = e.target.value;
        // Filter out non-numeric characters
        const numericValue = value.replace(/\D/g, '');
        // Limit input length to 10 characters
        const truncatedValue = numericValue.slice(0, 10);
        setMobilenumber(truncatedValue);
        setIsInvalidMobile(false); // Reset error state
    };

    return (
        <div className="w-full flex justify-center items-center h-screen font-roboto border border-gray-300">
            <div className="flex w-full justify-center items-center">
                <div className="flex flex-col space-y-4 justify-center w-full items-center">
                    <div className='flex flex-row justify-center items-center' >
                        <img src={'/BB.png'} alt="Logo" className='h-20 w-20 sm:w-14 sm:h-14'/>
                        <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
                    </div>
                    <div className="flex-col w-full sm:w-6/7 md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-2 bg-opacity-[15%] rounded-sm p-4">
                        <p className="text-2xl ">LOGIN</p>
                        <div className="p-6 relative">
                            <div className="flex-col p-2 flex justify-center">
                                <div className='flex justify-center items-center'>
                                    <p className="text-md">Mobile Number</p>
                                </div>
                                <div className="flex-row flex items-center  justify-center relative">
                                    <span className="flex justify-center items-center absolute md:left-2 lg:left-2 sm:left-6">
                                        <FontAwesomeIcon icon={faPhone} className="text-gray-400" />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                        type="text"
                                        placeholder="Mobile Number"
                                        className={`w-5/6 md:w-full rounded-sm focus:outline-none focus:ring-[1px] focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300 ${isInvalidMobile ? 'border-red-500' : ''}`}
                                        value={mobilenumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                {isInvalidMobile && (
                                    <p className="text-red-500 text-xs mt-1">Mobile number must be 10 digits</p>
                                )}
                            </div>
                            <p className="text-center mt-4">{message}</p>
                            <div className="flex justify-center items-center mt-3">
                                <button
                                    className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1 "
                                    onClick={handleOTP}
                                >
                                    Get OTP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsumerLogin;
