import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleTilesClick = () => {
    navigate('/bb/consumerapp/tilesquote');
  };

  const handleDoorsClick = () => {
    navigate('/bb/consumerapp/doorsquote');
  };

  const handleWindowsClick = () => {
   // navigate('/bb/consumerapp/windows');
  };

  const handleAdhesivesClick = () => {
    //navigate('/bb/consumerapp/adhesives');
  };

  return (
    <div className="bg-[#FAFAFA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
      <div className="flex flex-col space-y-4">
        <div
          className="bg-white p-4 rounded-lg shadow-md flex justify-between items-center cursor-pointer"
          onClick={handleTilesClick}
        >
          <span className="text-lg font-semibold">Tiles</span>
          <FaArrowRight className="text-gray-600" />
        </div>
        <div
          className="bg-white p-4 rounded-lg shadow-md flex justify-between items-center cursor-pointer"
          onClick={handleDoorsClick}
        >
          <span className="text-lg font-semibold">Doors</span>
          <FaArrowRight className="text-gray-600" />
        </div>
        <div
          className="bg-white p-4 rounded-lg shadow-md flex justify-between items-center cursor-pointer"
          onClick={handleWindowsClick}
        >
          <span className="text-lg font-semibold">Windows</span>
          <FaArrowRight className="text-gray-600" />
        </div>
        <div
          className="bg-white p-4 rounded-lg shadow-md flex justify-between items-center cursor-pointer"
          onClick={handleAdhesivesClick}
        >
          <span className="text-lg font-semibold">Adhesives</span>
          <FaArrowRight className="text-gray-600" />
        </div>
      </div>
    </div>
  );
};

export default Home;
