import React, { useEffect, useState } from 'react';
import App1 from './measurit/app1';
import BBAdmin from './BBAdmin/BBAdminRouting';
import HtmlApp from './HtmlApp';
import Measurit_v3 from './Measurit_v3.0/App_M_3.0';
import Consumer_app from './ConsumerApp/Consumer_app';
import './App.css';

const MainApp = () => {
  const [shouldRenderApp1, setShouldRenderApp1] = useState(false);
  const [shouldRenderBBAdmin, setShouldRenderBBAdmin] = useState(false);
  const [shouldRenderHtmlApp, setShouldRenderHtmlApp] = useState(false);
  const [shouldRendermeasurit, setShouldRendermeasurit] = useState(false);
  const [shouldRenderconsumerapp, setShouldRenderconsumerapp] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;

    // Check for specific paths first to avoid conflicts
    if (path.startsWith('/measurit_v3.0')) {
      setShouldRendermeasurit(true);
    } else if (path.startsWith('/measurit')) {
      setShouldRenderApp1(true);
    } else if (path.startsWith('/bb')) {
      setShouldRenderBBAdmin(true);
    } else if (path.startsWith('/consumer')) {
      setShouldRenderconsumerapp(true);
    }
     else if (path === '/') {
      setShouldRenderHtmlApp(true);
    }
  }, []); // Empty dependency array ensures this runs only once

  return (
    <>
      {shouldRenderApp1 && <App1 />}
      {shouldRenderBBAdmin && <BBAdmin />}
      {shouldRendermeasurit && <Measurit_v3 />}
      {shouldRenderconsumerapp && <Consumer_app />}  
      {shouldRenderHtmlApp && <HtmlApp />}
    </>
  );
};

export default MainApp;
