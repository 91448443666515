import React , {useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle, faTrash, faX , faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
 import TableSearchDropDown from '../Components/TableSearchDropdown';
 import TableSearchForAreaName from '../Components/TableSearchForAreaName'

 const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option); // Pass the entire option object
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
            //  autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};


 const Windows = forwardRef(({rooms , measurements,  areas , Block, flat , project},ref) => {

    const [Windowsrows, setWindowsRows] = useState([
  
      ]);

      console.log(Windowsrows)
      const uomOptions = [
        { value: 'mm', label: 'mm' },
        { value: 'inches', label: 'inches' },
      ];
      const [areatypeOptions, setAreatypeOptions] = useState([
    
      ]);
      const [selectedUOM, setSelectedUOM] = useState(null);
      const mtypeOptions = [
        { label: 'Addition', value: 'Addition' },
        { label: 'Deduction', value: 'Deduction' },
        // Add other mtype options here
      ];
    
      const [roomOptions , setRoomOptions] = useState([]);

      const handleWindowInputChange = (index, field, value) => {
        const updatedRows = [...Windowsrows];
        updatedRows[index][field] = value;
        if(field === 'length' || field === 'breadth'){
          if(selectedUOM){
            updatedRows[index].area = handleAreaConversion(updatedRows[index]?.length||0, updatedRows[index]?.breadth ||0, selectedUOM?.value);
          }else{
            updatedRows[index].area = updatedRows[index]?.length * updatedRows[index]?.breadth;
          }
         
        }
        setWindowsRows(updatedRows);
      };
    
  
      const addWindowsRow = () => {
        const windowsArea = areas.find(area => area.areaName.toLowerCase() === 'windows');
        const WindownewRow = { room: '', type: 'Addition', length: '', breadth: '', area: '', flatId: flat.value ,
          blockId: Block.value , 
          projectId: project._id ,
          areatype: windowsArea?.areaName || 'Windows',
          areaId: windowsArea?.areaId
        };
        setWindowsRows([...Windowsrows, WindownewRow]);
        
      };
  
        
  
      const deleteRow = (index) => {
        const updatedData = [...Windowsrows];
        updatedData.splice(index, 1);
        setWindowsRows(updatedData);
       
      };

      useImperativeHandle(ref, () => ({
        getWindowsData: () => {
          return Windowsrows.filter(row => {
            const areaValue = parseFloat(row.area);
            return !isNaN(areaValue) && areaValue > 0;
          });
        }
      }));
      

      useEffect(() => {
        if (measurements && rooms && areas && project && Block && flat) {
          const windowsArea = areas.find(area => area.areaName.toLowerCase() === 'windows');
          if (measurements.length > 0) {
            setWindowsRows(measurements.map((measurement) => {
              const room = rooms.find(r => r.roomId === measurement.roomId);
              const area = areas.find(a => a.areaId === measurement.areaId) || windowsArea;
      
              const baseObject = {
                room: room?.roomName || '',
                roomId: measurement.roomId,
                areatype: area?.areaName || 'Windows',
                areaId: measurement.areaId || windowsArea?.areaId,
                type: measurement.type || "Addition",
                length: measurement.length,
                breadth: measurement.breadth,
                unit: measurement.unit,
                area: handleAreaConversion(measurement.length, measurement.breadth, measurement.unit),
                blockId: Block.value,
                flatId: flat.value,
                projectId: project._id,
              };
      
              return baseObject;
            }));
          } else {
            const WindowNewRow = {
              room: '',
              areatype: windowsArea?.areaName || 'Windows',
              areaId: windowsArea?.areaId,
              type: 'Addition',
              length: '',
              breadth: '',
              area: '',
              flatId: flat.value,
              blockId: Block.value,
              projectId: project._id,
            };
            setWindowsRows([WindowNewRow]);
          }
      
          setRoomOptions(rooms.map((room) => ({
            label: room.roomName,
            value: room.roomId,
          })));
      
          setAreatypeOptions(areas.map((area) => ({
            label: area.areaName,
            value: area.areaId,
          })));
      
          const uomOption = {
            label: measurements[0]?.unit || 'inches',
            value: measurements[0]?.unit || 'inches'
          };
      
          handleUom(uomOption);
        }
      }, [measurements, rooms, areas, project, Block, flat]);
      
      
      const handleAreaConversion = (length, breadth, unit) => {
        const lengthValue = parseFloat(length);
        const breadthValue = parseFloat(breadth);
      
        if (isNaN(lengthValue) || isNaN(breadthValue)) {
          return '';
        }
      
        const area = lengthValue * breadthValue;
        const conversionFactors = {
          mm: 92903.04,
          inches: 144
        };
      
        const convertedArea = area / (conversionFactors[unit] || 1);
        return convertedArea.toFixed(2);
      };
      
      
      const handleUom = (value) => {
        console.log(value)
        setSelectedUOM(value);
        setWindowsRows(prevRows => prevRows.map(row => ({
          ...row,
          unit: value.value,
          length: handleValueByUnit(row.length, row.unit, value.value),
          breadth: handleValueByUnit(row.breadth, row.unit, value.value),
          area: handleAreaConversion(
            handleValueByUnit(row.length, row.unit, value.value),
            handleValueByUnit(row.breadth, row.unit, value.value),
            value.value
          )
        })));
      };
      
      
      const handleValueByUnit = (unitValue, unit, changedUnit) => {
        const value = parseFloat(unitValue);
        
        if (isNaN(value)) {
          return '';
        }
      
        const conversionFactors = {
          'mm-inches': 25.4,
          'inches-mm': 25.4
        };
      
        const conversionKey = `${unit}-${changedUnit}`;
        const factor = conversionFactors[conversionKey];
      
        if (!factor) {
          return value.toFixed(2);
        }
      
        const convertedValue = unit === 'mm' ? value / factor : value * factor;
        return convertedValue.toFixed(2);
      };
      
      const handleRoomChange = (index, value) => {
        const updatedRows = [...Windowsrows];
        updatedRows[index].room = value.label;
        updatedRows[index].roomId = value.value;
        setWindowsRows(updatedRows);
      };
    
     const  handleAreaChange = (index, value) => {
        const updatedRows = [...Windowsrows];
        updatedRows[index].areatype = value.label;
        updatedRows[index].areaId = value.value;
        setWindowsRows(updatedRows);
      };
      const handleMeasurementType = (index, value) => {
        const updatedRows = [...Windowsrows];
        updatedRows[index].type = value.label;
        setWindowsRows(updatedRows);
      };
    

      
    

return (
    <div className='w-full'>
       <div className="flex flex flex-row space-x-10  w-2/3 px-4 items-center mt-4">
       <div className='flex items-center'>
                  <h1 className="text-xl font-semibold">Windows</h1> 
                  </div>
                  <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-1">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-gray-700  w-1/4 md:w-1/4">
                 UOM
                </label> 
                <div className='w-1/3 md:w-1/4'>          
                <CustomDropdown
  options={uomOptions}
  selected={selectedUOM}
  onSelect={(value) => {
    handleUom(value);
    
  }}
  
  placeholder="Select UOM"
  showAddNew={false}
  showSearch={true}
/>
  </div> 
              </div>          
                </div>

                <div className="w-full flex flex-col px-2 mb-8 mt-4">
      <table className="w-full">
        <thead>
          <tr className="text-[#6c7184] bg-[#fafafc] ">
            <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Room</th>
            <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Area Type</th>
            <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Type</th>
            <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Height ({selectedUOM?.value})</th>
              <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Width ({selectedUOM?.value})</th>
              <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Area (sq ft)</th>
          </tr>
        </thead>
        <tbody>
          {Windowsrows.map((measurement, index) => (
            <tr key={index}>
                <td className="border ">
                <div className="w-full w-[130px] md:min-w-[200px]">
                 <CustomDropdown
  options={roomOptions}
  selected={measurement.roomId ? { label: measurement.room, value: measurement.roomId } : null}
  onSelect={(selectedOption) => handleRoomChange(index,  selectedOption)}
  placeholder="Select Room"
  showAddNew={false}
  showSearch={true}
/>
</div>
</td>
<td className="border ">
<div className="w-full w-[110px] md:min-w-[200px]">
  <CustomDropdown
    options={areatypeOptions}
    selected={ measurement.areaId? {label: measurement.areatype , value: measurement.areaId } : null}
    onSelect={(selectedOption) => handleAreaChange(index,  selectedOption)}
    placeholder="Select Area"
    showAddNew={false}
    showSearch={true}
  />
  </div>
</td>
<td className="border ">
<div className="w-full w-[110px] md:min-w-[200px]">
                  {/* <TableSearchDropDown
                    options={mtypeOptions}
                    value={measurement.type}
                    onChange={(selectedOption) => handleTileInputChange(index, 'type', selectedOption ? selectedOption.value : '')}
                  /> */}
                   <CustomDropdown
    options={mtypeOptions}
    selected={{label: measurement.type , value: measurement.type }}
    onSelect={(selectedOption) => handleMeasurementType(index,  selectedOption)}
    placeholder="Select  Type"
    showAddNew={false}
    showSearch={true}
    
  />
  </div>
                </td>
              <td className="border">
                <input
                  type="text"
                  value={measurement.length}
                  onChange={(e) => handleWindowInputChange(index, 'length', e.target.value)}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
              <td className="border">
                <input
                  type="text"
                  value={measurement.breadth}
                  onChange={(e) => handleWindowInputChange(index, 'breadth', e.target.value)}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
              <td className="border">
                <input
                  type="text"
                  value={measurement.area}
                  onChange={(e) => handleWindowInputChange(index, 'area', e.target.value)}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
              <td className="px-2">  <button    type="button"
                                  onClick={() => deleteRow(index ,measurement)}
                                  className="text-red-500">
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='mt-2'>
        <button  onClick={addWindowsRow} className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'><FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1"  />
      Add New Row</button>
      </div> 
    </div>
</div>
);
});

export default Windows;