import React, {useEffect ,useState} from "react";
import { FaArrowRight, FaBox, FaImage, FaInfoCircle, FaRuler, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';

const ItemsforQuote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const detailedSelection  = location.state?.detailedSelection  || [];
  const {categoryId} = location.state || '';
  const {customerId} = location.state || '';
  const {projectId} = location.state || '';
  console.log(detailedSelection);
  const [localDetailedSelection, setLocalDetailedSelection] = useState(
    location.state?.detailedSelection.map(item => ({...item, selectedItem: null})) || []
  );
  console.log(localDetailedSelection);
const [UomOptions , setUomOptions] = useState([]);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [showSelectItemPopup, setShowSelectItemPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [items , setItems] = useState([]);
const [selectedCardItems, setSelectedCardItems] = useState({});
console.log(selectedCardItems);
const [currentCardIndex, setCurrentCardIndex] = useState(null);
console.log(currentCardIndex);
const[ attributes, setAttributes]= useState([])
const { 
  showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
  isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
  setPopupType } = useCommonResponseHandler();
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [subItems,setSubItems] = useState([]);
  const [AlertofSelectingMoreItems , setAlertofSelectingMoreItems] = useState(false);

  const fetchItemsofSelectedGroup = async (productGroupId ,attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product/get-products-by-product-group?product_group_id=${productGroupId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        if (output) {
          console.log(output)
          setItems(output);
        } else {
          setItems([]);
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchItemsofSelectedGroup(productGroupId, attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchItemsofSelectedGroup(productGroupId ,attempt)); // Set retry function
        }
      }/* finally {
        setIsLoading(false);
      } */
    };
    
    useEffect(() => {
      const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
          //console.log(data)
          const output = await handleGetResponse(response , data)
          console.log(output)
          if (output) {
          //console.log(output)
           setAttributes(output)
          } else {
            setAttributes([])
            console.error(data.message);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
          } else {
           
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
          }
        }
        }
        if(categoryId){
        fetchAttributesByproductCategory(categoryId);
        }
    }, [categoryId]);


    const handleSelectItems = (tile, index) => {
      fetchItemsofSelectedGroup(tile.productGroup._id);
      setCurrentCardIndex(index);
      setShowSelectItemPopup(true);
    };
    
  
  const handleConfirm = () => {
    setShowSelectItemPopup(false);
    setShowConfirmPopup(true);
  };

  const handleClickOkay = () => {
    setAlertofSelectingMoreItems(false);
    navigate('/consumer/app');
  }

  const handleItemSelection = (item) => {
    setLocalDetailedSelection(prevSelection =>
      prevSelection.map((selection, index) =>
        index === currentCardIndex ? {...selection, selectedItem: item} : selection
      )
    );
  };
  
  

      useEffect(() => {
        FetchUoms();
    }, []);
    
      const FetchUoms = async (attempt = 1) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
      
          const data = await response.json();
          const output = await handleGetResponse(response, data);
          if (output) {
            // Log the data to ensure it's fetched correctly
            //console.log('Fetched uoms:',output);
      
            const uomData = output.map((item) => ({
              id: item._id,
              value: item.UOM,
              label: item.UOM,
            }));
      console.log(data);
        setUomOptions(uomData)
          } else {
            console.error('Error:', data.message);
            return ([])
    
          }
        }catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => FetchUoms(attempt + 1), 1000); // Retry after 1 second
          } else {
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => FetchUoms(attempt)); // Set retry function
          }
        }
      };

      const findMatchingUOM = (uomId) => {
        const matchedUOM = UomOptions.find(uom => uom.id === uomId);
        return matchedUOM ? matchedUOM.value : ' '; // Default to 'Box' if no match found
      };

      const handleSaveSalesOrder = async () => {
        try {
          const token = sessionStorage.getItem('token');  
          // Prepare the items array with async operations for calculating quantity and prices
          const items = await Promise.all(localDetailedSelection.map(async (selection) => {
            const quantity = handletheQuantity(selection.value, selection.productGroup); // Calculate quantity
            const cost = calculeteCost(quantity, selection.productGroup?.selling_price); // Calculate cost based on quantity and selling price
            const matchedUOM = findMatchingUOM(selection.productGroup.UOM_id);
            return {
              item_id: selection?.selectedItem.item_id,
              item_name: selection.selectedItem.item_name,
              quantity: quantity, // Use calculated quantity
              cost_price: selection.productGroup.cost_price, // Set cost price
              selling_price: selection.productGroup.selling_price, // Set selling price
              tax_rate: selection.productGroup.GST, // Use product GST rate
              tax_type: 'GST', // Assuming tax type is always GST
             cost_price: selection.selectedItem.cost_price,
             UOM_id: selection.selectedItem.UOM_id,
              UOM: 'Box',
              total_tax_amount: calculateItemTax(selection.productGroup.GST, cost), // Call the function to calculate tax
              sales_total: cost, // Set sales total to calculated cost
              UOM_id: selection.selectedItem.UOM_id, // Set UOM ID
              UOM: matchedUOM || '', // Set UOM name
              application_areas: {
                area_name: selection.area, // Set area name
                area: selection.value.toString(), // Convert area value to string
                count: '', // Add count logic if needed
              },
              organizationId:sessionStorage.getItem('organizationId'),  

            };
          }));
      
          // Calculate subtotal by summing up all item sales_totals
          const subtotal = calculateSalesTotalOfEachLineItem(items);
          const taxAmount = calculateSaleTaxAmount(items);
          
          // Prepare sales order data
          const salesorderData = {
            customer_id: customerId,
            product_category_id: categoryId,
            salesorder_date: new Date().toISOString(),
            project_id: projectId,
            status: 'Confirmed',
            subtotal: parseFloat(subtotal), // Use calculated subtotal
            tax_amount: taxAmount, // Use calculated tax amount
            total : parseFloat(subtotal) + parseFloat(taxAmount), // Calculate total
            tax_prefrence: 'Tax Exclusive',
            organizationId:sessionStorage.getItem('organizationId'),  
          };
      
          console.log({ ...salesorderData, items });
      
          // Make the API call to create the sales order
          const response = await fetch(`${fetchUrl}customer/salesorder/create-salesorder`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token, 
            },
            body: JSON.stringify({ ...salesorderData, items }), // Combine sales order data and items
          });
      console.log(salesorderData)
          const data = await response.json();
      console.log(data)
          if (response.ok) {
            setShowConfirmPopup(true);
            // Handle success (navigate to a new page or show success message)
            console.log('Sales order created successfully:', data);
            
          } else {
            // Handle error response from the server
            console.error('Error creating sales order:', data);
            // Show error message to user
          }
        } catch (error) {
          // Handle general error
          console.error('Error:', error);
          // Show error message to user
        }
      };
      
      // Corrected function to calculate total tax amount
      const calculateSaleTaxAmount = (items) => {
        return items.reduce((totalTax, element) => {
          const taxAmount = parseFloat(element.total_tax_amount || 0); // Ensure value is a number
          return totalTax + taxAmount;
        }, 0);
      };
      
      // Corrected function to calculate the total sales of each line item
      const calculateSalesTotalOfEachLineItem = (items) => {
        return items.reduce((total, element) => {
          const salesTotal = parseFloat(element.sales_total || 0); // Ensure value is a number
          return total + salesTotal;
        }, 0);
      };
      
      // Corrected tax calculation function
      const calculateItemTax = (GST, cost) => {
        const lineItemTax = (parseFloat(GST) / 100) * parseFloat(cost);
        return lineItemTax;
      };
      
      
   
      const handletheQuantity = ( value , productGroup) => {
  
        const productGroupData = productGroup;
        console.log('productGroupData', productGroupData)
        const attributeValueIds = productGroupData?.attribute_value_ids || [];
        const sqFtPerBoxAttribute = attributes.find(p => p.attribute_name === 'Sq Ft per Box');
        
        if (sqFtPerBoxAttribute) {
          let conversionFactor = 0;
    
          for (const valueId of attributeValueIds) {
            const attributeValue = sqFtPerBoxAttribute.attribute_values.find(p => p.attribute_value_id === valueId);
            if (attributeValue) {
              conversionFactor = parseFloat(attributeValue.attribute_value);
              break;
            }
          }
    
          if (conversionFactor > 0) {
            const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
            console.log('quantity', quantity);
            return quantity;
          }
        }
      
      return 0; // Return 0 if no valid conversion can be made
    };
    
    const calculeteCost = (Boxes, price) => {
      // Convert Boxes and price to numbers and handle undefined or invalid values
      const numBoxes = parseFloat(Boxes) || 0; // Default to 0 if Boxes is not a valid number
      const numPrice = parseFloat(price) || 0; // Default to 0 if price is not a valid number
    
      const totalPrice = numBoxes * numPrice;
      return totalPrice.toFixed(2); // Ensure it returns a string with two decimal places
    };
      
  return (
    <div className="bg-[#FAFAFA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
    <h1 className="text-2xl font-bold mb-4">Select Items for Order</h1>
  
    {localDetailedSelection.map((tile, index) => (
      <div key={index} className="bg-white rounded-lg shadow-lg border border-[#BDC3C7] p-6 mt-2">
        <div className="flex flex-col items-start border-b border-[#ECF0F1] mb-4 pb-4">
          <div className="flex items-start text-xl font-semibold mb-3">
            <FaInfoCircle className="flex-shrink-0 mt-1 mr-2 text-[#3498DB]" />
            <span className="text-[#3498DB]">{tile.area}</span>
          </div>
          
          <div className="text-lg text-[#2C3E50] mb-2">
            <FaRuler className="inline mr-2 text-[#E74C3C]" />
            Area: <span className="font-bold">{tile.value.toFixed(2)}</span> <span className="font-semibold">sq.ft</span>
          </div>
          
          {attributes.length > 0 && (
            <div className="text-lg text-[#2C3E50]">
              <FaBox className="inline mr-2 text-[#27AE60]" />
              Quantity: <span className="font-bold">{handletheQuantity(tile.value, tile.productGroup)}</span> <span className="font-semibold">Box</span>
            </div>
          )}
        </div>
        
        <div className="bg-[#D6EAF8] p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-4 text-[#2C3E50]">{tile.productGroup.product_group_name}</h3>
          <p className="text-lg text-[#27AE60]">
            ₹{tile.productGroup.selling_price}/<span className="text-sm">Box</span>
          </p>
        </div>
  
        <div className="mt-4 flex justify-between items-center cursor-pointer" onClick={() => handleSelectItems(tile, index)}>
          <span className="text-lg text-[#2C3E50]">
            {localDetailedSelection[index].selectedItem
              ? localDetailedSelection[index].selectedItem.item_name
              : "Choose Items"}
          </span>
          <FaArrowRight className="text-[#3498DB]" />
        </div>
      </div>
    ))}
  
    <div className="mt-6 flex justify-center">
      <button
        className="bg-[#3498DB] text-white px-4 py-2 rounded-lg font-semibold hover:bg-[#2980B9] transition-colors duration-200"
        onClick={handleSaveSalesOrder}
      >
        Confirm
      </button>
    </div>
  
    {showSelectItemPopup && (
  <div className="fixed inset-0 z-20 bg-white flex flex-col">
    <div className="p-4 flex justify-between items-center">
      <h2 className="text-2xl font-bold text-[#2C3E50]">Select Items</h2>
      <button
        onClick={() => setShowSelectItemPopup(false)}
        className="text-2xl font-bold"
      >
        <FaTimes className="text-[#E74C3C] w-6 h-6" />
      </button>
    </div>

    <div className="flex-1 overflow-y-auto p-4">
      <div className="grid grid-cols-2 gap-4">
        {items.map((item) => {
const isSelected = localDetailedSelection[currentCardIndex]?.selectedItem?.item_id === item.item_id;
return (
            <div
              key={item.item_id}
              className={`border rounded-lg p-4 flex flex-col items-center ${
                isSelected ? 'bg-[#D6EAF8] border-[#3498DB]' : ''
              }`}
              onClick={() => handleItemSelection(item, currentCardIndex)}
            >
              <div className="w-24 h-24 flex items-center justify-center mb-2">
                {item.item_image ? (
                  <img
                    src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}/${item.item_image}`}
                    alt="image unavailable"
                    className="w-full h-full object-cover rounded"
                  />
                ) : (
                  <FaImage className="text-gray-400 w-12 h-12" />
                )}
              </div>
              <p className="text-center mb-2 text-[#2C3E50]">{item.item_name}</p>
              <div className={`px-2 py-1 rounded-lg text-center ${
  isSelected ? 'bg-[#3498DB] text-white font-bold' : 'border border-[#3498DB] text-[#3498DB]'
} transition-colors duration-200`}>
  {isSelected ? 'Selected' : 'Select'}
</div>

            </div>
          );
        })}
      </div>
    </div>

    <div className="mt-6 flex justify-center p-4">
      <button
        className="bg-[#3498DB] text-white px-4 py-2 rounded-lg font-semibold hover:bg-[#2980B9] transition-colors duration-200"
        onClick={() => setShowSelectItemPopup(false)}
      >
        Submit
      </button>
    </div>
  </div>
)}


  
    {showConfirmPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-end z-50">
    <div className="bg-white rounded-t-lg shadow-lg p-6 w-full max-w-sm transform transition-transform duration-300 ease-out translate-y-0 animate-slide-up">
          <div className="flex justify-end">
            <button
onClick={() => {
  setShowConfirmPopup(false);
  
}}

              className="text-2xl font-bold"
            >
              <FaTimes className="text-[#E74C3C] w-6 h-6" />
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-4 text-[#2C3E50]">Confirmed!</h2>
          <p className="text-lg text-[#34495E] mb-6">Your Sales Order is now confirmed.</p>
          <div className="flex justify-center">
            <button
              className="bg-[#3498DB] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#2980B9] transition-colors duration-200"
              onClick={handleClickOkay}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    )}
  
    {AlertofSelectingMoreItems && (
      <div className="fixed inset-0 z-20 bg-black bg-opacity-60 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
          <p className="text-lg text-[#34495E] mb-6">Only one item can be selected. Please deselect the current item to choose another.</p>
          <div className="flex justify-center">
            <button
              className="bg-[#3498DB] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#2980B9] transition-colors duration-200"
              onClick={handleClickOkay}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
  
  );
};

export default ItemsforQuote;
