import { useState } from 'react';

const useCommonResponseHandler = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [retryFunction , setRetryFunction]= useState ()
  const handleGetResponse = async (response, data) => {
    switch (response.status) {
      case 200:
      case 201:
        setShowPopup(true);
        //setPopupType('200or201');
        return data.data;
      case 204:
        setShowPopup(true);
        //setPopupType('204');
        return null;
      case 400:
      case 401:
      case 403:
        setShowPopup(true);
        setPopupType(`${response.status}`);
        setPopupMessage(data.message);
        return null;
      case 404:
        return null;
      case 500:
        setShowPopup(true);
        setPopupType(`${response.status}`);
        setPopupMessage(data.message);
       // setRetryFunction(()=>{retryFunction})
        return null;
      default:
        setShowPopup(true);
        setErrorMessages([...errorMessages, data.message]);
        return null;
    }
  };

  const handleSaveResponse = async (response, data) => {
    switch (response.status) {
      case 200:
      case 201:
        setShowPopup(true);
        setPopupMessage(data.message || 'Operation successful.');
        setPopupType('200or201');
        break;
      case 204:
        setShowPopup(true);
        setPopupMessage('No content to display.');
        setPopupType('204');
        break;
      default:
        setShowPopup(true);
        setPopupMessage(data.message || 'An error occurred.');
        setPopupType(`${response.status}`);
    }
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 3000);
    setTimeout(() => setIsSaving(false), 3000);
    return response.status === 200 || response.status === 201 ? data.data : null;
  };

  const handleDeleteResponse = async (response, data) => {
    setIsSaving(false);
    if (response.status === 204) {
        setShowPopup(true);
      setPopupMessage('Deletion successful.');
      setPopupType(`${response.status}`);
    } else if (response.status === 200 || response.status === 201) {
        setShowPopup(true);
      setPopupMessage('Deletion successful.');
      setPopupType('200or201');
    } else {
        setShowPopup(true);
      setPopupMessage(data.message || 'An error occurred.');
      setPopupType(`${response.status}`);
    }
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 3000);
    return response.status === 204 || response.status === 200 || response.status === 201;
  };

  return {
    showPopup,
    isSaving,
    setIsSaving,
    popupMessage,
    popupType,
    errorMessages,
    handleGetResponse,
    setShowPopup,
    handleSaveResponse,
    handleDeleteResponse,
    setRetryFunction,
    retryFunction,
    setPopupMessage,
    setPopupType

  };
};

export default useCommonResponseHandler;