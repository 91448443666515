import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faAdd, faCheck, faTimes, faSpinner, faClose  } from "@fortawesome/free-solid-svg-icons";
import { PencilIcon, CheckIcon, TrashIcon, PlusIcon } from '@heroicons/react/outline';
import {useNavigate ,useLocation} from "react-router-dom";

const AreasMeasurements=({areasStatusses,setAreasStatusses,handleAreaClick,RoomData,selectedAreadata,
       FlatId,setRoomData,SelectedAreaBlocks })=>{
        const Navigate=useNavigate()
    const [subAreaData, setsubAreaData] = useState([]);
    const [SelectedAreadata,setSelectedAreadata]=useState(null)
    const [AreaRowEditedIndex, setAreaRowEditedIndex] = useState(null);
    const [showAreaAddButton, setshowAreaAddButton] = useState(true);
    const [AreasStatusses,setareasStatusses]=useState(null);
   const[specificAreaData,setspecificAreaData]=useState(false);
   const [areaId,setAreaId]=useState(null);
   const [selecetedRoomData ,setselecetedRoomData] = useState (null);
   const [roomId, setRoomId]= useState(null);
   const [flatId ,setFlatId] =useState();
   const [alertMessage, setAlertMessage]= useState(null);
   

  const location = useLocation();
  const projectId = location.state.projectId;
  const blockId = location.state.blockId;
  //console.log(projectId,blockId)
  const [flatAlertVisible, setFlatAlertVisible] = useState(false);
  const [flatAlertMessage, setFlatAlertMessage] = useState('');

  const [quantities, setQuantities] = useState([
    { modularType: 1, quantity: 0 },
    { modularType: 2, quantity: 0 },
    { modularType: 3, quantity: 0 },
    { modularType: 4, quantity: 0 },
    { modularType: 6, quantity: 0 },
    { modularType: 8, quantity: 0 },
    { modularType: 12, quantity: 0 },
    { modularType: 16, quantity: 0 },]);

  const [editMode, setEditMode] = useState(false);
  const [Area, setArea] = useState([]);
  const handleAreaRowInputChange = (rowindex, field, value, index, data) => {
    // Check if the value is a valid number
    if (!isNaN(value)) {
        const AreaToChange = Area.filter(P => P.areaSubId === data.areaSubId);
        const remainingAreas = Area.filter(P => P.areaSubId !== data.areaSubId);
        const updatedRows = [...AreaToChange];
        updatedRows[rowindex][field] = value;
        setArea([...updatedRows, ...remainingAreas]);
    }
};

const handleAreaRowRadioChange = (rowindex,  value ,index ,data) => {
  const AreaToChange= Area.filter(P=>P.areaSubId===data.areaSubId)
  const remainingAreas= Area.filter(P=>P.areaSubId!==data.areaSubId)
  const updatedRows = [...AreaToChange];
  updatedRows[rowindex].type = value;
  setArea([...updatedRows,...remainingAreas]);
};


  const handleFrameRadioChange = (rowindex, value,index, SubAreas) => {
   
    const areaToChange = Area.filter(P => P.areaSubId === SubAreas[0].areaSubId);
    const remainingAreas = Area.filter(P => P.areaSubId !== SubAreas[0].areaSubId);
    const updatedRows = [...areaToChange];
    updatedRows[rowindex].doorType = value;
    setArea([...remainingAreas, ...updatedRows]);  // Note the order of updatedRows and remainingAreas
  };


  const handleWallRadioChange = (rowindex, value, index, SubAreas) => {
    
    const areaToChange = Area.filter(P => P.areaSubId === SubAreas[0].areaSubId);
    const remainingAreas = Area.filter(P => P.areaSubId !== SubAreas[0].areaSubId);
    const updatedRows = [...areaToChange];
    updatedRows[rowindex].wallType = value;
    setArea([...remainingAreas, ...updatedRows]);  // Note the order of updatedRows and remainingAreas
  };


  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      //console.log('profile matched')
      }else{
        //console.log('logout')
       Navigate('/')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      Navigate('/')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

useEffect(()=>{
    if(selectedAreadata){
      setSelectedAreadata(selectedAreadata);
      const selectedArea=selectedAreadata;
      setAreaId(selectedAreadata.areaId);
      if (selectedArea) {
          //setspecificAreaData(true);
         
          setAreaRowEditedIndex(null);
      
          const SessionStorageData = localStorage.getItem('measurementData') || null;
          const storedData = SessionStorageData ? JSON.parse(SessionStorageData) : null;
          
          if (storedData && storedData.length !== 0) {
            const filterRoomAreasData=storedData.filter(p=>p.roomId===RoomData.roomId);
            const filterAreaData = filterRoomAreasData.filter(p => p.areaId === selectedArea.areaId);
      
            //console.log(filterAreaData);
      
            if (filterAreaData.length !== 0) {
          
              //console.log(filterAreaData);
              setArea(filterAreaData);
            } else {
              //console.log('error');
              
                  setArea([{
                    length: '0',
                    breadth: '0',
                    type: 'addition',
                    unit: 'inches',
                    areaId:selectedAreadata.areaId,
                    isEditing: false,
                    areaSubId: 1,
                    blockId: blockId,
                    flatId: FlatId,
                    projectId:projectId,
                    roomId: RoomData.roomId,
                    wallType: selectedAreadata.areaId === 4 ? 'tilebleWall' : undefined,
                    doorType: selectedAreadata.areaId === 1 ? 'withoutFrame' : undefined,
                  },]);
             
            }
          } else {
            setArea([{
              length: '0',
              breadth: '0',
              type: 'addition',
              unit: 'inches',
              areaId:selectedAreadata.areaId,
              isEditing: false,
              areaSubId: 1,
              blockId: blockId,
              flatId: FlatId,
              projectId:projectId,
              roomId: RoomData.roomId,
              wallType: selectedAreadata.areaId=== 4 ? 'tilebleWall' : undefined,
              doorType: selectedAreadata.areaId=== 1 ? 'withoutFrame' : undefined,
            },]);
                 }
        } else {
          //console.log('selected area not getting');
        }
    }
   
},[selectedAreadata,RoomData,projectId,blockId,FlatId])

useEffect(()=>{
  if(RoomData){
    setselecetedRoomData(RoomData);
    setRoomId(RoomData.roomId);
  }
    
},[RoomData])

useEffect(()=>{
  if(areasStatusses){
    setareasStatusses(areasStatusses);
  }
   
},[areasStatusses])

useEffect(()=>{
  if(FlatId){
setFlatId(FlatId);
  }
    
},[FlatId])


   
  useEffect(() => {
    if (Area) {
        //console.log(Area);
      const PerticulrAreaData= Area.filter(p=>p.areaId===selectedAreadata.areaId)
      const separateByTheSubIds = PerticulrAreaData.reduce((accumulator, currentValue) => {
        const { areaSubId } = currentValue;
  
        if (!accumulator[areaSubId]) {
          accumulator[areaSubId] = [];
        }
  
        accumulator[areaSubId].push(currentValue);
  
        return accumulator;
      }, {});
  
      const arrayFromObject = Object.values(separateByTheSubIds);
      setsubAreaData(arrayFromObject);
       //console.log(arrayFromObject);
    }
  }, [Area]);

  
  
  const handleAreaRowEdit = (rowindex, index, SubAreas) => {
        const SubAreaRows= Area.filter(p=>p.areaSubId===SubAreas[0].areaSubId)
        const remainingAreas=Area.filter(p=>p.areaSubId!==SubAreas[0].areaSubId)
   const updatedRows = [...SubAreaRows];
   const data = Area.filter((p) => p.isEditing === true);
   if (data.length === 1) {
    const findindex= Area.findIndex((p) => p.isEditing === true)
    
    handleEditAreaRowNew(findindex,index, rowindex,SubAreas)
   }else{
    updatedRows[rowindex].isEditing = true;
    setArea([...updatedRows,...remainingAreas]);
    setAreaRowEditedIndex(rowindex);   // Set the index of the edited row
   }
  };
  
  const handleEditAreaRowNew=async(oldindex, index, rowindex, SubAreas )=>{
   
    const allAreas = [...Area];
    allAreas[oldindex].isEditing = false;
       const SubAreaRows= allAreas.filter(p=>p.areaSubId===SubAreas[0].areaSubId) 
      SubAreaRows[rowindex].isEditing = true;
       const remainingAreas=allAreas.filter(p=>p.areaSubId!==SubAreas[0].areaSubId)
       setArea([...SubAreaRows,...remainingAreas]);
     setAreaRowEditedIndex(rowindex);
   }
   const handleAreaRowSave =async (rowindex, index, data) => {
    
    //console.log(SelectedAreadata)
   // const areaId = SelectedAreadata.areaId;
    let updatedStatuses = { ...AreasStatusses };
    
    switch (areaId) {
      case 1:
        updatedStatuses.doorStatus = "processing";
        break;
      case 2:
        updatedStatuses.windowStatus = "processing";
        break;
      case 3:
        updatedStatuses.flooringStatus = "processing";
        break;
      case 4:
        updatedStatuses.wallsStatus = "processing";
        break;
      case 5:
        updatedStatuses.ceilingStatus = "processing";
        break;
      case 6:
        updatedStatuses.electricalsStatus = "processing";
        break;
      default:
        break;
    }
  
    setAreasStatusses(updatedStatuses);
    setareasStatusses(updatedStatuses);
  
    try {
      const roomData = JSON.parse(localStorage.getItem('RoomsData')) || [];
  
      // Find the index of the item you want to update
      const indexToUpdate = roomData.findIndex(room => room.roomId === selecetedRoomData.roomId);
       
      //console.log(roomData[indexToUpdate])
      const subAreaRows = Area.filter(p => p.areaSubId === data.areaSubId);
      if (
        subAreaRows[rowindex].length === "" ||
        subAreaRows[rowindex].breadth === "" ||
        subAreaRows[rowindex].type === ""
      ) {
        // Display an error message or handle it as needed
        setAlertMessage('Please fill all fields before saving.');
       // alert('Please fill all fields before saving.');
        return;
      }else{
        if (indexToUpdate !== -1) {
          // If the item is found, update the existing property
          roomData[indexToUpdate] = {
            ...roomData[indexToUpdate],
            status: 'processing',
          };
        
          // Save the updated array back to localStorage
          localStorage.setItem('RoomsData', JSON.stringify(roomData));
          setselecetedRoomData(roomData[indexToUpdate])
          setRoomData(roomData);
        } else {
          // Handle the case where the room with the specified ID was not found
          //console.error('Room not found for the specified ID:', roomId);
        }

        const storedData = localStorage.getItem('measurementData') || null;
        const sessionStorageData = storedData ? JSON.parse(storedData) : [];
        const RoomAreasData=sessionStorageData.filter(p=>p.roomId===selecetedRoomData.roomId);
        const remainingRoomsAreasRowsDataFiltered = sessionStorageData.filter(p => p.roomId !== selecetedRoomData.roomId);
        const areaRowsDataFiltered = RoomAreasData.filter(p => p.areaId === areaId);
        const remainingAreasRowsDataFiltered = RoomAreasData.filter(p => p.areaId !== areaId);
        localStorage.setItem("areaStatus", JSON.stringify(updatedStatuses));
    
        if (AreaRowEditedIndex !== null) {
          console.log('enterd to edited index ')
          const subAreaRows = Area.filter(p => p.areaSubId === data.areaSubId);
    
          if (
            subAreaRows[rowindex].length === "" ||
            subAreaRows[rowindex].breadth === "" ||
            subAreaRows[rowindex].type === ""
          ) {
            // Display an error message or handle it as needed
            setAlertMessage('Please fill all fields before saving')
           // alert('Please fill all fields before saving.');
            return;
          }
          
          subAreaRows[rowindex].isEditing = false;
    
          const remainingSubAreaRows = Area.filter(p => p.areaSubId !== data.areaSubId);
         console.log([...remainingSubAreaRows, ...subAreaRows,])
          setArea([...remainingSubAreaRows, ...subAreaRows,]);
    
          const updatedMeasurementData = [...remainingAreasRowsDataFiltered, ...remainingRoomsAreasRowsDataFiltered, ...remainingSubAreaRows, ...subAreaRows];
          localStorage.setItem('measurementData', JSON.stringify(updatedMeasurementData));
          if(updatedMeasurementData){
            setAreaRowEditedIndex(null)
          }
          
        } else {
          const subAreaRows = Area.filter(p => p.areaSubId === data.areaSubId);
    
          
          if (
            subAreaRows[rowindex].length === "" ||
            subAreaRows[rowindex].breadth === "" ||
            subAreaRows[rowindex].type === ""
          ) {
            // Display an error message or handle it as needed
            setAlertMessage('Please fill all fields before saving.')
           // alert('Please fill all fields before saving.');
            return;
          }
      
          subAreaRows[rowindex].isEditing = false;
          const measurementData = {
            projectId:projectId,
            blockId:blockId,
            flatId: flatId,
            roomId: roomId,
            areaId: areaId,
            areaSubId: data.areaSubId,
            type: subAreaRows[rowindex].type,
            breadth: subAreaRows[rowindex].breadth,
            length: subAreaRows[rowindex].length,
            unit: "inches",
            
          };
  
          if (SelectedAreadata.areaName === 'Doors') {
              // If the current area is "Doors", include frame data
              measurementData.doorType = subAreaRows[rowindex].doorType||'withoutFrame';
            }
    
  
            if (SelectedAreadata.areaName === 'Walls') {
              // If the current area is "walls", include frame data
              measurementData.wallType = subAreaRows[rowindex].wallType||'tilebleWall';
            }
            //console.log([...areaRowsDataFiltered, measurementData]);
  
          setArea([...areaRowsDataFiltered, measurementData]);
          localStorage.setItem('measurementData', JSON.stringify([...remainingAreasRowsDataFiltered,...remainingRoomsAreasRowsDataFiltered, ...areaRowsDataFiltered, measurementData]));
        }
      }
     
  
     
    } catch (error) {
      clearStorageAndRedirectToHome()
      //console.error('Error parsing JSON:', error);
    }
  };  
 
  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }
         
 

  const handleAddAreaRowDelete = (rowindex, index, data) => {
    const sessionStorageData = JSON.parse(localStorage.getItem('measurementData')) || [];
    const areaId = SelectedAreadata.areaId;
    let updatedStatuses = { ...AreasStatusses };
  
    // Update status based on areaId
    switch (areaId) {
      case 1:
        updatedStatuses.doorStatus = 'processing';
        break;
      case 2:
        updatedStatuses.windowStatus = 'processing';
        break;
      case 3:
        updatedStatuses.flooringStatus = 'processing';
        break;
      case 4:
        updatedStatuses.wallsStatus = 'processing';
        break;
      case 5:
        updatedStatuses.ceilingStatus = 'processing';
        break;
      case 6:
        updatedStatuses.electricalsStatus = 'processing';
        break;
      default:
        break;
    }
   
    // Update statuses in state
    setAreasStatusses(updatedStatuses);
    setareasStatusses(updatedStatuses);
  
    if (sessionStorageData.length !== 0) {
      const RoomAreasMeasurements = sessionStorageData.filter((p) => p.roomId === selecetedRoomData.roomId);
      const remainingRoomAreaMeasurements = sessionStorageData.filter((p) => p.roomId !== selecetedRoomData.roomId);
      const selectedAreas = RoomAreasMeasurements.filter((p) => p.areaId === SelectedAreadata.areaId);
      const remainingAreasRowsData = RoomAreasMeasurements.filter((p) => p.areaId !== SelectedAreadata.areaId);
  
      // Update areaStatus in local storage
      localStorage.setItem('areaStatus', JSON.stringify(updatedStatuses));
  
      if (Area.length !== 0) {
        HandleSetRoomData(data, rowindex)
        const updatedRows = Area.filter((p) => p.areaSubId !== data.areaSubId);
        const remainingAreaAfterDeleted = Area.filter((p) => p.areaSubId === data.areaSubId && p.areaId === data.areaId);
  
        // Ensure we remove the correct row from remainingAreaAfterDeleted
        if (remainingAreaAfterDeleted.length > 0) {
          remainingAreaAfterDeleted.splice(rowindex, 1);
        }
  
        const updatedMeasurementData = [
          ...remainingAreasRowsData,
          ...updatedRows,
          ...remainingAreaAfterDeleted,
          ...remainingRoomAreaMeasurements,
        ];
  
        // Update state and local storage
        setArea([...updatedRows, ...remainingAreaAfterDeleted]);
        localStorage.setItem('measurementData', JSON.stringify(updatedMeasurementData));
  
        if (selectedAreas.length === 1) {
          setArea([
            {
              length: '0',
              breadth: '0',
              type: 'addition',
              isEditing: true,
              areaId: areaId,
              areaSubId: 1,
              roomId: RoomData.roomId,
              wallType: areaId === 4 ? 'paintableWall' : undefined,
            },
          ]);
          
        }
      } else {
        setAlertMessage('No data available to delete in this area');
      }
    }
  };
  

  const HandleSetRoomData=(data, rowindex)=>{
    const roomData = JSON.parse(localStorage.getItem('RoomsData')) || [];

// Find the index of the item you want to update
const indexToUpdate = roomData.findIndex(room => room.roomId === selecetedRoomData.roomId);

//console.log(roomData[indexToUpdate])
const subAreaRows = Area.filter(p => p.areaSubId === data.areaSubId);
if (
  subAreaRows.length === 0 || // Check if subAreaRows is empty
  subAreaRows[rowindex].length === "" ||
  subAreaRows[rowindex].breadth === "" ||
  subAreaRows[rowindex].type === ""
) {
  // Display an error message or handle it as needed
  setAlertMessage('Please fill all fields before saving.');
  // alert('Please fill all fields before saving.');
  return;
} else {
  if (indexToUpdate !== -1) {
    // If the item is found, update the existing property
    const updatedRoomData = [...roomData]; // Create a copy of roomData
    updatedRoomData[indexToUpdate] = {
      ...updatedRoomData[indexToUpdate],
      status: 'processing',
    };

    // Save the updated array back to localStorage
    localStorage.setItem('RoomsData', JSON.stringify(updatedRoomData));
    setselecetedRoomData(updatedRoomData[indexToUpdate]);
    setRoomData(updatedRoomData);
  } else {
    // Handle the case where the room with the specified ID was not found
    //console.error('Room not found for the specified ID:', roomId);
  }
}

  }
 


   const handleAddSubArea = async (index, SubAreas) => {
    const measurementsData = JSON.parse(localStorage.getItem('measurementData')) || [];
  
    const newSubArea = {
      length: '0',
      breadth: '0',
      type: 'addition',
      isEditing: false,
      areaSubId: SubAreas[0].areaSubId + 1,
      areaId: areaId,
      blockId: blockId,
      flatId: flatId,
      roomId: selecetedRoomData.roomId,
      projectId:projectId,
      wallType: areaId === 4 ? 'tilebleWall' : undefined,
      doorType: areaId === 1 ? 'withoutFrame' : undefined,
    
    };
  
    // Update state
    setArea((prevArea) => [...prevArea, newSubArea]);
  
    // Update local storage
    const updatedData = [...measurementsData, newSubArea];
    localStorage.setItem('measurementData', JSON.stringify(updatedData));
  };
  
   

  const handleAddAreaRow = (index, rowindex, data) => {
  
  
    const emptyArray = [];
    Area.forEach((area) => {
      if (area.isEditing === true) {
        console.log("Empty fields found in area:", area);
        emptyArray.push(area);
      }
    });
  
    if (emptyArray&&emptyArray.length !== 0) {
      alert('First finish editing the area in state');
    } else {
     
      setArea((prevArea) => [
        ...prevArea,
        {
          length: '0',
          breadth: '0',
          type: 'addition',
          unit: 'inches',
          areaId: areaId,
          isEditing: false,
          areaSubId: data.areaSubId,
          blockId: blockId,
          flatId: flatId,
          roomId: roomId,
          projectId:projectId,
          wallType: areaId === 4 ? 'tilebleWall' : undefined,
      doorType: areaId === 1 ? 'withoutFrame' : undefined,
        },
      ]);
  
      // Update local storage
      const measurementsData = JSON.parse(localStorage.getItem('measurementData')) || [];
      const newData = {
        length: '0',
        breadth: '0',
        type: 'addition',
        unit: 'inches',
        areaId: areaId,
        isEditing: false,
        areaSubId:data.areaSubId,
        blockId: blockId,
        flatId: flatId,
        roomId: roomId,
        projectId:projectId,
        wallType: areaId === 4 ? 'tilebleWall' : undefined,
      doorType: areaId === 1 ? 'withoutFrame' : undefined,
      };
      const updatedData = [...measurementsData, newData];
      localStorage.setItem('measurementData', JSON.stringify(updatedData));
  
      setshowAreaAddButton(true);
    }
  };
  

  const handleCompletedArea = async (selecetdArea) => {
    const updatedStatuses = { ...AreasStatusses };
    const areaData = Area.find((P) => P.isEditing === true) || '';
    const measurementData=JSON.parse(localStorage.getItem('measurementData'))
    const remainiRoomsMeasuremtsData=measurementData.filter(p=>p.roomId!==RoomData.roomId)
    const selectedRoomMeasuremetsData=measurementData.filter(p=>p.roomId===RoomData.roomId)
    const remainingAreasofThiSRoomData=selectedRoomMeasuremetsData.filter(p=>p.areaId!==selecetdArea.areaId)
   
    if (SelectedAreadata.areaName === 'Electricals') {
     
      if(editMode===true){
        //console.log(electricalareaData);
        setFlatAlertMessage('Please fill and save the data');
        setFlatAlertVisible(true);
      }else{

      const areaIndex = areaId - 1;
      const areaName = Object.keys(updatedStatuses)[areaIndex];
  
      updatedStatuses[areaName] = 'completed';
  
      setAreasStatusses(updatedStatuses);
      localStorage.setItem('areaStatus', JSON.stringify(updatedStatuses));
  const electricalsData=  JSON.parse ( localStorage.getItem('electricalsData'))
  const selectedRoomElecticals= electricalsData.filter(p=>p.roomId===selecetedRoomData.roomId)
  const remainingRoomElectricalMeasurements= electricalsData.filter(p=>p.roomId!==selecetedRoomData.roomId)
  if(selectedRoomElecticals&&selectedRoomElecticals.length===0){
    const electricalMeasurements = quantities.map((quantity) => ({
      ...quantity,
      roomId: selecetedRoomData.roomId,
      flatId: flatId,
      areaId: SelectedAreadata.areaId,
      areaSubId: 1,
      projectId:projectId,
      blockId:blockId,
    }));
    
    localStorage.setItem('electricalsData',JSON.stringify([...electricalMeasurements,...remainingRoomElectricalMeasurements]))
  }else{
    console.log('data found')
  }
      const valuesArray = Object.values(updatedStatuses);
      const indexOfPendingAndProcessing = valuesArray.indexOf('pending' || 'processing');
  
      //console.log(indexOfPendingAndProcessing);
  
      const findNextArea = SelectedAreaBlocks[indexOfPendingAndProcessing];
  
      if (findNextArea) {
        if (findNextArea.display === 'no') {
          const areaStatusIndex = findNextArea.areaId;
          //console.log(areaStatusIndex);
          const  areaStatuses = { ...updatedStatuses };
          //console.log(areaStatuses);
          const nextAreaName = Object.keys(areaStatuses)[areaStatusIndex - 1];
          areaStatuses[nextAreaName] = 'completed';

          //console.log(areaStatuses);
          localStorage.setItem('areaStatus', JSON.stringify(areaStatuses));
          setAreasStatusses(areaStatuses);

          const updatedAreaStatusesArray = Object.values(areaStatuses);
          const indexOfPendingAndProcessingAreaStatuses = updatedAreaStatusesArray.indexOf('pending' || 'processing');

          const findNextAreaForStatus = SelectedAreaBlocks[indexOfPendingAndProcessingAreaStatuses];
         if(findNextAreaForStatus){
          setFlatAlertMessage(`Moved to the ${findNextAreaForStatus.areaName}`);
          setFlatAlertVisible(true);
          handleAreaClick(findNextAreaForStatus);
         }else{
          setTimeout(async () => {
            setFlatAlertMessage('All areas of this room completed');
            setFlatAlertVisible(true);
          }, 500);
         }
          
  
        } else {
          setFlatAlertMessage(`Moved to the ${findNextArea.areaName}`);
          setFlatAlertVisible(true);
          handleAreaClick(findNextArea);
        }
      } else {
        setTimeout(async () => {
          setFlatAlertMessage('All areas of this room completed');
          setFlatAlertVisible(true);
        }, 500);
      }
    }
    } else {
      if (areaData.length === 0) {
        const TotalMeasuremets= [...remainiRoomsMeasuremtsData, ...remainingAreasofThiSRoomData, ...Area]
        console.log(TotalMeasuremets)
        const areaIndex = areaId - 1;
        const areaName = Object.keys(updatedStatuses)[areaIndex];
  
        updatedStatuses[areaName] = 'completed';
  
        setAreasStatusses(updatedStatuses);
        localStorage.setItem('areaStatus', JSON.stringify(updatedStatuses));
        
        const valuesArray = Object.values(updatedStatuses);
        const indexOfPendingAndProcessing = valuesArray.indexOf('pending' || 'processing');
       localStorage.setItem('measurementData', JSON.stringify(TotalMeasuremets))
        //console.log(indexOfPendingAndProcessing);
  
        const findNextArea = SelectedAreaBlocks[indexOfPendingAndProcessing];
    
        if (findNextArea) {
          //console.log(findNextArea);
  
          if (findNextArea.display === 'no') {
            const areaStatusIndex = findNextArea.areaId;
            //console.log(areaStatusIndex);
            const  areaStatuses = { ...updatedStatuses };
            //console.log(areaStatuses);
            const nextAreaName = Object.keys(areaStatuses)[areaStatusIndex - 1];
            areaStatuses[nextAreaName] = 'completed';
  
            //console.log(areaStatuses);
            localStorage.setItem('areaStatus', JSON.stringify(areaStatuses));
            setAreasStatusses(areaStatuses);
  
            const updatedAreaStatusesArray = Object.values(areaStatuses);
            const indexOfPendingAndProcessingAreaStatuses = updatedAreaStatusesArray.indexOf('pending' || 'processing');

            const findNextAreaForStatus = SelectedAreaBlocks[indexOfPendingAndProcessingAreaStatuses];
           if(findNextAreaForStatus){
            setFlatAlertMessage(`Moved to the ${findNextAreaForStatus.areaName}`);
            setFlatAlertVisible(true);
            handleAreaClick(findNextAreaForStatus);
           }else{
            setTimeout(async () => {
              setFlatAlertMessage('All areas of this room completed');
              setFlatAlertVisible(true);
            }, 500);
           }
            
    
          } else {
            setFlatAlertMessage(`Moved to the ${findNextArea.areaName}`);
            setFlatAlertVisible(true);
            handleAreaClick(findNextArea);
          }
        } else {
          setTimeout(async () => {
            setFlatAlertMessage('All areas of this room completed');
            setFlatAlertVisible(true);
          }, 500);
        }
      } else {
        setFlatAlertMessage('Please fill and save the data');
        setFlatAlertVisible(true);
      }
    }
  };
  

  

  const handleQuantityIncrement = (modularType) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((quantity) =>
        quantity.modularType === modularType ? { ...quantity, quantity: quantity.quantity + 1 } : quantity
      )
    );
  };

  const handleQuantityDecrement = (modularType) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((quantity) =>
        quantity.modularType === modularType && quantity.quantity > 0
          ? { ...quantity, quantity: quantity.quantity - 1 }
          : quantity
      )
    );
  };

  //const getQuantity = (modularType) => quantities.find((quantity) => quantity.modularType === modularType)?.quantity || 0;

  const handleSave = () => {
    const roomData = JSON.parse(localStorage.getItem('RoomsData')) || [];
  
      // Find the index of the item you want to update
      const indexToUpdate = roomData.findIndex(room => room.roomId === selecetedRoomData.roomId);
       
      //console.log(roomData[indexToUpdate])
      if (indexToUpdate !== -1) {
        // If the item is found, update the existing property
        roomData[indexToUpdate] = {
          ...roomData[indexToUpdate],
          status: 'processing',
        };
      
        // Save the updated array back to localStorage
        localStorage.setItem('RoomsData', JSON.stringify(roomData));
        setselecetedRoomData(roomData[indexToUpdate])
        setRoomData(roomData);
      } else {
        // Handle the case where the room with the specified ID was not found
        //console.error('Room not found for the specified ID:', roomId);
      }


    let updatedStatuses = { ...AreasStatusses };
    
    switch (areaId) {
      case 1:
        updatedStatuses.doorStatus = "processing";
        break;
      case 2:
        updatedStatuses.windowStatus = "processing";
        break;
      case 3:
        updatedStatuses.flooringStatus = "processing";
        break;
      case 4:
        updatedStatuses.wallsStatus = "processing";
        break;
      case 5:
        updatedStatuses.ceilingStatus = "processing";
        break;
      case 6:
        updatedStatuses.electricalsStatus = "processing";
        break;
      default:
        break;
    }
  
    setAreasStatusses(updatedStatuses);
    setareasStatusses(updatedStatuses);
    
    const allRoomsElectricalMeasurementsfromLocalStorage = JSON.parse(localStorage.getItem('electricalsData')) || []
    const remainingRoomElectricalMeasurements =  allRoomsElectricalMeasurementsfromLocalStorage.filter(p=>p.roomId!== roomId)||null
    // Filter out quantities with a value of 0
    //const nonZeroQuantities = quantities.filter((quantity) => quantity.quantity !== 0);
  console.log(quantities)
    // Add additional properties to each item in the array
    const electricalMeasurements = quantities.map((quantity) => ({
      ...quantity,
      roomId: selecetedRoomData.roomId,
      flatId: flatId,
      areaId: SelectedAreadata.areaId,
      areaSubId: 1,
      projectId:projectId,
      blockId:blockId,
    }));
    setArea([{...Area, isEditing:false}])
    console.log(electricalMeasurements)
    // Save data to local storage
    localStorage.setItem('electricalsData', JSON.stringify([...electricalMeasurements,...remainingRoomElectricalMeasurements]));

    const updatedQuantities = quantities.map((quantity) => {
      const existingQuantity = electricalMeasurements.find((existing) => existing.modularType === quantity.modularType);
      return existingQuantity ? existingQuantity : quantity;
    });
    setQuantities(updatedQuantities);
    setEditMode(false);
    
  };
  

const handleEdit = () => {
  setEditMode(true);
}
  
  
  
  useEffect(() => {
    // Load data from local storage on component mount
    const allRoomsElectricalMeasurementsfromLocalStorage = JSON.parse(localStorage.getItem('electricalsData')) || [...quantities]
  ////console.log(typeof(allRoomsElectricalMeasurementsfromLocalStorage[0].roomId))
  //console.log(roomId)
    const selecetedRoomElectricalMeasurements =  allRoomsElectricalMeasurementsfromLocalStorage.filter(p=>p.roomId === roomId)
    
    // Combine the existing data with the default quantities
    const updatedQuantities = quantities.map((quantity) => {
      const existingQuantity = selecetedRoomElectricalMeasurements.find((existing) => existing.modularType === quantity.modularType);
      return existingQuantity ? existingQuantity : quantity;
    });
  
    setQuantities(updatedQuantities);
  }, [roomId]);

    return(

<div className="flex justify-center">
{flatAlertVisible && (
  <div className="fixed inset-0 flex z-10 items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30"></div>

    {/* Popup content */}
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-bold mb-2">{flatAlertMessage}</p>
      <button
        className="items-center flex justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
        onClick={() => {
          setFlatAlertVisible(false);
          // Additional handling on OK button click if needed
        }}
      >
        OK
      </button>
    </div>
  </div>
)}
{alertMessage && (
  <div className="fixed inset-0 flex z-10 items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30"></div>

    {/* Popup content */}
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-bold mb-2">{alertMessage}</p>
      <button
        className="items-center flex justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
        onClick={() => {
          setAlertMessage(false);
          // Additional handling on OK button click if needed
        }}
      >
        OK
      </button>
    </div>
  </div>
)}
{SelectedAreadata && SelectedAreadata.areaName !== 'Electricals'&&(          
<div className="w-3/4 flex flex-col items-center">
        {/* Header */}
        {subAreaData && subAreaData.length > 0 && subAreaData.map((SubAreas, index) => (
          <div key={index}>
        
            <div className="w-full mb-4">
            <h1 className="text-lg flex justify-center font-semibold ">
  {SelectedAreadata.areaName === 'Doors' || SelectedAreadata.areaName === 'Windows' || SelectedAreadata.areaName === 'Walls'
    ? SelectedAreadata.areaName.slice(0, -1)
    : SelectedAreadata.areaName
  } {SelectedAreadata.areaName !== 'Flooring' && SelectedAreadata.areaName !== 'Ceiling' && index + 1}
</h1>
              <div className="container mx-auto mt-8 p-4 border border-gray-300 bg-gray-100 flex flex-col items-center justify-center">
                {Area.filter(p => p.areaSubId === SubAreas[0].areaSubId).map((data, rowindex) => (
                  <div key={rowindex} className="flex flex-col items-center space-y-4 mb-4">
                  <div className="flex flex-row items-center space-x-4 ">
                    <div className="flex flex-row items-center space-x-2">
                      <label>Length</label>
                      <input
                        type="int"
                        placeholder="inches"
                        value={data.length}
                        onChange={(e) => handleAreaRowInputChange(rowindex, 'length', e.target.value, index, data)}
                        className={`border px-1 py-1 ${data.isEditing ? 'border-gray-300' : 'border-gray-300'}  focus:border-gray-300 w-20`}
                        disabled={!data.isEditing}
                      />
                      <span className="mr-4 opacity-70"> inches</span>
  
                      <label>Breadth</label>
                      <input
                        type="int"
                        placeholder="inches"
                        value={data.breadth}
                        onChange={(e) => handleAreaRowInputChange(rowindex, 'breadth', e.target.value, index, data)}
                        className={`border px-1 py-1 ${data.isEditing ? 'border-gray-300' : 'border-gray-300'} focus:outline-none focus:border-gray-300 w-20 `}
                        disabled={!data.isEditing}
                      />
                      <span className="opacity-70"> inches</span>
                    </div>
  
                    <div className="flex items-center space-x-2">
                      <label>
                        <input
                          type="radio"
                          value="addition"
                          checked={data.type === 'addition'}
                          onChange={() => handleAreaRowRadioChange(rowindex, 'addition', index, data)}
                          disabled={!data.isEditing}
                        />
                        Add
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="deduction"
                          checked={data.type === 'deduction'}
                          onChange={() => handleAreaRowRadioChange(rowindex, 'deduction', index, data)}
                          disabled={!data.isEditing} />
                        Deduct
                      </label>
                    </div>

                    


                    <div className="flex ml-5 items-center space-x-2">
  {(!data.isEditing && (
    <button onClick={() => handleAreaRowEdit(rowindex, index,SubAreas)} className="text-blue-500 hover:text-blue-700">
      <PencilIcon className="w-5 h-5" />
    </button>
  ))}

  {(data.isEditing && (
    <>
      <button onClick={() => handleAreaRowSave(rowindex, index, data)} className="text-green-500 hover:text-green-700">
        <CheckIcon className="w-5 h-5" />
      </button>
    </>
  ))}

  <button onClick={() => handleAddAreaRowDelete(rowindex, index, data)} className="text-red-500 hover:text-red-700">
    <TrashIcon className="w-5 h-5" />
  </button>

  {Area.filter(p => p.areaSubId === SubAreas[0].areaSubId).length === rowindex + 1 && (
    <button onClick={(e) => handleAddAreaRow(index, rowindex, data)} className="text-blue-500 hover:text-blue-700">
      <PlusIcon className="w-5 h-5" />
    </button>
  )}
</div>


                    </div>
                    {/* With Frame/Without Frame radio buttons */}
{SelectedAreadata.areaName === 'Doors' && (
 <div className="flex items-center space-x-6 mt-2">
  <div className=" flex items-center space-x-2 ">
     <input
         type="radio"
         value="withoutFrame"
         checked={data.doorType === 'withoutFrame' || data.doorType === undefined}
         onChange={() => handleFrameRadioChange(rowindex, 'withoutFrame', index, SubAreas)}
         disabled={!data.isEditing}
     />
     <span className="mr-2">Without Frame</span>
 </div>
 <div className=" flex items-center space-x-2 ">
     <input
         type="radio"
         value="withFrame"
         checked={data.doorType === 'withFrame'}
         onChange={() => handleFrameRadioChange(rowindex, 'withFrame', index, SubAreas)}
         disabled={!data.isEditing}
     />
     <span className="mr-2">With Frame</span>
 </div>
 
</div>

)}
{SelectedAreadata.areaName === 'Walls' && (
 <div className="flex items-center space-x-6 mt-2">
  <div className=" flex items-center space-x-2 ">
     <input
         type="radio"
         value="paintableWall"
         checked={data.wallType === 'paintableWall' }
         onChange={() => handleWallRadioChange(rowindex, 'paintableWall', index, SubAreas)}
         disabled={!data.isEditing}
     />
     <span className="mr-2">Paintable Wall</span>
 </div>
 <div className=" flex items-center space-x-2 ">
     <input
         type="radio"
         value="tilebleWall"
         checked={data.wallType === 'tilebleWall' || data.wallType === undefined}
         onChange={() => handleWallRadioChange(rowindex, 'tilebleWall', index, SubAreas)}
         disabled={!data.isEditing}
     />
     <span className="mr-2">Tileble Wall</span>
 </div>
 
</div>

)}
                  </div>
                ))}
              </div>
            </div>
            
            <div className="m-4 flex justify-center">
  {subAreaData.length === index + 1 && (
    // if the areaName is 'Ceiling' or 'Flooring' then button will be hidden.
    SelectedAreadata.areaName !== 'Ceiling' && SelectedAreadata.areaName !== 'Flooring' && (
      <button
        onClick={(e) => handleAddSubArea(index,SubAreas)}
        className="text-blue-500 p-1 hover:underline active:text-blue-500"
      >
        Add {SelectedAreadata.areaName === 'Doors' || SelectedAreadata.areaName === 'Windows' || SelectedAreadata.areaName === 'Walls'
          ? SelectedAreadata.areaName.slice(0, -1) // Slice the last letter for Doors, Windows, Walls
          : SelectedAreadata.areaName}
      </button>
    )
  )}
</div>

            
          </div>
        ))}
  
        {/* Add Another Door Button */}
        <div className="flex justify-center">
          <button className="text-blue-500 p-1 hover:underline active:text-blue-500"
            onClick={() => handleCompletedArea(SelectedAreadata)}
          >
            {SelectedAreadata.areaName}  Completed
          </button>
        </div>
      </div>
        )}

{/* Conditionally render content for Electricals */}
{SelectedAreadata && SelectedAreadata.areaName === 'Electricals' && (
    <div className="electricals-layout">
<h1 className="text-lg flex justify-center font-semibold ">{SelectedAreadata.areaName} </h1>
    {/* Type and Quantity headings */}
<div>
    <table className="bg-gray-200 mt-4 border-collapse border border-white">
        <thead>
            <tr>
                <th className="border p-2 border-white font-normal">Type</th>
                {quantities.map((quantity) => (
                    <th key={quantity.modularType} className="border p-2 border-white font-normal">
                        {`${quantity.modularType} mod`}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            <tr>
                <th className="border p-2 border-white font-normal">Quantity</th>
                {quantities.map((quantity) => (
                    <td key={quantity.modularType} className="border p-2 border-white">

                        <div className="flex items-center">
                            {editMode &&  (
                                <button
                                    onClick={() => handleQuantityDecrement(quantity.modularType)}
                                    className="bg-gray-400 text-white px-1 rounded-sm hover:bg-gray-600 focus:outline-none focus:shadow-outline-red active:bg-red-800"
                                >
                                    -
                                </button>
                            )}
                            {editMode ? (
                                <input
                                    type="text"
                                    placeholder="Quantity"
                                    value={quantity.quantity}
                                    className="w-6  rounded-sm   text-center"
                                    
                                />
                            ) : (
                                <div className="flex justify-center items-center w-8 rounded-sm bg-gray-100 " 
                                >
                                    <p >{quantity.quantity} </p>
                                    
                                </div>
                            )}
                            {editMode &&  (
                                <button
                                    onClick={() => handleQuantityIncrement(quantity.modularType)}
                                    className="bg-gray-400 text-white px-1 rounded-sm hover:bg-gray-600 focus:outline-none focus:shadow-outline-green active:bg-green-800"
                                >
                                    +
                                </button>
                            )}
                        </div>
                        <div className="text-center">{/* {getQuantity(quantity.modularType)} */}</div>

                    </td>
                ))}
            </tr>
        </tbody>
    </table>
    </div>
    <div className="mb-2 flex justify-center mt-2">
        {editMode ? (
            <button
                onClick={handleSave}
                className="text-green-600  px-1 py-1  hover:underline  active:text-green-800"
            >
                Save
            </button>
        ) : (
            <button
                onClick={handleEdit}
                className=" text-blue-500  px-1 py-1  hover:underline  active:text-blue-800"
            >
                Edit
            </button>
        )}
    </div>
    <div className="flex justify-center">
        <button className="text-blue-500 p-1 hover:underline text-bold text-md active:text-blue-500"
            onClick={() => handleCompletedArea(SelectedAreadata)}
        >
            {SelectedAreadata.areaName} Completed
        </button>
    </div>
</div>

)}
 </div>
 
    )
};
export default AreasMeasurements;
