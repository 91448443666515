import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faProjectDiagram, faUser, faSignOutAlt, faUserPlus ,faChartBar} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';


function LeftsideMenu() {

  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
       Navigate('/measurit')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
       Navigate('/measurit')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  
  const userRole= sessionStorage.getItem('userRole');
    const Navigate=useNavigate();
    const [selectedOption, setSelectedOption] = useState('home');

    const handleclickHome = () => {
      setSelectedOption('home');
      if (userRole === '1') {
        Navigate('/measurit/AdminHome');
      } else {
        Navigate('/measurit/UserHome');
      }
    };
  
    const handleCreateUser = async () => {
      setSelectedOption('createUser');
      Navigate('/measurit/CreateUser');
    };
  
    const handleProfile = async () => {
      setSelectedOption('profile');
      Navigate('/measurit/Profile');
    };
  
    const handleCreateProject = async () => {
      setSelectedOption('createProject');
      Navigate('/measurit/CreateProject');
    };


    const handleReports = async () => {
      setSelectedOption('reports');
      Navigate('/measurit/Reports');
    };
  
    const handleLogout = async () => {
      setSelectedOption('logout');
      localStorage.removeItem('LoginData');
      localStorage.removeItem('BlocksData');
      //sessionStorage.removeItem('userData');
     // sessionStorage.removeItem('userRole');
     sessionStorage.clear(); 
      const loginPageUrl = '/measurit';
        // Push multiple states to the history to prevent going back to the homepage
        if (window.history && window.history.pushState) {
          for (let i = 0; i < 140; i++) {
            window.history.pushState({}, document.title, loginPageUrl);
          }
          window.addEventListener('popstate', function (event) {
            if (event.state === null) {
              for (let i = 0; i < 140; i++) {
                window.history.pushState({}, document.title, loginPageUrl);
              }
            }
          });
        }
        // Redirect the user to the login page
        window.location.replace(loginPageUrl);
      };
      

    return(
      <div>
  {/* Left Side Menubar */}
  <div className="w-1/6 flex flex-col bg-white text-black shadow-md fixed h-full">
    {/* Company Name and Separator */}
    <a onClick={handleclickHome} className="p-2 flex items-center justify-center m-1">
      <span className="text-lg font-bold cursor-pointer">MEASURIT</span>
    </a>

    <hr className="border-b border-gray-300 mx-4 mb-1" />

    {/* Menu Items */}
    <div className="pt-4 flex flex-col space-y-4">
      <div
        onClick={handleclickHome}
        className={`flex flex-row space-x-2 items-center text-md hover:bg-[#f9f4f4] cursor-pointer h-8 ${
          selectedOption === 'home' ? 'bg-[#f9f4f4]' : ''
        } text-black md:flex`}
      >
        <div className="ml-4 flex items-left">
          <FontAwesomeIcon icon={faHome} className="" />
        </div>
        <div className="flex items-left">
          <span className="text-sm hidden sm:inline">Home</span>
        </div>
      </div>

      <div
        onClick={handleCreateProject}
        className={`flex flex-row space-x-2 items-center text-md hover:bg-[#f9f4f4] cursor-pointer h-8 ${
          selectedOption === 'createProject' ? 'bg-[#f9f4f4]' : ''
        } text-black md:flex`}
      >
        <div className="ml-4 flex items-left">
          <FontAwesomeIcon icon={faProjectDiagram} className="" />
        </div>
        <div className="flex items-left">
          <span className="text-sm hidden sm:inline">Create Project</span>
        </div>
      </div>
            {userRole === '1' && (
        <div
          onClick={handleCreateUser}
          className={`flex flex-row space-x-2 items-center text-md hover:bg-[#f9f4f4] cursor-pointer h-8 ${
            selectedOption === 'createUser' ? 'bg-[#f9f4f4]' : ''
          } text-black md:flex`}
        >
          <div className="ml-4 flex items-left">
            <FontAwesomeIcon icon={faUserPlus} className="" />
          </div>
          <div className="flex items-left">
            <span className="text-sm hidden sm:inline">Create User</span>
          </div>
        </div>
      )}

        {userRole === '1' && (
        <div
            onClick={handleReports}
            className={`flex flex-row space-x-2 items-center text-md hover:bg-[#f9f4f4] cursor-pointer h-8 ${
              selectedOption === 'reports' ? 'bg-[#f9f4f4]' : ''
            } text-black md:flex`}
          >
            <div className="ml-4 flex items-left">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="flex items-left">
              <span className="text-sm hidden sm:inline">Reports</span>
            </div>
          </div>
          )}

      <div
        onClick={handleProfile}
        className={`flex flex-row space-x-2 items-center text-md hover:bg-[#f9f4f4] cursor-pointer h-8 ${
          selectedOption === 'profile' ? 'bg-[#f9f4f4]' : ''
        } text-black md:flex`}
      >
        <div className="ml-4 flex items-left">
          <FontAwesomeIcon icon={faUser} className="mr-2" />
        </div>
        <div className="flex items-left">
          <span className="text-sm hidden sm:inline">Profile</span>
        </div>
      </div>
    </div>
    {/* Spacer */}
    <div className="flex-grow sm:flex-grow-0 md:flex-grow"></div>
        <hr className="border-b border-gray-300 mx-4" />
    {/* Logout */}
    <div className="p-4 hover:bg-[#f9f4f4]">
      <div className="ml-2 flex items-left">
        <a
          onClick={handleLogout}
          className={`flex items-center text-md cursor-pointer ${
            selectedOption === 'logout' ? 'text-[#f9f4f4]' : 'text-black'
          }`}
        >
          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
          <span className="text-sm hidden sm:inline">Logout</span>
        </a>
      </div>
    </div>
  </div>
  {/* Left Side Menubar Ends */}
</div>


    );
}
export default LeftsideMenu;