import React, { Children, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function FlatContainer({ block, blockIndex, actualFlatIndex, flatDetails, children }) {
  const [flats, setFlats] =useState (flatDetails.filter((p) => p.blockId === block._id));
  const [flatUpdateAllert,setflatUpdateAllert]=useState(false);
  const [editModeIndex, setEditModeIndex] = useState(null);
  const Navigate= useNavigate();

  const roleId = sessionStorage.getItem('userRole');
  console.log(roleId)
  console.log(typeof roleId);
  const roleIdNumber = parseInt(roleId);



  const location = useLocation();
  //const projectId = location.state.blocksData.projectId;
 const blocksData = JSON.parse(localStorage.getItem('BlocksData'));
 const projectId = blocksData[0].projectId;
//console.log(projectId)
//console.log(blocksData)
const blockId = blocksData[0]._id;
//console.log(blockId)


  const handleEditClick = (index) => {
    // Set edit mode for the selected index
    setEditModeIndex(index);
  };
  
  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      //console.log('profile matched')
      }else{
        //console.log('logout')
       Navigate('/measurit')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      Navigate('/measurit')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

  const handleSaveFlat = async (index) => {
    try {
   
      //console.log(projectId);
      const flatToSave = {...flats[index],projectId:projectId};
      const token = sessionStorage.getItem('token');
      // Check if any of the required fields are empty
      if (!flatToSave.flatNo || !flatToSave.type || !flatToSave.facing || !flatToSave.plinthArea) {
        setflatAlertMessage('Please fill in all required fields before saving.');
        setflatAlertVisible(true);
        return;
      }
  
      //console.log(flatToSave);
  
      const saveFlatData = process.env.REACT_APP_FORE_APILINK + '/flats/update-flat-Data';
  
      const response = await fetch(saveFlatData, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-token':token
        },
        body: JSON.stringify(flatToSave),
      });
  
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setflatUpdateAllert(true);
      } else {
        //console.error('Failed to update flat data');
      }
    } catch (error) {
      clearStorageAndRedirectToHome()
      //console.error('Error fetching to update the flat data:', error);
    }
  };
  
  // Add state for custom alert message
  const [flatAlertVisible, setflatAlertVisible] = useState(false);
  const [flatAlertMessage, setflatAlertMessage] = useState('');
  
  


  const [flatDeleteAllert,setflatDeleteAllert]=useState(null);
  const handleDeleteFlat=async(flat, index)=>{
   ////console.log(flat, index);
   setflatDeleteAllert(flat);
  }


  const handleInputChange = (e, index, field) => {
    const updatedFlats = [...flats];
    updatedFlats[index] = { ...updatedFlats[index], [field]: e.target.value };
    setFlats(updatedFlats);
  };
  const handleCloseUpdateAlert=async()=>{
    setflatUpdateAllert(false)
  setEditModeIndex(null);

  }
   
  const handleYesClick = async () => {
    try {
      const token = sessionStorage.getItem('token');
      // Delete the flat
      const DeleteFlat = process.env.REACT_APP_FORE_APILINK + '/flats/delete-flat';
      const response = await fetch(DeleteFlat, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-token':token
        },
        body: JSON.stringify(flatDeleteAllert),
      });
  
      if (response.ok) {
        // Check if there are no more flats after deletion
        const remainingFlats = flats.filter((flat) => flat._id !== flatDeleteAllert._id);
  
        if (remainingFlats.length === 0) {
          // If no more flats, navigate back
          Navigate(-1);
        } else {
          // Reload the window to reflect the changes
          window.location.reload();
        }
  
        setflatDeleteAllert(null);
      } else {
        //console.error('Failed to delete flat');
      }
    } catch (error) {
      clearStorageAndRedirectToHome()
      //console.error('Error while deleting the flat:', error);
    }
  };
  
const createMeasurementsStatusOfFlat = process.env.REACT_APP_FORE_APILINK + '/measurements/create-measurement-status-of-flat';

  const handleMeasurements = async (flat) => {
    //console.log(flat._id);
  
    // Add the required field checks for measurements
    if (!flat.flatNo || !flat.type || !flat.facing || !flat.plinthArea) {
      setflatAlertMessage('Please fill in all required fields before proceeding with measurements.');
      setflatAlertVisible(true);
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(createMeasurementsStatusOfFlat, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
        body: JSON.stringify({
          flatId: flat._id,
        }),
      });
  
      if (response.ok) {
        const body = await response.json();
        //console.log(body);
  
        const Measurements = JSON.parse(localStorage.getItem('measurementData')) || [];
        const electricalMeasurements = JSON.parse(localStorage.getItem('electricalsData')) || [];
        const AllData = [...Measurements, ...electricalMeasurements];
        const findAreaMeasurements = AllData.find((p) => p.flatId === flat._id) || null;
  
        if (!findAreaMeasurements) {
          //console.log('helo');
          localStorage.removeItem('measurementData');
          localStorage.removeItem('RoomsData');
          localStorage.removeItem('areaStatus');
          localStorage.removeItem('electricalsData');
          Navigate('/measurit/Measurements', { state: { flatId: flat._id, projectId:projectId, blockId: blockId } });

        } else {
          Navigate('/measurit/Measurements', { state: { flatId: flat._id, projectId:projectId, blockId: blockId } });        }
      } else {
        //console.log('Already exists');
  
        const Measurements = JSON.parse(localStorage.getItem('measurementData')) || [];
        const electricalMeasurements = JSON.parse(localStorage.getItem('electricalsData')) || [];
        const AllData = [...Measurements, ...electricalMeasurements];
        const findAreaMeasurements = AllData.find((p) => p.flatId === flat._id) || null;
  
        if (!findAreaMeasurements) {
          //console.log('helo');
          localStorage.removeItem('measurementData');
          localStorage.removeItem('RoomsData');
          localStorage.removeItem('areaStatus');
          localStorage.removeItem('electricalsData');
          Navigate('/measurit/Measurements', { state: { flatId: flat._id, projectId: projectId, blockId: blockId } });        } else {
            Navigate('/measurit/Measurements', { state: { flatId: flat._id, projectId: projectId, blockId: blockId } });        }
      }
    } catch {
      clearStorageAndRedirectToHome()
      //console.error('Failed to fetch');
    }
  };
  
  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }
  
 
  return (
    <div key={`${blockIndex}-${actualFlatIndex}`} className="flat-input font-roboto">
      {flatUpdateAllert&&(
        <div className="fixed inset-0 z-10 flex items-center justify-center">
        {/* Background overlay (blurred) */}
        <div className="absolute inset-0 bg-black opacity-30 "></div>
 
        {/* Popup content */}
        <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
          <p className="text-lg font-bold mb-2">
           Flat saved successfully!!
         </p>
         <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleCloseUpdateAlert}>
           OK
         </button>
       </div>
     </div>
      )}

{flatDeleteAllert&&(
       <div className="fixed inset-0 flex z-10 items-center justify-center">
       {/* Background overlay (blurred) */}
       <div className="absolute inset-0 bg-black opacity-30 "></div>

       {/* Popup content */}
       <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
         <p className="text-lg font-bold mb-2">
           Are you sure want to delete Flat?
         </p>
         <div className="flex justify-between mt-4">
              <button
                className="items-center flex justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
                onClick={handleYesClick}>
                Yes
              </button>
              <button
                className="items-center flex justify-center text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4 ml-6"
                onClick={() => {setflatDeleteAllert(null)}}
              >
                No
              </button>
            </div>
       </div>
     </div>
      )}
      {flatAlertVisible && (
  <div className="fixed inset-0 flex z-10 items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30"></div>

    {/* Popup content */}
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-bold mb-2">{flatAlertMessage}</p>
      <button
        className="items-center flex justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
        onClick={() => setflatAlertVisible(false)}
      >
        OK
      </button>
    </div>
  </div>
)}
      <div className="flex flex-wrap justify-center max-md:max-w-full max-md:mt-10 h-auto">
        <h2 className="text-left ml-6 mt-4">Block {block.blockNo}</h2>
        {flats.map((flat, Index) => (
          <div className=" border bg-white self-stretch mt-2 pl-7 pr-12 pt-4 pb-8 rounded-md shadow-sm border-solid border-black border-opacity-20 max-md:max-w-full ml-6 mr-6 max-md:px-5 flex w-full" /* key={`${flat._id}`} */>
            <div className="flex-grow flex items-center justify-center">
              <div className="flex-grow flex flex-col items-stretch mb-5 sm:mb-0 w-full sm:w-1/5">
                <div className="text-black text-opacity-80 text-base whitespace-nowrap">Flat No</div>
                <input
                  className={`w-full sm:w-1/2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 border self-stretch flex-shrink-0 h-[32px] flex-col mt-2 pl-2 pr-2 rounded-sm border-solid border-black border-opacity-20 ${editModeIndex !== Index ?'bg-gray-200  ':'bg-white'}`}
                  
                  type="text"
                  placeholder=""
                  value={flat.flatNo || ''}
                  disabled={editModeIndex !== Index}
                  onChange={(e) => handleInputChange(e, Index, 'flatNo')}
                />
              </div>

              <div className="flex-grow flex flex-col items-stretch mb-5 sm:mb-0 w-full sm:w-1/5">
                <div className="text-black text-opacity-80 text-base whitespace-nowrap">Type</div>
                <select
                 className= {`w-full sm:w-1/2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 border self-stretch flex-shrink-0 h-[32px] flex-col mt-2 rounded-sm border-solid border-black border-opacity-20 ${editModeIndex !== Index ?'bg-gray-200  ':'bg-white'}`}
                  value={flat.type || ''}
                  disabled={editModeIndex !== Index}
                  onChange={(e) => handleInputChange(e, Index, 'type')}
                  style={{ textAlign: 'center' }} // Center align the text
                >
                  <option value="">Select</option>
                  <option value="1 BHK">1 BHK</option>
                  <option value="2 BHK">2 BHK</option>
                  <option value="3 BHK">3 BHK</option>
                  <option value="4 BHK">4 BHK</option>
                </select>
              </div>
              <div className="flex-grow flex flex-col items-stretch mb-5 sm:mb-0 w-full sm:w-1/5">
                <div className="text-black text-opacity-80 text-base whitespace-nowrap">Facing</div>
                <select
                  className= {`w-full sm:w-1/2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 border self-stretch flex-shrink-0 h-[32px] flex-col mt-2  rounded-sm border-solid border-black border-opacity-20 ${editModeIndex !== Index ?'bg-gray-200  ':'bg-white'}`}
                  value={flat.facing || ''}
                  disabled={editModeIndex !== Index}
                  onChange={(e) => handleInputChange(e, Index, 'facing')}
                >
                  <option value="">Select</option>
                  <option value="north">North</option>
                  <option value="south">South</option>
                  <option value="east">East</option>
                  <option value="west">West</option>
                </select>
              </div>

              <div className="flex-grow flex flex-col items-stretch mb-5 sm:mb-0 w-full sm:w-1/5">
  <div className="text-black text-opacity-80 text-base whitespace-nowrap">Plinth Area</div>
  <div className="flex items-center">
    <input
      className={`w-full sm:w-1/2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 border self-stretch flex-shrink-0 h-[32px] flex-col mt-2 pl-2 pr-2 rounded-sm border-solid border-black border-opacity-20 ${editModeIndex !== Index ? 'bg-gray-200  ' : 'bg-white'}`}
      type="text"
      placeholder="Sq ft"
      value={flat.plinthArea || ''}
      readOnly={editModeIndex !== Index}
      onChange={(e) => handleInputChange(e, Index, 'plinthArea')}
    />
    <span className="ml-1 mt-2 text-gray-600"> sq.ft</span>
  </div>
</div>


              <div className="flex-grow flex flex-row space-x-2 items-stretch mt-2 mb-5 sm:mb-0 w-full sm:w-1/5">
              {editModeIndex === Index ? (
                <button
                className="text-opacity-100 text-bold text-md whitespace-nowrap text-[#0f9d58] hover:underline justify-center items-center mt-6 px-4 py-1  self-center max-md:px-5 transition-colors duration-300"
                  type="button"
                  onClick={() =>  handleSaveFlat(Index) }
                >
                  Save
                </button>
                 ) : (
                  <button
                  className=" text-opacity-100 text-bold text-md whitespace-nowrap text-blue-500 hover:underline justify-center items-center mt-6 px-4 py-1 self-center max-md:px-5 transition-colors duration-300"
                  type="button"
                  onClick={() => handleEditClick(Index)}
                >
                  Edit
                </button>
                  )}
                 <button
                  className="text-opacity-100 text-bold whitespace-nowrap text-md text-red-500 hover:underline justify-center items-center mt-6 px-4 py-1 self-center max-md:px-5 transition-colors duration-300"
                  type="button"
                  onClick={() =>  handleDeleteFlat(flat, Index) }
                >
                 Delete
                </button>

                

              </div>
              {roleIdNumber !== 3 && (
  <div className="flex-grow flex flex-col space-y-1 items-stretch mb-5 w-full sm:mb-0 sm:w-full md:w-1/4 sm:ml-1 sm:mt-2 sm:justify-center">
    <button
      className="text-white text-opacity-100 text-base whitespace-nowrap bg-[#0f9d58] sm:ml-16 ml-5 hover:bg-green-600 justify-center items-center mt-6 px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300"
      type="button"
      onClick={(e) => handleMeasurements(flat)}
    >
      Measurements 
    </button>
    <div className="ml-8 text-center">
      <p className="text-xs">Status: {flat.flatMeasurementsStatus}</p>
    </div>
  </div>
)}


            </div>

          
          </div>
        ))}
        
      
      </div>
      {children && children}
    </div>
    
  );
}

export default FlatContainer;
