import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faProjectDiagram,
  faUser,
  faSignOutAlt,
  faBell,
  faUserPlus,
  faAngleLeft,faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FlatContainer from './flatContainer';


function FlatsContent() {
    const location = useLocation();
    const blocksData = location.state?.blocksData || JSON.parse(localStorage.getItem('BlocksData'));
    const [flatDetails, setFlatDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const Navigate= useNavigate();

    const roleId = sessionStorage.getItem('userRole');
    console.log(roleId)
    const roleIdNumber = parseInt(roleId);
  
    const [userProfileImage, setuserProfileImage] = useState();
  
    useEffect(()=>{
      const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
      const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
      if(storedLoginData&&Userdata){
        if(storedLoginData.token===Userdata.token){
        //console.log('profile matched')
        }else{
          //console.log('logout')
         Navigate('/measurit')
         sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
        }
      }else{
        Navigate('/measurit')
          sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
      }
    })
    
    const getUserDetails = async (_id) => {
      const GetUserData = `${process.env.REACT_APP_FORE_APILINK}/user/get-user-details?userId=${_id}`;
  
      try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(GetUserData, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  'x-token': token
              }
          });
  
          const data = await response.json();
          //console.log(data);
  
          // Update state with user details
          setuserProfileImage(data.user.userProfileImage || null);
  
      } catch (error) {
        clearStorageAndRedirectToHome()

          //console.error("Error fetching user details:", error.message);
      }
  };
  
  
    useEffect(() => {
      const storedLoginData = localStorage.getItem('LoginData');
      if (storedLoginData !== null) {
          const storedUserDetails = JSON.parse(storedLoginData);
         // //console.log(storedUserDetails);
    getUserDetails(storedUserDetails.userId);
          // Now storedUserDetails is an object containing the user details
      } else {
          // Handle the case where no data is stored under the key 'UserDetails'
      }
      
  }, []); 
  
  const fetchFlatsDetails = async () => {
    try {
      const blockIds = blocksData.map((block) => block._id);
      const token = sessionStorage.getItem('token');
  
      const promises = blockIds.map(async (blockId) => {
        const response = await fetch(
          `${process.env.REACT_APP_FORE_APILINK}/flats/get-all-flats-of-block?blockId=${blockId}`,
          {
            method: 'GET',
            headers: {
              'x-token': token,
            },
          }
        );
  
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Failed to fetch flat details for block ${blockId}: ${response.status} - ${response.statusText}`);
        }
      });
  
      const results = await Promise.all(promises);
      const allFlatDetails = results.reduce((acc, flatDetails) => acc.concat(flatDetails), []);
  
      setFlatDetails(allFlatDetails);
      setLoading(false);
    } catch (error) {
      clearStorageAndRedirectToHome()

      //console.error('Error during API call:', error.message);
      setLoading(false);
    }
  };
  
  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }

    const handleBack = () => {
      // Define the action when the back button is clicked
      // For example, you can navigate to the previous page
      Navigate(-1);
  };

    useEffect(() => {
        fetchFlatsDetails();
        localStorage.setItem('BlocksData',JSON.stringify(blocksData))
      }, []);
      
      
        if (loading) {
          return <div>Loading...</div>;
        }

        const handleSubmit = async () => {
          // Your submission logic here
          setShowPopup(true); // Step 2: Show pop-up on submission
        };
      
        const handleOkClick = () => {
          setShowPopup(false); // Step 3: Hide pop-up when "OK" button is clicked
          Navigate('/measurit/UserHome'); // Step 4: Navigate to UserHome
        };


return (
<div className="overflow-x-scroll no-scrollbar">
<div className="flex flex-col ml-5 items-stretch">
         {/* Back Button */}
<div className="flex justify-start items-center mr-2 ml-2 mb-2 mt-8">
<button onClick={handleBack} className="relative flex items-center text-md text-black rounded-md px-5 py-1 mt-3">
  <FontAwesomeIcon icon={faAngleLeft} className="text-lg cursor-pointer text-black mr-2" /> Back
</button>
</div>
        {blocksData.map((block, blockIndex, actualFlatIndex) => (
          <FlatContainer
            key={blockIndex}
            block={block}
            blockIndex={blockIndex}
            actualFlatIndex={blockIndex}
            flatDetails={flatDetails}
          >
          
          </FlatContainer>
        ))}


{roleIdNumber === 3 && (
  <div className="flex justify-center items-center mt-4">
    <button
      className="text-white text-base font-medium self-stretch max-md:max-w-full px-6 py-1 mt-6 rounded-md bg-[#0f9d58] hover:bg-green-700 transition-colors duration-300"
      onClick={handleSubmit}
    >
      Submit
    </button>
  </div>
)}

 {/* Pop-up */}
 {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25">
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <p className="text-xl font-semibold mb-4">Flats details submitted successfully!</p>
              <div className="flex justify-center items-center mt-4">

              <button
      className="text-white text-base font-medium self-stretch max-md:max-w-full px-6 py-1 mt-6 rounded-md bg-[#0f9d58] hover:bg-green-700 transition-colors duration-300"
      onClick={handleOkClick}
              >
                OK
              </button>
              </div>
            </div>
          </div>
        )}

 </div>
</div>
)
}
export default FlatsContent;