import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSortUp, faSortDown, faHome, faHomeAlt, faBagShopping, faBank, faCartShopping, faPlusCircle, faUsers, faBell, faGear, faUserCircle,  faPlus, faSearch, faBasketShopping, faStopwatch, faUserPlus, faRectangleList, faFile, faFolder } from '@fortawesome/free-solid-svg-icons';

const Quotes = () => {
  const [quotes, setQuotes] = useState([
    { date: '08/07/2024', quoteNumber: 'QT-000005', referenceNumber: '', customerName: 'gopi', status: 'DRAFT', amount: '₹693.14' },
    { date: '08/07/2024', quoteNumber: 'QT-000004', referenceNumber: '', customerName: 'gopi', status: 'DRAFT', amount: '₹693.14' },
    { date: '08/07/2024', quoteNumber: 'QT-000003', referenceNumber: '', customerName: 'gopi', status: 'DRAFT', amount: '₹693.14' },
    { date: '02/07/2024', quoteNumber: 'QT-000002', referenceNumber: '', customerName: 'Mr. yashwanth khatri', status: 'INVOICED', amount: '₹1,390.00' },
    { date: '02/07/2024', quoteNumber: 'QT-000001', referenceNumber: '', customerName: 'veda constructions', status: 'SENT', amount: '₹1,390.00' },
  ]);

  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const handleSalesClick = (menuItem) => {
    if (selectedMenuItem === menuItem) {
      setSelectedMenuItem(null);
    } else {
      setSelectedMenuItem(menuItem);
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case 'DRAFT':
        return 'text-gray-500';
      case 'INVOICED':
        return 'text-green-500';
      case 'SENT':
        return 'text-blue-500';
      default:
        return '';
    }
  };

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...quotes].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setQuotes(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  return (
    <div className="flex flex-col w-full h-screen">
      <div className="bg-[#272E40] h-14 flex items-center justify-between px-4 py-2">
      <img className="h-10 w-10 cursor-pointer" src={'/BB.png'} alt="Brick Bucket Logo" />
        <div className="relative">
  <span className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-400">
    <FontAwesomeIcon icon={faSearch} />
  </span>
  <input
    type="text"
    className="bg-[#42495E] text-white pl-10 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    placeholder="Search in Quotes (/)"
  />
 
</div>
        <div className="flex items-center">
          <button className=" text-white px-4 py-2 rounded-md mx-2">Subscribe</button>
          <button className=" text-white px-4 py-2  mx-2">brickbucket</button>
          <button className="bg-[#EB001B] text-white px-1 py-1 rounded-md ">   
            <FontAwesomeIcon icon={faPlus} className="text-xl text-white mx-2" />
          </button>
          <div className="flex items-center mx-2">
            <FontAwesomeIcon icon={faUsers} className="text-white mx-2" />
            <FontAwesomeIcon icon={faBell} className="text-white mx-2" />
            <FontAwesomeIcon icon={faGear} className="text-white mx-2" />
            <FontAwesomeIcon icon={faUserCircle} className="text-4xl text-white mx-2" />
            <FontAwesomeIcon icon={faHomeAlt} className="text-white mx-2" />
          </div>
        </div>
      </div>
      <div className="bg-white  flex flex-col">
        <div className="flex flex-row">
        <div className="bg-[#F7F9FC] w-48 p-1 rounded-md flex flex-col overflow-y-auto">
<ul className="list-none">
  <div className='text-sm  ml-2 mt-2'>
  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" onClick={() => handleSalesClick('home')}>
    <FontAwesomeIcon icon={faHome} className="ml-2 text-gray-400 mr-2" />
    Home
  </li>
  </div>
  <div className='text-sm ml-2 mt-2'>
  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" >
    <FontAwesomeIcon icon={faBagShopping} className="ml-2 text-gray-400 mr-2" />
    Items
  </li>
  </div>
  <div className='text-sm ml-2 mt-2'>
  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" >
    <FontAwesomeIcon icon={faBank} className="ml-2 text-gray-400 mr-2" />
    Banking
  </li>
  </div>
  <div>
  <div className='text-sm ml-2 mt-2'>

  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" onClick={() => handleSalesClick('sales')}>
    <FontAwesomeIcon icon={faCartShopping} className="ml-2 text-gray-400 mr-2" />
    Sales
  </li>
  </div>
  {selectedMenuItem === 'sales' && (
    <ul className="list-none">
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
      Customers
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Quotes
        <FontAwesomeIcon icon={faPlusCircle} className="text-gray-400 ml-auto mr-2 " />
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Sales Orders
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Delivery Challans
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Invoices
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Payments Received
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Recurring Invoices
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Credit Notes
      </li>
    </ul>
  )}
  </div>
  <div>
          <div className='text-sm ml-2 mt-2'>
  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" onClick={() => handleSalesClick('purchase')}>
    <FontAwesomeIcon icon={faBasketShopping} className="ml-2 text-gray-400 mr-2" />
    Purchases
  </li>
  </div>
  {selectedMenuItem === 'purchase' && (
    <ul className="list-none">
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
      Vendors
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Expenses
        <FontAwesomeIcon icon={faPlusCircle} className="text-gray-400 ml-auto mr-2 " />
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Recurring Expenses
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Purchase Orders
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Bills
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Payments Made
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Recurring Bills
      </li>
      <li className="w-[80%] ml-5 text-sm flex items-center py-2 hover:bg-gray-200 hover:rounded-md cursor-pointer">
        Vendor Credits
      </li>
    </ul>
  )}
  </div>
  <div className='text-sm ml-2 mt-2'>
  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" onClick={() => handleSalesClick('purchase')}>
    <FontAwesomeIcon icon={faStopwatch} className="ml-2 text-gray-400 mr-2" />
    Time Tracking
  </li>
  </div>
  <div className='text-sm ml-2 mt-2'>
  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" onClick={() => handleSalesClick('purchase')}>
    <FontAwesomeIcon icon={faUserPlus} className="ml-2 text-gray-400 mr-2" />
    Accountant
  </li>
  </div>
  <div className='text-sm ml-2 mt-2'>
  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" onClick={() => handleSalesClick('purchase')}>
    <FontAwesomeIcon icon={faRectangleList} className="ml-2 text-gray-400 mr-2" />
    Reports
  </li>
  </div>
  <div className='text-sm ml-2 mt-2'>
  <li className="flex items-center py-2 hover:bg-gray-200 hover:rounded-l-md cursor-pointer" onClick={() => handleSalesClick('purchase')}>
    <FontAwesomeIcon icon={faFolder} className="ml-2 text-gray-400 mr-2" />
    Documents
  </li>
  </div>
</ul>
</div>
          <div className="w-full flex-1 px-4">
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-col justify-center items-center">
                <div className="container mx-auto px-4">
                  <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-bold">All Quotes</h1>
                    <button className="bg-[#EB001B] text-white px-4 py-2 rounded">+ New</button>
                  </div>
                  <table className="min-w-full bg-white rounded-md overflow-hidden">
                    <thead>
                      <tr className="bg-[#f3f3f8]">
                        <th className="text-xs py-2 px-4 border-b cursor-pointer" onClick={() => sortArray('date')}>
                          DATE {getSortIcon('date') && <FontAwesomeIcon icon={getSortIcon('date')} />}
                        </th>
                        <th className="text-xs py-2 px-4 border-b cursor-pointer" onClick={() => sortArray('quoteNumber')}>
                          QUOTE NUMBER {getSortIcon('quoteNumber') && <FontAwesomeIcon icon={getSortIcon('quoteNumber')} />}
                        </th>
                        <th className="text-xs py-2 px-4 border-b">REFERENCE NUMBER</th>
                        <th className="text-xs py-2 px-4 border-b cursor-pointer" onClick={() => sortArray('customerName')}>
                          CUSTOMER NAME {getSortIcon('customerName') && <FontAwesomeIcon icon={getSortIcon('customerName')} />}
                        </th>
                        <th className="text-xs py-2 px-4 border-b">STATUS</th>
                        <th className="text-xs py-2 px-4 border-b cursor-pointer" onClick={() => sortArray('amount')}>
                          AMOUNT {getSortIcon('amount') && <FontAwesomeIcon icon={getSortIcon('amount')} />}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {quotes.map((quote, index) => (
                        <tr key={index} className="text-sm border-b">
                          <td className="py-2 px-4">{quote.date}</td>
                          <td className="py-2 px-4 text-blue-600">{quote.quoteNumber}</td>
                          <td className="py-2 px-4">{quote.referenceNumber}</td>
                          <td className="text-sm py-2 px-4">{quote.customerName}</td>
                          <td className={`py-2 px-4 ${getStatusColor(quote.status)}`}>{quote.status}</td>
                          <td className="py-2 px-4">{quote.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotes;