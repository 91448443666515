import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faSortUp, faSortDown} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Loader, Spinner} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import Search from '../Components/Search';

const DeliveryChallanList = () => {
     
  
      const navigate = useNavigate();
      const [searchQuery, setSearchQuery] = useState('');
      const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
      const [selectedCategories, setSelectedCategories] = useState([]);


      const [deliveryChallans, setDeliveryChallans] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [showPopup, setShowPopup] = useState(false);
const [popupMessage, setPopupMessage] = useState('');
const [popupType, setPopupType] = useState('');
const [retryFunction, setRetryFunction] = useState(null);
const [filteredDC, setFilteredDC] = useState([]);
const searchTypes = ['Challen Number', 'SO Number', 'Item Category', 'Customer Names', 'Status'];


useEffect(() => {
  fetchDeliveryChallans();
}, []);

useEffect(() => {
  handleSearch();
}, [deliveryChallans]);

const handleSearch = (query, type) => {
  if (!query || !type) {
    setFilteredDC(deliveryChallans);
    return;
  }

  const filtered = deliveryChallans.filter((challan) => {
    const lowercaseQuery = query.toLowerCase();
    switch (type) {
      case 'Challen Number':
        return challan?.challan_number?.toLowerCase().includes(lowercaseQuery);
      case 'SO Number':
        return challan.sales_order_id?.salesorder_number?.toLowerCase().includes(lowercaseQuery);
      case 'Item Category':
        return challan.product_category_id?.product_category_name?.toLowerCase().includes(lowercaseQuery);
      case 'Customer Names':
        return challan.customer_id?.customer_name?.toLowerCase().includes(lowercaseQuery);
        case 'Status':
          return challan.status?.toLowerCase().includes(lowercaseQuery);
      default:
        return false;
    }
  });

  setFilteredDC(filtered);
};

    
      const fetchDeliveryChallans = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/delivery-challan/get-all-delivery-challans?organizationId=${sessionStorage.getItem('organizationId')}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
      
          const data = await response.json();
          console.log("deliveryChallans",data)
          if (data.success) {
            
            setDeliveryChallans(data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setPopupMessage(data.message);
            setPopupType(`${data.status}`);
            setShowPopup(true);
          }
        } catch (error) {
          console.error('Failed to fetch delivery challans:', error);
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => fetchDeliveryChallans);
        }
      };
      
    
      const sortArray = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
    
        const sortedArray = [...deliveryChallans].sort((a, b) => {
          const aValue = key.split('.').reduce((obj, k) => obj && obj[k], a) || '';
          const bValue = key.split('.').reduce((obj, k) => obj && obj[k], b) || '';
          
          const aString = aValue.toString().toLowerCase();
          const bString = bValue.toString().toLowerCase();
    
          if (aString < bString) return direction === 'ascending' ? -1 : 1;
          if (aString > bString) return direction === 'ascending' ? 1 : -1;
          return 0;
        });
    
        setDeliveryChallans(sortedArray);
      };
    
      const getSortIcon = (key) => {
        if (sortConfig.key === key) {
          return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
        }
        return null;
      };
    
      const handleCreate = () => {
        navigate("/bb/app/deliverychallans/newdeliverychallans");
      };
    
      const handlenavigateDeliveryChallanDetails = (challan) => {
        navigate("/bb/app/deliverychallans/deliverychallandetails", {state:{DeliveryChallanData:challan}});
      };
      // const handlenavigateToSalesOrderDetails = (salesOrderId) => {
      //   navigate("/bb/app/salesorders/salesorderdetails", {state:{SalesOrderData:salesOrderId}});
      // };
    
      const formatSalesOrderDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
      };
    
      return (
        <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
          <div className="flex flex-row w-full">
            <div className="w-full flex-1">
              <div className="flex flex-col w-full justify-center items-center">
                <div className="w-full h-full flex flex-col justify-between">
                  <div className="w-full h-16 border-b flex items-center">
                    <div className="flex w-full justify-between px-8 items-center">
                    <div className='space-x-10 flex flex-row'>
                          <h1 className="text-lg font-bold">Challan List</h1>
                          <Search onSearch={handleSearch} searchTypes={searchTypes} />
                        </div>
                        <button onClick={handleCreate}
        className="bg-[#E54643] text-white z-20 px-2 py-1 rounded"
        title="" data-tooltip="Create Delivery Challan"  style={{ '--tooltip-bg': 'black' }}>+ New</button>    
                       </div>
                    
                  </div>
                  <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                    <div className="overflow-y-auto h-[80vh]">
                      <table className="w-full mb-5">
                        <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
                          <tr className='border-b'>
                            <th className="text-xs py-3 text-center">
                              <input type="checkbox" className="mr-2" />
                            </th>
                            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('challan_number')}>
                              Challan Number {getSortIcon('challan_number') && <FontAwesomeIcon icon={getSortIcon('challan_number')} />}
                            </th>
                            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('challan_date')}>
                              Challan Date {getSortIcon('challan_date') && <FontAwesomeIcon icon={getSortIcon('challan_date')} />}
                            </th>
                            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_category_id.product_category_name')}>
                              Item Category {getSortIcon('product_category_id.product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_id.product_category_name')} />}
                            </th>
                            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('sales_order_id.salesorder_number')}>
                              Sales Order NO {getSortIcon('sales_order_id.salesorder_number') && <FontAwesomeIcon icon={getSortIcon('sales_order_id.salesorder_number')} />}
                            </th>
                            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('customer_id.customer_name')}>
                              Customer Name {getSortIcon('customer_id.customer_name') && <FontAwesomeIcon icon={getSortIcon('customer_id.customer_name')} />}
                            </th>
                            {/* <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('total_quantity')}>
                              Total Quantity {getSortIcon('total_quantity') && <FontAwesomeIcon icon={getSortIcon('total_quantity')} />}
                            </th> */}
                            {/* <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('vehicle_number')}>
                              Vehicle NO {getSortIcon('vehicle_number') && <FontAwesomeIcon icon={getSortIcon('vehicle_number')} />}
                            </th>
                            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('driver_name')}>
                              Driver Name {getSortIcon('driver_name') && <FontAwesomeIcon icon={getSortIcon('driver_name')} />}
                            </th>
                            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('driver_number')}>
                              Driver Number {getSortIcon('driver_number') && <FontAwesomeIcon icon={getSortIcon('driver_number')} />}
                            </th> */}
                            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('status')}>
                              Status {getSortIcon('status') && <FontAwesomeIcon icon={getSortIcon('status')} />}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="border-b border-t">
                        {filteredDC.length > 0 ? (
    filteredDC.map((challan) => (
    <tr key={challan._id} className="text-sm border-b">
      <td className="py-2 text-center">
        <input
          type="checkbox"
          className="mr-2"
          checked={selectedCategories.some(
            (selected) =>
              selected.salesOrderId === challan.sales_order_id &&
              selected.productCategoryId === challan.product_category_id
          )}
          onChange={() => {/* Add your checkbox change handler here */}}
        />
      </td>
      <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" onClick={() => handlenavigateDeliveryChallanDetails(challan)}>{challan.challan_number}</td>
      <td className="py-2 text-center">{formatSalesOrderDate(challan.challan_date)}</td>
      <td className="py-2 text-center">{challan.product_category_id?.product_category_name}</td>
      <td className="py-2 text-center ">{challan.sales_order_id?.salesorder_number}</td>
      <td className="py-2 text-center">{challan.customer_id?.customer_name}</td>
      {/* <td className="py-2 text-center">{challan.vehicle_number}</td>
      <td className="py-2 text-center">{challan.driver_name}</td>
      <td className="py-2 text-center">{challan.driver_number}</td> */}
           <td className="py-2 text-center">{challan.status}</td>
      {/* <td className="py-2 text-center">{challan.items.length} items</td> */}
    </tr>
  ))
) : (
  <tr>
  <td colSpan="10" className="text-center text-[#6c7184] py-4">
    Not found
  </td>
</tr>
)}
</tbody>


                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
    
    export default DeliveryChallanList;
    
    