import * as XLSX from 'xlsx';

const exportToExcel = (pricelistProductRatesData, pricingSchema) => {
  const worksheetData = pricelistProductRatesData.flatMap((item) => {
    const commonData = {
      'Price List Item ID': item?.price_list_item_id || '',
      'Item Name': item?.product_name || '',
      'Item Price': item?.selling_price|| '',
      'SKU': item?.SKU,
      'GST Included': item?.gst_included || '',
      'HSN Code': item?.HSN_Code || '',
      'Type' : pricingSchema || '',
    };

    if (pricingSchema === 'Unit') {
      const unitData = {
        ...commonData,
        'Custom Rate': item?.pricing.custom_rate || '',
        'Discount': item?.pricing.discount || '',

      };
      return [unitData];
    } else if (pricingSchema === 'Volume') {
      return item.pricing.map(volumeItem => ({
        ...commonData,
        'Start Quantity': volumeItem?.start_quantity || '',
        'End Quantity': volumeItem?.end_quantity || '',
        'Custom Rate': volumeItem?.custom_rate || '',
        'Discount': volumeItem?.discount || '',
      }));
    }

    return [commonData];
  });

  const worksheet = XLSX.utils.json_to_sheet(worksheetData);

  // Set column widths
  const columnWidths = [
    { wch: 22 }, // Price List Item ID
    { wch: 30 }, // Item Name
    { wch: 10 }, // Item Price
    { wch: 10 }, // SKU
    { wch: 10 }, // GST Included
    { wch: 10 }, // HSN Code
    { wch: 10 }, // Custom Rate or Start Quantity
    { wch: 10 }, // Discount or End Quantity
    { wch: 10 }  // Unit (for Unit pricing)
  ];

  worksheet['!cols'] = columnWidths;

  // Create workbook and append worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Pricelist Product Rates');

  // Apply font color to 'Item Name' column
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  for (let R = range.s.r + 1; R <= range.e.r; ++R) {
    const address = XLSX.utils.encode_cell({ c: 1, r: R });
    if (!worksheet[address]) continue;
    if (!worksheet[address].s) worksheet[address].s = {};
    worksheet[address].s.font = { color: { rgb: '0000FF' } }; // Setting font color to blue
  }

  // Create Excel file and trigger download
  XLSX.writeFile(workbook, 'Pricelist_Product_Rates.xlsx');
};

export { exportToExcel };
