import React, { useEffect, useState, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {  faChevronDown, faPaperclip, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import {Loader} from '../Loader/Loader';
import { FaRegTrashAlt } from 'react-icons/fa';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { useLocation, useNavigate } from 'react-router-dom';
import  '../Css/Tooltip.css';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};


const CustomDropdown = ({ options, selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const handleOptionClick = (value) => {
    onSelect(value);
    setIsOpen(false);
  };


  return (
    <div className="relative w-1/6" ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Salu'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {isOpen && (
      <div className="absolute mt-1 w-full h-40 bg-white border border-gray-300 rounded-md shadow-lg z-10 flex flex-col">
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {options.map((option) => (
            option.value !== 'add-new' && (
              <div className='p-1'>

              <div

                key={option.value}

                className="py-2 pl-1 cursor-pointer text-sm hover:bg-blue-500 hover:rounded-md  hover:text-white"

                onClick={() => handleOptionClick(option.value)}

              >

                {option.icon && <FontAwesomeIcon icon={option.icon} className="" />}

                {option.label}

              </div>

              </div>

            )

          ))}

        </div>

      </div>

    )}

  </div>

  

  );

};

const ViewProductDetails = () => {
    const navigate= useNavigate();
    const [productCategories, setProductCategories] = useState([
      ]);
      const [products, setProducts] = useState([
      ]);
      const [productAttributes, setProductAttributes] = useState([
      ]);
      
      const [productSubcategories, setProductSubcategories] = useState([
      ]);
   const location= useLocation();
   const { ItemData } = location.state || {};
  const [selectedProductCategory, setSelectedProductcategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  //console.log(selectedProduct);
  const [productGroupid , setproductid] = useState(null);
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const url = process.env.REACT_APP_FORE_BRICKBUCKET
  const [isLoading, setIsLoading] = useState(true);
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [vendor , setvendor] = useState()
  const [productCategory , setProductCategory] = useState()
  const [productGroup , setProductgroup] = useState()
   const [specifications, setSpecifications] = useState([]);
   //console.log(specifications)
   const [productData, setproductData] = useState();
   //console.log(specifications);
  const [showRequiredPopup, setShowRequiredPopup] = useState(false)
 // const [attributeLoading , setProductgrouploading]= useState(true)
  const [isSaving, setIsSaving] = useState(false);
  const [ProductLoader, setProductLoader] = useState(true)
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [retryFunction, setRetryFunction] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
const [isUploading, setIsUploading] = useState(false);
const [roleId, setRoleId] = useState(null);

/* useEffect(() => {
  if (selectedProduct && selectedProduct.product_image) {
    setImageSrc(imageUrl + selectedProduct.product_image);
  }
}, [selectedProduct, imageUrl]); */


const handleImageChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const formData = new FormData();
    formData.append('image', file); // This should match the field name in Multer's configuration
    formData.append('_id', selectedProduct._id);

    setIsUploading(true);

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}product/change-product-image`, {
        method: 'PUT',
        headers: {
          'x-token': token,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === 200) {


        console.log(imageUrl + data.data.product_image);
        setImageSrc(imageUrl + data.data.product_image); // Update the image URL with the new uploaded image URL
        fetchItems(data.data)
      } else {
        console.error('Failed to upload image:', data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false);
    }
  }
};


const handleFileInputClick = () => {
  document.getElementById('imageInput').click();
};

/*   useEffect(() => {
    const fetchProductCategories = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(url + "product-category/get-all-productCategories", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                }
            });
            const data = await response.json();
            if (response.ok) {
                setProductCategories(data); // Update customers state
                console.log(data)
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }
    fetchProductCategories();
}, []); */

/* useEffect(() => {
    const fetchItems = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "product-groups/get-all-products", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                }
            });
            const data = await response.json();
            if (response.ok) {
                setProducts(data); // Update ItemGroups state
                console.log(data)
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error(error);
        }finally {
          setIsLoading(false);
        }
    }
    fetchItems();
}, []); */

useEffect(() => {
  fetchRoleId();
  fetchItems(ItemData);
  //setImageSrc(imageUrl + ItemData.product_image)
}, [ItemData]);

const fetchRoleId = () => {
  const roleId = JSON.parse(sessionStorage.getItem('roleId'));
  setRoleId(roleId);
};

const fetchItems = async (selectedItem,attempt = 1) => {
  //console.log(selectedItem)
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `product/get-all-products?organizationId=${sessionStorage.getItem('organizationId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      setProducts(data.data);
     // console.log(data.data);
      setSelectedProduct(selectedItem)
      handleProductClick(selectedItem)
    } else if (data.status === 400) {
      setShowPopup(true);
      setPopupType('400');
      setPopupMessage('Bad Request!');
    } else if (data.status === 401) {
      setShowPopup(true);
      setPopupType('401');
      setPopupMessage('Unauthorized!');
    } else if (data.status === 403) {
      setShowPopup(true);
      setPopupType('403');
      setPopupMessage('Forbidden!');
    } else if (data.status === 404) {
      setShowPopup(true);
      setPopupType('404');
      setPopupMessage('Not Found!');
    } else if (data.status === 500) {
      setShowPopup(true);
      setPopupType('500');
      setPopupMessage('Internal Server Error!');
    } else {
      setShowPopup(true);
      setPopupType('unknown');
      setPopupMessage('Unknown error!');
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => fetchItems(selectedItem,attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchItems(selectedItem,attempt)); // Set retry function
    }
  } finally {
    setIsLoading(false);
  }
};

  const handleProductClick = (product) => {
//console.log(product)
    setproductid(product._id);
//console.log(product._id)
    setSelectedProduct(product);
    getProductDetails(product._id)
    setProductLoader(true)
    setImageSrc(imageUrl + product?.product_image)
  };

  const getProductDetails = async (id,attempt = 1) => {
    try {
       const token = sessionStorage.getItem('token')
      const apiEndpoint = backendApiUrl + `product/get-product?id=${id}`; // Replace with your actual API endpoint
  
      // Make a GET request to the API
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
           'x-token' : token
        },
      });

      const data = await response.json();
      //console.log(data)
    const output =  await getResponseHandling(response , data)
    if(output){
    setProductgroup(output?.productGroup);
   // setAttributes(output?.groupAttributes||[]);
   setproductData(output?.productData || []);
   const specifications = output?.productData?.specifications;

   let parsedSpecifications;
   if (specifications && specifications.trim() !== "") {
       parsedSpecifications = JSON.parse(specifications);
   } else {
       parsedSpecifications = {}; // or [] depending on your requirements
   }
   
   setSpecifications(parsedSpecifications);
   //console.log(output?.productData)
    //console.log(output?.productData);
    setvendor(output?.vendor);
    //console.log(output?.vendor)
    setProductCategory(output?.productCategory);
    }else{
      setProductgroup()
      setvendor()
      setProductCategory()
      //setAttributes([])
      setproductData([])
    }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchItems(id,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchItems(id,attempt)); // Set retry function
      }
    } finally{
    setProductLoader(false)
    }
  };

  const getResponseHandling = async (response, data)=>{
    if (response.ok) {
      if (response.status === 200 || response.status === 201) {
        setPopupType('200or201');
          return (data.data)
      } else if (response.status === 204) {
        setPopupType('204');
        return (data.data)
      } 
    } else {
      if (response.status === 400) {
        setPopupType('400');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 401) {
        setPopupType('401');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 403) {
       
        setPopupType('403');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 500) {
       
        setPopupType('500');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else {
        setErrorMessages([...errorMessages, data.message]);
      }
    }
  }
  const handleClose = () => {
    navigate(-1);
  }
   const handleClosePopup = ()=>{
    setShowRequiredPopup(false);
   }

  if (isLoading) {
    return <Loader />;
  }
  
  const Retry = async() => {
    //console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };

  const BackToLoginPage = () => {
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
    };


    const closePopup = () => {
     // console.log("closePopup called");
      setShowPopup(false);
    };

    const handleCreateItem = () => {
      navigate('/bb/app/items/newitem');
    }

    const handleEditItem = () => {
      navigate('/bb/app/items/edititems', { state: { ItemData: selectedProduct } });
      console.log(selectedProduct);
    }


    const itemsToDisplay = roleId === 6 
    ? products.filter(item => (item.product_category_id?.product_category_name.toLowerCase()) === 'tiles')
    : products;
    console.log(itemsToDisplay);

  return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
      {/* List of Customers Div start */}
      <div className=" flex flex-col  w-[32%]  ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Items
     <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />
    </h5>
    </div>
    <div className=''>
    <button onClick={handleCreateItem}
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md" 
   title="" data-tooltip="Create Item"  style={{ '--tooltip-bg': 'black' }}>
  <FontAwesomeIcon icon={faPlus} className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>

  <div className=" flex justify-center  overflow-y-hidden  flex-col ">
   <div className='flex  flex-col h-[80vh]  overflow-y-auto '>
   {itemsToDisplay.map((product) => (
        <div
          key={product._id}
          onClick={() => handleProductClick(product)}
          className={` border-b text-sm flex items-center px-4 py-2 ${selectedProduct?._id === product._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
        >
          <div className='w-full flex flex-row justify-between'>
            <div className='w-[10%]'>
              <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
            </div>
            <div className='w-[80%] flex flex-col'>
              <div>
              <p className='text-xs font-semibold pr-4 ' >{product.product_name}</p>
              </div>
              {product?.UOM_id && (
  <div className='text-gray-800 mt-2 flex flex-row'>
    <p className='text-xs mr-1'>Units:</p>
    <p className='text-xs'>{product.UOM_id.UOM}</p>
  </div>
)}
            </div>
            {product?.selling_price && (
            <div className='w-[10%] flex justify-end'> 
                <p className='text-md font-semibold' >₹{product.selling_price.toLocaleString()}</p>
              </div>
            )}
          </div>
        </div>
      ))}
  </div>
</div>
</div>
{/* List of Customers Div end */}

<div className="border-l w-full flex flex-col">
      {selectedProduct ? (
        <>
          <div className="border-b h-24 overflow-hidden"> {/* or h-12, depending on your desired height */}
            <div className='p-4 flex justify-between items-center relative'>
              <span className="text-md font-semibold">{selectedProduct?.product_name}</span>
              <div className="flex items-center px-2">
                <button className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md mr-2" 
                onClick={handleEditItem}
                >
                  Edit
                </button>
               {/*  <button className="bg-gray-100 px-1 py-1 shadow-sm text-sm border rounded-md mr-2">
                  <FontAwesomeIcon icon={faPaperclip} className="text-md text-black mx-2 cursor-pointer" />
                </button> */}
               {/*  <button className="bg-[#f7525a] text-sm text-white px-2 py-1 shadow-sm border rounded-md mr-2">
                  New Transaction
                </button> */}
               {/*  <button
                  className="bg-gray-100 text-sm px-2 py-1 shadow-sm border rounded-md mr-2"
                  onClick={() => setShowRequiredPopup(!showRequiredPopup)}
                >
                  Assign
                </button> */}
                <button className="text-white px-1 py-1 rounded-md"
                  title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}>
                  <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-md text-[#f7525a] mx-2 cursor-pointer" />
                </button>
              </div>
            </div>
          </div>
          {ProductLoader ? (
    <Loader />
  ) : (
          <div className="h-5/6 w-full flex justify-center">
            <div className="flex flex-row h-full w-full">
              <div className="flex flex-1 h-full flex-col">
                <div className="flex flex-col h-[30rem] overflow-y-auto">
                  <div className="flex flex-col h-60">
                    <div className="flex justify-start px-2">
      <span className="text-md text-[1rem] font-semibold">Item Specifications</span>
      </div>
      {specifications && specifications.length > 0 ? (
      <div className="flex flex-col p-2 w-full items-start mt-2">
        <table className="table-auto w-3/4 text-[0.89rem]">
          <tbody>
            {specifications.map((spec, index) => (
              Object.entries(spec).map(([key, value]) => (
                <tr className="flex justify-between mt-2" key={`${index}-${key}`}>
                  <th className="text-left text-[#777] font-normal w-1/2">{key}</th>
                  <td className="text-left w-1/2">{value}</td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="flex justify-start p-2">
        <span className="text-[0.8rem] text-[#777] font-normal">
          No specifications currently assigned to this item. Please assign specifications if needed.
        </span>
      </div>
    )}
    </div>

                  <div className="flex flex-col h-60 mt-2 px-2">
                    <div className="flex justify-start">
                      <span className="text-md text-[1rem] font-semibold">Item Category Details</span>
                    </div>
                    {productCategory ? (
                      <div className="flex flex-col p-2 w-full items-start">
                        <table className="table-auto w-3/4 text-[0.89rem]">
                          <tbody>
                            <tr className="flex justify-between mt-2">
                              <th className="text-left text-[#777] font-normal w-1/2">Item Category Name</th>
                              <td className="text-left w-1/2">{productCategory.category_name}</td>
                            </tr>
                            <tr className="flex justify-between mt-2">
                              <th className="text-left text-[#777] font-normal w-1/2">Description</th>
                              <td className="text-left w-1/2">{productCategory?.category_description}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="flex justify-center p-4">
                        <span className="text-[0.8rem] text-[#777] font-normal">There are no Item Category currently assigned to this item. Please assign Item Category if needed.</span>
                      </div>
                    )}
                  </div>
                  {selectedProduct?.cost_price && (
                  <div className="flex flex-col h-60 mt-2 px-2">
                    <div className="flex justify-start">
                      <span className="text-md text-[1rem] font-semibold">Purchase Information</span>
                    </div>
                  <div className="flex flex-col p-2 w-full items-start">
                        <table className="table-auto w-3/4 text-[0.89rem]">
                          <tbody>
                            <tr className="flex justify-between mt-2">
                              <th className="text-left text-[#777] font-normal w-1/2">Cost Price</th>
                              <td className="text-left w-1/2">₹{selectedProduct.cost_price.toLocaleString()}</td>
                            </tr>                           
                          </tbody>
                        </table>
                      </div>
                      </div>
                  )}
                   {selectedProduct?.selling_price && (
                      <div className="flex flex-col h-60 mt-2 px-2">
                    <div className="flex justify-start">
                      <span className="text-md text-[1rem] font-semibold">Sales Information</span>
                    </div>
                  <div className="flex flex-col p-2 w-full items-start">
                        <table className="table-auto w-3/4 text-[0.89rem]">
                          <tbody>
                            <tr className="flex justify-between mt-2">
                              <th className="text-left text-[#777] font-normal w-1/2">Selling Price</th>
                              <td className="text-left w-1/2">₹{selectedProduct.selling_price.toLocaleString()}</td>
                            </tr>
                           
                          </tbody>
                        </table>
                      </div>
                      </div>
                   )}
                  <div className="flex flex-col h-60 mt-2 px-2">
                    <div className="flex justify-start w-full mb-2">
                      <span className="text-[1rem] font-semibold">Vendor Details</span>
                    </div>
                    {vendor ? (
                      <div className="flex flex-col p-2 w-full items-start">
                        <table className="table-auto w-3/4 text-[0.89rem]">
                          <tbody>
                            <tr className="flex justify-between mt-2">
                              <th className="text-left text-[#777] font-normal w-1/2">Vendor Name</th>
                              <td className="text-left w-1/2">{vendor?.vendor_name}</td>
                            </tr>
                            <tr className="flex justify-between mt-2">
                              <th className="text-left text-[#777] font-normal w-1/2">Vendor Company</th>
                              <td className="text-left w-1/2">{vendor?.vendor_company}</td>
                            </tr>
                            <tr className="flex justify-between mt-2">
                              <th className="text-left font-normal text-[#777] w-1/2">Vendor Address</th>
                              <td className="text-left w-1/2">{vendor?.vendor_address}</td>
                            </tr>
                            <tr className="flex justify-between mt-2">
                              <th className="text-left font-normal text-[#777] w-1/2">Vendor Pincode</th>
                              <td className="text-left w-1/2">{vendor?.vendor_pincode}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="flex justify-center p-4">
                        <span className="text-[0.8rem]">There are no vendors currently assigned to this item. Please assign a vendor if needed.</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex w-60 h-full p-2 justify-center">
    <div className="flex flex-col h-48 justify-center p-2 w-40 border">
      <div className="flex justify-center">
        <img
          src={imageSrc}
          alt={selectedProduct.product_image}
          className="flex w-28 h-28 object-cover"
        />
      </div>
      <div className="mt-6 flex justify-between">
        <span
          className="text-[0.89rem] text-blue-500 cursor-pointer"
          onClick={handleFileInputClick}
        >
          Change Image
        </span>
        <FaRegTrashAlt className="text-xs text-gray-600 mx-1 cursor-pointer" />
      </div>
      <input
        type="file"
        id="imageInput"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageChange}
      />
    </div>
    {isUploading && <p>Uploading...</p>}
  </div>
            </div>
          </div>     
  )}
        </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <p className="text-gray-500">Select an Item to view details</p>
        </div>
      )}
  
</div>


{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}

{/* {showRequiredPopup && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col ">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md"> Attributes</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      {attributeLoading ?(
          <>
          <Loader />
          </>
      ):(

     <>
      <div className="w-full flex flex-row items-center justify-center mb-4 space-x-2 mt-5 px-6">
         <label htmlFor="uom" className="block text-sm font-regular text-[#e54643] w-1/6">
          Attributes<span className="text-red-500">*</span>
        </label> 
        
      </div>

      <div className="w-full flex flex-row justify-center items-center mb-4 space-x-2 px-6">
      <div className="flex justify-center flex-col items-center">
        
      </div>
      </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
            onClick={handleAddattributesToGroup} >
            Save {isSaving && (
          <Spinner/>)}
            </button>
          <button onClick={handleClosePopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
      </div>
      </>
 )}
    </div>
  </div>
)} */}
                   


    </div>

  );

};



export default ViewProductDetails;