import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const HtmlApp = () => {
   const [htmlContent, setHtmlContent] = useState(''); 
 const navigate=useNavigate()
 const location = useLocation();

  useEffect(() => {
    // This will run every time the location changes
    if (location.pathname === '/home.html') {
      window.location.reload();
    }
  }, [location]);

  useEffect(() => {
    /*  fetch('/home.html')
      .then(response => response.text())
      .then(data => setHtmlContent(data))
      .catch(error => console.error('Error Fetching HTML:', error));  */
     navigate('home.html')
  }, []);

   return (
    <>
   {/*  <a href="home.html"
                            
                            ></a> */}
    {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
    </>
  ); 
};

export default HtmlApp;
