import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX, faPen, faPaperPlane, faEnvelope , faSms } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Css/Tooltip.css';
import { Msg200or201, Msg204, Msg400, Msg401, Msg403, Msg500 } from '../Messages/Messages';
import {Loader, Spinner } from '../Loader/Loader';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import DeliveryChallanPdfViewer from './DeliveryChallanPdfViewer';
// SalesOrderPdfViewer from '../SalesOrder/SalesOrderPdfViewer';

const DeliveryChallanDetails = () => {
  const navigate = useNavigate();
  // or get this from props or API
const [dropdownOpen, setDropdownOpen] = useState(false);
const [DeliveryChallanDataArray, setDeliveryChallanDataArray] = useState([]);
const [deliveryChallans,  setDeliveryChallans] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const [showSendOptions, setShowSendOptions] = useState(false);
const [selectedChallan, setSelectedChallan] = useState(null);
console.log(selectedChallan);
const [challanId, setChallanId] = useState(null);
  const location = useLocation();
  const { DeliveryChallanData } = location.state || {};
  const [status, setStatus] = useState('draft');
  const { 
    showPopup, popupMessage, popupType, retryFunction,
    isSaving ,setPopupMessage,setPopupType,handleGetResponse, handleSaveResponse, setShowPopup,
    setRetryFunction, handleDeleteResponse, setIsSaving  } = useCommonResponseHandler();
    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [showConvertPopup, setShowConvertPopup] = useState(false);

  useEffect(() => {
    fetchDeliveryChallans(DeliveryChallanData);
  }, [DeliveryChallanData]);
  

  const fetchDeliveryChallans = async (DeliveryChallanData ,attempt = 0 ) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/delivery-challan/get-all-delivery-challans?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
  
      const data = await response.json();
      console.log("deliveryChallans",data)
      if (data.success) {
        setSelectedChallan(DeliveryChallanData);
        handleChallanClick(DeliveryChallanData);
        setDeliveryChallanDataArray(data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch delivery challans:', error);
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => fetchDeliveryChallans);
    }
  };


  const handleClose = () => {
    navigate('/bb/app/deliverychallans/deliverychallanslist');
  };

  // const handleEdit = () => {
  //   navigate('/bb/app/deliverychallans/editdeliverychallan', { state: { challanId: DeliveryChallanData?._id } });
  // };

  

  const closePopup = () => {
    setShowPopup(false);
  };

  const BackToLoginPage = () => {
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
    navigate('/bb');
  };
  

  const handleStatusChange = async (newStatus) => {
    setStatus(newStatus);
    setDropdownOpen(false);
     
    const challanId = selectedChallan?._id || DeliveryChallanData?._id;
  
    if (!challanId) {
      setPopupMessage('No challan selected. Please select a challan first.');
      setPopupType('400');
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/delivery-challan/update-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify({
          challanId: challanId,
          newStatus: newStatus
        })
      });
  
      const data = await response.json();
      if (data.success) {
        setPopupMessage('DC Status updated successfully');
        setPopupType('200or201');
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
        if (selectedChallan) {
          setSelectedChallan({...selectedChallan, status: newStatus});
        }
  
        // Update the status in the DeliveryChallanDataArray
        setDeliveryChallanDataArray(prevArray => 
          prevArray.map(challan => 
            challan._id === challanId ? {...challan, status: newStatus} : challan
          )
        );
  
        // Fetch the updated challan data
        fetchDeliveryChallanbyId(challanId);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Failed to update status:', error);
      setPopupMessage('Failed to update status. Please try again.');
      setPopupType('500');
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    }
  };
   

  const fetchDeliveryChallanbyId = async (id ,attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/delivery-challan/get-delivery-challan?challan_id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });

      const data = await response.json();
      console.log(data);
      const output =  await handleGetResponse(response , data)
      if(output){
        console.log(output)
        setSelectedChallan(output);     
      }else{
      
      }
      }  catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchDeliveryChallanbyId(id,attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchDeliveryChallanbyId(id,attempt)); // Set retry function
        }
      }finally{
        //setProductGroupLoader(false)
      }
    };
  
   const handleChallanClick = (challan) => {
  const challanId = challan?._id;
  if (challanId) {
    setChallanId(challanId);
    setSelectedChallan(challan);
    setStatus(challan?.status);
   // fetchDeliveryChallanbyId(challanId);
  } else {
    console.error('Challan or challan ID is undefined');
  }
}; 
const handleSendClick = () => {
  setShowSendOptions(!showSendOptions);
};

const sendDeliveryChallanToUser = async () => {
  setIsSaving(true);
  setShowSendOptions(false);
  try {
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/delivery-challan/send-delivery-challan-to-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': sessionStorage.getItem('token'),
      },
      body: JSON.stringify({
        customerId: selectedChallan.customer_id._id,
        documentData: {
          documentType: 'Delivery Challan',
          documentNumber: selectedChallan.challan_number,
          date: selectedChallan.challan_date,
          items: selectedChallan.items.map(item => ({
            item_name: item.item_name,
            quantity: item.quantity,
            UOM: item.UOM
          }))
        }
      }),
    });


    const data = await response.json();
    if (data.success) {
      setPopupMessage('Delivery Challan sent successfully');
      setShowPopup(true);
      setPopupType('200or201');
      setTimeout(() => {
        setShowPopup(false);
        setPopupMessage('');
        setPopupType('');
      }, 2000);
    } else {
      //throw new Error(data.message);
      if (response.status === 400) {
        setPopupType('400');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 401) {
        setPopupType('401');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 403) {
        setPopupType('403');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      }else if (response.status === 404) {
        setPopupType('404');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } 
    }
  } catch (error) {
    console.error('Failed to send Delivery Challan:', error);
    setPopupMessage('Failed to send Delivery Challan. Please try again.');
    setPopupType('500');
    setShowPopup(true);
  }finally {
    setIsSaving(false);
  }
};




  return (
    <div className="bg-white flex flex-row w-full justify-center overflow-y-hidden">
      <div className="flex flex-col w-[32%]">
        <div className="p-4 border-b flex flex-row justify-between">
          <div>
            <h5 className="text-md font-semibold cursor-pointer">
              All Delivery Challans
              <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer" />
            </h5>
          </div>
        </div>
  
        <div className="flex justify-center overflow-y-hidden flex-col">
  <div className="flex flex-col h-[80vh] overflow-y-auto">
    {DeliveryChallanDataArray && DeliveryChallanDataArray.length > 0 && (
      <div>
        {DeliveryChallanDataArray.map((challan, index) => (
          <div
            key={index}
            className={`border-b text-sm flex items-center px-4 py-2 ${
              (selectedChallan?._id === challan._id || (index === 0 && !selectedChallan)) ? 'bg-gray-100' : ''
            } hover:bg-gray-100 cursor-pointer`}
            onClick={() => handleChallanClick(challan)}
          >
            <div className="flex flex-col">
              <p className="text-md font-semibold">{challan.challan_number}</p>
              <p className="text-gray-600 text-sm flex items-center">
                {challan.status}
                <span className="mx-1 text-gray-600">&middot;</span>
                {new Date(challan.challan_date).toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
</div>
</div>
      <div className="border-l w-full flex flex-col">
        <div className="border-b h-30">
          <div className="p-4 flex justify-between items-center">
            <span className="text-md font-semibold">{selectedChallan?.challan_number ||DeliveryChallanData?.challan_number}</span>
            <button className="text-white px-1 py-1 rounded-md"
             title="" data-tooltip="Close" style={{ '--tooltip-bg': 'black' }}>
              <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-sm text-[#f7525a] mx-2 cursor-pointer" />
            </button>
          </div>
          <div className="flex items-center px-2 bg-[#F9F9FB] border-t">
            <button className="border-r px-4 py-2 text-sm mr-2">
              <FontAwesomeIcon icon={faPen} className="mr-2 w-3 h-3" />
              Edit
            </button>
            <div className="relative">
            <button 
  onClick={handleSendClick} 
  className={`px-4 py-2 border-r text-sm mr-2 ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
  disabled={isSaving}
>
  <div className="flex items-center justify-center space-x-2">
    <FontAwesomeIcon icon={faPaperPlane} className="mr-1 w-3 h-3" />
    <span>Send</span> {isSaving && <Spinner />}
  </div>
</button>
{showSendOptions && (
    <div className="p-0.5 absolute left-0 top-full mt-1 bg-white border rounded shadow-lg z-10">
      <button onClick={sendDeliveryChallanToUser} className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        Email
      </button>
      <button className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
        <FontAwesomeIcon icon={faSms} className="mr-2" />
        SMS
      </button>
    </div>
  )}
  </div>
          </div>
        </div>
        <div className="w-full h-full bg-white rounded-xs overflow-hidden">
          <div className="flex flex-col h-[80vh] overflow-hidden">
            <div className="overflow-y-auto flex-1 p-6">
              {DeliveryChallanData && (
                <>
                  <div className="mt-4 mx-10 px-2 border p-2 flex items-center border-l-4">
                    <div className="flex-1">
                      <p className="text-sm font-semibold">What's Next?</p>
                      <p className="mt-1 mb-2 text-sm text-gray-700">
                        Change to In Transit or Delivered
      {/* {status === 'draft' && "Change to In Transit or Delivered"}
      {status === 'in_transit' && "Change to Delivered"}
      {status === 'delivered' && "Already Delivered"} */}
    </p>         
    
               </div>
               {status !== 'Delivered' && (
  <div className="flex-shrink-0 flex space-x-2">
    <div className="relative">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="px-2 py-1 text-xs bg-[#f7525a] border-[#f7525a] text-white rounded-md"
      >
        Change To
      </button>
      {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
          {status !== 'In Transit' && (
            <button
              onClick={() => handleStatusChange('In Transit')}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              In Transit
            </button>
          )}
          <button
            onClick={() => handleStatusChange('Delivered')}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Delivered
          </button>
        </div>
      )}
    </div>
  </div>
)}
{status === 'Delivered' && (
  <div className="text-sm text-green-600 font-semibold">
    Already Delivered
  </div>
)}


</div>


                  {/* <h1 className="text-lg mt-4 px-4 font-semibold mb-0">{DeliveryChallanData?.status}</h1> */}
                  <div className="mt-4">
  <DeliveryChallanPdfViewer challan={selectedChallan || DeliveryChallanData} currentStatus={selectedChallan?.status || status
} />
</div>

                  {/* <div className="mt-4">
                    <SalesOrderPdfViewer salesOrder={DeliveryChallanData} />
                    </div> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div>
          {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
          {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={closePopup} cancelPopup={closePopup} />}
        </div>
      )}
    </div>
  );
};

export default DeliveryChallanDetails;
