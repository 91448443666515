import React from 'react';


const DeliveryChallanHtmlDocument = ({ challan }) => (
  <div className="p-8 flex flex-col" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
    <div className="flex justify-between mb-4 text-xs">
      <div className="flex-1">
        <h1 className="font-bold text-md">Brickbucket</h1>
        <p>Andhra Pradesh</p>
        <p>India</p>
        <p>GSTIN 37AAKCK7567P1ZG</p>
        <p>info@brickbucket.com</p>
      

      <div className="mt-10">
          <h1 className=''>Deliver To</h1>
        </div>
        <p className="font-semibold"> {challan?.customer_id?.company_name}</p> 
        <p className=""> {challan?.customer_id?.customer_name}</p> 
        <p className=""> {challan?.customer_id?.address}</p>
          <p className=""> {challan?.customer_id?.city}</p>
        <p className=""> {challan?.customer_id?.state}</p> 
        <div className="mt-1 flex flex-row">
          <h1 className=''>GST: </h1>
          <p className=""> {challan?.customer_id?.gstin}</p>
        </div>
        <p className=""> {challan?.customer_id?.email}</p> 
        <p className=""> {challan?.customer_id?.mobile_number}</p> 
      </div>


      <div className="flex-1 mt-2 text-right">
        <h1 className="text-2xl font-semibold mb-0">Delivery Challan</h1>
        <h1 className="text-md mt-1 font-semibold mb-0">{challan?.challan_number}</h1>
        <h1 className="text-sm mt-5 font-semibold">
          {new Date(challan?.challan_date).toLocaleDateString()}
        </h1>
        <h1 className="text-md mt-1 font-semibold mb-0">{challan?.status}</h1>
      </div>
    </div>

    <div className="mb-4 p-2 text-xs">
      <table className="w-full border-collapse">
        <thead className='bg-[#3C3D3A] text-white p-2 h-12 text-left'>
          <tr>
            <th className="text-center">#</th>
            <th>Items</th>
            <th>Quantity</th>
            <th>UOM</th>
          </tr>
        </thead>
        <tbody>
          {challan?.items?.length > 0 ? (
            challan.items.map((item, index) => (
              <tr key={index} className='border-b p-2 h-12 text-left'>
                <td className="p-1 text-center">{index + 1}</td>
                <td className="p-1">{item?.item_name}</td>
                <td className="p-1">{item?.quantity}</td>
                <td className="p-1">{item?.UOM}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center p-2">No items available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>





    

    <div className="mb-4 p-2 text-xs">
      <div className="mt-6 flex items-center">
        <h3 className="text-sm font-regular mr-4 flex-shrink-0">Authorized Signature</h3>
        <div className="flex-grow">
          <div className="border-t border-black" style={{ width: '150px', height: '1px', marginTop: '1rem' }}></div>
        </div>
      </div>
    </div>
  </div>
);

const DeliveryChallanPdfViewer = ({ challan }) => (
  <div className="h-screen">
    <DeliveryChallanHtmlDocument challan={challan} />
  </div>
);

export default DeliveryChallanPdfViewer;

