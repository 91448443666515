import React, { useEffect, useState ,useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faBagShopping, faBank, faCartShopping, faBasketShopping,
  faUserPlus, faRectangleList, faFolder,
  faPlusCircle,
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faDashboard,
  faGear,
  faExclamationTriangle,
  faX
} from '@fortawesome/free-solid-svg-icons';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { useLocation,useNavigate } from 'react-router-dom';
import { Loader ,Spinner } from '../Loader/Loader';



function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
}

const Sidebar = () => {

  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [Items, setItems] = useState(false);
  const [Sales, setSales] = useState(false);
  const [Purchases, setPurchases] = useState(false);
  const [Settings, setSettings] = useState(false);
  const location = useLocation();
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const roleId = parseInt(sessionStorage.getItem('roleId'), 10);

  useEffect(() => {
    setIsCollapsed(isMobile);
  }, [isMobile]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  
  



  const shouldShowMenuItem = (menuItem) => {
    switch (roleId) {
      case 0:
        return true; // All menu items visible
      case 1:
        return menuItem !== 'Reports' && menuItem !== 'Settings'; // All except
      case 2:
      case 3:
        return menuItem === 'Home' || menuItem === 'Projects';
      case 4:
        return menuItem === 'Items' || menuItem === 'Sales';
      case 6:
        return menuItem !== 'Reports' && menuItem !== 'Settings' && menuItem !== 'Projects'; // All except  
      default:
        return true; // For any other roleId, show all menu items
    }
  };
  
  

  const menu = {
    Home: 1, Items: 2, Banking: 3, Sales: 4, Purchases: 5, Reports: 6, Projects:7, Users:8,Settings: 9
  };

  const visibleMenuItemsCount = Object.keys(menu).filter(item => shouldShowMenuItem(item)).length;
  const shouldEnableScroll = visibleMenuItemsCount > 3;
  
  const MainMenu = {
    Home: false, Items: false, Banking: false, Sales: false, Purchases: false, Reports: false, Projects: false, Users: false,Settings: false
  }
  const ItemsSubMenu = {
  ItemCategories : false, 
  ItemGroups: false,
  Items: false,
  ItemAttributes:false,
  Pricelist : false,
  InventoryAdjustMent: false
  }
 
  const SalesSubMenu = {
    Customers : false, 
    Quotes: false,
    SalesOrders: false,
    SOItemsWise: false,
    DeliveryChallans:false,
    
    }

    const PurchasesSubMenu = {
      Vendors : false,
      PurchaseOrders: false,
      PoItemsWise:false
    }
   
    const SettingsSubMenu = {
      Organizations : false,
  
     }

  const handleRemainingMenuClose = (menu) => {
    if (menu === 2) {
      setIsCollapsed(false);
      setSales(false);
      setPurchases(false);
    } else if (menu === 4) {
      setIsCollapsed(false);
      setItems(false);
      setPurchases(false);
    } else if (menu === 5) {
      setIsCollapsed(false);
      setItems(false);
      setSales(false);
    } else {
      setSales(false);
      setItems(false);
    }
  };
 

  const handleMenuClick = (menu, submenu) => {
    console.log(menu, submenu);
    


  if (isMobile) {
    setIsCollapsed(true);
  }
  

    if(menu === 1){
      navigate('home');
    }else  if (menu === 2) {
      if (submenu === 1) {
        navigate('items/productcategories');
      } else if (submenu === 1.1) {
        navigate('items/newproductcategory');
      }else if(submenu === 2){
        navigate('items/productgroupslist');
      }else if(submenu === 2.1) {
        navigate('items/newproductgroups');
      }
      else if(submenu === 3) {
        navigate('items/itemslist');
      }else if(submenu === 3.1) {
        navigate('items/newitem');
      }
      else if(submenu === 4) {
        navigate('items/productattributes');
      }else if(submenu === 4.1) {
        navigate('items/productattributes');
      }
      else if(submenu === 5) {
        navigate('items/viewpricelists');
      }else if(submenu === 5.1) {
        navigate('items/newpricelist');
      }
    }else  if (menu === 4) {  // hear 2 is the sub menus of the Sales
      if (submenu === 2) {
        navigate('sales/customerslist');
      }else if (submenu === 2.1) {
        navigate('sales/newcustomer');
      }else if (submenu === 3) {
        navigate('sales/quotelists');
      }
      else if (submenu === 3.1 ) {
        navigate('sales/newquote');
      }
      else if (submenu === 4) {
        navigate('sales/salesordersitemwise');
      }else if (submenu === 4.1) {
        navigate('sales/newsalesorder');
      }
      else if (submenu === 5) {
        navigate('sales/salesorderslist');
      }
      else if (submenu === 5.1) {
        navigate('sales/newsalesorder');
      }
      else if (submenu === 6) {
        navigate('deliverychallans/deliverychallanslist');
      }else if (submenu === 6.1) {
        navigate('deliverychallans/newdeliverychallans');
      }
      else if (submenu === 7) {
        navigate('sales/vehicleshedulelist');
      }
      else if (submenu === 7.1) {
        navigate('sales/newvehicleschedule');
      }
       
    }else  if (menu === 5) {  // hear 2 is the sub menus of the Sales
      if (submenu === 1) {
        navigate('purchases/vendorslist');
      }else if (submenu === 1.1) {
        navigate('purchases/newvendor');
      }
      else if (submenu === 2) {
        navigate('purchases/purchaseorderitemwise');
      }else if (submenu === 2.1) {
        navigate('purchases/newpurchaseorder');
      }
       else if (submenu === 3) {
        navigate('purchases/purchaseorderlist');
      }else if (submenu === 3.1) {
        navigate('purchases/newpurchaseorder');
      }
    }else if (menu === 7){
      if(submenu === 1){
        navigate('projects/projectslist')
      }else if (submenu === 1.1){
        navigate('projects/newproject')
      }
    }else if (menu === 9){
      if(submenu === 1){
        navigate('settings/organizationlist');
      }else if (submenu ===1.1){
        navigate('settings/neworganization');
      }

      
    }else if( menu=== 8){
      if(submenu === 1){
        navigate('users/userslist')
      }else if (submenu === 1.1){
        navigate('users/newuser')
      }
    }
    if (!isMobile) {
      setIsCollapsed(false);
    }
  };

  const isCurrentPath = (path) => location.pathname.toLowerCase() === path.toLowerCase();
  
    if(isCurrentPath('/bb/app/home')){
      MainMenu.Home = true
    }
     if(isCurrentPath('/bb/app/items/productcategories')){
      MainMenu.Items = true;
      ItemsSubMenu.ItemCategories=true
     }
     if(isCurrentPath('/bb/app/items/newproductcategory')){
      MainMenu.Items = true;
      ItemsSubMenu.ItemCategories=true
     }
     if(isCurrentPath('/bb/app/items/productattributes')){
      MainMenu.Items = true;
      ItemsSubMenu.ItemAttributes=true
     }
     if(isCurrentPath('/bb/app/items/productcategorydetails')){
      MainMenu.Items = true;
      ItemsSubMenu.ItemCategories=true
     }
     if(isCurrentPath('/bb/app/items/productgroupslist')){
      MainMenu.Items = true;
      ItemsSubMenu.ItemGroups=true
     }
     if(isCurrentPath('/bb/app/items/newproductgroups')){
      MainMenu.Items = true;
      ItemsSubMenu.ItemGroups=true
     }
     if(isCurrentPath('/bb/app/items/productgroupdetails')){
      MainMenu.Items = true;
      ItemsSubMenu.ItemGroups=true   
     }
     if(isCurrentPath('/bb/app/items/itemslist')){
      MainMenu.Items = true;
      ItemsSubMenu.Items=true   
     }
     if(isCurrentPath('/bb/app/items/newitem')){
      MainMenu.Items = true;
      ItemsSubMenu.Items=true   
     }
     if(isCurrentPath('/bb/app/items/itemdetails')){
      MainMenu.Items = true;
      ItemsSubMenu.Items=true   
     }
     if(isCurrentPath('/bb/app/items/viewpricelists')){
      MainMenu.Items = true;
      ItemsSubMenu.Pricelist=true
     }
     if(isCurrentPath('/bb/app/items/newpricelist')){
      MainMenu.Items = true;
      ItemsSubMenu.Pricelist=true
     }
     if(isCurrentPath('/bb/app/items/EditPriceList')){
      MainMenu.Items = true;
      ItemsSubMenu.Pricelist=true   
     }
     if(isCurrentPath('/bb/app/sales/customerslist')){
      MainMenu.Sales = true;
      SalesSubMenu.Customers=true
     }
     if(isCurrentPath('/bb/app/sales/customerdetails')){
      MainMenu.Sales = true;
      SalesSubMenu.Customers=true
     }
     if(isCurrentPath('/bb/app/sales/newcustomer')){
      MainMenu.Sales = true;
      SalesSubMenu.Customers=true
     }
     if(isCurrentPath('/bb/app/sales/newquote')){
      MainMenu.Sales = true;
      SalesSubMenu.Quotes=true
     }
     if(isCurrentPath('/bb/app/sales/quotelists')){
      MainMenu.Sales = true;
      SalesSubMenu.Quotes=true
     }
     if(isCurrentPath('/bb/app/sales/editquote')){
      MainMenu.Sales = true;
      SalesSubMenu.Quotes=true
     }
     if(isCurrentPath('/bb/app/sales/quotedetails')){
      MainMenu.Sales = true;
      SalesSubMenu.Quotes=true
     }
     if(isCurrentPath('/bb/app/sales/newsalesorder')){
      MainMenu.Sales = true;
      SalesSubMenu.SalesOrders=true
     }
     if(isCurrentPath('/bb/app/sales/salesorderslist')){
      MainMenu.Sales = true;
      SalesSubMenu.SalesOrders=true
     }
     
     if(isCurrentPath('/bb/app/sales/salesordersitemwise')){
      MainMenu.Sales = true;
      SalesSubMenu.SOItemsWise=true
     }
     if(isCurrentPath('/bb/app/sales/newvehicleschedule')){
      MainMenu.Sales = true;
      SalesSubMenu.VehicleSchedule=true
     }
     if(isCurrentPath('/bb/app/sales/vehicleshedulelist')){
      MainMenu.Sales = true;
      SalesSubMenu.VehicleSchedule=true
     }
     if(isCurrentPath('/bb/app/sales/vehicleschedulingdetails')){
      MainMenu.Sales = true;
      SalesSubMenu.VehicleSchedule=true
     }
     if(isCurrentPath('/bb/app/deliverychallans/deliverychallanslist')){
      MainMenu.Sales = true;
      SalesSubMenu.DeliveryChallans=true
     }
     if(isCurrentPath('/bb/app/deliverychallans/newdeliverychallans')){
      MainMenu.Sales = true;
      SalesSubMenu.DeliveryChallans=true
     }
     if(isCurrentPath('/bb/app/deliverychallans/deliverychallandetails')){
      MainMenu.Sales = true;
      SalesSubMenu.DeliveryChallans=true
     }
     if(isCurrentPath('/bb/app/purchases/vendorslist')){
      MainMenu.Purchases = true;
      PurchasesSubMenu.Vendors = true
     }
     if(isCurrentPath('/bb/app/purchases/newvendor')){
      MainMenu.Purchases = true;
      PurchasesSubMenu.Vendors = true
     }
     if(isCurrentPath('/bb/app/purchases/purchaseorderitemwise')){
      MainMenu.Purchases = true;
      PurchasesSubMenu.PoItemsWise = true
     }
     if(isCurrentPath('/bb/app/purchases/purchaseorderlist')){
      MainMenu.Purchases = true;
      PurchasesSubMenu.PurchaseOrders = true
     }
     if(isCurrentPath('/bb/app/purchases/newpurchaseorder')){
      MainMenu.Purchases = true;
      PurchasesSubMenu.PurchaseOrders = true
     }
     if(isCurrentPath('/bb/app/projects/projectslist')){
      MainMenu.Projects = true; 
     }
     if(isCurrentPath('/bb/app/projects/newproject')){
      MainMenu.Projects = true; 
     }
     
     if(isCurrentPath('/bb/app/projects/projectdetails')){
      MainMenu.Projects = true; 
     }
     if(isCurrentPath('/bb/app/projects/blockdetails')){
      MainMenu.Projects = true; 
     }
     if(isCurrentPath('/bb/app/projects/measurements')){
      MainMenu.Projects = true; 
     }
     if(isCurrentPath('/bb/app/settings/neworganization')){
      MainMenu.Settings = true;
      SettingsSubMenu.Organizations = true
     }
     if(isCurrentPath('/bb/app/settings/organizationlist')){
      MainMenu.Settings = true;
      SettingsSubMenu.Organizations = true
     }
    
     return (
      <div className={`flex flex-col text-[0.8125rem] bg-[#f7f7fe] border-r font-normal h-full ${isCollapsed ? 'w-[3.125rem]' : 'w-[12.5rem]'}`}>
      <div className={`flex flex-col flex-1 w-full rounded-md ${shouldEnableScroll ? 'overflow-y-auto' : ''} ${isCollapsed ? '' : ''}`}>
        <div className={`${shouldEnableScroll ? 'max-h-[calc(100vh-2rem)]' : ''} flex-grow`}>
          <ul className="flex flex-col space-y-3 px-2 list-none h-full mt-4 text-[#181C2E]">
          {shouldShowMenuItem('Home') && (

            <li onClick={() => {handleRemainingMenuClose(menu.Home); handleMenuClick(menu.Home); handleMenuClick(menu.Home);
          }}
              className={`flex flex-row space-x-3 items-center py-2  rounded-l-md cursor-pointer ${MainMenu.Home ? 'bg-[#f7525A] text-white' : 'hover:bg-[#EDEDF7]'}`}>
              <FontAwesomeIcon icon={faHome} className={`ml-2 mr-2 ${MainMenu.Home ? 'text-white ' : ''}`} />
              {!isCollapsed && <span>Home</span>}
            </li>
                      )}
          {shouldShowMenuItem('Items') && (            
            <li className={`flex flex-row space-x-3 items-center py-2 hover:bg-[#EDEDF7] rounded-l-md cursor-pointer ${MainMenu.Items ? ' bg-[#EDEDF7] text-[#f7525A]' : ''}`}
              onClick={() => { setItems(!Items); handleRemainingMenuClose(menu.Items); handleMenuClick(menu.Items); handleMenuClick(menu.Items); }}>
              <FontAwesomeIcon icon={faBagShopping} className={`ml-2 mr-2 ${MainMenu.Items ? 'text-[#f7525A]' : ''}`} />
              {!isCollapsed && <span>Items</span>}
              {!isCollapsed && (
                <>
                  {Items ? (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  ) : (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                  )}
                </>
              )}
            </li>
          )}
            {Items && (
              !isCollapsed && (
                <ul className="flex flex-col space-y-2 justify-center items-center cursor-pointer">
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${ItemsSubMenu.ItemCategories ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                    onClick={() => handleMenuClick(menu.Items, 1)}>
                    <span className="ml-6">Item Categories</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${ItemsSubMenu.ItemCategories ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Items, 1.1);
                        }} />
                    </div>
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${ItemsSubMenu.ItemGroups ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                  onClick={() => handleMenuClick(menu.Items, 2)}>
                    <span className='ml-6'>Item Groups</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${ItemsSubMenu.ItemGroups ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Items, 2.1);
                        }} />
                    </div>
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${ItemsSubMenu.Items ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                  onClick={() => handleMenuClick(menu.Items, 3)}>
                    <span className='ml-6'>Items</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${ItemsSubMenu.Items ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Items, 3.1);
                        }} />
                    </div>
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${ItemsSubMenu.ItemAttributes ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                    onClick={() => handleMenuClick(menu.Items, 4)}>
                    <span className='ml-6'>Item Attributes</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${ItemsSubMenu.ItemAttributes ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Items, 4.1);
                        }} />
                    </div>
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${ItemsSubMenu.Pricelist ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                  onClick={() => handleMenuClick(menu.Items, 5)}>
                    <span className='ml-6'>Price list</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${ItemsSubMenu.Pricelist ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Items, 5.1);
                        }} />
                    </div>
                  </li>
                  {/* <li className="group flex flex-row justify-between items-center p-2 w-full max-w-md hover:bg-[#EDEDF7] hover:rounded-l-md cursor-pointer">
                    <span className='ml-6'>Inventory Adjustment</span>
                    <div className="w-5">
                      <FontAwesomeIcon icon={faPlusCircle} className="hidden group-hover:inline-block" />
                    </div>
                  </li> */}
                </ul>
              )
            )}
           {/*  <li className={`flex flex-row space-x-3 items-center py-2 hover:bg-[#EDEDF7] hover:rounded-l-md cursor-pointer ${isCurrentPath('bb/admin/App/banking') ? 'bg-blue-500 text-white' : ''}`}
              onClick={() => handleRemainingMenuClose(menu.Banking)}>
              <FontAwesomeIcon icon={faBank} className={`ml-2 mr-2 ${isCurrentPath('bb/admin/App/banking') ? 'text-white' : ''}`} />
              {!isCollapsed && <span>Banking</span>}
            </li> */}
            {shouldShowMenuItem('Sales') && (
            <li className={`flex flex-row space-x-3 items-center py-2 hover:bg-[#EDEDF7] rounded-l-md cursor-pointer ${MainMenu.Sales ? ' bg-[#EDEDF7] text-[#f7525A]' : ''}`}
              onClick={() => { setSales(!Sales); handleRemainingMenuClose(menu.Sales);handleMenuClick(menu.Sales); }}>
              <FontAwesomeIcon icon={faCartShopping}  className={`ml-2 mr-2 ${MainMenu.Sales ? 'text-[#f7525A]' : ''}`} />
              {!isCollapsed && <span>Sales</span>}
              {!isCollapsed && (
                <>
                  {Sales ? (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  ) : (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                  )}
                </>
              )}
            </li>
            )}
            {Sales && (
              !isCollapsed && (
                <ul className="flex flex-col space-y-2 justify-center items-center cursor-pointer">
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${SalesSubMenu.Customers ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                  onClick={() => handleMenuClick(menu.Sales, 2)}
                 >
                    <span className='ml-7'>Customers</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${SalesSubMenu.Customers ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Sales, 2.1);
                        }} />
                    </div>
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${SalesSubMenu.Quotes ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                   onClick={() => handleMenuClick(menu.Sales, 3)}>
                    <span className='ml-7'>Quotes</span>
                    {/* <div className="w-5">
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${SalesSubMenu.Quotes ? 'text-white block' : 'hidden'}`}  />
                    </div> */}
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${SalesSubMenu.Quotes ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Sales, 3.1);
                        }} />
                    </div>
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${SalesSubMenu.SOItemsWise ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                   onClick={() => handleMenuClick(menu.Sales, 4)}>
                    <span className='ml-7'>SO Itemwise</span>
                    {/* <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${SalesSubMenu.SOItemsWise ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Sales, 4.1);
                        }} />
                    </div> */}
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${SalesSubMenu.SalesOrders ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                   onClick={() => handleMenuClick(menu.Sales, 5)}>
                    <span className='ml-7'>Sales Orders</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${SalesSubMenu.SalesOrders ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Sales, 5.1);
                        }} />
                    </div>
                  </li>
                
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${SalesSubMenu.DeliveryChallans ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                   onClick={() => handleMenuClick(menu.Sales, 6)}>
                    <span className='ml-7'>Delivery Challans</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${SalesSubMenu.DeliveryChallans ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Sales, 6.1);
                        }} />
                    </div>
                  </li>   

                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${SalesSubMenu.VehicleSchedule ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                   onClick={() => handleMenuClick(menu.Sales, 7)}>
                    <span className='ml-7'>Vehicle Schedule</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${SalesSubMenu.VehicleSchedule ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Sales, 7.1);
                        }} />
                    </div>
                  </li>   

                   </ul>
              )
            )}
            {shouldShowMenuItem('Purchases') && (
            <li onClick={() => { setPurchases(!Purchases); handleRemainingMenuClose(menu.Purchases);
              handleMenuClick(menu.Purchases);
            }}
              className={`flex flex-row space-x-3 items-center py-2 hover:bg-[#EDEDF7] rounded-l-md cursor-pointer ${MainMenu.Purchases ? ' bg-[#EDEDF7] text-[#f7525A]' : ''}`}>
              <FontAwesomeIcon icon={faBasketShopping} className={`ml-2 mr-2 ${MainMenu.Purchases ? 'text-[#f7525A]' : ''}`} />
              {!isCollapsed && <span>Purchases</span>}
              {!isCollapsed && (
                <>
                  {Purchases ? (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  ) : (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                  )}
                </>
              )}
            </li>
            )}
            {Purchases && (
              !isCollapsed && (
                <ul className="flex flex-col space-y-2 justify-center items-center cursor-pointer">
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${PurchasesSubMenu.Vendors ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                    onClick={() => handleMenuClick(menu.Purchases, 1)}>
                    <span className='ml-7'>Vendors</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${PurchasesSubMenu.Vendors  ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Purchases, 1.1);
                        }} />
                    </div>
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${PurchasesSubMenu.PoItemsWise ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                    onClick={() => handleMenuClick(menu.Purchases, 2)}>
                    <span className='ml-7'>PO Itemwise</span>
                    {/* <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${PurchasesSubMenu.PoItemsWise  ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Purchases, 2.1);
                        }} />
                    </div> */}
                  </li>
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${PurchasesSubMenu.PurchaseOrders ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                    onClick={() => handleMenuClick(menu.Purchases, 3)}>
                    <span className='ml-7'>Purchase Orders</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${PurchasesSubMenu.PurchaseOrders  ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Purchases, 3.1);
                        }} />
                    </div>
                  </li>
                 
                </ul>
              )
            )}
             {shouldShowMenuItem('Settings') && (
              <li onClick={() => { setSettings(!Settings); handleRemainingMenuClose(menu.Settings); }}
              className={`flex flex-row space-x-3 items-center py-2 hover:bg-[#EDEDF7] rounded-l-md cursor-pointer ${MainMenu.Settings ? ' bg-[#EDEDF7] text-[#f7525A]' : ''}`}>
              <FontAwesomeIcon icon={faGear} className={`ml-2 mr-2 ${MainMenu.Settings ? 'text-[#f7525A]' : ''}`} />
              {!isCollapsed && <span>Settings</span>}
              {!isCollapsed && (
                <>
                  {Settings ? (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  ) : (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                  )}
                </>
              )}
            </li>
             )}
            {Settings && (
              !isCollapsed && (
                <ul className="flex flex-col space-y-2 justify-center items-center cursor-pointer">
                  <li className={`group flex flex-row justify-between items-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${SettingsSubMenu.Organizations ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                    onClick={() => handleMenuClick(menu.Settings, 1)}>
                    <span className='ml-7'>Organizations</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${SettingsSubMenu.Organizations  ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Settings, 1.1);
                        }} />
                    </div>
                  </li>
                  </ul>
              )
            )}
                         {shouldShowMenuItem('Projects') && (
              <li onClick={() => {handleRemainingMenuClose(menu.Projects); handleMenuClick(menu.Projects , 1) }} 
               className={`group flex flex-row space-x-3 items-center py-2 w-full max-w-md  rounded-l-md cursor-pointer ${MainMenu.Projects ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}>
              <FontAwesomeIcon icon={faDashboard} className={`ml-2 mr-2 ${isCurrentPath('bb/admin/App/reports') ? 'text-white' : ''}`} />
              {!isCollapsed && <span className=''>Projects</span>
              }
              <div className="w-5 " onClick={(e) => e.stopPropagation(menu.Projects )}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${MainMenu.Projects  ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Projects, 1.1);
                        }} />
                    </div>
              
            </li>
                         )}
             {shouldShowMenuItem('Reports') && (
           <li onClick={() => handleRemainingMenuClose(menu.Reports)}
              className={`flex flex-row space-x-3 items-center py-2 hover:bg-[#EDEDF7] hover:rounded-l-md cursor-pointer ${isCurrentPath('bb/admin/App/reports') ? 'bg-blue-500 text-white' : ''}`}>
              <FontAwesomeIcon icon={faRectangleList} className={`ml-2 mr-2 ${isCurrentPath('bb/admin/App/reports') ? 'text-white' : ''}`} />
              {!isCollapsed && <span>Reports</span>}
            </li>
             )}
            {/* <li onClick={() => handleRemainingMenuClose(menu.Accountant)}
              className={`flex flex-row space-x-3 items-center py-2 hover:bg-[#EDEDF7] hover:rounded-l-md cursor-pointer ${isCurrentPath('bb/admin/App/accountant') ? 'bg-blue-500 text-white' : ''}`}>
              <FontAwesomeIcon icon={faUserPlus} className={`ml-2 mr-2 ${isCurrentPath('bb/admin/App/accountant') ? 'text-white' : ''}`} />
              {!isCollapsed && <span>Accountant</span>}
            </li> */}
           {/*  <li onClick={() => handleRemainingMenuClose(menu.Documents)}
              className={`flex flex-row space-x-3 items-center py-2 hover:bg-[#EDEDF7] hover:rounded-l-md cursor-pointer ${isCurrentPath('bb/admin/App/documents') ? 'bg-blue-500 text-white' : ''}`}>
              <FontAwesomeIcon icon={faFolder} className={`ml-2 mr-2 ${isCurrentPath('bb/admin/App/documents') ? 'text-white' : ''}`} />
              {!isCollapsed && <span>Documents</span>}
            </li> */}
          </ul>
        </div>
      </div>
      <div className="flex justify-center h-[2rem] bg-[#ededf7] items-center cursor-pointer" onClick={toggleCollapse}>
        {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
      </div>
      
    </div>
  
     );
    };


export default Sidebar;
