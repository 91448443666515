import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';


function Main_Page() {
  

  return (
  
    <div className="fixed inset-0 flex flex-col overflow-hidden">
    {/* Navbar */}
    <div className='h-[3rem] w-full'>
      <Navbar  />
    </div>
  
    {/* Main content area */}
    <div className='flex flex-1 overflow-hidden'>
      <Sidebar />
      
      {/* Content area with scroll */}
      <div className='flex-1 overflow-y-auto'>
        <Outlet />
      </div>
    </div>

  </div>
  );
}

export default Main_Page;