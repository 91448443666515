import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faProjectDiagram, faUser, faSignOutAlt, faBell, faTimes,faAngleRight,faAngleLeft, faUserPlus,faUserCircle } from "@fortawesome/free-solid-svg-icons";

function ProjectsContainer() {

    const Navigate = useNavigate();
    const GetUsers = process.env.REACT_APP_FORE_APILINK + '/admin/get-all-users-details';
    const GetProjects = process.env.REACT_APP_FORE_APILINK + '/projects/get-all-projects';
    const AssignUserToProject = process.env.REACT_APP_FORE_APILINK + '/admin/asign-project-to-user';
    const [displayedProjects, setdisplayedProjects] = useState([]);
    const [users, setUsers] = useState([]);
 
  const [selectedUser, setselectedUser] = useState();
  const [selectedproject, setselectedproject] = useState();
  const [ProjectUserId, setProjectUserId] = useState();
  const [AssignUser, setAssignUser] = useState(false);

  const [userProfileImage, setuserProfileImage] = useState();

    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Adjust the number of items per page as needed
    
    const totalPages = Math.ceil(displayedProjects?.length / itemsPerPage) || 1;
    const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = displayedProjects?.slice(indexOfFirstItem, indexOfLastItem) || [];
    // Calculate the index range for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    const [UserAssignedtoProjectAlert, setUserAssignedtoProjectAlert] = useState(false);
    const [noSelectAssignUseralert, setnoSelectAssignUseralert] = useState(false);

    const [rescheduleDate, setRescheduleDate] = useState({});
    const [DatePopup, setDatePopup] = useState(false);
    const [date,setDate] = useState('');
    const [selectedProjectId, setSelectedProjectId] = useState(null);

    // Function to handle selecting the reschedule date for a specific project
    

    useEffect(()=>{
      const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
      const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
      if(storedLoginData&&Userdata){
        if(storedLoginData.token===Userdata.token){
        }else{
          Navigate('/measurit')
         sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
        }
      }else{
         Navigate('/measurit')
          sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
      }
    })
    const handleUserAssignedtoProjectAlert = async () => {
        setUserAssignedtoProjectAlert(false);
        window.location.reload();
       }
 
       const handleViewProjectDetails = async (Project, username, User) => {
         Navigate("/measurit/View", { state: { project: Project, username ,user: User} });
       }
 
       useEffect (() => {
         fetchProjects();
         fetchUsers();
       }, []);
       
      
 
       const fetchProjects = async () => {
         try {
       const token = sessionStorage.getItem('token');
           const response = await fetch(GetProjects, {
             method: 'GET',
             headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json',
               'x-token':token
             },
           });
       
           if (response.ok) {
             const projectsData = await response.json();
         const reversedData = projectsData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
             setProjects(sortedProjects);
             setdisplayedProjects(projectsData || []);
           } else {
           }
         } catch (error) {
          clearStorageAndRedirectToHome()
          
         }
       };
 
       function clearStorageAndRedirectToHome() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/measurit';
      }

       const fetchUsers = async () => {         
         try{
          const token = sessionStorage.getItem('token');
           const response = await fetch(GetUsers,{
             method: 'GET',
             headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json', // Set the content type to JSON
               'x-token':token
             },
           })
           if(response.ok){
             const usersData = await response.json();
             setUsers(usersData);
             fetchProjects();
           }
           else {
           }
         }
         catch (error){
          clearStorageAndRedirectToHome()
         }
       };
     
 
       const handleAssignprojecttoUser = async(user) => {      
          const selectedUsers = users.filter((user) => user.isSelected);
          if (selectedUsers.length === 0) {
           setnoSelectAssignUseralert(true);
            return;
          }
          const userId = selectedUsers.map((user) => user._id);
      
          const data = {
            projectId: selectedproject._id,
            userId: userId
          };
          try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(AssignUserToProject,{
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json', // Set the content type to JSON
              'x-token':token
            },
      
            body: JSON.stringify(data),
          })
           if (response.ok) {
            setselectedUser(user);
            setUserAssignedtoProjectAlert(true);
            
            //alert('Users assigned to project successfully');
      
          } else {
          }
        } catch (error) {
          clearStorageAndRedirectToHome()
        }
      };
      
      const handleCheckboxChange = (userId) => {
        setProjectUserId(userId)
        setUsers((prevUsers) =>
          prevUsers.map((User) =>
          User._id === userId ? { ...User, isSelected: !User.isSelected } : User
          )
        );
      };
      
       const handleAssignUser = async(Project)=>{
        setAssignUser(true);
        setselectedproject(Project);
        setProjectUserId(Project.assignedUserId);
       
       };
 
       const handleClose = () => {
         setAssignUser(false);
       }
 
       const handleClosenoAssigneUser = () =>{
         setnoSelectAssignUseralert(false);
       }
 
       const handlePageChange = (page) => {
         setCurrentPage(page);
       };
       const currentProjects = projects.slice(startIndex, endIndex);
 
       const handleUserDetails = (assignedUser) => {
         // Navigate to the user details page with the user ID
         Navigate('/measurit/UserDetails', { state: { user: assignedUser } });
     };
 
     
     const handleBack = () => {
       // Define the action when the back button is clicked
       // For example, you can navigate to the previous page
       Navigate(-1); // This navigates back one step in the history
   };

   const url = process.env.REACT_APP_FORE_APILINK + `/projects/update-project?projectId=${selectedProjectId}`;

const handleSubmit = async () => {
  try {
    const token = sessionStorage.getItem('token');
    
    // Get the rescheduled date for the selected project
    const date = rescheduleDate;
    console.log(date);

    const formData = new FormData();
   // formData.append('projectId', projectIdString); // Include the project ID as a string
    formData.append('rescheduleDate', date); // Include the rescheduled date


    // Make an API call to the backend to update the project
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'x-token': token
      },
      body: formData
    });

    if (response.ok) {
      // Handle success response from the backend
      const projectDetails = await response.json();
      console.log(projectDetails.rescheduleDate);
      setRescheduleDate(projectDetails.rescheduleDate)
      setDatePopup(false)
      fetchProjects()
      // You can update state or perform any other actions as needed
    } else {
      // Handle errors if the API call fails
      // You can display an error message to the user if needed
    }
  } catch (error) {
    // Handle errors if the API call encounters an exception
    clearStorageAndRedirectToHome();
    // You can display an error message to the user if needed
  }
};

   const handleCloseDate = () => {
    setDatePopup(false);
   // window.location.reload();
  };
  const handleOpenDate = (projectId,date) => {
    //console.log(projectId)
    //console.log(date)
    setDatePopup(true);
    setDate(date); 
    setSelectedProjectId(projectId);
  };
  
  // Function to handle date selection
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    // Set only the selected date for the specified project ID
    setRescheduleDate(selectedDate);
  };
 

return (
<div>
{UserAssignedtoProjectAlert && (
    <div className="fixed inset-0 z-30 flex items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30 "></div>
  
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-bold mb-2">
       User assigned to project successfully!</p>
       <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleUserAssignedtoProjectAlert}>OK</button>
      </div>
    </div>
  )} 
  
  {noSelectAssignUseralert &&(
         <div className="fixed inset-0 z-30 flex items-center justify-center">
         {/* Background overlay (blurred) */}
         <div className="absolute inset-0 bg-black opacity-30 "></div>
  
         <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
         <p className="text-lg font-bold mb-2">
           Please select at least one user!
           </p>
           <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={ handleClosenoAssigneUser}>
             OK
           </button>
         </div>
       </div>
        )}
  
  
  
   {/* Projects Table */}
   
   <div className="w-full mx-auto mt-2">
   
   {/* Wrapper div with white background */}
   
   <div className="max-w-screen-xl mx-auto mt-5 p-4">
    {/* Back Button */}
    <div className="flex justify-start items-center mr-6 ml-6 mb-2">
    <button onClick={handleBack} className="relative flex items-center text-md text-black rounded-md px-5 py-1 mt-3">
      <FontAwesomeIcon icon={faAngleLeft} className="text-lg cursor-pointer text-black mr-2" /> Back
    </button>
  </div>
   
     <div className="bg-white flex flex-col relative rounded-md shadow-md p-2 ml-10 mr-10 mx-2 md:mx-6 overflow-x-auto">
       <div>
         <h1 className="text-md font-semibold">Projects</h1>
       </div>
       {currentProjects.length > 0 ? (
       <table className="w-full border-b border-gray-300 mt-4">
       <thead className="border-b border-gray-300">
         <tr className="bg-[#959BB0] bg-opacity-[15%]">
           <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Name</th>
           <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Builder Name</th>
           <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Builder Contact</th>
           <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Total Flats</th>
           <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Assigned User</th>
           <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Last Visited</th>
           <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Reschedule Date</th>
         </tr>
       </thead>
       {/* Add your table body content here */}
      
       <tbody className="border-b border-gray-300">
  {currentProjects.map((Project, index) => {
    const date = new Date(Project.timestamp);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    return (
      <tr key={Project._id} className={index % 2 === 0 ? "bg-white border-b border-gray-300" : "bg-[#F9F4F4] border-b border-gray-300"}>
        <td
          className="text-blue-950 text-opacity-100 py-2 text-xs px-4 text-center cursor-pointer hover:text-opacity-90 hover:underline"
          onClick={(e) => {
            const assignedUser = users.find((p) => p._id === Project.assignedUserId);
            const userName = assignedUser ? `${assignedUser.firstName} ${assignedUser.lastName || 'UnAssigned'}` : 'UnAssigned';
            handleViewProjectDetails(Project, { name: userName }, users.find((p) => p._id === Project.assignedUserId));
          }}
        >
          {Project.projectName}
        </td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.builderName}</td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.builderContactNo}</td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.totalFlats}</td>
        <td
          className={`text-${Project.assignedUserId ? 'blue-300' : 'red-300'} py-2 text-xs text-center px-4 ${
            Project.assignedUserId ? 'underline cursor-pointer' : 'underline cursor-pointer'
          }`}
          onClick={() =>  (/* Project.assignedUserId ? handleUserDetails(users.find((p) => p._id === Project.assignedUserId)) : */ handleAssignUser(Project))}
        >
          {Project.assignedUserId ? (
            <a
              // Replace with the actual URL pattern for the user's page
              onClick={(e) => {
                if (!Project.assignedUserId) {
                  e.preventDefault();
                  handleAssignUser(Project);
                }
              }}
            >
              {`${users.find((p) => p._id === Project.assignedUserId)?.firstName} ${users.find((p) => p._id === Project.assignedUserId)?.lastName || ''}`}
            </a>
          ) : (
            'UnAssigned User'
          )}
        </td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{formattedDate}</td>
        <td
  className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center underline cursor-pointer"
  onClick={() => handleOpenDate(Project._id,Project.rescheduleDate)}
>
  { Project.rescheduleDate  || 'Reschedule Date'}
</td>
      </tr>
    );
  })}
</tbody>
 
           
     </table>
     ) : (
      <p className="text-center">No projects available</p>
  )}

{DatePopup && (
  <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
    <div className="bg-white p-10 rounded-lg shadow-md relative">
      <div className="flex justify-end cursor-pointer absolute top-2 right-2" onClick={handleCloseDate}>
        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
      </div>
      <div className="flex flex-col items-center">
        <label htmlFor="rescheduleDate" className="text-lg font-semibold mb-2">Reschedule Date</label>
        <input 
          type="date" 
          id="rescheduleDate" 
          className="px-4 py-2 border border-gray-300 rounded-md mb-4 "
          value={date}
          onChange={handleDateChange}
        />
        <button 
          className="bg-[#0F9D58] flex flex-col relative mx-auto mt-2 text-sm text-white bg-opacity-75 rounded-sm px-4 py-1 hover:bg-opacity-90"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  </div>
)}

     <div className="flex justify-end mt-4">
    <button
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className={`mr-2 bg-gray-200 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-300 ${currentPage === 1 ? 'opacity-30 cursor-not-allowed' : ''}`}
    >
      <FontAwesomeIcon icon={faAngleLeft} className="cursor-pointer mr-1" />
    </button>
    {Array.from({ length: totalPages }).map((_, index) => (
      <button
        key={index}
        onClick={() => handlePageChange(index + 1)}
        className={`mr-2 bg-gray-200 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-300 ${currentPage === index + 1 ? 'bg-gray-400 text-white' : ''}`}
      >
        {index + 1}
      </button>
    ))}
    <button
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className={`bg-gray-200 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-300 ${currentPage === totalPages ? 'opacity-30 cursor-not-allowed' : ''}`}
    >
      <FontAwesomeIcon icon={faAngleRight} className="cursor-pointer mr-1" />
    </button>
  </div>
  
   
     </div>
   </div>
   </div>
  
   {/* Assign User Popup */}
   {AssignUser && (
            <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
              <div className="bg-white p-2 rounded-lg shadow-md">
                <div className="flex justify-end cursor-pointer" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-600" />
                </div>
                <div className="w-full mx-auto mt-2">
    {/* Wrapper div with white background */}
    <div className="max-w-screen-xl mx-auto mt-3 p-2">
      <div className="flex flex-col relative rounded-md p-2 ml-10 mr-10">
        
        <table className="w-full border-b border-gray-300 mt-2">
        <thead className="border-b border-gray-300">
          <tr className="bg-[#959BB0] bg-opacity-[15%]">
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">User Name</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Mobile Number</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Assign User</th>
          </tr>
        </thead>
        {/* Add your table body content here */}
       
         <tbody className="border-b border-gray-300">
        {users.map ((User, index) => (
          <tr key={User._id} className={index % 2 === 0 ? "bg-white border-b border-gray-300" : "bg-[#F9F4F4] border-b border-gray-300"}>
              <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{`${User.firstName} ${User.lastName}`}</td>
              <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.mobileNo}</td>
              <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">
              <input
          type="checkbox"
          className="form-checkbox h-4 w-4 text-blue-500"
          checked={User._id === ProjectUserId || false}
          onChange={() => handleCheckboxChange(User._id)}
        />
              </td>
            </tr>
            
        ))}
          
            </tbody> 
            
      </table>
      
      </div>
            <button class=" bg-[#0F9D58] flex flex-col relative mx-auto mt-2 text-sm text-white bg-opacity-75 rounded-sm px-4 py-1 hover:bg-opacity-90" onClick={handleAssignprojecttoUser}>Assign</button>
    </div>
    </div>
                </div>
                </div>
                )}
</div>

)
}
export default ProjectsContainer;