import React, { useState,useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faProjectDiagram, faUser, faSignOutAlt, faBell, faCamera, faImage, faUserPlus, faAngleLeft, faUserCircle, faTimes, faChevronLeft, faChevronRight, faChevronUp, faChevronDown ,faArrowUp,faArrowDown} from '@fortawesome/free-solid-svg-icons';

import { MdAddLocationAlt } from "react-icons/md";

function UserViewProjectContainer() {
    const roleId = sessionStorage.getItem('userRole');
    const location = useLocation();
    const projectDetails = location.state;
    console.log(projectDetails);
    const [projectName, setProjectName] = useState('');
    const [builderName, setBuilderName] = useState('');
    const [builderContactNo, setBuilderContactNo] = useState('');
    const [siteContactName, setSiteContactName] = useState('');
    const [siteContactNo, setSiteContactNo] = useState('');
    const [siteAddress, setSiteAddress] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [file,setFile] = useState(null);
    const [fillDetailsAlert, setfillDetailsAlert] = useState(false);
    const [DeactivateAndActivateAlert, setDeactivateAndActivateAlert] = useState(false);
    const [coordinates, setCoordinates] = useState(null);
    const [inputLatitude, setInputLatitude] = useState('');
    const [inputLongitude, setInputLongitude] = useState('');
    const [notes, setNotes] = useState(''); // State for notes
    const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
    const [visitHistory,setVisitHistory] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

  

  

    const handlePrevImage = () => {
      if (currentImageIndex > 0) {
        setCurrentImageIndex(currentImageIndex - 1);
      }
    };
  
    const handleNextImage = () => {
      if (currentImageIndex < projectImage.length - 1) {
        setCurrentImageIndex(currentImageIndex + 1);
      }
    };
    
    const handleSelectChange = (e) => {
      setSelectedValue(e.target.value);
    };
  
    const handleNotesChange = (e) => {
      setNotes(e.target.value);
  };

  const handlePopupOkClick = () => {
    // Navigate to user home
    Navigate('/measurit/UserHome');
};


    useEffect(()=>{
      const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
      const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
      if(storedLoginData&&Userdata){
        if(storedLoginData.token===Userdata.token){
        //console.log('profile matched')
        }else{
          //console.log('logout')
         Navigate('/measurit')
         sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
        }
      }else{
        Navigate('/measurit')
          sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
      }
    })

    const handleChange = (e, setStateFunction, isNumeric = false, maxLength = null, relatedStateFunction = null) => {
      let inputValue = e.target.value;
    
      // If it's a numeric field, validate input to allow only numeric characters
      if (isNumeric) {
        inputValue = inputValue.replace(/\D/g, '');
      }
    
      // Limit the input to the specified maxLength if provided
      if (maxLength !== null && inputValue.length > maxLength) {
        inputValue = inputValue.slice(0, maxLength);
      }
    
      // Update the state with the sanitized input
      setStateFunction(inputValue);
    
      // If relatedStateFunction is provided, update the related state with the same value
      if (relatedStateFunction && typeof relatedStateFunction === 'function') {
        relatedStateFunction(inputValue);
      }
    };
  
    const [projectProfile, setprojectProfile] = useState();
    const [uploadedImage, setUploadedImage] = useState();
    const [projectImage, setProjectImage] = useState();
      const [images, setImages] = useState([]);
      const [files, setFiles] = useState([]);
    ////console.log(uploadedImage)
    const handleImageUpload = (event) => {
      const newImages = [];
      const newFiles = [];
    
      for (let i = 0; i < event.target.files.length; i++) {
        const imageUrl = URL.createObjectURL(event.target.files[i]);
        const fileUrl = event.target.files[i];
        console.log('Uploaded Image URL:', imageUrl); // Log the uploaded image URL for debugging
        console.log('Uploaded File URL:', fileUrl);
        newImages.push(imageUrl);
        newFiles.push(fileUrl);
      }
    
      console.log('New Images:', newImages);
      console.log('New Files:', newFiles); // Log the updated images state for debugging
      // Here you might need to concatenate new images and files with the existing ones
      setImages([...images, ...newImages]);
      setFiles([...files, ...newFiles]);
      
      // Set the uploadedImage state to the first image URL
      if (newImages.length > 0) {
        setUploadedImage(newImages[0]);
      }
    };
    
    
    // Function to update project status in the UI
    const updateProjectStatus = (status) => {
      setIsActivated(status);
    };
  
    const [isActivated, setIsActivated] = useState();
  
    const handleToggle = async () => {
      try {
        const token = sessionStorage.getItem('token');
        // Determine the API endpoint based on the current activation status
        const endpoint = isActivated
          ? '/admin/deactivate-project' // Replace with your actual deactivate endpoint
          : '/admin/activate-project'; // Replace with your actual activate endpoint
  
        // Make an API call to activate or deactivate the project
        const response = await fetch(
         ` ${process.env.REACT_APP_FORE_APILINK}${endpoint}?projectId=${projectDetails.projectId}` , {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-token':token
          },
        });
 
        if (response.ok) {
          // If the update is successful, update project status in the UI
          updateProjectStatus((prevState) => !prevState);
          setDeactivateAndActivateAlert(true);
        } else {
          // Handle errors if the API call fails
        }
      } catch (error) {
        clearStorageAndRedirectToHome()
        // Handle errors if the API call encounters an exception
      }
    };
    
  
  
    const Navigate = useNavigate();
  
  
    const url = process.env.REACT_APP_FORE_APILINK + `/projects/update-project?projectId=${projectDetails.projectId}`;
  
    const handleNextClick = async () => {
/*       if (!siteContactName || !siteContactNo || !siteAddress || !selectedValue || !inputLatitude || !inputLongitude) {
        //setfillDetailsAlert(true);
        return;
      } */
      // Prepare data to be sent to the backend
      const formData = new FormData();
      formData.append('projectName', projectName);
      formData.append('builderName', builderName);
      formData.append('builderContactNo', builderContactNo);
      formData.append('siteContactName', siteContactName);
      formData.append('siteContactNo', siteContactNo);
      formData.append('siteAddress', siteAddress);
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
      }
      //formData.append('numBlocks', selectedValue); // Fix syntax here
      formData.append('latitude', inputLatitude); 
      formData.append('longitude', inputLongitude);
      formData.append('notes', notes);
    
      try {
        const token = sessionStorage.getItem('token');
        // Make an API call to the backend to post the data
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            /* 'Content-Type': 'application/json', */
            'x-token':token
          },
          body: formData,
        });
    
        if (response.ok) {
          // If the data is successfully posted, navigate to the next page with the number of blocks as a query parameter
          //navigate(`/block?numberOfBlocks=${selectedValue}`);
          const projectDetails = await response.json();
          //console.log(projectDetails._id);
          const nextState = {
            projectId: projectDetails._id,
            numBlocks:projectDetails.numBlocks
          };
          Navigate('/measurit/blocks', { state: nextState });
        
        } else {
          // Handle errors if the API call fails
          // You can display an error message to the user if needed
        }
      } catch (error) {
        clearStorageAndRedirectToHome()
        // Handle errors if the API call encounters an exception
        // You can display an error message to the user if needed
      }
    };
    
    const handleSubmit = async () => {
      if (!siteContactName || !siteContactNo || !siteAddress || !selectedValue || !inputLatitude || !inputLongitude) {
          //setfillDetailsAlert(true);
          return;
      }
      try {
          const token = sessionStorage.getItem('token');
          const formData = new FormData();
          formData.append('projectName', projectName);
          formData.append('builderName', builderName);
          formData.append('builderContactNo', builderContactNo);
          formData.append('siteContactName', siteContactName);
          formData.append('siteContactNo', siteContactNo);
          formData.append('siteAddress', siteAddress);
          //formData.append('numBlocks', selectedValue);
          formData.append('latitude', inputLatitude);
          formData.append('longitude', inputLongitude);
          formData.append('notes', notes);
          for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
          }
  
          // Make an API call to the backend to update the project
          const response = await fetch(url, {
              method: 'PUT',
              headers: {
                  'x-token': token
              },
              body: formData
          });
  
          if (response.ok) {
              const projectDetails = await response.json();
              const nextState = {
                  projectId: projectDetails._id,
                  numberOfBlocks: selectedValue
              };
              setShowPopup(true);
          } else {
              // Handle errors if the API call fails
              // You can display an error message to the user if needed
          }
      } catch (error) {
          clearStorageAndRedirectToHome()
          // Handle errors if the API call encounters an exception
          // You can display an error message to the user if needed
      }
  };
  
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchProjectDetails = async () => {
        try {
          const token = sessionStorage.getItem('token');
          // Make an API call to get project details
          const response = await fetch(
            process.env.REACT_APP_FORE_APILINK + `/projects/get-project?projectId=${projectDetails.projectId}`,
            {
            method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token':token
          },});
  
          if (response.ok) {
            const projectDetails = await response.json();

            console.log(projectDetails)
  
            // Set project details in state
            setProjectName(projectDetails.projectName || '');
            setBuilderName(projectDetails.builderName || '');
            setBuilderContactNo(projectDetails.builderContactNo || '');
            setSiteContactName(projectDetails.siteContactName || '');
            setSiteContactNo(projectDetails.siteContactNo || '');
            setSiteAddress(projectDetails.siteAddress || '');
            //setSelectedValue(projectDetails.numBlocks || '');
            setUploadedImage(projectDetails.projectImage || '');
            setIsActivated(projectDetails.is_active);
           setprojectProfile(projectDetails.projectImage);
           setInputLatitude(projectDetails.latitude);
           setInputLongitude(projectDetails.longitude);
           setVisitHistory(projectDetails.visitHistory || []); // Set visit history
           setProjectImage(projectDetails.projectImage);
           console.log(projectDetails.projectImage);
           setProjectImage(projectDetails.projectImage || '');
           console.log(projectDetails.projectImage);
           // Mark loading as false
            setLoading(false);
           
          } else {
            // Handle errors if the API call fails
            // You can display an error message to the user if needed
          }
        } catch (error) {
          clearStorageAndRedirectToHome()
          // Handle errors if the API call encounters an exception
          // You can display an error message to the user if needed
        }
      };
  
      // Trigger the fetchProjectDetails function on component mount
      fetchProjectDetails();
    }, []); // The empty dependency array ensures that this effect runs once on mount
  
    function clearStorageAndRedirectToHome() {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/measurit';
    }
  
    if (loading) {
      return <div>Loading...</div>; // You can replace this with a loading spinner or message
    }
  
    const isProjectActive = isActivated;

    const handleBack = () => {
        // Define the action when the back button is clicked
        // For example, you can navigate to the previous page
        Navigate(-1); // This navigates back one step in the history
    };

    const handlefillDetails = async() => {
        setfillDetailsAlert(false);
       }
      
       const handleCloseActiveAndDeactiveAlert = async() => {
        setDeactivateAndActivateAlert(false);
       }
      
       const handleUserDetails = () => {

        // Navigate to the user details page with the user ID
        Navigate('/UserDetails', { state: { user: projectDetails.user } });
      };



      const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setInputLatitude(position.coords.latitude.toString());
                setInputLongitude(position.coords.longitude.toString());
            });
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };


    const handleImageClick = () => {
      setModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setModalOpen(false);
      //window.location.reload();
    };

const handleCopyToBuilder = () => {
    if (siteContactName && siteContactNo) {
        setBuilderName(siteContactName);
        setBuilderContactNo(siteContactNo);
    }
};

const handleCopyToSiteContact = () => {
    if (builderName && builderContactNo) {
        setSiteContactName(builderName);
        setSiteContactNo(builderContactNo);
    }
};


return (
<div className='overflow-x-scroll no-scrollbar'>
{fillDetailsAlert && (
    <div className="fixed inset-0 z-30 flex items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30 "></div>
  
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-bold mb-2">
       Please fill all required fields!</p>
       <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handlefillDetails}>OK</button>
      </div>
    </div>
  )}
  
  {DeactivateAndActivateAlert&&(
         <div className="fixed inset-0 flex z-10 items-center justify-center">
         {/* Background overlay (blurred) */}
         <div className="absolute inset-0 bg-black opacity-30 "></div>
  
         {/* Popup content */}
         <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
           <p className="text-lg font-bold mb-2">
             Are you sure want to {isActivated ? "Activate" : "Deactivate"} Project?
           </p>
           <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleCloseActiveAndDeactiveAlert}>
             OK
           </button>
         </div>
       </div>
        )}


{showPopup && (
         <div className="fixed inset-0 flex z-10 items-center justify-center">
           <div className="absolute inset-0 bg-black opacity-30 "></div>
         <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
                        <p>Project Submitted successfully</p>
                        <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"  onClick={handlePopupOkClick}>OK</button>
                    </div>
                </div>
            )}
      <div className="flex flex-col items-stretch">
  
  
  <div className="flex flex-col mt-3.5 p-4 max-md:max-w-full max-md:mt-6">
  
  {/* Back Button */}
  <div className="flex justify-start items-center mb-2">
    <button onClick={handleBack} className="relative flex items-center text-md text-black rounded-md px-5 py-1 mt-3">
      <FontAwesomeIcon icon={faAngleLeft} className="text-lg cursor-pointer text-black mr-2" /> Back
    </button>
  </div>
  
  <div className="border bg-white self-stretch px-2 pt-4 pb-10 rounded-sm border-solid border-black border-opacity-20 max-md:max-w-full ml-2 mr-2 max-md:px-2">
    <div className=" flex flex-row space-x-20 max-md:items-stretch max-md:gap-0 sm:space-x-4">
  
  <div className="flex flex-col items-stretch w-1/3 max-md:w-full max-md:ml-0">
    <div className="flex flex-col items-center mt-6 max-md:mt-10">
      <div className="border border-gray-300 pr-4 pl-4  mt-6 flex flex-col items-center max-md:mt-2">
        <div className="text-black text-opacity-80 mt-2 text-base whitespace-nowrap">
          Project Image
        </div>
        <div className="mb-4 flex mt-2 items-center justify-center">
    {images && images.length > 0 && images[images.length - 1] || projectImage && projectImage.length > 0 && projectImage[projectImage.length - 1] ? (
        <div className="relative">
            <img
                src={images[images.length - 1] || process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL + projectImage[projectImage.length - 1]}
                alt="Uploaded Profile"
                className="w-20 h-20 object-cover border-4 border-white cursor-pointer"
                onClick={handleImageClick}
            />
            <label
                htmlFor="imageUpload"
                className="text-blue-500 text-sm underline hover:text-blue-900 cursor-pointer"
            >
                <FontAwesomeIcon icon={faCamera} />
                <input type="file" id="imageUpload" accept="image/*" className="hidden" onChange={handleImageUpload} multiple />            </label>
        </div>
    ) : (
        <label htmlFor="imageUpload" className="cursor-pointer">
            <span className="hover:text-gray-700 text-gray-500 text-4xl mr-2">
                <FontAwesomeIcon icon={faImage} />
            </span>
            <input type="file" id="imageUpload" accept="image/*" className="hidden" onChange={handleImageUpload} multiple />
        </label>
    )}
</div>

{modalOpen && (
  <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
    <div className="bg-white p-20 rounded-lg shadow-md relative">
      <div className="flex justify-end cursor-pointer absolute top-2 right-2" onClick={handleCloseModal}>
        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
      </div>
      <div className="flex items-center">
        <button
                   className={`px-2 py-1 mr-2 ${currentImageIndex === 0 ? 'opacity-20 pointer-events-none' : ''}`}
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
        >
         <FontAwesomeIcon icon={faChevronLeft} size="2x" />
        </button>
        <img
          src={`${process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL}` + projectImage[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
          className="w-80 h-auto"
        />
        <button
           className={`px-2 py-1 ml-2 ${currentImageIndex === projectImage.length - 1 ? 'opacity-20 pointer-events-none' : ''}`}
          onClick={handleNextImage}
          disabled={currentImageIndex === projectImage.length - 1}
        >
        <FontAwesomeIcon icon={faChevronRight} size="2x" />
        </button>
      </div>
    </div>
  </div>
)}




</div>
  
  
      <div className="text-black text-opacity-80 text-base self-stretch mt-8 whitespace-nowrap text-center">
    Assigned User <br />
  {projectDetails.userName}
  </div>
      <div className={`text-base text-opacity-80 self-stretch mt-5 max-md:max-w-full text-center`}>
        Project Status <br />
        <span style={{ color: isActivated ? 'green' : 'red' }}>
            {isActivated ? 'Activated' : 'Deactivated'}
          </span>
      </div>



<div className="flex justify-center items-center mt-4">
        <button
    className={`${
      isActivated ? 'bg-[#ef4444]' : 'bg-[#0f9d58]'
    } text-white text-base font-medium self-stretch max-md:max-w-full px-6 py-1 mt-6 rounded-md transition-colors duration-300`}
    onClick={(e) => handleToggle()}
  >
    {isActivated ? 'Deactivate' : 'Activate'}
  </button>
  
        </div>

    </div>
  </div>
  


  
      <div className="flex flex-col items-stretch w-1/3  max-md:w-full max-md:ml-0">
        <div className="flex flex-col items-stretch max-md:mt-10">
          <div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
            Project Name
          </div>
          <input
              className="border bg-white bg-opacity-30 self-stretch flex-shrink-0 h-[40px] w-4/5 flex-col mt-1 pl-2 pr-2 rounded-sm text-gray-600 text-[14px] border-black border-opacity-20"
              type="text"
              placeholder="Enter Project Name"
              value={projectName}
              onChange={(e) => handleChange(e, setProjectName)}
            />

  
{/*           <div className="flex flex-col space-y-4 md:flex-col border border-gray-300 w-4/5 mt-6 p-2 ">
            <div className=" flex justify-center text-black text-opacity-80 text-base">
             <p className='flex justify-center'> Num Blocks</p>
            </div>
  
  <div className="flex items-center justify-center inline-block text-left">
  <select className="border border-solid border-black rounded-sm" value={selectedValue} onChange={handleSelectChange}>
          <option value="" className="focus:text bg-transparent">
            Select
          </option>
  
          {[...Array(10)].map((_, index) => (
            <option key={index + 1} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>
  
  </div>
  
          </div> */}

<div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
            Site Address
          </div>
          <input
            className="border bg-white bg-opacity-30 self-stretch flex-shrink-0 w-4/5 flex-col mt-1 py-2 pl-2 pr-2 placeholder-text-sm placeholder-text-left rounded-sm text-gray-600 text-[14px] border-black border-opacity-20"
            type="text" placeholder="Enter site address" 
            value={siteAddress}
            onChange={(e) => handleChange(e, setSiteAddress)}
            />
<div>
        <div className="flex flex-row space-x-3 text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
           <div className='flex flex-row  space-x-2'> Add Location : </div>
        <button onClick={getLocation}><MdAddLocationAlt className='text-3xl border border-[#000000]'/></button>
        </div>
        <div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
            Latitude
          </div>
        <input
          className="border bg-white bg-opacity-30 self-stretch flex-shrink-0 w-4/5 flex-col mt-1 py-2 pl-2 pr-2 placeholder-text-sm placeholder-text-left rounded-sm text-gray-600 text-[14px] border-black border-opacity-20"
          type="text"
          value={inputLatitude}
          onChange={(e) => setInputLatitude(e.target.value)}
          placeholder="Latitude"
        />
                  <div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
            Longitude
          </div>
        <input
          className="border bg-white bg-opacity-30 self-stretch flex-shrink-0 w-4/5 flex-col mt-1 py-2 pl-2 pr-2 placeholder-text-sm placeholder-text-left rounded-sm text-gray-600 text-[14px] border-black border-opacity-20"
          type="text"
          value={inputLongitude}
          onChange={(e) => setInputLongitude(e.target.value)}
          placeholder="Longitude"
        />
      </div>


          <div>
          <div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
           Visit Notes
</div>
          <textarea
    className="border bg-white bg-opacity-30 w-4/5 border-opacity-20 text-[14px] text-gray-600 px-2"
    rows="3"
    value={notes}
    onChange={handleNotesChange}
    placeholder="Enter notes..."
/>
</div>
        </div>
      </div>
  
      <div className="flex flex-col items-stretch w-1/3 ml-0 max-md:w-full max-md:ml-0">
        <div className="flex flex-col items-stretch max-md:mt-10">
        <div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
            Builder Name
          </div>
          <input
            className="border bg-white bg-opacity-30 self-stretch flex-shrink-0 h-[40px] w-4/5 flex-col mt-1 pl-2 pr-2 rounded-sm text-gray-600 text-[14px] border-black border-opacity-20"
            type="text" placeholder=""
            value={builderName}
            onChange={(e) => handleChange(e, setBuilderName)}
            />
          <div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
            Builder Contact Number
          </div>
          <input
            className="border bg-white bg-opacity-30 self-stretch flex-shrink-0 h-[40px] w-4/5 flex-col mt-1 pl-2 pr-2 rounded-sm text-gray-600 text-[14px] border-black border-opacity-20"
            type="text" placeholder="" 
            value={builderContactNo}
            onChange={(e) => handleChange(e, setBuilderContactNo, true, 10,)}
            />
            <div className="flex flex-row m-4 justify-center items-center">
                  <div className="mr-2">
                    <button
                      onClick={handleCopyToBuilder}
                      className="text-lg text-gray-500 focus:outline-none"
                      disabled={!siteContactName || !siteContactNo}
                    >
                      <FontAwesomeIcon icon={faArrowUp} />
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={handleCopyToSiteContact}
                      className="text-lg text-gray-500 focus:outline-none"
                      disabled={!builderName || !builderContactNo}
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                    </button>
                  </div>
                </div>

          <div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
            Site Contact Name
          </div>
          <input
            className="border bg-white bg-opacity-30 self-stretch flex-shrink-0 w-4/5 flex-col mt-1 py-2 pl-2 pr-2 placeholder-text-sm placeholder-text-left rounded-sm text-gray-600 text-[14px] border-black border-opacity-20"
            type="text" placeholder="Enter site contact name" 
            value={siteContactName}
            onChange={(e) => handleChange(e, setSiteContactName)}
            />
          <div className="text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
            Site Contact Number
          </div>
          <input
            className="border bg-white bg-opacity-30 self-stretch flex-shrink-0 w-4/5 flex-col mt-1 py-2 pl-2 pr-2 placeholder-text-sm placeholder-text-left rounded-sm text-gray-600 text-[14px] border-black border-opacity-20"
            type="text" placeholder="Enter site contact number" 
            value={siteContactNo}
            onChange={(e) => handleChange(e, setSiteContactNo, true, 10,)}
            />

        </div>




  
        <div className="mt-5 max-md:max-w-full">
    <h2 className="text-base text-opacity-80">Visit History</h2>
    <div className="border bg-white bg-opacity-30 w-4/5 border-opacity-20 overflow-scroll no-scrollbar max-h-32">
        <ul>
            {visitHistory.map((visit, index) => {
                const visitDate = new Date(visit.visitedDate);
                const formattedVisitDate = `${visitDate.getFullYear()}-${(visitDate.getMonth() + 1).toString().padStart(2, '0')}-${visitDate.getDate().toString().padStart(2, '0')}`;
                return (
                    <li key={index} className="mb-2 h-16 px-2">
                        <div>{formattedVisitDate}</div>
                        <div>- {visit.notes}</div>
                    </li>
                );
            })}
        </ul>
    </div>
</div>



      </div>
  
    </div>
  </div>
  
  
  
  <div className="flex flex-row space-x-5 justify-center mr-20 ml-20 mt-1">
  {
    isProjectActive && (
      <div className="flex justify-center items-center mt-4">
        <button
          className="text-white text-opacity-100 text-base whitespace-nowrap bg-[#0f9d58] hover:bg-green-700 justify-center items-center px-6 py-1 mt-3 rounded-md self-center max-md:px-5 transition-colors duration-300"
          type="button"
          onClick={handleNextClick}
        >
          Next
        </button>
      </div>
    )
  }
</div>


  
  
  
  
  </div>
  </div>
  </div>
  )
}
export default UserViewProjectContainer;