import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function ContactPersons({ contactPersons, onChange, addContactPerson, removeContactPerson }) {
  const handleInputChange = (e, index, field) => {
    const updatedData = [...contactPersons];
    updatedData[index][field] = e.target.value;
    onChange(updatedData);
  };

  return (
    <div className="w-full mt-5">
      <div className="w-full">
        <div className="w-full flex flex-col justify-start items-center">
          <div className="w-full flex flex-col justify-start items-start">
            <div className="w-full container mx-auto px-4 mb-2">
              <table className="w-full">
                <thead>
                  <tr className="text-[#6c7184] bg-[#fafafc]">
                    <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Name</th>
                    <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Email</th>
                    <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Contact Info</th>
                    <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Designation</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {contactPersons.map((person, index) => (
                    <tr key={index}>
                      <td className="text-xs border">
                        <input
                          type="text"
                          value={person.name}
                          onChange={(e) => handleInputChange(e, index, 'name')}
                          className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                        />
                      </td>
                      <td className="text-xs border">
                        <input
                          type="text"
                          value={person.email}
                          onChange={(e) => handleInputChange(e, index, 'email')}
                          className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                        />
                      </td>
                      <td className="text-xs border">
                        <input
                          type="text"
                          value={person.mobile_number}
                          onChange={(e) => handleInputChange(e, index, 'mobile_number')}
                          className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                        pattern="\d{10}" 
            maxLength="10"
                        />
                      </td>
                      <td className="text-xs border">
                        <input
                          type="text"
                          value={person.designation}
                          onChange={(e) => handleInputChange(e, index, 'designation')}
                          className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                        />
                      </td>
                      <td className="px-2">
                        <button
                          onClick={() => removeContactPerson(index)}
                          className="text-red-500 hover:text-red-700 focus:outline-none"
                        >
                          <FontAwesomeIcon className='w-3 h-3' icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <button
                onClick={addContactPerson}
                className="text-blue-500 text-sm py-2 px-4"
              >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                Add Contact Person
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPersons;
