import React from 'react';

const formatNumber = (value) => {
  const parsedValue = parseFloat(value);
  if (Number.isFinite(parsedValue)) {
    const roundedValue = Math.round(parsedValue * 100) / 100;
    return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 });
  }
  return '0.00';
};

const VehicleSchedulingHtmlDocument = ({ schedule , vehicleSchdule }) => (
  <div className="p-8 flex flex-col" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
    <div className="flex justify-between mb-4 text-xs">
      <div className="flex-1">
        <h1 className="font-bold text-md">BrickBucket</h1>
        <p>Andhra Pradesh</p>
        <p>GSTIN 37AAKCK7567P1ZG</p>
        <p>info@brickbucket.com</p>
        <div className="mt-10">
          <h1 className=''></h1>
        </div>
        {/* <div className="mt-1">
          <h1 className="font-bold text-md">{schedule?.vehicle_number}</h1>
          <p>{schedule?.driver_name}</p>
          <p>{schedule?.driver_number}</p>
        </div> */}
      </div>
      
      <div className="flex-1 mt-2 text-right">
        <h1 className="text-2xl font-semibold mb-0">Vehicle Scheduling</h1>
        <h1 className="text-md mt-1 font-semibold mb-0">{vehicleSchdule?.schedule_number}</h1>
        <h1 className="text-sm mt-5 font-semibold">
          {new Date(vehicleSchdule?.schedule_date).toLocaleDateString()}
        </h1>
        {/* <h1 className="text-md mt-1 font-semibold mb-0">{schedule?.status}</h1> */}
      </div>
    </div>
    
    <div className="mb-4 p-2 text-xs">
      <table className="w-full border-collapse">
        <thead className='bg-[#3C3D3A] text-white p-2 h-12 text-left break-all'>
          <tr>
            <th className="text-center">#</th>
            <th className="">Sales Order NO</th>
            {/* <th className="">Customer Address</th> */}
            <th className="">Items</th>
            <th className="">Quantity</th>
            <th className="">UOM</th>
            <th className="">Weight (Ton) </th>
          </tr>
        </thead>
        <tbody>
  {schedule?.length > 0 ? (
    schedule.flatMap((order, orderIndex) =>
      order.order_items.map((item, itemIndex) => (
        <tr key={`${orderIndex}-${itemIndex}`} className='border-b p-2 h-12 text-left'>
          <td className="p-1 text-center">{orderIndex + 1}</td>
          <td className="p-1">{order.sales_order_number}</td>
          <td className="p-1">{item.item_name}</td>
          <td className="p-1">{item.quantity}</td>
          <td className="p-1">{item.UOM}</td>
          <td className="p-1">{(item.weight_in_kgs / 1000).toFixed(2)}</td>
        </tr>
      ))
    )
  ) : (
    <tr>
      <td colSpan="6" className="text-center p-2">No items available</td>
    </tr>
  )}
</tbody>

      </table>
    </div>

    <div className="flex justify-end mb-4">
      <div className="text-right p-2 text-xs">
        <div className="mb-2 flex justify-between space-x-20 p-2">
          <p className="text-sm font-semibold">Vehicle NO:</p>
          <p className="text-sm font-semibold">{vehicleSchdule?.vehicle_number}</p>
        </div>
        <div className="mb-2 flex justify-between space-x-20 p-2">
          <p className="text-sm font-semibold">Driver Name:</p>
          <p className="text-sm font-semibold">{vehicleSchdule?.driver_name}</p>
        </div>
        <div className="mb-2 flex justify-between space-x-20 p-2">
          <p className="text-sm font-semibold">Driver Mobile Number:</p>
          <p className="text-sm font-semibold">{vehicleSchdule?.driver_number}</p>
        </div>
        <div className="flex justify-between bg-[#F5F4F3] p-2">
          <p className="text-sm font-bold">Total Weight:</p>
          <p className="text-sm font-regular">{(formatNumber(vehicleSchdule?.total_weight_in_kgs/1000))} Ton</p>
        </div>
      </div>
    </div>

    <div className="mb-4 p-2 text-xs">
      <p className="font-regular"></p>
      <div className="mt-6 flex items-center">
        <h3 className="text-sm font-regular mr-4 flex-shrink-0">Authorized Signature</h3>
        <div className="flex-grow">
          <div className="border-t border-black" style={{ width: '150px', height: '1px', marginTop: '1rem' }}></div>
        </div>
      </div>
    </div>
  </div>
);

const VehicleSchedulingPdfViewer = ({ schedule , vehicleSchdule }) => (
  <div className="h-screen">
    <VehicleSchedulingHtmlDocument schedule={schedule} vehicleSchdule={vehicleSchdule} />
  </div>
);

export default VehicleSchedulingPdfViewer;
