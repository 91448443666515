import React, { useRef ,useState, useEffect, useCallback  } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle,faPaperclip, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import TableSearchDropDown from '../Components/TableSearchDropDownForSalesOrder';
import  '../Css/Tooltip.css';
import { useNavigate ,useLocation} from 'react-router-dom';
import { parse } from '@fortawesome/fontawesome-svg-core';
import * as XLSX from 'xlsx';


// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = React.useRef(null);

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option); // Pass the entire option object
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <div
                  key={option.value}
                  className="p-2 cursor-pointer text-sm hover:bg-blue-500 hover:text-white"
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EditPurchaseOrder = () => {
  const location = useLocation();
  const { PurchaseOrderID } = location.state || {};
  //console.log(PurchaseOrderID);
const navigate = useNavigate()
const dropdownRef = useRef(null); // Define the dropdownRef variable
  const [selectedQuoteType, setSelectedQuoteType] = useState(); // Initial value
  const [selectedTax, setSelectedTax] = useState();
  const [priceLists , setPriceLists] = useState([ ]);
  //console.log(priceLists);
  const[selectedPricList, setselectedPricList] = useState('');
  const [UomOptions , setUomOptions] = useState([]);
  const [AreaMeasurementsData, setAreaMeasurementsData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [purchaseorder, setPurchaseOrder] = useState([]);
  const [selectedVendor, setselectedVendor] = useState();
 // console.log(selectedVendor);
  const [quotationNumber, setQuotationNumber] = useState('');
  const [purchaseorderDate, setpurchaseorderDate] = useState(new Date());
  //console.log(purchaseorderDate);
  const [showDesignSelectPopup, setshowDesignSelectPopup] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [Summary , setSummary] =  useState({});
  const [selectedProductCategory, setSelectedProductCategory] = useState();
  console.log(selectedProductCategory)
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const FetchProjectsUrl = process.env.REACT_APP_FORE_APILINK; //measurit
  const Taxes = [{name:'GST' , value: 'GST'},{name:'IGST' , value: 'IGST'}]
  const [total, setTotal] = useState(0);
  const [amendedDiscount, setAmendedDiscount] = useState();
  const [amendedPrice, setAmendedPrice] = useState();
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowPricelists, setRowPricelists] = useState({});
  const [rows, setRows] = useState([{
    item_name: '',
    item_id: '',
    tax_amount: '',
    tax_rate: '',
    sales_orders: [
      {
        salesorder_id:'',
        so_number: '',
        quantity: '',
        receivable_qty: '',
        delivery_qty: '',
        weight: '',
        location: '',
        amount: ''
      }
    ]
  }]);
    const [products, setProducts] = useState([]); // assume products is set to one of the arrays you provided
  const [amounts, setAmounts] = useState([]);
  const [items, setItems] = useState([{ tilesArea: '', doorsArea: '', windowsArea: '' }]);
  const [tilesOptions, setTilesOptions] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [adjustment, setAdjustment] = useState('');
  const[ attributes, setAttributes]= useState([])
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const [insurance, setInsurance] = useState(0);
    const [SelectedItemRow,setSelectedItemRow] = useState()
    const [SelectedItemRowIndex ,setSelectedItemRowIndex] = useState()
    const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES; 
    const [searchTerm, setSearchTerm] = useState('');
    //console.log(selectedCategories);
    const [showDoorsAndWindowsBreakUpPopup, setShowDoorsAndWindowsBreakUpPopup] = useState(false);
    const [breakUp,setBreakUp] = useState([]);
    console.log(breakUp)
    const [BOMIds, setBOMIds] = useState([]);
    const isDoorsOrWindows = selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows';

    useEffect(() => {
      fetchAllVendors();
      handleGetAllUoms();
    }, [fetchUrl]);

    const fetchAllVendors = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + `vendor/get-all-vendors?organizationId=${sessionStorage.getItem('organizationId')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
       // console.log(output)
        if (output) {
          // Format data for dropdown
          const formattedVendors = output.map(vendor => ({
            id: vendor._id,  // Use vendor ID as value
            label: vendor.name, // Use vendor name as label
            value: vendor.name
          }));
          setVendors(formattedVendors); // Update state with formatted data
          //console.log(formattedVendors);
         fetchPurchaseOrder(formattedVendors);
        } else {
          if(response.status === 500){
            //console.log('retry enterd')
            
            setRetryFunction(() => () => fetchAllVendors); 
          }
          //console.error(data.message);
        }
      }  catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchAllVendors(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAllVendors(attempt)); // Set retry function
        }
      }finally {
        setIsLoading(false);
      }
    };
      
    const fetchPurchaseOrder = async (allvendors, attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + `customer/purchaseorder/get-purchaseorder-for-edit?purchaseorder_id=${PurchaseOrderID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
    
        if (output) {
          console.log(output);
          setPurchaseOrder(output); // Update state with formatted data
          setSelectedProductCategory(output.product_category_id?.product_category_name)
          if(selectedProductCategory === 'Doors' || 'Windows'){
          setBreakUp(output?.bomData?.[0]?.BOMData?.bill_of_materials || []);
          const bomIds = output?.BOM_ids || [];
          setBOMIds(bomIds);
        }
          console.log(output.BOM_ids);
    setpurchaseorderDate(output.purchaseorder_date);
    //console.log(output.purchaseorder_date);
          const selectedVendor = allvendors.find(vendor => vendor.id === output.vendor_id);
    console.log(output.items);
          // Flatten items for the table
          const transformedRows = output.items.map(item => ({
              _id: item._id, // Ensure this is unique or change as needed
              item_id: item.item_id,
              item_name: item.item_name,
              count:item?.count,
              cost_price: item.cost_price,
              UOM: item.UOM,
              quantity: item.quantity,
              sales_orders: item.salesorder_ids || [],
              orderitem_ids: item.orderitem_ids || [],
              receivable_quantity: item.receivable_quantity || 0, // Initialize as empty or with default value
              delivery_quantity: item.delivery_quantity || 0, // Initialize as empty or with default value
              shortfall_quantity: item.shortfall_quantity || 0, // Initialize as quantity for pending calculation
              excess_quantity: item.excess_quantity || 0, 
              weight: 0, // Initialize as empty or with default value
              amount: item.total, // Calculate amount
              tax_amount: item.tax_amount || 0, // Initialize as empty or with default value  
              tax_rate: item.tax_rate || 0, // Initialize as empty or with default value
            }))
    
          setRows(transformedRows);
          setselectedVendor(selectedVendor);
        } else {
          if (response.status === 500) {
            setRetryFunction(() => () => fetchPurchaseOrder(attempt + 1)); 
          }
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
    
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1);
          setTimeout(() => fetchPurchaseOrder(attempt + 1), 1000);
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchPurchaseOrder(attempt));
        }
      } finally {
        setIsLoading(false);
      }
    };
    
    


    const handleGetAllUoms = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        });
    
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        if (output) {
          // Log the data to ensure it's fetched correctly
          //console.log('Fetched uoms:',output);
    
          const uomData = output.map((item) => ({
            id: item._id,
            value: item.UOM,
            label: item.UOM,
          }));
    
      setUomOptions(uomData)
        } else {
          console.error('Error:', data.message);
          return ([])
        
         
        }
      }catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleGetAllUoms(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleGetAllUoms(attempt)); // Set retry function
        }
      }finally {
        setIsLoading(false);
      }
    };

   
  const handleDateChange = (date) => {
    setpurchaseorderDate(date);
  };

  const handleInputChange = (value, index, key, cost_price) => {
    const updatedData = [...rows];
    const updatedItem = { ...updatedData[index] };
  
    updatedItem[key] = value;
  
    const isDoorsOrWindows = selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows';
    const count = isDoorsOrWindows ? parseFloat(updatedItem.count) || 0 : parseFloat(updatedItem.quantity) || 0;
    const receivableCount = isDoorsOrWindows ? parseFloat(value) || 0 : parseFloat(updatedItem.receivable_quantity) || 0;
    const costPrice = parseFloat(cost_price) || 0;
  
    if (isDoorsOrWindows) {
      if (key === 'receivable_count') {
        if (receivableCount > count) {
          updatedItem.excess_count = receivableCount - count;
          updatedItem.shortfall_count = 0;
        } else {
          updatedItem.excess_count = 0;
          updatedItem.shortfall_count = count - receivableCount;
        }
        updatedItem.amount = receivableCount * costPrice;
      } else if (key === 'count') {
        updatedItem.shortfall_count = Math.max(0, count - receivableCount);
        updatedItem.excess_count = Math.max(0, receivableCount - count);
        updatedItem.amount = count * costPrice;
      }
    } else {
      // Existing logic for other categories
      if (key === 'receivable_quantity' || key === 'quantity') {
        if (updatedItem.receivable_quantity > updatedItem.quantity) {
          updatedItem.excess_quantity = updatedItem.receivable_quantity - updatedItem.quantity;
          updatedItem.shortfall_quantity = 0;
        } else {
          updatedItem.excess_quantity = 0;
          updatedItem.shortfall_quantity = updatedItem.quantity - updatedItem.receivable_quantity;
        }
        updatedItem.amount = (key === 'receivable_quantity' ? updatedItem.receivable_quantity : updatedItem.quantity) * costPrice;
      }
    }
  
    updatedData[index] = updatedItem;
    setRows(updatedData);
  };
  
  
  const handleSelectDesignPopup = (index) => {
    const selectedRow = rows[index];
    console.log(selectedRow)
    setSelectedItemRow(selectedRow)
    setSelectedItemRowIndex(index)
    //setSubItems(selectedRow?.sub_items); 
    setshowDesignSelectPopup(true);
    if(!products.length > 0) {
      rows[index].selectedDesigns = selectedRow?.sub_items
    }else{
      
    }
  };

  const handleClosePopup = () => {
    setshowDesignSelectPopup(false);
  }
  const addRow = () => {
    const newRow = {
      item_name: '',
      item_id: '',
      sales_orders: [
        {
          so_number: '',
          quantity: '',
          receivable_qty: '',
          delivery_qty: '',
          weight: '',
          location: '',
          amount: ''
        }
      ]
    };
    setRows([...rows, newRow]);
  };
  

  const deleteRow = (index) => {
    const updatedData = [...rows];
    updatedData.splice(index, 1);
    setRows(updatedData);
  };
  
  const handleItemNameChange = (index, selectedOption) => {
    const updatedRows = [...rows];
  const selectedItem = products.find(product => product.item_id === selectedOption.value)|| null;
    
    if (selectedItem) {
      updatedRows[index].item_id = selectedItem?.item_id
      updatedRows[index].item_name = selectedItem?.item_name
      updatedRows[index].cost_price = selectedItem?.cost_price
      updatedRows[index].item_image = selectedItem?.item_image
      updatedRows[index].selectedDesigns = [];
      const product = products.find(p => p.item_id === selectedItem.item_id)
      const selectedUom = UomOptions.find(p => p?.id === product?.UOM_id )
      //console.log(selectedUom)
      updatedRows[index].UOM = selectedUom?.value;
      updatedRows[index].UOM_id = selectedUom?.id;
      if(products.length > 0){
        updatedRows[index].sub_items = selectedItem.sub_items;
      }
      // If you want to set these values as well, uncomment the following lines
      // updatedRows[index].UOM_id = selectedItem.UOM_id
      // updatedRows[index].GST = selectedItem.GST
      // updatedRows[index].IGST = selectedItem.IGST
      // updatedRows[index].sub_items = selectedItem.sub_items
      handleInputChange(selectedItem.cost_price, index , 'price')
    
      setRows(updatedRows);
    }
  
  }

  const handleAmountInputChange = (e, index, fieldName) => {
    const { value } = e.target;
    
    // Assuming you have a state like `updatedRows` that holds your table data
    const updatedRows = [...rows]; 
  
    updatedRows[index] = {
      ...updatedRows[index],
      [fieldName]: value
    };
  
    // Set the updated rows back to state
    setRows(updatedRows);
  };


  const  [SubTotal,setSubTotal]= useState(0)

  useEffect(()=>{
    calculateSubtotal()
    calculateTax()
  },[rows])
  
  
  const calculateSubtotal = () => {
    let subtotal = 0;
  
    rows.forEach(row => {
      const quantity =  handleQuantityForPurchareOrderItem( parseFloat(row?.receivable_quantity || 0)  ,parseFloat(row?.quantity || 0))
      const price = parseFloat(row?.cost_price) || 0;
      const rowTotal = quantity * price;
      subtotal += rowTotal;
    });
   setSubTotal(subtotal)
    return subtotal;
  };

  const handleQuantityForPurchareOrderItem = (receivedQuantity , quantity) =>{
   if (receivedQuantity > 0){
    return receivedQuantity
   }else {
    return quantity
   }
  }
  const[totalTax,setTotalTax] = useState();  
  const calculateTax = () => {
    let TotalTax = 0;
  
    rows.forEach((p) => {
      console.log(p)
      const price = parseFloat(p.cost_price) || 0;  // Handle missing or invalid price
      const taxRate = parseFloat(p.tax_rate) || 0;  // Handle missing or invalid tax rate
      const quantity = handleQuantityForPurchareOrderItem( parseFloat(p?.receivable_quantity || 0)  ,parseFloat(p?.quantity || 0))
  console.log(taxRate)
  console.log(price)
  console.log(quantity)
      const tax = (taxRate / 100) * price;
      const SubTotalTax = tax * quantity;
  console.log(tax)
  console.log(SubTotalTax)
      TotalTax += SubTotalTax;
    });
  setTotalTax(TotalTax)
    return TotalTax;
  };
  
  useEffect(() => {
    const newTotalTax = calculateTax();
    setTotalTax(newTotalTax);
  }, [rows]); // Add any other dependencies that should trigger a recalculation
  
  const calculateTotal = () => {
    const subtotal = calculateSubtotal() || 0; // Get the subtotal from the existing function
    const discount = (amendedDiscount / 100) * subtotal || 0; // Calculate the discount based on the amendedDiscount percentage
    const tax_amount = calculateTax() || 0; // Calculate the tax amount using the existing function
    //console.log(tax_amount);
  
    const parsedAmendedPrice = parseFloat(amendedPrice) || 0; // Parse amendedPrice to ensure it's a valid number
    const insuranceAmount = parseFloat(insurance) || 0; // Parse insurance to ensure it's a valid number
    
    // Adjust the subtotal based on amended price
    const adjustedSubtotal = subtotal - discount + parsedAmendedPrice;
    
    let total = adjustedSubtotal + insuranceAmount; // Add insurance to the adjusted subtotal
    
    if (selectedTax) {
      if (selectedTax.value === 'Tax Exclusive') {
        total += tax_amount; // Add tax if it's exclusive
      }
      // For 'Tax Inclusive', no need to adjust since total is already calculated
    } else {
      // If selectedTax is not defined, assume tax is exclusive
      total += tax_amount;
    }
    
    return total;
  };
  
  useEffect(() => {
    const calculatedTotal = calculateTotal();
    setTotal(calculatedTotal);
  }, [rows, amendedDiscount, amendedPrice, selectedTax, insurance]);


  const handleTaxChange = (index, selectedOption, value, cost_price, quantity) => {
    //console.log(value)
    if (selectedOption) {
      const updatedRows = [...rows];
      updatedRows[index] = {
        ...updatedRows[index],
        tax: value, // Use the label as the key and value for the selected option
        tax_type: selectedOption?.label,
      };
      const totaltaxOfproduct = calculateTaxAmount(value, cost_price, quantity);
      updatedRows[index].total_tax_amount = totaltaxOfproduct;
      setRows(updatedRows);
    }
  };
  
  const calculateTaxAmount = (taxRate, cost_price, quantity) => {
    const tax = parseFloat(taxRate) || 0;
    const sellingPricePerQuantity = parseFloat(cost_price) || 0;
    const totalQuantity = parseFloat(quantity) || 0; // Ensure quantity is a number
    const taxAmount = parseFloat(((tax / 100) * sellingPricePerQuantity) * totalQuantity) || 0;
    return taxAmount;
  };

  const calculateTotalTaxAmount = () => {
    let totalTaxAmount = 0;
   
    if (Array.isArray(rows)) {
      rows.forEach((row) => {
        console.log(row)
        totalTaxAmount += row?.tax_amount || 0;
      });
    }
  
    return totalTaxAmount; // Update the total tax amount state
  };
  //console.log(rows)


  const calculateEachProductTotal = (discount, CostPrice, quantity ) => {
    // Ensure sellingPrice and quantity are numbers
    const price = parseFloat(CostPrice) || 0;
    const qty = parseFloat(quantity) || 0;
    
    // Calculate the initial total without discount
    let total = price * qty;
  
    // If the discount is provided and is a valid number, apply it
    if (discount && !isNaN(discount) && parseFloat(discount) > 0) {
      total = total * (1 - parseFloat(discount) / 100);
    }
    return total;
  };


  const handleTheItemsDataForUpdate = () => {
    return rows.map((p) => {
      const isDoorsOrWindows = selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows';
      const itemData = {
        item_name: p?.item_name,
        item_id: p?.item_id,
        UOM: p?.UOM || '',
        sales_orders: p?.sales_orders || [],
      };
  
      if (isDoorsOrWindows) {
        itemData.count = p?.count || 0;
        itemData.receivable_count = parseFloat(p?.receivable_count) || 0;
        itemData.shortfall_count = p?.shortfall_count || 0;
        itemData.excess_count = p?.excess_count || 0;
      } else {
        itemData.quantity = p?.quantity || 0;
        itemData.receivable_quantity = parseFloat(p?.receivable_quantity) || 0;
        itemData.shortfall_quantity = p?.shortfall_quantity || 0;
        itemData.excess_quantity = p?.excess_quantity || 0;
      }
  
      return itemData;
    });
  };
  
  
  const handleUpdatePurchaseOrder = async (attempt = 1) => {
    setIsSaving(true); 
    try {
      const purchaseorderData = {
        vendor_id: selectedVendor?.id || '',
        BOM_ids:BOMIds,
        subtotal: SubTotal,
        tax_amount: totalTax,
        total: total,

        discount: amendedDiscount,
        insurance: insurance,
        adjustment: amendedPrice,
      };
  
      const ItemsData = await handleTheItemsDataForUpdate();
  
      const purchaseOrderData = {
        purchaseOrder: purchaseorderData,
        orderItems: ItemsData,
        bill_of_materials:breakUp,
        organizationId:sessionStorage.getItem('organizationId'),
      };
  console.log(purchaseOrderData)
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}customer/purchaseorder/update-purchaseorder?purchaseorder_id=${PurchaseOrderID}`, {
        method: 'PUT', // Use 'PATCH' if your API uses that method
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(purchaseOrderData)
      });
  
      const data = await response.json();
      const output = await handleSaveResponse(response, data);
  
      if (output) {
        setTimeout(() => {
          setShowPopup(false);
        console.log(output);
        navigate('/bb/app/purchases/purchaseorderlist');
      }, 2000);
      }else{
        console.error(data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      // Retry logic in case of failure
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleUpdatePurchaseOrder(attempt + 1), 1000); // Retry after 1 second
      } else {
        // If max retries reached, show an error popup
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleUpdatePurchaseOrder(attempt)); // Set retry function
      }
    } finally {
      setIsSaving(false); // Ensure saving state is reset after attempt
    }
  };
  
  
  const handleSelectDoorsAndWindowsBreakUpPopup = (index,) => {
    setShowDoorsAndWindowsBreakUpPopup(true);
  };

  const handleCloseDoorsAndWindowsBreakUpPopup = () => {
    setShowDoorsAndWindowsBreakUpPopup(false);
  }

  const handleInsuranceChange = (e) => {
    const value = e.target.value;

    // Check if the entered value is a number
    if (!isNaN(value) || value === '') {
      setInsurance(value); // Update insurance state only if the input is a valid number
    }
  };

  const handleClose = () => {
    navigate(-1);
  }


  const handleSelectDesign = (design) => {
    const updatedRows = [...rows];
    const selectedDesigns = updatedRows[SelectedItemRowIndex].selectedDesigns || [];
  
    const designIndex = selectedDesigns.findIndex((d) => d.sub_item_id === design.sub_item_id);
  
    if (designIndex !== -1) {
      // Design is already in the array, remove it
      selectedDesigns.splice(designIndex, 1);
    } else {
      // Design is not in the array, add it
      selectedDesigns.push({ ...design, quantity: 1 }); // Default quantity set to 1 when adding
    }
  
    updatedRows[SelectedItemRowIndex].selectedDesigns = [...selectedDesigns];
    setRows(updatedRows); // Trigger a state update
  };
  
  
  const handleQuantityChangeForDesign = (subItemId, index, quantity) => {
    const updatedRows = [...rows];
    const selectedDesigns = updatedRows[SelectedItemRowIndex].selectedDesigns || [];
  
    updatedRows[SelectedItemRowIndex].selectedDesigns = selectedDesigns.map((item) =>
      item.sub_item_id === subItemId ? { ...item, sub_item_quantity: quantity } : item
    );
  
    setRows(updatedRows); // Trigger a state update
  };


  if (isLoading) {
    return <Loader />;
  }

  
  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleExportAsExcel = () => {
    if (Array.isArray(breakUp) && breakUp.length > 0) {
      const exportData = breakUp.map(row => {
        if (row && typeof row === 'object') {
          return [
            row.UIC, // UIC
            row.computedMeasurements.length, // Height
            row.computedMeasurements.breadth, // Width
            row.computedMeasurements.areaInSFT, // Area
            row.ItemName, // Item Code
            row.Installation
          ];
        } else {
          console.error('Invalid row data:', row);
          return [];
        }
      });
  
      const ws = XLSX.utils.aoa_to_sheet([
        ["UIC", "Height", "Width", "Area", "Item Code"], // Header row
        ...exportData // Data rows
      ]);
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "BreakUp");
  
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(data);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = 'break_up_data.xlsx';
      a.click();
  
      window.URL.revokeObjectURL(url);
    } else {
      alert('No data available to export');
    }
  };


const handleReceivableStatusChange = (e, index) => {
  const newStatus = e.target.value;
  setBreakUp(prevBreakUp => {
    const newBreakUp = [...prevBreakUp];
    newBreakUp[index].received_status = newStatus;
    if (newStatus === "order ready") {
      updateMainTable(newBreakUp[index]);
    }
    return newBreakUp;
  });
};

const updateMainTable = (breakUpItem) => {
  console.log("Updating main table with:", breakUpItem);
  setRows(prevRows => {
    return prevRows.map(row => {
      if (row.item_id === breakUpItem.BOM_item_id) {
        const isDoorsOrWindows = selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows';
        const newReceivableCount = isDoorsOrWindows ? 
          (row.receivable_count || 0) + 1 : 
          (row.receivable_quantity || 0) + 1;
        const newCount = isDoorsOrWindows ? row.count || 0 : row.quantity || 0;
        
        return {
          ...row,
          receivable_count: isDoorsOrWindows ? newReceivableCount : row.receivable_count,
          receivable_quantity: isDoorsOrWindows ? row.receivable_quantity : newReceivableCount,
          shortfall_count: isDoorsOrWindows ? Math.max(0, newCount - newReceivableCount) : row.shortfall_count,
          shortfall_quantity: isDoorsOrWindows ? row.shortfall_quantity : Math.max(0, newCount - newReceivableCount),
          excess_count: isDoorsOrWindows ? Math.max(0, newReceivableCount - newCount) : row.excess_count,
          excess_quantity: isDoorsOrWindows ? row.excess_quantity : Math.max(0, newReceivableCount - newCount)
        };
      }
      return row;
    });
  });
};

   
  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Edit Purchase Order</h1>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
              <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                     <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="purchaseorderDate" className="block text-sm font-regular text-gray-700 w-1/6">
                      Purchase Order No
                      </label>
                      <div className='w-1/6'>
                      <input
                  type="text"
                  value={purchaseorder?.purchaseorder_number}
                  readOnly
                  className="cursor-not-allowed bg-[#F9F9FB] w-full outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                  />
</div>
                    </div>            
                  </div>
                </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              </div>
                <div className="w-full flex flex-col justify-start items-start px-4 ">
                  <div className='w-full flex flex-row'>
                    <div className="flex flex-col w-full">
                      <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="customername" className="block text-sm font-regular text-[#e54643] w-1/6">
                          Vendor Name<span className="text-red-500">*</span>
                        </label>
                        <div className='w-1/3'>
                        <CustomDropdown
        options={vendors}
        selected={selectedVendor}
        //onSelect={handleVendorSelect}
        placeholder='Select Vendor'
        showAddNew={false}
        showSearch={true}
      />
      </div>
                      </div>
                     
                    </div>
                  </div>
                  </div>
                  <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                     <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="purchaseorderDate" className="block text-sm font-regular text-gray-700 w-1/6">
                        Date
                      </label>
                      <DatePicker
                        selected={purchaseorderDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        className="w-full outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                      />
                    </div>            
                  </div>
                </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              </div>
              <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
              

              <div className="w-full container mx-auto px-4 mb-2 mt-4">
  <div className='border border-gray-300 rounded-md bg-[#fafafc]'>
  <h2 className="text-lg font-semibold py-1 px-2">Item Table</h2>
  </div>
  <table className="w-full">
  <thead>
    <tr className="font-semibold">
      <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">Item Details</th>
      {selectedProductCategory && (selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows') && (
  <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">
    {selectedProductCategory} Count
  </th>
)}   
      <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Quantity</th>
      <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">UOM</th>
      {selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows' ? (
  <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">
    {selectedProductCategory} Receivable Count
  </th>
) : (
  <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">
    Receivable Qty
  </th>
)}

{selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows' ? (
  <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">
    {selectedProductCategory} Shortfall Count
  </th>
) : (
  <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">
    Shortfall Qty
  </th>
)}

{selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows' ? (
  <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">
    {selectedProductCategory} Excess Count
  </th>
) : (
  <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">
    Excess Qty
  </th>
)}

      {/* <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Weight</th> */}
      <th className="font-semibold border-b border-r text-left text-sm py-2 px-2">Amount</th>
    </tr>
  </thead>
  <tbody>
    {rows.map((item, index) => (
      <tr key={index}>
        <td className="border-r border-b px-2 py-2" ref={dropdownRef}>
          <TableSearchDropDown
            options={products.map(product => ({
              value: product.item_id,
              label: product.item_name,
            }))}
            value={item?.item_name}
            onChange={(selectedOption) => handleItemNameChange(index, selectedOption)}
          />
        </td>
        {selectedProductCategory && ['Doors', 'Windows'].includes(selectedProductCategory) && (
  <td className="border-r border-b px-2 py-2">
    <input
      type="number"
      value={item?.count || ''}
      onChange={(e) => handleInputChange(e.target.value, index, 'count', products.find(p => p.item_id === item?.item_id)?.count)}
      className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
      min="0"
    />
  </td>
)}

        <td className="border-r border-b px-2 py-2">
          <input
            type="text"
            value={item?.quantity}
            onChange={(e) => handleInputChange(e.target.value, index, 'quantity', products.find(p => p.item_id === item?.item_id)?.cost_price)}
            className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
          />
        </td>
        <td className="border-r border-b px-1 py-1">
          <input
            type="text"
            value={item?.UOM}
            readOnly
            className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
          />
        </td>
        <td className="border-r border-b px-2 py-2">
            <input
              type="text"
              value={isDoorsOrWindows ? (item?.receivable_count || '') : (item?.receivable_quantity || '')}
              onChange={(e) => handleInputChange(e.target.value, index, isDoorsOrWindows ? 'receivable_count' : 'receivable_quantity', item.cost_price)}
              className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
            />
          </td>
          <td className="border-r border-b px-2 py-2">
            <input
              type="text"
              value={isDoorsOrWindows ? (item?.shortfall_count || '') : (item?.shortfall_quantity || '')}
              readOnly
              className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
            />
          </td>
          <td className="border-r border-b px-2 py-2">
            <input
              type="text"
              value={isDoorsOrWindows ? (item?.excess_count || '') : (item?.excess_quantity || '')}
              readOnly
              className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
            />
          </td>

       {/*  <td className="border-r border-b px-2 py-2">
          <input
            type="text"
            value={item?.weight || ''}
            onChange={(e) => handleInputChange(e.target.value, index, 'weight')}
            className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
          />
        </td> */}
        <td className="border-r border-b py-2">
          <input
            type="text"
            value={  item?.amount ||  item?.cost_price * (item.receivable_qty > 0 ? item.receivable_qty : item?.quantity || 0) }
            //readOnly
            className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
          />
        </td>
        <td className="border-b px-2">
          <button
            type="button"
            onClick={() => deleteRow(index, rows)}
            className="text-red-500"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

</div>
              </div>
              </div>
              </div>
              <div className='pt-2 px-6 py-6 flex flex-row justify-between w-full'>
              <div class="flex flex-col">
                <div><button  onClick={addRow} className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'><FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1"  />
                Add New Row</button></div> 
                {(selectedProductCategory && (selectedProductCategory === 'Doors' || selectedProductCategory === 'Windows')) && (
                <div>
                  {console.log(selectedProductCategory)}
                <button
                  className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md mt-10"
                  onClick={handleSelectDoorsAndWindowsBreakUpPopup}
                >
                  <FontAwesomeIcon icon={faPaperclip} /> attachment
                </button>
                </div>
                )}
                </div>
                <div className='p-6 space-y-6 flex flex-col justify-between rounded-md w-1/2 bg-[#F7F7FE]'>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm font-semibold'>Sub Total</p>
                </div>
                <div><p className='text-sm font-semibold'>{SubTotal.toFixed(2)}</p></div>
                </div>
                <div className='flex justify-between'>
                <div className='flex flex-row items-center'>
  <p className='text-sm'>Discount</p>
  <div className='flex justify-end w-full relative'>
  <input 
    type="text"
    value={discountPercentage}
    onChange={(e) => {
      const currentValue = e.target.value;
      const newValue = currentValue.replace(/[^0-9]/g, ''); // remove non-numeric characters
      setDiscountPercentage(newValue);
      setAmendedDiscount(newValue);
    }}
    className='px-2 text-right border border-gray-300 outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 py-1 w-1/3 border-r-0 rounded-l-md'
  />
  <span className='bg-gray-200 p-2 text-sm border border-gray-300 border-l-0 rounded-r-md'>%</span>
</div>
</div>
<div>
  <p className='text-sm '>
    <span className='mr-1 font-bold text-xl text-red-500'>-</span>
    {Number.isFinite(parseFloat(SubTotal)) && Number.isFinite(parseFloat(amendedDiscount)) ? parseFloat(SubTotal) * (parseFloat(amendedDiscount / 100)) : 0}
  </p>
</div>

                </div>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm '>Tax</p>
                </div>
                <div><p className='text-sm font-semibold'>{totalTax}</p></div>
                </div>
                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                <p className='text-sm '>Adjustment</p>
                <div className='flex justify-end w-full relative'>
                <input
  type="text"
  value={amendedPrice}
  onChange={(e) => {
    const regex = /^[-+]?[0-9]*$/; // allow optional - or +, followed by digits
    if (regex.test(e.target.value)) {
      setAmendedPrice(e.target.value);
    }
  }}
  className='px-2 w-[50%] text-right py-1 w-2/3 outline-blue-500 border border-gray-300 rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
/>
  <button 
  className="mr-2 text-white px-1 py-0.5 rounded-md" 
  title="" info-tooltip="Add any other +ve or -ve value that need to be applied to adjust the total amount of the transaction Eg. +10 or -10."  style={{ '--tooltip-bg': 'black', 'zIndex': "10px" }}>
  <FontAwesomeIcon icon={faQuestionCircle}  className="word-break break-words mb-0.5 text-xs text-gray-500 mx-1 cursor-pointer" />
</button>
</div>


                </div>
                <div><p className='text-sm '>{amendedPrice ? amendedPrice : '0.00'}</p></div>
                </div>
                <div className='flex justify-start space-x-24 w-3/4 relative'>
      <label htmlFor="insurance" className="block text-sm font-regular">
        Insurance
      </label>
      <input
        type="text"
        id="insurance"
        value={insurance}
        onChange={handleInsuranceChange}
        className='px-2 text-right py-1 w-1/3 outline-blue-500 border border-gray-300 rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
        placeholder="Enter insurance amount"
      />
    </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                <p className='text-lg font-semibold '>Total ( ₹ )</p>
                </div>
                <div><p className='text-lg font-semibold'>{(total).toFixed(2)}</p></div>
                </div>
                </div>
                </div>
              </div>
              <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
          onClick={handleUpdatePurchaseOrder} >
            Save {isSaving && <Spinner />}
            </button>
            {/* <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
           onClick={handleSubmitUomData} >
            Save and Send  {isSaving && <Spinner />} 
            </button> */}
          <button  onClick={handleClose}  className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>

{/* Popup starts */}
      {showDesignSelectPopup && (
      <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col">
        <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
            <h2 className="text-md">Select Design</h2>
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">   
              <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer" />
            </button>
          </div>
          <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />

          {/* <div className="flex flex-wrap p-4 overflow-auto">
            {sampleDesigns.map(design => (
              <div key={design.id} className="w-1/3 p-2">
                <div className={`border rounded-md p-2 ${isSelected(design.id) ? 'border-blue-500' : 'border-gray-300'}`}>
                  <img src={design.imageUrl} alt={design?.name} className="w-full h-32 object-cover rounded-md" />
                  <h3 className="text-center mt-2">{design?.name}</h3>
                  <div className="flex items-center justify-center mt-2">
                    <input 
                      type="checkbox"
                      checked={isSelected(design.id)}
                      onChange={() => handleSelectDesign(design)}
                    />
                    <span className="ml-2">Select</span>
                  </div>
                  {isSelected(design.id) && (
                    <div className='flex justify-between items-center'>
                    <label htmlFor="quantity" className="mt-2 block text-sm font-regular text-center text-gray-700 ">
                      Quantity :
                    </label>
                    <input 
                      type="number" 
                      value={selectedDesigns.find(item => item?.id === design?.id).quantity} 
                      onChange={(e) => handleQuantityChange(design?.id, parseInt(e.target.value) || 0)}
                      className="mt-2 w-1/2 p-1 border rounded-md"
                      min="1"
                    />
                  </div>
                  )}
                </div>
              </div>
            ))}
          </div> */}
          <div className="flex flex-wrap p-4 overflow-auto">
    {SelectedItemRow?.sub_items && SelectedItemRow?.sub_items
      .filter((design) =>
        design.sub_item_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((design) => {
        const isSelected = rows[SelectedItemRowIndex]?.selectedDesigns?.some(
          (d) => d.sub_item_id === design.sub_item_id
        );

        return (
          <div key={design.id} className="w-1/3 p-2">
            <div
              className={`border rounded-md p-2 ${
                isSelected ? "border-blue-500" : "border-gray-300"
              }`}
            >
              <img
                src={imageUrl + design?.sub_item_image}
                alt={design?.sub_item_image}
                className="w-full h-32 object-cover rounded-md"
              />
              <h3 className="text-center mt-2">{design?.sub_item_name}</h3>
              <div className="flex items-center justify-center mt-2">
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => handleSelectDesign(design)}
                />
                <span className="ml-2">Select</span>
              </div>
              {isSelected && (
                <div className="flex justify-between items-center">
                  <label
                    htmlFor={`quantity-${design.id}`}
                    className="mt-2 block text-sm font-regular text-center text-gray-700"
                  >
                    Quantity:
                  </label>
                  <input
                    type="number"
                    id={`quantity-${design.id}`}
                    value={
                      rows[SelectedItemRowIndex]?.selectedDesigns?.find((d) => d.sub_item_id === design.sub_item_id)?.sub_item_quantity || ''
                    }
                    onChange={(e) =>
                      handleQuantityChangeForDesign(
                        design.sub_item_id,
                        design.id,
                        parseInt(e.target.value, 10)
                      )
                    }
                    className="w-1/3 p-1 border rounded-md"
                    min="1"
                  />
                  {SelectedItemRow?.UOM}
                </div>
              )}
            </div>
          </div>
        );
      })}
  </div>


          <div className="mt-auto w-full">
            <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
            <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
              <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md">
                Save
              </button>
              <button onClick={handleClosePopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )}

{showDoorsAndWindowsBreakUpPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-3/4 h-2/3 flex flex-col">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
        <h2 className="text-md flex justify-center">Break Up of {selectedProductCategory.label}</h2>
      </div>

      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />

      {/* Table to display dynamic data */}
      <div className="flex-grow overflow-auto p-6">
        {Array.isArray(breakUp) && breakUp.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UIC</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Height</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Width</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Area</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Receivable Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {breakUp.map((row, index) => (

                <tr key={index}>
                  {console.log(row)}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row?.UIC}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.computedMeasurements?.length}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.computedMeasurements?.breadth}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.computedMeasurements?.areaInSFT}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row?.itemName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <select
                value={row.received_status}
                onChange={(e) => handleReceivableStatusChange(e, index)}
                className="w-full p-1 border rounded text-sm"
              >
                <option value="pending">pending</option>
                <option value="received">received</option>
                <option value="order ready">order ready</option>
              </select>
            </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="px-6 py-4 text-sm text-gray-500">No data available</p>
        )}
      </div>
      <div className='flex justify-end m-5'>
        <button className="px-2 py-1 bg-[#f5f5f5] text-red-700 border border-[#DDDDDD] rounded-md mt-10" onClick={handleExportAsExcel}>Export As Excel</button>
      </div>

      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button onClick={handleCloseDoorsAndWindowsBreakUpPopup} className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md">Done</button>
          <button onClick={handleCloseDoorsAndWindowsBreakUpPopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
      </div>
    </div>
  </div>
)}   

{showPopup && (
        <div>
           {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
           {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
      {/* Popup ends */}
    </div>
  );
};

export default EditPurchaseOrder;
