import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faEllipsisV, faTimes ,faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../BBAdmin/Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from './Messages/Messages';
import ProjectReports from '../measurit/ProjectSummary/ProjectReports';







const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const navigate = useNavigate();
  const [isSaving , setisSaving] = useState(false);
  const [isMeasurementsNotify, setMeasurementsNotify] = useState(false); 
  const [isAdvancePaymentNotify, setAdvancePaymentNotify] = useState(false); 
  const [isProductionMeasurementsNotify, setProductionMeasurementsNotify] = useState(false); 
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const backendApiUrl = process.env.REACT_APP_FORE_APILINK;
  const GetUsers = process.env.REACT_APP_FORE_APILINK + `/admin/get-all-users-details?organizationId=${sessionStorage.getItem('organizationId')}`;
  const AssignMAToProject = process.env.REACT_APP_FORE_APILINK + '/admin/assign-project-to-marketingassociate';
  const AssignFAToProject = process.env.REACT_APP_FORE_APILINK + '/admin/assign-project-to-fieldassociate';
  const url = process.env.REACT_APP_FORE_APILINK + `/projects/update-project?projectId=${selectedProjectId}`;
  const [users, setUsers] = useState([]);
  const [ProjectUserId, setProjectUserId] = useState();
  const [AssignMarketingAssociate, setAssignUser] = useState(false);
  const [AssignFieldAssociate, setAssignFieldAssociate] = useState(false);
  const [UserAssignedtoProjectAlert, setUserAssignedtoProjectAlert] = useState(false);
  const [noSelectAssignUseralert, setnoSelectAssignUseralert] = useState(false);
  const [selectedproject, setselectedproject] = useState();
  const [selectedUser, setselectedUser] = useState();
  const [rescheduleDate, setRescheduleDate] = useState({});
  const [DatePopup, setDatePopup] = useState(false);
  const [CollectPopUp , setCollectPopUp] = useState(false);
  const [NotifyPopUp , setNotifyPopUp] = useState(false);
  const [displayedProjects, setdisplayedProjects] = useState([]);
  const [currentProject ,setcurrentProject] = useState();
  const [date,setDate] = useState('');
  const [itemsPerPage] = useState(5); // Adjust the number of items per page as needed
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = displayedProjects?.slice(indexOfFirstItem, indexOfLastItem) || [];
  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentProjects = projects.slice(startIndex, endIndex);
    const totalPages = Math.ceil(displayedProjects?.length / itemsPerPage) || 1;
    const [purposeOfVisit, setPurposeOfVisit] = useState('');
    const [showSummaryPopup, setShowSummaryPopup] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    console.log(selectedProject);
    const [measurementStatus, setMeasurementStatus] = useState({
      quotation: selectedProject?.quotationMeasurementStatus,
      production: selectedProject?.productionMeasurementStatus
    });

    const [showPurposePopup, setShowPurposePopup] = useState(false);
const [selectedPurpose, setSelectedPurpose] = useState('');


  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...projects].sort((a, b) => {
      const aValue = a[key] || ''; // Treat undefined as empty string
      const bValue = b[key] || ''; // Treat undefined as empty string

      // Convert to string for comparison and handle case insensitivity
      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();

      if (aString < bString) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aString > bString) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setProjects(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
    }
    return null;
  };

  const userData = JSON.parse(sessionStorage.getItem('userData'));

  const filterProjects = (projects) => {
    if (!userData || (userData.roleID !== 2 && userData.roleID !== 3)) {
      return projects;
    }

    return projects.filter(project => {
      if (userData.roleID === 2) {
        return project.marketingAssociateId === userData.userId;
      } else if (userData.roleID === 3) {
        return project.fieldAssociateId === userData.userId;
      }
    });
  };
  
  const fetchProjects = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const GetProjects = `${backendApiUrl}/projects/get-all-projects?organizationId=${sessionStorage.getItem('organizationId')}`;
      
      const response = await fetch(GetProjects, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-token': token
        },
      });
  
      const data = await response.json();
  
      if (response.ok) {
        const projectsData = data;
        const filteredProjects = filterProjects(data);
        setProjects(filteredProjects);
        console.log(data);
        setdisplayedProjects(projectsData || []);
        return data;
      } else if (response.status === 500) {
        setShowPopup(true);
        setRetryFunction(() => () => fetchProjects());
        setPopupType("500");
        setPopupMessage(data.message || "Internal Server Error");
      } else {
        setPopupMessage(data.message || "Failed to fetch projects");
        setPopupType(`${response.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch projects:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchProjects(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage("Internal Server Error. Kindly Retry.");
        setPopupType("500");
        setShowPopup(true);
        setRetryFunction(() => () => fetchProjects(attempt));
      }
    }
  
    return [];
  };

  useEffect(() => {
    fetchProjects();
    fetchUsers();
  }, []);

      const closePopup = () => {
        console.log("closePopup called");
        setShowPopup(false);
      };

      const Retry = async () => {
        console.log("Retry called");
        setIsLoading(true); // Show loader
        setTimeout(async () => {
          if (retryFunction) {
            await retryFunction();
          }
          setIsLoading(false); // Hide loader after retry
        }, 5000); // 5-second delay
        setShowPopup(false);
      };
      
      const BackToLoginPage = () => {
        console.log("Back To Login Page");
        setShowPopup(false);
        localStorage.removeItem('LoginData');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('appId');
      navigate('/bb');
      };

      
      

  const handleNavigateProjectDetails = (project)=>{
    // console.log(project)
    navigate('/bb/app/projects/projectdetails', {state:{projectData: project}} );
  }

  const handleNavigatetoMeasurements = (project, measurementsType) => {
    if (!project || !measurementsType) {
      console.error('Invalid project or measurementsType');
      return;
    }
  
    const data = {
      projectData: project,
      measurementsType: measurementsType,
    };
  
    navigate('/bb/app/projects/measurements', { state: { projectData: data } });
  };

  const handleNewProject = () => {
    navigate('/bb/app/projects/newproject');
  };

  const fetchUsers = async () => {         
    try{
     const token = sessionStorage.getItem('token');
      const response = await fetch(GetUsers,{
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', // Set the content type to JSON
          'x-token':token
        },
      })
      if(response.ok){
        const usersData = await response.json();
        setUsers(usersData);
        console.log(usersData);
        fetchProjects();
      }
      else {
      }
    }
    catch (error){
    }
  };
  
  const handleAssignprojecttoMarketingAssociate = async(user) => {      
    const selectedUsers = users.filter((user) => user.isSelected);
    if (selectedUsers.length === 0) {
     setnoSelectAssignUseralert(true);
      return;
    }
    const userId = selectedUsers.map((user) => user._id);

    const data = {
      projectId: selectedproject._id,
      userId: userId
    };
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(AssignMAToProject,{
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json', // Set the content type to JSON
        'x-token':token
      },

      body: JSON.stringify(data),
    })
     if (response.ok) {
      setselectedUser(user);
      setAssignUser(false);
      setAssignFieldAssociate(false);
      setUserAssignedtoProjectAlert(true);
      
      //alert('Users assigned to project successfully');

    } else {
    }
  } catch (error) {
  }
};

  const handleAssignprojecttoFieldAssociate = async(user) => {      
    const selectedUsers = users.filter((user) => user.isSelected);
    if (selectedUsers.length === 0) {
     setnoSelectAssignUseralert(true);
      return;
    }
    const userId = selectedUsers.map((user) => user._id);

    const data = {
      projectId: selectedproject._id,
      userId: userId
    };
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(AssignFAToProject,{
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json', // Set the content type to JSON
        'x-token':token
      },

      body: JSON.stringify(data),
    })
     if (response.ok) {
      setselectedUser(user);
      setAssignUser(false);
      setAssignFieldAssociate(false);
      setUserAssignedtoProjectAlert(true);
      
      //alert('Users assigned to project successfully');

    } else {
    }
  } catch (error) {
  }
};

const handleCheckboxChange = (userId) => {
  setProjectUserId(userId)
  setUsers((prevUsers) =>
    prevUsers.map((User) =>
    User._id === userId ? { ...User, isSelected: !User.isSelected } : User
    )
  );
};

  const handleAssignMarketingAssociate = async(Project)=>{
    setAssignUser(true);
    setselectedproject(Project);
    setProjectUserId(Project.marketingAssociateId);
   };

   const handleAssignFieldAssociate = async(Project)=>{
    setAssignFieldAssociate(true);
    setselectedproject(Project);
    setProjectUserId(Project.fieldAssociateId);
   };

   const handleClose = () => {
     setAssignUser(false);
     setAssignFieldAssociate(false);
     setCollectPopUp(false);
     setNotifyPopUp(false);
     setShowPurposePopup(false);
   }

   const handleClosenoAssigneUser = () =>{
    setnoSelectAssignUseralert(false);
  }

   const handleUserAssignedtoProjectAlert = async () => {
    setUserAssignedtoProjectAlert(false);
    window.location.reload();
   }

   const handleCloseDate = () => {
    setDatePopup(false);
   // window.location.reload();
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    // Set only the selected date for the specified project ID
    setRescheduleDate(selectedDate);
  };

  const handlePurposeChange = (e) => {
    setPurposeOfVisit(e.target.value);
  };

  const handleSubmit = async () => {
    setisSaving(true);
    try {
      const token = sessionStorage.getItem('token');
      const date = rescheduleDate;
      console.log(date);
  
      const formData = new FormData();
      formData.append('rescheduleDate', date);
      formData.append('purposeOfVisit', purposeOfVisit);
  
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'x-token': token
        },
        body: formData
      });
  
      const data = await response.json();
      console.log(data);
      setDatePopup(false);
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project._id === selectedProjectId 
            ? { ...project, rescheduleDate: date, purposeOfVisit: purposeOfVisit }
            : project
        )
      );
      
    const statusMessages = {
      400: 'Bad Request',
      401: 'Unauthorized',
      403: 'Forbidden',
      404: 'Project not found',
      500: 'Internal Server Error'
    };

    setMeasurementsNotify(false);
    setCollectPopUp(false);
    
    if (response.ok) {
      setPopupMessage('email sent successfully');
      setPopupType('200or201');
    } else {
      setPopupMessage(data.message || statusMessages[data.status] || 'An unexpected error occurred');
      setPopupType(data.status.toString());
    }
    
    setShowPopup(true);
    
    if (response.ok) {
      setTimeout(() => {
        setShowPopup(false);
        setPopupMessage('');
        setPopupType('');
      }, 2000);
    }
    } catch (error) {
      console.error('Error rescheduling project:', error);
      setPopupMessage('Failed to reschedule project');
      setPopupType('500');
      setShowPopup(true);
    } finally {
      setisSaving(false);
    }
  };
  

  const handleOpenDate = (projectId,date,pov) => {
    console.log(projectId)
    console.log(date)
    setDatePopup(true);
    setDate(date); 
    setPurposeOfVisit(pov);
    setSelectedProjectId(projectId);
  };

const handleCollect = async (project) => {
  setCollectPopUp(true);
  setcurrentProject(project);
};
    const handleCollectMeasurements = async (project) => {
      setMeasurementsNotify(true);
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${backendApiUrl}/admin/send-collect-measurements-notification-to-field-associate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          },
          body: JSON.stringify({
            projectId: project._id,
            projectName: project.projectName,
            fieldAssociateId: project.fieldAssociateId
          })
        });
        const data = await response.json();
        console.log(data);

        const statusMessages = {
          200: 'Success',
          400: 'Bad Request',
          401: 'Unauthorized',
          403: 'Forbidden',
          404: 'Not Found',
          500: 'Internal Server Error'
        };

        setCollectPopUp(false);
        setPopupMessage(data.message || statusMessages[data.status] || 'An unexpected error occurred');
        setPopupType(data.status === 200 ? '200or201' : data.status.toString());
        setShowPopup(true);

        if (data.status === 200) {
          setTimeout(() => {
            setShowPopup(false);
            setPopupMessage('');
            setPopupType('');
          }, 2000);
        }
      } catch (error) {
        console.error('Error sending notification:', error);
        setPopupMessage('Failed to send notification');
        setPopupType('500');
        setShowPopup(true);
      } finally {
        setMeasurementsNotify(false);
      }
    };

    
  const handleCollectAdvancePayment = async (project) => {
    setAdvancePaymentNotify(true);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}/admin/send-collect-advance-payment-notification-to-field-associate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({
          projectId: project._id,
          projectName: project.projectName,
          fieldAssociateId: project.fieldAssociateId
        })
      });
      const data = await response.json();
      console.log(data);

      const statusMessages = {
        200: 'Success',
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        500: 'Internal Server Error'
      };
      setCollectPopUp(false);
      setPopupMessage(data.message || statusMessages[data.status] || 'An unexpected error occurred');
      setPopupType(data.status === 200 ? '200or201' : data.status.toString());
      setShowPopup(true);

      if (data.status === 200) {
        setTimeout(() => {
          setShowPopup(false);
          setPopupMessage('');
          setPopupType('');
        }, 2000);
      }
    } catch (error) {
      console.error('Error sending notification:', error);
      setPopupMessage('Failed to send notification');
      setPopupType('400');
      setShowPopup(true);
    }finally{
      setAdvancePaymentNotify(false);
    }
  };

  const handleCollectProductionMeasurements = async (project) => {
    setProductionMeasurementsNotify(true);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}/admin/send-collect-production-measurements-notification-to-field-associate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({
          projectId: project._id,
          projectName: project.projectName,
          fieldAssociateId: project.fieldAssociateId
        })
      });
      const data = await response.json();
      console.log(data);

      const statusMessages = {
        200: 'Success',
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        500: 'Internal Server Error'
      };
      setCollectPopUp(false);
      setPopupMessage(data.message || statusMessages[data.status] || 'An unexpected error occurred');
      setPopupType(data.status === 200 ? '200or201' : data.status.toString());
      setShowPopup(true);

      if (data.status === 200) {
        setTimeout(() => {
          setShowPopup(false);
          setPopupMessage('');
          setPopupType('');
        }, 2000);
      }
    } catch (error) {
      console.error('Error sending notification:', error);
      setPopupMessage('Failed to send notification');
      setPopupType('400');
      setShowPopup(true);
    }finally {
      setProductionMeasurementsNotify(false);
    }
  };

  const handleNotify = async (project) => {
    setNotifyPopUp(true);
    setcurrentProject(project);
  };

  const handleSendMeasurementsLink = async (project) => {
    setisSaving(true);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}/admin/send-collected-measurements-notification-to-customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({
          projectId: project._id,
        })
      });
      const data = await response.json();
      console.log(data);

      const statusMessages = {
        200: 'Success',
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        500: 'Internal Server Error'
      };
      setNotifyPopUp(false);
      setPopupMessage(data.message || statusMessages[data.status] || 'An unexpected error occurred');
      setPopupType(data.status === 200 ? '200or201' : data.status.toString());
      setShowPopup(true);

      if (data.status === 200) {
        setTimeout(() => {
          setShowPopup(false);
          setPopupMessage('');
          setPopupType('');
        }, 2000);
      }
    } catch (error) {
      console.error('Error sending notification:', error);
      setPopupMessage('Failed to send notification');
      setPopupType('400');
      setShowPopup(true);
    }finally {
      setisSaving(false);
    }
  };

  const handleSummaryClick = (project) => {
    console.log('Summary clicked for project:', project); 
    setSelectedProject(project);
    setShowSummaryPopup(true);
  };

  const handleQuoteSummary = () => {
    setSelectedProject({...selectedProject, type: 'quotation'});
  };
  
  const handleProductionSummary = () => {
    setSelectedProject({...selectedProject, type: 'production'});
  };
  
  const handleComplete = async () => {
    const url = `${backendApiUrl}/projects/update-project-measurements-status?projectId=${selectedProject._id}`;
  
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': sessionStorage.getItem('token')
        },
        body: JSON.stringify({
          measurementType: selectedProject.type,
          status: 'completed'
        })
      });
  
      if (response.ok) {
        const updatedProject = await response.json();
        setMeasurementStatus({
          quotation: updatedProject.quotationMeasurementStatus,
          production: updatedProject.productionMeasurementStatus
        });
  
        setSelectedProject(prevProject => ({
          ...prevProject,
          [selectedProject.type === 'quotation' ? 'quotationMeasurementStatus' : 'productionMeasurementStatus']: 'Completed'
        }));
  
        setProjects(prevProjects => prevProjects.map(project => 
          project._id === selectedProject._id 
            ? { 
                ...project, 
                [selectedProject.type === 'quotation' ? 'quotationMeasurementStatus' : 'productionMeasurementStatus']: 'Completed'
              }
            : project
        ));
  
        setShowSummaryPopup(false);
      }
    } catch (error) {
      console.error('Error updating project measurement status:', error);
    }
  };
  
  return (
    <div className="w-full h-full flex flex-col justify-between ">
<div className="w-full h-16 border-b flex items-center">
  <div className="flex w-full justify-between px-4 items-center">
    <h1 className="text-lg font-bold">Projects</h1>

    <div className="flex items-center space-x-2">
      <input
        type="text"
        placeholder="Search..."
       className="border rounded px-2 py-1 w-40"
       value={searchQuery}
       onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>

    <button
      className="bg-[#f7525a] text-white px-2 py-1 rounded"
      onClick={handleNewProject}
      title=""
      style={{ '--tooltip-bg': 'black' }}
    >
      <span className="md:inline hidden">+ New</span>
      <span className="md:hidden">+</span>
    </button>
  </div>
</div>

    <div className="w-full h-full bg-white rounded-xs overflow-hidden">
      <div className="overflow-y-auto h-[80vh]">
        <table className="w-full mb-5">
          <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
            <tr className="border-b">
            <th className="py-2 cursor-pointer w-1/12">
            <div className="flex flex-col justify-start items-center h-[32px]">
              <div className="flex items-center" onClick={() => sortArray('projectName')}>
                <span className="text-center text-sm font-semibold">Project Name</span>
                {getSortIcon('projectName')}
              </div>
            </div>
          </th>
          <th className="py-2 cursor-pointer w-1/12">
            <div className="flex flex-col justify-start items-center h-[32px]">
              <div className="flex items-center" onClick={() => sortArray('builderName')}>
                <span className="text-center text-sm font-semibold">Builder Info</span>
                {getSortIcon('builderName')}
              </div>
            </div>
          </th>
             {/*  <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('builderContactNo')}>
                Builder Contact No {getSortIcon('builderContactNo')}
              </th> */}
             {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1') && (
  <th className="py-2 cursor-pointer w-1/12">
    <div className="flex flex-col justify-start items-center h-[32px]">
      <div className="flex items-center" onClick={() => sortArray('siteContactName')}>
        <span className="text-center text-sm font-semibold">Assigned MA</span>
        {getSortIcon('siteContactName')}
      </div>
    </div>
  </th>
)}
             <th className="py-2 cursor-pointer w-1/12">
  <div className="flex flex-col justify-start items-center h-[32px]">
    <div className="flex items-center" onClick={() => sortArray('siteContactNo')}>
      <span className="text-center text-sm font-semibold">Last Visit</span>
      {getSortIcon('siteContactNo')}
    </div>
  </div>
</th>
{(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1') && (
  <>
    <th className="py-2 cursor-pointer w-1/12">
      <div className="flex flex-col justify-start items-center h-[32px]">
        <div className="flex items-center" onClick={() => sortArray('siteContactNo')}>
          <span className="text-center text-sm font-semibold">Reshedule Date</span>
          {getSortIcon('siteContactNo')}
        </div>
      </div>
    </th>
    <th className="py-2 cursor-pointer w-1/12">
      <div className="flex flex-col justify-start items-center h-[32px]">
        <div className="flex items-center" onClick={() => sortArray('siteContactName')}>
          <span className="text-center text-sm font-semibold ">Assigned FA</span>
          {getSortIcon('siteContactName')}
        </div>
      </div>
    </th>
    <th className="py-2 cursor-pointer w-1/12">
      <div className="flex flex-col justify-start items-center h-[32px]">
        <div className="flex items-center" onClick={() => sortArray('siteContactName')}>
          <span className="text-center text-sm font-semibold">FA Action</span>
          {getSortIcon('siteContactName')}
        </div>
      </div>
    </th>
  </>
)}
                     {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1' || sessionStorage.getItem('roleId') === '3') && (
  <>
    <th className="py-2 cursor-pointer w-1/12">
      <div className="flex flex-col justify-start items-center h-[32px]">
        <div className="flex items-center" onClick={() => sortArray('quoteMeasurements')}>
          <span className="text-center text-sm font-semibold">Quote Measurements</span>
          {getSortIcon('quoteMeasurements')}
        </div>
      </div>
    </th>
    <th className="py-2 cursor-pointer w-1/12">
      <div className="flex flex-col justify-start items-center h-[32px]">
        <div className="flex items-center" onClick={() => sortArray('productionMeasurements')}>
          <span className="text-center text-sm font-semibold">Production Measurements</span>
          {getSortIcon('productionMeasurements')}
        </div>
      </div>
    </th>
    <th className="py-2 cursor-pointer w-1/12">
      <div className="flex flex-col justify-start items-center h-[32px]">
        <div className="flex items-center" onClick={() => sortArray('measurements')}>
          <span className="text-center text-sm font-semibold">Measurements</span>
          {getSortIcon('measurements')}
        </div>
      </div>
    </th>
  </>
)}

{(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1') && (
  <th className="py-2 cursor-pointer w-1/12">
    <div className="flex flex-col justify-start items-center h-[32px]">
      <div className="flex items-center" onClick={() => sortArray('siteContactNo')}>
        <span className="text-center text-sm font-semibold">Action to Customer</span>
        {getSortIcon('siteContactNo')}
      </div>
    </div>
  </th>
)}
           {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1' || sessionStorage.getItem('roleId') === '3') && (
  <th className="py-2 cursor-pointer w-1/12">
    <div className="flex flex-col justify-start items-center h-[32px]">
      <div className="flex items-center" onClick={() => sortArray('summary')}>
        <span className="text-center text-sm font-semibold">Summary</span>
        {getSortIcon('summary')}
      </div>
    </div>
  </th>
)}

            {(sessionStorage.getItem('roleId') === '2') && (
  <th className="py-2 cursor-pointer w-1/12">
    <div className="flex flex-col justify-start items-center h-[40px]">
      <div className="flex items-center" onClick={() => sortArray('siteContactNo')}>
        <span className="text-center text-sm font-semibold">Next Visit</span>
        {getSortIcon('siteContactNo')}
      </div>
    </div>
  </th>
)}
            </tr>
          </thead>
          <tbody className="border-b border-t">
  {projects
    .filter(
      (project) =>
        (project.projectName?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
        (project.builderName?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
        (project.builderContactNo?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
        (project.siteContactName?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
        (project.siteContactNo?.toLowerCase() || '').includes(searchQuery.toLowerCase())
    )
    .map((project) => {
      const date = new Date(project.timestamp);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;

      return (
        <tr key={project._id} className="text-sm border-b">
<td 
  className="py-2 text-center text-[#408dfb] font-500 cursor-pointer hover:underline"
  onClick={() => handleNavigateProjectDetails(project)}
>
  <div className="flex flex-col items-center justify-center h-full">
    <span className="whitespace-pre-line text-sm" style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>
      {project.projectName.split(' ').join('\n')}
    </span>
  </div>
</td>


          <td className="py-2 text-center">
  <div className="flex flex-col items-center">
  <span className="text-sm text-center line-clamp-2 max-w-[100px]">
    {project.builderName}
  </span>
  <a href={`tel:${project.builderContactNo}`} className="text-sm text-blue-500 hover:underline">
    {project.builderContactNo}
  </a>
    </div>
</td>

          {/* <td className="py-2 text-center">{project.builderContactNo}</td> */}
          {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1') && (
          <td
            className={`text-${project.marketingAssociateId ? 'blue-300' : 'red-300'} py-2 text-xs text-center px-4 underline cursor-pointer`}
            onClick={() => handleAssignMarketingAssociate(project)}
          >
            {project.marketingAssociateId ? (
              <span>
                {`${users.find((p) => p._id === project.marketingAssociateId)?.firstName} ${
                  users.find((p) => p._id === project.marketingAssociateId)?.lastName || ''
                }`}
              </span>
            ) : (
              'Unassigned User'
            )}
          </td>
          )}
          <td className="py-2 text-center">{formattedDate}</td>
          {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1') && (
             <>
          <td
            className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center underline cursor-pointer"
            onClick={() => handleOpenDate(project._id, project.rescheduleDate,project.purposeOfVisit)}
          >
            {project.rescheduleDate || 'Reschedule Date'}
          </td>
          <td
            className={`text-${project.fieldAssociateId ? 'blue-300' : 'red-300'} py-2 text-xs text-center px-4 underline cursor-pointer`}
            onClick={() => handleAssignFieldAssociate(project)}
          >
            {project.fieldAssociateId ? (
              <span>
                {`${users.find((p) => p._id === project.fieldAssociateId)?.firstName} ${
                  users.find((p) => p._id === project.fieldAssociateId)?.lastName || ''
                }`}
              </span>
            ) : (
              'Unassigned User'
            )}
          </td>
          <td className='py-2 text-center text-[#408dfb] cursor-pointer hover:underline' 
          onClick={() => handleCollect(project)}>Collect</td>
                                    </>
        )}
          {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1' || sessionStorage.getItem('roleId') === '3') && (
                        <>
          <td className="py-2 text-center">{project.quotationMeasurementStatus}</td>
          <td className="py-2 text-center">{project.productionMeasurementStatus}</td>
          <td className="py-2 text-center">
            <div className="flex flex-row space-x-2 items-center justify-center">
              <span className="text-[#408dfb] font-500 cursor-pointer hover:underline" onClick={() => handleNavigatetoMeasurements(project, 'quotation')}>
                Quote
              </span>
              <span className="text-[#408dfb] font-500 cursor-pointer hover:underline" onClick={() => handleNavigatetoMeasurements(project, 'production')}>
                Production
              </span>
            </div>
          </td>
          </>
          )}
          {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1') && (
          <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer hover:underline"
          onClick={() => handleNotify(project)}>Notify</td>
        )}
        {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1' || sessionStorage.getItem('roleId') === '3') && (
         <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer hover:underline" onClick={() => handleSummaryClick(project)}
                >view</td>
        )}

{(sessionStorage.getItem('roleId') === '2') && (
  <td
    className={`py-2 text-center ${
      project.rescheduleDate && project.purposeOfVisit ? 'cursor-pointer text-[#408dfb]' : ''
    }`}
    onClick={() => {
      if (project.rescheduleDate && project.purposeOfVisit) {
        setSelectedPurpose(project.purposeOfVisit);
        setShowPurposePopup(true);
      }
    }}
  >
    {project.rescheduleDate
      ? new Date(project.rescheduleDate).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'numeric',
          year: 'numeric'
        }).replace(/\//g, '-')
      : '--'}
  </td>
)}

{showPurposePopup && (
  <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-10 p-4">
  <div className="bg-white rounded-lg shadow-md w-full max-w-xs sm:max-w-md">
    <div className="p-4 sm:p-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg sm:text-xl font-bold">Purpose of Visit</h3>
        <FontAwesomeIcon 
          icon={faTimes} 
          className="text-red-600 cursor-pointer hover:text-red-800" 
          onClick={handleClose}
        />
      </div>
      <div className="bg-gray-100 p-3 sm:p-4 rounded-md">
        <p className="text-gray-800 text-sm sm:text-base whitespace-pre-wrap overflow-y-auto max-h-32 sm:max-h-40">
          {selectedPurpose}
        </p>
      </div>
      </div>

      
    </div>
  </div>
)}




        </tr>

      );
    })}
  {projects.filter(
    (project) =>
      (project.projectName?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
      (project.builderName?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
      (project.builderContactNo?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
      (project.siteContactName?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
      (project.siteContactNo?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  ).length === 0 && (
    <tr>
      <td colSpan="8" className="text-center text-[#6c7184] py-4">
        Not found
      </td>
    </tr>
  )}
</tbody>

      </table>
    </div>
  </div>



{/* Pop ups */}

  {AssignMarketingAssociate && (
            <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
              <div className="bg-white p-2 rounded-lg shadow-md">
                <div className="flex justify-end cursor-pointer" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-600" />
                </div>
                <div className="w-full mx-auto mt-2">
    {/* Wrapper div with white background */}
    <div className="max-w-screen-xl mx-auto mt-3 p-2">
      <div className="flex flex-col relative rounded-md p-2 ml-10 mr-10">
        
        <table className="w-full border-b border-gray-300 mt-2">
        <thead className="border-b border-gray-300">
          <tr className="bg-[#959BB0] bg-opacity-[15%]">
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">User Name</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Mobile Number</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Assign User</th>
          </tr>
        </thead>
        {/* Add your table body content here */}
       
        <tbody className="border-b border-gray-300">
  {users
    .filter(user => user.roleId === 2)
    .map((User, index) => (
      <tr key={User._id} className={index % 2 === 0 ? "bg-white border-b border-gray-300" : "bg-[#F9F4F4] border-b border-gray-300"}>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{`${User.firstName} ${User.lastName}`}</td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.mobileNo}</td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">
          <input
            type="checkbox"
            className="form-checkbox h-4 w-4 text-blue-500"
            checked={User._id === ProjectUserId || false}
            onChange={() => handleCheckboxChange(User._id)}
          />
        </td>
      </tr>
    ))}          
</tbody>
      </table>
      </div>
            <button class=" bg-[#0F9D58] flex flex-col relative mx-auto mt-2 text-sm text-white bg-opacity-75 rounded-sm px-4 py-1 hover:bg-opacity-90"
             onClick={handleAssignprojecttoMarketingAssociate}>Assign</button>
    </div>
    </div>
                </div>
                </div>
                )}

{AssignFieldAssociate && (
            <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
              <div className="bg-white p-2 rounded-lg shadow-md">
                <div className="flex justify-end cursor-pointer" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-600" />
                </div>
                <div className="w-full mx-auto mt-2">
    {/* Wrapper div with white background */}
    <div className="max-w-screen-xl mx-auto mt-3 p-2">
      <div className="flex flex-col relative rounded-md p-2 ml-10 mr-10">
        
        <table className="w-full border-b border-gray-300 mt-2">
        <thead className="border-b border-gray-300">
          <tr className="bg-[#959BB0] bg-opacity-[15%]">
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">User Name</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Mobile Number</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Assign User</th>
          </tr>
        </thead>
        {/* Add your table body content here */}
       
        <tbody className="border-b border-gray-300">
  {users
    .filter(user => user.roleId === 3)
    .map((User, index) => (
      <tr key={User._id} className={index % 2 === 0 ? "bg-white border-b border-gray-300" : "bg-[#F9F4F4] border-b border-gray-300"}>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{`${User.firstName} ${User.lastName}`}</td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.mobileNo}</td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">
          <input
            type="checkbox"
            className="form-checkbox h-4 w-4 text-blue-500"
            checked={User._id === ProjectUserId || false}
            onChange={() => handleCheckboxChange(User._id)}
          />
        </td>
      </tr>
    ))}          
</tbody>
      </table>
      </div>
            <button class=" bg-[#0F9D58] flex flex-col relative mx-auto mt-2 text-sm text-white bg-opacity-75 rounded-sm px-4 py-1 hover:bg-opacity-90"
             onClick={handleAssignprojecttoFieldAssociate}>Assign</button>
    </div>
    </div>
                </div>
                </div>
                )}

  {CollectPopUp && currentProject && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-md shadow-md w-[500px] p-6 animate-slide-in">
        <h2 className="text-xl font-bold mb-4 text-gray-800">Notify Field Associate</h2>
        <p className="mb-4 text-gray-600">Select an action to notify the Field Associate:</p>
        <div className="space-y-3">
          <button 
            className="w-full border border-[#f7525a] hover:bg-[#f7525a] hover:text-white text-black py-2 px-4 rounded transition duration-300"
            onClick={() => handleCollectMeasurements(currentProject)}
          >
            <div className="flex items-center justify-center space-x-2">
    <span>    {currentProject.quotationMeasurementStatus === 'Completed' ? 'Re-collect Quote Measurements ' : 'Collect Quote Measurements'}
    </span>
    {isMeasurementsNotify && <Spinner />}
  </div>
          </button>
          <button 
            className="w-full border border-[#f7525a] hover:bg-[#f7525a] hover:text-white text-black  py-2 px-4 rounded transition duration-300"
            onClick={() => handleCollectAdvancePayment(currentProject)}
          >
             <div className="flex items-center justify-center space-x-2">
    <span>Collect Advance Payment</span>
    {isAdvancePaymentNotify && <Spinner />}
  </div>
          </button>
          <button 
            className="w-full border border-[#f7525a] hover:bg-[#f7525a] hover:text-white text-black  py-2 px-4 rounded transition duration-300"
            onClick={() => handleCollectProductionMeasurements(currentProject)}
          >
             <div className="flex items-center justify-center space-x-2">
  <span>  {currentProject.productionMeasurementStatus === 'Completed' ? 'Re-collect Production Measurements ' : 'Collect Production Measurements'}
    </span>
    {isProductionMeasurementsNotify && <Spinner />}
  </div>
          </button>
        </div>
        <div className='flex justify-end mt-4'>
        <button
          className={`px-2 py-1 rounded-md border ${
            isMeasurementsNotify || isAdvancePaymentNotify || isProductionMeasurementsNotify
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-[#f5f5f5] text-gray-700 border-[#DDDDDD] hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50'
          }`}
          onClick={handleClose}
          disabled={isMeasurementsNotify || isAdvancePaymentNotify || isProductionMeasurementsNotify}
        >
          Cancel
        </button>
        </div>
      </div>
      </div>
  )}

{NotifyPopUp && currentProject && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-md shadow-md w-[500px] p-6 animate-slide-in">
        <h2 className="text-xl font-bold mb-4 text-gray-800">Notify Customer</h2>
        <p className="mb-4 text-gray-600">Select an action to notify the Customer:</p>
        <div className="space-y-3">
          <button 
            className="w-full border border-[#f7525a] hover:bg-[#f7525a] hover:text-white text-black py-2 px-4 rounded transition duration-300"
            onClick={() => handleSendMeasurementsLink(currentProject)}
          >
            <div className="flex items-center justify-center space-x-2">
    <span>Send View Measurements Link</span>
    {isSaving && <Spinner />}
  </div>
          </button>
        </div>
        <div className='flex justify-end mt-4'>
        <button
          className={`px-2 py-1 rounded-md border ${
            isSaving
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-[#f5f5f5] text-gray-700 border-[#DDDDDD] hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50'
          }`}
          onClick={handleClose}
          disabled={isSaving}
        >
          Cancel
        </button>
        </div>
      </div>
      </div>
  )}
                {UserAssignedtoProjectAlert && (
    <div className="fixed inset-0 z-30 flex items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30 "></div>
  
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-bold mb-2">
       User assigned to project successfully!</p>
       <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleUserAssignedtoProjectAlert}>OK</button>
      </div>
    </div>
  )} 

{DatePopup  && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-md shadow-md w-[500px] p-6 animate-slide-in">
      <div className="flex justify-end cursor-pointer absolute top-2 right-2" onClick={handleCloseDate}>
        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
      </div>
      <h2 className="text-xl font-semibold mb-4">Reschedule Marketing Associate Visit</h2>
      <div className="space-y-4">
        <div className="flex items-center space-x-4">
          <label htmlFor="rescheduleDate" className="text-sm font-medium w-1/3">Reschedule Date:</label>
          <input
            type="date"
            id="rescheduleDate"
            className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={date}
            onChange={handleDateChange}
          />
        </div>
        <div className="flex space-x-4">
          <label htmlFor="purposeOfVisit" className="text-sm font-medium w-1/3">Purpose of Visit:</label>
          <textarea
            id="purposeOfVisit"
            className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
            value={purposeOfVisit}
            onChange={handlePurposeChange}
            placeholder="Enter purpose of visit"
            rows="3"
          ></textarea>
        </div>
      </div>
      <div className='flex justify-start mt-4 '>
      <button
        className=" border border-[#f7525a] hover:bg-[#f7525a] hover:text-white text-black text-sm px-2 py-1 rounded-md hover:bg-opacity-90 transition duration-300"
        onClick={handleSubmit}
      >
        <div className="flex items-center justify-center space-x-2">
       <span> Submit</span>
        {isSaving && <Spinner />}
        </div>
      </button>
        <button  onClick={handleCloseDate}   className={`ml-2 hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
         disabled={isSaving} >Cancel</button>
        </div>
    </div>
  </div>
)}

    {noSelectAssignUseralert &&(
         <div className="fixed inset-0 z-30 flex items-center justify-center">
         {/* Background overlay (blurred) */}
         <div className="absolute inset-0 bg-black opacity-30 "></div>
  
         <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
         <p className="text-lg font-bold mb-2">
           Please select at least one user!
           </p>
           <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={ handleClosenoAssigneUser}>
             OK
           </button>
         </div>
       </div>
        )}

{showSummaryPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-4 rounded-lg max-w-4xl max-h-[90vh] overflow-y-auto relative">
    <button
        className="absolute top-2 right-2 bg-transparent text-gray-600 hover:text-gray-800"
        onClick={() => setShowSummaryPopup(false)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
            <h2 className="text-lg font-bold mb-4">Summary Options</h2>
            <div className="flex space-x-4 mb-4">
              <button 
                className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"
                onClick={handleQuoteSummary}
              >
                Quote Summary
              </button>
              <button 
                className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"
                onClick={handleProductionSummary}
              >
                Production Summary
              </button>
            </div>
            <div className="mt-4">
  {selectedProject && selectedProject.type && (
    <ProjectReports project={selectedProject} type={selectedProject.type} />
  )}
  
  {selectedProject && selectedProject.type && (
  <div className="flex justify-center mt-4">
    {(selectedProject.type === 'quotation' ?
      selectedProject.quotationMeasurementStatus :
      selectedProject.productionMeasurementStatus) === 'Completed' ? (
      <div className="text-green-500 flex items-center">
        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
        Completed
      </div>
    ) : (
      <button
        className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"
        onClick={handleComplete}
      >
        Complete
      </button>
    )}
  </div>
)}

</div>
          </div>
        </div>
      )}
        


  {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}

{/* Pop ups */}
  </div>
);
};

export default ProjectList;