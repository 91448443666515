import { faArrowLeft, faBackspace, faBackward, faBalanceScale, faChevronDown, faChevronUp, faDoorOpen, faInfo, faInfoCircle, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { jwtDecode } from "jwt-decode";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FaBox, FaDoorOpen, FaInfoCircle, FaRuler, FaWindowClose, FaWindows } from "react-icons/fa";

const ViewWiendowDetailsQuote = () => {
  const navigate = useNavigate();
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
const  SelectedProjectMeasurements = location.state?.ProjectMeasurements;
const {categoryId} = location.state || '';
const {customerId} = location.state || '';
const {projectId} = location.state || '';
  const [SelectedWindow, setSelectedWindow]=  useState(null);
  const [UOMS , setUOMS] = useState([]);
  const fetchUrl = process.env.REACT_APP_FORE_APILINK;
    const fetchUrl2 = process.env.REACT_APP_FORE_BRICKBUCKET;
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES
   const [productGroups, setProductGroups] = useState([]);
   const [ProjectMeasurements, setProjectMeasurements]= useState();
   const [Products,setProducts] = useState([]);
   const [windowHardware, setwindowHardware] = useState([]);
   const [openCategories, setOpenCategories] = useState({});

    const [SelectedWindows, setSelectedWindows] = useState({
        mainEntrance: null,
        bedroom: null,
        bathroom: null,
      });
      const [showSelectedWindow, setShowSelectedWindow] = useState(false);
      const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
      const [showQuotationPopup, setShowQuotationPopup] = useState(false);

      const [SelectedWindowDetails, setSelectedWindowDetails] = useState(null);
      const [selectedProducts, setSelectedProducts] = useState({});
      const [SelectedWindowHardware, setSelectedWindowHardware] = useState({});
      const [showChangePopup, setShowChangePopup] = useState(false);
      const [selectedCategoryName , setSelectedCategoryName] = useState(null);
      const [ShowConformPopup, setShowConformPopup] = useState(false);

     useEffect(() => {
    if(SelectedProjectMeasurements){
        setProjectMeasurements(SelectedProjectMeasurements)
      }
    },[SelectedProjectMeasurements])

  
    const handleProductGroupComparison = ()=>{
      setShowChangePopup(true)
    }
      
      
      const handleClosePopup = () => {
        setShowSelectedWindow(false);
        setSelectedWindowDetails(null);
      };

      

      useEffect(() => {
          getUoms();
       }, []);
    

      const getUoms =  async () => {
        const token = sessionStorage.getItem('token');
        try {
          const response = await fetch(fetchUrl2 + `UOMs/get-all-UOMs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
          const data = await response.json();
          const output = await handleGetResponse(response, data);
          //console.log(data)
          if(output) {
            setUOMS(output);
          }else{
            setUOMS([]);
          }

        } catch (error) {
          console.error('Error fetching UOMs:', error);
        }
      };

  

      const calculatewindowPrice = (addOns, groupPrice, area) => {
        let mainwindowPrice = parseFloat(groupPrice) || 0;
      
        if (Array.isArray(addOns) && addOns.length > 0) {
          const addOnsPrice = addOns.reduce((total, addOn) => {
            const price = parseFloat(addOn.selling_price) || 0;
            return total + price;
          }, 0);
          mainwindowPrice += addOnsPrice;
        }
      
        const totalArea = parseFloat(area) || 1;
        const finalPrice = mainwindowPrice * totalArea;
        
        return Math.ceil(finalPrice);
      };
      
      const calculatewindowPricePerSquareFeet = (addOns, groupPrice) => {
        let mainwindowPrice = parseFloat(groupPrice) || 0;
      
        if (Array.isArray(addOns) && addOns.length > 0) {
          const addOnsPrice = addOns.reduce((total, addOn) => {
            const price = parseFloat(addOn.selling_price) || 0;
            return total + price;
          }, 0);
          mainwindowPrice += addOnsPrice;
        }
        
        return Math.ceil(mainwindowPrice);
      };
      
      const handleAddProduct = (product) => {
        if (!product || typeof product !== 'object') {
          console.error('Invalid product object');
          return;
        }
      
        const groupId = product.item_group_id;
      
        setSelectedWindowDetails(prevDetails => {
          if (!prevDetails || !prevDetails.categoryDetails) {
            console.error('Invalid SelectedWindowDetails structure');
            return prevDetails;
          }
      
          const currentProducts = Array.isArray(prevDetails.categoryDetails.selectedProducts) 
            ? prevDetails.categoryDetails.selectedProducts 
            : [];
      
          const filteredProducts = currentProducts.filter(p => p && p.item_group_id !== groupId);
          const updatedProducts = [...filteredProducts, { ...product, quantity: 1 }];
      
          return {
            ...prevDetails,
            categoryDetails: {
              ...prevDetails.categoryDetails,
              selectedProducts: updatedProducts
            }
          };
        });
      };
      
      const handleAddRemove = (product) => {
      /*   if (!product || typeof product !== 'object') {
          console.error('Invalid product object');
          return;
        }
      
        setSelectedWindowDetails(prevDetails => {
          if (!prevDetails || !prevDetails.categoryDetails) {
            console.error('Invalid SelectedWindowDetails structure');
            return prevDetails;
          }
      
          const currentProducts = Array.isArray(prevDetails.categoryDetails.selectedProducts) 
            ? prevDetails.categoryDetails.selectedProducts 
            : [];
      
          const filteredProducts = currentProducts.filter(p => p && p.item_id !== product.item_id);
      
          const findSubcategoryProduct = Array.isArray(Products) 
            ? Products.find(p => p && p.item_sub_category_id === product.item_sub_category_id && p.selling_price === 0)
            : null;
      
          const updatedProducts = findSubcategoryProduct
            ? [...filteredProducts, { ...findSubcategoryProduct, quantity: 1 }]
            : filteredProducts;
      
          return {
            ...prevDetails,
            categoryDetails: {
              ...prevDetails.categoryDetails,
              selectedProducts: updatedProducts
            }
          };
        }); */
      };
      


      const handleAddwindowHardware = (product) => {
        setSelectedWindowDetails(prevDetails => {
          const currentHardware = prevDetails.categoryDetails.selectedHardware || [];
          
          // Remove any existing product from the same group
          const filteredHardware = currentHardware.filter(
            item => item.product_group_id !== product.product_group_id
          );
      
          // Add the new product
          const updatedHardware = [
            ...filteredHardware,
            { ...product, quantity: prevDetails.categoryDetails.MainAreaCount || 1 }
          ];
      
          return {
            ...prevDetails,
            categoryDetails: {
              ...prevDetails.categoryDetails,
              selectedHardware: updatedHardware
            }
          };
        });
      };
      
      
      const handleRemovewindowHardware = (product) => {
      /*   setSelectedWindowDetails(prevDetails => ({
          ...prevDetails,
          categoryDetails: {
            ...prevDetails.categoryDetails,
            selectedHardware: prevDetails.categoryDetails.selectedHardware.filter(
              item => item._id !== product._id
            )
          }
        })); */
      };
       
      
  const handleGetProductsDataByGroup = async (Window) => {
    const id = Window?.SelectedWindow?._id;
    const tokn = sessionStorage.getItem('token');
    try {
      const response = await fetch(`${fetchUrl2}product/get-products-by-product-group?product_group_id=${id}&organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': tokn,
        },
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      setProducts(output || []);
      return output || [];
    } catch (error) {
      console.error('Error fetching products:', error);
      setProducts([]);
      return [];
    }
  }

  const calculateSelectedWindowPrice = () => {
    const windowPrice = SelectedWindowDetails?.selling_price || 0;
    const area = SelectedWindowDetails?.categoryDetails?.MainAreaValue || 0;
    const addOnsPrice = SelectedWindowDetails?.categoryDetails?.selectedProducts?.reduce((total, product) => {
      return total + (parseFloat(product.selling_price) || 0);
    }, 0) || 0;
    return Math.round((windowPrice + addOnsPrice) * area);
  };
  
  // const calculateHardwarePrice = () => {
  //   const mainAreaCount = SelectedWindowDetails?.categoryDetails?.MainAreaCount || 1;
  //   return Math.round(SelectedWindowDetails?.categoryDetails?.selectedHardware?.reduce((total, hardware) => {
  //     return total + ((parseFloat(hardware.selling_price) || 0) * mainAreaCount);
  //   }, 0) || 0);
  // };
  
  // const calculateTotalPrice = () => {
  //   const windowPrice = calculateSelectedWindowPrice();
  //   const hardwarePrice = calculateHardwarePrice();
  //   return Math.round(windowPrice + hardwarePrice);
  // };

  const calculateWindowPrice = (window, area) => {
    return (window?.selling_price || 0) * area;
  };
  
  const calculateHardwarePrice = (hardware, count) => {
    return (hardware?.selling_price || 0) * count;
  };
  
  const calculateTotalPrice = (windowPrice, hardwarePrice) => {
    return windowPrice + hardwarePrice;
  };
  
  const calculateSubtotal = () => {
    if (!ProjectMeasurements || !ProjectMeasurements.projectMeasurements) {
      return 0;
    }
    
    return ProjectMeasurements.projectMeasurements.reduce((total, category) => {
      const windowPrice = calculateWindowPrice(category.SelectedWindow, category.MainAreaValue);
      const hardwarePrice = (category.selectedHardware || []).reduce((sum, hardware) => 
        sum + calculateHardwarePrice(hardware, category.MainAreaCount), 0);
      return total + calculateTotalPrice(windowPrice, hardwarePrice);
    }, 0);
  };
  
  
  const calculateTax = (subtotal) => {
    // Assuming 18% GST
    return subtotal * 0.18;
  };
  
  
  const handleTotalPriceInfo = () => {
    setShowPriceBreakdown(true);
  };
  const handleApprove = async () => {
    setIsSaving(true);
  
    try {
      const subtotal = calculateSubtotal();
      const taxAmount = calculateTax(subtotal);
      const total = subtotal + taxAmount;
  
      const items = ProjectMeasurements.projectMeasurements.flatMap(category => {
        const windowItem = {
          item_id: category.SelectedWindow._id,
          item_name: category.SelectedWindow.product_group_name,
          quantity: category.MainAreaValue,
          selling_price: category.SelectedWindow.selling_price,
          tax_rate: 18, // Assuming 18% GST
          tax_type: 'GST',
          UOM_id: category.SelectedWindow.UOM_id,
          UOM: UOMS.find(uom => uom._id === category.SelectedWindow.UOM_id)?.UOM || 'Unit',
          total_tax_amount: calculateTax(calculateWindowPrice(category.SelectedWindow, category.MainAreaValue)),
          total: calculateWindowPrice(category.SelectedWindow, category.MainAreaValue),
          area_types: {
            area_name: category.name,
            area: category.MainAreaValue.toString(),
            count: category.MainAreaCount.toString()
          }
        };
  
        const hardwareItems = (category.selectedHardware || []).map(hardware => ({
          item_id: hardware._id,
          item_name: hardware.product_name,
          quantity: category.MainAreaCount,
          selling_price: hardware.selling_price,
          tax_rate: 18, // Assuming 18% GST
          tax_type: 'GST',
          UOM_id: hardware.UOM_id,
          UOM: UOMS.find(uom => uom._id === hardware.UOM_id)?.UOM || 'Unit',
          total_tax_amount: calculateTax(calculateHardwarePrice(hardware, category.MainAreaCount)),
          total: calculateHardwarePrice(hardware, category.MainAreaCount),
          area_types: {
            area_name: category.name,
            count: category.MainAreaCount.toString()
          }
        }));
  
        return [windowItem, ...hardwareItems];
      });
  
      const quotationData = {
        customer_id: customerId,
        product_category_id: categoryId,
        quotation_date: new Date(),
        project_id: projectId,
        status: 'Draft',
        quotation_type: 'Group',
        items: items,
        subtotal: subtotal,
        tax_amount: taxAmount,
        total: total,
        tax_prefrence: 'Tax Exclusive',
        organizationId: sessionStorage.getItem('organizationId'),
      };
  
      console.log(quotationData);
  
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl2}customer/quotations/save-quotation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(quotationData),
      });
  
      const data = await response.json();
      const output = await handleSaveResponse(response, data);
  
      if (output) {
        //setShowConformPopup(true);
        setShowQuotationPopup(true);
      }
    } catch (error) {
      console.error('Failed to save quotation:', error);
      setPopupMessage('Failed to save quotation');
      setPopupType('500');
      setShowPopup(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleItems = () => {
    navigate("/consumer/app/windowsitemsforso", { state: { SelectedProjectMeasurements, categoryId : categoryId , customerId: customerId, projectId: projectId } });
  console.log(SelectedProjectMeasurements);
  }
  
  
  return (
    <div className="bg-[#FAFAFA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
    <h1 className="text-2xl font-bold mb-4">windows</h1>
  
    {ProjectMeasurements && Array.isArray(ProjectMeasurements.projectMeasurements) && ProjectMeasurements.projectMeasurements.length > 0 ? (
    <>
      {ProjectMeasurements.projectMeasurements.map((category) => (
        <div key={category.name} className="bg-white rounded-lg shadow-lg border border-[#BDC3C7] p-6 mb-8">
          {Object.entries(category.subAreaTypes).map(([subAreaName, subArea]) => (
            <div key={subAreaName} className="border-b border-[#ECF0F1] last:border-b-0 pb-6 mb-6 last:pb-0 last:mb-0">
              <div className="flex flex-col items-start mb-4">
                <div className="flex items-start text-xl font-semibold mb-3">
                  <FaInfoCircle className="flex-shrink-0 mt-1 mr-2 text-[#3498DB]" />
                  <span className="text-[#3498DB]">{subAreaName}</span>
                </div>
                
                <div className="text-lg text-[#2C3E50] mb-2">
                  <FaRuler className="inline mr-2 text-[#E74C3C]" />
                  Area: <span className="font-bold">{(subArea.area || 0).toFixed(2)}</span> <span className="font-semibold">sq ft</span>
                </div>
                
                <div className="text-lg text-[#2C3E50]">
            <FaWindows className="inline mr-2 text-[#27AE60]" />
            {subAreaName === "Ventilators Area" ? "Ventilators" : "Windows"}: <span className="font-bold">{subArea.count || 0}</span> <span className="font-semibold"></span>
          </div>
              </div>

              {category.SelectedWindow ? (
                 <div className="bg-[#D6EAF8] p-4 rounded-lg">
                 
                  <h3 className="text-xl font-semibold mb-4 text-[#2C3E50]">{category.SelectedWindow.product_group_name || 'Unnamed Window'}</h3>
                  <p className="text-lg text-[#27AE60]">
                      ₹{((category.SelectedWindow.selling_price || 0) + '/' + (UOMS.find(p => p?._id === category.SelectedWindow.UOM_id)?.UOM || 'Unit')).replace('undefined', 'Unit')}
                    </p>
              
              <p className="mt-2 text-lg text-[#2C3E50]">
                Total: ₹ {((category.SelectedWindow.selling_price || 0) * (subArea.area || 0)).toFixed(2)}
              </p>
          
                  </div>
               
              ) : (
                <div className="bg-[#FCF3CF] p-4 rounded-lg mt-4">
  <p className="text-lg text-[#F39C12]">
    No {subAreaName === "Ventilators Area" ? "ventilator" : "window"} selected for this area.
  </p>
</div>

              )}
            </div>
          ))}
          
          {/* <div className="mt-6 text-right">
        <span
        className="text-sm font-semibold text-[#EC001D] bg-red-50 px-3 py-1 rounded-full cursor-pointer hover:bg-red-100 transition duration-300"
        onClick={() => handleGetProductsDataByGroup(category)} > Select Window
        </span>
        </div> */}
        </div>
      ))}
    </>
  ) : null}

{ProjectMeasurements && ProjectMeasurements.projectMeasurements && (
  <div className="mt-6 flex flex-col justify-center items-center space-y-2">
    <p className="text-lg text-[#2C3E50]">Sub Total: ₹{calculateSubtotal().toFixed(2)}</p>
    <p className="text-lg text-[#2C3E50]">Tax: ₹{calculateTax(calculateSubtotal()).toFixed(2)} (Exclusive)</p>
    <p className="text-xl font-bold text-[#2C3E50]">
      Total: ₹{(calculateSubtotal() + calculateTax(calculateSubtotal())).toFixed(2)}
    </p>
  </div>
)}



<div className="mt-6 flex justify-center">
    <button
      className="bg-[#3498DB] text-white px-4 py-2 rounded-lg font-semibold hover:bg-[#2980B9] transition-colors duration-200"
      onClick={handleApprove}
    >
      Approve Quote
    </button>
  </div>

  {ShowConformPopup && (
  <div className="fixed inset-0 z-20 bg-black bg-opacity-60 flex justify-center items-center">
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
      <h2 className="text-2xl font-bold mb-4 text-[#2C3E50]">Quote Approved</h2>
      <p className="text-lg text-[#34495E] mb-2">Your quotation has been approved.</p>
      <p className="text-lg text-[#34495E] mb-6">Please select the items to generate an order.</p>
      <div className="flex justify-between">
        <button
          className="bg-[#3498DB] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#2980B9] transition-colors duration-200"
          onClick={handleItems}
        >
          Okay
        </button>
        {/* <button
          className="bg-[#E74C3C] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#C0392B] transition-colors duration-200"
          onClick={() => setShowPopup(false)}
        >
          Close
        </button> */}
      </div>
    </div>
  </div>
)}

{showQuotationPopup && (
  <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-end z-50">
    <div className="bg-white rounded-t-lg shadow-lg p-6 w-full max-w-sm transform transition-all duration-700 ease-in-out animation1-slide-up">
      <h2 className="text-xl font-bold mb-4 text-gray-800">Quotation Saved</h2>
      <p className="mb-6 text-gray-600">
        Windows quotation has been generated and saved successfully. We will get back to you for production measurements.
      </p>
      <button
        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors duration-300"
        onClick={() => {
          setShowQuotationPopup(false);
          navigate('/consumer/app');
        }}
      >
        OK
      </button>
    </div>
  </div>
)}





  </div>
  
  );
};

export default ViewWiendowDetailsQuote;


