import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const RETRY_LIMIT = 3;
  const [retryCount, setRetryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  
    const getNestedValue = (item, key) => {
      return key.split('.').reduce((value, keyPart) => {
        return value ? value[keyPart] : undefined;
      }, item);
    };
  
    const sortedArray = [...organizations].sort((a, b) => {
      const aValue = getNestedValue(a, key);
      const bValue = getNestedValue(b, key);
  
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return direction === 'ascending' ? aValue - bValue : bValue - aValue;
      } else {
        const aString = aValue.toString().toLowerCase();
        const bString = bValue.toString().toLowerCase();
        return direction === 'ascending'
          ? aString.localeCompare(bString)
          : bString.localeCompare(aString);
      }
    });
  
    setOrganizations(sortedArray);
  };
  
  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async (attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}organization/get-all-organizations`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });

      const data = await response.json();
      console.log(data.data);
      if (response.ok) {
        setOrganizations(data.data || []);
      } else {
        console.error(data.message);
      }

      if (response.status === 500) {
        // Handle retry logic here if needed
      }
    } catch (error) {
      console.error('Failed to fetch organizations:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchOrganizations(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        console.error('Internal Server Error. Kindly Retry.'); // Handle error without popup
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = () => {
    navigate("/bb/app/settings/neworganization");
  };
  const handleNavigateToOrganizationDetails = (org) => {
    navigate("/bb/app/settings/organizationdetails", {state:{organizationData:org}});
    console.log(org);
  }


  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-5 items-center">
                  <div className='space-x-10 flex flex-row'>
                    <h1 className="text-lg font-semibold">Organization Lists</h1>
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="ml-4 p-1 border rounded"
                    />
                  </div>
                  <button onClick={handleCreate} className="bg-[#F7525A] text-white z-20 px-2 py-1 rounded"
                    title="" data-tooltip="Create Organization" style={{ '--tooltip-bg': 'black' }}>+ New</button>
                </div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                  {organizations && Array.isArray(organizations) && organizations.length > 0 ? (
                    <table className="w-full mb-5">
                      <thead className="sticky top-0 z-10 bg-[#fafafc] text-[#6c7184] border-b">
                        <tr className='border-b'>
                          <th className="text-xs py-3 text-center">
                            <input type="checkbox" className="mr-2" />
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('organization_name')}>
                            Organization ID {getSortIcon('organization_number') && <FontAwesomeIcon icon={getSortIcon('organization_name')} />}
                          </th>
                         
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('organization_name')}>
                            Organization Name {getSortIcon('organization_name') && <FontAwesomeIcon icon={getSortIcon('organization_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('company_name')}>
                            Company Name {getSortIcon('company_name') && <FontAwesomeIcon icon={getSortIcon('company_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('email')}>
                            Email {getSortIcon('email') && <FontAwesomeIcon icon={getSortIcon('email')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('mobile_number')}>
                            Mobile Number {getSortIcon('mobile_number') && <FontAwesomeIcon icon={getSortIcon('mobile_number')} />}
                          </th>
                          {/* <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('gst')}>
                            GST {getSortIcon('gst') && <FontAwesomeIcon icon={getSortIcon('gst')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('pan')}>
                            PAN {getSortIcon('pan') && <FontAwesomeIcon icon={getSortIcon('pan')} />}
                          </th> */}
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('address1')}>
                            Address {getSortIcon('address1') && <FontAwesomeIcon icon={getSortIcon('address1')} />}
                          </th>
                          {/* <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('city')}>
                            City {getSortIcon('city') && <FontAwesomeIcon icon={getSortIcon('city')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('pincode')}>
                            Pincode {getSortIcon('pincode') && <FontAwesomeIcon icon={getSortIcon('pincode')} />}
                          </th> */}
                           <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('state')}>
                            State {getSortIcon('state') && <FontAwesomeIcon icon={getSortIcon('state')} />}
                          </th>
                        </tr> 
                      </thead>
                      <tbody className='border-b border-t'>
                        {organizations.filter(
                          (org) =>
                            org?.organization_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            org?.company_name?.toLowerCase().includes(searchQuery.toLowerCase())
                        ).map((org) => (
                          <tr key={org._id} className="text-sm border-b">
                            <td className="py-2 text-center">
                              <input type="checkbox" className="mr-2" />
                            </td>
                            <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" onClick={()=>handleNavigateToOrganizationDetails(org)}>{org.organization_number}</td>
                            <td className="py-2 text-center" >{org.organization_name}</td>
                            <td className="py-2 text-center">{org.company_name}</td>
                            <td className="py-2 text-center">{org.email}</td>
                            <td className="py-2 text-center">{org.mobile_number}</td>
                            {/* <td className="py-2 text-center">{org.gst}</td>
                            <td className="py-2 text-center">{org.pan}</td> */}
                            <td className="py-2 text-center">{org.address1}</td>
                            {/* <td className="py-2 text-center">{org.city}</td>
                            <td className="py-2 text-center">{org.pincode}</td>*/}
                            <td className="py-2 text-center">{org.state}</td> 
                            <td className="py-2 text-center">
                              {/* Removed handleNavigateToQuoteDetails usage */}
                            </td>
                          </tr>
                        ))}
                        {organizations.filter(
                          (org) =>
                            org?.organization_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            org?.company_name?.toLowerCase().includes(searchQuery.toLowerCase())
                        ).length === 0 && (
                          <tr>
                            <td colSpan="12" className="text-center text-[#6c7184] py-4">
                              Not found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center py-4">No organizations found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationList;
