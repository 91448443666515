import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401, Msg403, Msg500 } from '../Messages/Messages';
import search from '../Components/Search';
import Search from '../Components/Search';

const Allvendors = () => {
  const navigate = useNavigate();
  const [vendors, setvendors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [retryFunction, setRetryFunction] = useState(null);
  const [selectedVendors, setSelectedVendors] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [filteredVenors, setFilteredVendors] = useState([]);
  const searchTypes = ['Vendor Name', 'Company Name', 'Item Category'];

  const getStatusColor = (status) => {
    switch (status) {
      case 'DRAFT':
        return 'text-gray-500';
      case 'INVOICED':
        return 'text-green-500';
      case 'SENT':
        return 'text-blue-500';
      default:
        return '';
    }
  };

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...vendors].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setvendors(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  useEffect(() => {
    handleSearch();
  }, [vendors]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredVendors(vendors);
      return;
    }

  
  
    const filtered = vendors.filter((vendor) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'Vendor Name':
          return vendor.name?.toLowerCase().includes(lowercaseQuery);
        case 'Company Name':
          return vendor.company_name?.toLowerCase().includes(lowercaseQuery);
         case 'Item Category':
          return vendor.categories?.some(category => 
            category.toLowerCase().includes(lowercaseQuery)
          );
        default:
          return false;
      }
    });

    setFilteredVendors(filtered);
  };

  const fetchVendors = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `vendor/get-all-vendors?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });

      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setvendors(data.data); // Update vendors state
        setIsLoading(false);
        console.log(data.data);
      }else if (data.status === 500) {
        setIsLoading(false);
        setRetryFunction(() => fetchVendors);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      } 
      else {
        setIsLoading(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchVendors(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchVendors(attempt)); // Set retry function
      }
    }
  };

  {/* get-vendorsBy-productCategoryId */}

  useEffect(() => {
    fetchVendors();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const handleCheckboxChange = (vendor) => {
    if (selectedVendors.includes(vendor)) {
      setSelectedVendors(selectedVendors.filter((v) => v !== vendor));
      setSelectAll(false); // Update selectAll state when deselecting a checkbox
    } else {
      setSelectedVendors([...selectedVendors, vendor]);
      if (selectedVendors.length === vendors.length - 1) {
        setSelectAll(true); // Update selectAll state when selecting all checkboxes
      }
    }
  };
  
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedVendors([]);
      setSelectAll(false);
    } else {
      setSelectedVendors(vendors);
      setSelectAll(true);
    }
  };

  const handleDelete = async (attempt = 1) => {
    const selectedVendorIds = selectedVendors.map((vendor) => vendor._id);
    console.log(selectedVendorIds);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "vendor/delete-vendors", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({ vendorIds: selectedVendorIds })
      });
  
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // Update the vendors state to reflect the deleted vendors
        setvendors(vendors.filter((vendor) => !selectedVendorIds.includes(vendor.id)));
        setSelectedVendors([]);
        setSelectAll(false);
      } else {
        // Handle error cases
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleDelete(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleDelete(attempt)); // Set retry function
      }
    }
  };

  const handleCreate = () => {
    navigate("/bb/app/purchases/newvendor");
  };

  const handleNavigateVendorDetails = (vendor) => {
    navigate("/bb/app/purchases/vendordetails", { state: { VendorData: vendor } });
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };

  const Retry = async () => {
    console.log("Retry called");
    setIsLoading(true); // Show loader
    setTimeout(async () => {
      if (retryFunction) {
        await retryFunction();
      }
      setIsLoading(false); // Hide loader after retry
    }, 5000); // 5-second delay
    setShowPopup(false);
  };

  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
    navigate('/bb');
  };

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex w-full flex-row">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between ">
              <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-6 items-center">
  {selectedVendors.length > 0 ? (
    <button onClick={handleDelete}
      className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md ">Delete</button>
  ) : (
    <>
     <div className='space-x-10 flex flex-row'>
      <h1 className="text-lg font-bold">All vendors</h1>
     <Search onSearch={handleSearch} searchTypes={searchTypes} />
        </div>
      <button onClick={handleCreate}
        className="bg-[#E54643] text-white z-20 px-2 py-1 rounded"
        title="" data-tooltip="Create Vendor"  style={{ '--tooltip-bg': 'black' }}>+ New</button>
    </>
  )}
</div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden ">
                <div className="overflow-y-auto h-[80vh] ">
                  {vendors.length > 0 ? (
                    <table className="w-full mb-5 ">
                      <thead className="sticky top-0  bg-[#fafafc] text-[#6c7184] border-b ">
                        <tr className='border-b'>
                        <th className="text-xs py-3 text-center ">
                        <input
        type="checkbox"
        className="mr-2"
        onChange={handleSelectAll}
        checked={selectAll}
      />
    </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('name')}>
                            VENDOR NAME {getSortIcon('name') && <FontAwesomeIcon icon={getSortIcon('name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('company_name')}>
                            COMPANY NAME {getSortIcon('company_name') && <FontAwesomeIcon icon={getSortIcon('company_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('categories')}>
                            ITEM CATEGORY {getSortIcon('categories') && <FontAwesomeIcon icon={getSortIcon('categories')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('city')}>
                            CITY {getSortIcon('city') && <FontAwesomeIcon icon={getSortIcon('city')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('state')}>
                            STATE {getSortIcon('state') && <FontAwesomeIcon icon={getSortIcon('state')} />}
                          </th>
                          <th className="text-xs py-3 text-center">STATUS</th>
                        </tr>
                      </thead>
                      <tbody className='border-b border-t'>
                      {filteredVenors.length > 0 ? (
    filteredVenors.map((vendor) => (
                           <tr key={vendor._id} className="text-sm border-b">
                           <td className="py-2 text-center">
                             <input
                               type="checkbox"
                               className="mr-2"
                               onChange={(e) => handleCheckboxChange(vendor)}
                               checked={selectedVendors.includes(vendor) || selectAll}
                             />
                           </td>
                            <td onClick={() => handleNavigateVendorDetails(vendor)}
                              className="py-2 text-center text-[#408dfb] font-500 cursor-pointer">{vendor.name}</td>
                            <td className="py-2 text-center">{vendor.company_name}</td>
                            <td className="py-2 text-center">{vendor.categories.join(', ')}</td>
                            <td className="py-2 text-center">{vendor.city}</td>
                            <td className={`py-2 text-center ${getStatusColor(vendor.mobile_number)}`}>{vendor.state}</td>
                            <td className="py-2 text-center">{vendor.is_active ? 'Active' : 'deactive'}</td>
                          </tr>
                        ))
                      ) : (
                        
      <tr>
    <td colSpan="10" className="text-center text-[#6c7184] py-4">
    Not found
        </td>
      </tr>
    )}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      <div className='mt-2 mb-6 text-md flex justify-center items-center'>
                        No Vendors Found.
                      </div>
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Create New vendor</h1>
                      </div>
                      <div className="flex justify-center items-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div>
          {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
          {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    </div>
  );
};

export default Allvendors;
