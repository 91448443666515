import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faImage } from "@fortawesome/free-solid-svg-icons";

function ProfileContainer(){
    const Navigate = useNavigate();
    const [initialFirstName, setInitialFirstName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUserName] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [email, setEmail] = useState("");
    const [uploadedImage, setUploadedImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [ProfileData,setProfileData]=useState()
    const [file,setfile]=useState(null)
    const [profileImage,setprofileImage]= useState()
    const [UserImage, seUserImage] = useState(uploadedImage);
    const ForgotPassword = process.env.REACT_APP_FORE_APILINK + '/user/user-forgot-password';
   
    const getUserDetails = async (_id) => {
        const GetUserData = `${process.env.REACT_APP_FORE_APILINK}/user/get-user-details?userId=${_id}`;
    
          try {
            const token=sessionStorage.getItem('token');
            const response = await fetch(GetUserData, {
              method: 'GET',
              headers: {
                  'x-token': token
              }
          });
            const data = await response.json();
            // Update state with user details
            setInitialFirstName(data.user.firstName);
            setFirstName(data.user.firstName);
            setLastName(data.user.lastName);
            setUserName(data.user.username);
            setMobileNo(data.user.mobileNo);
            setEmail(data.user.email);
            setprofileImage(data.user.userProfileImage || null);
            setProfileData(data.user);
          } catch (error) {
            clearStorageAndRedirectToHome()
          }
        };
    
        useEffect(()=>{
          const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
          const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
          if(storedLoginData&&Userdata){
            if(storedLoginData.token===Userdata.token){
            }else{
              Navigate('/measurit')
             sessionStorage.removeItem('userRole');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userData');
            }
          }else{
             Navigate('/measurit')
              sessionStorage.removeItem('userRole');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userData');
          }
        })
    
    // Fetch user details when the component mounts
    useEffect(() => {
      const storedUserData = sessionStorage.getItem('userData');
    
      if (storedUserData !== null) {
        const storedUserDetails = JSON.parse(storedUserData);
        const userId = storedUserDetails.userId;
        getUserDetails(userId);
      } else {
        // Handle the case where no data is stored under the key 'userData'
      }
    }, []); // Empty dependency array ensures this effect runs only once, equivalent to componentDidMount
    // Empty dependency array ensures this effect runs only once, equivalent to componentDidMount
    
  
  useEffect(() => {
  }, [firstName, lastName, username, mobileNo, email]);
  
  const handleInputChange = (field, value) => {
  
    if (field === "firstName") {
      setFirstName(value);
    } else if (field === "lastName") {
      setLastName(value);
    } else if (field === "username") {
      setUserName(value);
    } else if (field === "mobileNo") {
      if(!isNaN(value)){
        setMobileNo(value.slice(0,10));
      } 
    } else if (field === "email") {
      setEmail(value);
    }
  };
  
  
  const emailRegex = /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
  
  const handleUpdate = async () => {
    // Validate input fields
  
    const newErrors = {};
  
    if (!firstName.trim()) {
      newErrors.firstName = "Required*";
    }
  
    if (!lastName.trim()) {
      newErrors.lastName = "Required*";
    }
  
    if (!username.trim()) {
      newErrors.username = "Required*";
    }
  
    if (!/^\d{10}$/.test(mobileNo)) {
      newErrors.mobileNo = "Mobile No must be 10 digits*";
    }
  
    if (!emailRegex.test(email)) {
      newErrors.email = "Enter a valid email address*";
    }
  
    // If there are errors, set them in the state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        const token = sessionStorage.getItem('token');
        const formData = new FormData();
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("username", username);
        formData.append("mobileNo", mobileNo);
        formData.append("email", email);
        formData.append("_id", ProfileData._id);
        formData.append("file", file);
        // Make a PUT request to update user details
        const UpdateProfileData = `${process.env.REACT_APP_FORE_APILINK}/user/update-user-details`;
        const response = await fetch(UpdateProfileData, {
          method: 'PUT',
          headers:{
            'x-token': token
          },
          body: formData, /* JSON.stringify({
            firstName,
            lastName,
            username,
            mobileNo,
            email,
            _id:ProfileData._id,
            file:file
          }), */
        });
  
        if (response.ok) {
            // If the update is successful, update the initial first name
            setInitialFirstName(firstName);
            setPopupVisible(true); // Show the popup on successful update
            const updatedData = await response.json();
            setEditModeIndex(false);
        } else {
          // Handle errors from the server
        }
      } catch (error) {
        clearStorageAndRedirectToHome()
      }
    }
  };
  
  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }
  
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setUploadedImage(URL.createObjectURL(file));
    seUserImage(URL.createObjectURL(file)|| uploadedImage )
    setfile(file)
  };
  
  
  const [editModeIndex, setEditModeIndex] = useState(false);
  
  const handleEditClick = () => {
    // Set edit mode for the selected index
    setEditModeIndex(true);
  };
  
  
  const handleChangePassword = async () => {
    const loginData = JSON.parse(localStorage.getItem('LoginData'));
    Navigate('/measurit/ResetPassword', { state: loginData });
  };



return ( 
 <div className="overflow-x-scroll no-scrollbar">
 {/* Profile  Container */}
 <div className="ml-10 flex-grow">
 <div className="flex items-center justify-center h-screen mt-2 md:mt-0">
 <div className="bg-[#959BB0] bg-opacity-[15%] w-full max-w-[400px] flex-col px-4 py-4 rounded-sm max-md:w-full max-md:px-3">
   {/* Profile Image */}
   <div className="mb-4 flex items-center justify-center">
 {UserImage || profileImage ? (
   <div className="relative">
     <img
        src={UserImage || process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL + profileImage}
       alt="Uploaded Profile"
       className="w-20 h-20 object-cover rounded-full border-4 border-white"
     />
     <label
       htmlFor="imageUpload"
       className="absolute top-14 right-1 text-blue-500 text-sm underline hover:text-blue-900 cursor-pointer"
     >
       {/* Upload Image Icon */}
       <FontAwesomeIcon icon={faCamera} />
       <input
         id="imageUpload"
         type="file"
         accept="image/*"
         className="hidden"
         onChange={handleImageUpload}
       />
     </label>
   </div>
 ) : (
   <label htmlFor="imageUpload" className="cursor-pointer">
     <span className="hover:text-gray-700 text-gray-500 text-4xl mr-2">
       <FontAwesomeIcon icon={faImage} />

     </span>
     <input
       id="imageUpload"
       type="file"
       accept="image/*"
       className="hidden"
       onChange={handleImageUpload}
     />
   </label>
 )}
</div>

    {/* Profile Image Ends*/}

   <div >
   <div className="mb-4 relative">
 {/* First Name */}
 <label className="text-gray-600 text-sm mb-1 block">First Name</label>
 <input
   type="text"
   className={`border bg-white w-full rounded-sm ${
     errors.firstName ? "border-red-500" : ""
   } ${editModeIndex === false? 'bg-gray-300  ':'bg-white'} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
   value={firstName}
   onChange={(e) => handleInputChange("firstName", e.target.value)}
   disabled={editModeIndex === false}
 />

 {errors.firstName && (
   <p className="absolute top-1 right-4  text-red-500 text-sm ">{errors.firstName}</p>
 )}
</div>


     <div className="mb-4 relative" >
       {/* Last Name */}
       <label className="text-gray-600 text-sm mb-1 block">Last Name</label>
       <input
         type="text"
         className={`border bg-white w-full rounded-sm ${
           errors.lastName ? "border-red-500" : ""
         } ${editModeIndex === false?'bg-gray-300':'bg-white'} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
         value={lastName}
         onChange={(e) => handleInputChange("lastName", e.target.value)}
         disabled={editModeIndex === false}
       />
       {errors.lastName && (
         <p className="absolute top-1 right-4  text-red-500 text-sm">{errors.lastName}</p>
       )}
     </div>

     <div>
 {/* User Name */}
 <div className="mb-4 relative" >
   <label className="text-gray-600 text-sm mb-1 block">User Name</label>
   <input
     type="text"
     name="username"
     className={`border bg-white w-full rounded-sm ${
       errors.username ? "border-red-500" : ""
     } ${editModeIndex === false?'bg-gray-300':'bg-white'} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
     value={username}
     onChange={(e) => handleInputChange("username", e.target.value)}
     disabled={editModeIndex === false}
   />
   {errors.username && (
     <p className="absolute top-1 right-4  text-red-500 text-sm">{errors.username}</p>
   )}
 </div>

 {/* Mobile No */}
 <div className="mb-4 relative" >
   <label className="text-gray-600 text-sm mb-1 block">Mobile No</label>
   <input
     pattern="[0-9]{10}"
     className={`border bg-white w-full rounded-sm ${
       errors.mobileNo ? "border-red-500" : ""
     } ${editModeIndex === false?'bg-gray-300':'bg-white'} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
     value={mobileNo}
     onChange={(e) => handleInputChange("mobileNo", e.target.value)}
     disabled={editModeIndex === false}
   />
   {errors.mobileNo && (
     <p className="absolute top-1 right-4  text-red-500 text-sm">{errors.mobileNo}</p>
   )}
 </div>

 {/* Email */}
 <div className="mb-4 relative" >
   <label className="text-gray-600 text-sm mb-1 block">Email</label>
   <input
     type="email"
     pattern="[a-zA-Z0-9]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
     className={`border bg-white w-full rounded-sm ${
       errors.email ? "border-red-500" : ""
     } ${editModeIndex === false?'bg-gray-300':'bg-white'} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
     value={email}
     onChange={(e) => handleInputChange("email", e.target.value)}
     disabled={editModeIndex === false}
   />
   {errors.email && (
     <p className="absolute top-1 right-4  text-red-500 text-sm">{errors.email}</p>
   )}
 </div>
</div>


   </div>

   {/* Change Password Link */}
   <a
     onClick={handleChangePassword}
     className="flex justify-center items-center text-[14px] text-blue-500 underline cursor-pointer hover:text-blue-900 mt-2"
   >
     Change Password?
   </a>

     {/* Update Button */}
     <div className="flex justify-center mt-2">
       {/* <button
         className="text-white text-sm bg-green-600 hover:bg-green-700 py-2 px-4 rounded-sm transition duration-300"
         type="button"
         onClick={handleUpdate}
       >
         Update
       </button> */}
        {editModeIndex === true ? (
           <button
             className="text-white text-opacity-100 text-base whitespace-nowrap bg-[#0f9d58] hover:bg-green-600 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300"
             type="button"
             onClick={() => handleUpdate()}
           >
             Update
           </button>
         ) : (
           <button
             className="text-white text-opacity-100 text-base whitespace-nowrap bg-blue-500 hover:bg-blue-700 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300"
             type="button"
             onClick={() => handleEditClick()}
           >
             Edit
           </button>
         )}
     </div>

      {/* Popup */}
      {isPopupVisible && (
       <div className="fixed inset-0 flex items-center justify-center">
       {/* Background overlay (blurred) */}
       <div className="absolute inset-0 bg-black opacity-30 "></div>

       {/* Popup content */}
       <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
         <p className="text-lg font-bold mb-2">Update Successful!</p>
         <button
           className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
           onClick={() => setPopupVisible(false)}
         >
           Ok
         </button>
       </div>
     </div>

     )}
   </div>
   </div>
   </div>
</div>
)

}

export default ProfileContainer;