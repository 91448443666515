import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faSortUp, faSortDown, faX , faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Loader, Spinner} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import Search from '../Components/Search';

const SalesOrdersList = () => {
  const navigate = useNavigate();
 const [productCategories, setProductCategories] = useState([]);
 const [searchQuery, setSearchQuery] = useState('');
 const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);

    const [salesOrders, setSalesOrders] = useState([]);
    //console.log(salesOrders);
  const [isLoading, setIsLoading] = useState(true);
  const url = process.env.REACT_APP_FORE_BRICKBUCKET;
  //const [showPopup, setShowPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
  //const [popupType, setPopupType] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
 // const [retryFunction, setRetryFunction] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
  const [SOItemQty, setSOItemQty] = useState(false);
  const [ReceivableQty, setReceivableQty] = useState(false);
  const [PendingQty, setPendingQty] = useState(false);
  const [createPOButtonEnabled, setCreatePOButtonEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState(null);
const [showCancelPopup , setShowCancelPopup] = useState(false);
const [showSOItemStatusPopup ,setShowSOItemStatusPopup] = useState(false);
const [orderItemsDataOfSO,setOrderItemsDataOfSO] = useState([]);
const [roleId, setRoleId] = useState(null);
const [filteredSO, setFilteredSO] = useState([]);
const searchTypes = ['SO Number', 'Customers', 'Item Category', 'SO Status'];

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

  // Function to get nested value using key
  const getNestedValue = (item, key) => {
    return key.split('.').reduce((value, keyPart) => {
      return value ? value[keyPart] : undefined;
    }, item);
  };

  const sortedArray = [...salesOrders].sort((a, b) => {
    const aValue = getNestedValue(a, key) || ''; // Treat undefined as empty string
    const bValue = getNestedValue(b, key) || ''; // Treat undefined as empty string

    // Convert to string for comparison and handle case insensitivity
    const aString = aValue.toString().toLowerCase();
    const bString = bValue.toString().toLowerCase();

 

    if (aString < bString) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (aString > bString) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  setSalesOrders(sortedArray);
};

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

 /*  const handleCheckboxChange = (salesOrder) => {
    
  
    // Check if the combination is already selected
    const isAlreadySelected = selectedCategories.some(
      (selected) =>
        selected.salesOrderId === salesOrder.salesorder_id._id &&
        selected.itemId === salesOrder.item_id &&
        selected.productCategoryId === salesOrder.salesorder_id.product_category_id._id && selected.vendorId === salesOrder.vendor_id &&
        selected.salesOrderType === 'converted'
    );
  
    // Update the selected categories
    const updatedSelectedCategories = isAlreadySelected
      ? selectedCategories.filter(
          (selected) =>
            !(selected.salesOrderId === salesOrder.salesorder_id._id &&
              selected.itemId === salesOrder.item_id &&
              selected.productCategoryId === salesOrder.salesorder_id.product_category_id._id && selected.vendorId === salesOrder.vendor_id,
              selected.salesOrderType === 'converted')
        )
      : [
          ...selectedCategories,
          {
            salesOrderId: salesOrder.salesorder_id._id,
            itemId: salesOrder.item_id,
            productCategoryId: salesOrder.salesorder_id.product_category_id._id, // Add productCategoryId here
            vendorId: salesOrder.vendor_id,
            salesOrderType: 'converted' 

          }
        ];
  
    // Update the state
    setSelectedCategories(updatedSelectedCategories);
  
    // Update the selectAll state if all items are selected
    setSelectAll(updatedSelectedCategories.length === salesOrders.length);
  
    // Enable the Create Purchase Order button if at least one item is selected
    setCreatePOButtonEnabled(updatedSelectedCategories.length > 0);
  }; */

  useEffect(() => {
    handleSearch();
  }, [salesOrders]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredSO(salesOrders);
      return;
    }
  
    const filtered = salesOrders.filter((salesOrder) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'SO Number':
          return salesOrder.salesorder_number?.toLowerCase().includes(lowercaseQuery);
        case 'Customers':
          return salesOrder?.customer_id?.customer_name?.toLowerCase().includes(lowercaseQuery);
        case 'Item Category':
          return salesOrder.product_category_id?.product_category_name?.toLowerCase().includes(lowercaseQuery);
        case 'SO Status':
          return salesOrder.salesorder_status?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });

    setFilteredSO(filtered);
  };
  
  const handleCheckboxChange = (salesOrder) => {
    // Check if any item is already selected
    if (selectedCategories.length > 0) {
      const firstSelected = selectedCategories[0];
  
      // Check if the selected salesOrder has the same product category ID and vendor ID
      const isSameCategoryAndVendor =
        firstSelected.productCategoryId === salesOrder.product_category_id._id; /* &&
        firstSelected.vendorId === salesOrder.vendor_id; */
  
      if (!isSameCategoryAndVendor) {
        // Show popup if the product category ID or vendor ID is different
        setShowCancelPopup(true);
        return;
      }
    }
  
    // Check if the combination is already selected
    const isAlreadySelected = selectedCategories.some(
      (selected) =>
        selected.salesOrderId === salesOrder._id &&
        //selected.itemId === salesOrder.item_id &&
        selected.productCategoryId === salesOrder.product_category_id._id &&
        //selected.vendorId === salesOrder.vendor_id &&
        selected.salesOrderType === 'converted'
    );
  
    // Update the selected categories
    const updatedSelectedCategories = isAlreadySelected
      ? selectedCategories.filter(
          (selected) =>
            !(
              selected.salesOrderId === salesOrder._id &&
              //selected.itemId === salesOrder.item_id &&
              selected.productCategoryId === salesOrder.product_category_id._id &&
              //selected.vendorId === salesOrder.vendor_id &&
              selected.salesOrderType === 'converted'
            )
        )
      : [
          ...selectedCategories,
          {
            
            salesOrderId: salesOrder._id,
            //itemId: salesOrder.item_id,
            productCategoryId: salesOrder.product_category_id._id,
            vendor_id: salesOrder.vendor_id,
            salesOrderType: 'converted',
          },
        ];
        console.log(salesOrder);
    // Update the state
    setSelectedCategories(updatedSelectedCategories);
  
    // Update the selectAll state if all items are selected
    setSelectAll(updatedSelectedCategories.length === salesOrders.length);
  
    // Enable the Create Purchase Order button if at least one item is selected
    setCreatePOButtonEnabled(updatedSelectedCategories.length > 0);
  };
  
  
  

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(salesOrders.map(order => order.id));
    }
    setSelectAll(!selectAll); // Toggle the "Select All" checkbox state
  };

  const handleCreatePurchaseOrder = () => {
    // Log the selected sales order IDs and item IDs
    console.log('Selected Sales Orders and Item IDs:', selectedCategories);
  
    // Navigate to the new purchase order page
    navigate('/bb/app/purchases/newpurchaseorder',{ state: { selectedCategories } });
  };
  
  

  const handleCreate = () => {
    navigate("/bb/app/sales/newsalesorder");
  };

  const handlenavigateToSalesOrderDetails = (salesOrder) => {
    navigate("/bb/app/sales/salesorderdetails", {state:{SalesOrderData:salesOrder}});
    console.log(salesOrder);
  }

  useEffect(() => {
    fetchRoleId();
    fetchSalesOrder();
  }, []);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const salesOrdersToDisplay = salesOrders && roleId === 6 
  ? salesOrders.filter(salesorder => (salesorder.product_category_id?.product_category_name.toLowerCase()) === 'tiles')
  : salesOrders || [];
  console.log(salesOrdersToDisplay);

  const fetchSalesOrder = async (attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/get-all-salesorders?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
  
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setSalesOrders(data.data);
        console.log(data.data);
        setIsLoading(false);
      } else if (data.status === 500) {
        setIsLoading(false);
        setRetryFunction(() => fetchSalesOrder);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      } else {
        setIsLoading(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchSalesOrder(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => fetchSalesOrder(attempt)); // Set retry function
      }
    }
  };
  
  if (isLoading) {
    return <Loader />;
  }

  const handleDelete = async (attempt = 1) => {
    const selectedCategoryIds = selectedCategories.map((category) => category._id);
    console.log(selectedCategoryIds);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "product-category/delete-product-categories", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({ productCategoryIds: selectedCategoryIds })
      });
  
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // Update the vendors state to reflect the deleted vendors
        setProductCategories(productCategories.filter((category) => !selectedCategoryIds.includes(category._id)));
        setSelectedCategories([]);
        setSelectAll(false);
      } else {
        // Handle error cases
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleDelete(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleDelete(attempt)); // Set retry function
      }
    }
  };


 /* Still Modifications have to be done for the below fetch call */
 const FetchAggregatedOrderItemsDataofSalesOrder = async (salesOrder, attempt = 0) => {
  console.log('FetchAggregatedOrderItemsDataofSalesOrder called:', salesOrder);
  try {
    console.log('Making GET request to server...');
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/get-salesorder-items-by-salesorder?salesorder_id=${salesOrder._id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
    });
    console.log('Response received:', response);
    const data = await response.json();
    console.log('Data:', data);
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      setOrderItemsDataOfSO(data.data);
      console.log('Order items data:', data.data);
      setIsLoading(false);
    } else if (data.status === 500) {
      setIsLoading(false);
      setRetryFunction(() => fetchSalesOrder);
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    } else {
      setIsLoading(false);
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => FetchAggregatedOrderItemsDataofSalesOrder(salesOrder, attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => FetchAggregatedOrderItemsDataofSalesOrder(salesOrder, attempt)); // Set retry function
    }
  }
};
  const handleSOItemsStatusPOPUp = (salesOrder) => {
    setShowSOItemStatusPopup(true);
    console.log(salesOrder);
    FetchAggregatedOrderItemsDataofSalesOrder(salesOrder);
  }


  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleClickQuantity = () => {
    setSOItemQty(true);
  }

  const handleClickReceivableQty = () => {
    setReceivableQty(true);
  }

  const handleClickPendingQty = () => {
    setPendingQty(true);
  }

  const handleClosePopup = () => {
    setSOItemQty(false);
    setReceivableQty(false);
    setPendingQty(false);
  };

  function formatSalesOrderDate(dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1; // add 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function calculateAgingDays(dateStr) {
    const salesOrderDate = new Date(dateStr);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - salesOrderDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24));
    return diffInDays;
  }

  
  const handleStayHere = () => {
    setShowCancelPopup(false);
    setShowSOItemStatusPopup(false);
  }

  const formatNumber = (value) => {
    // Parse the input as a number
    const parsedValue = parseFloat(value);
  
    // Check if the parsed value is a finite number
    if (Number.isFinite(parsedValue)) {
      // Round to two decimal places
      const roundedValue = (Math.round(parsedValue * 100) / 100);
    // Format the number with commas and two decimal places
    return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
    }
  
    // Return '0.00' if the value is not a valid number
    return '0.00';
  };

  
  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-4 items-center">
  {selectedCategories.length > 0 ? (
    <>
 <div className="flex space-x-2">
        <button
          onClick={handleDelete}
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
        >
          Delete
        </button>
       <button
          onClick={handleCreatePurchaseOrder}  // Ensure this function is defined
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
          disabled={!createPOButtonEnabled}
        >
          Create Purchase Order
        </button>
      </div>
    </>
  ) : (
    <>
    <div className='space-x-10 flex flex-row'>
      <h1 className="text-lg font-bold">All Sales Orders</h1>
     <Search onSearch={handleSearch} searchTypes={searchTypes}/>
      </div>
      <button
        onClick={handleCreate}
        className="bg-[#F7525A] text-white px-2 py-1 rounded"
      >
        + New
      </button>
    </>
  )}
</div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                  {salesOrdersToDisplay.length > 0 ? (
                    <table className="w-full mb-5">
                      <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
                        <tr className='border-b'>
                          <th className="text-xs py-3 text-center">
                          {/* <input   type="checkbox"
        className="mr-2"
        onChange={handleSelectAll}
        checked={selectAll}
      /> */}
    </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('salesorder_date')}>
                            Date {getSortIcon('salesorder_date') && <FontAwesomeIcon icon={getSortIcon('salesorder_date')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('aging')}>
                            Aging {getSortIcon('aging') && <FontAwesomeIcon icon={getSortIcon('aging')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('salesorder_number')}>
                            Sales Order {getSortIcon('salesorder_number') && <FontAwesomeIcon icon={getSortIcon('salesorder_number')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('salesorder_number')}>
                            Customer {getSortIcon('salesorder_number') && <FontAwesomeIcon icon={getSortIcon('salesorder_number')} />}
                          </th>
                          <th className="text-xs py-3 text-center" >
                            Location 
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('salesorder_status')}>
                            SO Status {getSortIcon('salesorder_status') && <FontAwesomeIcon icon={getSortIcon('salesorder_status')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_category_id.product_category_name')}>
                            Item Category {getSortIcon('product_category_id.product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_id.product_category_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer">
                            SO Items Status 
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('item_po_status')}>
                            Amount {getSortIcon('item_po_status') && <FontAwesomeIcon icon={getSortIcon('item_po_status')} />}
                          </th>
                        </tr>
                        
                      </thead>
                      <tbody className="border-b border-t">
                      {filteredSO.length > 0 ? (
    filteredSO.map((salesOrder) => (
          <tr key={salesOrder._id}  className="text-sm border-b" onClick={() => setSelectedSalesOrder(salesOrder)}> 
            <td className="py-2 text-center">
            <input
  type="checkbox"
  className="mr-2"
  checked={selectedCategories.some(
    (selected) =>
      selected.salesOrderId === salesOrder._id
    && 
      selected.productCategoryId === salesOrder.product_category_id._id &&
      selected.salesOrderType === 'converted'
  )}
  onChange={() => handleCheckboxChange(salesOrder)}
/>

            </td>
            <td className="py-2 text-center">  {formatSalesOrderDate(salesOrder.salesorder_date)}</td>
            <td className="py-2 text-center">{calculateAgingDays(salesOrder.salesorder_date)} days</td>
            <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" onClick={()=> handlenavigateToSalesOrderDetails(salesOrder)}>{salesOrder.salesorder_number}</td>
            <td className="py-2 text-center">{salesOrder?.customer_id?.customer_name}</td>
            <td className="py-2 text-center">{salesOrder.location}</td>
            <td className="py-2 text-center">{salesOrder.salesorder_status}</td>
            <td className="py-2 text-center">{salesOrder.product_category_id?.product_category_name}</td>
          
            <td className="py-2 text-center text-[#408dfb] cursor-pointer" onClick={()=> handleSOItemsStatusPOPUp(salesOrder)}>View</td>
            <td className="py-2 text-center">₹ {formatNumber(salesOrder.total)}</td>

          </tr>
        ))
      ) : (
        <tr>
        <td colSpan="10" className="text-center text-[#6c7184] py-4">
          Not found
        </td>
      </tr>
    )}
      </tbody>
                    </table>
                    
                  ) : (
                    <>
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Create Sales Order</h1>
                      </div>
                      <div className="flex justify-center items-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     {/*  pop up starts */}
     {SOItemQty && selectedSalesOrder && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">SO Item Quantity</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />

      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Design Name</th>
              <th className="border px-4 py-2 text-left">Boxes</th>
            </tr>
          </thead>
          <tbody>
            {selectedSalesOrder.required_order_breakup && selectedSalesOrder.required_order_breakup.map((breakup, index) => (
              <tr key={`${selectedSalesOrder._id}-${breakup._id}-${index}`}>
                <td className="border px-4 py-2">{breakup.sub_item_name || "N/A"}</td>
                <td className="border px-4 py-2">{breakup.sub_item_quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}


      {ReceivableQty && salesOrdersToDisplay && salesOrdersToDisplay.length > 0 && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Receivable Quantity</h2>
        <button  onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Design</th>
              <th className="border px-4 py-2 text-left">Boxes</th>
            </tr>
          </thead>
          {salesOrdersToDisplay.map((salesOrder) => (
              salesOrder.selected_designs && salesOrder.selected_designs.map((design, index) => (
                <tr key={`${salesOrder.id}-${design.subitem_id}-${index}`}>
                  <td className="border px-4 py-2">Design {index + 1}</td>
                  <td className="border px-4 py-2">{design.sub_item_receivable_quantity}</td>
                </tr>
              ))
            ))}
        </table>
      </div>
    </div>
  </div>
)}

{PendingQty && salesOrdersToDisplay && salesOrdersToDisplay.length > 0 && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Pending Quantity</h2>
        <button  onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Design</th>
              <th className="border px-4 py-2 text-left">Boxes</th>
            </tr>
          </thead>
          {salesOrdersToDisplay.map((salesOrder) => (
              salesOrder.selected_designs && salesOrder.selected_designs.map((design, index) => (
                <tr key={`${salesOrder.id}-${design.subitem_id}-${index}`}>
                  <td className="border px-4 py-2">Design {index + 1}</td>
                  <td className="border px-4 py-2">{design.sub_item_pending_quantity}</td>
                </tr>
              ))
            ))}
        </table>
      </div>
    </div>
  </div>
)}
      {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[200px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '200px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Unable to select?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      Kindly select Sales Order with same Item Category.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Okay
  </button>
        </div>
      </div>
    </div>
  </div>
)}

{showSOItemStatusPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[600px] flex flex-col animate-slide-in" style={{ maxWidth: '550px', maxHeight: '800px' }}>
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6">
        <h2 className="text-md font-semibold">
          SO Item Details
        </h2>
        <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="text-sm bg-gray-100">
              <th className="border px-2 py-1 text-left">Items</th>
              <th className="border px-2 py-1 text-middle">Item SO Status</th>
              <th className="border px-2 py-1 text-middle">Item PO Status</th>
              <th className="border px-2 py-1 text-middle">Item PO Number</th>
            </tr>
          </thead>
          <tbody>
            {orderItemsDataOfSO.map((item, index) => (
              <tr key={`${item.salesorder_id}-${index}`}>
                <td className="text-sm border px-2 py-1 text-middle">{item.item_name}</td>
                <td className="text-sm border px-2 py-1 text-middle">{item.item_SO_status}</td>
                <td className="text-sm border px-2 py-1 text-middle">{item.item_PO_status}</td>
                <td className="text-sm border px-2 py-1 text-middle">{item.purchaseorder_number}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}

      {showPopup && (
        <div>
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    {/*   pop up end */}
    </div>
  );
};

export default SalesOrdersList;
