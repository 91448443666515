import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Main_Page from './mainPage';
import Home from './Home';
import TilesQuote from './TilesQuote';
import ViewTilesQuote from './ViewTilesQuote';
import ItemsforQuote from './ItemsforSO';
import ConsumerLogin from './ConsumerLogin';
import OTPpage from './OTPpage';
import AddUserDetails from './AddUserDetails';
import NotApproved from './NotApproved';
import AddProject from './AddProject';

 import DoorsQuote from './DoorsQuote';
 import WindowsQuote from './WindowsQuote';
 import ViewWindowsQuote from './ViewQuotationDetails';

function App() {
/*   const [appId, setappId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const BrickBucket = () => {
      if (location.pathname.startsWith('/measurit_v1.0')) {
        const storedLoginData = JSON.parse(localStorage.getItem('LoginData'));
        if (storedLoginData) {
          const appId = storedLoginData.appId;
        setappId(appId);
        setLoading(false);
        } else {
          // Handle case where user data is not available
          // For example, navigate to login page
          if (location.pathname.startsWith('/measurit_v1.0') && 
          !location.pathname.startsWith('/measurit_v1.0/reset-password') &&
          !location.pathname.startsWith('/measurit_v1.0/forgot-password') &&
          !location.pathname.startsWith('/measurit_v1.0/reset-password')
      ) {
          navigate('/measurit_v1.0/adminhome');
          setLoading(false);
      }
          
        }
      }
    };

    BrickBucket(); // Call BrickBucket function directly here
  }, [navigate, location.pathname]); // Add navigate and location.pathname as dependencies
 */

  /* if (loading) {
    return <div>Loading...</div>;
  } */

/*   const AccessDenied = () => {
    return (
      <div
        className="fixed inset-0 bg-gray-500/50 flex justify-center items-center"
        style={{ backdropFilter: 'blur(100px)' }}
        role="dialog"
        aria-modal="true"
      >
<div className="bg-white rounded shadow-md p-8 max-w-md">
  <h2 className="text-lg font-bold text-center">Access Denied</h2>
  <p className="text-gray-600 text-center">You do not have permission to access this page.</p>
  <div className="flex justify-center mt-2">
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded"
      onClick={() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/bb');
      }}
    >
      Go Back
    </button>
  </div>
</div>
      </div>
    );
  }; */

  return (
    <Routes>
       {/* <Route path="/bb" element={<Login />} /> */}
       <Route path='/consumer' element={<ConsumerLogin />} />
       <Route path='/consumer/otp' element={<OTPpage />} />
       <Route path='/consumer/UserDetails' element={<AddUserDetails/>}/>
       <Route path='/consumer/NotApproved' element={<NotApproved/>}/>
       <Route path='/consumer/addproject' element={<AddProject/>}/>
       
     <Route path="/consumer/app" element={<Main_Page />}>
       <Route path='home' element = {<Home/>}/> 
       <Route path='tilesquote' element = {<TilesQuote/>}/>
       <Route path='viewtilesquote' element = {<ViewTilesQuote/>}/>
       <Route path='itemsforso' element = {<ItemsforQuote/>} />
         <Route path='doorsquote' element = {<DoorsQuote/>} />
         <Route path='windowsquote' element = {<WindowsQuote/>} />     
        <Route path='windowsQuoteDetails' element = {<ViewWindowsQuote/>} /> 
      </Route>
   
    </Routes>
  );
}

export default App;
