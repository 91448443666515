import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Css/Tooltip.css';
import {
  faHomeAlt, faGear, faUserCircle, faPlus, faSearch,
  faUserGroup, faX, faSignOut, faBuilding,
  faBars
} from '@fortawesome/free-solid-svg-icons';
import { FaChevronDown, FaRegBell } from 'react-icons/fa';

const Navbar = () => {
  const navigate = useNavigate();
  const [searchDropDown, setSearchDropDown] = useState(false);
  const [userDropDown, setUserDropDown] = useState(false);
  const [orgPopUp, setOrgPopUp] = useState(false);
  const [type, setType] = useState('');
  const [userData, setUserData] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const ref = useRef(null);

  const List = ['Items', 'Customers', 'Vendors', 'Quotes'];

  useEffect(() => {
    const storedUserData = sessionStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const handleDropDownToggle = () => {
    setSearchDropDown(!searchDropDown);
  };

  const handleClickItem = (item) => {
    setType(item);
    setSearchDropDown(false);
  };

  const handleChangeSearch = () => {
    setSearchDropDown(false);
  };

  const handleUserClick = () => {
    setUserDropDown(!userDropDown);
  };

  const handleOrgClick = () => {
    setOrgPopUp(true);
  };

  const handleOrgClose = () => {
    setOrgPopUp(false);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchDropDown(false);
      setUserDropDown(false);
      setOrgPopUp(false);
    }
  };

  const handleClose = () => {
    setUserDropDown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/user/user-logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': sessionStorage.getItem('token')
        }
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Clear local storage and session storage
        localStorage.removeItem('LoginData');
        sessionStorage.clear();
  
        // Redirect to login page
        navigate('/bb');
      } else {
        // Handle logout error
/*         setPopupMessage(data.message || 'Logout failed');
        setPopupType('400');
        setShowPopup(true); */
      }
    } catch (error) {
      console.error('Error during logout:', error);
/*       setPopupMessage('Error during logout');
      setPopupType('500');
      setShowPopup(true); */
    }
  };
  

  const handleLogoClick = () => {
    navigate('/bb/app/home');
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="bg-[#21263c] text-[0.8125rem] font-normal w-full h-full flex items-center justify-between overflow-hidden py-2">
      <div className="flex flex-row space-x-4">
        <div className='w-[12.5rem] px-3'>
          <div className='flex flex-row'>
            <img className="w-8 h-8 cursor-pointer" src='/BB.png' alt="Brick Bucket Logo" onClick={handleLogoClick} />
            <p className="text-xl text-white ml-2">Brickbucket</p>
          </div>
        </div>
      </div>

      <div className="hidden md:flex items-center">
        <div className="flex items-center flex-row text-white space-x-6 mx-2">
          <p className="text-[1rem]">Welcome {userData?.username}</p>
        </div>
        <button className="text-white px-4 py-2 mx-2 text-[1rem] hover:bg-[#f7525a] hover:text-white" onClick={handleOrgClick}>
          {sessionStorage.getItem('organizationName')}
        </button>
        <div className="flex items-center flex-row space-x-6 mx-2">
          <FaRegBell className="text-white mx-2 w-4 h-4" />
          <FontAwesomeIcon icon={faUserCircle} className="cursor-pointer text-4xl text-white mx-2" onClick={handleUserClick} />
        </div>
      </div>

      <div className="md:hidden flex items-center">
        <FontAwesomeIcon icon={faUserCircle} className="cursor-pointer text-3xl text-white mx-2" onClick={handleUserClick} />
        {/* <FontAwesomeIcon icon={faBars} className="cursor-pointer text-2xl text-white ml-4" onClick={toggleMobileMenu} /> */}
      </div>

     {/*  {mobileMenuOpen && (
        <div className="absolute top-full left-0 right-0 bg-[#21263c] z-50 md:hidden">
          <div className="flex flex-col items-center py-4">
            <p className="text-white mb-2">Welcome {userData?.username}</p>
            <button className="text-white px-4 py-2 mb-2 hover:bg-[#f7525a] hover:text-white" onClick={handleOrgClick}>
              {sessionStorage.getItem('organizationName')}
            </button>
            <FaRegBell className="text-white mb-2" />
          </div>
        </div>
      )} */}


      {userDropDown && (
        <div ref={ref} className="absolute right-0 z-50 top-12 bg-white shadow-md border-[#6c7184] rounded-[0.5rem] w-96">
          <div className="p-4 bg-[#fbfbfb]">
            <div className="flex flex-col p-2">
              <div className="flex flex-row justify-between pb-2">
                <div className="flex flex-row">
                  <div>
                    <img className="rounded-md w-8 h-8 cursor-pointer" src='/usericon.gif' alt="User Icon" />
                  </div>
                  <div className="mt-2 ml-2">
                    <p >{userData?.username}</p>
                    <p className="font-semibold">{sessionStorage.getItem('roleName')}</p>
                    <p >{sessionStorage.getItem('organizationName')}</p>
                  </div>
                </div>
                <div>
                  <button title="" data-tooltip="close" style={{ '--tooltip-bg': 'black' }} onClick={handleClose}>
                    <FontAwesomeIcon icon={faX} className="cursor-pointer text-md text-[#f7525a] mx-2" />
                  </button>
                </div>
              </div>
              <div className="p-2 border-t border-b flex justify-end">
                <div>
                  <button onClick={handleLogout} className="text-[#f7525a] text-md">
                    <FontAwesomeIcon icon={faSignOut} className="cursor-pointer text-md text-[#f7525a] mx-2" />
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {orgPopUp && (
        <div ref={ref} className="absolute right-0 z-10 top-12 bg-white shadow-md border-[#6c7184] rounded-[0.5rem] w-96">
          <div className="p-4 bg-[#fbfbfb]">
            <div className="flex flex-col p-2">
              <div className="flex flex-row justify-between pb-2">
                <div className="flex flex-row">
                  <div>
                    <FontAwesomeIcon icon={faBuilding} className="cursor-pointer w-8 h-8 text-md text-[#f7525a] mt-3 mx-2" />
                  </div>
                  <div className="mt-2 ml-2">
                    <p>{sessionStorage.getItem('organizationName')}</p>
                    <p>{sessionStorage.getItem('organizationNumber')}</p>
                  </div>
                </div>
                <div>
                  <button title="" data-tooltip="close" style={{ '--tooltip-bg': 'black' }} onClick={handleOrgClose}>
                    <FontAwesomeIcon icon={faX} className="cursor-pointer text-md text-[#f7525a] mx-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
