import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronDown, faChevronUp, faSortUp, faSortDown, faX, faPaperclip, faPlus, faPen, faMobileAndroidAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import  '../Css/Tooltip.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner, Loader } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsOpen(false));
  const handleOptionClick = (value) => {
    onSelect(value);
    setIsOpen(false);
  };



  return (

    <div className="relative w-1/6" ref={dropdownRef}>

    <div

      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"

      onClick={() => setIsOpen(!isOpen)}

    >

      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>

        {selected || 'Salu'}

      </span>

      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />

    </div>

    {isOpen && (

      <div className="absolute mt-1 w-full h-40 bg-white border border-gray-300 rounded-md shadow-lg z-10 flex flex-col">

        <div className="overflow-y-auto scrollbar-visible flex-grow">

          {options.map((option) => (

            option.value !== 'add-new' && (

              <div className='p-1'>

              <div

                key={option.value}

                className="py-2 pl-1 cursor-pointer text-sm hover:bg-blue-500 hover:rounded-md  hover:text-white"

                onClick={() => handleOptionClick(option.value)}

              >

                {option.icon && <FontAwesomeIcon icon={option.icon} className="" />}

                {option.label}

              </div>

              </div>

            )

          ))}

        </div>

      </div>

    )}

  </div>

  

  );
};

const ViewProductCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {ProductCategoryData}= location.state || {}
  const [productCategories, setProductCategories] = useState([  ]);
  const [productSubcategories, setProductSubcategories] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [selectedProductCategory, setSelectedProductcategory] = useState();
  const [productcategoryid , setproductcategoryid] = useState(null);
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const url = process.env.REACT_APP_FORE_BRICKBUCKET
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ProductCategoryLoader, setProductCategoryLoader] = useState(true)
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [retryFunction, setRetryFunction] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES

  useEffect(() => {
    fetchRoleId();
    fetchProductCategories(ProductCategoryData);
  }, [ProductCategoryData]);


  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };


  const fetchProductCategories = async (CategoryData,attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}product-category/get-all-productCategories`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
       const output =  await getResponseHandling(response , data)
    if (output) {
        setProductCategories(output);
        handleProductCategoryClick(ProductCategoryData)
      } else {
        setProductCategories([]);
      } 
    }catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }finally{
    setIsLoading(false)
    }
  };
 

  const fetchProductSubcategoriesByProductCategoryId = async (productCategoryId,attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}product-category/get-all-subcategories-and-UOMs-under-product-category?product_category_id=${productCategoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      const output =  await getResponseHandling(response , data)
      console.log(output)
      if (output) {
        setProductSubcategories(output?.subCategories || []);
      } else {
        setProductSubcategories([]);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductSubcategoriesByProductCategoryId(productCategoryId,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductSubcategoriesByProductCategoryId(productCategoryId,attempt)); // Set retry function
      }
    }finally{
       setProductCategoryLoader(false) 
    }
  };

  const getResponseHandling = async (response, data)=>{
      if (response.status === 200 || response.status === 201) {
        setPopupType('200or201');
          return (data.data)
      } else if (response.status === 204) {
        setPopupType('204');
        return (data.data)
      } 
   
      if (response.status === 400) {
        setPopupType('400');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 401) {
        setPopupType('401');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 403) {
       
        setPopupType('403');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 500) {
       
        setPopupType('500');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else {
        setErrorMessages([...errorMessages, data.message]);
      }
    
  }

  const handleProductCategoryClick = (productcategory) => {
    setproductcategoryid(productcategory._id);
    setSelectedProductcategory(productcategory);
    fetchProductSubcategoriesByProductCategoryId(productcategory._id);
   
  };

  const handleCreateProductCategory = () => {
    navigate('/bb/app/items/newproductcategory')
  }
  
  const handleClose = () => {
    navigate('/bb/app/items/productcategories');
  }

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
  };

  if (isLoading) {
    return <Loader />;
  }


  const categoriesToDisplay = roleId === 6 
  ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
  : productCategories;


  return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
      {/* List of Customers Div start */}
      <div className=" flex flex-col  w-[32%]  ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Item Categories
     <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />
    </h5>
    </div>
    <div className=''>
    <button 
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md" 
  title="" data-tooltip="Create Item Category"  style={{ '--tooltip-bg': 'black' }}
  onClick={handleCreateProductCategory}>
  <FontAwesomeIcon icon={faPlus}  className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>
  <div className=" flex justify-center  overflow-y-hidden  flex-col ">
   <div className='flex  flex-col h-[80vh]  overflow-y-auto '>
   {categoriesToDisplay.map((productcategory) => (
        <div
          key={productcategory._id}
          onClick={() => handleProductCategoryClick(productcategory)}
          className={`border-b text-sm flex items-center px-4 py-2 ${selectedProductCategory?._id === productcategory._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
        >
          <div className='flex flex-row'>
            <div>
              <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
            </div>
            <div className='flex flex-col'>
              <p className='text-md font-semibold' >{productcategory.product_category_name}</p>
            </div>
          </div>
        </div>
      ))}
  </div>
</div>
</div>

<div className="border-l  w-full flex flex-col ">
  {categoriesToDisplay.length > 0 ? (
    selectedProductCategory ? (
      <>
        <div className="border-b h-24"> {/* or h-12, depending on your desired height */}
          <div className='p-6 flex justify-between items-center '>
            <span className="text-md font-semibold">{selectedProductCategory?.product_category_name}</span>
            <button className="text-white px-1 py-1 rounded-md"
             title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}>   
      <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-md text-[#F7525A] cursor-pointer" />
    </button>
          </div>
        </div>
        {ProductCategoryLoader ? (
    <Loader />
  ) : (
        <div className="flex h-5/6 w-full justify-center overflow-y-auto flex-row">
        {productSubcategories.length > 0 ? (
          <table className="w-[80%] h-20">
            <thead>
              <tr className="text-[#212529] bg-[#fafafc]">
                <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer">Item Category Name</th>
                
                <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer">Item Subcategory</th>
                <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer">Item SubCategory Image</th>
                <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer">UOM</th>
                <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer">Description</th>
              </tr>
            </thead>
              <tbody className="h-20 overflow-y-auto">
                {productSubcategories.map((subcategory) => (
                  <tr key={subcategory._id} className='text-xs  border-b'>
                    <td  className='py-2 px-4 text-center text-blue-600 '>
                      {selectedProductCategory?.product_category_name}
                    </td>
                    <td className="py-2 text-center px-4">{subcategory.product_sub_category_name}</td>
                    <td className="py-2 text-center px-4">
                    <img 
          src={`${imageUrl}${subcategory.product_sub_category_image}`} 
          alt={subcategory.product_sub_category_name}
          className="w-16 h-16 object-cover mx-auto"
        />
                    </td>
                    <td className="py-2 text-center px-4">{subcategory.uom}</td>
                    <td className="py-2 text-center px-4">{subcategory.product_sub_category_description}</td>
                  </tr>
                ))}
              </tbody>
          </table>
        ) : (
          <div className='flex justify-center items-center w-full'>
            <p className='text-center'>No Item Subcategories Available</p>
          </div>
        )}
        </div>
  )}
      </>
    ) : (
      <div className="flex justify-center items-center h-full">
        <p className="text-gray-500">Select a Item Category to view details</p>
      </div>
    )
  ) : (
    <div className="flex justify-center items-center h-full">
      <p className="text-gray-500">No Item Categories Available</p>
    </div>
  )}
</div>
{showPopup && (
        <div>
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    </div>

  );

};



export default ViewProductCategory;