// ProductProductCategories.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Loader} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';

const ProductProductCategories = () => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const url = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [retryFunction, setRetryFunction] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [roleId, setRoleId] = useState(null);


  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...productCategories].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setProductCategories(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  const handleCheckboxChange = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
      setSelectAll(false); // Update selectAll state when deselecting a checkbox
    } else {
      setSelectedCategories([...selectedCategories, category]);
      if (selectedCategories.length === productCategories.length - 1) {
        setSelectAll(true); // Update selectAll state when selecting all checkboxes
      }
    }
  };
  
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCategories([]);
      setSelectAll(false);
    } else {
      setSelectedCategories(productCategories);
      setSelectAll(true);
    }
  };

  const handleCreate = () => {
    navigate("/bb/app/items/newproductcategory");
  };

  
  const handleNavigateProductCategoryDetails = (category) =>{
    navigate("/bb/app/items/productcategorydetails", {state:{ProductCategoryData:category}})
    console.log(category);
   }

   useEffect(() => {
    fetchRoleId();
    fetchProductCategories();
  }, [url]);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const fetchProductCategories = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(url + "product-category/get-all-productCategories", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      }); // Update productCategories state
      const data = await response.json();
      if (data.status === 200 || data.status === 201) {
        setProductCategories(data.data);
      } else {
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }finally {
setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader />;
  }

  const handleDelete = async (attempt = 1) => {
    const selectedCategoryIds = selectedCategories.map((category) => category._id);
    console.log(selectedCategoryIds);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "product-category/delete-product-categories", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({ productCategoryIds: selectedCategoryIds })
      });
  
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // Update the vendors state to reflect the deleted vendors
        setProductCategories(productCategories.filter((category) => !selectedCategoryIds.includes(category._id)));
        setSelectedCategories([]);
        setSelectAll(false);
      } else {
        // Handle error cases
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleDelete(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleDelete(attempt)); // Set retry function
      }
    }
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const categoriesToDisplay = roleId === 6 
    ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
    : productCategories;

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-10 items-center">
  {selectedCategories.length > 0 ? (
    <button onClick={handleDelete}
      className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md ">Delete</button>
  ) : (
    <>
      <h1 className="text-lg font-bold">All Item Categories</h1>
      <button onClick={handleCreate}
        className="bg-[#E54643] text-white z-20 px-2 py-1 rounded"
        title="" data-tooltip="Create Item Category"  style={{ '--tooltip-bg': 'black' }}>
      + New</button>
    </>
  )}
</div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                  {categoriesToDisplay.length > 0 ? (
                    <table className="w-full mb-5">
                      <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
                        <tr className='border-b'>
                          <th className="text-xs py-3 text-center">
                          <input
        type="checkbox"
        className="mr-2"
        onChange={handleSelectAll}
        checked={selectAll}
      />
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('customer_name')}>
                            ITEM CATEGORY NAME {getSortIcon('customer_name') && <FontAwesomeIcon icon={getSortIcon('customer_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('company_name')}>
                            CATEGORY DESCRIPTION {getSortIcon('company_name') && <FontAwesomeIcon icon={getSortIcon('company_name')} />}
                          </th>
                        </tr>
                      </thead>
                      <tbody className='border-b border-t'>
                        {categoriesToDisplay.map((category, index) => (
                          <tr key={index} className="text-sm border-b">
                            <td className="py-2 text-center">
                            <input
                               type="checkbox"
                               className="mr-2"
                               onChange={(e) => handleCheckboxChange(category)}
                               checked={selectedCategories.includes(category) || selectAll}
                             />
                            </td>
                            <td  onClick={()=>handleNavigateProductCategoryDetails(category)} 
                            className="py-2 px-3 text-center text-[#408dfb] font-500 cursor-pointer">{category.product_category_name}</td>
                            <td className="py-2 px-3 text-center w-1/2 break-all">{category.product_category_description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Create Item Categories</h1>
                      </div>
                      <div className="flex justify-center items-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div>
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    </div>
  );
};

export default ProductProductCategories;
