import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faSortUp, faSortDown, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Loader} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import Search from '../Components/Search';

const PurchaseOrdersList = () => {
  const navigate = useNavigate();
  const [productCategories, setProductCategories] = useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
    const [PurchaseOrders, setPurchaseOrders] = useState();
    //console.log(PurchaseOrders);
  const [isLoading, setIsLoading] = useState(true);
  const url = process.env.REACT_APP_FORE_BRICKBUCKET;
  //const [showPopup, setShowPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
  //const [popupType, setPopupType] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
 // const [retryFunction, setRetryFunction] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
  const [SOItemQty, setSOItemQty] = useState(false);
  const [ReceivableQty, setReceivableQty] = useState(false);
  const [DeliveryQty, setDeliveryQty] = useState(false);
  const [createPOButtonEnabled, setCreatePOButtonEnabled] = useState(false);
  const [ViewPopup , setViewPopup] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [filteredPO, setFilteredPO] = useState([]);
const searchTypes = ['PO Number', 'Vendors', 'Item Category', 'PO Status', 'SO Number'];


  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  
    // Function to get nested value using key
    const getNestedValue = (item, key) => {
      return key.split('.').reduce((value, keyPart) => {
        return value ? value[keyPart] : undefined;
      }, item);
    };
  
    const sortedArray = [...PurchaseOrders].sort((a, b) => {
      const aValue = getNestedValue(a, key) || ''; // Treat undefined as empty string
      const bValue = getNestedValue(b, key) || ''; // Treat undefined as empty string
  
      // Convert to string for comparison and handle case insensitivity
      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();
  
   
  
      if (aString < bString) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aString > bString) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  
    setPurchaseOrders(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  useEffect(() => {
    handleSearch();
  }, [PurchaseOrders]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredPO(PurchaseOrders);
      return;
    }

    
    const filtered = PurchaseOrders.filter((PurchaseOrder) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'PO Number':
          return PurchaseOrder?.purchaseorder_number?.toLowerCase().includes(lowercaseQuery);
        case 'Vendors':
          return PurchaseOrder?.vendor_id?.name?.toLowerCase().includes(lowercaseQuery);
        case 'Item Category':
          return PurchaseOrder?.product_category_id?.product_category_name?.toLowerCase().includes(lowercaseQuery);
        case 'PO Status':
          return  PurchaseOrder?.purchaseorder_status?.toLowerCase().includes(lowercaseQuery);
          case 'SO Number':
           return PurchaseOrder?.items.some((item) => 
            item?.sales_orders_data.some((salesorder) =>
              salesorder.salesorder_number?.toLowerCase().includes(lowercaseQuery)
           )
           );
        default:
          return false;
      }
    });

    setFilteredPO(filtered);
  };
  

  const handleCheckboxChange = (id) => {
    const isAlreadySelected = selectedCategories.includes(id);
    const updatedSelectedCategories = isAlreadySelected
      ? selectedCategories.filter((c) => c !== id)
      : [...selectedCategories, id];
  
    setSelectedCategories(updatedSelectedCategories);
  
    // Update the selectAll state if all items are selected
    if (updatedSelectedCategories.length === PurchaseOrders.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  
    // Enable the Create Purchase Order button if at least one item is selected
    setCreatePOButtonEnabled(updatedSelectedCategories.length >= 1);
  };
  

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(PurchaseOrders.map(order => order.id));
    }
    setSelectAll(!selectAll); // Toggle the "Select All" checkbox state
  };

  const handleCreatePurchaseOrder = () => {
    // Your logic for creating a purchase order
     navigate("/bb/app/purchases/newpurchaseorder"); 
   // console.log("Creating Purchase Order for:", selectedCategories);
  };
  

  const handleCreate = () => {
    navigate("/bb/app/purchases/newpurchaseorder");
  };

  const handlenavigateToPurchaseOrderDetails = (purchaseOrder) => {
    navigate("/bb/app/purchases/purchaseorderdetails", {state:{PurchaseOrderData:purchaseOrder}});
    console.log(purchaseOrder);
  }

   useEffect(() => {
    fetchRoleId();
    fetchPurchaseOrders();
  }, []);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const PurchaseOrdersToDisplay = PurchaseOrders && roleId === 6 
  ? PurchaseOrders.filter(PurchaseOrder => (PurchaseOrder?.product_category_id?.product_category_name?.toLowerCase()) === 'tiles')
  : PurchaseOrders || [];


  const fetchPurchaseOrders = async (attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/purchaseorder/get-all-purchaseorders?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      if (output) { // Checks if response status is 200-299
  setPurchaseOrders(data.data);
  console.log(data.data);
} else {
  console.error(data.message);
}


      if (response.status === 500) {
        setRetryFunction(() => () => fetchPurchaseOrders(attempt));
      }
    } catch (error) {
      console.error('Failed to fetch quotations:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchPurchaseOrders(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchPurchaseOrders(attempt));
      }
    } finally {
      setIsLoading(false);
    }
  }; 


  const handleDelete = async (attempt = 1) => {
    const selectedCategoryIds = selectedCategories.map((category) => category._id);
    console.log(selectedCategoryIds);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "product-category/delete-product-categories", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({ productCategoryIds: selectedCategoryIds })
      });
  
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // Update the vendors state to reflect the deleted vendors
        setProductCategories(productCategories.filter((category) => !selectedCategoryIds.includes(category._id)));
        setSelectedCategories([]);
        setSelectAll(false);
      } else {
        // Handle error cases
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleDelete(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleDelete(attempt)); // Set retry function
      }
    }
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleClickQuantity = () => {
    setSOItemQty(true);
  }

  const handleClickReceivableQty = () => {
    setReceivableQty(true);
  }

  const handleClickDeliveryQty = () => {
    setDeliveryQty(true);
  }

  const handleClosePopup = () => {
    setSOItemQty(false);
    setReceivableQty(false);
    setDeliveryQty(false);
    setViewPopup(false);
  };

  function formatPurchaseOrderDate(dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1; // add 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function calculateAgingDays(dateStr) {
    const salesOrderDate = new Date(dateStr);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - salesOrderDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24));
    return diffInDays;
  }

  const handleViewButton = (purchaseorder) => {
    getSalesOrdersForPurchaseOrder(purchaseorder);
    setViewPopup(true);
  }

  const getSalesOrdersForPurchaseOrder = (purchaseOrder) => {
    const uniqueSalesOrders = new Set();
  
    purchaseOrder.items.forEach(item => {
      item.sales_orders_data.forEach(salesOrder => {
        uniqueSalesOrders.add(JSON.stringify({
          id: salesOrder._id,
          number: salesOrder.salesorder_number,
        date: formatPurchaseOrderDate(salesOrder.salesorder_date),
        }));
      });
    });
  
    return Array.from(uniqueSalesOrders).map(JSON.parse);
  };
  
  const handlenavigateToSalesOrderDetails = (salesOrder) => {
    console.log("salesOrder", salesOrder);
    const salesorderData = {
      _id: salesOrder.id,
    }
    navigate("/bb/app/sales/salesorderdetails", {state:{SalesOrderData:salesorderData}});
  }

  if (isLoading) {
    return <Loader />;
  }
  
  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-11 items-center">
  {selectedCategories.length > 0 ? (
    <>
 <div className="flex space-x-2">
        <button
          onClick={handleDelete}
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
        >
          Delete
        </button>
       {/*  <button
          onClick={handleCreatePurchaseOrder}  // Ensure this function is defined
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
          disabled={!createPOButtonEnabled}
        >
          Create Purchase Order
        </button> */}
      </div>
    </>
  ) : (
    <>
    <div className='space-x-10 flex flex-row'>
      <h1 className="text-lg font-bold">All Purchase Orders</h1>
     <Search onSearch={handleSearch} searchTypes={searchTypes} />
          </div>
      <button
        onClick={handleCreate}
        className="bg-[#F7525A] text-white z-20 px-2 py-1 rounded"
        title="" data-tooltip="Create Purchase Order"  style={{ '--tooltip-bg': 'black' }}>
        + New
      </button>
    </>
  )}
</div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                  {PurchaseOrdersToDisplay && PurchaseOrdersToDisplay.length > 0 ? (
                    <table className="w-full mb-5">
                      <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
                        <tr className='border-b'>
                          <th className="text-xs py-3 text-center">
                          <input   type="checkbox"
        className="mr-2"
        onChange={handleSelectAll}
        checked={selectAll}
      />
    </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('purchaseorder_date')}>
                            Date {getSortIcon('purchaseorder_date') && <FontAwesomeIcon icon={getSortIcon('purchaseorder_date')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('purchaseorder_date')}>
                            Aging {getSortIcon('purchaseorder_date') && <FontAwesomeIcon icon={getSortIcon('purchaseorder_date')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('purchaseorder_number')}>
                            Purchase Order {getSortIcon('purchaseorder_number') && <FontAwesomeIcon icon={getSortIcon('purchaseorder_number')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_category_id.product_category_name')}>
                            Item Category {getSortIcon('product_category_id.product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_id.product_category_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('purchaseorder_status')}>
                            PO Status {getSortIcon('purchaseorder_status') && <FontAwesomeIcon icon={getSortIcon('purchaseorder_status')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('vendor_id.city')}>
                            Location {getSortIcon('vendor_id.city') && <FontAwesomeIcon icon={getSortIcon('vendor_id.city')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('vendor_id.name')}>
                            Vendor {getSortIcon('vendor_id.name') && <FontAwesomeIcon icon={getSortIcon('vendor_id.name')} />}
                          </th>
                           <th className="text-xs py-3 text-center cursor-pointer">
                            Sales Orders
                          </th>
                        </tr>
                        
                      </thead>
                      <tbody className="border-b border-t">
                      {filteredPO && filteredPO.length > 0 ? (
    filteredPO.map((PurchaseOrder) => (
          <tr key={PurchaseOrder.id} className="text-sm border-b" onClick={() => setSelectedPurchaseOrder(PurchaseOrder)}>
            <td className="py-2 text-center">
            <input
  type="checkbox"
  className="mr-2"
  checked={selectedCategories.includes(PurchaseOrder)}
  onChange={() => handleCheckboxChange(PurchaseOrder)}
/>

            </td>
            <td className="py-2 text-center">  {formatPurchaseOrderDate(PurchaseOrder.purchaseorder_date)}</td>
            <td className="py-2 text-center">{/* {calculateAgingDays(PurchaseOrder.purchaseorder_date)} */} {calculateAgingDays(PurchaseOrder.purchaseorder_date)} days</td>
            <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" onClick={()=> handlenavigateToPurchaseOrderDetails(PurchaseOrder)} >{PurchaseOrder.purchaseorder_number}</td>
            <td className="py-2 text-center">{PurchaseOrder.product_category_id.product_category_name}</td>
            <td className="py-2 text-center">{PurchaseOrder.purchaseorder_status}</td>
            <td className="py-2 text-center">{PurchaseOrder.vendor_id.city}</td>
            <td className="py-2 text-center">{PurchaseOrder.vendor_id.name}</td>
<td className=" text-[#408dfb]  cursor-pointer text-center">
<button onClick={() => handleViewButton(PurchaseOrder)}>View</button>
  </td> 
          </tr>
        ))
      ) : (
         
      <tr>
    <td colSpan="10" className="text-center text-[#6c7184] py-4">
    Not found
        </td>
      </tr>
    )}
      </tbody>
                    </table>
                    
                  ) : (
                    <>
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Create Purchase Order</h1>
                      </div>
                      <div className="flex justify-center items-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {SOItemQty && selectedPurchaseOrder && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">SO Item Quantity</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />


    </div>
  </div>
)}

{ViewPopup && selectedPurchaseOrder && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}>
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Sales Orders</h2>
        <button onClick={() => setViewPopup(false)} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="overflow-y-auto p-4">
      <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
            
            <th className="border text-sm px-2 py-1 text-left">Date</th>
             {/*  <th className="border text-sm px-2 py-1 text-left">Aging</th> */}
              <th className="border text-sm px-2 py-1 text-left">SO Number</th>
{/*               <th className="border text-sm px-2 py-1 text-left">Quantity</th>
              <th className="border text-sm px-2 py-1 text-left">SO Item Status</th> */}
            </tr>
          </thead>
           <tbody>
           {getSalesOrdersForPurchaseOrder(selectedPurchaseOrder).map((salesOrder, index) => (
              <tr key={index}>              
               <td className="border text-sm px-2 py-1 text-left">{salesOrder.date}</td>
                {/* <td className="border text-sm px-2 py-1 text-left">{calculateAgingDays(salesOrder.date)}</td> */}
                <td className="border text-sm px-2  text-center text-[#408dfb] font-500 cursor-pointer text-left"  
                 onClick={()=> handlenavigateToSalesOrderDetails(salesOrder)}> {salesOrder.number}</td>
 </tr>
            ))}
          </tbody> 
        </table>
      </div>
    </div>
  </div>
)}

      {showPopup && (
        <div>
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    </div>
  );
};

export default PurchaseOrdersList;
