import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faProjectDiagram, faUser, faSignOutAlt, faBell, faTimes, faUserPlus, faAngleLeft, faAngleRight, faCamera, faImage, faUserCircle } from "@fortawesome/free-solid-svg-icons";



function UnAssignedUsersContainer() {
    const [users, setUsers] = useState([]);
    const [Projects,setProjects] = useState([]);
    const Navigate = useNavigate();
    //const GetUsers = `${process.env.REACT_APP_FORE_APILINK}/admin/get-all-users-details`;
    const GetProjects = process.env.REACT_APP_FORE_APILINK + '/projects/get-all-projects';
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Adjust the number of items per page as needed
  
    const totalPages = Math.ceil(users?.length / itemsPerPage) || 1;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUsers = users?.slice(indexOfFirstItem, indexOfLastItem) || [];
  
    const [EditUser, setEditUser] = useState(false);
    const [initialFirstName, setInitialFirstName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUserName] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [email, setEmail] = useState("");
    const [uploadedImage, setUploadedImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [ProfileData,setProfileData]=useState();
    const [isActivated, setIsActivated] = useState(false);
    const [editModeIndex, setEditModeIndex] = useState(false);
    const [UserStatus, setUserStatus] = useState(false);
    const [userProfileImage, setuserProfileImage] = useState();
    const [profileImage,setprofileImage]= useState();
    const [UserImage, setUserImage] = useState(uploadedImage);
    const [file,setfile]=useState(null)

    useEffect(()=>{
      const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
      const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
      if(storedLoginData&&Userdata){
        if(storedLoginData.token===Userdata.token){
        }else{
          Navigate('/measurit')
         sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
        }
      }else{
         Navigate('/measurit')
          sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
      }
    })
    const handleInputChange = (field, value) => {      
        if (field === "firstName") {
          setFirstName(value);
        } else if (field === "lastName") {
          setLastName(value);
        } else if (field === "username") {
          setUserName(value);
        } else if (field === "mobileNo") {
          if(!isNaN(value)){
            setMobileNo(value.slice(0,10));
          } 
        } else if (field === "email") {
          setEmail(value);
        }
      };
      const emailRegex = /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
      const handleClose = () => {
        setEditUser(false);
      }
    
      const handleStatus = (user) => {
        setUserStatus(true);
        setProfileData(user);
        // Set the initial state based on the current status of the user
        setIsActivated(user.is_active);
      };
    
      const handleEdit = (User) => {
        if (!User.assignedProjectId) {
          setEditUser(true);
          setFirstName(User.firstName);
          setLastName(User.lastName);
          setUserName(User.username);
          setMobileNo(User.mobileNo);
          setEmail(User.email);
          setProfileData(User);
          setIsActivated(User.is_active);
          setprofileImage(User.userProfileImage);
        } else {
          // Display a message or handle the case where the user is assigned to a project
        }
      };
    
      const updateProjectStatus = (status) => {
        setIsActivated(status);
      };
    
      const handleToggle = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const endpoint = isActivated
            ? '/admin/deactivate-user?userId = ${ProfileData._id}'
            : '/admin/activate-user?userId = ${ProfileData._id}';
      
          const response = await fetch(
            process.env.REACT_APP_FORE_APILINK + endpoint,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'x-token':token
              },
            }
          );
      
          if (response.ok) {
            // If the update is successful, update user status in the UI
            updateProjectStatus((prevState) => !prevState);
            // Optionally, you can also fetch the updated user data from the server
            // and update your 'users' state accordingly.
            await fetchProjectsAndUsers()
            setUserStatus(false);
          } else {
          }
        } catch (error) {
          clearStorageAndRedirectToHome()
        }
      };
      
      const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setUploadedImage(URL.createObjectURL(file));
        setUserImage(URL.createObjectURL(file)|| uploadedImage )
        setfile(file)
      };

      const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };
      

      useEffect (() => {
        fetchProjectsAndUsers();
      }, []);

      const handleEditClick = () => {
        // Set edit mode for the selected index
        setEditModeIndex(true);
      };

      const handleUpdate = async () => {
        // Validate input fields
      
        const newErrors = {};
      
        if (!firstName.trim()) {
          newErrors.firstName = "Required*";
        }
      
        if (!lastName.trim()) {
          newErrors.lastName = "Required*";
        }
      
        if (!username.trim()) {
          newErrors.username = "Required*";
        }
      
        if (!/^\d{10}$/.test(mobileNo)) {
          newErrors.mobileNo = "Mobile No must be 10 digits*";
        }
      
        if (!emailRegex.test(email)) {
          newErrors.email = "Enter a valid email address*";
        }
      
        // If there are errors, set them in the state
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
          try {
            const token = sessionStorage.getItem('token');
            const formData = new FormData();
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("username", username);
            formData.append("mobileNo", mobileNo);
            formData.append("email", email);
            formData.append("_id", ProfileData._id);
            formData.append("file", file);
                  // Make a PUT request to update user details
            const UpdateProfileData = `${process.env.REACT_APP_FORE_APILINK}/user/update-user-details`;
            const response = await fetch(UpdateProfileData, {
              method: "PUT",
              headers: {
                'Content-Type': 'application/json',
                'x-token': token
            },
              body: formData,
            });
      
            if (response.ok) {
                // If the update is successful, update the initial first name
                setInitialFirstName(firstName);
                setPopupVisible(true); // Show the popup on successful update
                const updatedData = await response.json();
                setEditModeIndex(false);
                window.location.reload();
            } else {
              // Handle errors from the server
            }
          } catch (error) {
            clearStorageAndRedirectToHome()
          }
        }
      };

      const fetchProjectsAndUsers = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const projectsResponse = await fetch(GetProjects, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "x-token":token
            },
          });
      
          if (projectsResponse.ok) {
            const projectsData = await projectsResponse.json();
            setProjects(projectsData);
            const token = sessionStorage.getItem('token');
            const usersResponse = await fetch(`${process.env.REACT_APP_FORE_APILINK}/admin/get-all-users-details`, {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-token':token
              },
            });
      
            if (usersResponse.ok) {
              const usersData = await usersResponse.json();
              const assignedUserIds = projectsData.map(project => project.assignedUserId);
              
              // Filter unassigned users
              const unassignedUsers = usersData.filter(user => !assignedUserIds.includes(user._id));
              setUsers(unassignedUsers);
      
              // Continue with the rest of your code
              const reversedData = projectsData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
              const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              const latest3Projects = sortedProjects.slice(0, 3);
              setDisplayedProjects(latest3Projects);
              handleUserName();
            } else {
            }
          } else {
          }
        } catch (error) {
          clearStorageAndRedirectToHome()
        }
      };
      
      function clearStorageAndRedirectToHome() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/measurit';
      }

      const [UserData,setUserData] =useState();
      const [displayedProjects, setDisplayedProjects] = useState([]);
      const  handleUserName= async()=>{
        const storedLoginData = localStorage.getItem('LoginData');
        if (storedLoginData !== null) {
          const storedUserDetails = JSON.parse(storedLoginData);
            const User= 
            users._id === storedUserDetails.userId
   setUserData(User);
          // Now storedUserDetails is an object containing the user details
      } else {
          // Handle the case where no data is stored under the key 'UserDetails'
      }
      
       }

      const handleBack = () => {
        // Define the action when the back button is clicked
        // For example, you can navigate to the previous page
        Navigate(-1); // This navigates back one step in the history
    };
    

return (
<div>
{/* Users Table */}

<div className="w-full mx-auto mt-2">

<div className="w-full mx-auto mt-5">
         <div className="max-w-screen-xl mx-auto mt-5 p-4">

{/* Back Button */}
<div className="flex justify-start items-center mr-6 ml-6 mb-2">
 <button onClick={handleBack} className="relative flex items-center text-md text-black rounded-md px-5 py-1 mt-3">
   <FontAwesomeIcon icon={faAngleLeft} className="text-lg cursor-pointer text-black mr-2" /> Back
 </button>
</div>

           <div className="bg-white flex flex-col relative rounded-md shadow-md p-2 ml-10 mr-10 mx-2 md:mx-6 overflow-x-auto">
             <div>
               <h1 className="text-md font-semibold">Users</h1>
             </div>

             <table className="w-full border-b border-gray-300 mt-4 relative">
               <thead className="border-b border-gray-300">
                 <tr className="bg-[#959BB0] bg-opacity-[15%]">
                   <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Profile</th>
                   <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Name</th>
                   <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Mobile Number</th>
                   <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Email</th>
                   <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Status</th>
                   <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Action</th>
                 </tr>
               </thead>
               {/* Add your table body content here */}
               <tbody className="border-b border-gray-300">
                 {currentUsers.map((User, index) => (
                   <tr
                     key={User._id}
                     className={index % 2 === 0 ? "bg-white border-b border-gray-300" : "bg-[#F9F4F4] border-b border-gray-300"}
                   >
                    <td className="text-blue-950 text-opacity-60 py-2 text-sm px-4 flex justify-center text-center">
 {User.userProfileImage ? (
   <img
     src={process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL + User.userProfileImage}
     alt="User Profile"
     className="w-[15px] md:w-[30px] h-[15px] md:h-[30px] self-center object-cover rounded-full"
   />
 ) : (
   <div className="self-stretch flex w-[15px] md:w-[30px] h-[15px] md:h-[30px] self-center flex-col bg-black bg-opacity-30 rounded-full" >
   <FontAwesomeIcon icon={faUserCircle} className="bg-white w-[15px] md:w-[30px] h-[15px] md:h-[30px]" />
   </div>
 )}
</td>
                     <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.firstName + User.lastName}</td>
                     <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.mobileNo}</td>
                     <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.email}</td>
                     <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center cursor-pointer hover:underline" onClick={() => handleStatus(User)}>
 {User.is_active === true ? 'Active' : 'Deactive'}
</td>
                     <td className="text-blue-950 text-opacity-60 py-2 px-4 text-xs text-center cursor-pointer hover:underline" onClick={() => handleEdit(User)}>Edit</td>
                   </tr>
                 ))}
               </tbody>
             </table>

             <div className="flex justify-end mt-4">
               <button
                 onClick={() => handlePageChange(currentPage - 1)}
                 disabled={currentPage === 1}
                 className={`mr-2 bg-gray-200 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-300 ${currentPage === 1 ? 'opacity-30 cursor-not-allowed' : ''}`}
               >
                 <FontAwesomeIcon icon={faAngleLeft} className="cursor-pointer mr-1" />
               </button>
               {Array.from({ length: totalPages }).map((_, index) => (
                 <button
                   key={index}
                   onClick={() => handlePageChange(index + 1)}
                   className={`mr-2 bg-gray-200 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-300  ${
                     currentPage === index + 1 ? 'bg-gray-400 text-white' : ''
                   }`}
                 >
                   {index + 1}
                 </button>
               ))}
               <button
                 onClick={() => handlePageChange(currentPage + 1)}
                 disabled={currentPage === totalPages}
                 className={`bg-gray-200 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-300 ${currentPage === totalPages ? 'opacity-30 cursor-not-allowed' : ''}`}
               >
                 <FontAwesomeIcon icon={faAngleRight} className="cursor-pointer mr-1" />
               </button>
             </div>
           </div>
         </div>
       </div>
       </div>

       {UserStatus && (
 <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
   <div className="bg-white p-2 rounded-lg shadow-md">
     <div className="flex justify-end cursor-pointer" onClick={() => setUserStatus(false)}>
       <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-600" />
     </div>
     <div className="w-full mx-auto mt-2">
       <div className="max-w-screen-xl mx-auto mt-3 p-2">
         <div className="flex flex-col items-center rounded-md p-2 ml-10 mr-10">
           <p className="text-lg font-bold mb-2">{ProfileData.is_active ? 'Are you sure want to Deactive?' : 'Are you sure want to Active?'}</p>
          <div className="flex justify-between mt-4">
           <button
             className="items-center flex justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
             onClick={handleToggle}
           >
             Yes
           </button>
           <button
               className="items-center flex justify-center text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4 ml-6"
               onClick={() => setUserStatus(false)}
             >
               No
             </button>
             </div>
         </div>
       </div>
     </div>
   </div>
 </div>
)}

       {EditUser && (
         <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
          <div className="bg-white w-80 p-4 rounded-lg shadow-md">
              <div className="flex justify-end cursor-pointer" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-600" />
              </div>
              {/* Profile Image */}
   <div className="mb-4 flex items-center justify-center">
 {UserImage || profileImage ? (
   <div className="relative">
     <img
        src={UserImage || process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL + profileImage}
       alt="Uploaded Profile"
       className="w-20 h-20 object-cover rounded-full border-4 border-white"
     />
     <label
       htmlFor="imageUpload"
       className="absolute top-14 right-1 text-blue-500 text-sm underline hover:text-blue-900 cursor-pointer"
     >
       {/* Upload Image Icon */}
       <FontAwesomeIcon icon={faCamera} />
       <input
         id="imageUpload"
         type="file"
         accept="image/*"
         className="hidden"
         onChange={handleImageUpload}
       />
     </label>
   </div>
 ) : (
   <label htmlFor="imageUpload" className="cursor-pointer">
     <span className="hover:text-gray-700 text-gray-500 text-4xl mr-2">
       <FontAwesomeIcon icon={faImage} />

     </span>
     <input
       id="imageUpload"
       type="file"
       accept="image/*"
       className="hidden"
       onChange={handleImageUpload}
     />
   </label>
 )}
</div>

    {/* Profile Image Ends*/}
              <div>
                  <div className="mb-4 relative">
                      <label className="text-gray-600 text-sm mb-1 block">First Name</label>
                      <input
                          type="text"
                          className={`border w-full rounded-sm ${errors.firstName ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-2 pl-1 pr-2 placeholder-text-xs placeholder-text-center`}
                          value={firstName}
                          onChange={(e) => handleInputChange("firstName", e.target.value)}
                          disabled={editModeIndex === false}
                      />
                      {errors.firstName && (
                          <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.firstName}</p>
                      )}
                  </div>

                  <div className="mb-4 relative">
                      <label className="text-gray-600 text-sm mb-1 block">Last Name</label>
                      <input
                          type="text"
                          className={`border w-full rounded-sm ${errors.lastName ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-center`}
                          value={lastName}
                          onChange={(e) => handleInputChange("lastName", e.target.value)}
                          disabled={editModeIndex === false}
                      />
                      {errors.lastName && (
                          <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.lastName}</p>
                      )}
                  </div>

                  <div className="mb-4 relative">
                      <label className="text-gray-600 text-sm mb-1 block">User Name</label>
                      <input
                          type="text"
                          name="username"
                          className={`border w-full rounded-sm ${errors.username ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-center`}
                          value={username}
                          onChange={(e) => handleInputChange("username", e.target.value)}
                          disabled={editModeIndex === false}
                      />
                      {errors.username && (
                          <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.username}</p>
                      )}
                  </div>

                  <div className="mb-4 relative">
                      <label className="text-gray-600 text-sm mb-1 block">Mobile No</label>
                      <input
                          
                          pattern="[0-9]{10}"
                          className={`border w-full rounded-sm ${errors.mobileNo ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-center`}
                          value={mobileNo}
                          onChange={(e) => handleInputChange("mobileNo", e.target.value)}
                          disabled={editModeIndex === false}
                      />
                      {errors.mobileNo && (
                          <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.mobileNo}</p>
                      )}
                  </div>

                  <div className="mb-4 relative">
                      <label className="text-gray-600 text-sm mb-1 block">Email</label>
                      <input
                          type="email"
                          pattern="[a-zA-Z0-9]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                          className={`border w-full rounded-sm ${errors.email ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-center`}
                          value={email}
                          onChange={(e) => handleInputChange("email", e.target.value)}
                          disabled={editModeIndex === false}
                      />
                      {errors.email && (
                          <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.email}</p>
                      )}
                  </div>
                 
              </div>

           

              <div className="flex justify-center mt-2">
              {editModeIndex === true ? (
           <button
             className="text-white text-opacity-100 text-base whitespace-nowrap bg-[#0f9d58] hover:bg-green-600 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300"
             type="button"
             onClick={() => handleUpdate()}
           >
             Update
           </button>
         ) : (
           <button
             className="text-white text-opacity-100 text-base whitespace-nowrap bg-blue-500 hover:bg-blue-700 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300"
             type="button"
             onClick={() => handleEditClick()}
           >
             Edit
           </button>
         )}
              </div>
          </div>
          </div>
  
)}
 {/* Popup */}
 {isPopupVisible && (
     <div className="fixed inset-0 z-20 flex items-center justify-center">
     {/* Background overlay (blurred) */}
     <div className="absolute inset-0 bg-black bg-opacity-30 filter blur-md"></div>

     {/* Popup content */}
     <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
       <p className="text-lg font-bold mb-2">Update Successful!</p>
      
     </div>
   </div>
   )}
</div>
)
}

export default UnAssignedUsersContainer;