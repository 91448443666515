import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

function Main_Page() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  
  /* const pageNames = {
    "/bb/AdminHome": "Home",
    "/bb/AdminHome/ProductGroupFilters": "Product Group Attributes",
    "/bb/AdminHome/CreateProductGroup": "Create Product Group",
  };
  const currentPage = pageNames[location.pathname] || "Unknown Page";
 */
  return (
    <div className="flex flex-col w-screen h-screen overflow-hidden">
    {/* Navbar */}
    <div className='h-[3rem] w-full'>
      <Navbar/>
    </div>
    
    {/* Main content area */}
    <div className='flex flex-1 overflow-hidden'>
      <Sidebar />
      
      {/* Content area with scroll */}
      <div className='flex-1 overflow-y-auto'>
        <Outlet />
      </div>
    </div>
  </div>
 
  );
}

export default Main_Page;
