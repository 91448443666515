import React from 'react';

const formatNumber = (value) => {
  // Parse the input as a number
  const parsedValue = parseFloat(value);

  // Check if the parsed value is a finite number
  if (Number.isFinite(parsedValue)) {
    // Round to two decimal places
    const roundedValue = (Math.round(parsedValue * 100) / 100);
  // Format the number with commas and two decimal places
  return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
  }

  // Return '0.00' if the value is not a valid number
  return '0.00';
};

const PurchaseOrderHtmlDocument = ({ purchaseorder }) => (
  <div className="p-8 flex flex-col"
  style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
  >
    <div className="flex justify-between mb-4 text-xs">
      <div className="flex-1">
        <h1 className="font-bold text-md">BrickBucket</h1>
        <p>Andhra Pradesh</p>
        <p>GSTIN 37AAKCK7567P1ZG</p>
        <p>info@brickbucket.com</p>
        <div className="mt-10">
        <h1 className='font-semibold '>Bill To</h1>
        </div>
        <div className="mt-1">
        <h1 className="font-bold text-md">{purchaseorder?.vendor_id?.company_name}</h1>
        <p> {purchaseorder?.vendor_id?.state}</p>
        <div className='flex flex-row'>
  <h1 className=''>GST: </h1>
  <p className=""> {purchaseorder?.vendor_id?.gstin}</p>
  </div>
  <p className=""> {purchaseorder?.vendor_id?.mobile_number}</p>
</div>

      </div>
      
      <div className="flex-1 mt-2 text-right">
        <h1 className="text-2xl font-semibold mb-0">Purchase Order</h1>
        <h1 className="text-md mt-1 font-semibold mb-0">{purchaseorder?.purchaseorder_number}</h1>
        <h1 className="text-sm mt-5 font-semibold">
  {new Date(purchaseorder?.purchaseorder_date).toLocaleDateString()}
</h1>
<h1 className="text-md mt-1 font-semibold mb-0">{purchaseorder?.purchaseorder_status}</h1>


       
      </div>
      
    </div>
    
    <div className="mb-4 p-2 text-xs">
      <table className="w-full border-collapse">
        <thead className='bg-[#3C3D3A] text-white p-2 h-12 text-left break-all'>
          <tr>
            <th className="text-center">#</th>
            <th className="">Items</th>
            <th className="">Item Category</th>
           {/*  <th className="">So Item Status</th> */}
            <th className="">Quantity</th>
           {/*  <th className="">Recievable Quantity</th>
            <th className="">Pending Quantity</th> */}
          {/*   <th className="">PO Item Status</th> */}
            <th className="">Price</th>
            <th className="">Tax</th>
            <th className="">Amount</th>
          </tr>
        </thead>
         <tbody>
         {purchaseorder?.items?.length > 0 ? (
  purchaseorder.items.map((item, index) => (
    <React.Fragment key={index}>
    <tr key={index} className='border-b p-2 h-12 text-left'>
      <td className="p-1 text-center">{index + 1}</td>
      <td className="p-1">{item?.item_name}</td>
      <td className="p-1">{purchaseorder?.product_category_id?.product_category_name}</td>
   {/*    <td className="p-1">{item?.item_SO_status}</td> */}
      <td className="p-1">{item?.quantity.toFixed(2)} {item?.UOM}</td>
     {/*  <td className="p-1">{''}</td>
      <td className="p-1">{''}</td> */}
     {/*  <td className="p-1">{item?.item_PO_status}</td> */}
      <td className="p-1">₹{item?.cost_price}</td>
      <td className="p-1">{item?.tax_rate}%</td>
      <td className="p-1">₹{formatNumber(item?.cost_price * item?.quantity)}</td>
    </tr>
    {item.required_order_breakup?.map((breakup, breakupIndex) => (
      <tr key={breakupIndex} className='border-b p-2 h-8 text-left '>
        <td className="p-1 text-center"></td>
        <td colSpan="2" className="p-1 pl-4">{breakup?.sub_item_name}</td>
        <td className="p-1">{breakup?.sub_item_quantity} {item?.UOM}</td>
        <td colSpan="3" className="p-1"></td>
      </tr>
    ))}
    </React.Fragment>
  ))
) : (
  <tr>
    <td colSpan="7" className="text-center p-2">No items available</td>
  </tr>
)}
        </tbody> 
      </table>
    </div>

    {/* Container div to align the totals section to the right */}
    <div className="flex justify-end mb-4">
      <div className="text-right p-2 text-xs">
        <div className="mb-2 flex justify-between space-x-20 p-2">
          <p className="text-sm font-semibold">Sub Total:</p>
          <p className="text-sm font-semibold">₹{ formatNumber(purchaseorder?.subtotal)}</p>
        </div>
        <div className="flex justify-between p-2">
          <p className="text-sm font-regular">Discount:</p>
           <p className="text-sm font-regular">{(purchaseorder?.discount)}%</p> 
        </div>
        <div className="mb-2 flex justify-between p-2">
          <p className="text-sm font-regular">Tax Amount:</p>
          <p className="text-sm font-regular">₹{ formatNumber(purchaseorder?.tax_amount)}</p> {/* GST */}
        </div>
        <div className="flex justify-between bg-[#F5F4F3] p-2">
          <p className="text-sm font-bold">Total:</p>
           <p className="text-sm font-regular">₹{ formatNumber(purchaseorder?.total)}</p> 
        </div>
      </div>
    </div>

    <div className="mb-4 p-2 text-xs">
     {/*  <h3 className="font-regular">Notes</h3>
      <h3 className="font-regular mt-5">Looking forward for your business.</h3>
 */}
      <div className="mt-6 flex items-center">
      <h3 className="text-sm font-regular mr-4 flex-shrink-0">Authorized Signature</h3>
      <div className="flex-grow">
        <div className="border-t border-black" style={{ width: '150px', height: '1px', marginTop: '1rem' }}></div>
      </div>
    
    </div>
      
     
    </div>
  </div>
);

const PurchaseOrderPdfViewer = ({ purchaseorder }) => (
  <div className="h-screen">
    <PurchaseOrderHtmlDocument purchaseorder={purchaseorder} />
  </div>
);

export default PurchaseOrderPdfViewer;
