import React, { useState, useRef , useEffect } from 'react';
//import logoImage from "./images/BB.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { MdAddLocationAlt } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
let currentInfoWindow = null;

function AddProject() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [projectName, setProjectName] = useState('');
  const [address, setAddress] = useState('');
  const CreateProjectURL = process.env.REACT_APP_FORE_APILINK + '/projects/create-project';
 const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  const [locations,setLocations]=useState([{ProjectName:'',BuilderName:''}])
  // Fetch user's live location on component mount
  useEffect(() => {
    fetchOurLocation(); // Call the fetchLocation function when the component mounts
  }, []);

  const fetchOurLocation= async () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      return;
    }
    try {
      // Options to improve accuracy
      const options = {
        enableHighAccuracy: true,  // Use GPS if available
        timeout: 10000,            // Maximum time to wait for a response
        maximumAge: 0              // Do not use cached position
      };
  
      // Attempt to get the user's current position
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
  
      // If successful, update the coordinates state
      setCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
  
      // Reverse geocode to get location name
      const results = await geocodeByAddress(`${position.coords.latitude},${position.coords.longitude}`);
      const address = results[0].formatted_address;
  
    } catch (error) {
      if (error.code === error.PERMISSION_DENIED) {
        console.error('User denied the request for Geolocation.');
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        console.error('Location information is unavailable.');
      } else if (error.code === error.TIMEOUT) {
        console.error('The request to get user location timed out.');
      } else {
        console.error('An unknown error occurred:', error);
      }
    }
  }

  const fetchLocation = async () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      return;
    }
    try {
      // Options to improve accuracy
      const options = {
        enableHighAccuracy: true,  // Use GPS if available
        timeout: 10000,            // Maximum time to wait for a response
        maximumAge: 0              // Do not use cached position
      };
  
      // Attempt to get the user's current position
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
  
      // If successful, update the coordinates state
      setCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
  
      // Reverse geocode to get location name
      const results = await geocodeByAddress(`${position.coords.latitude},${position.coords.longitude}`);
      const address = results[0].formatted_address;
  
      // Set the address in the state to display in the search field
      setAddress(address);
    } catch (error) {
      if (error.code === error.PERMISSION_DENIED) {
        console.error('User denied the request for Geolocation.');
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        console.error('Location information is unavailable.');
      } else if (error.code === error.TIMEOUT) {
        console.error('The request to get user location timed out.');
      } else {
        console.error('An unknown error occurred:', error);
      }
    }
  }; 
  
 //console.log(coordinates)

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setCoordinates(latLng);
      setAddress(address);
    } catch (error) {
      console.error('Error:', error);
    }
  };

 /*  const handleSubmit = () => {
    // You can perform any action with the coordinates here, such as submitting them to a backend server
    console.log('latitude:', coordinates.lat);
    console.log('longitude:', coordinates.lng);
  }; */

  useEffect(()=>{
    if(coordinates){
      renderMap()
    }
     
  },[coordinates])

 const renderMap = () => {
  let markersAdded = false; // Flag to check if any markers are added
  if (!coordinates || !coordinates.lat || !coordinates.lng) {
    return; // Coordinates not set yet, do not render map
  }
  const map = new window.google.maps.Map(document.getElementById('map'), {
    center: { lat: coordinates.lat, lng: coordinates.lng },
    zoom: 8
  });

  // Add click event listener to the map
  map.addListener('click', async (event) => {
    try {
      const latLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };

      // Reverse geocode to get location name
      const results = await geocodeByAddress(`${latLng.lat},${latLng.lng}`);
      const address = results[0].formatted_address;

      // Set the address in the state to display in the search field
      setAddress(address);

      // Update the coordinates state
      setCoordinates(latLng);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  locations.forEach(location => {
    const marker = new window.google.maps.Marker({
      position: { lat: coordinates.lat, lng: coordinates.lng },
      map,
      title: location.ProjectName
    });
    markersAdded = true; // Markers are added

    // Create info window for each marker
    const infoWindow = new window.google.maps.InfoWindow({
      content: ` <div class="text-xs p-1 text-red-700"><strong>Project:</strong> ${location.ProjectName}<br><strong>Builder:</strong>${location.BuilderName}<br><strong>Contact:</strong></div>`
    });

    // Add click event listener to marker
    marker.addListener('click', () => {
      // If there is a currently open info window, close it
      if (currentInfoWindow) {
        currentInfoWindow.close();
      }
      
      // If the clicked marker's info window is not already open, open it
      if (currentInfoWindow !== infoWindow) {
        infoWindow.open(map, marker);
        currentInfoWindow = infoWindow;
      } else {
        // If the clicked marker's info window is already open, set currentInfoWindow to null
        currentInfoWindow = null;
      }
    });
  });

  // If no markers are added, center the map on default coordinates
  if (!markersAdded) {
    map.setCenter(new window.google.maps.LatLng(coordinates.lat, coordinates.lng));
  }
};

  useEffect(() => {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      setUserData(JSON.parse(storedData));
    }
  }, []);

  const handleProjectSubmit = async () => {
    try {
      const token = sessionStorage.getItem('token');
  
      // Validate project name
      if (!projectName || projectName.trim() === '') {
        alert('Project name is required');
        return;
      }
  
      const response = await fetch(CreateProjectURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({
          customer_id: userData._id,
          projectName: projectName,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          siteAddress: address,
          organizationId: sessionStorage.getItem('organizationId')
        }),
      });
  
      const data = await response.json();
      console.log(data);
  
      if (response.ok) {
        // Handle success
        console.log('Project created successfully');
        navigate("/consumer/app/home");
      } else {
        // Handle error
        throw new Error(data.message || 'Error creating project');
      }
    } catch (error) {
      console.error('Error creating project:', error.message);
      // You can add additional error handling here, such as displaying an error message to the user
    }
  };
  

  const handleSkip = ()=>{
    navigate("/consumer/app/home");
    console.log('skipped')
  }

return (
  <div className=" flex justify-center items-center min-h-screen ">
      <div className="bg-white h-full  space-y-4 rounded-lg shadow-lg px-8  py-4 h-screen w-full max-w-2xl">
        <div className='p-2'>
        <h2 className="text-2xl font-bold text-center text-gray-800 ">Add Project Details</h2>
        </div>
        <div className="space-y-4">
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">Project Name</label>
            <input
              type="text"
              className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder='Enter Project Name'
              required
            />
          </div>

          <div className="w-full">
          <label className="block text-sm font-medium text-gray-700">Address</label>
            <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <div className="flex">
                    <input
                      {...getInputProps({
                        placeholder: 'Search Places...',
                        className: 'location-search-input border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline'
                      })}
                    />
                  {/*   <button onClick={fetchLocation} className="flex flex-row bg-blue-500 text-white p-3 rounded-r-md">
                    <p>Live</p>  <MdAddLocationAlt className="text-xl" />
                    </button> */}
                  </div>
                  <div className="autocomplete-dropdown-container bg-white  rounded shadow-lg ">
                    {loading && <div className="p-2">Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active p-2 bg-blue-500 text-white'
                        : 'suggestion-item p-2';
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className
                          })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className=' flex text-black justify-center items-center '>
            <p> ( OR )</p>
          </div>
          <div className=' flex  justify-center items-center '>
          <button onClick={fetchLocation} className="flex flex-row  rounded-sm border border-[#EB001B] text-black  p-3 rounded-r-md">
                    <p>Use Current Location</p>  <MdAddLocationAlt className="text-xl" />
                    </button>
          </div>

          <div className="flex justify-center items-center" style={{ height: '250px', width: '100%' }}>
            <div id="map" className="w-full h-full border rounded-md"></div>
          </div>
         
          <div className="flex flex-row space-x-4 justify-center items-center ">
          <button className="rounded-sm border border-[#EB001B] px-2 py-1 text-sm md:text-sm xl:text-sm text-black  hover:text-white hover:bg-[#EB001B]"
          onClick={handleSkip}>
            skip
            </button>

            <button
              className="focus:outline-none border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-sm rounded-sm px-4 py-1"
              onClick={handleProjectSubmit}
            >
              Submit
            </button>
           
          </div>
        </div>
      </div>
    </div>
)
}

export default AddProject;