import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import Main_Page from './mainPage';
import ProjectList from './ProjectList';
import ProjectDetails from './ProjectDetails'
import BlockDetails from './BlockDetails'
import NewMeasurements from './Measurements/NewMeasurements';
import Login from './login';
import NewProject from './NewProject';
function App() {
/*   const [appId, setappId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const BrickBucket = () => {
      if (location.pathname.startsWith('/measurit_v1.0')) {
        const storedLoginData = JSON.parse(localStorage.getItem('LoginData'));
        if (storedLoginData) {
          const appId = storedLoginData.appId;
        setappId(appId);
        setLoading(false);
        } else {
          // Handle case where user data is not available
          // For example, navigate to login page
          if (location.pathname.startsWith('/measurit_v1.0') && 
          !location.pathname.startsWith('/measurit_v1.0/reset-password') &&
          !location.pathname.startsWith('/measurit_v1.0/forgot-password') &&
          !location.pathname.startsWith('/measurit_v1.0/reset-password')
      ) {
          navigate('/measurit_v1.0/adminhome');
          setLoading(false);
      }
          
        }
      }
    };

    BrickBucket(); // Call BrickBucket function directly here
  }, [navigate, location.pathname]); // Add navigate and location.pathname as dependencies
 */

  /* if (loading) {
    return <div>Loading...</div>;
  } */

/*   const AccessDenied = () => {
    return (
      <div
        className="fixed inset-0 bg-gray-500/50 flex justify-center items-center"
        style={{ backdropFilter: 'blur(100px)' }}
        role="dialog"
        aria-modal="true"
      >
<div className="bg-white rounded shadow-md p-8 max-w-md">
  <h2 className="text-lg font-bold text-center">Access Denied</h2>
  <p className="text-gray-600 text-center">You do not have permission to access this page.</p>
  <div className="flex justify-center mt-2">
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded"
      onClick={() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/bb');
      }}
    >
      Go Back
    </button>
  </div>
</div>
      </div>
    );
  }; */

  return (
    <Routes>
       <Route path="/measurit_v3.0" element={<Login />} />
      
      <Route path="/measurit_v3.0/app" element={<Main_Page />}>
      {/* <Route path='adminhome' element = {<Home/>}/> */}
      <Route path='projects/projectslist' element = {<ProjectList/>}/> 
        <Route path='projects/projectdetails' element = {<ProjectDetails/>}/> 
        <Route path='projects/blockdetails' element = {<BlockDetails/>}/> 
        <Route path='projects/newproject' element={<NewProject/>} />
      <Route path='measurements/newmeasurements' element={<NewMeasurements/>} />
           
      </Route>
   
    </Routes>
  );
}

export default App;
