
import React, { useState ,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons';
/* import logoImage from "/BB.png"; */
import { useNavigate } from 'react-router-dom';


/**
 * The Login component is the main entry point for the login functionality of the application. It handles user authentication, including validating the username and password, and navigating the user to the appropriate page based on their role.
 *
 * The component uses the `useState` and `useEffect` hooks from React to manage the state of the login form, including the username, password, and various error states. It also uses the `useNavigate` hook from the `react-router-dom` library to handle navigation to different pages.
 *
 * The `handleLogin` function is the main entry point for the login process. It first checks if the username and password fields are empty, and if so, sets the `EmptyLoginData` state. If the fields are not empty, it validates the email and password using the `validateEmail` and `validatePassword` functions, respectively. If the validation fails, it sets the appropriate error states (`InvalideEmail` and `invalidPassword`).
 *
 * If the validation passes, the function makes a POST request to the `Admin/user-login` endpoint using the `fetch` API. If the response is successful, it stores the user data in the local and session storage, and then navigates the user to the appropriate page based on their role (`/measurit_v3.0/adminhome` for admins, `/measurit_v3.0/userhome` for users, or `/measurit_v3.0/reset-password` if the user needs to reset their password).
 *
 * The `useEffect` hook is used to check if there is any existing login data in the local storage. If there is, it stores the data in the local and session storage, and then navigates the user to the appropriate page based on their role.
 *
 * The `handleForgotPassword` function is used to navigate the user to the "Forgot Password" page.
 *
 * The `togglePasswordVisibility` function is used to toggle the visibility of the password input field.
 */
function Login() {
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const navigate = useNavigate();
    const [InvalideEmail,setInvalidEmail]=useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [EmptyLoginData, setEmptyLoginData]= useState(false);
    const [UserNotFound,setUserNotFound]=useState(false);
    const [showPassword, setShowPassword] = useState(false);
    
  
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
      resetErrors();
    };
    
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
      resetErrors();
    };
    
    const resetErrors = () => {
      setInvalidEmail(false);
      setInvalidPassword(false);
      setEmptyLoginData(false);
      setUserNotFound(false);
    };
    
    const validateEmail = (email) => {
      const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailValidation.test(email);
    };
    
    const validatePassword = (password) => {
      const passwordValidation = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordValidation.test(password);
    };
    
    const handleLogin = async () => {
      resetErrors();
      
      if (!Username || !Password) {
        setEmptyLoginData("Please enter Email and Password.");
        return;
      }
      
      if (!validateEmail(Username)) {
        setInvalidEmail(true);
        return;
      }
      
      if (!validatePassword(Password)) {
        setInvalidPassword(true);
        return;
      }
      
      try {
        const LoginUrl = process.env.REACT_APP_FORE_BRICKBUCKET + 'Admin/user-login';
        const LoginData = { username: Username, password: Password };
        
        const response = await fetch(LoginUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(LoginData),
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        
        storeUserData(data);
        navigateUser(data);
      } catch (error) {
        console.error('Login error:', error);
        setUserNotFound('An error occurred during login. Please try again.');
      }
    };
    
    const storeUserData = (data) => {
      localStorage.setItem('LoginData', JSON.stringify(data));
      sessionStorage.setItem('userData', JSON.stringify(data));
      sessionStorage.setItem('userId', data.userId);
      sessionStorage.setItem('token', data.token);
      sessionStorage.setItem('appId', data.appId);
    };
    
    const navigateUser = (data) => {
      if (data.forcePasswordChange) {
        navigate('/measurit_v3.0/reset-password', { state: { LoginData: data } });
      } else if (data.roleID === 1) {
        navigate('/measurit_v3.0/app', { state: { LoginData: data } });
      } else if (data.roleID === 2) {
        navigate('/measurit_v3.0/userhome', { state: { LoginData: data } });
      } else {
        console.warn('Unknown user role:', data.roleID);
        setUserNotFound('Invalid user role. Please contact support.');
      }
    };
    
    
    useEffect(() => {
      try {
        const loginDataString = localStorage.getItem('LoginData');
        if (!loginDataString) {
          console.log('No login data found');
          return;
        }
    
        const loginData = JSON.parse(loginDataString);
        if (!loginData || typeof loginData !== 'object') {
          console.error('Invalid login data format');
          return;
        }
    
        // Store data in localStorage and sessionStorage
        localStorage.setItem('LoginData', JSON.stringify(loginData));
        sessionStorage.setItem('userData', JSON.stringify(loginData));
        sessionStorage.setItem('userId', loginData.userId || '');
        sessionStorage.setItem('token', loginData.token || '');
    
        // Navigation based on user role
        if (loginData.forcePasswordChange === true) {
          navigate('/measurit_v3.0/ResetPassword', { state: { LoginData: loginData } });
        } else if (loginData.roleID === 1) {
          navigate('/measurit_v3.0/app', { state: { LoginData: loginData } });
        } else if (loginData.roleID === 2) {
          navigate('/measurit_v3.0/userhome', { state: { LoginData: loginData } });
        } else {
          console.warn('Unknown user role:', loginData.roleID);
        }
      } catch (error) {
        console.error('Error processing login data:', error);
        // Optionally, you can set an error state here to display to the user
        // setError('An error occurred while processing your login information');
      }
    }, [navigate]); // Add navigate to the dependency array
    
  
    const handleForgotPassword = () => {
      navigate('/bb/ForgotPassword')
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };


    return (
        <div className="w-full flex justify-center items-center h-screen font-roboto border border-gray-300">
            <div className="flex w-full justify-center items-center">
                <div className="flex flex-col space-y-4 justify-center w-full items-center">
                <div className='flex flex-row justify-center items-center' >
                <img src={'/BB.png'} alt="Logo" className='h-20 w-20'/>
                <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
            </div>
                    <div className="flex-col w-1/2 sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4">
                        <p className="text-2xl pt-4">LOGIN</p>
                        <div className="p-6 relative">
                            <div className="flex-col p-2 ">
                            <div className='flex justify-center'>
                                <p className="text-md">User Name</p>
                                </div>
                                <div className="flex-row flex items-center space-x-2 pl-2 pr-2 relative">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={faUser} className="text-gray-400" />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                    type="text"
                                    placeholder="Username"
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                        value={Username}
                                      onChange={handleUsernameChange}
                                      onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                           document.querySelector('input[type="password"]').focus();
                                       }
                                       }}
                                   />
                                    {InvalideEmail && (
                                    <div className="text-red-500 text-[12px]">
                                        <p className="flex justify-center">Enter the correct Email</p>
                                    </div>
                                    )}
                                    </div>
                                                                </div>
                                <div className='flex flex-col p-2'>
                                    <div className='flex justify-center'>
                                <p className="text-md">Password</p>
                                </div>
                                <div className="flex-row flex items-center space-x-2 relative pl-2 pr-2">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-gray-400 cursor-pointer" onClick={togglePasswordVisibility} />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                    onChange={handlePasswordChange}
                                     type={showPassword ? "text" : "password"}
                                     placeholder="Password"
                                     value={Password}
                                    className="w-5/6 md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B]  text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        handleLogin(); // Call the handleLogin function when Enter key is pressed
                                      }
                                    }} 
                                     />
                                    {invalidPassword && (
                                        <div className="text-red-500 text-[12px]">
                                            <p>Password is incorrect</p>
                                        </div>
                                        )}
                                </div>
                                {EmptyLoginData || UserNotFound && (
                                <div className="text-red-500 text-[12px] flex justify-center items-center">
                                <p className="text-red-500">
                                    {EmptyLoginData}
                                    {UserNotFound}
                                </p>
                                </div>
                            )}
                            </div>
                            
                            <div className="flex justify-center items-center mt-3">
                                <p className="underline text-[#2158E5] hover:text-[#EB001B] cursor-pointer text-[14px]" onClick={handleForgotPassword}>Forgot Password?</p>
                            </div>
                            <div className="flex justify-center items-center mt-3">
                                <button className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"
                                onClick={handleLogin}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
