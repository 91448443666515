export const validate = (fields) => {
    const errors = {};
  
    fields.forEach((field) => {
      const { name, value, required, type, message, usage } = field;
  
      if (required && !value) {
        errors[name] = `${message} `;
      } else if (type === 'email' && value) {
        // Simple email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          errors[name] = 'Invalid email address';
        }
      } else if (type === 'number' && value) {
        // Check if the value is a number
        if (isNaN(value)) {
          errors[name] = `${usage} must be a number`;
        }
      }
    });
  
    return errors;
  };
  