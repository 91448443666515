import React, { useRef ,useState, useEffect, useCallback  } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle, faPaperclip,faTrash, faX, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages'
import TableSearchDropDown from '../Components/TableSearchDropDownForSalesOrder';
import TableSearchForAreaName from '../Components/TabelSerchForAreaName'
import  '../Css/Tooltip.css';
import {useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
    setFocusedOptionIndex(null);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };

  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };


  useOutsideClick(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);


  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option,index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const NewDeliveryChallans = () => {
  const navigate = useNavigate ();
  const location= useLocation();
  const [salesOrders, setSalesOrders] = useState([]);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);
  const [allSalesOrders, setAllSalesOrders] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState();
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const FetchProjectsUrl = process.env.REACT_APP_FORE_APILINK; //measurit


const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES; 

  const [isLoading, setIsLoading] = useState(true);
  const [SalesOrderItems, setSalesOrderItems] = useState([]);
  const [DeliveryChallanData, setDeliveryChallanData] = useState([]);
  const [SelectedSalesOrderItems, setSelectedSalesOrderItems] = useState([]);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const [customersLoaded, setCustomersLoaded] = useState(false);
const [productCategoriesLoaded, setProductCategoriesLoaded] = useState(false);
const [roleId, setRoleId] = useState(null);
const [quantityErrors, setQuantityErrors] = useState({});



    useEffect(() => {
      fetchRoleId();  
      fetchAllApprovedCustomers();
      fetchProductCategories();
    }, [fetchUrl]);

    const fetchRoleId = () => {
      const roleId = JSON.parse(sessionStorage.getItem('roleId'));
      setRoleId(roleId);
    };
  
    const categoriesToDisplay = roleId === 6 
    ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
    : productCategories;

    const fetchAllApprovedCustomers = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + `customer/get-all-approved-customers?organizationId=${sessionStorage.getItem('organizationId')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        if (output) {
          // Format data for dropdown
          const formattedCustomers = output.map(customer => ({
            id: customer._id,  // Use customer ID as value
            label: customer.customer_name, // Use customer name as label
            value: customer.customer_name
          }));
    
          setCustomers(formattedCustomers); // Update state with formatted data
         // console.log(formattedCustomers);
         setCustomersLoaded(true);
        } else {
          handleFetchError(response, attempt, fetchAllApprovedCustomers);
        }
      } catch (error) {
        handleErrorWithRetry(error, attempt, fetchAllApprovedCustomers);
      }finally {
        setIsLoading(false);
      }
    };

    const fetchProductCategories = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
          setProductCategories(output); // Update productCategories state
          setProductCategoriesLoaded(true);
        }  else {
          handleFetchError(response, attempt, fetchProductCategories);
        }
      } catch (error) {
        handleErrorWithRetry(error, attempt, fetchProductCategories);
      }finally {
        setIsLoading(false);
      }
    };


    useEffect(() => {
      if(selectedCustomer && selectedProductCategory) {
        fetchSalesOrder();
      }    
    }, [selectedCustomer, selectedProductCategory]);
    
    
    
    const fetchSalesOrder = async (attempt = 0) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/get-salesorders-by-category-and-customer?customer_id=${selectedCustomer?.id}&product_category_id=${selectedProductCategory?.value}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
      
          const data = await response.json();
      
          if (data.status === 200 || data.status === 201 || data.status === 204) {
            const salesOrderOptions = data.data.map(order => ({
                id: order._id,
                value: order.salesorder_number,
                label: order.salesorder_number,
                customer_id: order.customer_id._id,
                product_category_id: order.product_category_id._id
              }));
              setAllSalesOrders(salesOrderOptions); 
            setSalesOrders(salesOrderOptions);
            console.log(salesOrderOptions);
            console.log(data.data);
            setIsLoading(false);
          } else if (data.status === 500) {
            setIsLoading(false);
            setRetryFunction(() => fetchSalesOrder);
            setPopupMessage(data.message);
            setPopupType(`${data.status}`);
            setShowPopup(true);
          } else {
            setIsLoading(false);
            setPopupMessage(data.message);
            setPopupType(`${data.status}`);
            setShowPopup(true);
            setAllSalesOrders([])
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
      
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1);
            setTimeout(() => fetchSalesOrder(attempt + 1), 1000);
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => fetchSalesOrder(attempt));
          }
        }
      };


      
      const fetchSalesOrderById = async (salesOrderId) => {
        console.log(salesOrderId)
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}customer/salesorder/get-salesorder-items-by-salesorder-id?salesorder_id=${salesOrderId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
      
          const data = await response.json();
          console.log(data);
          if (data.status === 200 || data.status === 201 || data.status === 204) {
            const salesOrderDetails = data.data;
            setSalesOrderItems(salesOrderDetails);
            console.log("Fetched Sales Order Details:", salesOrderDetails);
          } else {
            console.error("Failed to fetch sales order details:", data.message);
            setPopupMessage(data.message);
            setPopupType(`${data.status}`);
            setShowPopup(true);
          }
        } catch (error) {
          console.error("Error fetching sales order details:", error);
          setPopupMessage("Error fetching sales order details. Please try again.");
          setPopupType("error");
          setShowPopup(true);
        }
      };
 
   // Centralized error handling
const handleFetchError = (response, attempt, retryFunction) => {
  if (response.status === 500) {
    setRetryFunction(() => retryFunction); 
  }
};
    
    const handleErrorWithRetry = (error, attempt, retryFunction, ...args) => {
      console.error('Failed to fetch:', error);
    
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => retryFunction(...args, attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => retryFunction(...args, attempt));
      }
    };

  const handleSalesOrderSelect = (selectedOption) => {
    setSelectedSalesOrder(selectedOption);
    fetchSalesOrderById(selectedOption.id);
    fetchDeliveryChallanData(selectedOption.id);
  };
  
  



  const handleCustomerSelect = (option) => {
    setSelectedProductCategory(null); // Reset the selected product category
    setProductCategories([]); 
    fetchProductCategories();
    setSelectedCustomer(option); // Set the selected option object
    setSalesOrderItems([])
    setSelectedSalesOrder();
  };
  
 
  
  const handleProductCategorySelect = (selectedOption) => {
    
    //console.log(selectedOption)
    setSelectedProductCategory(selectedOption);
    setSalesOrderItems([])
    setSelectedSalesOrder();
   
  };

  const fetchDeliveryChallanData = async (salesOrderId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}customer/delivery-challan/get-delivery-challan-by-salesorder-id?salesOrderId=${salesOrderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
  
      const data = await response.json();
      const output = await handleGetResponse(response, data);
  
      if (output) {
        setDeliveryChallanData(output);
        console.log("Fetched Delivery Challan Data:", output);
      } else {
        setDeliveryChallanData([])
      }
    } catch (error) {
      console.error('Error fetching delivery challan data:', error);
      setPopupMessage('Failed to fetch delivery challan data. Please try again.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchDeliveryChallanData(salesOrderId));
    }
  };
  
  
  

const handleSaveDeliveryChallan = async (attempt = 1) => {
  setIsSaving(true);

  try {
   
    const deliveryChallanData = {
      customer_id: selectedCustomer?.id,
      product_category_id: selectedProductCategory?.value,
      sales_order_id: selectedSalesOrder?.id,
      organizationId: sessionStorage.getItem('organizationId'),
      items: SelectedSalesOrderItems.map(item => ({
        item_id: item.item_id,
        item_name: item.item_name,
        quantity: item.deliver_quantity,
        UOM: item.UOM
      }))
    };
    
console.log(deliveryChallanData)
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}customer/delivery-challan/create-delivery-challan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify(deliveryChallanData)
    });

    const data = await response.json();
    const output = await handleSaveResponse(response, data);

    if (output) {
      setTimeout(() => {
        setShowPopup(false);
        navigate('/bb/app/deliverychallans/deliverychallanslist');
      }, 2000);
    } else {
      throw new Error(data.message || 'Failed to create delivery challan');
    }
  } catch (error) {
    console.error('Failed to create delivery challan:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => handleSaveDeliveryChallan(attempt + 1), 1000);
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleSaveDeliveryChallan(attempt));
    }
  } finally {
    setIsSaving(false);
  }
};

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };


  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleStayHere = () => {
    setShowCancelPopup(false);
  }

  const handleCancel = () => {
    setShowCancelPopup(true);
  }

  const handleLeave = () => {
    navigate(-1);
  }

 

  const handleSelectOrderItem = (OrderItem) => {
    setSelectedSalesOrderItems(prevItems => {
      const existingItemIndex = prevItems.findIndex(item => item._id === OrderItem._id);
      
      if (existingItemIndex === -1) {
        // If the item is not in the array, add it
        return [...prevItems, OrderItem];
      } else {
        // If the item is already in the array, remove it
        return prevItems.filter((_, index) => index !== existingItemIndex);
      }
    });
  };
  
  const handleDeliverQuantityChange  = (event, orderItem) => {
    const inputValue = event.target.value;
    const newQuantity = inputValue === '' ? null : parseInt(inputValue, 10);
    setSelectedSalesOrderItems(prevItems => {
      const updatedItems = prevItems.map(item => {
        if (item._id === orderItem._id) {
          const isQuantityValid = inputValue === '' || newQuantity === null || newQuantity === 0 || 
          (newQuantity <= (orderItem.receivable_quantity || 0));
        
          setQuantityErrors(prevErrors => ({
            ...prevErrors,
            [item._id]: isQuantityValid ? '' : 'Quantity exceeds received quantity'
          }));
          return {
            ...item,
            deliver_quantity:  inputValue === '' ? '' : newQuantity,
          };
        }
        return item;
      });
      return updatedItems;
    });
  };
  
  const hasQuantityErrors = () => {
    return Object.values(quantityErrors).some(error => error !== '');
  };
  

  if (isLoading) {
    return <Loader />;
  }

 

return (
  <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
    <div className="flex flex-row w-full">
      <div className="w-full flex-1">
        <div className="flex flex-col w-full h-full justify-center items-center">
          <div className="w-full h-full flex flex-col justify-between">
            <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-4 items-center">
                <h1 className="text-lg font-semibold">New Delivery Challan</h1>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
              <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="customername" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Customer Name<span className="text-red-500">*</span>
                      </label>
                      <div className='w-1/3'>
                        <CustomDropdown
                          options={customers}
                          selected={selectedCustomer}
                          onSelect={handleCustomerSelect}
                          placeholder='Select Customer'
                          showAddNew={false}
                          showSearch={true}
                        />
                      </div>
                    </div>
                   
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Item Category<span className="text-red-500">*</span>
                      </label>           
                      <div className='w-1/3'>
                        <CustomDropdown
                          options={categoriesToDisplay.map(category => ({
                            value: category._id,
                            label: category.product_category_name
                          }))}
                          selected={selectedProductCategory}
                          onSelect={handleProductCategorySelect}
                          placeholder="Select Item Category"
                          showAddNew={false}
                          showSearch={true}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Sales Order Number<span className="text-red-500">*</span>
                      </label>
                      <div className='w-1/3'>
                        <CustomDropdown
                          options={salesOrders}
                          selected={selectedSalesOrder}
                          onSelect={handleSalesOrderSelect}
                          placeholder='Select Sales Order'
                          showAddNew={false}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="w-full container mx-auto px-4 mb-2 mt-4">
  <div className='border border-gray-300 rounded-md bg-[#fafafc]'>
    <h2 className="text-lg font-semibold py-1 px-2">Item Table</h2>
  </div>
  <table className="w-full">
  <thead className="sticky top-0  text-[#6c7184] border-b">
  <tr className='border-b'>
    <th className="text-xs py-3 text-center w-12">
      <input type="checkbox" className="mr-2" />
    </th>
    <th className="text-xs px-2 py-3 text-center cursor-pointer border-r">Item</th>
    <th className="text-xs px-2 py-3 text-center cursor-pointer border-r">Order Qty</th>
    <th className="text-xs px-2 py-3 text-center cursor-pointer border-r">Received Qty</th>
    <th className="text-xs px-2 py-3 text-center cursor-pointer border-r">Delivered Qty</th>
    <th className="text-xs px-2 py-3 text-center cursor-pointer border-r">Deliver Qty</th>
  </tr>
</thead>

<tbody className="border-b border-t">
  {SalesOrderItems.map((item, index) => (
    <tr key={index} className="text-sm border-b">
      <td className="py-2 text-center">
        <input
          type="checkbox"
          className="mr-2"
          checked={SelectedSalesOrderItems.find(selectedItem => selectedItem.item_id === item.item_id) !== undefined}
          onClick={() => handleSelectOrderItem(item, index)}
        />
      </td>
      <td className="border-r border-b px-4 py-2">{item.item_name}</td>
      <td className="border-r border-b px-2 py-2 text-center">{item.quantity + " " + item.UOM}</td>
      <td className="border-r border-b px-2 py-2 text-center">
        {item?.receivable_quantity ? `${item.receivable_quantity} ${item.UOM}` : ''}
      </td>
      <td className="border-r border-b px-2 py-2 text-center">
        {DeliveryChallanData.reduce((totalQuantity, itemData) => {
          if (itemData.sales_order_id === item.salesorder_id) {
            const matchingItem = itemData.items.find(data => data.item_id === item.item_id);
            return totalQuantity + (matchingItem ? matchingItem.quantity : 0);
          }
          return totalQuantity;
        }, 0)}
      </td>
      <td className="border-r border-b px-2 py-2 text-center">
        <input
          type="text"
          value={
            SelectedSalesOrderItems.find(selectedItem => selectedItem.item_id === item.item_id)?.deliver_quantity || ''
          }
          className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
          onChange={(e) => handleDeliverQuantityChange(e, item)}
        />
          {quantityErrors[item._id] && (
    <p className="text-red-500 text-xs mt-1">{quantityErrors[item._id]}</p>
  )}
      </td>
    </tr>
  ))}
</tbody>

</table>

</div>
              </div>
              </div>
              <div className="mt-auto w-full">
              <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
              <button 
  className={`px-2 py-1 ${hasQuantityErrors() ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#f7525a]'} text-white border border-[#DDDDDD] rounded-md`}
  onClick={handleSaveDeliveryChallan}
  disabled={hasQuantityErrors() || isSaving}
>
  Save as Draft {isSaving && <Spinner />}
</button>

                <button 
                  onClick={handleCancel}  
                  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
                    isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
                  }`}
                  disabled={isSaving}
                >
                  Cancel
                </button>
              </div>
            </div>
              </div>
              {/* <div className='pt-2 px-6 py-6 flex flex-row justify-between w-full'>
              <div class="flex flex-col">

                <div><button  onClick={addRow} className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'><FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1"  />
                Add New Row</button></div> 
</div>     
 </div> */}
            
           
          </div>
          </div>

{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}
    {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
{/* popup ends */}
</div>
  );
};

export default NewDeliveryChallans;
