import React from 'react';
import Select from 'react-select';
import { useState, useRef } from 'react';
import { useEffect } from 'react';

const TableSearchDropDown = ({ options, value, onChange, index, getFilteredOptions, setEditUOM }) => {
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(()=>{
    setInputValue(value)
  },[value])

  // Filter options based on the input value
  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setMenuIsOpen(true);
    onChange(e.target.value); // This will pass the input value directly
  };

  const handleFocus = () => {
    setMenuIsOpen(true);
  };

  const handleBlur = () => {
    // Delay closing the menu to allow click on menu items
    setTimeout(() => setMenuIsOpen(false), 200);
  };

  const handleChange = (selectedOption) => {
    onChange(selectedOption);
    setInputValue(selectedOption ? selectedOption.label : '');
    setMenuIsOpen(false);
  };
 
  

  // Click outside handler to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      
        className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
        ref={inputRef}
      />
      {menuIsOpen && (
        <div className="absolute top-full left-0 w-full z-10">
          <Select
            options={filteredOptions}
            value={options.find(option => option.value === value)}
            onChange={handleChange}
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            className="basic-single"
            styles={{
              control: (provided) => ({
                ...provided,
                display: 'none', // Hide the select box itself
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 50,
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              dropdownIndicator: () => ({
                display: 'none',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#000',
              }),
              menuList: (provided) => ({
                ...provided,
                padding: 0,
              }),
            }}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          {filteredOptions.length === 0 && (
            <div className="absolute top-full left-0 w-full bg-white border border-gray-300 z-20 rounded-md shadow-lg">
              <div className="p-2 text-center text-gray-500">No results found</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};


export default TableSearchDropDown;
