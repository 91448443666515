import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faExclamationTriangle, faX, faPlusCircle, faChevronDown, faImage } from '@fortawesome/free-solid-svg-icons';
 import { useNavigate } from 'react-router-dom'; 
import '../Css/Animation.css';
import TableSearchDropDown from '../Components/tableSerchDropDown';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages'
import { validate } from '../CommenResponseAndErrorHandlers/commenUiErrorHandlers';
import { Loader ,Spinner } from '../Loader/Loader';
//import Dropdown from './DropDown'
// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  React.useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [ref, callback]);
};

const CustomDropdown = ({ options = [], selected, onSelect, labelField, valueField, placeholder, onAddNew }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);


  useOutsideClick(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
    }
}, [isOpen]);

  // Filter options based on the search term
  const filteredOptions = options.filter(option => {
    const label = option[labelField] || '';
    return label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (name, id) => {
    onSelect(name, id);
    setIsOpen(false);
    setFocusedOptionIndex(null);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
        }
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
         break;
      case 'ArrowUp':
        e.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
        }
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption[labelField], selectedOption[valueField]);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };


  return (
    <div className="relative w-1/3" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected || placeholder}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
       <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
         <input
            ref={searchInputRef}
            type="text"
            className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus={isOpen}
          />
      <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length ? (
            filteredOptions.map((option, index) => (
              <div
                key={option[valueField]}
                data-index={index}
                className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                onClick={() => handleOptionClick(option[labelField], option[valueField])}
              >
                {option[labelField]}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No results found</div>
          )}
       </div>
          {onAddNew && (
            <button
              type="button"
              className="flex items-center border-t p-2 mt-2 text-blue-500 "
              onClick={onAddNew}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4 mr-2" />
              Add New
            </button>
          )}
        </div>
      )}
    </div>
  );
};


const VehicalSheduling = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryId , setSelectedCategoryId] = useState();
  const [attributes, setAttributes] = useState([]);
  const [WeightAttribute, setWeightAttribute] = useState();
  const navigate= useNavigate()
  const [showAttributePopup, setAttributePopup] = useState(false);
  const [attributeName, setAttributeName] = useState('');
  const [vendors,setVendors]= useState([])
const [selectedVendorName , setSelectedVendorName] = useState()
const [selectedVendorId, setSelectedVendorId] = useState()
const [showCancelPopup, setShowCancelPopup] = useState(false);
const [selectdLocation, setselectdLocation] = useState('');
const [salesOrdersItems, setSalesOrdersItems] = useState([]);
 const [SalesOrders , setSalesOrders] = useState([])
const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
const [SelectedSalesOrderItems,setSelectedSalesOrderItems] = useState([])
const [SelectedSalesOrder , setSelectedSalesOrder]= useState()
const [TotalSchedulingWeight, setTotalSchedulingWeight] = useState('');
const [SalesOrderItemsData,setSalesOrderItemsData] = useState([])
const [vehicalNumber , setVehicalNumber] = useState('');
  const [driverName , setDriverName] = useState('');
  const [driverNumber , setDriverNumber] = useState('');
  const [location , setLocation] = useState('');
  const [VehicalDetailsPopup,setVehicalDetailsPopup]= useState(false)
  const [roleId, setRoleId] = useState(null);

//console.log(selectdLocation)
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
     tax_preference: '',
     GST: '',
     IGST: ''
   });
    const [rows, setRows] = useState([
{ SNo: '', product_group_image:'', product_group_name: '', UOM: '', UOM_id: ' ',  cost_price: '',selling_price: '', product_group_description:''}
    ]);
    const [UomOptions , setUomOptions] = useState([
     
    ]);
    const { 
      showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
      isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
      setPopupType } = useCommonResponseHandler();

      const RETRY_LIMIT = 3; // Maximum number of retries
      const [retryCount, setRetryCount] = useState(0); // Track retry attempts


      const sortArray = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
      
        // Function to get nested value using key
        const getNestedValue = (item, key) => {
          const keyParts = key.split('.');
          let value = item;
          for (let i = 0; i < keyParts.length; i++) {
            if (Array.isArray(value)) {
              // If value is an array, get the first element's value
              value = value[0] ? value[0][keyParts[i]] : undefined;
            } else {
              value = value ? value[keyParts[i]] : undefined;
            }
          }
          return value;
        };
      
        const sortedArray = [...salesOrdersItems].sort((a, b) => {
          const aValue = getNestedValue(a, key) || ''; // Treat undefined as empty string
          const bValue = getNestedValue(b, key) || ''; // Treat undefined as empty string
      
          // Convert to string for comparison and handle case insensitivity
          const aString = aValue.toString().toLowerCase();
          const bString = bValue.toString().toLowerCase();
      
          if (aString < bString) {
            return direction === 'ascending' ? -1 : 1;
          }
          if (aString > bString) {
            return direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      
        setSalesOrdersItems(sortedArray);
      };
      
        const getSortIcon = (key) => {
          if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
          }
          return null;
        };
      

    const handleInputChange = (index, field, value) => {
      const updatedRows = [...rows];
      updatedRows[index][field] = value;
      setRows(updatedRows);
    };
   
    const addRow = () => {
      if(selectedCategoryId){
        setRows([...rows, { SNo: '',product_group_image:'', product_group_name: '', UOM: '', UOM_id: ' ',  cost_price: '',selling_price: '', product_group_description:''}]);
      }else{
        console.log (' you need to select the item Category and attribute name ')
      }
      
    };

    const Locations = [
      { label: 'Vijayawada', value: 'Vijayawada' },
      { label: 'Visakhapatnam', value: 'Visakhapatnam' },
      { label: 'Gudivada', value: 'Gudivada' },
      { label: 'Guntur', value: 'Guntur' },
    ];

    const handleLocationChange = (value, label) => {
      console.log('Selected location:', label); // Debugging: Check selected value
      setselectdLocation(label);
    };

  useEffect(() => {
   fetchRoleId();
    fetchProductCategories();
  }, [fetchUrl]);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const categoriesToDisplay = roleId === 6 
  ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
  : productCategories;


  const fetchProductCategories = async (attempt = 1) => {
    console.log(popupMessage, showPopup)
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      if (output) {
        // Log the data to ensure it's fetched correctly
        console.log('Fetched Product Categories:', output);

        // Map data to expected format
        const categories =output.map(cat => ({
          product_category_id: cat._id,
          product_category_name: cat.product_category_name
        }));
        setProductCategories(categories);
      } else {
        if(response.status === 500){
          console.log('retry enterd')
          
          setRetryFunction(() => () => fetchProductCategories); 
        }
        console.error(data.message);
      }
    }  catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = async (name, id) => {
    try {
      // Update selected category and related states
      setSelectedCategory(name);
      setSelectedCategoryId(id);
      setRows([{
        SNo: '',
        product_group_image: null, // Assuming this is an empty object or use appropriate default
        product_group_name: '',
        UOM: '',
        UOM_id: '',
        cost_price: '',
        selling_price: '',
        product_group_description: ''
      }]);
     
      setSelectedVendorId('');
      setSelectedVendorName('');
      await fetchSalesOrderAndTheirItems(id)
      await fetchAttributes(id)
      // Fetch new product subcategories
      // Fetch and set new UOM options
      const updatedUomOptions = await handleGetAllUoms(id); // Assuming it takes id and returns updated options
      setUomOptions(updatedUomOptions);
  
    } catch (error) {
      console.error('Error handling category change:', error);
    }
  };
  
  const fetchSalesOrderAndTheirItems = async (productCategoryId) => {
    const token = sessionStorage.getItem('token');
  
    try {
      const response = await fetch(fetchUrl + `customer/salesorder/get-salesorder-and-their-orderitems-by-productcategory?product_category_id=${productCategoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
  
      const data = await response.json();
      //console.log('Response data:', data);
      if (data.status === 200) {
        setSalesOrdersItems(data.data.salesOrderItems);
        console.log('Sales Orders Items:', data.data.salesOrderItems);
        setSalesOrders(data.data.salesOrders);
      } else {
        let message = 'An error occurred. Please try again.';
        let type = 'error';
  
        switch (data.status) {
          case 401:
            message = 'Unauthorized Access. Kindly Login.';
            type = '401';
            break;
          case 404:
            message = 'Not Found. Kindly Retry.';
            type = '404';
            break;
          case 500:
            message = 'Internal Server Error. Kindly Retry.';
            type = '500';
            break;
        }
  
        setPopupMessage(message);
        setPopupType(type);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error fetching sales orders:', error);
      setPopupMessage('Network error. Please check your connection and try again.');
      setPopupType('error');
      setShowPopup(true);
    }
  };
  
  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };
 
  
 

  const fetchAttributes = async (categoryId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${categoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        const formattedAttributes = data.data.map(attr => ({
          attribute_name: attr.attribute_name,
          attribute_name_id: attr.attribute_name_id
        }));
        setAttributes(formattedAttributes);

        const RequiredAttribute = formattedAttributes.find(p=>p.attribute_name==='Weight per Box')
        setWeightAttribute(RequiredAttribute)
        return formattedAttributes;
      } else {
        setAttributes([])
        //setSelectedAttribute('')
        //setSelectedAttributeNameId()
       // setRows([{ SNo: 1, Name: '', Values: '', attribute_name_id: '' }])
        console.error(data.message);
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      return [];
    }
  }; 


  const handleGetAllUoms = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
  
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      if (output) {
        // Log the data to ensure it's fetched correctly
        console.log('Fetched Product Categories:',output);
  
        const uomData = output.map((item) => ({
          _id: item._id,
          value: item.UOM,
          label: item.UOM,
        }));
  
       return uomData
      } else {
        console.error('Error:', data.message);
        return ([])
      
       
      }
    }catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleGetAllUoms(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleGetAllUoms(attempt)); // Set retry function
      }
    }finally {
      setIsLoading(false);
    }
  };
  

 
  const handleClosePopup = () => {
   // setAttributePopup(false);
    setSalesOrderItemsData([])
    
  };

  const handleCloseVehicalPopup = () => {
    setVehicalDetailsPopup(false)
  };
  
  
  
 // console.log(selectedCategoryId);
  const handleSave = async (attempt = 1) => {
    const fields = [
      { name: 'attribute_name', value: attributeName, required: true,  message: 'attribute name is required', usage: 'attribute name'},
      { name: 'product_category', value: selectedCategoryId, required: true,  message: 'select a product category before creation of uom', usage: 'product category'},
    ];

    // Validate the fields
    const validationErrors = validate(fields);
    setErrors(validationErrors);

    // Proceed only if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      // Prepare specifications
    const data = {
      product_category_id: selectedCategoryId,
      attribute_name: attributeName,
    };

    try {
      const token = sessionStorage.getItem('token');
      const saveUrl = `${fetchUrl}product-attributes/create-attribute`;
  
      const response = await fetch(saveUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(data),
      });
      //console.log(data)
      const responseData = await response.json();
      const output = await handleSaveResponse(response,responseData)
      if (output) {
       
       //console.log('Save Response:', output); 
       //fetchAttributes(selectedCategoryId)
       setAttributePopup(false);
      } else {
       
        console.error(responseData.message);
      }
    }catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleSave(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleSave(attempt)); // Set retry function
      }
    }
  }
  }
  
  
  const handleSaveVehicalSheduling = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const url = fetchUrl + 'customer/vehicle-schedule/create-vehicle-schedule';
  
      const vehicleScheduleData = {
        vehicle_number: vehicalNumber,
        driver_name: driverName,
        driver_number: driverNumber,
        location: location,
        total_weight_in_kgs: TotalSchedulingWeight,
        organizationId:sessionStorage.getItem('organizationId'),
      };
  
      const data = {
        vehicleScheduleData: vehicleScheduleData,
        salesOrderData: handleSalesOrderData(),
      };
  
      console.log(data)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          'x-token': token,
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
      const output = await handleSaveResponse(response, result);
  
      if (output) {
        setTimeout(() => {
          setShowPopup(false);
          navigate('/bb/app/sales/vehicleshedulelist');
        }, 2000);
      } else {
        console.error('Error:', result.message);
      }
  
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => handleSaveVehicalSheduling(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleSaveVehicalSheduling(attempt));
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleFindLengthOfOrders = (input) => {
    const uniqueSalesOrderIds = new Set(SelectedSalesOrderItems.map(item => item.salesorder_id._id));
    if(input === 'length'){
      return uniqueSalesOrderIds.size;
    }else if (input === 'sales_orders') {
      return Array.from(uniqueSalesOrderIds);
    }
   
  };
  
  const handleSalesOrderData = () => {
    const salesOrderMap = new Map();

    SelectedSalesOrderItems.forEach(item => {

      if (!salesOrderMap.has(item.salesorder_id._id)) {
        salesOrderMap.set(item.salesorder_id._id, {
          sales_order_id: item.salesorder_id._id,
          sales_order_number: item.salesorder_id.salesorder_number,
          product_category_id: selectedCategoryId,
          customer_id: SalesOrders.find(salesOrder => salesOrder._id === item.salesorder_id._id)?.customer_id._id || null,
          customer_name: SalesOrders.find(salesOrder => salesOrder._id === item.salesorder_id._id)?.customer_id?.customer_name || null,
          order_items: []
        });
      }

      salesOrderMap.get(item.salesorder_id._id).order_items.push({
       
        item_id: item.item_id,
        item_name: item.item_name,
        scheduling_quantity: parseFloat(item.scheduling_quantity),
        quantity: parseFloat(item.scheduling_quantity),
        UOM: item.UOM,
        weight_in_kgs: parseFloat(item.scheduling_quantity * handleReturnWeight(item)) || 0
      });
    });

    return Array.from(salesOrderMap.values());
  };

  const handleSaveVehicalShedulingAndDeliveryChallan = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const url = fetchUrl + 'customer/vehicle-schedule/create-vehicle-schedule-and-delivery-challan';
  
      const vehicleScheduleData = {
        vehicle_number: vehicalNumber,
        driver_name: driverName,
        driver_number: driverNumber,
        location: location,
        total_orders: handleFindLengthOfOrders('length'),
        total_weight_in_kgs: parseFloat(TotalSchedulingWeight),
        sales_orders: handleFindLengthOfOrders('sales_orders'),
        organizationId:sessionStorage.getItem('organizationId'),
      };
  
      const data = {
        vehicleScheduleData: vehicleScheduleData,
        salesOrderData: handleSalesOrderData(),
      };
       
      console.log(data);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
      const output = await handleSaveResponse(response, result);
  
      if (output) {
        setPopupMessage('VS and DC created successfully');
        setPopupType('200or201');
      setShowPopup(true);
      setVehicalDetailsPopup(false);
        setTimeout(() => {
          setShowPopup(false);
          navigate('/bb/app/sales/vehicleshedulelist');
          console.log(output)
        }, 2000);
      } else {
        console.error('Error:', result.message);
      }
  
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => handleSaveVehicalShedulingAndDeliveryChallan(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleSaveVehicalShedulingAndDeliveryChallan(attempt));
      }
    } finally {
      setIsSaving(false);
    }
  };
  
  


const handleCancel = () => {
  setShowCancelPopup(true);
}

const handleStayHere = () => {
  setShowCancelPopup(false);
}
const handleLeave = () => {
  navigate(-1);
}

const handleReturnWeight = (salesOrderItem) => {
  return parseFloat(salesOrderItem.productGroupData?.attribute_values?.find(p => p.attribute_name_id === WeightAttribute?.attribute_name_id)?.attribute_value  || 0);
}

const handleSelecetSalesOredrItem = (salesOrderItem, index) => {
  const prevOrderItemIndex = SelectedSalesOrderItems.findIndex(item => item._id === salesOrderItem._id);

  const receivableQuantity = salesOrderItem?.receivable_quantity || 0;
  const scheduledQuantity = salesOrderItem?.scheduled_quantity || 0;

  const schedulingQuantity = 
    scheduledQuantity >= receivableQuantity ? 0 : Math.max(0, receivableQuantity - scheduledQuantity);

  const updatedSalesOrder = {
    ...salesOrderItem,
    scheduling_quantity: schedulingQuantity
  };

  setSelectedSalesOrderItems(prevItems => {
    if (prevOrderItemIndex === -1) {
      // If the item is not in the array, add it
      return [...prevItems, updatedSalesOrder];
    } else {
      // If the item is already in the array, remove it
      return prevItems.filter((item, idx) => idx !== prevOrderItemIndex);
    }
  });
};


const handleChangeScheduleQuantity = (value, salesOrderItem, index) => {
  setSelectedSalesOrderItems(prevItems => {
    const updatedItems = [...prevItems];
    const itemIndex = updatedItems.findIndex(item => item._id === salesOrderItem._id);
    
    if (itemIndex !== -1) {
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        scheduling_quantity: value
      };
    }
    
    return updatedItems;
  });
};




useEffect(() => {
  if (SelectedSalesOrderItems.length > 0) {
    const TotalWeight = SelectedSalesOrderItems.reduce((total, item) => {
      const weightValue = item.productGroupData?.attribute_values?.find(p => p.attribute_name_id === WeightAttribute?.attribute_name_id)?.attribute_value;
      return total + (parseFloat(weightValue) || 0) * (parseFloat(item.scheduling_quantity) || 0);
    }, 0);
    console.log('TotalWeight', TotalWeight.toFixed(2));
    setTotalSchedulingWeight(TotalWeight.toFixed(2));
  }else{
    setTotalSchedulingWeight('')
  }
}, [SelectedSalesOrderItems, WeightAttribute]);


const handleClickSalesOrder = (salesOrderId) => {
const salesOrder = SalesOrders.find(order => order._id === salesOrderId);
setSelectedSalesOrder(salesOrder)
const OrderItems = salesOrdersItems.filter(item => item.salesorder_id._id === salesOrderId);
setSalesOrderItemsData(OrderItems)
}

const handleSaveAndCreateDeleveryChalan = async()=>{
  setVehicalDetailsPopup(true)
}

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
        
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Vehicle Scheduling</h1>
                  
                </div>
              </div>
            {/*   {Object.keys(errors).length > 0 && (
        <div className="flex justify-between  w-full p-2 items-center px-auto py-3 bg-[#fddcde] rounded-md">
          <div className="px-6 flex flex-col space-y-2 justify-start items-start">
            {errors.vendor && (
              <span className="text-sm">
                • {errors.vendor}
              </span>
            )}
            {errors.product_category && (
              <span className="text-sm">
                • {errors.product_category}
              </span>
            )}
            {errors.product_group_name && (
              <span className="text-sm">
                • {errors.product_group_name}
              </span>
            )}
          </div>
          <div className='px-4'>
            <button
              className="text-gray-700 hover:text-red-500"
              onClick={() => setErrors({})}
            >
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )} */}
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 ">
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="selectedCategory" className="block text-sm font-regular text-[#e54643] w-1/6">Item Category Name<span className="text-red-500">*</span></label>
                    <CustomDropdown
                      options={categoriesToDisplay}
                      selected={selectedCategory}
                      onSelect={handleCategoryChange}
                      labelField="product_category_name"
                      valueField="product_category_id"
                      placeholder="Select Product Category"
                    />
                  </div>
                </div>

                <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2 px-4">
                  <label htmlFor="tax" className="block text-sm font-regular text-gray-700 w-1/6">Location</label>
                  <CustomDropdown
              options={Locations}
              selected={selectdLocation}
              labelField="label"
              valueField="value"
              onSelect={handleLocationChange}
              showAddNew={false}
              placeholder="Select Location"
            />
                </div>

     {/* Conditionally Render GST and IGST Fields */}
    
                <div className="flex w-full flex-row space-x-6 px-2 items-center mt-5">
                  <div className='ml-4'>
                  <h1 className="text-md font-semibold underline">Sales Orders ItemWise</h1>
                  </div>
                  <div className='flex flex-row space-x-4 '>
                    <h1 className="text-sm font-semibold">{`${TotalSchedulingWeight?'Total Scheduling Weight :':''}`}</h1>
                  <h1 className="text-sm ">{`${TotalSchedulingWeight?TotalSchedulingWeight/1000 + " "+ 'Tons':''}`}</h1>
                </div>
                </div>

                <div className="w-full container mx-auto px-4 mb-2 mt-4">
                <div className="overflow-y-auto h-[80vh]">
                  {salesOrdersItems.length > 0 ? (
                    <table className="w-full mb-5">
                     <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
  <tr className='border-b'>
    <th className="text-xs py-3 text-center ">
      <input type="checkbox" className="mr-2" />
    </th>
    <th className="text-xs px-1 py-3 text-center cursor-pointer border-r" onClick={() => sortArray('subitems.sub_item_name')}>
      Sales Order No {getSortIcon('subitems.sub_item_name') && <FontAwesomeIcon icon={getSortIcon('subitems.sub_item_name')} />}
    </th>
    <th className="text-xs py-3 text-center cursor-pointer border-r" onClick={() => sortArray('product_category_name')}>
      Item Name {getSortIcon('product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_name')} />}
    </th>
    <th className="text-xs px-1 py-3 text-center cursor-pointer border-r" onClick={() => sortArray('vendor_name')}>
      Vendor {getSortIcon('vendor_name') && <FontAwesomeIcon icon={getSortIcon('vendor_name')} />}
    </th>
    <th className="text-xs px-1 py-3 text-center border-r">
      Order Qty
    </th>
    <th className="text-xs px-1 py-3 text-center border-r">
      Received Qty
    </th>
    {/* <th className="text-xs px-1 py-3 text-center border-r">
      Delivered Qty
    </th> */}
    <th className="text-xs px-1 py-3 text-center cursor-pointer border-r" onClick={() => sortArray('_id.item_PO_status')}>
      weight/Box {getSortIcon('_id.item_PO_status') && <FontAwesomeIcon icon={getSortIcon('_id.item_PO_status')} />}
    </th>
    <th className="text-xs px-1 py-3 text-center border-r">
      Scheduled Qty
    </th>
    <th className="text-xs px-1 py-3 text-center border-r">
      Scheduling Qty
    </th>
   {/*  <th className="text-xs px-1 py-3 text-center border-r">
      Vehicle Assigned Qty
    </th> */}
    <th className="text-xs px-1 py-3 text-center border-r">
    Scheduling Qty Weight
    </th>
  </tr>
</thead>

                      <tbody className="border-b border-t">
  {salesOrdersItems.map((salesOrderItem, index) => (
    <tr key={salesOrderItem.id} className="text-sm border-b">
      <td className="py-2 text-center">
        <input
          type="checkbox"
          className="mr-2"
          checked={SelectedSalesOrderItems.find(p=>p._id === salesOrderItem._id)}
          onClick={()=>handleSelecetSalesOredrItem(salesOrderItem, index)}
        />
      </td>
      <td className="border-r border-b px-4 py-2 text-[#408dfb] font-500 cursor-pointer"
      onClick={()=>handleClickSalesOrder(salesOrderItem?.salesorder_id._id)}>
          
      {salesOrderItem?.salesorder_id?.salesorder_number || ''}
 
      </td>
      <td className="border-r border-b px-4 py-2 text-center">
        {salesOrderItem?.item_name || ''}
      </td>
      <td className="border-r border-b px-4 py-2 text-center">
      {salesOrderItem?.vendorData?.vendor_name || ''}
      </td>
      <td className="border-r border-b px-2 py-2 text-center">
       {salesOrderItem?.quantity + " " + `${salesOrderItem.UOM}`|| ''}
      </td>
      <td className="border-r border-b px-2 py-2 text-center">
  {salesOrderItem?.receivable_quantity ? `${salesOrderItem.receivable_quantity} ${salesOrderItem.UOM}` : ''}
</td>

{/* <td className="border-r border-b px-2 py-2 text-center">
  {salesOrderItem?.delivered_quantity ? `${salesOrderItem.delivered_quantity} ${salesOrderItem.UOM}` : ''}
</td> */}

      <td className="border-r border-b px-2 py-2 text-center"> 
  {salesOrderItem.productGroupData?.attribute_values ? handleReturnWeight(salesOrderItem) + ' ' + 'Kg' : '-'}
      </td>
      
      <td className="border-r border-b py-2 text-center">
  {salesOrderItem?.scheduled_quantity ? `${salesOrderItem.scheduled_quantity} ${salesOrderItem.UOM}` : ''}
</td>

      <td className="border-r border-b  py-2 text-center">
        <input
          type="text"
          value={SelectedSalesOrderItems.find(p=>p._id ===salesOrderItem._id)?.scheduling_quantity || ''}
          onChange={(e)=>handleChangeScheduleQuantity(e.target.value, salesOrderItem, index)}
          className="outline-blue-500 text-sm w-20 h-full p-2 bg-transparent rounded-md"
        />
      </td>
      
      <td className="border-r border-b px-2 py-2 text-center w-20">
  {(() => {
    const weight = handleReturnWeight(salesOrderItem);
    const scheduleQuantity = SelectedSalesOrderItems.find(p => p._id === salesOrderItem._id)?.scheduling_quantity;
    if (weight && scheduleQuantity) {
      const total = weight * scheduleQuantity;
      return isNaN(total) ? '-' : `${total.toFixed(2)} Kg`;
    }
    return '-';
  })()}
</td>


    </tr>
  ))}
</tbody>

                    </table>
                    
                  ) : (
                    <>
              
                     <tr>
                     <td colSpan="8" className="text-center text-[#6c7184] py-4">
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Sales Order item Wise not available  </h1>
                      </div>
                      </td>
                      </tr>
                    </>
                  )}
                </div>
</div>

                </div>

                <div className="mt-5 w-full">
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                  <button className="px-2 py-1  flex-row bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                    onClick={handleSaveVehicalSheduling}
                  >
                   <span>Save</span>
                    {isSaving && <Spinner />}
                  </button>
                  <button className="px-2 py-1  flex-row bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                    onClick={handleSaveAndCreateDeleveryChalan}
                  >
                   <span>Save & create Delivery Challan</span>
                    {isSaving && <Spinner />}
                  </button>
                  <button onClick={handleCancel} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
                </div>
                </div>
            </div>
          </div>

          {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
        </div>
         {/* Popup starts */}

         {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

         {SalesOrderItemsData.length > 0 && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-2/3 h-3/4 flex flex-col ">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md">Sales Order : {SelectedSalesOrder?.salesorder_number}</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='flex flex-row space-x-4 px-4'>
  <div className="flex flex-row items-center mb-2 mt-2 space-x-2">
    <label htmlFor="productSubCategoryName" className="block text-sm font-semibold text-gray-700">
      Item Category:
    </label>
    <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700">
      {selectedCategory}
    </label>
  </div>

  <div className="flex flex-row items-center mb-2 mt-2 space-x-2">
    <label htmlFor="productSubCategoryName" className="block text-sm font-semibold text-gray-700">
      Customer:
    </label>
    <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700">
      {SelectedSalesOrder?.customer_id?.customer_name}
    </label>
  </div>
</div>

      <table className="w-full mb-2 mt-2">
                      <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
                        <tr className='border-b'>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('salesorder_date')}>
                            Item  {getSortIcon('salesorder_date') && <FontAwesomeIcon icon={getSortIcon('salesorder_date')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('aging')}>
                            Vendor {getSortIcon('aging') && <FontAwesomeIcon icon={getSortIcon('aging')} />}
                          </th>
                        
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('salesorder_status')}>
                            SO Item Status {getSortIcon('salesorder_status') && <FontAwesomeIcon icon={getSortIcon('salesorder_status')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_category_id.product_category_name')}>
                            Po Item Status {getSortIcon('product_category_id.product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_id.product_category_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center" >
                            Order Qty 
                          </th>
                          <th className="text-xs py-3 text-center" >
                            Received Qty 
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer">
                            Delivered Qty
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer">
                            Scheduled Qty
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('item_po_status')}>
                            Amount {getSortIcon('item_po_status') && <FontAwesomeIcon icon={getSortIcon('item_po_status')} />}
                          </th>
                        </tr>
                        
                      </thead>
                       <tbody className="border-b border-t">
                     { SalesOrderItemsData.map((salesOrder) => (
          <tr key={salesOrder._id}  className="text-sm border-b" /* onClick={() => setSelectedSalesOrder(salesOrder)} */> 
    
            <td className="py-2 text-center">  {salesOrder.item_name}</td>
            <td className="py-2 text-center">{salesOrder.vendorData?.vendor_name ||'' } </td>
            <td className="py-2 text-center">{salesOrder.item_SO_status}</td>
            <td className="py-2 text-center">{salesOrder.item_PO_status }</td>
            <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" >{salesOrder?.quantity}</td>
            <td className="py-2 text-center">{salesOrder?.receivable_quantity}</td>
            <td className="py-2 text-center">{salesOrder?.delivered_quantity}</td>
          
            <td className="py-2 text-center">{salesOrder?.scheduled_quantity}</td>
            <td className="py-2 text-center">{''}</td>

          </tr>
        ))}
        
      </tbody> 
                    </table>
     
     
    </div>
  </div>
)}

{VehicalDetailsPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
  style={{    maxWidth: '35vw', maxHeight: '60vh',}}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
      <h2 className="text-md">Vehicle Details</h2>
      <button onClick={handleCloseVehicalPopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className=' mt-4 p-4'>
    <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Vehical No
    </label>
    <input
      type="name"
      id="vehical_no"
      value={vehicalNumber }
      onChange={(e)=>setVehicalNumber(e.target.value)}
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      />
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Driver Name
    </label>
    <input
      type="email"
      value={driverName}
      onChange={(e)=>setDriverName(e.target.value)}
      id="email"
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
    />
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-5 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Driver Mobile 
    </label>
    <input
      type="text"
      id="mobile_number"
      value={driverNumber}
      onChange={(e)=>setDriverNumber(e.target.value)}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
       pattern="\d{10}" 
            maxLength="10"
    />
  </div>    
</div>

<div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                  <button className="px-2 py-1  flex-row bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                    onClick={handleSaveVehicalShedulingAndDeliveryChallan}
                  >
                   <span>Save</span>
                    {isSaving && <Spinner />}
                  </button>
                
                  <button onClick={handleCancel} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
                </div>

  </div>
</div>
)}
{/* Popup ends */}
      </div>
       
    </div>
  );
};

export default VehicalSheduling;
