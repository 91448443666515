import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX, faPen, faPaperPlane, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Css/Tooltip.css';
import { Msg200or201, Msg204, Msg400, Msg401, Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader ,Spinner } from '../Loader/Loader';

//import DeliveryChallanPdfViewer from './DeliveryChallanPdfViewer';
// SalesOrderPdfViewer from '../SalesOrder/SalesOrderPdfViewer';
import VehicleShedulingPdfViewer from './VehicleShedulingPdfViewer';
const VehicalSchedulingDetails = () => {
  const navigate = useNavigate();
  // or get this from props or API
 
  const location = useLocation();
  const {vehicalScheduleData} = location.state;
  console.log(vehicalScheduleData)
  const [scheduleData, setScheduleData] = useState([]);
  const [scheduledOrder, setScheduledOrder] = useState();
  const [sheduleId , setSheduleId] = useState('');
  const [scheduledSalesOrderItems , setScheduledSalesOrderItems] = useState([]);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [ selectedSchedule, setSelectedSchedule] = useState(null);

  const [vehicalNumber , setVehicalNumber] = useState('');
  const [driverName , setDriverName] = useState('');
  const [driverNumber , setDriverNumber] = useState('');
  const [VehicalDetailsPopup,setVehicalDetailsPopup]= useState(false)

  

  const [showConvertPopup, setShowConvertPopup] = useState(false);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

  const handleClose = () => {
    navigate('/bb/app/sales/vehicleshedulelist');
  };

  const handleEdit = () => {
    /* navigate('/bb/app'); */
  };

//   const handleCreatePurchaseOrder = () => {
//     navigate('/bb/app/purchases/newpurchaseorder', { state: { fromChallan: DeliveryChallanData } });
//   }

useEffect(() => {
  getVehicleschedule(vehicalScheduleData);
  //handleClickVehicleSchedule(vehicalScheduleData)
}, [vehicalScheduleData]);


const getVehicleschedule = async (vehicalScheduleData , attempt = 0) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(fetchUrl + `customer/vehicle-schedule/get-all-vehicle-schedules?organizationId=${sessionStorage.getItem('organizationId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    const output = await handleGetResponse(response, data);
    if (output) {
      setScheduleData(output);
      const UpdatedData = output.find(p=>p._id === vehicalScheduleData._id)
      handleClickVehicleSchedule(UpdatedData);
      //setScheduledOrder(vehicalScheduleData)
      console.log(output);
    } else {
      console.error(data.message);
    }
  } catch (error) {
    console.error('Failed to fetch vehicle schedules:', error);
    setPopupMessage('Network error. Please check your connection and try again.');
    setPopupType('error');
    setShowPopup(true);
  }
};
  const closePopup = () => {
    setShowPopup(false);
  };

  const BackToLoginPage = () => {
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
    navigate('/bb');
  };
  
  const formatScheduleDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

//  const convertVehicleScheduleToDeliveryChallan = () => {
//   navigate('/bb/app/deliverychallans/deliverychallanslist');
// };

const handleClickVehicleSchedule = (vehiCleschedule) => {
  GetVehicleSheduleSalesOrderDataById(vehiCleschedule._id)
  setScheduledOrder(vehiCleschedule)
  setSelectedSchedule(vehiCleschedule)
}

const GetVehicleSheduleSalesOrderDataById = async (scheduleId) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(fetchUrl + `customer/vehicle-schedule/get-vehicle-schedule-by-id?scheduleId=${scheduleId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
    });

    const data = await response.json();
    const output = await handleGetResponse(response, data);
     console.log(output);
    if (output) {
      setScheduledSalesOrderItems(output);
    } else {
      setScheduledSalesOrderItems([])
    }
  } catch (error) {
    console.error('Failed to fetch vehicle schedules:', error);
    setPopupMessage('Network error. Please check your connection and try again.');
    setPopupType('500');
    setShowPopup(true);
    setRetryFunction(() => () => GetVehicleSheduleSalesOrderDataById(scheduleId));
  }
};

const [ConfirmationPopup, setConfirmationPopup]= useState(false);

const hadleConvertDeliveryChallan = () => {
  //setShowConvertPopup(true);
  setConfirmationPopup(true);
};

const handleCancel = () => {
  setShowConvertPopup(false);
  setDriverName('');
  setDriverNumber('');
  setVehicalNumber('');
  setConfirmationPopup(false);

}

const convertVehicleScheduleToDeliveryChallan = async () => {
  try {
  
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}customer/vehicle-schedule/create-delivery-challan-for-vehicle-schedule?vehicleScheduleId=${scheduledOrder._id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
    });
console.log(response);
    const data = await response.json();
    const output = await handleSaveResponse(response, data);
console.log(output);
    if (output) {
      setConfirmationPopup(false);  
      setTimeout(() => {
        setShowPopup(false);
        navigate('/bb/app/deliverychallans/deliverychallanslist');
      }, 2000);
    } else {
      console.log('')
    }
  } catch (error) {
    console.error('Error converting Vehicle Schedule to Delivery Challans:', error);
    setPopupMessage('Failed to convert Vehicle Schedule to Delivery Challans. Please try again.');
    setPopupType('500');
    setShowPopup(true);
    setRetryFunction(() => () => convertVehicleScheduleToDeliveryChallan(scheduledOrder._id));
  }
}


  return (
    <div className="bg-white flex flex-row w-full justify-center overflow-y-hidden">
      <div className="flex flex-col w-[32%]">
        <div className="p-4 border-b flex flex-row justify-between">
          <div>
            <h5 className="text-md font-semibold cursor-pointer">
              All Vehicle Schedule
              <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer" />
            </h5>
          </div>
        </div>
  
        <div className="flex justify-center overflow-y-hidden flex-col">
  <div className="flex flex-col h-[80vh] overflow-y-auto">
    {scheduleData && scheduleData.length > 0 && (
      <div>
        {scheduleData.map((schedule, index) => (
          <div
            key={index}
            className={`border-b text-sm flex items-center px-4 py-2 ${
              (selectedSchedule?._id === schedule._id || (index === 0 && !selectedSchedule)) ? 'bg-gray-100' : ''
            } hover:bg-gray-100 cursor-pointer`}
            onClick={() => handleClickVehicleSchedule(schedule)}
          >
            <div className="flex flex-col">
              <p className="text-md font-semibold">{schedule.schedule_number}</p>
              <p className="text-gray-600 text-sm flex items-center">
                {formatScheduleDate(schedule.schedule_date)}
              </p>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
</div>
      </div>

      <div className="border-l w-full flex flex-col">
        <div className="border-b h-30">
          <div className="p-4 flex justify-between items-center">
             <span className="text-md font-semibold">{selectedSchedule?.schedule_number || scheduleData?.schedule_number}</span> 
            <button className="text-white px-1 py-1 rounded-md"
             title="" data-tooltip="Close" style={{ '--tooltip-bg': 'black' }}>
              <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-sm text-[#f7525a] mx-2 cursor-pointer" />
            </button>
          </div>
          <div className="flex items-center px-2 bg-[#F9F9FB] border-t">
            <button className="border-r px-4 py-2 text-sm mr-2" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} className="mr-2 w-3 h-3" />
              Edit
            </button>
            {/* <button className="px-4 py-2 border-r text-sm mr-2">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2 w-3 h-3" />
              Send
            </button>
            <button className="text-sm border-r px-4 py-2 mr-2">
              <FontAwesomeIcon icon={faShareAlt} className="mr-2 w-3 h-3" />
              Share
            </button> */}
          </div>
        </div>
        <div className="w-full h-full bg-white rounded-xs overflow-hidden">
  <div className="flex flex-col h-[80vh] overflow-hidden">
    <div className="overflow-y-auto flex-1 p-6">
      {scheduledOrder && (
        <>
          <div className="mt-4 mx-10 px-2 border p-2 flex items-center border-l-4">
            <div className="flex-1">
              <p className="text-sm font-semibold">What's Next?</p>
              <p className="mt-1 mb-2 text-sm text-gray-700">
                {scheduledOrder.DC_status === 'Yes' ? 'DC- created' : 'Convert into Delivery Challan'}
              </p>
            </div>
            <div className="flex-shrink-0 flex space-x-2">
              {scheduledOrder.DC_status === 'Yes' ? (
                <p className="text-sm text-green-600">DC-created</p>
              ) : (
                <button
                  className="px-2 py-1 text-xs bg-[#f7525a] border-[#f7525a] text-white rounded-md"
                  onClick={hadleConvertDeliveryChallan}
                >
                  convert To DC
                </button>
              )}
            </div>
          </div>

          {/* <h1 className="text-lg mt-4 px-4 font-semibold mb-0">{DeliveryChallanData?.status}</h1> */}
          
          <div className="mt-4">
            <VehicleShedulingPdfViewer schedule={scheduledSalesOrderItems} vehicleSchdule={scheduledOrder} />
          </div>

          {/* <div className="mt-4">
            <SalesOrderPdfViewer salesOrder={DeliveryChallanData} />
          </div> */}
        </>
      )}
    </div>
  </div>
</div>
</div>

      {showConvertPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
  style={{    maxWidth: '35vw', maxHeight: '60vh',}}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
      <h2 className="text-md">Vehicle Details</h2>
      <button onClick={handleCancel} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className=' mt-4 p-4'>
    <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Vehical No
    </label>
    <input
      type="name"
      id="vehical_no"
      value={vehicalNumber }
      onChange={(e)=>setVehicalNumber(e.target.value)}
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      />
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Driver Name
    </label>
    <input
      type="email"
      value={driverName}
      onChange={(e)=>setDriverName(e.target.value)}
      id="email"
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
    />
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-5 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Driver Mobile 
    </label>
    <input
      type="text"
      id="mobile_number"
      value={driverNumber}
      onChange={(e)=>setDriverNumber(e.target.value)}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
       pattern="\d{10}" 
            maxLength="10"
    />
  </div>    
</div>

<div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                  <button className="px-2 py-1  flex-row bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                    //onClick={convertVehicleScheduleToDeliveryChallan}
                  >
                   <span>Save</span>
                    {isSaving && <Spinner />}
                  </button>
                
                  <button onClick={handleCancel} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
                </div>

  </div>
</div>
)}
 {ConfirmationPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
  style={{    maxWidth: '35vw', maxHeight: '40vh',}}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
      <h2 className="text-md">DC convertion</h2>
      <button onClick={handleCancel} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className=' mt-4 p-4'>
     <span> Are You Sure Convert To Delivery Challan</span> 
</div>

<div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                  <button className="px-2 py-1  flex-row bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                    onClick={convertVehicleScheduleToDeliveryChallan}
                  >
                   <span>Save</span>
                    {isSaving && <Spinner />}
                  </button>
                
                  <button onClick={handleCancel} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
                </div>

  </div>
</div>
)}
      {showPopup && (
        <div>
          {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
          {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={closePopup} cancelPopup={closePopup} />}
        </div>
      )}
    </div>
  );
};

export default VehicalSchedulingDetails;
