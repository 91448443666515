import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faExclamationTriangle ,faTrash, faX, faPlusCircle, faChevronDown, faTimes, faChevronLeft, faChevronRight, faCamera, faImage, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
//import '../Css/Animation.css';
//import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from './Messages/Messages';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdAddLocationAlt } from 'react-icons/md';
import { Spinner } from '../BBAdmin/Loader/Loader';
//import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
//import { validate } from '../CommenResponseAndErrorHandlers/commenUiErrorHandlers';
//import Dropdown from './DropDown'
// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
          callback();
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => {
          document.removeEventListener('click', handleClick);
      };
  }, []);
};
 
const CustomDropdown = ({ options, selected, onSelect,showAddNew, disabled  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null);
  
    const filteredOptions = options.filter(option => {
      if (!option || !option.label) return false;
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  
    const handleOptionClick = (value, label, id) => {
      onSelect(value, label, id);
      setIsOpen(false);
      setFocusedOptionIndex(null);
    };
  
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'Enter':
          e.preventDefault();
          if (focusedOptionIndex !== null) {
            const selectedOption = filteredOptions[focusedOptionIndex];
            handleOptionClick(selectedOption.value, selectedOption.label, selectedOption.id);
          }
          break;
        default:
          break;
      }
    };
  
    const scrollToOption = (index) => {
      const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
      if (optionElements && optionElements[index]) {
        optionElements[index].scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    };
  
    useOutsideClick(dropdownRef, () => setIsOpen(false));
    useEffect(() => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isOpen]);
  

  return (
    <div className={`relative md:w-1/2 sm:w-full ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`} ref={dropdownRef}>
    <div
 className={`flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
 onClick={() => !disabled && setIsOpen(!isOpen)}
 >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Select'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {!disabled && isOpen && (
      <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
        <input
          type="text"
          className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus={isOpen}
        />
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option,index) => (
              <div
                key={option.value}
                data-index={index}
                className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                onClick={() => handleOptionClick(option.value, option.label,option.id)}
              >
                {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                {option.label}
              </div>
            ))
          ) : (
            <div className="p-2 text-sm text-gray-500">
              Not available
            </div>
          )}
        </div>
        {showAddNew && (
          <button
          type="button"
          className="flex items-center border-t p-2 mt-2 text-blue-500 "
          onClick={showAddNew}
        >
          <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4 mr-2" />
           New Customer
        </button>
        )}
      </div>
    )}
  </div>
  );
};

const EditProject = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const project= location.state.project;
  console.log(project)
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); 
  const [isSaving, setIsSaving] = useState(false); 
  const [showNewUOMPopup, setShowNewUOMPopup] = useState(false);
  const[ CustomersOptions ,setCustomersOptions]= useState([]);
  const [userId, setUserId] = useState('');
  console.log(userId)
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [CustomerId, setCustomerId] = useState('');
  const [showCreateCustomerPopup, setshowCreateCustomerPopup] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);
 // const [projectImage, setProjectImage] = useState();
      const [images, setImages] = useState([]);
      const [files, setFiles] = useState([]);

  const [Project, setProject] = useState({
    projectName: '',
    builderName: '',
    builderContactNo: '',
    siteAddress: '',
    latitude: '',
    longitude: '',
    projectImage: null,
    siteContactName: '',
    siteContactNo: '',
    notes: ''
  });
  const [customerData, setCustomerData] = useState({
  });
  const [mobile_number, setMobileNumber] = useState('');
  const [customer_name, setCustomerName] = useState('');
  const [customer_id, setcustomer_id] = useState('');
  const [projectImage, setProjectImage] = useState([]);
  const [inputLatitude, setInputLatitude] = useState('');
      const [inputLongitude, setInputLongitude] = useState('');
      const [siteAddress, setInputSiteAddress] = useState('');
      const [notes, setNotes] = useState('');

  useEffect(() => {
      setProjectImage(projectImage);
  }, []);

  useEffect(() => {
    getCustomerData();
  },[]);

  useEffect(() => {
    const storedData = localStorage.getItem('LoginData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUserId(parsedData.userId);
    }
  }, []);
  
  useEffect(() => {
    if (CustomersOptions.length > 0 && project.customer_id) {
      const customer = CustomersOptions.find(option => option.value === project.customer_id);
      if (customer) {
        setSelectedCustomer(customer.name);
        setCustomerName(customer.name);
        setMobileNumber(customer.mobile);
        setCustomerId(customer.value);
      }
    }
  }, [CustomersOptions, project.customer_id]);

  useEffect(() => {
    if (project) {
      setProject({
        projectName: project.projectName || '',
        builderName: project.builderName || '',
        builderContactNo: project.builderContactNo || '',
        siteAddress: project.siteAddress || '',
        latitude: project.latitude || '',
        longitude: project.longitude || '',
        siteContactName: project.siteContactName || '',
        siteContactNo: project.siteContactNo || '',
        notes: project.notes || '',
        customer_id: project.customer_id || ''
      });
      setProjectImage(project.projectImage || []);
    }
  }, [project]);
  

  const getCustomerData = async ( attempt = 1) => {
    const token = sessionStorage.getItem('token');
    try {
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `customer/get-all-customers??organizationId=${sessionStorage.getItem('organizationId')}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'x-token': token,
      },
    });
    const data = await response.json();
    console.log(data.data);
    // Handle response
    if (response.status === 200 || data.status === 201 || data.status === 204) {
        setCustomerData(data.data);
        const options = data.data.map(customer => ({
            value: customer._id,
            label: `${customer.customer_name} - ${customer.mobile_number}`,
            name: customer.customer_name,
            mobile: customer.mobile_number
          }));
          setCustomersOptions(options);
        return data;
      } else if (data.status === 500) {
        setShowPopup(true);
        setPopupType("500");
        setPopupMessage(data.message);
        setRetryFunction(() => () => getCustomerData());
      } else {
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setTimeout(() => getCustomerData( attempt + 1), 1000);
      } else {
        setPopupMessage("Internal Server Error. Kindly Retry.");
        setPopupType("500");
        setShowPopup(true);
        setRetryFunction(() => () => getCustomerData());
      }
    }
  };


      const handleUpdateProject = async (attempt = 1) => {
        setIsSaving(true);
        const formData = new FormData();
      
        // Append all project fields to formData
        Object.keys(Project).forEach(key => {
          formData.append(key, Project[key]);
        });
      
        // Append new images to formData
        for (let i = 0; i < files.length; i++) {
          formData.append('file', files[i]);
        }
      
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/projects/update-project?projectId=${project._id}`, {
            method: 'PUT',
            headers: {
              'x-token': token,
            },
            body: formData,
          });
      
          const data = await response.json();
      
          if (response.ok) {
            setShowPopup(true);
            setPopupType("200or201");
            setPopupMessage("Project updated successfully.");
            navigate('/bb/app/projects/projectdetails');
          } else {
            throw new Error(data.message || "Failed to update project");
          }
        } catch (error) {
          console.error('Error during API call:', error);
          setPopupMessage(error.message);
          setPopupType("500");
          setShowPopup(true);
          setRetryFunction(() => () => handleUpdateProject(attempt));
        } finally {
          setIsSaving(false);
        }
      };
      

      const handleCreateContactPerson = async (name, mobile_number, customer_id, attempt = 1) => {
        // Create an object to hold non-empty fields
        const newContactPerson = { customer_id };
      
        // Conditionally add the fields if they are not empty
        if (name) newContactPerson.name = name;
        if (mobile_number) newContactPerson.mobile_number = mobile_number;
      
        // If both fields are empty, log a message and skip the request
        if (!newContactPerson.name && !newContactPerson.mobile_number) {
          console.log("Both contact name and number are empty. Skipping contact person creation.");
          return true; // No need to proceed further
        }
      
        setIsSaving(true);
      
        try {
          const token = sessionStorage.getItem('token');
          const url = process.env.REACT_APP_FORE_BRICKBUCKET + "customer/create-customer-contact";
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
            body: JSON.stringify(newContactPerson),
          });
      
          const data = await response.json();
          if (response.ok) {
            console.log("Contact person created:", data);
            return true; // Return true on success
          } else {
            setIsSaving(false);
            setPopupMessage(data.message);
            setPopupType(`${data.status}`);
            return false; // Return false on failure
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
      
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => handleCreateContactPerson(name, mobile_number, customer_id, attempt + 1), 1000); // Retry after 1 second
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => handleCreateContactPerson(name, mobile_number, customer_id, attempt)); // Set retry function
          }
        } finally {
          setErrorMessages([]);
          setIsSaving(false);
        }
      };
      
      

  const resetCustomerForm = () => {
    setCustomerName('');
    setMobileNumber('');
  };

  const handleDropdownChange = (value, label, id) => {
    if (value === 'add-new') {
      resetCustomerForm();
      setshowCreateCustomerPopup(true);
    } else {
      const selectedCustomer = CustomersOptions.find(option => option.value === value);
      setCustomerName(selectedCustomer.name);
      setMobileNumber(selectedCustomer.mobile);
      setSelectedCustomer(selectedCustomer.name);
      setCustomerId(selectedCustomer.value);
  
      // Update the Project state with the selected customer_id
      setProject(prevProject => ({
        ...prevProject,
        customer_id: selectedCustomer.value,
        builderName: selectedCustomer.name,
        builderContactNo: selectedCustomer.mobile
      }));
    }
  };


  const handleCopyToBuilder = () => {
    setProject(prevProject => ({
      ...prevProject,
      builderName: prevProject.siteContactName,
      builderContactNo: prevProject.siteContactNo
    }));
  };
  
  
  const handleCopyToSiteContact = () => {
    setProject(prevProject => ({
      ...prevProject,
      siteContactName: prevProject.builderName,
      siteContactNo: prevProject.builderContactNo
    }));
  };
  
  const handleImageClick = () => {
    setModalOpen(true);
   // console.log("Modal state set to:", true);
   // setCurrentImageIndex(0);
   const totalImages = projectImage.length + images.length;
   setCurrentImageIndex(totalImages - 1);
  };
  
  const handleCloseModal = () => {
    setModalOpen(false);
   // window.location.reload();
  };
  
  const handleImageUpload = (event) => {
    const newImages = [];
    const newFiles = [];
  
    for (let i = 0; i < event.target.files.length; i++) {
      const imageUrl = URL.createObjectURL(event.target.files[i]);
      const fileUrl = event.target.files[i];
      newImages.push(imageUrl);
      newFiles.push(fileUrl);
    }
  
    setImages(prevImages => [...prevImages, ...newImages]);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  
    // Set the uploadedImage state to the last uploaded image URL
    if (newImages.length > 0) {
      setUploadedImage(newImages[newImages.length - 1]);
    }
  
    // Set the current image index to the last uploaded image
    setCurrentImageIndex(images.length + newImages.length - 1);
  
    // Trigger a re-render
    setForceUpdate(prev => !prev);
  };
  
  
  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };
  
  const handleNextImage = () => {
    if (currentImageIndex < (projectImage.length + images.length - 1)) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };


  

  const handleProjectInputChange = (e) => {
    const { id, value } = e.target;
    setProject(prevProject => ({
      ...prevProject,
      [id]: value
    }));
  }

  const getLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            setInputLatitude(position.coords.latitude.toString());
            setInputLongitude(position.coords.longitude.toString());
        });
    } else {
        alert('Geolocation is not supported by this browser.');
    }
  };

  const handleCancel = () => {
    navigate(-1);
  }

  const handleClosePopup = () => {
    setShowPopup(false);
    resetCustomerForm();
    setshowCreateCustomerPopup(false)
    
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async () => {
    console.log("Retry called");
    setIsLoading(true); // Show loader
    setTimeout(async () => {
      if (retryFunction) {
        await retryFunction();
      }
      setIsLoading(false); // Hide loader after retry
    }, 5000); // 5-second delay
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };
  
 

  return (
    <div className="bg-white flex flex-col md:flex-row w-full justify-left md:h-full overflow-y-hidden">
  <div className="flex flex-row w-full">
    <div className="w-full flex-1">
      <div className="flex flex-col w-full h-full justify-center items-center">
        <div className="w-full h-full flex flex-col justify-between">
          <div className="w-full h-16 border-b flex items-center">
            <div className="flex w-full justify-between px-4 items-center">
              <h1 className="text-lg font-semibold">Edit Project</h1>
             {/*  <button className="text-white px-1 py-1 rounded-md">
                <FontAwesomeIcon icon={faX} className="text-md text-[#F7525A] cursor-pointer" />
              </button> */}
            </div>
          </div>
     {/*      {Object.keys(errors).length > 0 && (
        <div className="flex justify-between  w-full p-2 items-center px-auto py-3 bg-[#fddcde] rounded-md">
          <div className="px-6 flex flex-col space-y-2 justify-start items-start">
            {errors.vendor && (
              <span className="text-sm">
                • {errors.vendor}
              </span>
            )}
            {errors.product_category && (
              <span className="text-sm">
                • {errors.product_category}
              </span>
            )}
            {errors.product_name && (
              <span className="text-sm">
                • {errors.product_name}
              </span>
            )}
            {errors.UOM && (
              <span className="text-sm">
                • {errors.UOM}
              </span>
            )}
          </div>
          <div className='px-4'>
            <button
              className="text-gray-700 hover:text-red-500"
              onClick={() => setErrors({})}
            >
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )} */}
      
      
     
          <div className="flex flex-col justify-start items-start w-full flex-grow sm:h-full h-80 overflow-y-auto"> 
            {/* Image Upload Box - Moved to top for mobile */}
             
                  <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full md:hidden flex justify-center items-start mt-5 p-4'>
        <div className="w-3/4 h-32 border-2 border-dashed border-gray-300 rounded-lg flex justify-center items-center relative">
          {/* Your existing image upload code here */}
          {(images && images.length > 0) || (projectImage && projectImage.length > 0) ? (
    <div className="relative mt-2">
      <img
        src={images && images.length > 0 ? images[images.length - 1] : 
            projectImage && projectImage.length > 0 ? `${process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL}${projectImage[projectImage.length - 1]}` : 
            'default-image-url'}
        alt="Uploaded Profile"
        className="w-20 h-20 object-cover border-4 border-white cursor-pointer"
        onClick={handleImageClick}
      />
      <label
        htmlFor="imageUpload"
        className="text-blue-500 text-sm underline hover:text-blue-900 cursor-pointer"
      >
        <FontAwesomeIcon icon={faCamera} />
        <input 
          type="file" 
          id="imageUpload" 
          accept="image/*" 
          className="hidden" 
          onChange={handleImageUpload} 
          multiple 
        />
      </label>
    </div>
  ) : (
    <label htmlFor="imageUpload" className="cursor-pointer">
      <span className="hover:text-gray-700 text-gray-500 text-4xl mr-2">
        <FontAwesomeIcon icon={faImage} />
      </span>
      <input 
        type="file" 
        id="imageUpload" 
        accept="image/*" 
        className="hidden" 
        onChange={handleImageUpload} 
        multiple 
      />
    </label>
  )}
        </div>
        {modalOpen && (
  <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50 p-4">
    <div className="bg-white rounded-lg shadow-md relative max-w-sm w-full">
      <div className="flex justify-end cursor-pointer absolute top-2 right-2" onClick={handleCloseModal}>
        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
      </div>
      <div className="flex items-center justify-center p-4">
        <button
          className={`px-2 py-1 mr-2 ${currentImageIndex === 0 ? 'opacity-20 pointer-events-none' : ''}`}
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-xl md:text-2xl" />
        </button>
        <div className="flex-grow flex justify-center">
          <img
            src={currentImageIndex < projectImage.length 
              ? `${process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL}${projectImage[currentImageIndex]}`
              : images[currentImageIndex - projectImage.length]}
            alt={`Image ${currentImageIndex + 1}`}
            className="max-w-full max-h-64 object-contain"
          />
        </div>
        <button
          className={`px-2 py-1 ml-2 ${currentImageIndex === (projectImage.length + images.length - 1) ? 'opacity-20 pointer-events-none' : ''}`}
          onClick={handleNextImage}
          disabled={currentImageIndex === (projectImage.length + images.length - 1)}
        >
          <FontAwesomeIcon icon={faChevronRight} className="text-xl md:text-2xl" />
        </button>
      </div>
    </div>
  </div>
)}



      </div> 
                  <div className='w-full flex flex-row'>
            <div className="flex flex-col w-full">
              {/* Product Category Name Label and Input */}
              <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-20">
  <label htmlFor="uom" className="block text-sm md:text-sm font-regular text-[#e54643] w-full md:w-1/6 mb-2 md:mb-0">
    Customer Name
    <span className="text-[#e54643]">*</span>
  </label>
  
    <CustomDropdown 
      options={CustomersOptions} 
      selected={selectedCustomer} 
      onSelect={handleDropdownChange}
      showAddNew={false}
      disabled={true}
     
    /> 

   {/*  <input 
    type='text'
    value={selectedCustomer}
    disabled
    className="w-full md:w-1/2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    /> */}
  
</div>

                    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-20">
  <label htmlFor="productCategoryName" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Project Name
  </label>          
  <input
    id="projectName"
    className="w-full md:w-1/2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    type="text"
    placeholder="Enter project name"
    value={Project.projectName}
    onChange={handleProjectInputChange}
  />
</div>

              {/* Product Sub-Category Name Label and Input */}
              <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-20">
  <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Site Address
  </label>
  <input
    id='siteAddress'
    className="w-full md:w-1/2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    type="text" 
    placeholder="Enter site address"
    value={Project.siteAddress}
    onChange={handleProjectInputChange}
  />
</div>

          
                     {/* vendor Label and Input */}
                    
                      
                     <div className="flex flex-col w-full">
  <div className="flex flex-row space-x-3  text-black text-opacity-80 text-base self-stretch whitespace-nowrap mt-3">
    <div className='flex flex-row text-sm space-x-2'> Add Location : </div>
    <button onClick={getLocation}><MdAddLocationAlt className='text-xl border border-[#000000]'/></button>
  </div>
  
  <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-20">
  <label htmlFor="latitude" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Latitude
  </label>
  <input
    className="w-full md:w-1/2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    type="text"
    placeholder="Enter Latitude"
    id='latitude'
    value={Project.latitude}
    onChange={handleProjectInputChange}
  />
</div>


<div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-20">
  <label htmlFor="longitude" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Longitude
  </label>
  <input
    className="w-full md:w-1/2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    type="text"
    placeholder="Enter Longitude"
    id='longitude'
    value={Project.longitude}
    onChange={handleProjectInputChange}
  />
</div>

</div>

                   
                    
                     {/* Product Category Name Label and Input */}
{/*                      <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-20">
  <label htmlFor="productCategoryName" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Visit Notes
  </label>
  <textarea
    id="notes"
    className="w-full md:w-2/5 border bg-white bg-opacity-30 h-16 border-opacity-20 text-[14px] text-gray-600 px-2 rounded-md"
    rows="3"
    value={project.notes}
    onChange={handleProjectInputChange}
    placeholder="Enter notes..."
  />
</div> */}

                    </div>
                    <div className='hidden md:flex w-1/2 justify-center items-start mt-5'>
              {/* Image Upload Box for md, lg, xl screens */}
               <div className=" w-1/2 h-[50%] border-2 border-dashed border-gray-300 rounded-lg flex justify-center items-center relative">
              {(images && images.length > 0) || (projectImage && projectImage.length > 0) ? (
    <div className="relative">
      <img
        src={images && images.length > 0 ? images[images.length - 1] : 
            projectImage && projectImage.length > 0 ? `${process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL}${projectImage[projectImage.length - 1]}` : 
            'default-image-url'}
        alt="Uploaded Profile"
        className="w-20 h-20 object-cover border-4 border-white cursor-pointer"
        onClick={handleImageClick}
      />
      <label
        htmlFor="imageUpload"
        className="text-blue-500 text-sm underline hover:text-blue-900 cursor-pointer"
      >
        <FontAwesomeIcon icon={faCamera} />
        <input 
          type="file" 
          id="imageUpload" 
          accept="image/*" 
          className="hidden" 
          onChange={handleImageUpload} 
          multiple 
        />
      </label>
    </div>
  ) : (
    <label htmlFor="imageUpload" className="cursor-pointer">
      <span className="hover:text-gray-700 text-gray-500 text-4xl mr-2">
        <FontAwesomeIcon icon={faImage} />
      </span>
      <input 
        type="file" 
        id="imageUpload" 
        accept="image/*" 
        className="hidden" 
        onChange={handleImageUpload} 
        multiple 
      />
    </label>
  )}
              </div> 
              {modalOpen && (
  <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50 p-4">
    <div className="bg-white rounded-lg shadow-md relative max-w-sm w-full">
      <div className="flex justify-end cursor-pointer absolute top-2 right-2" onClick={handleCloseModal}>
        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
      </div>
      <div className="flex items-center justify-center p-4">
        <button
          className={`px-2 py-1 mr-2 ${currentImageIndex === 0 ? 'opacity-20 pointer-events-none' : ''}`}
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-xl md:text-2xl" />
        </button>
        <div className="flex-grow flex justify-center">
          <img
            src={currentImageIndex < projectImage.length 
              ? `${process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL}${projectImage[currentImageIndex]}`
              : images[currentImageIndex - projectImage.length]}
            alt={`Image ${currentImageIndex + 1}`}
            className="max-w-full max-h-64 object-contain"
          />
        </div>
        <button
          className={`px-2 py-1 ml-2 ${currentImageIndex === (projectImage.length + images.length - 1) ? 'opacity-20 pointer-events-none' : ''}`}
          onClick={handleNextImage}
          disabled={currentImageIndex === (projectImage.length + images.length - 1)}
        >
          <FontAwesomeIcon icon={faChevronRight} className="text-xl md:text-2xl" />
        </button>
      </div>
    </div>
  </div>
)} 
              </div>
                    </div>
                    </div>
                    
                    <div className="w-full flex flex-col justify-start items-start px-4">
                     {/* Product Category Name Label and Input */}
                     <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-6">
  <label htmlFor="product_name" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Builder Name
  </label>
  <input
    className="w-full md:w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    type="text" 
    placeholder="Enter Builder name"
    id='builderName'
    value={Project.builderName}
    onChange={handleProjectInputChange}
  />
</div>

                    {/* Product Category Name Label and Input */}
                    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-6">
  <label htmlFor="SKU" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Builder Contact No
  </label>
  <input
    className="w-full md:w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    type="text" 
    placeholder="Enter builder contact number"
    id='builderContactNo'
    value={Project.builderContactNo}
    maxLength={10} // Limits input to 10 characters
    onChange={(e) => {
      const value = e.target.value;
      // Only allow digits and limit to 10 characters
      if (/^\d*$/.test(value) && value.length <= 10) {
        handleProjectInputChange(e);
      }
    }}
  />
</div>


<div className="flex flex-row py-2 md:w-1/2 w-full justify-center items-center">
                  <div className="mr-2">
                    <button
                       onClick={handleCopyToBuilder} 
                      className="text-md text-gray-500 focus:outline-none"
                      disabled={!Project.siteContactName || !Project.siteContactNo}
                    >
                      <FontAwesomeIcon icon={faArrowUp} />
                    </button>
                  </div>
                  <div>
                    <button
                       onClick={handleCopyToSiteContact} 
                      className="text-md text-gray-500 focus:outline-none"
                      disabled={!Project.builderName || !Project.builderContactNo}
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                    </button>
                  </div>
                </div>

                    {/* Product Category Name Label and Input */}
                    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-6">
  <label htmlFor="HSN_Code" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Site Contact Name
  </label>
  <input
    className="w-full md:w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    type="text" 
    placeholder="Enter site contact name"
    id='siteContactName'
    value={Project.siteContactName}
    onChange={handleProjectInputChange}
  />
</div>


                
<div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 mt-5 md:space-x-6">
  <label htmlFor="siteContactNo" className="block text-sm font-regular text-gray-700 w-full md:w-1/6 mb-2 md:mb-0">
    Site Contact No
  </label>
  <input
    className="w-full md:w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    type="text"
    placeholder="Enter site contact number"
    id="siteContactNo"
    value={Project.siteContactNo}
    maxLength={10} // Limits input to 10 characters
    onChange={(e) => {
      const value = e.target.value;
      // Only allow digits and limit to 10 characters
      if (/^\d*$/.test(value) && value.length <= 10) {
        handleProjectInputChange(e);
      }
    }}
  />
</div>

                    
    </div>
  </div>
  <div className="w-full">
                  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 flex-row bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
           onClick={handleUpdateProject} >
           <span>Save</span>
             {isSaving && <Spinner />} 
            </button>
          <button  onClick={handleCancel}   className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
         disabled={isSaving} >
          Cancel</button>
        </div>
        </div>   
 </div>
 </div>
 </div>





 {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)} 
{/* Popup ends */}

      </div>
      </div>
  
  );
};

export default EditProject;
