import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaWindowMaximize, FaRuler, FaCompass } from 'react-icons/fa';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader } from '../BBAdmin/Loader/Loader';
import { jwtDecode } from "jwt-decode";


const WindowsQuote = () => {
  const location = useLocation();
  
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('project');
 // console.log(projectId)
  const urlToken = searchParams.get('token');
  const categoryId = searchParams.get('category');
  console.log(categoryId)
  const customerId = searchParams.get('customer');
  const [projectData, setProjectData] = useState({
    projectId: '',
    projectName: '',
    rooms: []
  });

  const [productSubcategories, setProductSubcategories] = useState([]);
  const [selectedFlatIndex, setSelectedFlatIndex] = useState(0);

  const [errorMessages, setErrorMessages] = useState([]);
  
  const fetchUrl = process.env.REACT_APP_FORE_APILINK;
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES

  const [isLoading, setIsLoading] = useState(true);
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

    useEffect(() => {
      if (urlToken) {
        try {
          // Decode the token
          const decodedToken = jwtDecode(urlToken);
          
          // Set session data
          sessionStorage.setItem('token', urlToken);
          sessionStorage.setItem('userId', decodedToken.userId);
          sessionStorage.setItem('username', decodedToken.username);
          sessionStorage.setItem('roleId', decodedToken.roleId);
          sessionStorage.setItem('organizationId', decodedToken.organizationId);
          
        } catch (error) {
          console.error('Error decoding token:', error);
          // Handle error (e.g., redirect to error page)
        }
      }
    }, [urlToken, searchParams]);
  

      const fetchProjectData = async (attempt = 0) => {
        setIsLoading(true);
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}/measurements/get-flat-wise-window-measurements-of-floor?project_id=${projectId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
         // console.log(response);
          const data = await response.json();
          console.log(data);
        //  const output = await handleGetResponse(response, data);
        //   console.log(output);
          if (data) {
            setProjectData(data);

            
          } else {
            if (response.status === 404) {
              setPopupMessage('Project not found');
              setPopupType('error');
            } else if (response.status === 500) {
              setPopupMessage('Internal Server Error. Kindly Retry.');
              setPopupType('500');
              setRetryFunction(() => () => fetchProjectData(attempt));
            }
            setShowPopup(true);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1);
            setTimeout(() => fetchProjectData(attempt + 1), 1000);
          } else {
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchProjectData(attempt));
          }
        } finally {
          setIsLoading(false);
        }
      };
    
      useEffect(() => {
        if (projectId) {
          fetchProjectData();
        }
      }, [projectId]);

      const fetchProductSubcategoriesByProductCategoryId = async (categoryId,attempt = 1) => {
        console.log(categoryId);
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${backendApiUrl}product-category/get-all-subcategories-and-UOMs-under-product-category?product_category_id=${categoryId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
          const data = await response.json();
          console.log(data);
          const output =  await getResponseHandling(response , data)
          console.log(output)
          if (output) {
            setProductSubcategories(output?.subCategories || []);
          } else {
            setProductSubcategories([]);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchProductSubcategoriesByProductCategoryId(categoryId,attempt + 1), 1000); // Retry after 1 second
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchProductSubcategoriesByProductCategoryId(categoryId,attempt)); // Set retry function
          }
        }finally{
           //setProductCategoryLoader(false) 
        }
      };

      const getResponseHandling = async (response, data)=>{
        if (response.status === 200 || response.status === 201) {
          setPopupType('200or201');
            return (data.data)
        } else if (response.status === 204) {
          setPopupType('204');
          return (data.data)
        } 
     
        if (response.status === 400) {
          setPopupType('400');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        } else if (response.status === 401) {
          setPopupType('401');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        } else if (response.status === 403) {
         
          setPopupType('403');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        } else if (response.status === 500) {
         
          setPopupType('500');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        } else {
          setErrorMessages([...errorMessages, data.message]);
        }
      
    }
      
    useEffect(() => {
      if (categoryId) {
        fetchProductSubcategoriesByProductCategoryId(categoryId);
      }
    }, [categoryId]);
    

    const groupRoomsBySection = (rooms) => {
      const sections = {
        "Living Areas": ["Living"],
        "Bedrooms": ["Bedroom 1", "Bedroom 2", "Bedroom 3"],
        "Bathrooms": ["Bathroom 1", "Bathroom 2", "Bathroom 3"],
        "Dining Areas": ["Dining"],
        "Kitchens": ["Kitchen"],
      };
    
      return Object.entries(sections).flatMap(([sectionName, roomNames]) => {
        if (sectionName === "Bedrooms" || sectionName === "Bathrooms") {
          return roomNames.map(roomName => ({
            sectionName: roomName,
            rooms: rooms.filter(room => room.roomName === roomName)
          }));
        }
        return [{
          sectionName,
          rooms: rooms.filter(room => roomNames.includes(room.roomName))
        }];
      });
    };

    const isSpecificBathroom = (sectionName) => {
      return ['Bathroom 1', 'Bathroom 2', 'Bathroom 3'].includes(sectionName);
    };
    
    
    

  if (isLoading) {
    return <Loader />;
  }

  //console.log(projectData);

  return (
    <div className="bg-[#F0F4F8] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
    <h1 className="text-4xl font-bold mb-6 text-center text-[#2C3E50] whitespace-nowrap">Select Your Windows</h1>
  
    {projectData && projectData.rooms && projectData.rooms.length > 0 && (
      <>
        <div className="px-2 mt-2">
          <h2 className="text-2xl font-bold mb-2 text-[#2C3E50]">{projectData.projectName}</h2>
          <h3 className="text-xl font-semibold mb-2 text-[#3498DB]">
            Block: {projectData.rooms[0].blocks[0].blockNo}
          </h3>
        </div>

        <div className="flex mb-4">
  {projectData.rooms[0].blocks[0].flats.map((flat, index) => (
    <button
      key={index}
      className={`px-4 py-2 mr-2 rounded-t-lg ${
        selectedFlatIndex === index
          ? 'bg-[#3498DB] text-white'
          : 'bg-gray-200 text-[#2C3E50]'
      }`}
      onClick={() => setSelectedFlatIndex(index)}
    >
      Flat {flat.flatNo}
    </button>
  ))}
</div>

  
        {projectData.rooms[0].blocks[0].flats.length > 0 && (
  <div className="bg-white rounded-lg shadow-lg border border-[#BDC3C7] p-6 mt-4">
    <h3 className="text-xl font-semibold mb-4 text-[#3498DB]">
      Flat No: {projectData.rooms[0].blocks[0].flats[selectedFlatIndex].flatNo}
    </h3>
    {/* <p className="text-lg text-[#2C3E50] mb-4">
      Facing: {projectData.rooms[0].blocks[0].flats[selectedFlatIndex].facing}
    </p> */}
  
            {groupRoomsBySection(projectData.rooms).map((section, sectionIndex) => (
              <div key={sectionIndex} className="mb-8">
                <h3 className="text-xl font-semibold mb-4 text-[#3498DB]">{section.sectionName}</h3>
                {section.rooms.map((room) => (
                  <div key={room.roomId}>
                    {room.blocks[0].flats[selectedFlatIndex].windows.map((window, windowIndex) => (
                      <div key={windowIndex} className="bg-[#ECF0F1] p-4 rounded-lg mb-4 shadow-md">
                        <div className="flex flex-col items-start mb-2">
                          <div className="flex items-center w-full">
                            <FaWindowMaximize className="text-green-600  mr-2" />
                            <span className="text-lg font-medium text-black mr-2">
  {isSpecificBathroom(section.sectionName) ? 'Ventilator' : `Window ${windowIndex + 1}`}
</span>

                            <span className="text-sm text-black mt-1">({window.length}" x {window.breadth}")</span>
                          </div>
                          <div className="text-lg text-[#2C3E50] mb-2">
                            <FaRuler className="inline mr-2 text-[#E74C3C]" />
                            Area: <span className="font-bold">{window.areaInSFT}</span> <span className="font-semibold"> sq ft</span>
                          </div>      
                        </div>
  
                        <div className="mt-4">
  <div className="grid grid-cols-2 gap-2">
    {productSubcategories
      .filter(subcategory => 
        isSpecificBathroom(section.sectionName) 
          ? subcategory.product_sub_category_name.toLowerCase().includes('ventilator')
          : !subcategory.product_sub_category_name.toLowerCase().includes('ventilator')
      )
      .map((subcategory, index) => (
        <div key={index} className="bg-white p-2 rounded-md flex flex-col items-center">
          <img
            src={`${imageUrl}${subcategory.product_sub_category_image}`}
            alt={subcategory.product_sub_category_name}
            className="w-16 h-16 object-cover mb-2"
          />
          <span className="text-sm text-center">{subcategory.product_sub_category_name}</span>
        </div>
      ))}
  </div>
</div>



                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </>
    )}
  </div>
  
  );
};

export default WindowsQuote;
