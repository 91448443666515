import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronDown, faChevronUp, faSortUp, faSortDown, faX, faPaperclip, faPlus, faPen, faMobileAndroidAlt, faPlusCircle, faSave, faTimes, faTrash, faBank, faArrowUp, faArrowDown, faImage, faCamera, faChevronRight, faChevronLeft, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdAddLocationAlt } from 'react-icons/md';
import { Spinner } from '../BBAdmin/Loader/Loader';
import { useCallback } from 'react';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from './Messages/Messages';

const ViewVendor = () => {
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const  [vendors , setVendors] = useState ([]);
  const location = useLocation()
  const projectDetails= location.state?.projectData
  const projectId = location.state?.projectId
  console.log(projectId)
  // console.log(projectDetails)
  const [showAddress, setShowAddress] = useState(false);
  const [SelectedProject, setSelectedProject] = useState(null);
  console.log(SelectedProject)
  const [Projectid , setProjectid] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showOtherDetails , setShowOtherDetails] = useState(false);
  const [showContactPersons , setShowContactPersons] = useState(false);
  const [ShowBankAccountDetails, setShowBankAccountDetails] = useState(false);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const [showAddContactPersonPopup, setShowAddContactPersonPopup] = useState(false);
  const backendApiUrl = process.env.REACT_APP_FORE_APILINK;
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [vendorStatus, setVendorStatus] = useState('Deactivate');
  const RETRY_LIMIT = 3; // Maximum number of retries
const [retryCount, setRetryCount] = useState(0); // Track retry attempts
const [searchQuery, setSearchQuery] = useState('');
const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
const [BlockDetails, setBlockDetails] = useState([]);
const [ProjectEditPopup, setProjectEditPopup] = useState(false);
const [CreateBlockPopup, setCreateBlockPopup] = useState(false);
const [projectImage, setProjectImage] = useState();
      const [images, setImages] = useState([]);
      const [inputLatitude, setInputLatitude] = useState('');
      const [inputLongitude, setInputLongitude] = useState('');
      const [siteAddress, setInputSiteAddress] = useState('');
      const [notes, setNotes] = useState('');
      const [blockNo, setBlockNo] = useState('');
      const [numFloors, setFloors] = useState('');
      const [numFlatsPerFloor, setFlatsPerFloor] = useState('');
      const [numFlats, setTotalFlats] = useState('');
    const [projects, setProjects] = useState([]);
    const [ProjectDetails, setProjectDetails] = useState(null);
    const [projectProfile, setprojectProfile] = useState();
    const [uploadedImage, setUploadedImage] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showMapPopup, setShowMapPopup] = useState(false);
    const [locations, setLocations] = useState([]);
    const [coordinates, setCoordinates] = useState(null);
    const [mapRendered, setMapRendered] = useState(false);
    const [CreateNotesPopup, setCreateNotesPopup] = useState(false);



    const [files, setFiles] = useState([]);
  ////console.log(uploadedImage)
  
  useEffect(() => {
    if (SelectedProject && SelectedProject.projectImage) {
      setProjectImage(SelectedProject.projectImage);
    }
  }, [SelectedProject]);


  

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...BlockDetails].sort((a, b) => {
      const aValue = a[key] || ''; // Treat undefined as empty string
      const bValue = b[key] || ''; // Treat undefined as empty string

      // Convert to string for comparison and handle case insensitivity
      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();

      if (aString < bString) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aString > bString) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setBlockDetails(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
    }
    return null;
  };

  const fetchProjects = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const GetProjects = `${backendApiUrl}/projects/get-all-projects?organizationId=${sessionStorage.getItem('organizationId')}`;
      
      const response = await fetch(GetProjects, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-token': token
        },
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setProjects(data);
        return data;
      } else if (response.status === 500) {
        setShowPopup(true);
        setRetryFunction(() => () => fetchProjects());
        setPopupType("500");
        setPopupMessage(data.message || "Internal Server Error");
      } else {
        setPopupMessage(data.message || "Failed to fetch projects");
        setPopupType(`${response.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch projects:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchProjects(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage("Internal Server Error. Kindly Retry.");
        setPopupType("500");
        setShowPopup(true);
        setRetryFunction(() => () => fetchProjects(attempt));
      }
    }
  
    return [];
  };
  

  useEffect(() => {
    fetchProjects(projectDetails);
  }, [projectDetails]);


  useEffect(() => {
   if(projectDetails){
    handleProjectClick(projectDetails);
   }
   
}, [projectDetails]);

const fetchProjectsByProjectid = async (Projectid, attempt = 1) => {
  // console.log(Projectid);

  try {
    const token = sessionStorage.getItem('token');
    const GetContactPerson = `${backendApiUrl}/projects/get-project?projectId=${Projectid}`;
   

    const response = await fetch(GetContactPerson, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-token': token
      },
    });


    const data = await response.json();
console.log(data);

    if (response.ok) {
      setProjectDetails(data);

      const projectLocations = [{
        latitude: parseFloat(data.latitude),
        longitude: parseFloat(data.longitude),
        ProjectName: data.projectName,
        BuilderName: data.builderName,
        BuilderContact: data.builderContactNo
      }];
    
      const validProjectLocations = projectLocations.filter(location => !isNaN(location.latitude) && !isNaN(location.longitude));
    
      // Calculate average latitude and longitude
      const averageLatitude = validProjectLocations.reduce((sum, location) => sum + location.latitude, 0) / validProjectLocations.length;
      const averageLongitude = validProjectLocations.reduce((sum, location) => sum + location.longitude, 0) / validProjectLocations.length;
    
      setLocations(validProjectLocations);
      setCoordinates({ latitude: averageLatitude, longitude: averageLongitude });
    
      return data;

    } else if (response.status === 500) {
      setShowPopup(true);
      setRetryFunction(() => () => fetchProjectsByProjectid(Projectid));
      setPopupType("500");
      setPopupMessage(data.message || "Internal Server Error");
    } else {
      setPopupMessage(data.message || "Failed to fetch project details");
      setPopupType(`${response.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => fetchProjectsByProjectid(Projectid, attempt + 1), 1000);
    } else {
      setIsLoading(false);
      setPopupMessage("Internal Server Error. Kindly Retry.");
      setPopupType("500");
      setShowPopup(true);
      setRetryFunction(() => () => fetchProjectsByProjectid(Projectid, attempt));
    }
  }
};


    useEffect(() => {
    if (SelectedProject) {
      fetchProjectsByProjectid(SelectedProject._id);
      fetchBlocksByProjectid(SelectedProject._id);
    }
  }, [SelectedProject]);

  useEffect(() => {
    console.log(projectId);
    if (projectId) {
      fetchProjectsByProjectid(projectId);
      fetchBlocksByProjectid(projectId);
    }
    }, [projectId]);

  const handleProjectClick = (project) => {
    setProjectid(project._id);
    setSelectedProject(project);
  };

  const fetchBlocksByProjectid = async (Projectid, attempt = 1) => {

    try {
      const token = sessionStorage.getItem('token');
      const GetContactPerson = `${backendApiUrl}/blocks/get-all-blocks-of-project?projectId=${Projectid}`;

  
      const response = await fetch(GetContactPerson, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-token': token
        },
      });
  
  
      const data = await response.json();
  
      if (response.status === 200 || data.status === 201 || data.status === 204) {
        console.log('blocks',data);
        setBlockDetails(data);
        return data;
      } else if (data.status === 500) {
        setShowPopup(true);
        setPopupType("500");
        setPopupMessage(data.message);
        setRetryFunction(() => () => fetchBlocksByProjectid(Projectid));
      } else {
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setTimeout(() => fetchBlocksByProjectid(Projectid, attempt + 1), 1000);
      } else {
        setPopupMessage("Internal Server Error. Kindly Retry.");
        setPopupType("500");
        setShowPopup(true);
        setRetryFunction(() => () => fetchBlocksByProjectid(Projectid));
      }
    }
  };

 
  

  const updateProjectInList = (updatedProject) => {
    if (updatedProject && updatedProject._id) {
      const updatedProjects = projects.map((project) => {
        if (project._id === updatedProject._id) {
          return { ...project, ...updatedProject };
        }
        return project;
      });
      setProjects(updatedProjects);
    }
  };

      const handleSaveBlock = async (projectId,attempt = 1) => {
        const BlockData = {
          blockNo: String(blockNo),
          numFloors: Number(numFloors),
          numFlatsPerFloor: Number(numFlatsPerFloor),
          numFlats: Number(numFlats),
          projectId: SelectedProject._id,
        };
        
      
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/blocks/create-new-block`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            },
            body: JSON.stringify(BlockData),
          });
          const data = await response.json();
        
      
          if (response.ok) {
            //fetchBlocksData();
           // setEditModeIndex(null);
           // setsaveAlert(true);
           setCreateBlockPopup(false);
            setShowPopup(true);
            setPopupType("200or201");
            setPopupMessage("Block saved successfully.");
            setTimeout(() => {
              setShowPopup(false);
            }, 3000);
            
            // Clear input fields after successful save
            setBlockNo('');
            setFloors('');
            setFlatsPerFloor('');
            setTotalFlats('');
            await fetchBlocksByProjectid(SelectedProject._id);
          } else if (response.status === 500) {
            setShowPopup(true);
            setCreateBlockPopup(false);
            setRetryFunction(() => () => handleSaveBlock(projectId));
            setPopupType("500");
            setPopupMessage(data.message || "Internal Server Error");
          } else {
            setPopupMessage(data.message || "Failed to save block");
            setCreateBlockPopup(false);
            setPopupType(`${response.status}`);
            setShowPopup(true);
          }
        } catch (error) {
          console.error('Error during API call:', error);
      
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1);
            setTimeout(() => handleSaveBlock(projectId, attempt + 1), 1000);
          } else {
            setPopupMessage("Internal Server Error. Kindly Retry.");
            setPopupType("500");
            setShowPopup(true);
            setRetryFunction(() => () => handleSaveBlock(projectId, attempt));
          }
        }
      };


  const handleToggleAddress = () => {
    setShowAddress(!showAddress);
    setShowOtherDetails(false);
    setShowContactPersons(false);
    setShowBankAccountDetails(false);
  };

  const handleToggleOtherDetails = () => {
    setShowOtherDetails(!showOtherDetails);
    setShowContactPersons(false);
    setShowAddress(false);
    setShowBankAccountDetails(false);
  };

  const handleToggleContactPersons = () => {
    setShowContactPersons(!showContactPersons);
    setShowAddress(false);
    setShowOtherDetails(false);
    setShowBankAccountDetails(false);
  };


  const handleDeactivateClick = async (attempt = 1) => {
    setIsLoading(true);

      try {
        const token = sessionStorage.getItem('token');
        const ActivateURL = `${backendApiUrl}vendor/deactivate-vendor?Projectid=${Projectid}`;
        const response = await fetch(ActivateURL, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();

        if (data.status === 200 || data.status === 201 || data.status === 204) {
          setSelectedProject((prevVendor) => ({ ...prevVendor, is_active: false }));
          setVendorStatus('Activate');
          setShowPopup(true);
          setIsLoading(false);
          setPopupType('200or201');
          setPopupMessage(data.message);
          setTimeout(() => {
            setShowPopup(false);
          }, 3000);
          // Update the vendor status in the local state
          setVendors(vendors.map((vendor) => {
            if (vendor._id === Projectid) {
              vendor.is_active = false;
            }
            return vendor;
          }));
        } else {
          setIsLoading(false);
          setPopupMessage(data.message);
          setPopupType(`${data.status}`);
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleDeactivateClick(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleDeactivateClick(attempt)); // Set retry function
        }
      }
    };
  
  const handleActivateClick = async (attempt = 1) => {
    setIsLoading(true);
      try {
        const token = sessionStorage.getItem('token');
        const ActivateURL = `${backendApiUrl}vendor/activate-vendor?Projectid=${Projectid}`;
        const response = await fetch(ActivateURL, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        if (data.status === 200 || data.status === 201 || data.status === 204) {
          setSelectedProject((prevVendor) => ({ ...prevVendor, is_active: true }));
          setVendorStatus('Deactivate');
          setShowPopup(true);
          setIsLoading(false);
          setPopupType('200or201');
          setPopupMessage(data.message);
          setTimeout(() => {
            setShowPopup(false);
          }, 3000);
          // Update the vendor status in the local state
          setVendors(vendors.map((vendor) => {
            if (vendor._id === Projectid) {
              vendor.is_active = true;
            }
            return vendor;
          }));
        } else {
          setIsLoading(false);
          setPopupMessage(data.message);
          setPopupType(`${data.status}`);
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleActivateClick(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleActivateClick(attempt)); // Set retry function
        }
      }
    };


const handlePlusIconClick = (e) => {
  e.stopPropagation(); // Prevent triggering the toggle function

  setCreateNotesPopup(true);
};

const handleEdit = () => {
 // setProjectEditPopup(true);
navigate('/bb/app/projects/editproject', { state: { project: SelectedProject } });
};

const handleClosePopup = () => {
  setProjectEditPopup(false);
  setCreateBlockPopup(false);
  setCreateNotesPopup(false);
};

const handleCreateBlocks = () => {
  setCreateBlockPopup(true);
}


const HandleClose = () => {
  navigate('/bb/app/projects/projectslist'); 
};

const handleNavigatetoMeasuremnts = (Block ) =>{
  const data= {
    project:SelectedProject,
    Block  : Block,
    //measurementType: measurementsType
  }
  navigate('/bb/app/projects/blockdetails', {state:{projectData: data}} );
}


const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const Retry = async () => {
  console.log("Retry called");
  setIsLoading(true); // Show loader
  setTimeout(async () => {
    if (retryFunction) {
      await retryFunction();
    }
    setIsLoading(false); // Hide loader after retry
  }, 5000); // 5-second delay
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};

/* if (isLoading) {
  return <Loader />;
} */

  console.log('Modal Open:', modalOpen);
console.log('Project Image:', projectImage);
console.log('Images:', images);

const handleFloorsChange = (e) => {
  const floors = parseInt(e.target.value);
  setFloors(floors);
  setTotalFlats(floors * numFlatsPerFloor);
};

const handleFlatsPerFloorChange = (e) => {
  const flatsPerFloor = parseInt(e.target.value) ;
  setFlatsPerFloor(flatsPerFloor);
  setTotalFlats(numFloors * flatsPerFloor);
};

const handleTotalFlatsChange = (e) => {
  const totalFlats = parseInt(e.target.value) ;
  setTotalFlats(totalFlats);
};

const handleNewProject = () => {
  navigate("/bb/app/projects/newproject")
}

const handleNotesChange = (e) => {
  setNotes(e.target.value);
  console.log('Notes changed:', e.target.value); // For debugging
};

const handleProjectDetailsSave = async (projectId,attempt = 1) => {
  setIsSaving(true);

  const formData = new FormData();

formData.append('notes', notes);

  
 

  const token = sessionStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FORE_APILINK}/projects/update-project?projectId=${SelectedProject._id}`,
      {
        method: "PUT",
        headers: {
          "x-token": token,
        },
        body: formData,
      }
    );

    const data = await response.json();
    console.log(data);

    if (response.status === 200 || data.status === 201 || data.status === 204) {
      setSelectedProject(data.project); // Update with the returned project data
      updateProjectInList(data.project); // Update with the returned project data
      setProjectEditPopup(false);
      setCreateNotesPopup(false);
      setIsSaving(false);
      setShowPopup(true);
      setPopupType("200or201");
      setPopupMessage("Project Details and Images Updated Successfully.");
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    } else if (response.status === 500) {
      setShowPopup(true);
      setCreateBlockPopup(false);
      setRetryFunction(() => () => handleProjectDetailsSave(projectId));
      setPopupType("500");
      setPopupMessage(data.message || "Internal Server Error");
    } else {
      setPopupMessage(data.message || "Failed to save block");
      setCreateBlockPopup(false);
      setPopupType(`${response.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Error during API call:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => handleProjectDetailsSave(projectId, attempt + 1), 1000);
    } else {
      setPopupMessage("Internal Server Error. Kindly Retry.");
      setPopupType("500");
      setShowPopup(true);
      setRetryFunction(() => () => handleProjectDetailsSave(projectId, attempt));
    }
  }
};

const openGoogleMaps = useCallback(() => {
  const { latitude, longitude } = SelectedProject;
  const url = `https://www.google.com/maps?q=${latitude},${longitude}&z=15`;
  window.open(url, '_blank');
}, [SelectedProject]);


return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
{/* List of Vendors Div start */} {/* sm:hidden md:hidden lg:hidden xl:block */}
      <div className=" flex flex-col  w-[26%] sm:hidden md:hidden lg:hidden xl:block ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Projects
     <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />
    </h5>
    </div>
    <div className=''>
    <button 
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md"  
  /* onClick={handleCreateVendor} */>
  <FontAwesomeIcon icon={faPlus}  className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>

  <div className=" flex justify-center  overflow-y-hidden  flex-col ">
   <div className='flex  flex-col h-[80vh]  overflow-y-auto '>
   {projects?.map((project) => (
        <div
          key={project.id}
          onClick={() => handleProjectClick(project)}
          className={`border-b text-sm flex items-center px-4 py-2 ${Projectid === project._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
        >
          <div className='flex flex-row'>
            <div>
              <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
            </div>
            <div className='flex flex-col'>
              <p className='text-md font-semibold' >{project?.projectName}</p>
              {/* <p className="text-gray-600 text-sm">{project.company_name}</p> */}
            </div>
          </div>
        </div>
      ))}

  </div>

</div>
</div>
{/* List of Vendors Div end */}

<div className="border-l  w-full flex flex-col ">
{(ProjectDetails || SelectedProject) ? (
  <>
<div className="border-b h-24"> {/* or h-12, depending on your desired height */}
<div className='p-4 flex justify-between items-center'>
  <span className="text-md font-semibold break-words max-w-[60%]">{ProjectDetails?.projectName||SelectedProject?.projectName}</span>
  <div className="flex items-center px-2 flex-shrink-0">
    {/* <button className="bg-gray-100 px-1 py-1 shadow-sm text-sm border rounded-md mr-2">
      <FontAwesomeIcon icon={faPaperclip} className="text-md text-black mx-2 cursor-pointer" />
    </button> */}
    <button className='bg-[#f7525a] px-2 py-1 text-white shadow-sm text-sm border rounded-md mr-2 whitespace-nowrap' onClick={handleNewProject}>
      + New Project
    </button>
    <button className="text-white px-1 py-1 rounded-md" title="" data-tooltip="Close" style={{ '--tooltip-bg': 'black' }}>  
      <FontAwesomeIcon icon={faX} onClick={HandleClose} className="text-md text-[#f7525a] cursor-pointer" />
    </button>
  </div>
</div>

</div>
<div className=" flex flex-col md:flex-row h-full md:h-5/6  w-full overflow-y-auto">
<div className="bg-[#fbfbfb] w-full md:w-[32%] border-r  p-4">
    <h2 className="hidden md:flex text-sm pb-2 border-b flex justify-between">
    {ProjectDetails?.projectName||SelectedProject?.projectName}
    <button className=" px-2 py-1 text-[#408dfb] text-sm  "  onClick={handleEdit} >
        Edit
      </button>
    </h2>
    {/* Show full project details on mobile screens */}
<div className="md:hidden">
      <div className='pt-4 pl-4 pr-4 flex flex-row justify-between items-start'>
        <div className='flex-shrink-0'>
          <img className="rounded-md w-10 h-10 cursor-pointer" src={'/ProfileImageSvg.jpg'} alt="Brick Bucket Logo" />
        </div>
        <div className='text-sm ml-3 flex flex-col'>
          <div className='font-semibold text-[1rem] mb-0.5 break-words word-break'>{ProjectDetails?.projectName||SelectedProject?.projectName}</div>
          <div className='break-words word-break'>{ProjectDetails?.builderName||SelectedProject?.builderName}</div>
          <div className='break-words word-break'>
  <a href={`tel:${ProjectDetails?.builderContactNo||SelectedProject?.builderContactNo}`} className='inline-flex items-center'>
    <FontAwesomeIcon icon={faMobileAndroidAlt} className='mr-1 text-xs'/>
    <span className='text-blue-500 hover:underline'>{ProjectDetails?.builderContactNo||SelectedProject?.builderContactNo}</span>
  </a>
</div>

          <div className='text-xs flex flex-row'>
            <button
              onClick={ProjectDetails?.is_active||SelectedProject?.is_active ? handleDeactivateClick : handleActivateClick}
              className='text-[#408dfb] hover:text-blue-600'
            >
              {ProjectDetails?.is_active||SelectedProject?.is_active ? 'Deactivate' : 'Activate'}
            </button>
          </div>
        </div>

        <button className="px-2 py-1 text-[#408dfb] text-sm" onClick={handleEdit}>
          Edit
        </button>
      </div>
      </div>
      {/* for md and lg screens */}
    <div className='hidden md:block pt-4 pl-4 pr-4  flex flex-row'>
    <div className='w-[90%] flex flex-row'>
    <div className='w-2/5'>
    <img className=" rounded-md w-10 h-10 cursor-pointer" src={'/ProfileImageSvg.jpg'} alt="Brick Bucket Logo" />
  </div>
  <div className='w-4/5 text-sm ml-3 flex flex-col'>
    <div className='font-semibold mb-0.5 break-words word-break'>{ProjectDetails?.projectName||SelectedProject?.projectName}</div>
    <div className='break-words word-break'>{ProjectDetails?.builderName||SelectedProject?.builderName}</div>
    <div className='break-words word-break'>
      <FontAwesomeIcon icon={faMobileAndroidAlt} className='mr-1 text-xs'/>
      {ProjectDetails?.builderContactNo||SelectedProject?.builderContactNo}
    </div>
    <div className='text-xs flex flex-row'>
      {/* <button onClick={handleEditClick} className='text-[#408dfb] hover:text-blue-600'>Edit</button>
      <p className='text-gray-300 ml-2 mr-2 '>|</p> */}
      <button
      onClick={ProjectDetails?.is_active||SelectedProject?.is_active ? handleDeactivateClick : handleActivateClick}
      className='text-[#408dfb] hover:text-blue-600' >
      {ProjectDetails?.is_active||SelectedProject?.is_active ? 'Deactivate' : 'Activate'}
    </button>  </div>
  </div>
</div>
</div>

<div onClick={handleToggleAddress}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <span className=' text-sm cursor-pointer' onClick={handleToggleAddress}>SITE DETAILS</span>
  <button className=' text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleAddress}>
    {showAddress? <FontAwesomeIcon icon={faChevronUp} className='text-xs text-blue-800'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs text-blue-800'/>}
  </button>
</div>

<div className={`${showAddress ? '' : 'hidden'}`}>
  <div className='text-sm pt-2 pl-4 pr-4'>


    <div className='flex flex-col'>
    <div className='flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>
<div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex '>
  
 <div className='flex flex-col space-y-2'>
 <div className='flex flex-row space-x-2'>
  <p className='font-semibold mb-0.5'>Site Address :</p>
  <p>{ProjectDetails?.siteAddress||SelectedProject?.siteAddress}</p>
  </div>
  <div className='flex flex-row space-x-2'>
  <p className='font-semibold mb-0.5'>Site contact Name :</p>
  <p>{ProjectDetails?.siteContactName||SelectedProject?.siteContactName}</p>
  </div>
 
  <div className='flex flex-row space-x-2'>
<p className='font-semibold mb-0.5'>Site contact Number :</p>
<p>{ProjectDetails?.siteContactNo||SelectedProject?.siteContactNo}</p>
</div>
  
  </div>

  <div className='flex items-center'>
 
  </div>
  </div>
</div>
    </div>
  </div>
</div>

<div onClick={handleToggleOtherDetails}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <span className='text-sm cursor-pointer' onClick={handleToggleOtherDetails}>LOCATION</span>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleOtherDetails}>
    {showOtherDetails? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
  </button>
</div>
<div className={`${showOtherDetails ? '' : 'hidden'} mt-2 mb-2 flex flex-col`}>
  <div className='flex flex-row  hover:rounded-md'>
    <div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex'>
      <div className='flex flex-col pb-2 space-y-2'>
        <div className='flex flex-col space-y-1 ml-3'>
          <label className='font-semibold mb-0.5'>Latitude</label>
          <input className='border border-gray-300 p-1 rounded' type='text' value={ProjectDetails?.latitude||SelectedProject?.latitude} readOnly />
        </div>
       
        <div className='flex flex-col space-y-1 ml-3'>
          <label className='font-semibold mb-0.5'>Longitude</label>
          <input className='border border-gray-300 p-1 rounded' type='text' value={ProjectDetails?.longitude||SelectedProject?.longitude} readOnly />
        </div>
       
      </div>
      <div className='flex flex-col w-full justify-center items-center'>
       <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="text-blue-500 border border-black p-1 w-8 h-8 cursor-pointer" // Increased size with w-8 and h-8
            onClick={openGoogleMaps} />
         </div>
    </div>
  </div>
</div>

<div className="hidden md:block">
  <div onClick={handleToggleContactPersons} className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
    <div>
      <span className='text-sm cursor-pointer'>VISIT HISTORY</span>
    </div>
    <div>
      <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handlePlusIconClick}>
        <FontAwesomeIcon icon={faPlusCircle} className='text-blue-500 text-md'/>
      </button>
      <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out'>
        {showContactPersons ? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
      </button>
    </div>
  </div>

  {showContactPersons && (
    <div className='mt-2 mb-2 w-full flex flex-col justify-center items-center'>
      <textarea
        id='visitHistory'
        className='border border-gray-300 w-6/7 p-2 rounded-md resize-none'
        rows={4}
        value={ProjectDetails?.visitHistory.map(visit => `${new Date(visit.visitedDate).toLocaleDateString()}: ${visit.notes}`).join('\n')||SelectedProject?.visitHistory.map(visit => `${new Date(visit.visitedDate).toLocaleDateString()}: ${visit.notes}`).join('\n')}
        readOnly
      ></textarea>
    </div>
  )}
</div>





  </div>
  <div className="w-full md:w-[90vh] flex flex-col md:flex-row ">


            <div className="flex flex-col w-full ">
            <div className='mt-2 flex justify-between'>
    <h1 className="ml-4 mr-4">Blocks</h1>
    <button 
        className='bg-[#f7525a] px-2 py-1 text-white shadow-sm text-sm border rounded-md mr-2' 
        onClick={() => {handleCreateBlocks()}}
    >
        + New Block
    </button>
</div>

                <div className=" flex justify-center overflow-x-auto w-full   mt-2">
                <table className=" w-full mb-5">
          <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
            <tr className="border-b">
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('projectName')}>
                Block {getSortIcon('projectName')}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('builderName')}>
                Floors {getSortIcon('builderName')}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('builderContactNo')}>
              Flats Per Floor {getSortIcon('builderContactNo')}
              </th>
            
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('siteContactNo')}>
                Total Flats {getSortIcon('siteContactNo')}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('siteContactNo')}>
               Flats {getSortIcon('siteContactNo')}
              </th>
            </tr>
          </thead>
          <tbody className="border-b border-t">{console.log(BlockDetails)}
  {BlockDetails && BlockDetails.filter(
  (Block) =>
    (Block.blockNo.toLowerCase().includes(searchQuery.toLowerCase()) || '') &&
    (Block.numFloors.toString().toLowerCase().includes(searchQuery.toLowerCase()) || '') &&
    (Block.numFlatsPerFloor.toString().toLowerCase().includes(searchQuery.toLowerCase()) || '') &&
    (Block.numFlats?.toString().toLowerCase().includes(searchQuery.toLowerCase()) || '')
).map((Block) => (
    <tr key={Block._id} className="text-sm border-b">
      <td
        className="py-2 text-center  cursor-pointer "
       /*  onClick={() => handleNavigateProjectDetails(Block)} */
      >
        {Block.blockNo}
      </td>
      <td className="py-2 text-center">{Block.numFloors}</td>
      <td className="py-2 text-center">{Block.numFlatsPerFloor}</td>
      <td className="py-2 text-center">{Block.numFlats}</td>
      <td className="py-2 text-center">
      <div className="flex flex-row space-x-2 items-center justify-center">
          <span className="text-[#408dfb] font-500 cursor-pointer hover:underline" onClick={()=>handleNavigatetoMeasuremnts(Block )}>
            View
          </span>
          {/* <span className="text-[#408dfb] font-500 cursor-pointer hover:underline" onClick={()=>handleNavigatetoMeasuremnts(Block , 'production')}>
            Production
          </span> */}
        </div>
      </td>
    </tr>
  ))}
  {BlockDetails && BlockDetails.filter(
    (Block) =>
      Block.blockNo.toLowerCase().includes(searchQuery.toLowerCase()|| '') ||
      Block.numFloors.toLowerCase().includes(searchQuery.toLowerCase() || '') ||
      Block.numFlatsPerFloor.toLowerCase().includes(searchQuery.toLowerCase() || '') ||
      Block.numFlats.toLowerCase().includes(searchQuery.toLowerCase() || '')
  ).length === 0 && (
    <tr>
      <td colSpan="5" className="text-center text-[#6c7184] py-4">
        Not found
      </td>
    </tr>
  )}
</tbody>

      </table>
                </div>
              </div>
             {/* Visit history for mobile screens */}
             <div className="md:hidden block mt-4">
  <div onClick={handleToggleContactPersons} className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
    <span className='text-sm cursor-pointer'>VISIT HISTORY</span>
    <div>
      <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handlePlusIconClick}>
        <FontAwesomeIcon icon={faPlusCircle} className='text-blue-500 text-md'/>
      </button>
      <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out'>
        {showContactPersons ? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
      </button>
    </div>
  </div>

  {showContactPersons && (
    <div className='mt-2 mb-2 w-full flex flex-col justify-center items-center'>
     {/*  <label htmlFor='visitHistory' className='mb-1 text-sm font-semibold'>
        Visit History
      </label> */}
      <textarea
        id='visitHistory'
        className='border border-gray-300 w-5/6 p-2 rounded-md resize-none'
        rows={4}
        value={projectDetails?.visitHistory.map(visit => `${new Date(visit.visitedDate).toLocaleDateString()}: ${visit.notes}`).join('\n')||SelectedProject?.visitHistory.map(visit => `${new Date(visit.visitedDate).toLocaleDateString()}: ${visit.notes}`).join('\n')}
        readOnly
      ></textarea>
    </div>
  )}
</div>
            </div>
          </div>
  

</>
) : (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-500">Select a project to view details</p>
          </div>
        )}
</div>


                {/* Popup Shows */}   


{CreateBlockPopup && (
  <div className="fixed inset-0 z-50 flex items-top justify-center bg-gray-800 bg-opacity-50">
  <div className="bg-white rounded-b-md shadow-md w-full md:w-1/2 h-3/4 md:h-2/3 flex flex-col">
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-4 md:px-6'>
      <h2 className="text-md">Create Block Details</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px mt-1 '>
   {/*  {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1 mb-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )} */}

<div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
    <label htmlFor="blockNo" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
     Block
    </label>
    <input
  type="text"
  id="blockNo"  
  value={blockNo}
  onChange={(e) => setBlockNo(e.target.value)}
  className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
  </div>

  <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
      <label htmlFor="numFloors" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
        No of Floors
      </label>
      <input
        type="number"
        id="numFloors"
        value={numFloors}
        onChange={handleFloorsChange}
        className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
      />
    </div>

    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
      <label htmlFor="numFlatsPerFloor" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
        Flats Per Floors
      </label>
      <input
        type="number"
        id="numFlatsPerFloor"
        value={numFlatsPerFloor}
        onChange={handleFlatsPerFloorChange}
        className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
      />
    </div>

    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
      <label htmlFor="numFlats" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
        No of Flats
      </label>
      <input
        type="number"
        id="numFlats"
        value={numFlats}
        onChange={handleTotalFlatsChange}
        className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
      />
    </div>

       
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button  onClick={handleSaveBlock} 
        className="px-2 py-1 bg-[#408dfb] bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"><span>Save</span> {isSaving && (
           <Spinner/> 
        )}</button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{CreateNotesPopup && (
  <div className="fixed inset-0 z-50 flex items-top justify-center bg-gray-800 bg-opacity-50">
  <div className="bg-white rounded-b-md shadow-md w-full md:w-1/2 h-2/5 md:h-2/3 flex flex-col">
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[20%] px-4 md:px-6'>
      <h2 className="text-md">Visit Notes</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px mt-1 '>
   {/*  {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1 mb-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )} */}

<div className='mt-2 mb-2 w-full flex justify-center items-center p-2'>
  <textarea
    id='visitNotes'
    className='w-full border border-gray-300 p-2 rounded-md resize-none'
    placeholder='Enter your notes here...'
    rows={4}
    value={notes}
    onChange={handleNotesChange}
  ></textarea>
</div>


       
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button   onClick={handleProjectDetailsSave}  
        className="px-2 py-1 bg-[#408dfb] bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"><span>Save</span> {isSaving && (
           <Spinner/> 
        )}</button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}


{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}

    </div>
  );
};
export default ViewVendor;