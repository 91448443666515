import React from 'react';

function BankDetails({ account_holder_name, bank_name, account_number, reEnteraccountnumber, bank_IFSC, onChange }) {
  return (
    <div className="flex flex-col justify-start items-start w-full flex-grow ">
    <div className="w-full flex flex-col mb-4 mt-6 ">
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label htmlFor="accountholderName" className="block text-sm font-regular text-gray-700 w-1/6">Account Holder Name
        
        </label>
        <input
          type="text"
          id="account_holder_name"
          name="account_holder_name"
          value={account_holder_name}
          onChange={onChange}
          className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        />
      </div>
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label htmlFor="bankname" className="block text-sm font-regular text-gray-700 w-1/6">Bank Name</label>
        <input
          type="text"
          id="bankname"
          name='bank_name'
          value={bank_name}
          onChange={onChange}
          className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        />
      </div>
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label htmlFor="accountnumber" className="block text-sm font-regular text-[#e54643] w-1/6">Account Number
        <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="accountnumber"
          name='account_number'
          value={account_number}
          onChange={onChange}
          className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        />
      </div>
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label htmlFor="reEnteraccountnumber" className="block text-sm font-regular text-[#e54643] w-1/6">Re-enter Account Number
        <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="reEnteraccountnumber"
          name='reEnteraccountnumber'
          value={reEnteraccountnumber}
          onChange={onChange}
          className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        />
      </div>

      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label htmlFor="ifsc" className="block text-sm font-regular text-[#e54643] w-1/6">IFSC
        <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="ifsc"
          name='bank_IFSC'
          value={bank_IFSC}
          onChange={onChange}
          className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        />
      </div>
      
    </div>
    </div>
  );
}

export default BankDetails;
