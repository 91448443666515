import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

// Register the Roboto font
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf', // Regular
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOkCnqEu92Fr1Mu51xIIzc.ttf', // Bold
      fontWeight: 'bold',
    },
  ],
});

// Define your styles
const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontSize: 10,
    lineHeight: 1.5,
    color: '#000',
    fontFamily: 'Roboto',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  companyInfo: {
    fontSize: 12,
    fontStyle: 'normal',
  },
  billTo: {
    marginTop: 20,
    fontWeight: '100',
    fontSize: 10,
    fontStyle: 'normal',
  },
  gstin: {
    color: 'black',
    fontSize: 10,
  },
  quotationInfo: {
    textAlign: 'right',
  },
  title: {
    fontSize: 20,
    fontStyle: 'normal',
    marginBottom: 4,
  },
  quotationNumber: {
    fontSize: 10,
    marginLeft: '10px',
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 8,
    flexGrow: 1,
  },
  tableHeader: {
    backgroundColor: '#3C3D3A',
    color: '#FFF',
    flexDirection: 'row',
    height: 24,
    alignItems: 'center',
    textAlign: 'center',
  },
  tableRow: {
    flexDirection: 'row',
    height: 24,
    alignItems: 'center',
    textAlign: 'center',
    borderBottom: '1px solid #EEE',
  },
  tableCol: {
    width: '14%',
    padding: 4,
    borderRight: '1px solid #EEE',
    textAlign: 'left',
    paddingLeft: 8,
  },
  tableColRight: {
    width: '14%',
    padding: 4,
    borderRight: '1px solid #EEE',
    textAlign: 'right',
    paddingRight: 8,
  },
  totalsSection: {
    marginTop: 16,
    alignSelf: 'flex-end',
    width: '60%',
    fontSize: 10,
  },
  totalsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  notesSection: {
    marginTop: 16,
    paddingTop: 10,
    borderTop: '1px solid #EEE',
  },
  signature: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  signatureLine: {
    width: '150px',
    height: '1px',
    borderTop: '1px solid #000',
    marginLeft: '10px',
  },
});

// QuotePdfDocument Component
const QuotePdfDocument = ({ quote }) => {
  const itemsPerPage = 20; // Adjust based on your content's height and page size
  let itemNumber = 1; // Initialize item number

  const getTableRows = () => {
    const rows = quote?.items || [];
    const pages = [];
    while (rows.length > 0) {
      pages.push(rows.splice(0, itemsPerPage));
    }
    return pages;
  };

  const tableRows = getTableRows();
  
  const renderTable = (rows) => (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <View style={[styles.tableCol, { width: '5%' }]}><Text>#</Text></View>
        <View style={styles.tableCol}><Text>Items</Text></View>
        <View style={styles.tableCol}><Text>Product Category</Text></View>
        <View style={styles.tableCol}><Text>Quantity</Text></View>
        <View style={styles.tableCol}><Text>Price</Text></View>
        <View style={styles.tableCol}><Text>Tax</Text></View>
        <View style={[styles.tableColRight, { borderRight: 0 }]}><Text>Amount</Text></View>
      </View>
      {rows.length > 0 ? (
        rows.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={[styles.tableCol, { width: '5%' }]}><Text>{itemNumber++}</Text></View>
            <View style={styles.tableCol}><Text>{item.item_id || '--'}</Text></View>
            <View style={styles.tableCol}><Text>{quote?.product_category_id?.product_category_name}</Text></View>
            <View style={styles.tableCol}><Text>{item.quantity || '0'}</Text></View>
            <View style={styles.tableCol}><Text>₹ {item.unit_price || '0'}</Text></View>
            <View style={styles.tableCol}><Text>₹ {item.tax_rate}</Text></View>
            <View style={[styles.tableColRight, { borderRight: 0 }]}><Text>₹ {item.total || '0'}</Text></View>
          </View>
        ))
      ) : (
        <View style={styles.tableRow}>
          <Text>No items available.</Text>
        </View>
      )}
    </View>
  );

  return (
    <Document>
      {tableRows.map((rows, pageIndex) => (
        <Page key={pageIndex} style={styles.page}>
          {/* Header Section - Only on the first page */}
          {pageIndex === 0 && (
            <View style={styles.header}>
              <View style={styles.companyInfo}>
                <Text style={{ fontWeight: '900', fontSize: 14 }}>KRAFTO Ceramics Pvt Ltd</Text>
                <Text style={{ fontWeight: '200', fontSize: 8 }}>Andhra Pradesh</Text>
                <Text style={{ fontWeight: '200', fontSize: 8 }}>India</Text>
                <Text style={{ fontWeight: '200', fontSize: 8 }}>GSTIN 37AAKCK7567P1ZG</Text>
                <Text style={{ fontWeight: '200', fontSize: 8 }}>raja@kraftotiles.com</Text>
                <Text style={styles.billTo}>Bill To</Text>
                <Text style={styles.gstin}>GSTIN: {quote?.customer_id?.gstin}</Text>
              </View>
              <View style={styles.quotationInfo}>
                <Text style={styles.title}>Quote</Text>
                <Text style={styles.quotationNumber}>{quote?.quotation_number || 'N/A'}</Text>
              </View>
            </View>
          )}

          {/* Table Section */}
          {renderTable(rows)}

          {/* Totals Section - Only on the last page */}
          {pageIndex === tableRows.length - 1 && (
            <View style={styles.totalsSection}>
              <View style={styles.totalsRow}>
                <Text>Sub Total:</Text>
                <Text>₹ {quote?.subtotal || '0'}</Text>
              </View>
              <View style={styles.totalsRow}>
                <Text>Tax Amount:</Text>
                <Text>₹ {quote?.tax_amount || '0'}</Text>
              </View>
              <View style={styles.totalsRow}>
                <Text>Total:</Text>
                <Text>₹ {quote?.total || '0'}</Text>
              </View>
            </View>
          )}

          {/* Notes and Signature Section - Only if the table is complete on the last page */}
          {pageIndex === tableRows.length - 1 && (
            <>
              <View style={styles.notesSection}>
                <Text>Notes</Text>
                <Text>Looking forward to your business.</Text>
              </View>
              <View style={styles.signature}>
                <Text>Authorized Signature</Text>
                <View style={styles.signatureLine} />
              </View>
            </>
          )}
        </Page>
      ))}
    </Document>
  );
};

export default QuotePdfDocument;
