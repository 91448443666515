import { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUserCircle, faImage, faCamera, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaTimes } from 'react-icons/fa';


function AdminHomeContainer() {
    const Navigate = useNavigate();
    const location = useLocation();
    const UserDetails = location.state;

  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedUser, setselectedUser] = useState();
  const [selectedproject, setselectedproject] = useState();
  const [ProjectUserId, setProjectUserId] = useState();
  const [AssignUser, setAssignUser] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [UnAssignedUsers,setUnAssignedUsers]= useState();
  const [UnAssignedProjects,setUnAssignedProjects]=useState();
  const [DisplayUsersData,setDisplayUsersData]=useState([]);
  const [DatePopup, setDatePopup] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
const [rescheduleDate, setRescheduleDate] = useState({});
  //const url = `${process.env.REACT_APP_FORE_APILINK}/admin/get-all-users-details`;
  const GetUsers = process.env.REACT_APP_FORE_APILINK + '/admin/get-all-users-details';
  const GetProjects = process.env.REACT_APP_FORE_APILINK + '/projects/get-all-projects';
  const AssignUserToProject = process.env.REACT_APP_FORE_APILINK + '/admin/asign-project-to-user';
  //const url2 = `${process.env.REACT_APP_FORE_APILINK}/projects/get-all-projects`;
  //const url3 = `${process.env.REACT_APP_FORE_APILINK}/admin/asign-project-to-user/${projectId}`;
  

  const UserDetailsString = JSON.stringify(UserDetails);

  const totalUsers = users.length;
  const totalProjects = projects.length;


  const [EditUser, setEditUser] = useState(false);
  const [initialFirstName, setInitialFirstName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [ProfileData,setProfileData]=useState();
  const [allertUnassinedUsers, setallertUnassinedUsers]=useState(false);
 const[allertUnassinedProjects,setallertUnassinedProjects]=useState(false) ;
  const [noSelectAssignUseralert, setnoSelectAssignUseralert] = useState(false);
  const [editModeIndex, setEditModeIndex] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isActivated, setIsActivated] = useState(false);
  const [UserStatus, setUserStatus] = useState(false);
  const [userProfileImage, setuserProfileImage] = useState();
  const [profileImage,setprofileImage]= useState();
  const [UserImage, setUserImage] = useState(uploadedImage);
  const [file,setfile]=useState(null)
  const [coordinates, setCoordinates] = useState(null);
  const [locations, setLocations] = useState([]);
  const [date,setDate] = useState('');


  

//console.log(locations);

  const ImageUrl=process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL
  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
       Navigate('/measurit')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
       Navigate('/measurit')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  
 
  
  const getUserDetails = async (_id) => {
    const GetUserData = `${process.env.REACT_APP_FORE_APILINK}/user/get-user-details?userId=${_id}`;

    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(GetUserData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-token': token
            }
        });

        const data = await response.json();
        // Update state with user details
        setuserProfileImage(data.user.userProfileImage || null);

    } catch (error) {
      clearStorageAndRedirectToHome()
    }
};


  useEffect(() => {
    const storedLoginData = localStorage.getItem('LoginData')||null;
    if (storedLoginData !== null) {
        const storedUserDetails = JSON.parse(storedLoginData);
  getUserDetails(storedUserDetails.userId);
        // Now storedUserDetails is an object containing the user details
    } else {
        // Handle the case where no data is stored under the key 'UserDetails'
    }
    
}, []); 

  const handlePrevCard = () => {
    setCurrentCardIndex(Math.max(0, currentCardIndex - 2));
  };
  
  const handleNextCard = () => {
    setCurrentCardIndex(Math.min(2, currentCardIndex + 2));
  };
  
  
  const handleEditClick = () => {
    // Set edit mode for the selected index
    setEditModeIndex(true);
  };
  const handleInputChange = (field, value) => {
 
    if (field === "firstName") {
      setFirstName(value);
    } else if (field === "lastName") {
      setLastName(value);
    } else if (field === "username") {
      setUserName(value);
      
    } else if (field === "mobileNo") {
      if(!isNaN(value)){
        setMobileNo(value.slice(0,10));
      }
      
    } else if (field === "email") {
      setEmail(value);
    }
  };
  const emailRegex = /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleEditClose = () => {
    setEditUser(false);
  }

  const handleEdit = (User) => {
    // Set the user data in the state when Edit is clicked
    setEditUser(true);
    setFirstName(User.firstName);
    setLastName(User.lastName);
    setUserName(User.username);
    setMobileNo(User.mobileNo);
    setEmail(User.email);
    setProfileData(User); // Assuming user data contains an identifier like _id
    setIsActivated(User.is_active);
    setprofileImage(User.userProfileImage);
  };

  const handleStatus = (user) => {
    setUserStatus(true);
    setProfileData(user);
    // Set the initial state based on the current status of the user
    setIsActivated(user.is_active);
  };

  const updateProjectStatus = (status) => {
    setIsActivated(status);
  };

  const handleToggle = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const endpoint = isActivated
        ? '/admin/deactivate-user'
        : '/admin/activate-user';
  
      const response = await fetch(
        `${process.env.REACT_APP_FORE_APILINK}${endpoint}?userId=${ProfileData._id}` ,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-token':token
          },
        }
      );
  
      if (response.ok) {
        // If the update is successful, update user status in the UI
        updateProjectStatus((prevState) => !prevState);

        // Optionally, you can also fetch the updated user data from the server
        // and update your 'users' state accordingly.
        await fetchUsers();
        setUserStatus(false);
      } else {

      }
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setUploadedImage(URL.createObjectURL(file));
    setUserImage(URL.createObjectURL(file)|| uploadedImage )
    setfile(file)
  };
  


const handleUpdate = async () => {
  const token = sessionStorage.getItem('token');
  // Validate input fields

  const newErrors = {};

  if (!firstName.trim()) {
    newErrors.firstName = "Required*";
  }

  if (!lastName.trim()) {
    newErrors.lastName = "Required*";
  }

  if (!username.trim()) {
    newErrors.username = "Required*";
  }

  if (!/^\d{10}$/.test(mobileNo)) {
    newErrors.mobileNo = "Mobile No must be 10 digits*";
  }

  if (!emailRegex.test(email)) {
    newErrors.email = "Enter a valid email address*";
  }

  // If there are errors, set them in the state
  if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
  } else {

    try {
  
      const formData = new FormData();
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("username", username);
            formData.append("mobileNo", mobileNo);
            formData.append("email", email);
            formData.append("_id", ProfileData._id);
            formData.append("file", file);
            
      const UpdateProfileData = `${process.env.REACT_APP_FORE_APILINK}/user/update-user-details`;
      const response = await fetch(UpdateProfileData, {
        method: "PUT",
        headers:{
          /* 'Content-Type': 'application/json', */
          'x-token':token
        },
        body: formData,
      });

      if (response.ok) {
          // If the update is successful, update the initial first name
          setInitialFirstName(firstName);
          setPopupVisible(true); // Show the popup on successful update
          const updatedData = await response.json();
          setEditModeIndex(false);
          window.location.reload()
      } else {
        // Handle errors from the server

      }
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  }
};


  useEffect (() => {
    const assignedUSers= users.filter((p)=>{
    return  projects.some((project) => project.assignedUserId === p._id);
    })
    const AssignedProjects = projects.filter((p) => {
        for (var i = 0; i < users.length; i++) {
          return  p.assignedUserId ||'' === users[i]._id
      }

    });
if(assignedUSers){
  setUnAssignedUsers(totalUsers-assignedUSers.length);
}

setUnAssignedProjects(totalProjects-AssignedProjects.length);



  }, [projects,users]);


  const handleClose = () => {
    setAssignUser(false);
  }
  
  const handleUserAll =() => {
    Navigate("/measurit/Users");
  }

  const handleUnAssignedProjects = () => {
    if(UnAssignedProjects===0){
      setallertUnassinedProjects(true)
    }else{
      Navigate("/measurit/UnAssignedProjects")
    }
   
  } 

  const handleUnAssignedUsers = async () => {
  if(UnAssignedUsers==0){
    setallertUnassinedUsers(true);
  }else{
    Navigate("/measurit/UnAssignedUsers")
  }

  }
  const handleCloseUnassignedUserAlert=async()=>{
    setallertUnassinedUsers(false);
  }
  const handleCloseUnassignedProjectAlert= async()=>{
    setallertUnassinedProjects(false);
  }
  const handleClosenoAssigneUser = () =>{
    setnoSelectAssignUseralert(false);
  }


  const handleProjectsAll = () => {
    Navigate("/measurit/Projects");
  }

  const handleViewProjectDetails = async (Project, username, User) => {
    Navigate("/measurit/View", { state: { project: Project, username ,user: User} });
  }
  

  useEffect (() => {
    fetchUsers();
    const fetchedProjectId = projectId; // Replace with your actual project ID or fetch it
    setProjectId(fetchedProjectId);
    fetchProjects();
  }, []);


  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }
 
  const fetchUsers = async () => {
    try{
      const token = sessionStorage.getItem('token');
      const response = await fetch(GetUsers,{
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', // Set the content type to JSON
          'x-token':token
        },
      })
      if(response.ok){
        const usersData = await response.json();
        const reversedData = [...usersData].reverse();
        setUsers(reversedData);
        setDisplayUsersData(reversedData.slice(0,3));

        setEditUser(false);
      }
      else {

      }
    }
    catch (error)
    {
      clearStorageAndRedirectToHome()
    }
  };

 
  const fetchProjects = async () => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(GetProjects, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-token': token
            },
        });

        if (response.ok) {
            const projectsData = await response.json();
            console.log(projectsData);
            setProjects(projectsData);
            
            // Sort projects by timestamp
            const reversedData = projectsData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            // Get the latest 3 projects
            const latest3Projects = sortedProjects.slice(0, 3);

            // Set all projects if needed
            setProjects(sortedProjects);
            setDisplayedProjects(latest3Projects);

            // Create an array of locations from sortedProjects
            const projectLocations = sortedProjects.map(project => ({
                latitude: parseFloat(project.latitude),
                longitude: parseFloat(project.longitude),
                ProjectName:project.projectName,
                BuilderName:project.builderName,
                BuilderContact:project.builderContactNo
            }));
            //console.log(projectLocations);

            // Filter out invalid latitude and longitude values
            const validProjectLocations = projectLocations.filter(location => !isNaN(location.latitude) && !isNaN(location.longitude));

            // Calculate average latitude and longitude
            const averageLatitude = validProjectLocations.reduce((sum, location) => sum + location.latitude, 0) / validProjectLocations.length;
            const averageLongitude = validProjectLocations.reduce((sum, location) => sum + location.longitude, 0) / validProjectLocations.length;

            
            setLocations(validProjectLocations);
            setCoordinates({ latitude: averageLatitude, longitude: averageLongitude });

            //console.log("Average Latitude:", averageLatitude);
            //console.log("Average Longitude:", averageLongitude);
        } else {
            // Handle response not OK
        }
    } catch (error) {
        clearStorageAndRedirectToHome();
    }
};


  const [UserData,setUserData] =useState();
 const  handleUserName= async()=>{
  const storedLoginData = localStorage.getItem('LoginData');
  if (storedLoginData !== null) {
    const storedUserDetails = JSON.parse(storedLoginData);
    

  const User=  users._id === storedUserDetails.userId
  setUserData(User);
    // Now storedUserDetails is an object containing the user details
} else {
    // Handle the case where no data is stored under the key 'UserDetails'
}

 }

 const handleAssignprojecttoUser = async(user) => {
 
     const selectedUsers = users.filter((user) => user.isSelected);
     if (selectedUsers.length === 0) {
       //alert('Please select at least one user.');
       setnoSelectAssignUseralert(true);
       return;
     }
     const userId = selectedUsers.map((user) => user._id);
 
     const data = {
       projectId: selectedproject._id,
       userId: userId
     };

     try {
      const token = sessionStorage.getItem('token');
       const response = await fetch(AssignUserToProject,{
       method: 'PUT',
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json', // Set the content type to JSON
         'x-token':token
       },
 
       body: JSON.stringify(data),
     })

      if (response.ok) {
       setselectedUser(user);
       setUserAssignedtoProjectAlert(true);
       //alert('Users assigned to project successfully');
       
 
     } else {

     }
   } catch (error) {
    clearStorageAndRedirectToHome()
   }
 };
 
 const handleCheckboxChange = (userId) => {
  setProjectUserId(userId)
   setUsers((prevUsers) =>
     prevUsers.map((User) =>
     User._id === userId ? { ...User, isSelected: !User.isSelected } : User
     )
   );
   
 };
 
  const handleAssignUser = async(Project)=>{
   setAssignUser(true);
   setselectedproject(Project);
   setProjectUserId(Project.assignedUserId);
  
  };
 
  const handleUserDetails = (User) => {
    // Navigate to the user details page with the user ID
   Navigate('/measurit/UserDetails', { state: { user: User } });
  };
 
 const [UserAssignedtoProjectAlert, setUserAssignedtoProjectAlert] = useState(false);

 const handleUserAssignedtoProjectAlert = async () => {
  setUserAssignedtoProjectAlert(false);
  window.location.reload();
 }



 const defaultCoordinates = {
  latitude: 16.5062, // Default latitude for Vijayawada
  longitude: 80.6480 // Default longitude for Vijayawada
};

useEffect(() => {
  renderMap();
}, [locations]); // Render map when locations change

// Define a variable to keep track of the currently open info window
let currentInfoWindow = null;

const renderMap = () => {
  let markersAdded = false; // Flag to check if any markers are added
  
  const map = new window.google.maps.Map(document.getElementById('map'), {
    center: { lat: defaultCoordinates.latitude, lng: defaultCoordinates.longitude },
    zoom: 8
  });

  locations.forEach(location => {
    const marker = new window.google.maps.Marker({
      position: { lat: location.latitude, lng: location.longitude },
      map,
      title: location.ProjectName
    });
    markersAdded = true; // Markers are added

// Create info window for each marker
const infoWindow = new window.google.maps.InfoWindow({
  content: `<div><strong>Project:</strong> ${location.ProjectName}<br><strong>Builder:</strong>${location.BuilderName}<br><strong>Contact:</strong>${location.BuilderContact}</div>`
});


    // Add click event listener to marker
    marker.addListener('click', () => {
      // If there is a currently open info window, close it
      if (currentInfoWindow) {
        currentInfoWindow.close();
      }
      
      // If the clicked marker's info window is not already open, open it
      if (currentInfoWindow !== infoWindow) {
        infoWindow.open(map, marker);
        currentInfoWindow = infoWindow;
      } else {
        // If the clicked marker's info window is already open, set currentInfoWindow to null
        currentInfoWindow = null;
      }
    });
  });

  // If no markers are added, center the map on default coordinates
  if (!markersAdded) {
    map.setCenter(new window.google.maps.LatLng(defaultCoordinates.latitude, defaultCoordinates.longitude));
  }
};

const url = process.env.REACT_APP_FORE_APILINK + `/projects/update-project?projectId=${selectedProjectId}`;

const handleSubmit = async () => {
  try {
    const token = sessionStorage.getItem('token');
    
    // Get the rescheduled date for the selected project
    const date = rescheduleDate;
    console.log(date);

    const formData = new FormData();
   // formData.append('projectId', projectIdString); // Include the project ID as a string
    formData.append('rescheduleDate', date); // Include the rescheduled date


    // Make an API call to the backend to update the project
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'x-token': token
      },
      body: formData
    });

    if (response.ok) {
      // Handle success response from the backend
      const projectDetails = await response.json();
      console.log(projectDetails.rescheduleDate);
      setRescheduleDate(projectDetails.rescheduleDate)
      setDatePopup(false)
      fetchProjects()
      // You can update state or perform any other actions as needed
    } else {
      // Handle errors if the API call fails
      // You can display an error message to the user if needed
    }
  } catch (error) {
    // Handle errors if the API call encounters an exception
    clearStorageAndRedirectToHome();
    // You can display an error message to the user if needed
  }
};

const handleCloseDate = () => {
  setDatePopup(false);
 // window.location.reload();
};
const handleOpenDate = (projectId,date) => {
  //console.log(projectId)
  //console.log(date)
  setDatePopup(true);
  setDate(date); 
  setSelectedProjectId(projectId);
};

// Function to handle date selection
const handleDateChange = (e) => {
  const selectedDate = e.target.value;
  setDate(selectedDate);
  // Set only the selected date for the specified project ID
  setRescheduleDate(selectedDate);
};

return(

<div className="overflow-x-scroll no-scrollbar">
{/* Alerts */}

{/* User assigned  */}
{UserAssignedtoProjectAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-bold mb-2">User assigned to project successfully!</p>
     <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleUserAssignedtoProjectAlert}>OK</button>
    </div>
  </div>
)}

{allertUnassinedUsers&&(
       <div className="fixed inset-0 z-10 flex items-center justify-center">
       {/* Background overlay (blurred) */}
       <div className="absolute inset-0 bg-black opacity-30 "></div>

       <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
       <p className="text-lg font-bold mb-2">
           No UnAssigned users!
         </p>
         <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={ handleCloseUnassignedUserAlert}>
           OK
         </button>
       </div>
     </div>
      )}

{allertUnassinedProjects&&(
        <div className="fixed inset-0 z-10 flex items-center justify-center">
        {/* Background overlay (blurred) */}
        <div className="absolute inset-0 bg-black opacity-30 "></div>
 
        <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
        <p className="text-lg font-bold mb-2">
           No UnAssigned Projects!
         </p>
         <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={ handleCloseUnassignedProjectAlert}>
           OK
         </button>
       </div>
     </div>
      )}

{noSelectAssignUseralert &&(
       <div className="fixed inset-0 z-30 flex items-center justify-center">
       {/* Background overlay (blurred) */}
       <div className="absolute inset-0 bg-black opacity-30 "></div>

       <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
       <p className="text-lg font-bold mb-2">
         Please select at least one user!
         </p>
         <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={ handleClosenoAssigneUser}>
           OK
         </button>
       </div>
     </div>
      )}
       <div className="flex-grow flex flex-col relative ">
          {/* Your main content goes here */}
          <div className="flex flex-wrap justify-center mx-2 md:mx-5 lg:mx-16 mt-5">

<div className="relative flex justify-center mx-3 items-center">
  {/* Left arrow */}
  <button onClick={handlePrevCard} className={`ml-4 mt-1 relative flex justify-center items-center ${currentCardIndex <= 0 ? 'pointer-events-none opacity-20' : ''}`}
    disabled={currentCardIndex <= 0}>
    <FontAwesomeIcon icon={faAngleLeft} className="text-black w-[20px] h-[40px]" />
  </button>
</div>

{/* 1st card */}
<div className={`bg-white flex flex-col p-4 rounded-md shadow-md w-full sm:w-44 md:w-48 h-20 mx-2 md:mx-4 ${currentCardIndex >= 0 && currentCardIndex < 2 ? '' : 'hidden'} transition duration-300 ease-out transform`}>
  <h2 className="text-sm font-semibold text-center md:text-center">Total Projects</h2>
  <div className="relative w-10 self-center h-6 mt-2 bg-pink-100 rounded-md">
    <p className="text-center text-black cursor-pointer hover:underline" onClick={handleProjectsAll}>{totalProjects}</p>
  </div>
</div>

{/* 2nd card */}
<div className={`bg-white flex flex-col p-4 rounded-md shadow-md w-full sm:w-44 md:w-48 h-20 mx-2 md:mx-4 ${currentCardIndex >= 0 && currentCardIndex < 2 ? '' : 'hidden'} transition duration-300 ease-out transform`}>
  <h2 className="text-sm font-semibold text-center md:text-center">Total Users</h2>
  <div className="relative self-center w-10 h-6 mt-2 bg-blue-100 rounded-md">
    <p className="text-center text-black cursor-pointer hover:underline" onClick={handleUserAll}>{totalUsers}</p>
  </div>
</div>

{/* 3rd card */}
<div className={`bg-white flex flex-col p-4 rounded-md shadow-md w-full sm:w-44 md:w-48 h-20 mx-auto md:mx-4 ${currentCardIndex >= 2 && currentCardIndex < 4 ? '' : 'hidden'} transition duration-300 ease-out transform`}>
  <h2 className="text-sm font-semibold text-center md:text-center">UnAssigned Projects</h2>
  <div className="relative self-center w-10 h-6 mt-2 bg-green-100 rounded-md">
    <p className="text-center text-black cursor-pointer hover:underline" onClick={handleUnAssignedProjects}>{UnAssignedProjects}</p>
  </div>
</div>

{/* 4th card */}
<div className={`bg-white flex flex-col p-4 rounded-md shadow-md w-full sm:w-44 md:w-48 h-20 mx-auto md:mx-4 ${currentCardIndex >= 2 && currentCardIndex < 4 ? '' : 'hidden'} transition duration-300 ease-out transform`}>
  <h2 className="text-sm font-semibold text-center md:text-center">UnAssigned Users</h2>
  <div className="relative self-center w-10 h-6 mt-2 bg-orange-200 rounded-md">
    <p className="text-center text-black cursor-pointer hover:underline" onClick={handleUnAssignedUsers}>{UnAssignedUsers}</p>
  </div>
</div>

<div className={`relative flex justify-center mx-3 items-center ${currentCardIndex >= 2 ? '' : 'md:block lg:block'} sm:mt-4`}>
  <button onClick={handleNextCard} className={`mt-1 ml-2 mr-4 flex justify-center items-center ${currentCardIndex >= 2 ? 'pointer-events-none opacity-20' : ''}`}
    disabled={currentCardIndex >= 4}>
    <FontAwesomeIcon icon={faAngleRight} className="w-[20px] h-[40px]" style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} />
  </button>
</div>
</div>

<div className="w-3/4 mx-auto mt-2 md:mt-5 bg-white rounded-md shadow-md p-4">
<h1 className="text-md font-semibold mb-6">Locations Of Projects</h1>
  <div className="relative aspect-w-16 aspect-h-9">
    <div id="map" className="w-full h-96 md:h-72 lg:h-96"></div>
  </div>
</div>



    {/* Projects Table */}
    <div className="w-full mx-auto mt-2">
  {/* Wrapper div with white background */}
  <div className="max-w-screen-xl mx-auto mt-5 p-4">
  <div className="bg-white flex flex-col relative rounded-md shadow-md p-2 ml-10 mr-10 mx-2 md:mx-6 overflow-x-auto">
    <div className="flex flex-row justify-between">
      <h1 className="text-md font-semibold">Projects</h1>
    
    </div>
    {displayedProjects.length > 0 ? (
    <table className="w-full border-b border-gray-300 mt-4">
      <thead className="border-b border-gray-300">
        <tr className="bg-[#959BB0] bg-opacity-[15%]"> 
          <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Name</th>
          <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Builder Name</th>
          <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Builder Contact</th>
          <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Total Flats</th> 
          <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Assigned User</th>
           <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Last Visited</th>
          <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Reschedule Date</th> 
        </tr>
      </thead>
      
      {/* Add your table body content here */}
      {/* Add your table body content here */}
<tbody className="border-b border-gray-300">
  {displayedProjects.map((Project, index) => {
    // Parse the timestamp to get only the date portion
    const date = new Date(Project.timestamp);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    return (
      <tr
        key={Project._id}
        className={
          index % 2 === 0 ? 'bg-white border-b border-gray-300' : 'bg-[#F9F4F4] border-b border-gray-300'
        }
      >
        <td
          className="text-blue-950 text-opacity-100 py-2 text-xs px-4 text-center cursor-pointer hover:text-opacity-90 hover:underline"
          onClick={(e) => {
            const assignedUser = users.find((p) => p._id === Project.assignedUserId);
            const userName = assignedUser ? `${assignedUser.firstName} ${assignedUser.lastName || 'UnAssigned'}` : 'UnAssigned';
            handleViewProjectDetails(Project, { name: userName }, users.find((p) => p._id === Project.assignedUserId));
          }}
        >
          {Project.projectName}
        </td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.builderName}</td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.builderContactNo}</td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.totalFlats}</td>
        <td
          className={`text-${Project.assignedUserId ? 'blue-300' : 'red-300'} py-2 text-xs text-center px-4 ${
            Project.assignedUserId ? 'underline cursor-pointer' : 'underline cursor-pointer'
          }`}
          onClick={() => (/* Project.assignedUserId ? handleUserDetails(users.find((p) => p._id === Project.assignedUserId)) : */ handleAssignUser(Project))}
        >
          {Project.assignedUserId ? (
            <a
              // Replace with the actual URL pattern for the user's page
              onClick={(e) => {
                if (!Project.assignedUserId) {
                  e.preventDefault();
                  handleAssignUser(Project);
                }
              }}
            >
              {`${users.find((p) => p._id === Project.assignedUserId)?.firstName} ${users.find((p) => p._id === Project.assignedUserId)?.lastName || ''}`}
            </a>
          ) : (
            'UnAssigned User'
          )}
        </td>
        <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{formattedDate}</td>
        <td
  className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center underline cursor-pointer"
  onClick={() => handleOpenDate(Project._id,Project.rescheduleDate)}
>
  { Project.rescheduleDate  || 'Reschedule Date'}
</td>
      </tr>
    );
  })}
</tbody>

          
    </table>
     ) : (
      <p className="text-center">No projects available</p>
  )}

{DatePopup && (
  <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
    <div className="bg-white p-10 rounded-lg shadow-md relative">
      <div className="flex justify-end cursor-pointer absolute top-2 right-2" onClick={handleCloseDate}>
        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
      </div>
      <div className="flex flex-col items-center">
        <label htmlFor="rescheduleDate" className="text-lg font-semibold mb-2">Reschedule Date</label>
        <input 
          type="date" 
          id="rescheduleDate" 
          className="px-4 py-2 border border-gray-300 rounded-md mb-4 "
          value={date}
          onChange={handleDateChange}
        />
        <button 
          className="bg-[#0F9D58] flex flex-col relative mx-auto mt-2 text-sm text-white bg-opacity-75 rounded-sm px-4 py-1 hover:bg-opacity-90"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  </div>
)}


    <div className="text-blue-600 text-right text-sm underline cursor-pointer mt-4">
  {displayedProjects.length > 0 ? (
    <span onClick={handleProjectsAll}>See All</span>
  ) : (
    <span className="text-gray-400 cursor-not-allowed">See All</span>
  )}
</div>

    </div>
  </div>


        {/* Users Table */}
        <div className="w-full mx-auto mt-2">
  {/* Wrapper div with white background */}
  <div className="w-full mx-auto mt-5">
  {/* Wrapper div with white background */}
  <div className="max-w-screen-xl mx-auto mt-5 p-4">
    <div className="bg-white flex flex-col relative rounded-md shadow-md p-2 ml-10 mr-10 mx-2 md:mx-6 overflow-x-auto">
      <div>
        <h1 className="text-md font-semibold">Users</h1>
      </div>
      {DisplayUsersData.length > 0 ? (
      <table className="md:shrink-0 sm-shrink-0 lg:shrink-0 whitespace-wrap w-full border-b border-gray-300 mt-4 relative">
      <thead className="border-b border-gray-300">
        <tr className="bg-[#959BB0] bg-opacity-[15%]">
        {/* <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Profile</th> */}
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Name</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Role</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Mobile Number</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Email </th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Status</th>
            <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Action</th>
        </tr>
      </thead>
      {/* Add your table body content here */}
      <tbody className="border-b border-gray-300">
      {DisplayUsersData.map ((User, index) => (
        
 <tr key={User._id} className={index % 2 === 0 ? "bg-white border-b border-gray-300" : "bg-[#F9F4F4] border-b border-gray-300"}>
{/*   <td className="text-blue-950 text-opacity-60 py-2 text-sm px-4 flex justify-center text-center">
  {console.log(User)}
  {User.userProfileImage ? (
    <img
    src={ImageUrl + User.userProfileImage}
      alt="User Profile"
      className="w-[15px] md:w-[30px] h-[15px] md:h-[30px] self-center object-cover rounded-full"
    />
  ) : (
    <div className="self-stretch flex w-[15px] md:w-[30px] h-[15px] md:h-[30px] self-center flex-col bg-black bg-opacity-30 rounded-full" >
    <FontAwesomeIcon icon={faUserCircle} className="bg-white w-[15px] md:w-[30px] h-[15px] md:h-[30px]" />
    </div>
  )}
</td> */}
<td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center underline cursor-pointer" onClick={() => handleUserDetails(User)}>{`${User.firstName}  ${User.lastName}`}</td>
     <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.userRole}</td>
     <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.mobileNo}</td>
     <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.email}</td>
     <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center cursor-pointer hover:underline" onClick={() => handleStatus(User)}>
  {User.is_active === true ? 'Active' : 'Deactive'}
</td>
     <td className="text-blue-950 text-opacity-60 py-2 px-4 text-xs text-center cursor-pointer hover:underline" onClick={() => handleEdit(User)} >Edit</td>
   </tr>
      ))}
       
          </tbody>
          
    </table>

    ) : (
      <p className="text-center">No users available</p>
  )}

    <div className="text-blue-600 text-right text-sm underline cursor-pointer mt-4">
  {DisplayUsersData.length > 0 ? (
    <span onClick={handleUserAll}>See All</span>
  ) : (
    <span className="text-gray-400 cursor-not-allowed">See All</span>
  )}
</div>

     </div>
    </div>
   </div>
</div>
       
</div>
      </div>

      {AssignUser && (
  <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
    <div className="bg-white p-2 rounded-lg shadow-md">
      <div className="flex justify-end cursor-pointer" onClick={handleClose}>
        <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-600" />
      </div>
      <div className="w-full mx-auto mt-2">
        {/* Wrapper div with white background */}
        <div className="max-w-screen-xl mx-auto mt-3 p-2">
          <div className="flex flex-col relative rounded-md p-2 ml-10 mr-10">
            <div className="overflow-y-auto max-h-96"> {/* Set a max height and overflow-y */}
              <table className="w-full border-b border-gray-300 mt-2">
                <thead className="border-b border-gray-300">
                  <tr className="bg-[#959BB0] bg-opacity-[15%]">
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">User Name</th>
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Mobile Number</th>
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Role</th>
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Assign User</th>
                  </tr>
                </thead>
                {/* Add your table body content here */}
                <tbody className="border-b border-gray-300">
                  {users.map((User, index) => (
                    <tr key={User._id} className={index % 2 === 0 ? "bg-white border-b border-gray-300" : "bg-[#F9F4F4] border-b border-gray-300"}>
                      <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{`${User.firstName} ${User.lastName}`}</td>
                      <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.mobileNo}</td>
                      <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{User.userRole}</td>
                      <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-500"
                          checked={User._id === ProjectUserId || false}
                          onChange={() => handleCheckboxChange(User._id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <button className=" bg-[#0F9D58] flex flex-col relative mx-auto mt-2 text-sm text-white bg-opacity-75 rounded-sm px-4 py-1 hover:bg-opacity-90" onClick={handleAssignprojecttoUser}>Assign</button>
        </div>
      </div>
    </div>
  </div>
)}



{UserStatus && (
  <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
    <div className="bg-white p-2 rounded-lg shadow-md">
      <div className="flex justify-end cursor-pointer" onClick={() => setUserStatus(false)}>
        <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-600" />
      </div>
      <div className="w-full mx-auto mt-2">
        <div className="max-w-screen-xl mx-auto mt-3 p-2">
          <div className="flex flex-col items-center rounded-md p-2 ml-10 mr-10">
            <p className="text-lg font-bold mb-2">{ProfileData.is_active ? 'Are you sure want to Deactive?' : 'Are you sure want to Active?'}</p>
           <div className="flex justify-between mt-4">
            <button
              className="items-center flex justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
              onClick={handleToggle}
            >
              Yes
            </button>
            <button
                className="items-center flex justify-center text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4 ml-6"
                onClick={() => setUserStatus(false)}
              >
                No
              </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}

{EditUser && (
          <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
           <div className="bg-white w-80 p-4 rounded-lg shadow-md">
               <div className="flex justify-end cursor-pointer" onClick={handleEditClose}>
                   <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-600" />
               </div>
                 {/* Profile Image */}
    <div className="mb-4 flex items-center justify-center">
  {UserImage || profileImage ? (
    <div className="relative">
      <img
         src={UserImage || ImageUrl + profileImage}
        alt="Uploaded Profile"
        className="w-20 h-20 object-cover rounded-full border-4 border-white"
      />
      <label
        htmlFor="imageUpload"
        className="absolute top-14 right-1 text-blue-500 text-sm underline hover:text-blue-900 cursor-pointer"
      >
        {/* Upload Image Icon */}
        <FontAwesomeIcon icon={faCamera} />
        <input
          id="imageUpload"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleImageUpload}
        />
      </label>
    </div>
  ) : (
    <label htmlFor="imageUpload" className="cursor-pointer">
      <span className="hover:text-gray-700 text-gray-500 text-4xl mr-2">
        <FontAwesomeIcon icon={faImage} />

      </span>
      <input
        id="imageUpload"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleImageUpload}
      />
    </label>
  )}
</div>

     {/* Profile Image Ends*/}
               <div>
                   <div className="mb-4 relative">
                       <label className="text-gray-600 text-sm mb-1 block">First Name</label>
                       <input
                           type="text"
                           className={`border w-full rounded-sm ${errors.firstName ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                           value={firstName}
                           onChange={(e) => handleInputChange("firstName", e.target.value)}
                           disabled={editModeIndex === false}
                       />
                       {errors.firstName && (
                           <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.firstName}</p>
                       )}
                   </div>

                   <div className="mb-4 relative">
                       <label className="text-gray-600 text-sm mb-1 block">Last Name</label>
                       <input
                           type="text"
                           className={`border w-full rounded-sm ${errors.lastName ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                           value={lastName}
                           onChange={(e) => handleInputChange("lastName", e.target.value)}
                           disabled={editModeIndex === false}
                       />
                       {errors.lastName && (
                           <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.lastName}</p>
                       )}
                   </div>

                   <div className="mb-4 relative">
                       <label className="text-gray-600 text-sm mb-1 block">User Name</label>
                       <input
                           type="text"
                           name="username"
                           className={`border w-full rounded-sm ${errors.username ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                           value={username}
                           onChange={(e) => handleInputChange("username", e.target.value)}
                           disabled={editModeIndex === false}
                       />
                       {errors.username && (
                           <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.username}</p>
                       )}
                   </div>

                   <div className="mb-4 relative">
                       <label className="text-gray-600 text-sm mb-1 block">Mobile No</label>
                       <input
                           type="tel"
                           pattern="[0-9]{10}"
                           className={`border w-full rounded-sm ${errors.mobileNo ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                           value={mobileNo}
                           onChange={(e) => handleInputChange("mobileNo", e.target.value)}
                           disabled={editModeIndex === false}
                       />
                       {errors.mobileNo && (
                           <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.mobileNo}</p>
                       )}
                   </div>

                   <div className="mb-4 relative">
                       <label className="text-gray-600 text-sm mb-1 block">Email</label>
                       <input
                           type="email"
                           pattern="[a-zA-Z0-9]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                           className={`border w-full rounded-sm ${errors.email ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                           value={email}
                           onChange={(e) => handleInputChange("email", e.target.value)}
                           disabled={editModeIndex === false}
                       />
                       {errors.email && (
                           <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.email}</p>
                       )}
                   </div>
                   
               </div>

              

               <div className="flex justify-center mt-2">
               {editModeIndex === true ? (
            <button
              className="text-white text-opacity-100 text-base whitespace-nowrap bg-[#0f9d58] hover:bg-green-600 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300"
              type="button"
              onClick={() => handleUpdate()}
            >
              Update
            </button>
          ) : (
            <button
              className="text-white text-opacity-100 text-base whitespace-nowrap bg-blue-500 hover:bg-blue-700 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300"
              type="button"
              onClick={() => handleEditClick()}
            >
              Edit
            </button>
          )}
               </div>
           </div>
           </div>
   
 )}
  {/* Popup */}
  {isPopupVisible && (
      <div className="fixed inset-0 z-20 flex items-center justify-center">
      {/* Background overlay (blurred) */}
      <div className="absolute inset-0 bg-black bg-opacity-30 "></div>

      {/* Popup content */}
      <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
        <p className="text-lg font-bold mb-2">Update Successful!</p>
        
      </div>
    </div>
    )}
</div>

)

}

export default AdminHomeContainer;