import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaHome, FaUser, FaProjectDiagram } from 'react-icons/fa';

function TopandBottomMenu({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen">
      {/* Top bar with hamburger menu */}
      <div className='h-[3rem] border-b rounded-md w-full bg-white flex items-center justify-between px-4 fixed top-0 left-0 right-0 z-10'>
  <div className='flex flex-row items-center'>
    <img 
      className="w-8 h-8 cursor-pointer" 
      src={'/BB.png'} 
      alt="Brick Bucket Logo" 
    />
  </div>
  <button className="text-black">
    <FaBars size={24} />
  </button>
</div>


      
      {/* Sidebar menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50">
          <div className="w-64 h-full bg-white p-4">
            <button onClick={() => setIsMenuOpen(false)} className="mb-4">Close</button>
            {/* Add your sidebar menu items here */}
          </div>
        </div>
      )}
      
      {/* Main content area */}
      <div className='flex-1 '>
  {children}
</div>
      
      {/* Bottom navigation */}
      <div className='h-[3rem] w-full bg-white border-t flex justify-around items-center fixed bottom-0 left-0 right-0 z-10'>
        <button  onClick={() => navigate('/bb/consumerapp/home')}  className="text-gray-600 hover:text-blue-600">
          <FaHome size={24} />
        </button>
        <button /* onClick={() => navigate('/bb/consumerapp/profile')} */ className="text-gray-600 hover:text-blue-600">
          <FaUser size={24} />
        </button>
        <button /* onClick={() => navigate('/bb/consumerapp/projects')} */ className="text-gray-600 hover:text-blue-600">
          <FaProjectDiagram size={24} />
        </button>
      </div>
    </div>
  );
}

export default TopandBottomMenu;
