import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSortUp, faSortDown, faEllipsisV, faX, faChevronDown, faPlus, faPlusCircle, faSearch,    } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {Loader, Spinner} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import Select from 'react-select';
import { FaChevronDown } from 'react-icons/fa';
import Search from '../Components/Search';

const AllItems = () => {
  const  [Items , setItems] = useState ([])
  const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate()
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [showOpen, setshowOpen] = useState(false);
  const [showExportItemPopup, setshowExportItemPopup] = useState(false);
  const [showAddNewPopup, setShowAddNewPopup] =useState(false);
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedFormat, setSelectedFormat] = useState('');

  const [selectedItems, setSelectedItems] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const [retryFunction, setRetryFunction] = useState(null);
  const [selectedHeadersForExport, setSelectedHeadersForExport] = useState([]); // State for selected headers
const [setHeaders, setSetHeaders] = useState([]);
const [roleId, setRoleId] = useState(null);
const [filteredItems, setFilteredItems] = useState(Items);
  const [Type , setType ] = useState('');
const [searchdropDown, setSearchDropDown] = useState(false);
  const searchTypes  = ['Item Name',  'Vendors',  'Item Category', 'Item Sub Category', 'Item Group'];
  const ref = useRef(null);

  const dropdownOptions = [
    { value: 'product_name', label: 'Product Name' },
    { value: 'SKU', label: 'SKU' },
    { value: 'HSN_Code', label: 'HSN Code' },
    { value: 'selling_price', label: 'Selling Price' },
    { value: 'cost_price', label: 'Cost Price' },
    { value: 'tax_preference', label: 'Tax Preference' },
    { value: 'specifications', label: 'Specifications' },
    { value: 'GST', label: 'GST' },
    { value: 'IGST', label: 'IGST' },
  ];


  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  
    // Function to get nested value using key
    const getNestedValue = (item, key) => {
      return key.split('.').reduce((value, keyPart) => {
        return value ? value[keyPart] : undefined;
      }, item);
    };
  
    const sortedArray = [...Items].sort((a, b) => {
      const aValue = getNestedValue(a, key) || ''; // Treat undefined as empty string
      const bValue = getNestedValue(b, key) || ''; // Treat undefined as empty string
  
      // Convert to string for comparison and handle case insensitivity
      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();
  
   
  
      if (aString < bString) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aString > bString) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  
    setItems(sortedArray);
  };
  

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };
  
  useEffect(() => {
    fetchRoleId();
    fetchItems();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [Items]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredItems(Items);
      return;
    }
  
    const filtered = Items.filter((item) => {
      const lowercaseQuery  = query.toLowerCase();
      switch (type) {
        case 'Item Name':
          return  item?.product_name?.toLowerCase().includes(lowercaseQuery);
        case 'Vendors':
          return item?.vendor_id?.name?.toLowerCase().includes(lowercaseQuery);
        case 'Item Category':
          return item?.product_category_id?.product_category_name?.toLowerCase().includes(lowercaseQuery);
        case 'Item Sub Category':
          return item?.product_sub_category_id?.product_sub_category_name?.toLowerCase().includes(lowercaseQuery);
          case 'Item Group' :
            return item?.product_group_id?.product_group_name?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });
  
    setFilteredItems(filtered);
  };

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const fetchItems = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `product/get-all-products?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setItems(data.data);
        
        console.log(data.data)
      } else if (data.status === 400) {
        setShowPopup(true);
        setPopupType('400');
        setPopupMessage('Bad Request!');
      } else if (data.status === 401) {
        setShowPopup(true);
        setPopupType('401');
        setPopupMessage('Unauthorized!');
      } else if (data.status === 403) {
        setShowPopup(true);
        setPopupType('403');
        setPopupMessage('Forbidden!');
      } else if (data.status === 404) {
        setShowPopup(true);
        setPopupType('404');
        setPopupMessage('Not Found!');
      } else if (data.status === 500) {
        setShowPopup(true);
        setPopupType('500');
        setPopupMessage('Internal Server Error!');
      } else {
        setShowPopup(true);
        setPopupType('unknown');
        setPopupMessage('Unknown error!');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchItems(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchItems(attempt)); // Set retry function
      }
    }finally {
      setIsLoading(false);
    }
  };

if (isLoading) {
  return <Loader />;
}

const handleCheckboxChange = (item) => {
  if (selectedItems.includes(item)) {
    setSelectedItems(selectedItems.filter((i) => i !== item));
    setSelectAll(false); // Update selectAll state when deselecting a checkbox
  } else {
    setSelectedItems([...selectedItems, item]);
    if (selectedItems.length === Items.length - 1) {
      setSelectAll(true); // Update selectAll state when selecting all checkboxes
    }
  }
};

const handleSelectAll = () => {
  if (selectAll) {
    setSelectedItems([]);
    setSelectAll(false);
  } else {
    setSelectedItems(Items);
    setSelectAll(true);
  }
};

const handleDelete = async (attempt = 1) => {
  const selectedItemIds = selectedItems.map((item) => item._id);
  console.log(selectedItemIds);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "item/delete-items", {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify({ ItemIds: selectedItemIds })
    });

    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      // Update the vendors state to reflect the deleted vendors
      setItems(Items.filter((item) => !selectedItemIds.includes(item._id)));
      setSelectedItems([]);
      setSelectAll(false);
    } else {
      // Handle error cases
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  }catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleDelete(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleDelete(attempt)); // Set retry function
    }
  }
};

const handleCreate = () => {
  navigate("/bb/app/items/newitem");
};
 const handleNavigateItemDetails = (item) =>{
  navigate("/bb/app/items/itemdetails", {state:{ItemData:item}})
 }

 const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};



const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};

const handleOpenToggle = () => {
  setshowOpen(!showOpen);
};

const handleExport = () => {
  // Handle export items
  setshowExportItemPopup(true);
};

const handleImport = () => {
  // Handle import items
  navigate('/bb/app/items/bulkuploaditems')
};

const handleClosePopup = () => {
  setshowExportItemPopup(false);
};

const handleFormatChange = (event) => {
  setSelectedFormat(event.target.value);
};

const handleSaveExport = async () => {
  try {
    const token = sessionStorage.getItem('token');
    // Prepare request payload
    const payload = {
      selectedHeaders: setHeaders.join(','), // Convert array to comma-separated string
      fileType: selectedFormat.toLowerCase(),
    };

    console.log(payload)
    // Make API call to export products data
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}product/export-products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the filename from the Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition');
    const matches = contentDisposition && contentDisposition.match(/filename="?(.+)"?;/);
    const filename = matches && matches[1] ? matches[1] : `products.${payload.fileType}`;

    // Create a URL for the response data and initiate download
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename); // Set the filename for download
    document.body.appendChild(link);
    link.click();
    link.remove(); // Clean up the link element

    // Optional: Revoke the object URL to free up memory
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error exporting products:', error);
    alert('Failed to export products. Please try again.');
  }
};

const Retry = async() => {
  console.log("Retry called");
  setTimeout(async ()=>{
    if(retryFunction){

      await retryFunction ();
    }

    setIsLoading(false)
  },1000)
  
  setShowPopup(false);
};


/* const addRow = () => {
  
  const newRow = {  product_sub_category_name: '', UOM_type: '', product_sub_category_description: '', UOM_id: '' };
  setProductSubCategory([...ProductSubCategory, newRow]);
}; */

const handleDropDownToggle = () => {
  setSearchDropDown(!searchdropDown);
};

const handleClickItem = (item) => {
  setType(item);
  setSearchQuery('');
  setFilteredItems(Items);
  setSearchDropDown(false);
};


const handleChangeSearch = (e) => {
  setSearchQuery(e.target.value);
};
const itemsToDisplay = filteredItems.length > 0 ? filteredItems : Items;


  return (
    <div className=" bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
        <div className="flex w-full flex-row">
          <div className="w-full flex-1">
              <div className="flex flex-col w-full h-full justify-center items-center">
              <div className="w-full h-full flex flex-col justify-between ">
              <div className="w-full h-16 border-b flex items-center">
  <div className="flex w-full justify-between px-4 items-center">
    {selectedItems.length > 0 ? (
      <button onClick={handleDelete}
        className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md ">Delete</button>
    ) : (
      <>
     <div className="space-x-10 flex flex-row">
                    <h1 className="text-lg font-bold">All Items</h1>
                    <Search onSearch={handleSearch} searchTypes={searchTypes} />
                  </div>
                  <div className="flex items-center space-x-2">
          <button onClick={handleCreate} className="bg-[#f7525a] text-white z-20 px-2 py-1 rounded"
            title="" data-tooltip="Create Item"  style={{ '--tooltip-bg': 'black' }}>+ New</button>
          <button onClick={handleOpenToggle} className='bg-gray-100 border border-gray-300 rounded-md px-4 py-1'>
            <FontAwesomeIcon
              icon={faEllipsisV}
              className="cursor-pointer"
            />
          </button>
          {showOpen && (
            <div className="absolute z-20 right-4 mt-28 w-32 bg-white border border-gray-300 rounded-md shadow-lg">
              <button onClick={handleImport} className="block w-full text-left px-2 py-1 text-gray-700 hover:bg-gray-100">Import Items</button>
              <button onClick={handleExport} className="block w-full text-left px-2 py-1 text-gray-700 hover:bg-gray-100">Export Items</button>
            </div>
          )}
        </div>
                </>
              )}
            </div>
          </div>
                 <div className="w-full h-full bg-white rounded-xs overflow-hidden ">
      <div className="overflow-y-auto h-[80vh] ">
      {itemsToDisplay.length > 0 ? (
        <table className="w-full mb-5 ">
          <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b ">
            <tr className = 'border-b'>
              <th className="text-xs py-3 text-center ">
              <input
        type="checkbox"
        className="mr-2"
        onChange={handleSelectAll}
        checked={selectAll}
      />
              </th>
              <th className="text-xs py-3 text-center">
              ITEM IMAGE 
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_name')}>
              ITEM NAME {getSortIcon('product_name') && <FontAwesomeIcon icon={getSortIcon('product_name')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" >
              PURCHASE RATE 
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" >
              RATE 
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_category_id.product_category_name')}>
                ITEM CATEGORY {getSortIcon('product_category_id.product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_id.product_category_name')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('vendor_id.name')}>
                VENDOR  {getSortIcon('vendor_id.name') && <FontAwesomeIcon icon={getSortIcon('vendor_id.name')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_sub_category_id.product_sub_category_name')}>
                ITEM SUB CATEGORY{getSortIcon('product_sub_category_id.product_sub_category_name') && <FontAwesomeIcon icon={getSortIcon('product_sub_category_id.product_sub_category_name')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_group_id.product_group_name')}>
                ITEM GROUP{getSortIcon('product_group_id.product_group_name') && <FontAwesomeIcon icon={getSortIcon('product_group_id.product_group_name')} />}
              </th>
            </tr>
          </thead>
          <tbody className='border-b border-t'>
          {filteredItems.length > 0 ? (
    filteredItems.map((item) => (
    <tr key={item._id} className="text-sm border-b">
      <td className="py-2 text-center">
      <input
                               type="checkbox"
                               className="mr-2"
                               onChange={(e) => handleCheckboxChange(item)}
                               checked={selectedItems.includes(item) || selectAll}
                             />
      </td>
      <td className="py-2 text-center">
        {item?.product_image && (
          <div className="flex justify-center items-center">
            <img
              src={imageUrl + item.product_image}
              alt={item.product_name}
              className="w-16 h-16 object-cover"
            />
          </div>
        )}
      </td>
      <td
        className="py-2 text-center text-[#408dfb] font-500 cursor-pointer"
        onClick={() => handleNavigateItemDetails(item)}
      >
        {item.product_name}
      </td>
      <td className="py-2 text-center">
      ₹{item.cost_price?.toLocaleString()}
      </td>
      <td className="py-2 text-center">
      ₹{item.selling_price?.toLocaleString()}
      </td>
      <td className="py-2 text-center">
        {item.product_category_id?.product_category_name}
      </td>
      <td className="py-2 text-center">{item.vendor_id?.name}</td>{console.log(item)}
      <td className="py-2 text-center">
        {item.product_sub_category_id?.product_sub_category_name}
      </td>
      <td className="py-2 text-center">{item.product_group_id?.product_group_name}</td>
    </tr>
  ))
) : (
  <tr>
  <td colSpan="10" className="text-center text-[#6c7184] py-4">
    Not found
  </td>
</tr>
)}
</tbody>

        </table>
      ):(
        <>
        <div className="flex justify-center items-center mb-4">
            <h1 className="text-lg">No Items Available</h1>
        </div>
        <div className="flex justify-center items-center mb-4">
            <h1 className="text-xl font-bold">Create  New Item </h1>
        </div>
        <div className="flex justify-center items-center">
            <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
        </div>
    </>
      )}
      </div>
    </div>
              </div>
            </div>
          </div>
      {/*   </div> */}
      </div>

      {showExportItemPopup && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col ">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md">Export</h2>
        <button  onClick={handleClosePopup}  className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      {errorMessages.length > 0 && (
        <div className='p-1'>
                <div className="mt-1 flex justify-between items-center  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {/* {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))} */}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                   // onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
                </div>
              )}

<div className="px-6 py-4">
  <div className='w-full flex flex-col items-start  space-x-2 px-6'>
  <label htmlFor="exportfile" className="block text-sm font-regular text-gray-700 ">
  Fields in Export File
  
        </label>
        <Select
  isMulti
  value={selectedHeadersForExport}
  onChange={(selectedOptions) => {
    setSelectedHeadersForExport(selectedOptions);
    const headers = selectedOptions.map((option) => option.value);
    setSetHeaders(headers);
  }}
  options={dropdownOptions}
  className="w-full"
/>
    

        </div>
<div className="w-full flex flex-col items-start  space-x-2 px-6 mt-2">
<label htmlFor="exportas" className="block text-sm font-regular text-[#e54643] w-1/6">
          Export As<span className="text-red-500">*</span>
        </label>
              <div className="flex flex-col space-y-2 mt-2">
                <label className="flex items-center">
                  <input type="radio" value="CSV" checked={selectedFormat === 'CSV'} onChange={handleFormatChange} className="form-radio" />
                  <span className="ml-2">CSV (Comma Separated Value)</span>
                </label>
                <label className="flex items-center">
                  <input type="radio" value="XLSX" checked={selectedFormat === 'XLSX'} onChange={handleFormatChange} className="form-radio" />
                  <span className="ml-2">XLSX (Microsoft Excel)</span>
                </label>
              </div>
              </div>
              </div>
     
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
            onClick={handleSaveExport}  >
            Export {/* {isSaving && <Spinner />} */}
            </button>
          <button  onClick={handleClosePopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
      </div>
    </div>
  </div>
)}

      {showPopup && (
        <div>
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
 </div>
    
  );
};

export default AllItems;