import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Reports() {
  const Navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [showTable, setShowTable] = useState(true); // State to control table visibility

  const GetProjects = process.env.REACT_APP_FORE_APILINK + '/projects/get-all-projects';

  useEffect(() => {
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData')) || null;
    const Userdata = JSON.parse(sessionStorage.getItem('userData')) || null;
    if (storedLoginData && Userdata) {
      if (storedLoginData.token === Userdata.token) {
        //console.log('profile matched')
      } else {
        //console.log('logout')
        Navigate('/measurit');
        sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
      }
    } else {
      Navigate('/measurit');
      sessionStorage.removeItem('userRole');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userData');
    }
  }, [Navigate]);

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleAggreateReportClick = async () => {
    const projectIds = projects.map(project => project._id);
    const projectsNames = projects.map(project => project.projectName);
    console.log(projectsNames);
    Navigate("/measurit/AggregatedProjectReports", { state: { projectIds, projectsNames } });
  }

  const handleViewProjectSummary = async (Project) => {
    console.log(Project);
    Navigate("/measurit/ProjectReports", { state: { project: Project } });
  }

  const fetchProjects = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(GetProjects, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-token': token
        },
      });

      if (response.ok) {
        const projectsData = await response.json();
        setProjects(projectsData);

        const reversedData = projectsData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setProjects(sortedProjects); // Set all projects if needed
        setDisplayedProjects(sortedProjects);
        console.log(sortedProjects);
        if (sortedProjects<1){
          setShowTable(false);
        }
      } else {
        setShowTable(false); // Hide table if response is not ok
        console.log('Failed to fetch projects', response.statusText);
      }
    } catch (error) {
      clearStorageAndRedirectToHome();
      console.log('Failed to fetch projects', error);
    }
  };

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }

  return (
    <div className='overflow-x-scroll no-scrollbar'>
      {/* Conditional rendering based on showTable */}
      {showTable ? (
        <div className="w-full mx-auto mt-2">
          <div className="max-w-screen-xl mx-auto mt-5 p-4">
            <div className="bg-white flex flex-col relative rounded-md shadow-md p-2 ml-10 mr-10 mx-2 md:mx-6 overflow-x-auto">
              <div>
                <h1 className="text-md font-semibold flex justify-center">Projects Wise</h1>
              </div>
              <table className="w-full border-b border-gray-300 mt-4">
                <thead className="border-b border-gray-300">
                  <tr className="bg-[#959BB0] bg-opacity-[15%]">
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Name</th>
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Builder Name</th>
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Builder Contact</th>
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Status</th>
                    <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="border-b border-gray-300">
                  {displayedProjects.map((Project, index) => (
                    <tr
                      key={Project._id}
                      className={index % 2 === 0 ? 'bg-white border-b border-gray-300' : 'bg-[#F9F4F4] border-b border-gray-300'}
                    >
                      <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.projectName}</td>
                      <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.builderName}</td>
                      <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.builderContactNo}</td>
                      <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.is_active ? 'Active' : 'Deactive'}</td>
                      <td
                        className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center cursor-pointer hover:text-opacity-90 hover:underline"
                        onClick={(e) => {
                          handleViewProjectSummary(Project);
                        }}
                      >
                        View Report
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                className="text-white mt-6 mb-6 text-opacity-100 text-base whitespace-nowrap bg-[#0f9d58] hover:bg-green-600 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300 mx-auto"
                type="button"
                onClick={handleAggreateReportClick}
              >
                Aggregate Report
              </button>
            </div>
          </div>
        </div>
      ) : (
        
/*         <div className="text-center border mx-auto my-4 p-4">
        <p>No reports available</p>
    </div> */
    <div className="flex justify-center items-center  border mx-auto my-4 p-4"><div className="w-full text-center" >No reports available.</div></div>
      )}
    </div>
  );
}

export default Reports;
