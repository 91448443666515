import React,{ useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

 
function UserHomeContainer() { 
    const Navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [displayedProjects, setDisplayedProjects] = useState([]);
    const [rescheduleDate, setRescheduleDate] = useState({});
    const [DatePopup, setDatePopup] = useState(false);
    const [date,setDate] = useState('');
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [UserId, setUserId] = useState('');
  
    const [userName,setUserName] = useState('')
    console.log(userName)

    const [roleId,setRoleId] = useState('')
    console.log(roleId);
    // Retrieve user data from session storage

  
    // Initialize user data state with the parsed user data
    
 
   
    useEffect(()=>{
      const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
      const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
      console.log(Userdata)
      if(storedLoginData&&Userdata){
        if(storedLoginData.token===Userdata.token){
        //console.log('profile matched')
        }else{
          //console.log('logout')
         Navigate('/measurit')
         sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
        }
      }else{
        Navigate('/measurit')
          sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
      }
    })
  

    useEffect (() => {
      const userData = JSON.parse(localStorage.getItem('LoginData'));
     console.log(userData);
      setUserName(userData.username)
      setRoleId(userData.roleID);
        const fetchedProjectId = projectId; 
        setProjectId(fetchedProjectId);
        setUserId(userData.userId);
      
       fetchProjects(userData.userId);
      }, []);
    
     
     
      
    
     
      const fetchProjects = async (assignedUserId) => {
        const url2 = `${process.env.REACT_APP_FORE_APILINK}/projects/get-user-projects?assignedUserId=${assignedUserId}`;
  
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(url2, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-token':token
            },
          });
      
          if (response.ok) {
            const projectsData = await response.json();
            setProjects(projectsData);
            const reversedData = projectsData.projectDetails.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

           const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setProjects(sortedProjects); // Set all projects if needed
          setDisplayedProjects(sortedProjects);
          } else {
          }
        } catch (error) {
          clearStorageAndRedirectToHome()
        }
      };
      
      function clearStorageAndRedirectToHome() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/measurit';
      }
    
    
    
      const handleViewProjectDetails = async (Project) => {
        if (Project && Project._id) {
          Navigate("/measurit/UserViewProject", { state: { projectId: Project._id, userName: userName ,roleId:roleId} });
        } else {
        }
      }

      const url = process.env.REACT_APP_FORE_APILINK + `/projects/update-project?projectId=${selectedProjectId}`;

      const handleSubmit = async () => {
        try {
          const token = sessionStorage.getItem('token');
          
          // Get the rescheduled date for the selected project
          const date = rescheduleDate;
          console.log(date);
      
          const formData = new FormData();
         // formData.append('projectId', projectIdString); // Include the project ID as a string
          formData.append('rescheduleDate', date); // Include the rescheduled date
      
      
          // Make an API call to the backend to update the project
          const response = await fetch(url, {
            method: 'PUT',
            headers: {
              'x-token': token
            },
            body: formData
          });
      
          if (response.ok) {
            // Handle success response from the backend
            const projectDetails = await response.json();
            console.log(projectDetails.rescheduleDate);
            setRescheduleDate(projectDetails.rescheduleDate)
            setDatePopup(false)
            fetchProjects(UserId)
            // You can update state or perform any other actions as needed
          } else {
            // Handle errors if the API call fails
            // You can display an error message to the user if needed
          }
        } catch (error) {
          // Handle errors if the API call encounters an exception
          clearStorageAndRedirectToHome();
          // You can display an error message to the user if needed
        }
      };

      const handleCloseDate = () => {
        setDatePopup(false);
       // window.location.reload();
      };
      const handleOpenDate = (projectId,date) => {
        //console.log(projectId)
        //console.log(date)
        setDatePopup(true);
        setDate(date); 
        setSelectedProjectId(projectId);
      };
      
      // Function to handle date selection
      const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        setDate(selectedDate);
        // Set only the selected date for the specified project ID
        setRescheduleDate(selectedDate);
      };
     

return ( 
 <div>
 {/* Actual Content */}
 <div className="flex-grow flex flex-col relative ">
 {/* Your main content goes here */}

{/* Projects Table */}
<div className="w-full mx-auto mt-2">
{/* Wrapper div with white background */}
<div className="max-w-screen-xl mx-auto mt-5 p-4">
<div className="bg-white flex flex-col relative rounded-md shadow-md p-2 ml-10 mr-10">
<div>
<h1 className="text-md font-semibold">Projects</h1>
</div>
{displayedProjects.length > 0 ? (
<table className="w-full border-b border-gray-300 mt-4">
<thead className="border-b border-gray-300">
<tr className="bg-[#959BB0] bg-opacity-[15%]">
 <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Name</th>
 <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Builder Name</th>
 <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Builder Contact</th>
 <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Total Flats</th>
 <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Last Visited</th>
 <th className="text-blue-950 text-opacity-90 py-2 px-4 text-sm text-center">Reschedule Date</th>
</tr>
</thead>
{/* Add your table body content here */}

<tbody className="border-b border-gray-300">
{displayedProjects.map((Project, index) => {
    // Parse the timestamp to get only the date portion
    const date = new Date(Project.timestamp);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    return (
<tr key={Project._id} className={index % 2 === 0 ? "bg-white border-b border-gray-300" : "bg-[#F9F4F4] border-b border-gray-300"}>
   <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center cursor-pointer hover:text-opacity-90 hover:underline" onClick={(e) => {handleViewProjectDetails(Project )}}>{Project.projectName}</td>
   <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.builderName}</td>
   <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.builderContactNo}</td>
   <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{Project.totalFlats}</td>
   <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">{formattedDate}</td>
   <td
  className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center underline cursor-pointer"
  onClick={() => handleOpenDate(Project._id,Project.rescheduleDate)}
>
  { Project.rescheduleDate  || 'Reschedule Date'}
</td>
 </tr>
    );
})}

 </tbody> 
 
</table>
) : (
  <p className="text-center">No projects available</p>
)}

{DatePopup && (
  <div className="fixed inset-0 flex justify-center items-center z-20 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
    <div className="bg-white p-10 rounded-lg shadow-md relative">
      <div className="flex justify-end cursor-pointer absolute top-2 right-2" onClick={handleCloseDate}>
        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
      </div>
      <div className="flex flex-col items-center">
        <label htmlFor="rescheduleDate" className="text-lg font-semibold mb-2">Reschedule Date</label>
        <input 
          type="date" 
          id="rescheduleDate" 
          className="px-4 py-2 border border-gray-300 rounded-md mb-4 "
          value={date}
          onChange={handleDateChange}
        />
        <button 
          className="bg-[#0F9D58] flex flex-col relative mx-auto mt-2 text-sm text-white bg-opacity-75 rounded-sm px-4 py-1 hover:bg-opacity-90"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  </div>
)}

</div>
</div>

</div>
</div>
</div>
)
}

export default UserHomeContainer;