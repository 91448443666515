import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faPlus, faTrash, faCaretDown, faX, faPlusCircle, faChevronDown, faFileImport, faInfoCircle, faArrowRight, faArrowLeft, faCheck, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import '../Css/Animation.css';
import BulkUploadStep2 from './BulkUploadStep2';
import BulkUploadStep3 from './BulkUploadStep3';
import { read, utils } from 'xlsx';
import { useNavigate } from 'react-router-dom';


const BulkUploadItems = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [excelData, setExcelData] = useState({}); // Add this line
    console.log(excelData)
    const [savedCount,setSavedCount] = useState();
    const [notSavedCount,setNotSavedCount] = useState();
    const [updatedCount,setUpdatedCount] = useState();
    const [SavedProducts,setSavedProducts] = useState();
    const [UpdatedProducts,setUpdatedProducts] = useState();
    const [NotSavedProducts,setNotSavedProducts] = useState();
    const navigate = useNavigate();
  const [stepsData, setStepsData] = useState({
    step1: {
      selectedFile: null,
    },
    step2: {
      // Add any data you want to store for step 2
    },
  });

  const [showOverwriteOptions, setShowOverwriteOptions] = useState(false);
  const [duplicateOption, setDuplicateOption] = useState();
  const OverwriteOptions = [
    { label: 'ItemName', value: 'ItemName' },
  { label: 'ItemID', value: 'ItemID' },
  { label: 'SKU', value: 'SKU' }
  ];
  const [selectedItem, setselectedItem] = useState();
  const [completedSteps, setCompletedSteps] = useState([]);

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file) {
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
          // Handle Excel files
          const reader = new FileReader();
          reader.onload = (event) => {
            const workbook = read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = utils.sheet_to_json(worksheet);
            setExcelData(data); // Set the Excel data as a JSON object
            setSelectedFile(file);
          };
          reader.readAsBinaryString(file);
        } else {
          // Handle other file types
          const reader = new FileReader();
          reader.onloadend = () => {
            const filePreview = reader.result;
            console.log(filePreview);
          };
          reader.readAsDataURL(file);
        }
      }
    } else {
      console.log("No file selected or an error occurred");
    }
  };

const handleButtonClick = () => {
    document.getElementById('fileInput').click();
};

const handleRemoveFile = () => {
    setSelectedFile(null);
    document.getElementById('fileInput').value = null; // Reset the file input value
};

// Determine if steps should be enabled based on the current step
const isStepEnabled = (step) => {
    return currentStep === step || completedSteps.includes(step);
  };

  const handlePreviousClick = (targetStep) => {
    if (targetStep === 1) {
      setCurrentStep(1);
      setCompletedSteps(completedSteps.filter(step => step !== 1));
    } else if (targetStep === 2) {
      setCurrentStep(2);
      setCompletedSteps(completedSteps.filter(step => step !== 2));
    }
  };


  const handleNextClick = () => {
    if (currentStep === 1) {
      setCompletedSteps([...completedSteps, 1]);
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setCompletedSteps([...completedSteps, 2]);
      setCurrentStep(3);
    }
  };

const handleNext3 = (saved,notSaved,updated,savedProducts,updatedProducts,notSavedProducts) => {
    if (currentStep === 2 ) {
        setCompletedSteps([...completedSteps, 2]);
        setCurrentStep(3);
        console.log(saved,notSaved,updated)
        setSavedCount(saved);
        setNotSavedCount(notSaved);
        setUpdatedCount(updated);
        setSavedProducts(savedProducts)
        setUpdatedProducts(updatedProducts)
        setNotSavedProducts(notSavedProducts)
    }
}


const handleDuplicateOptionChange = (event) => {
    if (!event || !event.target) {
        console.error('Event or event.target is undefined');
        return;
    }

    const selectedOption = event.target.value;
    setDuplicateOption(selectedOption);
    //setselectedItem(selectedOption);

    if (selectedOption === 'overwriteItems') {
        setShowOverwriteOptions(true);
    } else {
        setShowOverwriteOptions(false);
    }
};

const handleOverwriteItemDropdownChange = (selectedOption) => {
    setselectedItem(selectedOption);
};

const handleDone = () => {
navigate('/bb/app/items/itemslist')
};

    return (
        <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
            <div className="flex flex-row w-full">
                <div className="w-full flex-1">
                    <div className="flex flex-col w-full h-full justify-center items-center">
                        <div className="w-full h-full flex flex-col items-center justify-start">
                            <div className="w-full h-24 border-b flex flex-col items-center mb-6">
                                <div className="flex w-full justify-between px-4 items-center mt-3">
                                    <h1 className="text-lg font-semibold flex-1 text-center">Items - Select File</h1>
                                    <button onClick={handleDone} title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}
                                    className="text-white px-1 py-1 rounded-md">
                                        <FontAwesomeIcon icon={faX} className="text-sm text-[#F7525A] cursor-pointer" />
                                    </button>
                                </div>
                                <div className="flex justify-center w-full mt-3 mb-2 px-4">
                                    <div className="flex items-center w-full justify-center">
                                        <div className="flex items-center justify-between space-x-2">
                                            <div className="flex items-center space-x-2">
                                            <div className={`flex justify-center items-center w-6 h-6 rounded-full ${isStepEnabled(1) ? 'text-white bg-[#408DFB]' : 'bg-gray-300'} ${completedSteps.includes(1) ? 'bg-green-500' : ''}`}>
                {completedSteps.includes(1) ? <FontAwesomeIcon icon={faCheck} className='text-white' /> : 1}
              </div>
                                                <span>Configure</span>
                                            </div>
                                            <div className='flex items-center'>
                                                <hr className=" w-[30px] border-gray-200 mx-8" />
                                            </div>
                                        </div>
                                        <div className={`flex items-center ${isStepEnabled(2) ? '' : 'text-gray-400 cursor-not-allowed'}`}>
                                            <div className="flex items-center space-x-2">
                                            <div className={`flex justify-center items-center w-6 h-6 rounded-full ${isStepEnabled(2) ? 'text-white bg-[#408DFB]' : 'bg-gray-300'} ${completedSteps.includes(2) ? 'bg-green-500' : ''}`}>
                {completedSteps.includes(2) ? <FontAwesomeIcon icon={faCheck} className="text-white" /> : 2}
              </div>
                                                <span className={`flex-shrink-0 ${isStepEnabled(2) ? '' : 'text-gray-400'}`}>Map Fields</span>
                                            </div>
                                            <div className='flex items-center'>
                                                <hr className={` w-[30px] border-gray-200 mx-8 ${isStepEnabled(2) ? '' : 'border-gray-300'}`} />
                                            </div>
                                        </div>
                                        <div className={`flex items-center ${isStepEnabled(3) ? '' : 'text-gray-400 cursor-not-allowed'}`}>
                                            <div className="flex items-center space-x-2">
                                                <div className={`flex justify-center items-center w-6 h-6 rounded-full ${isStepEnabled(3) ? 'text-white bg-[#408DFB]' : 'bg-gray-300'}`}>
                                                    3
                                                </div>
                                                <span className={`${isStepEnabled(3) ? '' : 'text-gray-400'}`}>Preview</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                            {currentStep === 1 && (
                                <div className='flex flex-col w-full justify-center items-center'>
                            <div className="flex flex-col justify-center items-center border rounded-md border-dashed border-gray-300 hover:border-blue-500 w-1/2 h-1/2 bg-[#F9F9FB]">
                                <span className="flex items-center">
                                    <FontAwesomeIcon icon={faFileImport} className="text-3xl text-[#408DFB] mb-4 mt-2" />
                                </span>
                                {selectedFile ? (
                                    <span className="text-sm text-gray-600 ml-2">{selectedFile.name}</span>
                                ) : (
                                    <span className="text-sm text-gray-600 ml-2">Drag and drop files to import</span>
                                )}
                                {selectedFile && (
                                    <button className="text-sm mt-4 py-2 px-4 text-black" 
                                         onClick={handleRemoveFile} 
                                    >
                                        <FontAwesomeIcon icon={faTrash} className='text-[#F7525A]' />  Remove 
                                    </button>
                                )}
                                <div className={selectedFile ? "w-full border-t border-gray-200 pt-4" : ""}></div>
                                
                                <input type="file" id="fileInput" className="hidden" onChange={handleFileUpload} />
                                <label htmlFor="fileInput">
                                    {selectedFile ? (
                                        <button className="py-1 px-2 rounded-md bg-[#F7525A] text-white" 
                                            onClick={handleButtonClick}
                                        > 
                                            Replace File 
                                        </button>
                                    ) : (
                                        <button className="mt-4 py-1 px-2 rounded-md bg-[#F7525A] text-white" 
                                            onClick={handleButtonClick}
                                        > 
                                            Upload File 
                                        </button>
                                    )}
                                </label>
                                <span className="text-xs text-gray-400 mt-3 mb-2">Maximum File Size: 25 MB •  File Format: CSV or TSV or XLS</span>
                            </div>
                            <div className="flex flex-row justify-start items-start rounded-md w-1/2 h-auto mt-6 p-2">
  <h2 className="font-regular text-sm text-[#e54643] mr-6 whitespace-nowrap">
    Duplicate Headings:<span className="text-red-500">*</span>
  </h2>
  <div className="flex flex-col items-start space-y-4">
    <div className="flex flex-col items-start">
      <label className="flex items-center">
        <input type="radio" name="duplicateOption" value="skipDuplicates" className="mr-2"  onChange={handleDuplicateOptionChange}/>
        <span className="text-sm font-regular text-gray-700">Skip Duplicates</span>
      </label>
      <span className="text-xs text-gray-500 mt-1">
        Retains the items and does not import the duplicates in the import file.
      </span>
    </div>
    <div className="flex flex-col items-start">
      <label className="flex items-center">
        <input type="radio" name="duplicateOption" value="overwriteItems" className="mr-2"  onChange={handleDuplicateOptionChange}/>
        <span className="text-sm font-regular text-gray-700">Overwrite Items</span>
      </label>
      <span className="text-xs text-gray-500 mt-1">
        Imports the duplicates in the import file and overwrites the existing items.
      </span>
    </div>
  </div>
</div>


                            <div className="flex flex-col justify-start items-start rounded-md w-1/2 h-1/2 bg-[#F9F9FB] mt-6 p-6">
                            <h2 className="font-semibold"> <FontAwesomeIcon 
                         className="text-yellow-500 transform rotate-45"  icon={faLightbulb} /> Page Tips</h2> <ul>
                                <li className='text-sm'>• Import data with the details of GST Treatment by referring these accepted formats.</li>
                                <li  className='text-sm '>• If you have files in other formats, you can convert it to an accepted file format using any online/offline converter.</li>
                                <li  className='text-sm '>• You can configure your import settings and save them for future too!</li>
                            </ul>
                        </div>
                        
                         <hr className="border-t border-gray-200 w-full mt-5" style={{ borderTopWidth: '1px' }} />
                         <div className="w-1/2">
                             <div className="flex justify-between items-center space-x-2 px-6 mt-5 mb-4">
                                 <button  className={`px-2 py-1 rounded-md border ${selectedFile ? 'bg-[#F7525A] border-[#F7525A] text-white' : 'bg-gray-400 border-gray-400 text-white cursor-not-allowed'}`}
                                    disabled={!selectedFile}
                                      onClick={handleNextClick} 
                                 >
                                     Next
                                     <FontAwesomeIcon icon={faArrowRight} className="ml-2 text-sm" />
                                 </button>
                                 <button className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md" onClick={handleDone}>Cancel</button>
                             </div>
                         </div>
                         </div>
                               )}

{currentStep === 2 && (
    <div className='flex flex-col w-full'>
        <div className='flex flex-row items-center justify-center h-88 overflow-y-auto'>
                                <BulkUploadStep2
                                    excelData={excelData}
                                    onPreviousClick={handlePreviousClick} // Pass the callback function
                                    onPreviousClick2={handlePreviousClick}
                                    onNextClick={handleNext3}
                                    showOverwriteOptions={showOverwriteOptions}
                                    OverwriteItemsOptions={OverwriteOptions}
                                    selectedItem={selectedItem}
                                    selectedFile={selectedFile}
                                    handleOverwriteItemDropdownChange={handleOverwriteItemDropdownChange}
                                />
                               
                                </div>
                            </div>
                            )}
                    {currentStep === 3 && (
                        <div className='flex flex-col w-full'>
                             <div className='flex flex-row items-center justify-center overflow-y-auto'>
                            <BulkUploadStep3
                            notSavedCount={notSavedCount}
                            updatedCount={updatedCount}
                            savedCount={savedCount}
                            SavedProducts={SavedProducts}
                            UpdatedProducts={UpdatedProducts}
                            NotSavedProducts={NotSavedProducts}
                            />
                            </div>
                            <div className='mt-10 flex flex-col justify-center items-center w-full'>
                                <hr className="border-t border-gray-200 w-full mt-5" style={{ borderTopWidth: '1px' }} />
                                 <div className="w-1/2 ">
                                 <div className="flex justify-between items-center space-x-2 px-6 mt-5 mb-4">
                              {/* New content for step 2 */}
                              <div className="flex justify-center items-center space-x-2 px-6 mt-5 mb-4">
                              <button  className="px-2 py-1 bg-[#f5f5f5] border border-[#DDDDDD] text-gray-700 rounded-md"
                                onClick={() => handlePreviousClick(2)}><FontAwesomeIcon icon={faArrowLeft} className="ml-2 text-sm" /> Previous</button>

                              <button  className="px-2 py-1 bg-[#F7525A] border-[#F7525A] text-white rounded-md" onClick={handleDone}>Done 
                              
                              </button>

                              </div>
                              <div className="flex justify-center items-center space-x-2 px-6 mt-5 mb-4">
                              <button  className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md" onClick={handleDone}>Cancel</button>
                            </div>
                              </div>
                              
                            </div>
                            </div>
                            </div>
                    )}

       
        </div>
        </div>
      
</div>
</div>
</div>
</div>

                            
                           
              
                   
          
    );
};

export default BulkUploadItems;
