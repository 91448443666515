import React, { useState, useEffect } from "react";
import { useLocation} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import Rooms from './rooms'; // Correct the import statement
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

function MeasurementsContainer() {
  const Navigate = useNavigate();
  const location = useLocation();
  const flatId = location.state.flatId;
  const projectId = location.state.projectId;
  const blockId = location.state.blockId;
  //console.log(flatId,projectId,blockId)
  
const [RoomData,setRoomData]=useState();

useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    //console.log('profile matched')
    }else{
      //console.log('logout')
     Navigate('/measurit')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    Navigate('/measurit')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})

  useEffect(() => {

    const roomsData = JSON.parse(localStorage.getItem('RoomsData'))||null;
    const token = sessionStorage.getItem('token');
    const endpoint = `${process.env.REACT_APP_FORE_APILINK}/measurements/get-all-rooms?flatId=${flatId}`;
    fetch(endpoint, {
      method: 'GET',
      headers: {
     
        'x-token':token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        // Filter rooms with display 'yes'
        const RoomData = data.filter((p) => p.display === 'yes');
          
          if(roomsData){
          //console.log(roomsData);
          setRoomData(roomsData);
        } else {
          // If not processing or pending, update localStorage with the current RoomData
          localStorage.setItem('RoomsData', JSON.stringify(RoomData));
          setRoomData(RoomData);
        }
      })
      .catch((error) => {
        clearStorageAndRedirectToHome()
        //console.error('Fetch error:', error.message);
      });
  }, [flatId]);

  const handleBack = () => { 
    Navigate(-1); 
};

function clearStorageAndRedirectToHome() {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = '/measurit';
}

  return (
    <div className="overflow-x-scroll no-scrollbar">
      <div className="flex justify-start items-center mr-2 ml-2 mt-1">
<button onClick={handleBack} className="relative flex items-center text-md text-black rounded-md px-5 py-1 mt-1">
<FontAwesomeIcon icon={faAngleLeft} className="text-lg cursor-pointer text-black mr-2" /> Back
</button>
</div>
      {RoomData&&flatId&&(
 <Rooms flatId={flatId} RoomData={RoomData} />
      )}
     
    </div>
  );
}

export default MeasurementsContainer;
