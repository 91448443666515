import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faExclamationTriangle ,faTrash, faX, faPlusCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import '../Css/Animation.css';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { validate } from '../CommenResponseAndErrorHandlers/commenUiErrorHandlers';
//import Dropdown from './DropDown'
// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
          callback();
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => {
          document.removeEventListener('click', handleClick);
      };
  }, []);
};
 
const CustomDropdown = ({ options, selected, onSelect,showAddNew }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null);
  
    const filteredOptions = options.filter(option => {
      if (!option || !option.label) return false;
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  
    const handleOptionClick = (value, label, id) => {
      onSelect(value, label, id);
      setIsOpen(false);
      setFocusedOptionIndex(null);
    };
  
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'Enter':
          e.preventDefault();
          if (focusedOptionIndex !== null) {
            const selectedOption = filteredOptions[focusedOptionIndex];
            handleOptionClick(selectedOption.value, selectedOption.label, selectedOption.id);
          }
          break;
        default:
          break;
      }
    };
  
    const scrollToOption = (index) => {
      const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
      if (optionElements && optionElements[index]) {
        optionElements[index].scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    };
  
    useOutsideClick(dropdownRef, () => setIsOpen(false));
    useEffect(() => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isOpen]);
  

  return (
    <div className="relative w-1/3" ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Select'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {isOpen && (
      <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
        <input
          type="text"
          className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus={isOpen}
        />
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option,index) => (
              <div
                key={option.value}
                data-index={index}
                className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                onClick={() => handleOptionClick(option.value, option.label,option.id)}
              >
                {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                {option.label}
              </div>
            ))
          ) : (
            <div className="p-2 text-sm text-gray-500">
              Not available
            </div>
          )}
        </div>
        {showAddNew && (
          <button
          type="button"
          className="flex items-center border-t p-2 mt-2 text-blue-500 "
          onClick={showAddNew}
        >
          <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4 mr-2" />
           New UOM
        </button>
        )}
      </div>
    )}
  </div>
  );
};

const NewItem = () => {
  const navigate = useNavigate();
  const [ProductSubCategory, setProductSubCategory] = useState([{product_sub_category_name: '', UOM_type: '', description: '', UOM_id: '' }]);
  const [productCategories, setProductCategories] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [selectedProductCategory, setSelectedProductCategory] = useState({ id: '', name: '' });
  const [productSubCategories, setProductSubCategories] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [showNewUOMPopup, setShowNewUOMPopup] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  console.log(selectedGroup)
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [selectedUOM, setSelectedUOM] = useState('');
  const [selectedTax, setSelectedTax] = useState('');
  const [SelectdPCUomid,setSelectdPCUomid] = useState()
  const [UOM, setUOM] = useState('')
  const [description,setdescription]= useState('')
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET
   const [UomIndex,setUomIndex] = useState()
 const { 
  showPopup, popupMessage, popupType, retryFunction,
  isSaving ,setIsLoading,setPopupMessage,setPopupType,handleGetResponse, handleSaveResponse, setShowPopup,
  setRetryFunction, handleDeleteResponse, setIsSaving  } = useCommonResponseHandler();
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts


 const[ uomOptions ,setUomOptions]= useState([

]);
const [errors, setErrors] = useState({});

const Taxoptions = [
  { label: 'Taxable', value: 'taxable' },
  { label: 'Non-Taxable', value: 'nontaxable' },
  { label: 'Non-GST supply', value: 'nongstsupply' }
];

const [imagePreview, setImagePreview] = useState(null);
const [rows, setRows] = useState([{ SNo: '', Name: '', Value: ''}]);
const [specifications, setSpecifications] = useState([{name:'', value:'' }]);
const [image,setImage] = useState('');
const [data, setData] = useState({
 // product_group_id: null,
  vendor_id: '',
  product_category_id: '',
  product_name: '',
  product_image: '',
  //product_description,
  selling_price:'',
  cost_price:'',
  specifications, 
  product_sub_category_id: '',
  UOM: '',
  tax_preference: '',
  GST: '',
  IGST: '',
  SKU: '',
  HSN_Code: ''
});

const handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
    setImage(file)
  }
};

 

  const handleDropdownChange = (value ,label, id) => {
    if (value === 'add-new') {
      setShowNewUOMPopup(true);
    } else {
      setSelectedUOM(value);
      setSelectdPCUomid(id)
    }
  };

  const handleTaxDropdownChange = (value, label) => {
    console.log('Selected Tax label:', label); // Debugging: Check selected value
    setSelectedTax(label);
  };
  


  const handleClosePopup = () => {
    setShowPopup(false);
    setShowNewUOMPopup(false)
    setUOM('');
    setdescription('');
  };



  const deleteRow = (index) => {
    const updatedData = [...rows];
    updatedData.splice(index, 1);
    setRows(updatedData);
  };

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...ProductSubCategory].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setProductSubCategory(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };


  const handleSubmitUomData = async (attempt = 1) => {
    const errorMessages = [];
    if (!UOM) {
      errorMessages.push('Please enter UOM');
    }
    if (errorMessages.length > 0) {
      setErrorMessages(errorMessages);
      return;
    }
    setIsSaving(true);
    try {
      const token = sessionStorage.getItem('token');
      const saveUomUrl = `${fetchUrl}UOMs/create-UOM`;
      const requestData = {
        UOM : UOM,
        UOM_description : description,
      };
     console.log(requestData)
      const response = await fetch(saveUomUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const output = await handleSaveResponse(response , data)
      if (output) {       
        console.log(data.message);
        setShowPopup(false);
        setPopupMessage('UOM Created sucessfully')
        setShowNewUOMPopup(false);
        setUOM('')
        setdescription('')
        getUoms()
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleSubmitUomData(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleSubmitUomData(attempt)); // Set retry function
      }
    }
  };

  const getUoms = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const getUomUrl = `${fetchUrl}UOMs/get-all-UOMs`;
  
      const response = await fetch(getUomUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      const output = await handleGetResponse(response , data)
      if (output) {
       
        const AllUoms= []
        for ( const uom of output){
          const uomData= {
            label: uom.UOM, value: uom.UOM , id: uom._id
          }
          AllUoms.push(uomData)
          
        }
        const addUomOption = []
        const UOMS= [...AllUoms , ...addUomOption]
        console.log(UOMS)
        setUomOptions(UOMS)
      } else {
      
        console.error('Error fetching UOMs:', data.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => getUoms(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => getUoms(attempt)); // Set retry function
      }
    }
  };

  useEffect(() => {
    getUoms();
  }, []);
  



   const addRow = () => {
    setUomIndex()
    const newRow = {  SNo: '', Name:'' , Value : ''};
    setRows([...rows, newRow]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSubmitItem = async (attempt = 1) => {
   
    // Define the fields to be validated
    const fields = [
      { name: 'vendor', value: selectedVendor?.id, required: true, message: 'Vendor is required', usage: 'vendor' },
      { name: 'product_category', value: selectedProductCategory?.id, required: true, message: 'Item categoy is required',  usage: 'product category' },
      { name: 'product_name', value: data?.product_name, required: true, message: 'Item name is required',  usage: 'product name' },
      { name: 'UOM', value: SelectdPCUomid, required: true, message: 'UOM is required',  usage: 'UOM' },
    ];

    // Validate the fields
    const validationErrors = validate(fields);
    setErrors(validationErrors);

    // Proceed only if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      setIsSaving(true)
      // Prepare specifications
      const specifications = rows.map(row => ({
        [row.Name.trim()]: row.Value
      }));

      // Prepare form data
      const formData = new FormData();
      formData.append('vendor_id', selectedVendor.id);
      formData.append('product_category_id', selectedProductCategory.id);
      formData.append('product_name', data.product_name);
      formData.append('image', image);
      formData.append('selling_price', data.selling_price || 0);
      formData.append('cost_price', data.cost_price || 0);
      formData.append('specifications', JSON.stringify(specifications));
      formData.append('UOM_id', SelectdPCUomid);
      formData.append('tax_preference', selectedTax);
      formData.append('GST', data.GST || selectedGroup?.GST);
      formData.append('IGST', data.IGST || selectedGroup?.IGST);
      formData.append('SKU', data.SKU);
      formData.append('HSN_Code', data.HSN_Code);
      formData.append('organizationId', sessionStorage.getItem('organizationId'));  

      if (selectedSubCategory?.id) {
        formData.append('product_sub_category_id', selectedSubCategory.id);
      }
      if (selectedGroup?.id) {
        formData.append('product_group_id', selectedGroup.id);
      }

      try {
        // Fetch token and URL for the request
        const token = sessionStorage.getItem('token');
        const saveProductUrl = `${fetchUrl}product/create-product`;

        // Send the POST request
        const response = await fetch(saveProductUrl, {
          method: 'POST',
          headers: {
            'x-token': token,
          },
          body: formData,
        });

        // Parse the response
        const responseData = await response.json();
        const output = await handleSaveResponse(response, responseData);

        if (output) {
          setTimeout(() => {
            setShowPopup(false);
            navigate('/bb/app/items/itemslist');
          }, 2000);
        } else {
          console.error(responseData.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleSubmitItem(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleSubmitItem(attempt)); // Set retry function
        }
      }finally{
        setIsSaving(false)
      }
    } else {
      // Log or display the validation errors
      console.log(errors);
    }
  };


  useEffect(() => {
   fetchRoleId();
    fetchProductCategories();
  }, [fetchUrl]);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const categoriesToDisplay = roleId === 6 
  ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
  : productCategories;


  const fetchProductCategories = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      const output = await handleGetResponse(response , data)
      if (output) {
        setProductCategories(output); // Update productCategories state
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }/* finally {
      setIsLoading(false);
    } */
  };
  const product_category_id = selectedProductCategory.id;

useEffect(() => {
  
}, [fetchUrl, selectedProductCategory]); // Ensure dependencies are correctly set


  
  const fetchSubProductCategories = async (product_category_id,attempt = 1) => {
    console.log('Fetching subcategories for ID:', product_category_id);
    console.log(selectedProductCategory)
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + `product-sub-category/get-product-subCategories-and-vendors-by-productCategory?product_category_id=${product_category_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      const output = await handleGetResponse(response , data)
       
      //console.log('Fetched Data:', data);
      if (output) {
        setProductSubCategories(output.subCategories);
      setVendors(output.AllAssignedVendors);
      } else {
        /* console.error(data.message); */
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchSubProductCategories(product_category_id,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchSubProductCategories(product_category_id,attempt)); // Set retry function
      }
    }
  };

  

useEffect(() => {
  if (selectedProductCategory?.id && selectedVendor?.id) {
    fetchProductGroups();
  }
}, [selectedProductCategory, selectedVendor]);

const fetchProductGroups = async (attempt = 1) => {
  const token = sessionStorage.getItem('token');
  try {
    const response = await fetch(
      `${fetchUrl}product-groups/get-productGroups-by-vendor-and-productCategory?product_category_id=${selectedProductCategory.id}&vendor_id=${selectedVendor.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      }
    );

    const data = await response.json();
    const output = await handleGetResponse(response, data);
    console.log(output)
    if (output) {
      setProductGroups(output);
    }
    console.log('Product groups:', data);
    // Handle the fetched data
  }catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => fetchProductGroups(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchProductGroups(attempt)); // Set retry function
    }
  }
};


const handleProductCategorySelect = (value, label) => {
  fetchSubProductCategories(value); // Use the ID correctly
  setSelectedProductCategory({ id: value, name: label }); // Store ID and label correctly
  setSelectedVendor({})
  setSelectedSubCategory({});
  setSelectedGroup({});
  setProductGroups();
  setData((prevState) => ({ ...prevState, selling_price: '', cost_price: '' }));
};

const handleProductSubcategory = (value, label) => {
  setSelectedSubCategory({ id: value, name: label });
};

const handleChange = (e) => {
  const { id, value } = e.target;
  if (id === 'cost_price' || id === 'selling_price') {
    const parsedValue = parseFloat(value);
    setData((prevState) => ({ 
      ...prevState, 
      [id]: isNaN(parsedValue) ? 0 : parsedValue 
    }));
  } else {
    setData((prevState) => ({ ...prevState, [id]: value }));
  }
};

console.log(data)

const handleCloseError = () => {
  setErrorMessages([]);
};

const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const Retry = async() => {
  console.log("Retry called");
  setTimeout(async ()=>{
    if(retryFunction){

      await retryFunction ();
    }

    setIsLoading(false)
  },1000)
  
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};

const handleStayHere = () => {
  setShowCancelPopup(false);
}

const handleCancel = () => {
  setShowCancelPopup(true);
}

const handleLeave = () => {
  navigate(-1);
}


  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
  <div className="flex flex-row w-full">
    <div className="w-full flex-1">
      <div className="flex flex-col w-full h-full justify-center items-center">
        <div className="w-full h-full flex flex-col justify-between">
          <div className="w-full h-16 border-b flex items-center">
            <div className="flex w-full justify-between px-4 items-center">
              <h1 className="text-lg font-semibold">New Item</h1>
             {/*  <button className="text-white px-1 py-1 rounded-md">
                <FontAwesomeIcon icon={faX} className="text-md text-[#F7525A] cursor-pointer" />
              </button> */}
            </div>
          </div>
          {Object.keys(errors).length > 0 && (
        <div className="flex justify-between  w-full p-2 items-center px-auto py-3 bg-[#fddcde] rounded-md">
          <div className="px-6 flex flex-col space-y-2 justify-start items-start">
            {errors.vendor && (
              <span className="text-sm">
                • {errors.vendor}
              </span>
            )}
            {errors.product_category && (
              <span className="text-sm">
                • {errors.product_category}
              </span>
            )}
            {errors.product_name && (
              <span className="text-sm">
                • {errors.product_name}
              </span>
            )}
            {errors.UOM && (
              <span className="text-sm">
                • {errors.UOM}
              </span>
            )}
          </div>
          <div className='px-4'>
            <button
              className="text-gray-700 hover:text-red-500"
              onClick={() => setErrors({})}
            >
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )}
          <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">         
                  <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full flex flex-row'>
            <div className="flex flex-col w-full">
              {/* Product Category Name Label and Input */}
              <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-20">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                  Item Category <span className="text-red-500">*</span>
                </label>           
                  <CustomDropdown
                    options={categoriesToDisplay.map(category => ({
                      value: category._id,
                      label: category.product_category_name
                    }))}
                    selected={selectedProductCategory ? selectedProductCategory.name : ''}
                    onSelect={(value, label) => handleProductCategorySelect(value, label)}
                    showAddNew={false}                 />
              </div>
              {/* Product Sub-Category Name Label and Input */}
              <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-20">
                <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                  Item Sub-Category 
                </label>
                
                  <CustomDropdown
                    options={(productSubCategories || []).map(subCategory => ({
                      value: subCategory._id,
                      label: subCategory.product_sub_category_name,
                    }))}
                    selected={selectedSubCategory ? selectedSubCategory.name : ''}
                    onSelect={(value, label) => handleProductSubcategory(value, label)}
                    showAddNew={false}
                  />
               
              </div>
          
                     {/* vendor Label and Input */}
                     <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Vendor<span className="text-red-500">*</span>
                      </label>
                      <CustomDropdown
              options={(vendors || []).map(vendor => ({
                value: vendor._id,
                label: vendor.vendor_name,
              }))}
              selected={selectedVendor ? selectedVendor.name : ''}
              onSelect={(value, label) => setSelectedVendor({ id: value, name: label })}
              showAddNew={false} // Adjust as needed
            />
                    </div>
                     {/* Product Category Name Label and Input */}
                     <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                        Item Group
                      </label>
                      <CustomDropdown
                          options={(productGroups || []).map(group => ({
                            value: group._id,
                            label: group.product_group_name,
                          }))}
                          selected={selectedGroup ? selectedGroup.name : ''}
                          onSelect={(value, label) => {
                            const selectedGroup = productGroups.find(group => group._id === value);
                            setSelectedGroup({
                              id: value,
                              name: label,
                              sellingPrice: selectedGroup.selling_price,
                              costPrice: selectedGroup.cost_price,
                              taxPreference: (selectedGroup?.tax_preference === Taxoptions[0]?.value ? Taxoptions[0].label : null),
                              GST:selectedGroup.GST,
                              IGST:selectedGroup.IGST,
                            });
                            setData({...data , selling_price: selectedGroup.selling_price, cost_price: selectedGroup.cost_price, GST:selectedGroup.GST, IGST:selectedGroup.IGST});
                            setSelectedTax((selectedGroup?.tax_preference === Taxoptions[0]?.value ? Taxoptions[0].label : null));
                          }}
                          showAddNew={false} // Adjust as needed
                          /> 
                    </div>
                    </div>
                    <div className='w-1/2 justify-start items-start mt-5'>
              {/* Image Upload Box */}
              <div className=" w-1/2 h-[50%] border-2 border-dashed border-gray-300 rounded-lg flex justify-center items-center relative">
                <input
                  type="file"
                  id="itemImage"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handleImageUpload}
                />
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="text-gray-400 text-center">Click to upload image</div>
                )}
              </div>
              </div>
                    </div>
                    </div>
                    
                    <div className="w-full flex flex-col justify-start items-start px-4">
                     {/* Product Category Name Label and Input */}
                     <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                      <label htmlFor="product_name" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Item Name <span className="text-[#e54643]">*</span>
                      </label>
                      <input
                        type="text"
                        id="product_name"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                       value={data.product_name}
                       onChange={handleChange}                      
                      />
                    </div>
                    {/* Product Category Name Label and Input */}
                    <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                      <label htmlFor="SKU" className="block text-sm font-regular text-gray-700 w-1/6">
                       SKU
                      </label>
                      <input
                        type="text"
                        id="SKU"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                       value={data.SKU}
                       onChange={handleChange}
                      />
                    </div>
                    {/* Product Category Name Label and Input */}
                    <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                      <label htmlFor="HSN_Code" className="block text-sm font-regular text-gray-700 w-1/6">
                        HSN code
                      </label>
                      <input
                        type="text"
                        id="HSN_Code"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                       value={data.HSN_Code}
                       onChange={handleChange}
                      />
                    </div>

                
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="uom" className="block text-sm font-regular text-[#e54643] w-1/6">UOM
                      <span className="text-[#e54643]">*</span></label>
                      <CustomDropdown options={uomOptions} selected={selectedUOM} onSelect={handleDropdownChange}
                      showAddNew={() => setShowNewUOMPopup(true)} />
                    </div>
      <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
      <label htmlFor="tax" className="block text-sm font-regular text-gray-700 w-1/6">Tax Preference</label>
      <CustomDropdown
      options={Taxoptions}
      selected={selectedTax || selectedGroup?.taxPreference}
      onSelect={handleTaxDropdownChange}
      showAddNew={false}
    />
    </div>

     {/* Conditionally Render GST and IGST Fields */}
     {selectedTax === 'Taxable' && (
        <>
          <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
            <label htmlFor="GST" className="block text-sm font-regular text-gray-700 w-1/6">GST (%)</label>
            <input
              type="text"
              id="GST"
              className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
               value={data.GST || (selectedGroup?.GST ?? '')}
               onChange={ handleChange}
            />
          </div>
          <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
            <label htmlFor="IGST" className="block text-sm font-regular text-gray-700 w-1/6">IGST (%)</label>
            <input
              type="text"
              id="IGST"
              className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
               value={data.IGST || (selectedGroup?.IGST ?? '')}
               onChange={ handleChange}
            />
          </div>
        </>
      )}
    </div>
    <div className="flex w-full flex-row justify-between mb-4 mt-5 px-4">
  <div className="w-1/2 ">
    <h2 className="text-md font-semibold mb-2">Sales Information</h2>
    <div className="flex flex-row items-center mb-2">
      <label htmlFor="selling_price" className="block text-sm font-regular text-gray-700 w-1/3">Selling Price</label>
      <input
  type="text"
  id="selling_price"
  value={data.selling_price}
  onChange={handleChange}
  className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
   />
    </div>
  </div>
  <div className="w-1/2">
    <h2 className="text-md font-semibold mb-2">Purchase Information</h2>
    <div className="flex flex-row items-center mb-2">
      <label htmlFor="cost_price" className="block text-sm font-regular text-gray-700 w-1/3">Cost Price</label>
      <input
  type="text"
  id="cost_price"
  value={data.cost_price  /* || (selectedGroup?.costPrice ?? '') */ }
  onChange={handleChange}
  className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
    </div>
  </div>
</div>               
                  <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-md font-semibold">Specifications</h1>           
                </div>

                <div className="w-full container mx-auto px-4 mb-2 mt-4">
                {rows.length > 0 ? (
                  <table className="w-full">
                    <thead>
                      <tr className="text-[#6c7184] bg-[#fafafc]">
                        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">SNo</th>
                        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Name</th>
                        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                     {rows.map((row, index) => (
                            <tr key={index}>
                              <td className="py-2 px-4 border">{index + 1}</td>
                              <td className="border">
                              <input
                                  type="text"
                                  value={row.Name}
                                  onChange={(e) => handleInputChange(index, 'Name', e.target.value)}
                                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"                                 
                                />
                              </td>
                              <td className="border">
                                <input
                                  type="text"
                                  value={row.Value}
                                  onChange={(e) => handleInputChange(index, 'Value', e.target.value)}
                                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                                />
                              </td>
                              <td className="px-2">
                                <button
                                  type="button"
                                  onClick={() => deleteRow(index ,row)}
                                  className="text-red-500"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
                            </tr>
                          ))} 
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center py-4">
                     <p>No Specifications</p>
                 </div>
  )}

                </div>

                <div className="mt-4">
                  <button
                    onClick={addRow}
                    className="text-blue-500 py-2 px-4"
                  >
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                    Add Specifications
                  </button>
                </div>
                </div>
                    

                  <div className="mt-5 w-full">
                  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 flex-row bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
           onClick={handleSubmitItem} >
           <span>Save</span>
            {isSaving && <Spinner />}
            </button>
          <button onClick={handleCancel}  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
        </div>
        </div>   
              </div>
            </div>
        
         </div> 

         {/* Popup starts */}

       

        {showNewUOMPopup && (
  <div className="fixed inset-0 z-50 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col animate-slide-in ">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md">New UOM</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      {errorMessages.length > 0 && (
        <div className='p-1'>
                <div className="mt-1 flex justify-between items-center  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
                </div>
              )}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-6">
        <label htmlFor="uom" className="block text-sm font-regular text-[#e54643] w-1/6">
          UOM<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="productCategoryName"
          className="w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          value={UOM}
          onChange={(e)=>{setUOM(e.target.value)}}
       />
      </div>
      {/* Description Label and Textarea */}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 px-6">
        <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
        <textarea
          id="Description"
          name="Description"
          className="mt-1 w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          value={description}
          onChange={(e)=>{setdescription(e.target.value)}}
        />
      </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
          onClick={handleSubmitUomData}>
            Save {isSaving && <Spinner />}
            </button>
          <button onClick={handleClosePopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
      </div>
      </div>
  </div>
)}

{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}
{/* Popup ends */}

      </div>
      </div>
  
  );
};

export default NewItem;
