import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faBagShopping, faBank, faCartShopping, faBasketShopping,
  faUserPlus, faRectangleList, faFolder,
  faPlusCircle,
  faCaretLeft,
  faCaretRight,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [Projects, setProjects] = useState(false);
  const [Users, setUsers] = useState(false);
  const [Purchases, setPurchases] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const menu = {
    Home: 1, Projects: 2 , Users:3 , Reports: 4
  };

  const MainMenu = {
    Home: false, Projects: false , Users:false , Reports: false
  }
  const ProjectsSubMenu = {
  Projects: false
  }
 
  const UsersSubMenu = {
      Users: false
    }

   


  const handleRemainingMenuClose = (menu) => {
    console.log(menu)
    if (menu === 1) {
      setIsCollapsed(false);
      setUsers(false);
      setProjects(false);
    } else if (menu === 2) {
      setIsCollapsed(false);
      setProjects(true);
      setUsers(false);
    } else if (menu === 3) {
      setIsCollapsed(false);
      setProjects(false);
      setUsers(true);
    } else {
      setUsers(false);
      setProjects(false);
    }
  };

  const handleMenuClick = (menu, submenu) => {
    if(menu === 1){
      navigate('home');
    }else  if (menu === 2) {
      if (submenu === 1) {
        navigate('projects/projectslist');
      } else if (submenu === 1.1) {
        navigate('projects/newproject');

      } 
    }else  if (menu === 2) {  // hear 2 is the sub menus of the Users
      if (submenu === 1) {
        navigate('users/userslist');
      }else if (submenu === 1.1) {
        navigate('users/newuser');
      }
    }
  };

  const isCurrentPath = (path) => location.pathname.toLowerCase() === path.toLowerCase();
  
    if(isCurrentPath('/measurit_v3.0/app/home')){
      MainMenu.Home = true
    }
     if(isCurrentPath('/measurit_v3.0/app/projects/projectslist')){
      MainMenu.Projects = true;
      ProjectsSubMenu.Projects=true
     }
     if(isCurrentPath('/measurit_v3.0/app/projects/newproject')){
      MainMenu.Projects = true;
      ProjectsSubMenu.Projects=true
     }
     if(isCurrentPath('/measurit_v3.0/app/projects/projectdetails')){
      MainMenu.Projects = true;
      ProjectsSubMenu.Projects=true
     }
     if(isCurrentPath('/measurit_v3.0/app/users/userslist')){
      MainMenu.Users = true;
      UsersSubMenu.Users=true
     }
     if(isCurrentPath('/measurit_v3.0/app/users/newuser')){
      MainMenu.Users = true;
      UsersSubMenu.Users=true
     }
    
     

     
     return (
    <div className={`flex flex-col text-[0.8125rem] bg-[#f7f7fe] border-r font-normal h-full overflow-y-hidden ${isCollapsed ? 'w-[3.125rem]' : 'w-[12.5rem]'}`}>
      <div className={`flex flex-col flex-1 overflow-y-auto  w-full rounded-md ${isCollapsed ? '' : ''}`}>
        <div className="h-[87vh]">
          <ul className="flex flex-col space-y-3 px-2 list-none h-full mt-4 text-[#181C2E]">
            <li onClick={() => {handleRemainingMenuClose(menu.Home); handleMenuClick(menu.Home)}}
              className={`flex flex-row space-x-3 Projects-center py-2  rounded-l-md cursor-pointer ${MainMenu.Home ? 'bg-[#f7525A] text-white' : 'hover:bg-[#EDEDF7]'}`}>
              <FontAwesomeIcon icon={faHome} className={`ml-2 mr-2 ${MainMenu.Home ? 'text-white ' : ''}`} />
              {!isCollapsed && <span>Home</span>}
            </li>
            <li className={`flex flex-row space-x-3 Projects-center py-2 hover:bg-[#EDEDF7] rounded-l-md cursor-pointer ${MainMenu.Projects ? ' bg-[#EDEDF7] text-[#f7525A]' : ''}`}
              onClick={() => { setProjects(!Projects); handleRemainingMenuClose(menu.Projects); }}>
              <FontAwesomeIcon icon={faBagShopping} className={`ml-2 mr-2 ${MainMenu.Projects ? 'text-[#f7525A]' : ''}`} />
              {!isCollapsed && <span>Projects</span>}
              {!isCollapsed && (
                <>
                  {Projects ? (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  ) : (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                  )}
                </>
              )}
            </li>
            {Projects && (
              !isCollapsed && (
                <ul className="flex flex-col space-y-2 justify-center Projects-center cursor-pointer">
                  <li className={`group flex flex-row justify-between Projects-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${ProjectsSubMenu.Projects ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                    onClick={() => handleMenuClick(menu.Projects, 1)}>
                    <span className="ml-6">Projects</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${ProjectsSubMenu.Projects ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Projects, 1.1);
                        }} />
                    </div>
                  </li>
                </ul>
              )
            )}

            <li className={`flex flex-row space-x-3 Projects-center py-2 hover:bg-[#EDEDF7] rounded-l-md cursor-pointer ${MainMenu.Users ? ' bg-[#EDEDF7] text-[#f7525A]' : ''}`}
              onClick={() => { setUsers(!Users); handleRemainingMenuClose(menu.Users); }}>
              <FontAwesomeIcon icon={faCartShopping}  className={`ml-2 mr-2 ${MainMenu.Users ? 'text-[#f7525A]' : ''}`} />
              {!isCollapsed && <span>Users</span>}
              {!isCollapsed && (
                <>
                  {Users ? (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  ) : (
                    <div className='w-full flex justify-end px-2'>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                  )}
                </>
              )}
            </li>
            {Users && (
              !isCollapsed && (
                <ul className="flex flex-col space-y-2 justify-center Projects-center cursor-pointer">
                  <li className={`group flex flex-row justify-between Projects-center p-2 w-full max-w-md  rounded-l-md cursor-pointer ${UsersSubMenu.Customers ? 'bg-[#f7525a] text-white' : 'hover:bg-[#EDEDF7]'}`}
                  onClick={() => handleMenuClick(menu.Users, 1)}
                 >
                    <span className='ml-7'>Users</span>
                    <div className="w-5" onClick={(e) => e.stopPropagation()}>
                      <FontAwesomeIcon icon={faPlusCircle} className={` group-hover:inline-block ${UsersSubMenu.Users ? 'text-white block' : 'hidden'}`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from bubbling up to the li
                          handleMenuClick(menu.Users, 1.1);
                        }} />
                    </div>
                  </li>
                 
                </ul>
              )
            )}
          </ul>
        </div>
      </div>
      <div className="flex justify-center h-[2rem] bg-[#ededf7] Projects-center cursor-pointer" onClick={toggleCollapse}>
        {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
      </div>
    </div>
  );
};

export default Sidebar;
