import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faExclamationTriangle ,faTrash, faX, faPlusCircle, faChevronDown, faCheckCircle, faCheck, faCog, faFileExport, faFileImport, faPlus, faClose } from '@fortawesome/free-solid-svg-icons';
import '../Css/Animation.css';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { useNavigate } from 'react-router-dom';
import TableSearchDropDown from '../Components/tableSerchDropDown';
import { useLocation } from 'react-router-dom';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { exportToExcel } from '../Components/ExcelExport';
import { importFromExcel } from '../Components/importPriceListUpdateXl';
//import Dropdown from './DropDown'
// Custom hook to handle outside clicks
 const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (value, label,id) => {
    onSelect(value, label,id); // Pass both value and label
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  

  return (
    <div className="relative w-1/4" ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Select'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {isOpen && (
      <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
        <input
          type="text"
          className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length > 0 ? (
            filteredOptions.map(option => (
              <div
                key={option.value}
                className="p-2 cursor-pointer text-sm hover:bg-blue-500 hover:text-white"
                onClick={() => handleOptionClick(option.value, option.label,option.id)}
              >
                {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                {option.label}
              </div>
            ))
          ) : (
            <div className="p-2 text-sm text-gray-500">
              Not available
            </div>
          )}
        </div>
        {showAddNew && (
          <div
            className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
            onClick={() => handleOptionClick('add-new', 'Add New')}
          >
            {options.find(option => option.value === 'add-new')?.icon && (
              <FontAwesomeIcon
                icon={options.find(option => option.value === 'add-new').icon}
                className="mr-2"
              />
            )}
            {options.find(option => option.value === 'add-new')?.label}
          </div>
        )}
      </div>
    )}
  </div>
  );
}; 

const NewPriceList = () => {
  const location= useLocation();
  const { PriceListData } = location.state || {};
  const price_list_id = PriceListData?._id;
  const navigate = useNavigate()
    const [vendor, setVendor] = useState();
    const [PricelistName, setPricelistName] = useState('');
    const [productCategory, setProductCategory] = useState();
    const [selectedProductCategory, setSelectedProductCategory] = useState({ id: '', name: '' });
    //console.log(selectedProductCategory)
    const [productCategoryId, setProductCategoryId] = useState('');
    const [selectedCategoryName, setSelectedCategoryName] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedPercentageOption, setSelectedPercentageOption] = useState({ label: 'Markup', value: 'markup' });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { 
        showPopup, popupMessage, popupType, retryFunction,
        isSaving , setIsLoading ,setPopupMessage,setPopupType,handleGetResponse, handleSaveResponse, setShowPopup,
        setRetryFunction, handleDeleteResponse, setIsSaving  } = useCommonResponseHandler();
        const RETRY_LIMIT = 3; // Maximum number of retries
        const [retryCount, setRetryCount] = useState(0); 
        const [showCancelPopup, setShowCancelPopup] = useState(false);



      const roundOffOptions = [
       { value: 'never-mind', label: 'Never Mind' }, // Add this option
      ];

      const CurrencyOptions = [
        { value: 'inr', label: 'INR - Indian Rupees' }
      ];
      
      const  [Pricelists , setPriceLists] = useState ([])
      const [PriceListbyId, setPriceListbyId] = useState(null);
      const [productRates, setproductRates] = useState([]);
  const [SelectedRoundoffOption, setSelectedRoundoffOption] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState('INR - Indian Rupees');
  const [isDiscountChecked, setIsDiscountChecked] = useState(true);
  const [isToggled, setIsToggled] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [pricingSchema, setPricingSchema] = useState('Unit'); // Default value
  const [data, setData] = useState([]);
  const  [Items , setItems] = useState ([])
  const [products, setProducts]= useState([])
  const [tableData, setTableData] = useState([]);
  const [selectedImportExcelFile, setSelectedImportExcelFile] = useState(null);
      const [importMessage, setImportMessage] = useState('');
      const [importedData, setImportedData] = useState(null);
      const [SucessImportMessage , setSucessImportMessage]= useState('')
      const [ErrorInImportingFile, setErrorInImportingFile]= useState(false)

  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET
    
// Function to fetch products with product_category_id and vendor_id
const fetchProducts = async (product_category_id, vendor_id, attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(fetchUrl + `product/get-products-by-category-and-vendor?product_category_id=${product_category_id}&vendor_id=${vendor_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const productData = await response.json();
    if (productData?.status === 200 || productData?.status === 201 || productData?.status === 204) {
      return productData?.data;
    } else {
      handleNonSuccessStatus(productData?.status);
      return null;
    }
  } catch (error) {
    console.error('Failed to fetch products:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => fetchProducts(product_category_id, vendor_id, attempt + 1), 1000);
    } else {
      //setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
    }
    return null;
  }
};

const mapProductsWithPriceList = (products, productRates, pricelistType, priceList) => {
  return products.map(product => {
    const matchedRate = productRates.find(rate => rate.price_list_item_id === product._id) || null;
    // Initialize an object to hold the pricing details
    let pricingDetails = {};

    // Check if matchedRate exists and determine its type (unit or volume)
    if (matchedRate) {
      if (pricelistType === 'Unit') {
        // Unit pricing
        pricingDetails = {
          price_list_rateid: matchedRate?._id,
          custom_rate: matchedRate.pricing.custom_rate || '',
          discount: matchedRate.pricing.discount || '',
          gst_included: 'gst_included' in matchedRate ? matchedRate.gst_included : false
        };
      } else if (pricelistType === 'Volume') {
        const [firstPricing, ...remainingPricing] = matchedRate.pricing;

        pricingDetails = {
          price_list_rateid: matchedRate?._id,
          gst_included: 'gst_included' in matchedRate ? matchedRate.gst_included : false,
          start_quantity: firstPricing.start_quantity || null,
          end_quantity: firstPricing.end_quantity || null,
          custom_rate: firstPricing.custom_rate || '',
          discount: firstPricing.discount || '',

          volumePricing: remainingPricing.map(p => ({
            start_quantity: p.start_quantity || null,
            end_quantity: p.end_quantity || null,
            custom_rate: p.custom_rate || '',
            discount: p.discount || '',
          }))
        };
      }
    }

    return {
      ...product,
      ...pricingDetails
    };
  });
};




// Main function to fetch price list and set the data
const fetchPriceListbyId = async (price_list_id, product_category_id, vendor_id, attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}pricelist/get-pricelist?price_list_id=${price_list_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      const priceList = data?.data?.priceList;
      setPricingSchema(data?.data?.priceList.pricing_type)
     setSelectedOption(data?.data?.priceList. applied_type)
      const productRates = data.data?.productRates;
      setVendor(data.data.VendorData)
      setProductCategory(data.data?.CategoryData)
      // Fetch the products using the fetchProducts function
      const products = await fetchProducts(product_category_id, vendor_id);
      if (products) {
        // Map the products with the price list rates using the mapProductsWithPriceList function
        const mappedData = mapProductsWithPriceList(products, productRates,  data?.data.priceList?.pricing_type , priceList);
        setData(mappedData)
        setTableData(mappedData);
        setproductRates(mappedData);
      }else{
        setTableData([]);
      }
    } else {
      
      handleNonSuccessStatus(data.status);
    }
  } catch (error) {
    console.error('Failed to fetch price list:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => fetchPriceListbyId(price_list_id,product_category_id, vendor_id, attempt + 1), 1000);
    } else {
      //setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchPriceListbyId(price_list_id, product_category_id, vendor_id,));
    }
  } finally {
    //setIsLoading(false);
  }
};

// Reusable function to handle non-successful responses
const handleNonSuccessStatus = (status) => {
  if (status === 400) {
    setShowPopup(true);
    setPopupType('400');
    setPopupMessage('Bad Request!');
  } else if (status === 401) {
    setShowPopup(true);
    setPopupType('401');
    setPopupMessage('Unauthorized!');
  } else if (status === 403) {
    setShowPopup(true);
    setPopupType('403');
    setPopupMessage('Forbidden!');
  } else if (status === 404) {
    setShowPopup(true);
    setPopupType('404');
    setPopupMessage('Not Found!');
  } else if (status === 500) {
    setShowPopup(true);
    setPopupType('500');
    setPopupMessage('Internal Server Error!');
  } else {
    setShowPopup(true);
    setPopupType('unknown');
    setPopupMessage('Unknown error!');
  }
};

    
      useEffect(() => {
        if (price_list_id && PriceListData) {
         // console.log(PriceListData)
          fetchPriceListbyId(price_list_id , PriceListData?.product_category_id, PriceListData?.vendor_id);
          //fetchProducts(PriceListData.product_category_id, PriceListData.vendor_id)
        }
      }, [price_list_id, PriceListData]);
    


      const addNewRange = (index) => {
        const newData = [...tableData];
        newData[index].volumePricing.push({
          startQuantity: '',
          endQuantity: '',
          customRate: '',
          gst: '',
        });
        setTableData(newData);
      };
    
      const handleInputChange = (index, field, value, subIndex = null) => {
        const newData = [...tableData];
        if (subIndex !== null) {
          newData[index].volumePricing[subIndex][field] = value;
        } else {
          newData[index][field] = value;
        }
        setTableData(newData);
      };

      const handleCheckboxChange = (index, field, value) => {
        const newData = [...tableData];
        newData[index][field] = value;
        setTableData(newData);
      };

      const handleToggle = () => {
        setIsToggled(!isToggled);
        setErrorInImportingFile(false)
        setSelectedImportExcelFile(null)
      };

  const handleCurrencySelect = (value, label) => {
    setSelectedCurrency(label);
   // console.log('Selected Currency:', label); // You can handle the selected currency as needed
  };

  const handleDiscountChange = () => {
    setIsDiscountChecked(!isDiscountChecked);
  };
  
  

      
       const handleChange = (type) => {
        setPricingSchema(type);
        const pricelistProducts = products.map(p => ({
          product_name : p?.product_name,  selling_price: p?.selling_price , price_list_item_id : p?._id,
          customRate: '', gst_included: false , discount: '' , Volumepricing: []
        }))
        setData(pricelistProducts)
      }; 

      const Retry = async() => {
        //console.log("Retry called");
        setTimeout(async ()=>{
          if(retryFunction){
      
            await retryFunction ();
          }
      
          //setIsLoading(false)
        },1000)
        
        setShowPopup(false);
      };

      const BackToLoginPage = () => {
        //console.log("BackToLoginPage called");
        setShowPopup(false);
      };

      const closePopup = () => {
        //console.log("closePopup called");
        setShowPopup(false);
      };

      const handleDeleteVolumePricing = (index, subIndex) => {
        // Create a copy of the data array
        const newData = [...tableData];
      
        // Check if the item at the specified index and the Volumepricing at subIndex exist
        if (newData[index] && newData[index]?.volumePricing) {
          // Remove the Volumepricing entry at the specified subIndex
          newData[index]?.volumePricing.splice(subIndex, 1);
      
          // Update the state with the modified data array
          setTableData(newData);
        }
      };

     const UpdatePriceListRates = async (attempt = 1) => {
  try {
    if(tableData.length > 0 ){
    const pricelistRates = await handleSetPriceListProductRatesData();
    try {
      // Fetch token and URL for the request
      const token = sessionStorage.getItem('token');
      const saveProductUrl = `${fetchUrl}pricelist/update-or-create-product-rates`;

      // Send the POST request
      const response = await fetch(saveProductUrl, {
        method: 'PUT',
        headers: {
          'x-token': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(pricelistRates)
      });

      // Parse the response
      const responseData = await response.json();
      const output = await handleSaveResponse(response, responseData);

      if (output) {
        setTimeout(() => {
          setShowPopup(false);
          navigate('/bb/app/items/viewpricelists')
        }, 2000);
      } else {
        console.error(responseData.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => UpdatePriceListRates( attempt + 1), 1000); // Retry after 1 second
      } else {
        //setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => UpdatePriceListRates( attempt)); // Set retry function
      }
    } 
    }else{
    console.log('select the file')
    }
  } catch (error) {
    console.error('Error updating price list rates:', error);
  }finally {
    setIsSaving(false);
  }
  
};

const handleSetPriceListProductRatesData = async () => {
  const filteredData = tableData.filter(p => 
    (pricingSchema === 'Unit' && (p?.custom_rate || p?.discount)) ||
    (pricingSchema === 'Volume' && (p?.custom_rate || p?.discount || (p?.Volumepricing && p?.Volumepricing.some(vp => vp?.custom_rate || vp?.discount))))
  );

  const productRates = await Promise.all(
    filteredData.map(async (p) => ({
      ...(p.price_list_rateid && { price_list_rateid: p?.price_list_rateid }),
      price_list_id,
      pricing_type :pricingSchema,
      selling_price: parseFloat(p?.selling_price),
      price_list_item_id: p?._id,
      gst_included: p?.gst_included,
      pricing: await handleAddInPricing(p),
    }))
  );
  //console.log(productRates)
  return productRates;
};

const handleAddInPricing = async (pricingData) => {
  if (pricingSchema === 'Unit') {
    const data = {
      custom_rate: pricingData.custom_rate ? parseFloat(pricingData.custom_rate) : null,
      discount: pricingData.discount ? parseFloat(pricingData.discount) : null,
      unit: ''
    };
    return data;
  } else if (pricingSchema === 'Volume') {
    const volumePricing = (pricingData?.volumePricing || []).map(p => ({
      start_quantity: p.start_quantity ? parseFloat(p.start_quantity) : null,
      end_quantity: p.end_quantity ? parseFloat(p.end_quantity) : null,
      custom_rate: p.custom_rate ? parseFloat(p.custom_rate) : null,
      discount: p.discount ? parseFloat(p.discount) : null
    }));

    // Include primary volume pricing details if present
    if (pricingData.start_quantity || pricingData.end_quantity || pricingData.custom_rate || pricingData.discount) {
      volumePricing.unshift({
        start_quantity: pricingData.start_quantity ? parseFloat(pricingData.start_quantity) : null,
        end_quantity: pricingData.end_quantity ? parseFloat(pricingData.end_quantity) : null,
        custom_rate: pricingData.custom_rate ? parseFloat(pricingData.custom_rate) : null,
        discount: pricingData.discount ? parseFloat(pricingData.discount) : null
      });
    }

    return volumePricing;
  }
};
const handleExportPriceList = async () =>{
  const pricelistProductRatesData = await handleConvertProductRatesData();
  //console.log( pricelistProductRatesData , data)
  exportToExcel(pricelistProductRatesData, pricingSchema);
   }

   const handleConvertProductRatesData = async () => {       
    const productRates = await Promise.all(
      tableData.map(async (p) => ({
        product_name:p?.product_name,
        SKU: p.SKU,
        HSN_Code:p?.HSN_Code,
        selling_price: parseFloat(p?.selling_price),
        price_list_item_id: p?._id,
        gst_included: p?.gst_included,
        pricing: await handleAddInPricing(p),
      }))
    );
    
    return productRates;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImportExcelFile({ fileName: file?.name });
      importFromExcel(file ,pricingSchema, PriceListData?._id)
        .then(data => {
          console.log(data);
          setImportedData(data);
          setTableData(data)
          setImportMessage('');
          setSucessImportMessage('File is imported successfully. Please save the data.')
          setErrorInImportingFile(false)
        })
        .catch(err => {setImportMessage(`Import failed: ${err.message}`); setSucessImportMessage (''); setErrorInImportingFile(true)});
    }
  };

  const handleImportClick = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current.click();
    }
  };

  const handleCancel = () => {
    setShowCancelPopup(true);
  }

  const handleStayHere = () => {
    setShowCancelPopup(false);
  }

  const handleLeave = () => {
    navigate(-1);
  }

      
  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
  <div className="flex flex-row w-full">
    <div className="w-full flex-1">
      <div className="flex flex-col w-full h-full justify-center items-center">
        <div className="w-full h-full flex flex-col justify-between">
          <div className="w-full h-16 border-b flex items-center">
            <div className="flex w-full justify-between px-4 items-center">
              <h1 className="text-lg font-semibold">Edit Price List</h1>
              {/* <button className="text-white px-1 py-1 rounded-md">
                <FontAwesomeIcon icon={faX} onClick={handleCancel} className="text-md text-[#F7525A] cursor-pointer" />
              </button> */}
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
          <div className="w-full flex flex-col justify-start items-start px-4">
          <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-20">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                  Item Category <span className="text-red-500">*</span>
                </label>           
                <input
        type="text"
        id="productCategoryName"
        className="w-1/4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
      value={productCategory?.product_category_name}
        readOnly
      />

              </div>
               {/* vendor Label and Input */}
               <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                      <label htmlFor="vendor" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Vendor<span className="text-red-500">*</span>
                      </label>
                      <input
        type="text"
        id="vendor"
        className="w-1/4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        value={vendor?.vendor_name}
        readOnly
      />
                    </div>

                     {/* Product Category Name Label and Input */}
                     <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                      <label htmlFor="name" className="block text-sm font-regular text-[#e54643] w-1/6">
                       Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="w-1/4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                        value={PriceListData?.price_list_name}
                       onChange={(e)=>setPricelistName(e.target.value)}
                      />
                    </div>

                     {/* Product Category Name Label and Input */}
                     <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
  <label htmlFor="name" className="block text-sm font-regular text-gray-700 w-1/6">
    Transaction Type
  </label>
  <div className="flex items-center cursor-not-allowed">
          <input type="radio" name="transactionType" value="sales" checked={PriceListData?.type === 'Sales'} className="mr-1 cursor-not-allowed" disabled />
          <span className="text-sm font-regular text-gray-700">Sales</span>
        </div>
        <div className="flex items-center cursor-not-allowed">
          <input type="radio" name="transactionType" value="purchase" checked={PriceListData?.type === 'Purchase'} className="mr-1" disabled />
          <span className="text-sm font-regular text-gray-700">Purchase</span>
        </div>
</div>

<div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
      <label htmlFor="name" className="block text-sm font-regular text-gray-700 w-1/6">
        Price List Type
      </label>
      
      <div 
  className="flex items-start bg-[#F9F9FB] p-2 rounded-md hover:border-blue-500 hover:border cursor-pointer"
  style={{ cursor: 'not-allowed' }} 
  /* onClick={() => handleCircleClick('allItems')} */
>
  <div 
    className={`relative flex items-center justify-center w-5 h-5 border rounded-full ${PriceListData?.applied_type === 'allItems' ? 'bg-blue-500 border-blue-500' : 'bg-white border-gray-400'}`}
  >
    <FontAwesomeIcon 
      icon={faCheck} 
      className={`absolute ${PriceListData?.applied_type === 'allItems' ? 'text-white' : 'text-gray-400'}`} 
      style={{ fontSize: '12px' }}
    />
  </div>
  <div className="ml-2">
    <span className="text-sm font-semibold block">All Items</span>
    <span className="text-xs font-regular text-gray-700 block mt-1">Mark up or mark down the rates of all items</span>
  </div>
</div>


      <div className="flex items-start bg-[#F9F9FB] p-2 rounded-md hover:border-blue-500 hover:border cursor-pointer" 
        style={{ cursor: 'not-allowed' }} 
      /* onClick={() => handleCircleClick('individualItems')} */>
        <div 
          className={`relative flex items-center justify-center w-5 h-5 border rounded-full ${PriceListData?.applied_type === 'individualItems' ? 'bg-blue-500 border-blue-500' : 'bg-white border-gray-400'}`}
          
        >
          <FontAwesomeIcon 
            icon={faCheck} 
            className={`absolute ${PriceListData?.applied_type === 'individualItems' ? 'text-white' : 'text-gray-400'}`} 
            style={{ fontSize: '12px' }}
          />
        </div>
        <div className="ml-2">
          <span className="text-sm font-semibold block">Individual Items</span>
          <span className="text-xs font-regular text-gray-700 block mt-1">Customize the rate of each item</span>
        </div>
      </div>     
</div>

  {/* Description Label and Textarea */}
  <div className="w-full flex flex-row items-center mb-4 space-x-20">
                      <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
                      <textarea
                        id="Description"
                        name="Description"
                        className="mt-1 w-1/4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                        value={productRates.length > 0 ? PriceListData?.description : ''}
                        //onChange={(e)=>setProductCategoryDescription(e.target.value)} 
                      />
                    </div>

                    {/* Pricing Schema */}
                  {selectedOption === 'allItems' && (
                  <div className='flex flex-col w-full justify-center items-start'>
            <div className="w-full flex flex-col items-start justify-center mb-4 mt-5 space-y-5">
                    <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                    <label htmlFor="percentage" className="block text-sm font-regular text-[#e54643] w-1/6">
                      Percentage<span className="text-red-500">*</span>
                    </label>
                    <div className="relative flex w-1/4 border border-gray-300 rounded-md overflow-hidden">
                     
                      <div className="flex items-center w-full">
                        
                        {/* Input field */}
                        <input
                          type="text"
                          id="percentage"
                          className="flex-1 outline-none p-2 text-sm text-gray-700 border-none"
                          placeholder="Enter percentage"
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        />
                      </div>
                      
                    </div>
                  
                  
                  </div>
                  
                  <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                                        <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                                          Round Off To<span className="text-red-500">*</span>
                                        </label>
                                        <CustomDropdown
                            options={roundOffOptions}
                            selected={SelectedRoundoffOption ? SelectedRoundoffOption.label : ''}
                            onSelect={(value, label) => setSelectedRoundoffOption({ value, label })}
                            showAddNew={false} // Adjust as needed
                          />
                                      </div>
                                      </div>
                                      </div>
                  
                  )}

{selectedOption === 'individualItems' && (
                  <div className='flex flex-col w-full justify-center items-start'>
            <div className="w-full flex flex-col items-start justify-center mb-4 mt-5 space-y-5">
            <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
  <label htmlFor="name" className="block text-sm font-regular text-gray-700 w-1/6">
    Pricing Schema
  </label>
  <div className="flex items-center bg-[#F9F9FB] p-2 rounded-md cursor-not-allowed">
  <input
    type="radio"
    name="pricingSchema"
    value="Unit"
    checked={PriceListData?.pricing_type === 'Unit'}
    className="mr-2"
    onClick={()=>handleChange('Unit')}
    style={{
      appearance: 'none',
      borderRadius: '50%',
      width: '1rem',
      height: '1rem',
      border: `2px solid ${PriceListData?.pricing_type === 'Unit' ? '#3B82F6' : 'gray'}`,
      outline: 'none',
      cursor: 'not-allowed',
      position: 'relative',
      transition: 'border-color 0.2s ease',
      backgroundColor: 'transparent', // Ensure no background color
    }}
  />
  <span className="text-sm font-regular text-gray-700">Unit Pricing</span>
</div>
<div className="flex items-center bg-[#F9F9FB] p-2 rounded-md cursor-not-allowed">
  <input
    type="radio"
    name="pricingSchema"
    value="Volume"
    checked={PriceListData?.pricing_type === 'Volume'}
    className="mr-2"
    
    //onClick={()=>handleChange('Volume')}
    style={{
      appearance: 'none',
      borderRadius: '50%',
      width: '1rem',
      height: '1rem',
      border: `2px solid ${PriceListData?.pricing_type === 'Volume' ? '#3B82F6' : 'gray'}`,
      outline: 'none',
      cursor: 'not-allowed',
      position: 'relative',
      transition: 'border-color 0.2s ease',
      backgroundColor: 'transparent', // Ensure no background color
    }}
  />
  <span className="text-sm font-regular text-gray-700">Volume Pricing</span>
</div>


</div>
                  
<div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                                        <label htmlFor="productCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                                          Currency
                                        </label>
                                        <CustomDropdown
                           options={CurrencyOptions}
                           selected={selectedCurrency}
                           onSelect={handleCurrencySelect}
                           showAddNew={false} // Adjust if you need an "Add New" option
                          />
                                      </div>

                                      <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                    <label htmlFor="discountCheckbox" className="block text-sm font-regular text-gray-700 w-1/6">
                      Discount
                    </label>
                    <div className='flex items-center'>
                    <input
                      type="checkbox"
                      id="discountCheckbox"
                      checked={isDiscountChecked}
                      onChange={handleDiscountChange}
                      className="w-4 h-4"
                    />
                     <span className="text-sm text-gray-700 ml-1">
                     I want to include discount percentage for the items
                    </span>
                    </div>
                  
                   
                   
                  </div>

                  
                  

            
                                      </div>
                   
                                      <div className="flex w-full justify-between px-4 items-center border-t mt-2">
      <div>
        <div className="flex w-full justify-start items-center  mt-2">
          <h1 className="text-lg font-semibold mt-5">Customize Rates In Bulk</h1>
        </div>
        <div className='flex w-full justify-start items-start mt-2'>
          <div className="flex items-center cursor-pointer">
            <FontAwesomeIcon icon={faCog} className="text-blue-500 mr-2" />
            <h1 className="text-sm font-regular text-blue-500">Update Rates in Bulk</h1>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <span className="text-sm font-regular text-gray-700 mr-2">Import Price List for Items</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" className="sr-only peer" onChange={handleToggle} />
          <div className="w-8 h-4 bg-gray-200 rounded-full peer peer-checked:bg-blue-600 peer-focus:outline-none after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
        </label>
      </div>
    </div>
    
    {isToggled ? (
        <div className="mt-4">
          <div className="mb-4">
            <h2 className="text-md font-semibold">1.Export items as XLS file</h2>
            <p className='text-sm mt-1'>Export all your items in an XLS file, customize the rates, and import the file.</p>
            <button className="mt-2 bg-[#F9F9FB] px-2 py-1 rounded-md" onClick={()=>handleExportPriceList()} >
            <FontAwesomeIcon icon={faFileExport} className="text-blue-500 ml-2 text-sm " /><span className='text-sm'> Export Items</span></button>
          </div>
          <div className="mb-4">
            <h2 className="text-md font-semibold">2.Import items as XLS file</h2>
            <p className='text-sm mt-1'>Import the CSV or XLS file that you've exported and updated with the customized rates to update the price list.</p>
            <p className="mt-2 font-regular">Note:</p>
            <ul className="list-disc pl-5">
              <p className='text-sm mt-1'>1. Before you import, ensure that the following column names are in English as given below:</p>
              <ul className="list-disc pl-10 mt-1">
                <li className='text-sm text-gray-500'>Item Name</li>
                <li className='text-sm text-gray-500'>SKU</li>
                <li className='text-sm text-gray-500'>PriceList Rate</li>
              </ul>
              <p className='text-sm mt-1'>2. Once you import the file, the existing items and their rates in this price list will be replaced with the data in the import file.</p>
            </ul>
            <div className="mt-2">
      <input
        type="file"
        accept=".csv, .xls, .xlsx"
        className="hidden"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <button onClick={handleImportClick} className="mt-2 bg-[#F9F9FB] px-2 py-1 rounded-md">
        <FontAwesomeIcon icon={faFileImport} className="text-blue-500 ml-2 text-sm" />
        <span className='text-sm'>Import Items</span>
      </button>
      {selectedImportExcelFile && (
        <span className="ml-2 text-sm text-gray-700">{selectedImportExcelFile?.fileName}</span>
      )}
      {importMessage && <p className="ml-2 text-sm text-red-500">{importMessage}</p>}
      {SucessImportMessage && <p className="ml-2 text-sm text-blue-500">{SucessImportMessage}</p>}
      {importedData && (
        <div>
          <h3>Imported Data:</h3>
          <pre></pre>
        </div>
      )}
    </div>
          </div>
        </div>
    ) : (
      <div className='w-full'>
         {/* Table for item details */}
         <div className="mt-4 w-full overflow-x-auto">
      <table className="w-full mb-5">
      <thead className="sticky top-0 border-t text-[#6c7184] border-b">
          <tr className="border-b">
            <th className="text-xs py-3 px-2 text-start border-r cursor-pointer w-1/6">ITEM DETAILS</th>
            <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">SALES RATE</th>
            <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">GST INCLUDED</th>
            {pricingSchema === 'Volume' && (
              <>
                <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">START QUANTITY</th>
                <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">END QUANTITY</th>
              </>
            )}
            <th className="text-xs py-3 px-2 text-end border-r cursor-pointer w-1/6">CUSTOM RATE</th>
           
            {isDiscountChecked && (
              <th className="text-xs py-3 px-2 text-end cursor-pointer w-1/6">DISCOUNT</th>
            )}
          </tr>
        </thead>
        <tbody className="border-b border-t w-full">
          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              {/* Main row */}
              <tr className='border'>
                <td className={`py-2 px-2 text-start border-r w-1/6 ${row.volumePricing && row.volumePricing.length ? 'border-b-0' : ''}`}>
                  <input
                    type="text"
                    value={row?.product_name}
                    className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"

                  />
                </td>
                <td className={`py-2 px-2 text-end border-r w-1/6 ${row.volumePricing && row.volumePricing.length ? 'border-b-0' : ''}`}>
                  <input
                    type="text"
                    value={row?.selling_price}
                    className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                  />
                </td>
                <td className="py-2 px-2 text-end border-r w-1/6">
                  <div className="flex items-center justify-end">
                    <label className="inline-flex items-center mr-4">
                      <input
                        type="checkbox"
                        checked={row?.gst_included === true}
                        onChange={() => handleCheckboxChange(index, 'gst_included', true)}
                        className="form-checkbox"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={row?.gst_included === false || true}
                        onChange={() => handleCheckboxChange(index, 'gst_included', false)}
                        className="form-checkbox"
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                </td>
                {pricingSchema === 'Volume' && (
                  <>
                    <td className="py-2 px-2 text-end border-l border-r w-1/6"> {/* {console.log(row)} */}
                      <input
                        type="text"
                        value={row?.start_quantity}
                        onChange={(e) => handleInputChange(index, 'start_quantity', e.target.value)}
                        className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                      />
                    </td>
                    <td className="py-2 px-2 text-end border-r w-1/6">
                      <input
                        type="text"
                        value={row?.end_quantity || ''}
                        onChange={(e) => handleInputChange(index, 'end_quantity', e.target.value)}
                        className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                      />
                    </td>
                  </>
                )}
                <td className="py-2 px-2 text-end border-r w-1/6">
                  <input
                    type="text"
                    value={row?.custom_rate}
                    onChange={(e) => handleInputChange(index, 'custom_rate', e.target.value)}
                    className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                  />
                </td>
                {isDiscountChecked && (
                  <td className="py-2 px-2 text-end w-1/6">
                    <input
                      type="text"
                      value={row?.discount}
                      onChange={(e) => handleInputChange(index, 'discount', e.target.value)}
                      className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                )}
              </tr>

              {/* Extra fields rows */}
              {pricingSchema === 'Volume' && row?.volumePricing && row?.volumePricing.map((extraField, subIndex) => (
                <tr key={subIndex} className="">
                  <td className="py-2 px-2 border-none w-1/6"></td> {/* Skip itemDetails */}
                  <td className="py-2 px-2 border-none w-1/6"></td> {/* Skip salesRate */}
                  <td className="py-2 px-2 border-none w-1/6"></td>
                  <td className="py-2 px-2 text-end border-l border-b border-r w-1/6">
                    <input
                      type="text"
                      value={extraField?.start_quantity}
                      onChange={(e) => handleInputChange(index, 'start_quantity', e.target.value, subIndex)}
                      className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                  <td className="py-2 px-2 text-end border-r border-b w-1/6">
                    <input
                      type="text"
                      value={extraField?.end_quantity}
                      onChange={(e) => handleInputChange(index, 'end_quantity', e.target.value, subIndex)}
                      className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                  <td className="py-2 px-2 text-end border-r border-b w-1/6">
                    <input
                      type="text"
                      value={extraField?.custom_rate}
                      onChange={(e) => handleInputChange(index, 'custom_rate', e.target.value, subIndex)}
                      className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                  {isDiscountChecked && (
                    <td className="py-2 px-2 text-end border-b w-1/6">
                      <input
                        type="text"
                        value={extraField?.discount}
                        onChange={(e) => handleInputChange(index, 'discount', e.target.value, subIndex)}
                        className="text-right outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                      />
                    </td>
                  )}
                  <td className="py-2 px-2 border-none">
                    <button
                      className="text-[#ff4d4f] p-2"
                      onClick={() => handleDeleteVolumePricing(index, subIndex)}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                  </td>
                </tr>
              ))}
              {pricingSchema === 'Volume' && (
                <tr className="">
                  <td colSpan={isDiscountChecked ? 4 : 1} className="text-right  p-2">
                    <button
                      className="text-blue-500 text-sm"
                      onClick={() => addNewRange(index)}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add New Range
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>

   </div>
    )}
     </div>
                  
                  )}

                    
{/* <TableSearchDropDown
      options={DropDownUoms} // Pass your options here
      value={productsubcategory?.UOM_id} // Pass the value (ID) of the selected UOM
      onChange={(selectedOption) => HandleChangeUom(selectedOption , index)} // Handle change
      index={index} // Pass the index
      getFilteredOptions={(options, inputValue) => options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )}
      setEditUOM={(value) => setUomName(value)}
    /> */}



</div>
<div className="mt-5 w-full">
                  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
           onClick={UpdatePriceListRates}>
            Save  {isSaving && ( <Spinner/>)}
            </button>
          <button  onClick={handleCancel}  className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
        </div>
</div>
</div>
</div>
</div>
</div>

{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

{showPopup && (
        <div>
          {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
          {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
</div>
  );
};

export default NewPriceList;
