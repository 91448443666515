import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { faSortUp, faSortDown, faX , faExclamationTriangle,faSearch} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Loader, Spinner} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { FaChevronDown } from 'react-icons/fa';
import Search from '../Components/Search';

const SalesOrdersItemwiseList = () => {
  const navigate = useNavigate();
 const [productCategories, setProductCategories] = useState([]);
 const [searchQuery, setSearchQuery] = useState('');
 const [selectedSalesOrderItems, setSelectedSalesOrderItems] = useState(null);
    const [salesOrdersItems, setSalesOrdersItems] = useState([]);
    //console.log(salesOrdersItems);
  const [isLoading, setIsLoading] = useState(true);
  const url = process.env.REACT_APP_FORE_BRICKBUCKET;
  //const [showPopup, setShowPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
  //const [popupType, setPopupType] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
 // const [retryFunction, setRetryFunction] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const [SalesOrderPopup, setSalesOrderPopup] = useState(false);
    const [SalesOrdersData, setSalesOrdersData] = useState();
  const [SOItemQty, setSOItemQty] = useState(null);
  const [ReceivableQty, setReceivableQty] = useState(false);
  const [PendingQty, setPendingQty] = useState(false);
  const [createPOButtonEnabled, setCreatePOButtonEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState(null);
  const [ReceivableQuantity, setReceivableQuantity] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDeliverableQuantities, setEditedDeliverableQuantities] = useState({});
  const [remainingReceivableQuantity, setRemainingReceivableQuantity] = useState(ReceivableQuantity?.receivable_quantity || 0);
  const [totalDeliverableQty, setTotalDeliverableQty] = useState(0);
  const [isExceeding, setIsExceeding] = useState(false); // To manage alert state
  const [alertforExceededQuantity , setAlertforExceededQuantity] = useState(false);
const [showCancelPopup , setShowCancelPopup] = useState(false);
const [purchaseOrderExists, setPurchaseOrderExists] = useState(false);
const [roleId, setRoleId] = useState(null);
const [deliveryChallans, setDeliveryChallans] = useState([]);
const [searchdropDown, setSearchDropDown] = useState(false);
const ref = useRef(null);
const [Type , setType ] = useState('');
const [filteredSalesOrderItems, setFilteredSalesOrderItems] = useState(salesOrdersItems);



const URLs = {
  'Items' : '',
  'Vendors' : '',
  'SalesOrders': '',
  'PurchaseOrders': '',
}
const searchTypes = ['Items', 'Vendors', 'SalesOrders', 'PurchaseOrders', 'Item PO Status'];
// useEffect(() => {
//   if (Type) {
//     const filteredItems = salesOrdersItems.filter((salesOrderItem) => {
//       if (Type === 'Items') {
//         return salesOrderItem._id.item_name.toLowerCase().includes(searchQuery.toLowerCase());
//       } else if (Type === 'Customers') {
//         return salesOrderItem.customer_name.toLowerCase().includes(searchQuery.toLowerCase());
//       } else if (Type === 'Vendors') {
//         return salesOrderItem.vendor_name.toLowerCase().includes(searchQuery.toLowerCase());
//       } else if (Type === 'Quotes') {
//         return salesOrderItem.quote_number.toLowerCase().includes(searchQuery.toLowerCase());
//       } else if (Type === 'SalesOrders') {
//         return salesOrderItem.sales_orders.some((salesOrder) =>
//           salesOrder.salesorder_number.toLowerCase().includes(searchQuery.toLowerCase())
//         );
//       } else if (Type === 'PurchaseOrders') {
//         return salesOrderItem.purchaseOrderData.purchaseorder_number
//           .toLowerCase()
//           .includes(searchQuery.toLowerCase());
//       }
//     });
//     setFilteredSalesOrderItems(filteredItems);
//   } else {
//     setFilteredSalesOrderItems(salesOrdersItems);
//   }
// }, [Type, searchQuery, salesOrdersItems]);

const sortArray = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  setSortConfig({ key, direction });

  // Function to get nested value using key
  const getNestedValue = (item, key) => {
    const keyParts = key.split('.');
    let value = item;
    for (let i = 0; i < keyParts.length; i++) {
      if (Array.isArray(value)) {
        // If value is an array, get the first element's value
        value = value[0] ? value[0][keyParts[i]] : undefined;
      } else {
        value = value ? value[keyParts[i]] : undefined;
      }
    }
    return value;
  };

  const sortedArray = [...salesOrdersItems].sort((a, b) => {
    const aValue = getNestedValue(a, key) || ''; // Treat undefined as empty string
    const bValue = getNestedValue(b, key) || ''; // Treat undefined as empty string

    // Convert to string for comparison and handle case insensitivity
    const aString = aValue.toString().toLowerCase();
    const bString = bValue.toString().toLowerCase();

    if (aString < bString) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (aString > bString) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  setSalesOrdersItems(sortedArray);
};

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

const [selectedItemWiseSalesOrder, setSelectedItemWiseSalesOrder] = useState([]);

const handleCheckboxChange = (salesOrderItem) => {
  if (salesOrderItem._id.item_PO_status === 'Placed') {
    return;
  }
    if (selectedItemWiseSalesOrder.length > 0) {
        // Check if the product category ID and vendor ID match the first selected item
        if (selectedItemWiseSalesOrder[0].productCategoryId === salesOrderItem.product_category_id &&
            selectedItemWiseSalesOrder[0].vendor_id === salesOrderItem.vendor_id) {
            
            // Proceed with selection if IDs match
            const salesOrderIds = salesOrderItem.sales_orders.map((p) => p.salesorder_id);

            const item = {
                item_id: salesOrderItem?._id?.item_id,
                salesorder_ids: salesOrderIds,
                item_PO_status: salesOrderItem?._id?.item_PO_status,
                productCategoryId: salesOrderItem?.product_category_id,
                vendor_id: salesOrderItem?.vendor_id,
            };

            setSelectedItemWiseSalesOrder((prevSelectedItems) => {
                const index = prevSelectedItems.findIndex(
                    (selectedItem) => selectedItem.item_id === item.item_id
                );

                if (index !== -1) {
                    // Remove the item if it already exists
                    return prevSelectedItems.filter(
                        (selectedItem) => selectedItem.item_id !== item.item_id
                    );
                } else {
                    // Add the item to the selection
                    return [...prevSelectedItems, item];
                }
            });
        } else {
            // Show popup and prevent checkbox selection
            setShowCancelPopup(true);
        }
    } else {
        // No items selected yet, proceed with selection
        const salesOrderIds = salesOrderItem.sales_orders.map((p) => p.salesorder_id);

        const item = {
            item_id: salesOrderItem?._id?.item_id,
            salesorder_ids: salesOrderIds,
            item_PO_status: salesOrderItem?._id?.item_PO_status,
            productCategoryId: salesOrderItem?.product_category_id,
            vendor_id: salesOrderItem?.vendor_id,
        };

        setSelectedItemWiseSalesOrder([item]);
    }
};

const handleCreatePurchaseOrder = () => {
  const hasPurchaseOrder = selectedItemWiseSalesOrder.some(item => item.item_PO_status === 'Placed');
  
  if (hasPurchaseOrder) {
    setPurchaseOrderExists(true);
    // Show a popup or message
    setPopupMessage("Cannot create purchase order. It already exists for the selected sales order.");
    setPopupType('400');
    setShowPopup(true);
  } else {
    navigate('/bb/app/purchases/newpurchaseorder', { 
      state: { selectedItemWiseSalesOrder: selectedItemWiseSalesOrder } 
    });
  }
};

  
  const handleCreate = () => {
    navigate("/bb/app/sales/newsalesorder");
  };
  
  


  const handlenavigateToSalesOrderDetails = (salesOrder) => {
    const salesorderData = {
      _id: salesOrder.salesorder_id,
    }
    navigate("/bb/app/sales/salesorderdetails", {state:{SalesOrderData:salesorderData}});
    console.log(salesOrder);
  }

  useEffect(() => {
    fetchRoleId();
    fetchSalesOrderItems();
    fetchDeliveryChallans();
  }, []);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const salesOrderItemsToDisplay = filteredSalesOrderItems.length > 0
  ? filteredSalesOrderItems
  : salesOrdersItems;

console.log(salesOrderItemsToDisplay);


  const fetchSalesOrderItems = async (attempt = 0) => {
    console.log('enter')
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/get-combined-salesorders-items`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      if (output) { // Checks if response status is 200-299
        setSalesOrdersItems(output);
        setFilteredSalesOrderItems(output);
        console.log(data);
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch:', error);
      setIsLoading(false);
    }
  };

  const fetchDeliveryChallans = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/delivery-challan/get-all-delivery-challans?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
  
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      if (output) { // Checks if response status is 200-299
        setDeliveryChallans(data.data);
        console.log(data.data);
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch:', error);
      setIsLoading(false);
    }
  };
       
  const fetchReceivableQuantityofSelectedItemfromAssociatedPurchaseOrder = async (salesOrderItem, attempt = 0) => {
    try {
        const token = sessionStorage.getItem('token');
        const purchaseOrderId = salesOrderItem.purchaseOrderData._id;
        const item_id = salesOrderItem._id.item_id;
        const orderItemIds = salesOrderItem.OrderItem_ids.join(','); // Join OrderItem IDs as a comma-separated string
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/purchaseorder/get-receivable-quantity-of-selectedItem?purchaseOrderId=${purchaseOrderId}&orderItemIds=${orderItemIds}&item_id=${item_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-token': token,
            },
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
       // console.log(output)
        if (output) {
            console.log(output[0]);
            setReceivableQuantity(output[0]);
            // Process data as needed
        } else {
            console.error('Failed to fetch:', response.status, response.statusText);
        } 
    } catch (error) {
        console.error('Failed to fetch:', error);
    } finally {
        setIsLoading(false);
    }
};



  const handleDelete = async (attempt = 1) => {
    const selectedCategoryIds = selectedCategories.map((category) => category._id);
    console.log(selectedCategoryIds);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "product-category/delete-product-categories", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({ productCategoryIds: selectedCategoryIds })
      });
  
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // Update the vendors state to reflect the deleted vendors
        setProductCategories(productCategories.filter((category) => !selectedCategoryIds.includes(category._id)));
        setSelectedCategories([]);
        setSelectAll(false);
      } else {
        // Handle error cases
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleDelete(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleDelete(attempt)); // Set retry function
      }
    }
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleSalesOrder = (salesOrderItem) => {
    setSalesOrderPopup(true);
    setSalesOrdersData(salesOrderItem);
    setSelectedSalesOrderItems(salesOrderItem);
  }

  const handlePurchaseOrder = (salesOrderItem) => {
navigate('/bb/app/purchases/purchaseorderitemwise' , {state:{PurchaseOrderData:salesOrderItem.purchaseOrderData}});
  }

  const handleClickQuantity = (salesOrderItem) => {
    setAlertforExceededQuantity(false);
    setReceivableQuantity([]);
    //console.log('Clicked item:', salesOrderItem); // Debugging statement
    setSOItemQty(salesOrderItem);
    setSelectedSalesOrderItems(salesOrderItem);
    fetchReceivableQuantityofSelectedItemfromAssociatedPurchaseOrder(salesOrderItem);
  };

  const handleClosePopup = () => {
    setEditedDeliverableQuantities({}); // This will reset all input field values
    setRemainingReceivableQuantity(0); // Set the remaining receivable quantity to 0
    setTotalDeliverableQty(0);
    setIsEditing(false);
    setSOItemQty(false);
    setPopupMessage('');
    setPopupType('');
    setReceivableQty(false);
    setPendingQty(false);
    setSalesOrderPopup(false);
  };

  useEffect(() => {
    // Calculate the total of all input fields
    const total = Object.values(editedDeliverableQuantities).reduce((sum, qty) => sum + parseFloat(qty || 0), 0);
    setTotalDeliverableQty(total);
  
    // Check if the total exceeds the receivable quantity
    if (total > (ReceivableQuantity?.receivable_quantity || 0)) {
      // If total exceeds the receivable quantity, set alert to true
      setAlertforExceededQuantity(true);
    } else {
      // If total is within the limit, reset the alert
      setAlertforExceededQuantity(false);
    }
  }, [editedDeliverableQuantities, ReceivableQuantity?.receivable_quantity]);
  
// Calculate the remaining receivable quantity when input fields change
useEffect(() => {
  const totalEnteredQuantity = Object.values(editedDeliverableQuantities).reduce((total, qty) => total + qty, 0);
  setRemainingReceivableQuantity(ReceivableQuantity?.receivable_quantity - totalEnteredQuantity);
}, [editedDeliverableQuantities, ReceivableQuantity?.receivable_quantity]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleInputChange = (salesorder_id, value) => {
    const quantity = parseFloat(value) || 0;
    // Update the state with the new deliverable quantity
    setEditedDeliverableQuantities((prevState) => ({
      ...prevState,
      [salesorder_id]: quantity,
    }));
  };

  const handleSave = async (salesorderdata , unallocatedqty , receivableqtydata) => {
    //console.log(salesorderdata._id.item_id);
    //console.log(unallocatedqty);
    //console.log(receivableqtydata.purchaseorder_id);
    // Create an array to hold the data
    const dataToSave = selectedSalesOrderItems.sales_orders
      .map(order => {
        return {
          item_id: selectedSalesOrderItems._id?.item_id, // Assuming item_id is available in selectedSalesOrderItems._id
          sales_order_id: order.salesorder_id,
          receivable_quantity: editedDeliverableQuantities[order.salesorder_id] || order.deliverable_quantity
        };
      })
      .filter(item => item.receivable_quantity && item.receivable_quantity > 0); // Filter out invalid quantities
  
    // Print the filtered data to the console
    //console.log(dataToSave);
  
    try {
    const token = sessionStorage.getItem('token');
      // Make the API request to update the receivable quantity
      const promises = [
        fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/update-receivable-quantity-of-orderitem`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
          body: JSON.stringify(dataToSave)
        }),
        UpdateReceivableQuantityofanIteminPO(salesorderdata._id.item_id, unallocatedqty, receivableqtydata.purchaseorder_id)
      ];
      const responses = await Promise.all(promises);
      const data = await responses[0].json();
      const output = await handleSaveResponse(responses[0], data);
      // If the save was successful, navigate to the quote list after a short delay
      if (output) {
        setSOItemQty(false);
        setTimeout(() => {
          setShowPopup(false);
          setPopupMessage('');
          setPopupType('');
        }, 2000);
      }
      // Optionally, handle success response here
    } catch (error) {
      console.error('Error updating receivable quantities:', error);
      // Optionally, handle error response here
    }
  
    setIsEditing(false);
  };
  
  const UpdateReceivableQuantityofanIteminPO = async (itemID, unallocatedQty, POId, attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await  fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/purchaseorder/update-unallocated-quantity-of-item-in-PO` , {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify({
          purchaseOrderId: POId,
          itemId: itemID,
          unallocatedQuantity: unallocatedQty
        })
      });

      const data = await response.json();
      const output = await handleSaveResponse(response, data);
      if (output) {
        setSOItemQty(false);
        setEditedDeliverableQuantities({}); // This will reset all input field values
        setRemainingReceivableQuantity(0); // Set the remaining receivable quantity to 0
        setTotalDeliverableQty(0);
        fetchSalesOrderItems();
        //console.log(output);
      } else {
        if (attempt < 3) {
          // retry the request after 500ms
          await new Promise(resolve => setTimeout(resolve, 500));
          return UpdateReceivableQuantityofanIteminPO(itemID, unallocatedQty, POId, attempt + 1);
        } else {
          throw new Error(`Failed to update unallocated quantity after ${attempt} attempts`);
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredSalesOrderItems(salesOrdersItems);
      return;
    }

    const filtered = salesOrdersItems.filter((salesOrderItem) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'Items':
          return salesOrderItem._id?.item_name?.toLowerCase().includes(lowercaseQuery);
        case 'Vendors':
          return salesOrderItem?.vendor_name?.toLowerCase().includes(lowercaseQuery);
        case 'SalesOrders':
          return salesOrderItem?.sales_orders?.some((salesOrder) =>
            salesOrder?.salesorder_number?.toLowerCase().includes(lowercaseQuery)
          );
        case 'PurchaseOrders':
          return salesOrderItem.purchaseOrderData?.purchaseorder_number?.toLowerCase().includes(lowercaseQuery);
        case 'Item PO Status':
          return salesOrderItem._id?.item_PO_status?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });

    setFilteredSalesOrderItems(filtered);
  };

  
  
  function formatSalesOrderDate(dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1; // add 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function calculateAgingDays(dateStr) {
    const salesOrderDate = new Date(dateStr);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - salesOrderDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24));
    return diffInDays;
  }

  
  const handleStayHere = () => {
    setShowCancelPopup(false);
  }

  function formatSalesOrderDate(dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1; // add 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function calculateAgingDays(dateStr) {
    const salesOrderDate = new Date(dateStr);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - salesOrderDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24));
    return diffInDays;
  }

  const calculateDeliveredQuantity = (salesOrderItem) => {
    console.log(salesOrderItem);
    const salesorderids = salesOrderItem.sales_orders.map((p) => p.salesorder_id.toString());
    const itemId = salesOrderItem._id.item_id.toString();
    const relevantChallans = deliveryChallans
    .filter(challan => challan.status === 'Delivered')
    .filter(challan =>
      salesorderids.includes(challan.sales_order_id._id.toString())
    ).filter(challan =>
      challan.items.some(item => item.item_id.toString() === itemId)
    );
    
  console.log(relevantChallans);
  const deliveredQuantity = relevantChallans.reduce((total, challan) => {
    const item = challan.items.find(item => item.item_id.toString() === itemId);
    return total + (item ? item.quantity : 0);
  }, 0);
   
    return deliveredQuantity;
  };
  
  


  if (isLoading) {
    return <Loader />;
  }
  
  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-8 items-center">
  {selectedItemWiseSalesOrder.length > 0 ? (
    <>
 <div className="flex space-x-2">
        <button
          onClick={handleDelete}
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
        >
          Delete
        </button>
        <button
          onClick={handleCreatePurchaseOrder}  // Ensure this function is defined
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
         // disabled={!createPOButtonEnabled}
        >
          Create Purchase Order
        </button>
      </div>
    </>
  ) : (
    <>
     <div className='space-x-10 flex flex-row'>
      <h1 className="text-lg font-bold">All Sales Orders Items</h1>
      <Search onSearch={handleSearch} searchTypes={searchTypes} />

      </div>
      <button
        onClick={handleCreate}
        className="bg-[#F7525A] text-white z-20 px-2 py-1 rounded"
        title="" data-tooltip="Create Sales order"  style={{ '--tooltip-bg': 'black' }}>
        + New
      </button>
    </>
  )}
</div>
              </div>
              
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                  {salesOrderItemsToDisplay.length > 0 ? (
                    <table className="w-full mb-5">
                      <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
                        <tr className='border-b'>
                          <th className="text-xs py-3 text-center">
                          {/* <input   type="checkbox"
        className="mr-2"
        onChange={handleSelectAll}
        checked={selectAll}
      /> */}
    </th>
    <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('_id.item_name')}>
                            Item Name {getSortIcon('_id.item_name') && <FontAwesomeIcon icon={getSortIcon('_id.item_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_category_name')}>
                            Item Category {getSortIcon('product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('vendor_name')}>
                            Vendor {getSortIcon('vendor_name') && <FontAwesomeIcon icon={getSortIcon('vendor_name')} />}
                          </th>                          
                           <th className="text-xs py-3 text-center " >
                           Sales Orders 
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('purchaseorder_number')}>
                           Purchase Order{getSortIcon('purchaseorder_number') && <FontAwesomeIcon icon={getSortIcon('purchaseorder_number')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('_id.item_PO_status')}>
                            Item PO Status {getSortIcon('_id.item_PO_status') && <FontAwesomeIcon icon={getSortIcon('_id.item_PO_status')} />}
                          </th>
                          <th className="text-xs py-3 text-center" >
                           Ordered Qty  
                          </th>
                          <th className="text-xs py-3 text-center" >
                          Deliverable Qty 
                          </th>                          
                          <th className="text-xs py-3 text-center " >
                          Pending Qty 
                          </th> 
                          <th className="text-xs py-3 text-center" >
                          Delivered Qty 
                          </th>                                                                      
                        </tr>
                        
                      </thead>
                      <tbody className="border-b border-t">
  {filteredSalesOrderItems.length > 0 ? (
    filteredSalesOrderItems.map((salesOrderItem) => (
      <tr key={salesOrderItem._id.item_id} className="text-sm border-b">
        <td className="py-2 text-center">
          <input
            type="checkbox"
            className="mr-2"
            checked={!!selectedItemWiseSalesOrder.find(
              (p) => p.item_id === salesOrderItem._id.item_id &&
                     p.item_PO_status === salesOrderItem._id.item_PO_status
            )}
            onChange={() => handleCheckboxChange(salesOrderItem)}
            disabled={salesOrderItem._id.item_PO_status === 'Placed'}
          />
        </td>
        <td className="py-2 text-center">{salesOrderItem?._id?.item_name}</td>
        <td className="py-2 text-center">{salesOrderItem.product_category_name}</td>
        <td className="py-2 text-center">{salesOrderItem.vendor_name}</td>
        <td className="py-2 text-center cursor-pointer text-[#408dfb]" onClick={() => handleSalesOrder(salesOrderItem)}>View</td>
        <td className={`py-2 text-center ${salesOrderItem?.purchaseOrderData?.purchaseorder_number ? 'text-[#408dfb] cursor-pointer' : ''}`}
    onClick={() => salesOrderItem?.purchaseOrderData?.purchaseorder_number && handlePurchaseOrder(salesOrderItem)}>
  {salesOrderItem?.purchaseOrderData?.purchaseorder_number || '--'}
</td>

        <td className="py-2 text-center">{salesOrderItem?._id?.item_PO_status}</td>
        <td
          className="py-2 text-center text-[#408dfb] cursor-pointer"
          onClick={() => handleClickQuantity(salesOrderItem)}
        >
          {salesOrderItem.total_quantity.toFixed(2)} {salesOrderItem?._id?.UOM}
        </td>
        <td className="py-2 text-center">{salesOrderItem?.total_receivable_quantity} {salesOrderItem?._id?.UOM}</td>
        <td className="py-2 text-center">
          {Math.max(salesOrderItem.total_quantity - salesOrderItem?.total_receivable_quantity, 0).toFixed(2)} {salesOrderItem?._id?.UOM}
        </td>
        <td className="py-2 text-center">
  {calculateDeliveredQuantity(salesOrderItem)} {salesOrderItem?._id?.UOM}
</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="10" className="text-center text-[#6c7184] py-4">
        Not found
      </td>
    </tr>
  )}
</tbody>




                    </table>
                    
                  ) : (
                    
                    <>
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Create Sales Order</h1>
                      </div>
                      <div className="flex justify-center items-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     {/*  pop up starts */}


     {SalesOrderPopup && selectedSalesOrderItems && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Sales Order Details</h2>
        <button  onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="px-6 py-2">
        <p className="text-md font-semibold">{selectedSalesOrderItems._id?.item_name}</p>
      </div>
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
            
            <th className="border text-sm px-2 py-1 text-left">Date</th>
              <th className="border text-sm px-2 py-1 text-left">Aging</th>
              <th className="border text-sm px-2 py-1 text-left">SO Number</th>
              <th className="border text-sm px-2 py-1 text-left">Quantity</th>
              <th className="border text-sm px-2 py-1 text-left">SO Item Status</th>
            </tr>
          </thead>
          <tbody>
            {SalesOrdersData.sales_orders.map((salesOrder, index) => (
              <tr key={index}>
               {/*  <td className="border text-sm px-2 py-1 text-left">{salesOrder.salesorder_number}</td> */}
              
               <td className="border text-sm px-2 py-1 text-left">{formatSalesOrderDate(salesOrder.salesorder_date)}</td>
                <td className="border text-sm px-2 py-1 text-left">{calculateAgingDays(salesOrder.salesorder_date)}</td>
                <td className="border text-sm px-2  text-center text-[#408dfb] font-500 cursor-pointer text-left"   onClick={()=> handlenavigateToSalesOrderDetails(salesOrder)} >{salesOrder.salesorder_number}</td>
                <td className="border text-sm px-2 py-1 text-left">{salesOrder.item_quantity.toFixed(2)} {selectedSalesOrderItems._id?.UOM}</td>
                <td className="border text-sm px-2 py-1 text-left">{salesOrder.item_SO_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}
 


 {SOItemQty && SOItemQty.subitems && selectedSalesOrderItems && ReceivableQuantity && (
  <div className="pt-2 pb-2 fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
      style={{ maxWidth: '70vw', maxHeight: '100vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">SO Item Quantity</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="flex flex-col px-6 py-2">
        <div>
          <p className="text-md font-semibold">{selectedSalesOrderItems._id?.item_name}</p>
        </div>
  <div className='mt-2 text-sm flex flex-row justify-between'>
    <p>Purchase Order No: {ReceivableQuantity?.purchaseorder_number}</p>
    <p>Available Quantity: {remainingReceivableQuantity || 0} {selectedSalesOrderItems._id?.UOM}</p>
  </div>
      </div>
      {alertforExceededQuantity && (
<div className='bg-[#f7525a] border-[#f7525a] text-white flex justify-center'>Deliverable Quantity is exceeded than Receivable Quantity</div>
)}
      <div className="flex-1 overflow-y-auto px-6 py-4">
            <table className="min-w-full border-collapse">
              <thead>
                <tr className="text-sm bg-gray-100">
                  <th className="border px-4 py-2 text-left">Date</th>
                  <th className="border px-4 py-2 text-left">SO No</th>
                  <th className="border px-4 py-2 text-center">Ordered Quantity</th>
                  <th className="border px-4 py-2 text-center">Allocated Qty</th>
                  <th className="border px-4 py-2 text-right">Allocate Qty</th>
                </tr>
              </thead>
              <tbody>
                {selectedSalesOrderItems.sales_orders.map((order) => ( 
                  <tr key={order.salesorder_id}> 
                    <td className="border text-sm px-2 py-1 text-left">{formatSalesOrderDate(order.salesorder_date)}</td>
                    <td className="border text-sm px-4 py-2">{order.salesorder_number}</td>
                    <td className="border px-4 py-2 text-center">{order.item_quantity.toFixed(2)} {selectedSalesOrderItems._id?.UOM}</td>
                    <td className="border px-4 py-2 text-center">
  {order.item_receivable_quantity || '0'} {selectedSalesOrderItems._id?.UOM}
</td>
<td className="border px-4 py-2 text-right">
  <div className="flex items-center">
    {isEditing ? (
      <input
        className='outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
        type="text"
        value={editedDeliverableQuantities[order.salesorder_id] || ''}
        onChange={(e) => handleInputChange(order.salesorder_id, e.target.value)}
      />
    ) : (
      <input
        className='text-right text-sm w-full h-full p-2 focus:outline-none'
        type="text"
        value='0'
        readOnly
      />
    )}
    <span className="ml-2 text-sm">{selectedSalesOrderItems._id?.UOM}</span>
  </div>
</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <hr className="border-t border-gray-200" style={{ borderTopWidth: '1px' }} />

          <div className="flex justify-start px-6 py-4 space-x-4">
            {isEditing ? (
              <>
              <button 
  onClick={(e) => handleSave(selectedSalesOrderItems, remainingReceivableQuantity, ReceivableQuantity)} 
  disabled={alertforExceededQuantity}
  className={`text-sm px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white text-gray-700 border rounded-md ${
    alertforExceededQuantity ? 'cursor-not-allowed opacity-50' : ''
  }`}
>
  Save
</button>

              </>
            ) : (
              <button onClick={handleEdit} className="text-sm px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white text-gray-700 border  rounded-md">
                Edit
              </button>
            )}
            <button onClick={handleClosePopup} className="text-sm border hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ">
              Close
            </button>
            </div>
        </div>
      </div>
)}




{/*       {ReceivableQty && salesOrderItems && salesOrderItems.length > 0 && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Receivable Quantity</h2>
        <button  onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Design</th>
              <th className="border px-4 py-2 text-left">Boxes</th>
            </tr>
          </thead>
          {salesOrderItems.map((salesOrderItem) => (
              salesOrder.selected_designs && salesOrder.selected_designs.map((design, index) => (
                <tr key={`${salesOrder.id}-${design.subitem_id}-${index}`}>
                  <td className="border px-4 py-2">Design {index + 1}</td>
                  <td className="border px-4 py-2">{design.sub_item_receivable_quantity}</td>
                </tr>
              ))
            ))}
        </table>
      </div>
    </div>
  </div>
)}
 */}
{/* {PendingQty && salesOrders && salesOrders.length > 0 && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Pending Quantity</h2>
        <button  onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Design</th>
              <th className="border px-4 py-2 text-left">Boxes</th>
            </tr>
          </thead>
          {salesOrders.map((salesOrder) => (
              salesOrder.selected_designs && salesOrder.selected_designs.map((design, index) => (
                <tr key={`${salesOrder.id}-${design.subitem_id}-${index}`}>
                  <td className="border px-4 py-2">Design {index + 1}</td>
                  <td className="border px-4 py-2">{design.sub_item_pending_quantity}</td>
                </tr>
              ))
            ))}
        </table>
      </div>
    </div>
  </div>
)} */}
      {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[200px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '200px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Unable to select?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      Kindly select same Item Category and Vendor.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Okay
  </button>
        </div>
      </div>
    </div>
  </div>
)}
      {showPopup && (
        <div>
           {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
           {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    {/*   pop up end */}
    </div>
  );
};

export default SalesOrdersItemwiseList;
