import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const BulkUploadStep3 = ({notSavedCount,updatedCount,savedCount,SavedProducts,UpdatedProducts,NotSavedProducts}) => {

    console.log(notSavedCount,updatedCount,savedCount);
    // State for toggling items list
    const [isItemsOpen, setIsItemsOpen] = useState(false);
    // State for toggling skipped records list
    const [isSkippedOpen, setIsSkippedOpen] = useState(false);

    const [isUnmappedOpen, setIsUnmappedOpen] = useState(false);

    // Toggle function for items list
    const toggleItemsOpen = () => {
        setIsItemsOpen(!isItemsOpen);
    };

    // Toggle function for skipped records list
    const toggleSkippedOpen = () => {
        setIsSkippedOpen(!isSkippedOpen);
    };

    const toggleUnmappedOpen = () => {
        setIsUnmappedOpen(!isUnmappedOpen);
    };

    return (
        <div className='flex flex-col w-1/2 justify-center items-center'>
            {/* Saved Products Section */}
            <div className='flex items-center w-full justify-between cursor-pointer' onClick={toggleItemsOpen}>
                <h2 className='text-sm font-regular'>Items Saved Successfully: {SavedProducts.length}</h2>
                <button className='ml-2 p-1'>
                    {isItemsOpen ? (
                        <FontAwesomeIcon icon={faChevronDown} className='w-3 h-3' />
                    ) : (
                        <FontAwesomeIcon icon={faChevronRight} className='w-3 h-3' />
                    )}
                </button>
            </div>
            {isItemsOpen && (
                <div className='mt-4 w-full'>
                    <ul className='list-disc pl-4'>
                        {SavedProducts.map(product => (
                            <li key={product._id}>{product.product_name}</li>
                        ))}
                    </ul>
                </div>
            )}

            <hr className='w-full border-gray-300 my-2' />

            {/* Not Saved Products Section */}
            <div className='flex items-center w-full justify-between cursor-pointer' onClick={toggleSkippedOpen}>
                <h2 className='text-sm font-regular'>Items NOT Saved: {NotSavedProducts.length}</h2>
                <button className='ml-2 p-1'>
                    {isSkippedOpen ? (
                        <FontAwesomeIcon icon={faChevronDown} className='w-3 h-3' />
                    ) : (
                        <FontAwesomeIcon icon={faChevronRight} className='w-3 h-3' />
                    )}
                </button>
            </div>
            {isSkippedOpen && (
                <div className='mt-4 w-full'>
                    <ul className='list-disc pl-4'>
                        {NotSavedProducts.map(product => (
                            <li key={product.product_id || product.SKU}>{product.product_name || 'Unnamed Product'}</li>
                        ))}
                    </ul>
                </div>
            )}

            <hr className='w-full border-gray-300 my-2' />

            {/* Updated Products Section */}
            <div className='flex items-center w-full justify-between cursor-pointer' onClick={toggleUnmappedOpen}>
                <h2 className='text-sm font-regular'>Items Updated Successfully: {UpdatedProducts.length}</h2>
                <button className='ml-2 p-1'>
                    {isUnmappedOpen ? (
                        <FontAwesomeIcon icon={faChevronDown} className='w-3 h-3' />
                    ) : (
                        <FontAwesomeIcon icon={faChevronRight} className='w-3 h-3' />
                    )}
                </button>
            </div>
            {isUnmappedOpen && (
                <div className='mt-4 w-full'>
                    <ul className='list-disc pl-4'>
                        {UpdatedProducts.map(product => (
                            <li key={product._id}>{product.product_name}</li>
                        ))}
                    </ul>
                </div>
            )}

            <hr className='w-full border-gray-300 my-2' />
        </div>
    );
};

export default BulkUploadStep3;
