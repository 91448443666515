import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FaChevronDown } from 'react-icons/fa';

const Search = ({ onSearch, searchTypes }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchDropDown, setSearchDropDown] = useState(false);
  const ref = useRef(null);
  const containerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);




  const handleDropDownToggle = () => {
    setSearchDropDown(!searchDropDown);
  };

  const handleClickItem = (item) => {
    setSearchType(item);
    setSearchQuery('');
    onSearch('', item);
    setSearchDropDown(false);
  };

  const handleChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearch(query, searchType);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setSearchDropDown(false);
        setIsExpanded(false);
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  return (
    <div 
  className={`relative transition-all duration-300 ease-in-out ${isExpanded ? 'w-72' : 'w-64'}`} 
  ref={containerRef}
  onClick={() => setIsExpanded(true)}
>
    <div className="flex items-center bg-white border border-gray-300 rounded-lg shadow-sm hover:border-blue-500 focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500 transition-all duration-300 ease-in-out">
      <div 
        className="flex items-center space-x-2 px-3 py-2 text-gray-600 border-r border-gray-300 cursor-pointer"
        onClick={handleDropDownToggle}
      >
        <FontAwesomeIcon icon={faSearch} className="text-sm " />
        <FaChevronDown className="text-gray-400 text-xs" />
      </div>
      <input
        type="text"
        className="flex-grow px-3 py-2 text-sm text-gray-700 placeholder-gray-400 bg-transparent focus:outline-none transition-all duration-300 ease-in-out"
        placeholder={searchType ? `Search in ${searchType}` : "Search"}
        onChange={handleChangeSearch}
        value={searchQuery}
        disabled={!searchType}
      />
    </div>
      {searchDropDown && (
        <div className="absolute left-0 z-50 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
          {searchTypes.map((item, index) => (
            <div
              key={index}
              className="px-4 py-2 text-sm text-gray-700 hover:bg-blue-50 cursor-pointer transition duration-150 ease-in-out"
              onClick={() => handleClickItem(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Search;
