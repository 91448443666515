import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { Loader } from '../Loader/Loader';
import Search from '../Components/Search';

const QuotationsList = () => {
  const [quotations, setQuotations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const {
    showPopup, popupMessage, popupType, retryFunction, setShowPopup, setPopupMessage,
    setPopupType, setRetryFunction, setIsSaving,
  } = useCommonResponseHandler();
  const RETRY_LIMIT = 3;
  const [retryCount, setRetryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [roleId, setRoleId] = useState(null);
  const [filteredQuotes, setFilteredQuotes] = useState([]);
const searchTypes = ['Quote No', 'Customer Name', 'Item Category', 'Status'];

  const getStatusColor = (status) => {
    switch (status) {
      case 'Draft':
        return 'text-gray-500';
      case 'Sent':
        return 'text-blue-500';
      case 'Accepted':
        return 'text-green-500';
      default:
        return '';
    }
  };

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  
    // Function to get nested value using a key
    const getNestedValue = (item, key) => {
      return key.split('.').reduce((value, keyPart) => {
        return value ? value[keyPart] : undefined;
      }, item);
    };
  
    const sortedArray = [...quotations].sort((a, b) => {
      const aValue = getNestedValue(a, key);
      const bValue = getNestedValue(b, key);
  
      // Check if values are numbers
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        if (direction === 'ascending') {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      } else {
        // If values are not numbers, convert to strings and compare
        const aString = aValue.toString().toLowerCase();
        const bString = bValue.toString().toLowerCase();
        if (aString < bString) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (aString > bString) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
    });
  
    setQuotations(sortedArray);
  };
  

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  useEffect(() => {
    fetchRoleId();
    fetchQuotations();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [quotations]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredQuotes(quotations);
      return;
    }
  
    const filtered = quotations.filter((quotation) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'Quote No':
          return quotation?.quotation_number?.toLowerCase().includes(lowercaseQuery);
        case 'Customer Name':
          return quotation?.customer_id?.customer_name?.toLowerCase().includes(lowercaseQuery);
        case 'Item Category':
          return quotation?.product_category_id?.product_category_name?.toLowerCase().includes(lowercaseQuery);
        case 'Status':
          return quotation?.status?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });

    setFilteredQuotes(filtered);
  };
  


  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const fetchQuotations = async (attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-all-quotations?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });

      const data = await response.json();
if (response.ok) {
  setQuotations(data.data || []);
  console.log(data.data);
} else {
  console.error(data.message);
}


      if (response.status === 500) {
        setRetryFunction(() => () => fetchQuotations(attempt));
      }
    } catch (error) {
      console.error('Failed to fetch quotations:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchQuotations(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchQuotations(attempt));
      }
    } finally {
      setIsLoading(false);
    }
  };

  console.log('Quotations:', quotations);


  const handleCreate = () => {
    navigate("/bb/app/sales/newquote");
  };

  const handleNavigateToQuoteDetails = (quotation) => {
    navigate("/bb/app/sales/quotedetails", {state:{QuoteData:quotation}});
    console.log(quotation);
  }

  const formatNumber = (value) => {
    // Parse the input as a number
    const parsedValue = parseFloat(value);
  
    // Check if the parsed value is a finite number
    if (Number.isFinite(parsedValue)) {
      // Round to two decimal places
      const roundedValue = (Math.round(parsedValue * 100) / 100);
    // Format the number with commas and two decimal places
    return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
    }
  
    // Return '0.00' if the value is not a valid number
    return '0.00';
  };

  if (isLoading) {
    return <Loader />;
  }

  
  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){
  
        await retryFunction ();
      }
  
      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const quotationsToDisplay = roleId === 6 
? quotations.filter(quotation => (quotation.product_category_id?.product_category_name.toLowerCase()) === 'tiles')
: quotations;
console.log(quotationsToDisplay);

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-5 items-center">
                <div className='space-x-10 flex flex-row'>
                  <h1 className="text-lg font-semibold">Quote Lists</h1>
                  <Search searchTypes={searchTypes} onSearch={handleSearch} />
                        </div>
                  <button onClick={handleCreate} className="bg-[#F7525A] text-white z-20 px-2 py-1 rounded"
                    title="" data-tooltip="Create Quote"  style={{ '--tooltip-bg': 'black' }}>+ New</button>
                </div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                  {quotationsToDisplay && Array.isArray(quotationsToDisplay) && quotationsToDisplay.length > 0 ? (
                    <table className="w-full mb-5">
                      <thead className="sticky top-0 z-10 bg-[#fafafc] text-[#6c7184] border-b">
                        <tr className='border-b'>
                          <th className="text-xs py-3 text-center">
                            <input type="checkbox" className="mr-2" />
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('quotation_date')}>
                            DATE {getSortIcon('quotation_date') && <FontAwesomeIcon icon={getSortIcon('quotation_date')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('quotation_number')}>
                            QUOTE No {getSortIcon('quotation_number') && <FontAwesomeIcon icon={getSortIcon('quotation_number')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('customer_id.customer_name')}>
                            CUSTOMER NAME {getSortIcon('customer_id.customer_name') && <FontAwesomeIcon icon={getSortIcon('customer_id.customer_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_category_id.product_category_name')}>
                            ITEM CATEGORY {getSortIcon('product_category_id.product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_id.product_category_name')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('status')}>
                            STATUS {getSortIcon('status') && <FontAwesomeIcon icon={getSortIcon('status')} />}
                          </th>
                          <th className="text-xs py-3 text-center">
                            AMOUNT
                          </th>
                        </tr>
                      </thead>
                      <tbody className='border-b border-t'>
                      {filteredQuotes.length > 0 ? (
    filteredQuotes.map((quotation) => (
    <tr key={quotation?._id} className="text-sm border-b">
      <td className="py-2 text-center">
        <input type="checkbox" className="mr-2" />
      </td>
      <td className="py-2 text-center ">
        {quotation?.quotation_date ? new Date(quotation.quotation_date).toLocaleDateString() : 'N/A'}
      </td>
      <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" onClick={() => handleNavigateToQuoteDetails(quotation)}>
        {quotation?.quotation_number || 'N/A'}
      </td>
      <td className="py-2 text-center ">
        {quotation?.customer_id?.customer_name || 'N/A'}
      </td>
      <td className="py-2 text-center">
        {quotation?.product_category_id?.product_category_name || 'N/A'}
      </td>
      <td className={`py-2 text-center ${getStatusColor(quotation?.status)}`}>
        {quotation?.status || 'N/A'}
      </td>
      <td className="py-2 text-center">
        {quotation?.total ? formatNumber(quotation.total) : 'N/A'}
      </td>
    </tr>
  )) 
) : (
  <tr>
  <td colSpan="10" className="text-center text-[#6c7184] py-4">
    Not found
  </td>
</tr>
  )}
</tbody>

                    </table>
                  ) : (
                    <>
                      <div className="flex justify-center mb-4">
                        <h1 className="text-xl font-bold">Create New Quotation</h1>
                      </div>
                      <div className="flex justify-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}
    </div>
  );
};

export default QuotationsList;
